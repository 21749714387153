import Snackbar from '@gain/components/snackbar'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useCallback, useEffect } from 'react'

const SNACKBAR_KEY = 'new-app-version'
const SNACKBAR_STORAGE_KEY = '__gain-initial-download'
const hadInitialDownload = Boolean(localStorage.getItem(SNACKBAR_STORAGE_KEY))

export default function AppVersionProvider({ children }: PropsWithChildren) {
  const { enqueueSnackbar } = useSnackbar()

  const handleAppReload = useCallback(() => {
    window.location.reload()
  }, [])

  const handleNewVersionOfAppAvailable = useCallback(() => {
    enqueueSnackbar(undefined, {
      key: SNACKBAR_KEY,
      content: () => (
        <Snackbar
          action={{
            title: 'Reload',
            onClick: handleAppReload,
          }}
          id={SNACKBAR_KEY}
          message={'A new version of Gain.pro is available'}
          disableCloseButton
        />
      ),
      persist: true,
      preventDuplicate: true,
    })
  }, [enqueueSnackbar, handleAppReload])

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // Only show the snackbar when we had an initial download, this prevents the user from seeing the
        // message on the first visit
        if (hadInitialDownload) {
          // TODO(@Tycho): enable when fixed
          // handleNewVersionOfAppAvailable()
        } else {
          localStorage.setItem(SNACKBAR_STORAGE_KEY, 'true')
        }
      })
    }
  }, [handleNewVersionOfAppAvailable])

  return children
}
