import Typography from '@gain/components/typography'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import classNames from 'classnames'
import { FunctionComponent, useRef, useState } from 'react'

import Card, { CardHeader } from '../../common/card/card'
import SearchInput from '../../common/search/search-input'
import VirtualTable, { VirtualTableProps } from '../../common/virtual-table/virtual-table'
import AddAssetsDialog from '../add-assets-dialog'
import BookmarksListCreateDialog from '../bookmarks-list/bookmarks-list-create/bookmarks-list-create-dialog'
import { useBenchmarkingContext } from './benchmarking-context'
import useBenchmarkingColumns, {
  SPOTLIGHT_ICON_BUTTON_CLASSNAME,
} from './benchmarking-table-columns'
import BenchmarkingTableMenu from './benchmarking-table-menu'
import { BenchmarkingItem, BenchmarkingItemType } from './utils'

const styledVirtualTableClasses = generateUtilityClasses('StyledVirtualTable', [
  'missingData',
  'targetAsset',
])

const StyledVirtualTable = styled(
  VirtualTable as FunctionComponent<VirtualTableProps<BenchmarkingItem>>
)(({ theme }) => ({
  [`& .${styledVirtualTableClasses.missingData} .${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[500],
    [`&:not(.${SPOTLIGHT_ICON_BUTTON_CLASSNAME}) *`]: {
      color: theme.palette.grey[500],
    },
  },
  [`& .${styledVirtualTableClasses.targetAsset} .${tableCellClasses.root}`]: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}))

const StyledRoot = styled(Card)(({ theme }) => ({
  maxHeight: 628,
  gap: theme.spacing(2),
}))

interface BenchmarkingTableProps
  extends Pick<VirtualTableProps<BenchmarkingItem>, 'onSort' | 'sort'> {
  assets: BenchmarkingItem[]
  deselectedAssetIds: number[]
  onSelectAssetId: (assetId: number, isChecked: boolean) => void
  missingDataAssetIds: number[]
  onAddAssets: (newAssetIds: number[]) => Promise<void>
  onReset: () => void
  hasChanges: boolean
  onSearch: (query: string | null) => void
  spotLightAssetIds: number[]
  onClickSpotlightAssetId: (assetId: number) => void
  benchmarkSize: number
  onSetBenchmarkSize: (size: number) => void
  allowAdjustBenchmarkSize: boolean
}

export default function BenchmarkingTable({
  allowAdjustBenchmarkSize,
  assets,
  deselectedAssetIds,
  onSelectAssetId,
  hasChanges,
  missingDataAssetIds,
  onAddAssets,
  onReset,
  sort,
  onSort,
  onSearch,
  spotLightAssetIds,
  onClickSpotlightAssetId,
  benchmarkSize,
  onSetBenchmarkSize,
}: BenchmarkingTableProps) {
  const context = useBenchmarkingContext()
  const [openAddAssetsDialog, setOpenAddAssetsDialog] = useState(false)
  const [createBookmarkDialogOpen, setCreateBookmarkDialogOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const columns = useBenchmarkingColumns(
    deselectedAssetIds,
    missingDataAssetIds,
    spotLightAssetIds,
    onSelectAssetId,
    onClickSpotlightAssetId,
    context.showRankColumn || false
  )

  return (
    <StyledRoot ref={ref}>
      <CardHeader
        actions={
          <Stack
            direction={'row'}
            gap={1}>
            <SearchInput
              initialValue={''}
              onChange={onSearch}
            />

            <Button
              color={'primary'}
              onClick={() => setOpenAddAssetsDialog(true)}
              variant={'text'}>
              Add company
            </Button>

            <BenchmarkingTableMenu
              allowAdjustBenchmarkSize={allowAdjustBenchmarkSize}
              benchmarkSize={benchmarkSize}
              hasChanges={hasChanges}
              onReset={onReset}
              onSaveBookmark={() => setCreateBookmarkDialogOpen(true)}
              onSetBenchmarkSize={onSetBenchmarkSize}
            />
          </Stack>
        }
        title={'Benchmarking input'}
      />

      <StyledVirtualTable
        columns={columns}
        disablePaddingStart={true}
        onSort={onSort}
        RowComponentProps={({ row }) => {
          return {
            hover: false,
            className: classNames({
              [styledVirtualTableClasses.missingData]: missingDataAssetIds.includes(row.id),
              [styledVirtualTableClasses.targetAsset]: row.type === BenchmarkingItemType.Target,
            }),
          }
        }}
        rows={assets}
        sort={sort}
        tableEmptyProps={{
          message: 'Please change your search query',
          title: 'No companies',
        }}
        variant={'inline'}
      />

      <AddAssetsDialog
        alreadyAddedAssetIds={assets.map((asset) => asset.id)}
        buttonText={'Add companies'}
        onClose={() => setOpenAddAssetsDialog(false)}
        onSave={onAddAssets}
        open={openAddAssetsDialog}
        title={'Add companies to the benchmark'}
      />

      <BookmarksListCreateDialog
        assetIdsToAdd={assets
          .map((asset) => asset.id)
          .filter((assetId) => !deselectedAssetIds.includes(assetId))}
        dialogTitle={'Save selection as Bookmark'}
        infoSlot={
          <Alert
            icon={false}
            severity={'info'}>
            <Typography variant={'body2'}>
              All companies that are toggled to "Include" will be added to this bookmark list.
            </Typography>
          </Alert>
        }
        initialListTitle={`${context.name} - Benchmark`}
        onClose={() => setCreateBookmarkDialogOpen(false)}
        open={createBookmarkDialogOpen}
        disableRedirect
      />
    </StyledRoot>
  )
}
