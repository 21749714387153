import { InvestorFundListItem } from '@gain/rpc/app-model'
import { useState } from 'react'

import Card, { CardHeader, CardTabs } from '../../../../common/card/card'
import InvestorFundOverviewCardFundraisingTab from './investor-fund-overview-card-fundraising-tab'
import InvestorFundOverviewCardPerformanceTab from './investor-fund-overview-card-performance-tab'

interface InvestorFundOverviewCardProps {
  fund: InvestorFundListItem
}

enum InvestorFundOverviewTabs {
  Fundraising = 'fundraising',
  Performance = 'performance',
}

export default function InvestorFundOverviewCard({ fund }: InvestorFundOverviewCardProps) {
  const [activeTab, setActiveTab] = useState(InvestorFundOverviewTabs.Fundraising)

  const hasPerformanceInfo = Boolean(
    fund.grossIrr || fund.netIrr || fund.twr || fund.moic || fund.dpi || fund.rvpi || fund.tvpi
  )

  return (
    <Card fullHeight>
      <CardHeader title={'Overview'} />

      {hasPerformanceInfo && (
        <CardTabs
          onChange={(_, newValue) => setActiveTab(newValue)}
          options={[
            {
              label: 'Fundraising',
              value: InvestorFundOverviewTabs.Fundraising,
            },
            {
              label: 'Performance',
              value: InvestorFundOverviewTabs.Performance,
            },
          ]}
          value={activeTab}
        />
      )}

      {activeTab === InvestorFundOverviewTabs.Fundraising && (
        <InvestorFundOverviewCardFundraisingTab fund={fund} />
      )}

      {hasPerformanceInfo && activeTab === InvestorFundOverviewTabs.Performance && (
        <InvestorFundOverviewCardPerformanceTab fund={fund} />
      )}
    </Card>
  )
}
