import { useAssetList, useInvestorAssetRatings } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { AssetListItem, InvestorFundListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import Grid from '@mui/material/Grid2'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import Loading from '../../../common/loading'
import { MAX_TREEMAP_ITEMS_LIMIT } from '../../../features/asset/asset-analysis'
import { CompanyTreeMapChart, getDefaultAssetChartGroupType } from '../../../features/chart'
import InvestmentCriteriaCard from '../../../features/investment-criteria/investment-criteria-card'
import { useTrackPageView } from '../../../features/planhat/planhat-hooks'

interface RouteAssessmentProps {
  fund: InvestorFundListItem
}

export default function RouteAssessment({ fund }: RouteAssessmentProps) {
  const isXs = useIsXs()
  const swrRatings = useInvestorAssetRatings({
    fundId: fund.id,
    investorId: fund.investorId,
  })

  useTrackPageView('investor_fund', {
    id: fund.id,
    tab: 'assessment',
    requireTab: true,
  })

  const swrAssets = useAssetList({
    filter: listFilters<AssetListItem>(
      listFilter('ownerIds', '=', fund.investorId),
      listFilter('fundIds', '=', fund.id)
    ),
    sort: [listSort('revenueEur', 'desc')],
    limit: MAX_TREEMAP_ITEMS_LIMIT,
  })

  if (swrAssets.loading) {
    return <Loading />
  }

  return (
    <>
      <Head>
        <title>
          {fund.investorName} - {fund.strategyName || ''} - {fund.name} - Assessment
        </title>
      </Head>

      <Grid
        spacing={2}
        sx={{ alignSelf: 'stretch' }}
        container>
        <Grid size={12}>
          <Card sx={{ pb: 0 }}>
            <CardHeader
              title={'Overview'}
              absolute
            />
            <CardContent sx={{ pt: isXs ? 7 : 3 }}>
              <CompanyTreeMapChart
                assets={swrAssets.data.items}
                defaultGroupTypeId={getDefaultAssetChartGroupType(swrAssets.data.items)}
                height={400}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid size={12}>
          <InvestmentCriteriaCard
            emptyMessage={'No assessment data available for this investor fund'}
            rating={swrRatings.data}
            variant={'default'}
          />
        </Grid>
      </Grid>
    </>
  )
}
