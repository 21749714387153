import Typography from '@gain/components/typography'
import { hasGroupRatings, Rating, RatingGroup, RatingMap } from '@gain/utils/investment-criteria'
import { genericMemo } from '@gain/utils/react'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useMemo, useState } from 'react'

import ListRatingItem from './list-rating-item'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))

const StyledGroupContainer = styled('div', {
  shouldForwardProp: (prop) =>
    !['disableBorderBottom', 'noBorderRadiusTop'].includes(prop as string),
})<{
  disableBorderBottom?: boolean
  noBorderRadiusTop?: boolean
}>(({ theme, disableBorderBottom, noBorderRadiusTop }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,

  ...(disableBorderBottom && {
    borderBottom: 'none',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  }),

  ...(noBorderRadiusTop && {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  }),
}))

export interface ListRatingProps<D extends string> {
  rating: RatingMap<D>

  groups: RatingGroup<D>[]

  disableGroupLabel?: boolean

  // default = everything in separate groups
  // grouped = everything in one group
  variant?: 'default' | 'grouped'
}
const ListRating = genericMemo(function ListRating<D extends string>({
  rating: assetRating,
  disableGroupLabel,
  groups,
  variant = 'default',
}: ListRatingProps<D>) {
  const [expanded, setExpanded] = useState<Rating<D>['key'] | null>(null)

  const ratings = useMemo(
    () => groups.filter((group) => hasGroupRatings<D>(group, assetRating)),
    [assetRating, groups]
  )

  return (
    <StyledRoot>
      {ratings.map(
        (group, index) =>
          hasGroupRatings(group, assetRating) && (
            <Stack
              key={index}
              direction={'column'}
              spacing={1}>
              {variant === 'default' && !disableGroupLabel && (
                <Typography variant={'subtitle2'}>{group.label}</Typography>
              )}

              <StyledGroupContainer
                disableBorderBottom={index + 1 < ratings.length && variant === 'grouped'}
                noBorderRadiusTop={index > 0 && variant === 'grouped'}>
                {group.ratings.map(
                  (rating) =>
                    assetRating[rating.key] && (
                      <ListRatingItem
                        key={rating.key}
                        expanded={expanded === rating.key}
                        onCollapse={() => setExpanded(null)}
                        onExpand={() => setExpanded(rating.key)}
                        rating={rating}
                        value={assetRating[rating.key] || null}
                      />
                    )
                )}
              </StyledGroupContainer>
            </Stack>
          )
      )}
    </StyledRoot>
  )
})

export default ListRating
