import { BriefcaseMoneyIcon } from '@gain/components/icons'
import Head from '@gain/modules/head'
import {
  AssetListItem,
  FilterableInvestorFundListItem,
  InvestorFundListItem,
} from '@gain/rpc/app-model'
import { FILTERABLE_INVESTOR_FUND_LIST_ITEM_TO_ASSET_MAP } from '@gain/rpc/app-model'
import { listSort } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import Hidden from '@mui/material/Hidden'

import {
  MobileListItem,
  MobileListItemIcon,
  MobileListItemText,
  MobileListItemValue,
} from '../../common/list-mobile'
import Logo from '../../common/logo'
import { RowComponentProps } from '../../common/virtual-table'
import { AssetFilterField } from '../../features/asset/asset-filter-bar'
import ColumnPickerButton from '../../features/column-picker/column-picker-button'
import { useFilterModelQueryParam } from '../../features/filter/filter-bar'
import FinancialValue from '../../features/financial/financial-value'
import {
  INVESTOR_FUND_DEFAULT_FILTERS,
  INVESTOR_FUND_FILTER_MAP,
  INVESTOR_FUND_FILTER_MENU,
} from '../../features/investor-fund/investor-fund-filter-bar'
import { InvestorFundFilterField } from '../../features/investor-fund/investor-fund-filter-bar/filter-bar-investor-fund-config'
import filterToInvestorFundTableColumnMapping from '../../features/investor-fund/investor-fund-table/filter-to-investor-fund-table-column-mapping'
import { investorFundTableColumnNames } from '../../features/investor-fund/investor-fund-table/investor-fund-list-view-columns'
import useInvestorFundListViewColumns from '../../features/investor-fund/investor-fund-table/use-investor-fund-list-view-columns'
import ListView, { createListViewRowLink } from '../../features/list-view'
import useActiveFilterColumns from '../../features/list-view/use-active-filter-columns'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import MobilePageHeader from '../../layout/mobile-page-header'
import { generateInvestorFundPagePath, RouteInvestorFundTabs } from '../investor-fund'

const InvestorFundListPageInvestorFundRow = createListViewRowLink<
  'data.listInvestorFunds',
  InvestorFundListItem,
  FilterableInvestorFundListItem,
  InvestorFundFilterField,
  'data.listAssets',
  AssetListItem,
  AssetListItem,
  AssetFilterField
>(`/${RouteInvestorFundTabs.Portfolio}`, FILTERABLE_INVESTOR_FUND_LIST_ITEM_TO_ASSET_MAP)

export default function InvestorListPageFundTab() {
  const isXs = useIsXs()

  useTrackPageView('investor', { tab: 'funds', requireTab: true, list: true })

  const [filterModel] = useFilterModelQueryParam()
  const activeFilterColumns = useActiveFilterColumns(
    filterModel,
    INVESTOR_FUND_FILTER_MAP,
    investorFundTableColumnNames,
    filterToInvestorFundTableColumnMapping
  )
  const columns = useInvestorFundListViewColumns(activeFilterColumns)

  return (
    <>
      <Head>
        <title>Investors - Funds</title>
      </Head>
      {isXs && (
        <MobilePageHeader
          title={'Funds'}
          variant={'list'}
        />
      )}
      <ListView
        addFilterMenu={INVESTOR_FUND_FILTER_MENU}
        defaultFilterModel={INVESTOR_FUND_DEFAULT_FILTERS}
        defaultSort={[listSort<InvestorFundListItem>('vintageDate', 'desc')]}
        filterBarSearchLabel={'Fund name'}
        filterBarSearchPlaceholder={'E.g. Compass fund'}
        filterConfigMap={INVESTOR_FUND_FILTER_MAP}
        inline={isXs}
        method={'data.listInvestorFunds'}
        renderPageActions={() => (
          <Hidden mdDown>
            <ColumnPickerButton
              activeFilterColumns={activeFilterColumns}
              columnConfigId={'investorFund'}
            />
          </Hidden>
        )}
        sm={{
          variant: 'virtual-table',
          VariantProps: {
            columns: columns,
            RowComponentProps: ({ row }) =>
              ({
                component: InvestorFundListPageInvestorFundRow,
                to: generateInvestorFundPagePath(row),
              } as RowComponentProps),
          },
        }}
        xs={{
          variant: 'list',
          VariantProps: {
            headerProps: {
              title: 'Name',
              secondaryTitle: 'Latest fund size',
            },
            renderListItem: (item) => (
              <MobileListItem
                key={item.strategyId}
                disableDivider>
                <MobileListItemIcon>
                  <Logo
                    defaultIcon={<BriefcaseMoneyIcon />}
                    size={40}
                    src={item.investorLogoFileUrl}
                  />
                </MobileListItemIcon>
                <MobileListItemText
                  primary={item.strategyName}
                  secondary={item.investorName}
                />
                <MobileListItemValue>
                  <FinancialValue amount={item.fundSizeEur} />
                </MobileListItemValue>
              </MobileListItem>
            ),
          },
        }}
        disableOrFilter
      />
    </>
  )
}
