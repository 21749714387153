import { FilterableInvestorStrategyListItem } from '@gain/rpc/app-model'
import { useMemo } from 'react'

import useListViewColumnVisibilityModel from '../../list-view/use-list-view-column-visibility-model'
import {
  investorStrategyTableColumnNames,
  investorStrategyTableColumns,
} from './investor-strategy-table-columns'

export default function useInvestorStrategyListViewColumns(
  activeFilterColumns: (keyof FilterableInvestorStrategyListItem)[]
) {
  const visibleColumns = useListViewColumnVisibilityModel(
    'investorStrategy',
    investorStrategyTableColumnNames,
    activeFilterColumns
  )

  return useMemo(() => {
    return investorStrategyTableColumns.filter(({ field }) => visibleColumns[field])
  }, [visibleColumns])
}
