import Head from '@gain/modules/head'
import { AssetList, Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { useIsXs } from '@gain/utils/responsive'
import { generatePath } from 'react-router-dom'

import ConditionalWrapper from '../../../common/conditional-wrapper'
import RepresentativesListView from '../../../features/list-view/representative-list-view/representatives-list-view'
import MobilePageListViewContainer from '../../../layout/mobile-page-list-view-container'
import {
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../route-strategy-path'

interface RouteRepresentativesProps {
  investor: Investor
  strategy: InvestorStrategy
  assets: AssetList
}

export default function RouteRepresentatives({
  investor,
  strategy,
  assets,
}: RouteRepresentativesProps) {
  const isXs = useIsXs()
  const params = useInvestorStrategyPageParams()

  return (
    <>
      <Head>
        <title>
          {investor.name} - {strategy.name} - Representatives
        </title>
      </Head>

      <ConditionalWrapper
        condition={isXs}
        wrapper={(children) => (
          <MobilePageListViewContainer>{children}</MobilePageListViewContainer>
        )}>
        <RepresentativesListView
          assetsIds={assets.items.map(({ id }) => id)}
          investorId={investor.id}
          portfolioPath={generatePath(INVESTOR_STRATEGY_ASSETS_PAGE_PATH, params)}
        />
      </ConditionalWrapper>
    </>
  )
}
