import Typography from '@gain/components/typography'
import { CellValue, RowType, useFormattedValue } from '@gain/utils/table'
import { memo, ReactElement, ReactNode } from 'react'

import { VirtualTableVariant } from './virtual-table'
import { ColumnFieldConfig } from './virtual-table-model'

export interface VirtualTableBodyCellContentProps<Row extends RowType, Field extends keyof Row> {
  column: ColumnFieldConfig<Row, Field>
  row: Row
  rowIndex: number
  variant?: VirtualTableVariant
}

function InnerVirtualTableBodyCellContent<Row extends RowType, Field extends keyof Row>({
  column,
  row,
  rowIndex,
}: VirtualTableBodyCellContentProps<Row, Field>) {
  const formattedValue = useFormattedValue(column.field, row, rowIndex, column.valueFormatter)

  let result: ReactElement | ReactNode | CellValue = formattedValue
  if (column.renderCell) {
    result = column.renderCell({
      field: column.field,
      row,
      value: row[column.field],
      rowIndex,
      formattedValue: formattedValue,
    })
  }

  if (typeof result === 'string' || typeof result === 'number') {
    return (
      <Typography
        variant={'body2'}
        noWrap>
        {result}
      </Typography>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{result}</>
}

export const VirtualTableBodyCellContent = memo(
  InnerVirtualTableBodyCellContent
) as typeof InnerVirtualTableBodyCellContent
