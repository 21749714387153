import Head from '@gain/modules/head'
import { Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'

import { INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL } from '../../../features/investor-strategy/investor-strategy-contants'
import { ListViewAsset } from '../../../features/list-view'
import { useTrackPageView } from '../../../features/planhat/planhat-hooks'

export interface RouteCompaniesProps {
  investor: Investor
  strategy: InvestorStrategy
}

export default function RouteCompanies({ investor, strategy }: RouteCompaniesProps) {
  useTrackPageView('investor_strategy', { id: strategy.id, tab: 'assets', requireTab: true })

  return (
    <>
      <Head>
        <title>
          {investor?.name || ''} - {strategy.name || INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL}{' '}
          - Assets
        </title>
      </Head>

      <ListViewAsset
        defaultFilter={[
          listFilter('strategyIds', '=', strategy.id),
          listFilter('ownerIds', '=', investor.id),
        ]}
      />
    </>
  )
}
