import { range } from 'lodash'
import { Waypoint } from 'react-waypoint'

import { useHomeArticleListInfinite } from '../use-home-article-list-infinite'
import NewsFeedItem from './news-feed-item'
import NewsFeedItemArticleSkeleton from './news-feed-item-article-skeleton'
import { useNewsFeedItems } from './use-news-feed-items'

export default function NewsFeed() {
  const articles = useHomeArticleListInfinite()
  const items = useNewsFeedItems(articles.data)
  const skeletonRange = range(0, 10)

  return (
    <div>
      {items.map((item, index) => (
        <NewsFeedItem
          key={index}
          item={item}
        />
      ))}
      {articles.isLoadingMore &&
        skeletonRange.map((item) => <NewsFeedItemArticleSkeleton key={item} />)}
      {!articles.isLoadingInitial && !articles.isReachingEnd && (
        <Waypoint
          bottomOffset={-800}
          onEnter={articles.fetchMore}
        />
      )}
    </div>
  )
}
