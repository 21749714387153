import { useAssetList } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { InvestorFundListItem, TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'
import { createExternalLink } from '@gain/utils/common'
import Grid from '@mui/material/Grid2'
import { useHistory } from 'react-router-dom'

import ShareFeedbackBar from '../../../features/feedback/share-feedback-bar'
import { SHARE_FEEDBACK_INVESTOR_INFO } from '../../../features/feedback/share-feedback-info-constants'
import { useTrackPageView } from '../../../features/planhat/planhat-hooks'
import { generateInvestorFundPagePath } from '../route-fund-path'
import InvestorFundDealsCard from './investor-fund-deals-card'
import InvestorFundMetricsCard from './investor-fund-metrics-card'
import InvestorFundOverviewCard from './investor-fund-overview-card'
import InvestorFundOwnershipCard from './investor-fund-ownership-card'
import InvestorFundRegionCard from './investor-fund-region-card'
import InvestorFundSectorCard from './investor-fund-sector-card'

export interface RouteSummaryProps {
  fund: InvestorFundListItem
}

export default function RouteSummary({ fund }: RouteSummaryProps) {
  const history = useHistory()
  const swrAssets = useAssetList({
    page: 0,
    limit: 500,
    sort: [listSort('ebitdaEur', 'desc')],
    filter: listFilters(listFilter('fundIds', '=', fund.id)),
  })

  useTrackPageView('investor_fund', {
    id: fund.id,
    tab: 'summary',
    requireTab: true,
  })

  return (
    <Grid
      spacing={2}
      sx={{ alignSelf: 'stretch' }}
      container>
      <Head>
        <title>
          {fund.investorName} - {fund.strategyName || ''} - {fund.name} - Summary
        </title>
      </Head>

      <Grid size={12}>
        <InvestorFundMetricsCard fund={fund} />
      </Grid>

      <Grid size={{ xs: 12, lg: 4 }}>
        <InvestorFundOverviewCard fund={fund} />
      </Grid>

      <Grid
        size={{ xs: 12, lg: 8 }}
        spacing={2}
        container>
        <Grid size={{ xs: 12, lg: 6 }}>
          <InvestorFundRegionCard assets={swrAssets.data.items} />
        </Grid>

        <Grid size={{ xs: 12, lg: 6 }}>
          <InvestorFundSectorCard assets={swrAssets.data.items} />
        </Grid>

        <Grid size={{ xs: 12, lg: 6 }}>
          <InvestorFundOwnershipCard
            assets={swrAssets.data.items}
            fund={fund}
          />
        </Grid>

        <Grid size={{ xs: 12, lg: 6 }}>
          <InvestorFundDealsCard fund={fund} />
        </Grid>
      </Grid>

      <Grid size={12}>
        <ShareFeedbackBar
          attributes={[
            { type: TicketAttributeType.InvestorName, value: fund.investorName },
            { type: TicketAttributeType.InvestorFundName, value: fund.name },
            {
              type: TicketAttributeType.GainProInvestorUrl,
              value: createExternalLink(
                history.createHref({
                  pathname: generateInvestorFundPagePath({
                    id: fund.id,
                    name: fund.name,
                  }),
                })
              ),
            },
          ]}
          disabledInfoFields={[
            {
              label: 'Investor name',
              value: fund.investorName,
            },
            {
              label: 'Fund name',
              value: fund.name,
            },
          ]}
          infoDescriptions={SHARE_FEEDBACK_INVESTOR_INFO}
          title={'Give feedback on investor profile'}
          type={TicketType.ShareInvestorFundFeedback}
        />
      </Grid>
    </Grid>
  )
}
