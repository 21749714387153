import Head from '@gain/modules/head'
import { Asset, CurrencyListItem } from '@gain/rpc/app-model'
import {
  useConvertFinancialResults,
  useDefinedAmountTypes,
  useDefinedRatios,
} from '@gain/utils/financials'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import { useCallback, useState } from 'react'

import CardNotes from '../../../common/card/card-notes'
import FinancialsCard from '../../../features/financial/financials-card/financials-card'
import RatiosCard from '../../../features/financial/ratios-card/ratios-card'
import SourcesList from '../../../features/source/source-list'
import { useAssetSources } from '../route-asset-hooks'
import AssetLegalEntitiesCard from './asset-legal-entities-card'
import SimilarPerformanceCard from './asset-similar-performance-card/asset-similar-performance-card'

interface AssetFinancialsProps {
  asset: Asset
}

export default function AssetFinancials({ asset }: AssetFinancialsProps) {
  const sources = useAssetSources(asset.sources, 'financials')

  const usedAmountTypes = useDefinedAmountTypes(asset.financialResults)
  const usedRatios = useDefinedRatios(asset.financialResults)
  const hasRatios = usedRatios.hasReturnRatios || usedRatios.hasOtherRatios
  const [currency, setCurrency] = useState<CurrencyListItem | null>(null)
  const financials = useConvertFinancialResults(
    asset.financialResults,
    asset.generalInfo?.currency,
    currency?.name ?? 'EUR'
  )

  const handleCurrencyChange = useCallback((newCurrency: CurrencyListItem) => {
    setCurrency(newCurrency)
  }, [])

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Financials</title>
      </Head>

      <Grid
        spacing={2}
        container>
        {!!asset.financialResults.length && (
          <Grid
            xs={12}
            item>
            <FinancialsCard
              currency={currency}
              dataCurrency={asset.generalInfo?.currency}
              exportContainerProps={{
                method: 'data.exportAssetFinancials',
                params: {
                  id: asset.id,
                },
              }}
              financialResults={financials}
              onCurrencyChange={handleCurrencyChange}
            />
          </Grid>
        )}

        {usedAmountTypes.hasAIEstimates && (
          <>
            <Grid
              xs={12}
              item>
              <Alert
                icon={false}
                severity={'info'}>
                The table below gives <strong>contextual insight</strong> based on similar companies
                for which relevant comparative datapoints are available
              </Alert>
            </Grid>

            <Grid
              xs={12}
              item>
              <SimilarPerformanceCard
                currency={currency}
                dataCurrency={asset.generalInfo?.currency}
                onCurrencyChange={handleCurrencyChange}
                similarToAssetId={asset.id}
              />
            </Grid>
          </>
        )}

        {/*
          There are no relevant ratios for AI estimates other than the
          "Revenue / FTE" which is shown shown in the financials tab instead.
        */}
        {hasRatios && !usedAmountTypes.hasAIEstimates && (
          <Grid
            xs={12}
            item>
            <RatiosCard
              currency={currency}
              dataCurrency={asset.generalInfo?.currency}
              financialResults={financials}
              onCurrencyChange={handleCurrencyChange}
            />
          </Grid>
        )}

        {!!asset.financialFootnotes.length && (
          <Grid
            xs={12}
            item>
            <CardNotes notes={asset.financialFootnotes} />
          </Grid>
        )}

        {!!asset.legalEntities.length && (
          <Grid
            xs={12}
            item>
            <AssetLegalEntitiesCard
              legalEntities={asset.legalEntities}
              processedAnnualReportFileIds={asset.annualReports.map((ar) => ar.annualReportFileId)}
            />
          </Grid>
        )}

        {sources.length > 0 && (
          <Grid
            xs={12}
            item>
            <SourcesList sources={sources} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
