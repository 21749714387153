import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react'

import { AlertDialog, AlertDialogProps } from './alert-dialog'
import ConfirmDialog, { ConfirmDialogProps } from './confirm-dialog'

export type AlertDialogContextProps = Omit<AlertDialogProps, 'open'>

export type ConfirmDialogContextProps = Omit<ConfirmDialogProps, 'open' | 'onClose'>

export interface DialogContext {
  dialog: AlertDialogContextProps | ConfirmDialogContextProps | null
  isOpen: boolean
  openDialog: (alert: AlertDialogContextProps | ConfirmDialogContextProps) => void
  closeDialog: () => void
}

const DialogContext = createContext<DialogContext | undefined>(undefined)

function useDialogContext() {
  const context = useContext(DialogContext)

  if (!context) {
    throw new Error('DialogContext not provided')
  }

  return context
}

/**
 * Returns a callback function that can be used to open a dismissible
 * alert dialog. There can be only one alert dialog open at a time, any
 * new dialogs will be dropped.
 */
export function useOpenDialog() {
  const context = useDialogContext()
  return context?.openDialog
}

/**
 * Returns a callback function that can be used to open a dismissible
 * alert dialog. See `useOpenDialog` for more details.
 */
export function useAlertDialog() {
  const openDialog = useOpenDialog()

  return useCallback(
    (options: AlertDialogContextProps) => {
      openDialog(options)
    },
    [openDialog]
  )
}

/**
 * Returns an async callback that can be used to open a dismissible confirm
 * dialog. The result contains true if the message was confirmed, false
 * otherwise.
 *
 * Usage:
 *  const confirm = useConfirm()
 *
 *  const handleClick = async () => {
 *    if (await confirm('Are you sure you want to proceed?')) {
 *      // Execute code requiring confirmation
 *    }
 *  }
 */
export function useConfirmDialog() {
  const openDialog = useOpenDialog()

  return useCallback(
    async (options: ConfirmDialogContextProps) => {
      return new Promise((resolve) => {
        openDialog({
          ...options,
          onConfirm: () => resolve(true),
          onCancel: () => resolve(false),
        })
      })
    },
    [openDialog]
  )
}

function isConfirmDialog(
  dialog: AlertDialogContextProps | ConfirmDialogContextProps | null
): dialog is ConfirmDialogContextProps {
  return Boolean(dialog) && Object.prototype.hasOwnProperty.call(dialog, 'confirmText')
}

function GlobalAlertDialog() {
  const context = useDialogContext()

  const dialog = context.dialog
  if (!dialog || isConfirmDialog(dialog)) {
    return null
  }

  return (
    <AlertDialog
      {...dialog}
      onClose={(...args) => {
        context.closeDialog()
        dialog.onClose?.(...args)
      }}
      open={context.isOpen}
    />
  )
}

function GlobalConfirmDialog() {
  const context = useDialogContext()
  const dialog = context.dialog

  if (!isConfirmDialog(dialog)) {
    return null
  }

  return (
    <ConfirmDialog
      {...dialog}
      onCancel={() => {
        dialog.onCancel?.()
        context.closeDialog()
      }}
      onConfirm={() => {
        dialog.onConfirm?.()
        context.closeDialog()
      }}
      open={context.isOpen}
    />
  )
}

/**
 * The dialog provider manages a global dialog window that can
 * be used to display messages or prompts to the user.
 */
export default function DialogProvider({ children }: PropsWithChildren<unknown>) {
  const [isOpen, setIsOpen] = useState(false)
  const [dialog, setDialog] = useState<AlertDialogContextProps | ConfirmDialogContextProps | null>(
    null
  )

  const openDialog = useCallback(
    (alertDialogProps: AlertDialogContextProps | ConfirmDialogContextProps) => {
      // Ignore any new alerts if there is already one open
      if (!isOpen) {
        setDialog(alertDialogProps)
        setIsOpen(true)
      }
    },
    [isOpen]
  )

  const closeDialog = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <DialogContext.Provider value={{ dialog, isOpen, openDialog, closeDialog }}>
      {children}
      <GlobalAlertDialog />
      <GlobalConfirmDialog />
    </DialogContext.Provider>
  )
}
