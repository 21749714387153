import { TooltipProps } from '@mui/material/Tooltip'
import { ReactNode } from 'react'

import ChartTooltip from '../../../../common/chart/chart-tooltip'
import { SharePriceDatapoint } from './market-chart-types'
import SharePriceTooltipTitle from './share-price-tooltip-title'

type SharePriceTooltipProps = Omit<TooltipProps, 'open' | 'title' | 'followCursor'> & {
  sharePrice?: SharePriceDatapoint
  children: ReactNode
  currency: string
}

/**
 * SharePriceTooltip is a custom tooltip for the market data chart, with custom
 * opacity and shows the share price and date.
 */
export default function SharePriceTooltip({
  children,
  sharePrice,
  currency,
  ...props
}: SharePriceTooltipProps) {
  return (
    <ChartTooltip
      open={!!sharePrice}
      title={
        <SharePriceTooltipTitle
          currency={currency}
          sharePrice={sharePrice}
        />
      }
      followCursor
      {...props}>
      <div>{children}</div>
    </ChartTooltip>
  )
}
