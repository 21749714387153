import { kebabCase } from 'lodash'
import { useMemo } from 'react'
import { ExtractRouteParams } from 'react-router'
import { generatePath, useParams } from 'react-router-dom'

import { Params } from './path'

export interface InvestorPagePathParams extends Params {
  investorId: string
  investorName: string
  preview: string
}

export interface InvestorAdvisorDealsPagePathParams extends Params {
  advisorId: string
  advisorName: string
}

export interface InvestorPageParams {
  investorId: number | string
  investorName: string
  preview?: boolean
}

export const INVESTOR_SHARE_PARAM = 'share'

export function useInvestorPageParams() {
  const params = useParams<InvestorPagePathParams>()
  return useMemo(
    () => ({
      investorId: parseInt(params.investorId, 10),
      investorName: params.investorName,
      preview: params.preview === 'preview' ? true : undefined,
    }),
    [params]
  )
}

export function useInvestorAdvisorDealsPageParams() {
  const investorParams = useInvestorPageParams()
  const advisorParams = useParams<InvestorAdvisorDealsPagePathParams>()
  return useMemo(
    () => ({
      ...investorParams,
      advisorId: parseInt(advisorParams.advisorId, 10),
      advisorName: advisorParams.advisorName,
    }),
    [advisorParams, investorParams]
  )
}

export const INVESTOR_PAGE_PATH = '/owner/:investorId/:investorName/:preview?'

export function generateInvestorPagePath(
  { preview, investorName, ...params }: InvestorPageParams,
  path: string = INVESTOR_PAGE_PATH
) {
  return generatePath(path, {
    preview: preview ? 'preview' : undefined,
    investorName: kebabCase(`${investorName}`) || '-',
    ...params,
  })
}

export function generateInvestorAdvisorDealsPath(
  params: ExtractRouteParams<typeof INVESTOR_PAGE_ADVISOR_DEALS_PATH>
) {
  return generatePath(INVESTOR_PAGE_ADVISOR_DEALS_PATH, {
    ...params,
    investorName: kebabCase(`${params.investorName}`) || '-',
    advisorName: kebabCase(`${params.advisorName}`) || '-',
  })
}

export const INVESTOR_PAGE_SUMMARY_PATH = `${INVESTOR_PAGE_PATH}/summary`
export const INVESTOR_PAGE_STRATEGIES_PATH = `${INVESTOR_PAGE_PATH}/strategies`
export const INVESTOR_PAGE_FUNDS_PATH = `${INVESTOR_PAGE_PATH}/funds`
export const INVESTOR_PAGE_ASSESSMENT_PATH = `${INVESTOR_PAGE_PATH}/assessment`
export const INVESTOR_PAGE_BENCHMARKING_PATH = `${INVESTOR_PAGE_PATH}/benchmarking`
export const INVESTOR_PAGE_REPRESENTATIVES_PATH = `${INVESTOR_PAGE_PATH}/representatives`
export const INVESTOR_PAGE_ENTRIES_PATH = `${INVESTOR_PAGE_PATH}/entries`
export const INVESTOR_PAGE_ADVISOR_DEALS_PATH = `${INVESTOR_PAGE_PATH}/advisor-deals/:advisorId/:advisorName`
export const INVESTOR_PAGE_EXISTS_PATH = `${INVESTOR_PAGE_PATH}/exits`
export const INVESTOR_PAGE_ADD_ONS_PATH = `${INVESTOR_PAGE_PATH}/add-ons`
export const INVESTOR_PAGE_ADVISORS_PATH = `${INVESTOR_PAGE_PATH}/advisors`
export const INVESTOR_PAGE_PORTFOLIO_PATH = `${INVESTOR_PAGE_PATH}/portfolio`

export const generateInvestorPagePortfolioTabPath = (params: InvestorPageParams) =>
  generateInvestorPagePath(params, INVESTOR_PAGE_PORTFOLIO_PATH)

export const INVESTOR_PAGE_TABS = [
  INVESTOR_PAGE_SUMMARY_PATH,
  INVESTOR_PAGE_STRATEGIES_PATH,
  INVESTOR_PAGE_ASSESSMENT_PATH,
  INVESTOR_PAGE_REPRESENTATIVES_PATH,
  INVESTOR_PAGE_ADVISORS_PATH,
  INVESTOR_PAGE_ADVISOR_DEALS_PATH,
  INVESTOR_PAGE_ENTRIES_PATH,
  INVESTOR_PAGE_EXISTS_PATH,
  INVESTOR_PAGE_ADD_ONS_PATH,
  INVESTOR_PAGE_PORTFOLIO_PATH,
]
