import { InvestorFund } from '@gain/rpc/app-model'

import Table, { createTableColumns } from '../../../../common/table'
import FinancialValue from '../../../../features/financial/financial-value'

interface InvestorStrategyTableProps {
  rows: InvestorFund[]
}

const columns = createTableColumns<InvestorFund>(
  {
    field: 'name',
    headerName: 'Fund name',
    align: 'left',
    valueFormatter: ({ value }) => value || '-',
  },
  {
    field: 'fundSizeEur',
    headerName: 'Fund size',
    width: 120,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'vintageDate',
    headerName: 'Vintage date',
    width: 120,
    align: 'right',
    valueFormatter: ({ value }) => value?.year ?? '-',
  }
)

export default function InvestorStrategyFundsTable({ rows }: InvestorStrategyTableProps) {
  return (
    <Table
      columns={columns}
      rows={rows}
      dense
      disablePaddingBottomOnLastRow
      disableStickyHeader
    />
  )
}
