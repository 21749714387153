import { isDefined } from '@gain/utils/common'
import { useElementWidth } from '@gain/utils/dom'
import { useIsXs } from '@gain/utils/responsive'
import { useValueCellWidth } from '@gain/utils/table'
import Fade from '@mui/material/Fade'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import { useCallback, useEffect, useRef, useState } from 'react'

import ButtonScroll from '../button-scroll'
import HorizontalHeaderCell from './horizontal-table-cell'
import { HorizontalTableContext } from './horizontal-table-context'
import HorizontalTableRow from './horizontal-table-row'
import { RowConfig, RowGroupsConfig } from './horizontal-table-utils'

const StyledContainer = styled('div')({
  position: 'relative',
})

const StyledTableContainer = styled('div')({
  overflow: 'auto',
  position: 'relative',
})

const StyledTable = styled('table')({
  width: '100%',
  borderCollapse: 'initial',
  borderSpacing: 0,
})

const StyledTBody = styled('tbody')({
  '&:last-of-type tr:last-of-type th:first-of-type': {
    borderBottomLeftRadius: 8,
  },
})

const StyledButtonScroll = styled(ButtonScroll)(({ direction }) => ({
  top: 0,
  position: 'absolute',

  ...(direction === 'left' && {
    padding: '3px 0 3px 4px',
  }),

  ...(direction === 'right' && {
    right: 0,
    padding: '3px 4px 3px 0',
  }),
}))

interface StyledThProps {
  width: number
  paddingX: number
  borderRight?: boolean
  borderLeft?: boolean
  textAlign?: 'right'
}

const StyledTh = styled('th', {
  shouldForwardProp: (prop) =>
    !['width', 'paddingX', 'borderRight', 'borderLeft', 'textAlign', 'sx'].includes(
      prop.toString()
    ),
})<StyledThProps>(({ theme, width, paddingX, borderRight, borderLeft, textAlign }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
  height: 40,
  textAlign: textAlign || 'left',
  padding: theme.spacing(0, paddingX),
  boxSizing: 'border-box',
  minWidth: width,
  maxWidth: width,
  width: width,
  zIndex: 2,

  '&:first-of-type': {
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.common.white,
  },

  ...(borderRight && {
    borderRight: `1px solid ${theme.palette.divider}`,
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.09)',
    clipPath: 'inset(0px -15px 0px 0px)',
  }),

  ...(borderLeft && {
    borderLeft: `1px dashed ${theme.palette.divider}`,
  }),
}))

function getVisibleRowGroups<Row>(
  rowGroups: ReadonlyArray<RowGroupsConfig<Row>>,
  rows: ReadonlyArray<Row>,
  hideRowWhenEmpty = true
): ReadonlyArray<RowGroupsConfig<Row>> {
  const visibleGroups = rowGroups.reduce((acc, group) => {
    const hasDefinedValues = group.some((subGroup) => {
      return rows.some((row) => isDefined(subGroup.valueFn?.(row) || subGroup.renderFn))
    })

    if (hasDefinedValues) {
      acc.push(group)
    }

    return acc
  }, new Array<RowGroupsConfig<Row>>())

  if (!hideRowWhenEmpty) {
    return visibleGroups
  }

  return visibleGroups.map((group) => {
    return group.filter((subGroup) => {
      // If the row doesn't have a valueFn, it is used to only display a label.
      // We only show a label row when there is more than 1 visible group.
      if (!subGroup.valueFn && visibleGroups.length > 1) {
        return true
      }

      return rows.some((row) => isDefined(subGroup.valueFn?.(row)))
    })
  })
}

export interface HorizontalTableProps<Row> {
  label?: string
  rows: ReadonlyArray<Row>
  rowGroups: ReadonlyArray<RowGroupsConfig<Row>>
  rowConfig: RowConfig<Row>
}

export const horizontalTableClasses = generateUtilityClasses('HorizontalTable', [
  'root',
  'cell',
  'cellBold',
  'cellEstimate',
])

/**
 * Renders a horizontally scrollable table with customizable rows and row groups.
 */
export default function HorizontalTable<Data>({
  label,
  rows,
  rowGroups,
  rowConfig,
}: HorizontalTableProps<Data>) {
  const isXs = useIsXs()
  const rowSizing = rowConfig.getRowSizing(isXs)
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth(ref)
  const availableWidth = width - rowSizing.labelCellWidth
  const [isScrollStart, setIsScrollStart] = useState(true)
  const [isScrollEnd, setIsScrollEnd] = useState(false)
  const [canScroll, setCanScroll] = useState(false)
  const visibleRowGroups = getVisibleRowGroups(rowGroups, rows, rowConfig.hideRowWhenEmpty)

  const valueCellWidth = useValueCellWidth(
    availableWidth,
    rows.length,
    rowSizing.minValueCellWidth,
    rowSizing.maxValueCellWidth
  )

  useEffect(() => {
    if (ref.current) {
      setCanScroll(ref.current.scrollWidth > ref.current.clientWidth)
    }
  }, [width, ref])

  const handleScrollTo = useCallback(
    (direction: 'left' | 'right') => () => {
      if (ref.current) {
        const offset =
          direction === 'left'
            ? ref.current.scrollLeft - availableWidth
            : ref.current.scrollLeft + availableWidth

        ref.current.scrollTo({
          left: offset,
          behavior: 'smooth',
        })
      }
    },
    [ref, availableWidth]
  )

  const handleScroll = useCallback(() => {
    const element = ref.current

    if (!element) {
      return
    }

    setIsScrollStart(element.scrollLeft === 0)
    setIsScrollEnd(element.scrollLeft + element.clientWidth >= element.scrollWidth)
  }, [])

  return (
    <HorizontalTableContext.Provider value={rowSizing}>
      <StyledContainer className={horizontalTableClasses.root}>
        {!isXs && canScroll && (
          <>
            <Fade in={!isScrollStart}>
              <StyledButtonScroll
                direction={'left'}
                onClick={handleScrollTo('left')}
                sx={{
                  left: rowSizing.labelCellWidth,
                }}
              />
            </Fade>
            <Fade in={!isScrollEnd}>
              <StyledButtonScroll
                direction={'right'}
                onClick={handleScrollTo('right')}
              />
            </Fade>
          </>
        )}
        <StyledTableContainer
          ref={ref}
          onScroll={handleScroll}>
          <StyledTable>
            <thead>
              <tr>
                <StyledTh
                  borderRight={canScroll}
                  paddingX={rowSizing.cellPaddingX}
                  sx={{ pl: rowSizing.leftCellPaddingLeft }}
                  width={rowSizing.labelCellWidth}>
                  {label}
                </StyledTh>

                {rows.map((row) => (
                  <StyledTh
                    key={`head-${rowConfig.getRowId(row)}`}
                    id={`head-${rowConfig.getRowId(row)}`}
                    paddingX={rowSizing.cellPaddingX}
                    width={valueCellWidth}>
                    <HorizontalHeaderCell
                      row={row}
                      rowConfig={rowConfig}
                    />
                  </StyledTh>
                ))}
              </tr>
            </thead>
            {visibleRowGroups.map((visibleGroups, groupIndex) => (
              <StyledTBody key={groupIndex}>
                {visibleGroups.map((group, rowIndex) => (
                  <HorizontalTableRow
                    key={rowIndex}
                    bold={isDefined(group.bold) ? group.bold : rowIndex === 0}
                    divider={canScroll}
                    getRowId={rowConfig.getRowId}
                    group={group}
                    rows={rows}
                    thickBorders={group.thickBorders}
                  />
                ))}
              </StyledTBody>
            ))}
          </StyledTable>
        </StyledTableContainer>
      </StyledContainer>
    </HorizontalTableContext.Provider>
  )
}
