import Button from '@mui/material/Button'
import { DialogProps } from '@mui/material/Dialog'
import DialogActions, { dialogActionsClasses } from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import { MouseEvent, useCallback } from 'react'

import { StyledDialog } from './styled-dialog'

const StyledConfirmDialog = styled(StyledDialog)<DialogProps>({
  [`& .${dialogActionsClasses.root}`]: {
    justifyContent: 'space-evenly',
    '& > *': {
      flex: 1,
    },
  },
})

export interface ConfirmDialogProps {
  title: string
  message: string
  confirmText: string
  cancelText: string
  color: 'primary' | 'info' | 'error' | 'warning' | 'success'
  onConfirm?: () => void
  onCancel?: () => void
}

type ConfirmDialogWithRootProps = ConfirmDialogProps & Pick<DialogProps, 'open'>

export default function ConfirmDialog({
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  color,
  ...dialogProps
}: ConfirmDialogWithRootProps) {
  const handleCancel = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      onCancel?.()
    },
    [onCancel]
  )

  const handleConfirm = useCallback(() => {
    onConfirm?.()
  }, [onConfirm])

  return (
    <StyledConfirmDialog
      {...dialogProps}
      onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {message.split('\n').map((part, index) => (
          <DialogContentText key={index}>{part}</DialogContentText>
        ))}
      </DialogContent>

      <DialogActions>
        <Button
          color={'primary'}
          onClick={handleCancel}
          variant={'contained'}
          autoFocus>
          {cancelText}
        </Button>
        <Button
          color={color}
          onClick={handleConfirm}
          variant={'outlined'}
          autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </StyledConfirmDialog>
  )
}
