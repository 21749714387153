import { useAdvisorListItem, useInvestorListItem } from '@gain/api/app/hooks'
import { DealListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { decodeQueryParams, DelimitedNumericArrayParam } from 'serialize-query-params'

import { ListViewDeal } from '../../features/list-view'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import ListPageLayout from '../../layout/list-page-layout'
import { useInvestorAdvisorDealsPageParams } from '../utils'

/**
 * This is a list of deals that are associated with the investor a particular
 * advisor for both entries and exits.
 *
 * For the actual investor page we separate entries and exits into two separate tabs;
 * there is no consolidated deals page we can use.
 */
export default function InvestorAdvisorDeals() {
  const location = useLocation()
  const { investorId, advisorId } = useInvestorAdvisorDealsPageParams()

  const swrInvestor = useInvestorListItem(investorId)
  const swrAdvisor = useAdvisorListItem(advisorId)

  const investorName = swrInvestor.data?.name || ''
  const advisorName = swrAdvisor.data?.name || ''

  useTrackPageView('investor', { tab: 'deals', requireTab: true })

  // Currently we do not have "buyerAdvisorIds" or "sellerAdvisorIds" in the deal model,
  // hence we pass the deal ids explicitly.
  const searchParams = parse(location.search)
  const decoded = decodeQueryParams({ dealIds: DelimitedNumericArrayParam }, searchParams)
  if (!decoded.dealIds) {
    return null
  }

  const dealIds = decoded.dealIds.filter((item) => item !== null) as number[]
  const defaultFilters = listFilters<DealListItem>(listFilter('id', '=', dealIds))

  if (swrInvestor.loading || swrAdvisor.loading) {
    // The list view already shows a spinner so in order to avoid duplicate
    // spinners, we render an empty page while the investor and advisor are
    // loading
    return null
  }

  const title = `${investorName} deals - advised by ${advisorName}`

  return (
    <ListPageLayout title={title}>
      <ListViewDeal defaultFilter={defaultFilters} />
    </ListPageLayout>
  )
}
