import { isTruthy } from '@gain/utils/common'
import { kebabCase } from 'lodash'
import { stringify } from 'query-string'
import { useMemo } from 'react'
import { ExtractRouteParams } from 'react-router'
import { generatePath, useParams } from 'react-router-dom'
import { BooleanParam, encodeQueryParams, useQueryParam } from 'use-query-params'

import { ADVISOR_PAGE_PATH, Params } from './path'

export interface AdvisorPageParams extends Params {
  advisorId: string
  advisorName?: string
}

export interface GenerateAdvisorPagePathParams {
  advisorId: number | string
  advisorName?: string | null
  preview?: boolean
}

interface AdvisorAssetClientsPagePathParams extends Params {
  assetId: string
  assetName: string
}

interface AdvisorInvestorClientsPagePathParams extends Params {
  investorId: string
  investorName: string
}

export function useAdvisorPageParams(): {
  advisorId: number
  advisorName: string
  preview: boolean
} {
  const params = useParams<AdvisorPageParams>()
  const [preview] = useQueryParam('preview', BooleanParam)

  return useMemo(
    () => ({
      advisorId: parseInt(params.advisorId),
      advisorName: params.advisorName ? decodeURIComponent(params.advisorName) : '',
      preview: preview || false,
    }),
    [params, preview]
  )
}

export function generateAdvisorPagePath(
  { preview, advisorId, advisorName, ...params }: GenerateAdvisorPagePathParams,
  path = ADVISOR_PAGE_PATH
): string {
  const generatedPath = generatePath(path, {
    advisorId: advisorId,
    advisorName: kebabCase(`${advisorName}`) || undefined,
  })
  const queryParams = stringify(
    encodeQueryParams({ preview: BooleanParam }, { preview: params['preview'] || undefined })
  )

  return [generatedPath, queryParams].filter(isTruthy).join('?')
}

export function useAdvisorAssetClientsPageParams() {
  const advisorParams = useAdvisorPageParams()
  const clientParams = useParams<AdvisorAssetClientsPagePathParams>()
  return useMemo(
    () => ({
      ...advisorParams,
      assetId: parseInt(clientParams.assetId, 10),
    }),
    [advisorParams, clientParams]
  )
}

export function generateAdvisorAssetClientsPagePath(
  params: ExtractRouteParams<typeof ADVISOR_PAGE_CLIENTS_ASSETS_PATH>
): string {
  return generatePath(ADVISOR_PAGE_CLIENTS_ASSETS_PATH, {
    ...params,
    advisorName: kebabCase(`${params.advisorName}`) || undefined,
    assetName: kebabCase(`${params.assetName}`) || '-',
  })
}

export function useAdvisorInvestorClientsPageParams() {
  const advisorParams = useAdvisorPageParams()
  const clientParams = useParams<AdvisorInvestorClientsPagePathParams>()
  return useMemo(
    () => ({
      ...advisorParams,
      investorId: parseInt(clientParams.investorId, 10),
    }),
    [advisorParams, clientParams]
  )
}

export function generateAdvisorInvestorClientsPagePath(
  params: ExtractRouteParams<typeof ADVISOR_PAGE_CLIENTS_INVESTORS_PATH>
): string {
  return generatePath(ADVISOR_PAGE_CLIENTS_INVESTORS_PATH, {
    ...params,
    advisorName: kebabCase(`${params.advisorName}`) || undefined,
    investorName: kebabCase(`${params.investorName}`) || '-',
  })
}

export const ADVISOR_PAGE_SUMMARY_PATH = `${ADVISOR_PAGE_PATH}/summary`
export const ADVISOR_PAGE_DEALS_PATH = `${ADVISOR_PAGE_PATH}/deals`
export const ADVISOR_PAGE_CLIENTS_PATH = `${ADVISOR_PAGE_PATH}/clients`
export const ADVISOR_PAGE_CLIENTS_ASSETS_PATH = `${ADVISOR_PAGE_CLIENTS_PATH}/asset/:assetId/:assetName`
export const ADVISOR_PAGE_CLIENTS_INVESTORS_PATH = `${ADVISOR_PAGE_CLIENTS_PATH}/investor/:investorId/:investorName`
