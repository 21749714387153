import { createAction, createReducer } from '@reduxjs/toolkit'
import {
  createContext,
  Dispatch,
  JSXElementConstructor,
  PropsWithChildren,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import { createPortal } from 'react-dom'

type SlotState = Record<string, any>

export interface SlotContextType {
  state: SlotState
  dispatch: Dispatch<any>
}

export enum SlotName {
  Tabs = 'tabs',
  MobileBottom = 'mobile-bottom',
  // CMS
  Drawer = 'drawer',
}

export const SlotContext = createContext<SlotContextType | null>(null)
const updateSlotRef = createAction<{ slotName: string; ref: any }>('update_slot_ref')
const initialSlotState: SlotState = {}
const slotReducer = createReducer<SlotState>(initialSlotState, (builder) =>
  builder.addCase(updateSlotRef, (state, { payload }) => {
    state[payload.slotName] = payload.ref
  })
)

// this keeps state + context related logic in one single place (here)
export function SlotProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(slotReducer, initialSlotState)
  return <SlotContext.Provider value={{ state, dispatch }}>{children}</SlotContext.Provider>
}

export const useSlotContext = () => {
  const context = useContext(SlotContext)
  if (!context) {
    throw new Error('SlotContext not provided')
  }
  return context
}
// the logic to retrieve partial state from the conceptual "slot" state
export const useSlot = (slotName: string) => {
  const { state, dispatch } = useSlotContext()
  return [state[slotName], (ref: unknown) => dispatch(updateSlotRef({ ref, slotName }))] as const
}
export function SlotPortal({ slotName, children }: PropsWithChildren<{ slotName: SlotName }>) {
  const [slotRef] = useSlot(slotName)
  return slotRef ? createPortal(children as never, slotRef) : null
}

export interface SlotHomeProps {
  component?: string | JSXElementConstructor<any>
  slotName: SlotName
  componentProps?: any
}

export function SlotHome({
  slotName,
  component: Component = 'div',
  componentProps = {},
}: SlotHomeProps) {
  const [, setSlot] = useSlot(slotName)
  const setSlotMemo = useCallback(setSlot, [slotName])
  /**
   * this is a callback ref https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
   * to differentiate from normal ref, its need is explained in this section
   * https://reactjs.org/docs/hooks-reference.html#useref
   */
  const ref = useCallback(
    (node) => {
      setSlotMemo(node)
    },
    [setSlotMemo]
  )

  return (
    <Component
      ref={ref}
      id={slotName}
      {...componentProps}
    />
  )
}
