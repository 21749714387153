import { ListItemKey } from '@gain/rpc/list-model'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'
import { useEffect } from 'react'
import { useField } from 'react-final-form'

import { FilterCheckboxValue, FilterConfigCheckbox } from '../filter-config/filter-config-model'
import { useTrackFilterEvent } from '../use-track-filter-event'

const StyledFormControlLabel = styled(FormControlLabel)({
  display: 'flex',
  marginLeft: -8,
  minHeight: 32,
  alignItems: 'center',
})

export interface FilterCheckboxProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  path: string
  filter: FilterConfigCheckbox<Item, FilterField>
}

export default function FilterCheckbox<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ path, filter }: FilterCheckboxProps<Item, FilterField>) {
  const trackEvent = useTrackFilterEvent()
  const field = useField<FilterCheckboxValue, HTMLButtonElement, boolean>(path, {
    type: 'checkbox',
    format: (value) => !!value,
    parse: (value) => value || null,
  })

  useEffect(() => {
    if (field.input.checked) {
      trackEvent(`Filter ${filter.label} value change`, 'true')
    }
  }, [field.input.checked, filter.label, trackEvent])

  return (
    <StyledFormControlLabel
      control={<Checkbox {...field.input} />}
      label={filter.label}
    />
  )
}
