import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'

import DialogHeader from '../../common/dialog-header'

interface ShareFeedbackInfoDialogProps {
  open: boolean
  onClose: () => void
  descriptions: string[]
}

export default function ShareFeedbackInfoDialog({
  open,
  onClose,
  descriptions,
}: ShareFeedbackInfoDialogProps) {
  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={open}
      fullWidth>
      <DialogHeader
        onCloseClick={onClose}
        title={'Why would I share my insights?'}
      />
      <DialogContent>
        {descriptions.map((description, index) => (
          <Typography
            key={index}
            variant={'body2'}
            gutterBottom>
            {description}
          </Typography>
        ))}
      </DialogContent>
    </Dialog>
  )
}
