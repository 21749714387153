import Typography from '@gain/components/typography'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import Grid from '@mui/material/Grid'

import Card, { CardContent, CardHeader, CardProps } from '../../../../common/card/card'
import { KeyValueGridItem, useKeyValueGridKeys } from '../../../../common/key-value/key-value-grid'
import AssetOwnershipTypeExplainer from '../../../../features/asset/asset-ownership'
import AssetOwnershipUnverifiedChip from '../../../../features/asset/asset-ownership/asset-ownership-unverified-chip'

export interface CardAssetHistoryProps extends CardProps {
  history: string
  ownershipType?: AssetOwnershipType
  ownershipIsVerified?: boolean // When false it will show an unverified chip; defaults to true
}

export default function CardAssetHistory({
  history,
  ownershipType,
  ownershipIsVerified = true,
}: CardAssetHistoryProps) {
  const keyValues = useKeyValueGridKeys({ ownershipType }, [
    {
      field: 'ownershipType',
      label: (
        <>
          Type
          {ownershipType && !ownershipIsVerified && (
            <AssetOwnershipUnverifiedChip ownershipType={ownershipType} />
          )}
        </>
      ),
      renderValue: (data) => (
        <Typography variant={'body2'}>
          <AssetOwnershipTypeExplainer
            disableExplainer={!ownershipIsVerified}
            ownershipType={data.ownershipType}
          />
        </Typography>
      ),
    },
  ])

  return (
    <Card fullHeight>
      <CardHeader title={'Ownership'} />
      <CardContent>
        {keyValues.length > 0 && (
          <Grid
            spacing={2}
            container>
            {keyValues.map((keyValue) => (
              <KeyValueGridItem
                key={keyValue.field}
                keyValue={keyValue}
                xs={12}
              />
            ))}
          </Grid>
        )}

        <Typography
          variant={'body2'}
          paragraph>
          {history}
        </Typography>
      </CardContent>
    </Card>
  )
}
