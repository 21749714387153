import type { BookmarkListItem } from '@gain/rpc/app-model'
import Badge, { badgeClasses } from '@mui/material/Badge'
import { styled, Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const StyledBadge = styled(Badge)({
  [`& .${badgeClasses.badge}`]: {
    position: 'initial',
    transform: 'none',
  },
})

interface RecentFilterListItemUpdatesProps {
  list: BookmarkListItem | null
}

export default function RecentFilterListUpdatesBadge({ list }: RecentFilterListItemUpdatesProps) {
  const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  if (!list || list.updatedCount === 0) {
    return null
  }

  if (isLgDown) {
    return (
      <StyledBadge
        badgeContent={list.updatedCount}
        color={'error'}
        max={99}
      />
    )
  }

  return (
    <StyledBadge
      badgeContent={`${list.updatedCount >= 100 ? '99+' : list.updatedCount} updates`}
      color={'error'}
    />
  )
}
