import { CompanyIcon } from '@gain/components/icons'
import { CreditListItem } from '@gain/rpc/app-model'
import { useIsXs } from '@gain/utils/responsive'

import { MobileListItem, MobileListItemIcon, MobileListItemText } from '../../../common/list-mobile'
import Logo from '../../../common/logo'
import ListView, { ListViewProps } from '../core'
import {
  CREDIT_DEFAULT_FILTERS,
  CREDIT_FILTER_MAP,
  CREDIT_FILTER_MENU,
  CreditFilterField,
} from './credit-filter-bar-config'
import { creditTableColumns } from './credits-table-columns'

type CreditListViewProps = Pick<
  ListViewProps<'data.listCredits', CreditListItem, CreditListItem, CreditFilterField>,
  'defaultFilter'
>

export default function CreditListView({ defaultFilter }: CreditListViewProps) {
  const isXs = useIsXs()

  return (
    <ListView
      addFilterMenu={CREDIT_FILTER_MENU}
      defaultFilter={defaultFilter}
      defaultFilterModel={CREDIT_DEFAULT_FILTERS}
      defaultSort={[{ field: 'debtQuantumEur', direction: 'desc' }]}
      filterBarSearchLabel={'Company name'}
      filterBarSearchPlaceholder={'E.g. Amazon'}
      filterConfigMap={CREDIT_FILTER_MAP}
      inline={isXs}
      method={'data.listCredits'}
      sm={{
        variant: 'virtual-table',
        VariantProps: {
          columns: creditTableColumns,
          RowComponentProps: {
            hover: false,
          },
        },
      }}
      xs={{
        variant: 'list',
        VariantProps: {
          headerProps: {
            title: 'Company',
          },
          renderListItem: (item) => (
            <MobileListItem
              key={item.id}
              disableDivider>
              <MobileListItemIcon>
                <Logo
                  defaultIcon={<CompanyIcon />}
                  size={40}
                  src={item.assetLogoFileUrl}
                  fullSize
                />
              </MobileListItemIcon>
              <MobileListItemText primary={item.assetName} />
            </MobileListItem>
          ),
        },
      }}
      disableOrFilter
    />
  )
}
