import { useSearch } from '@gain/api/app/hooks'
import { SearchParams, SearchResultItem } from '@gain/rpc/app-model'
import { isNotNull } from '@gain/utils/typescript'
import List from '@mui/material/List'
import { styled, useTheme } from '@mui/material/styles'
import Tab, { tabClasses } from '@mui/material/Tab'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'

import { MobileSearchParams } from '../home-mobile'
import NoSearchResults from './no-search-results'
import SearchResultListItem from './search-result-list-item'
import SearchResultSkeleton from './search-result-skeleton'
import {
  SEARCH_MOBILE_HEADER_HEIGHT,
  SEARCH_RESULTS_MOBILE_TABS_HEIGHT,
} from './search-results-constants'
import TabPanel from './tab-panel'

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: 'sticky',
  top: SEARCH_MOBILE_HEADER_HEIGHT,
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: SEARCH_RESULTS_MOBILE_TABS_HEIGHT,
  zIndex: theme.zIndex.appBar,
  backgroundColor: theme.palette.background.paper,

  [`& .${tabsClasses.flexContainer}`]: {
    display: 'block',
  },

  [`& .${tabClasses.root}`]: {
    marginRight: 10,
    marginLeft: 10,

    '&:first-of-type': {
      marginLeft: `${theme.spacing(3)} !important`,
    },

    '&:last-of-type': {
      marginLeft: theme.spacing(2),
      marginRight: `${theme.spacing(3)} !important`,
    },
  },
}))

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

function useSearchArgs(
  query: string | null,
  params: Partial<Omit<SearchParams, 'limit' | 'query'>>
): SearchParams | null {
  return useMemo(() => {
    if (!query) {
      return null
    }

    return {
      assets: false,
      industries: false,
      investors: false,
      entities: false,
      advisors: false,
      conferenceEditions: false,
      limit: 30,
      query: query,
      ...params,
    }
  }, [query, params])
}

interface SearchResult {
  label: string
  items: SearchResultItem[]
}

function formatResults(label: string, items?: SearchResultItem[]): null | SearchResult {
  if (!items || items.length === 0) {
    return null
  }

  return {
    label,
    items,
  }
}

function useDebouncedQuery() {
  const params = useParams<MobileSearchParams>()
  const [query, setQuery] = useState<string | null>(null)

  useEffect(() => {
    const handle = setTimeout(() => {
      setQuery(params.query)
    }, 500)

    return () => {
      clearTimeout(handle)
    }
  }, [params.query])

  return query ? decodeURIComponent(query) : null
}

function searchResults(...results: Array<SearchResult | null>): Array<SearchResult> {
  return results.filter(isNotNull)
}

function useSearchResults() {
  const query = useDebouncedQuery()

  const top = useSearch(
    useSearchArgs(query, {
      assets: true,
      industries: true,
      investors: true,
      entities: true,
      advisors: true,
      conferenceEditions: true,
    })
  )
  const companies = useSearch(useSearchArgs(query, { assets: true }))
  const entities = useSearch(useSearchArgs(query, { entities: true }))
  const industries = useSearch(useSearchArgs(query, { industries: true }))
  const investors = useSearch(useSearchArgs(query, { investors: true }))
  const advisors = useSearch(useSearchArgs(query, { advisors: true }))
  const conferenceEditions = useSearch(useSearchArgs(query, { conferenceEditions: true }))

  return useMemo(() => {
    const loading =
      top.loading ||
      companies.loading ||
      entities.loading ||
      industries.loading ||
      investors.loading ||
      advisors.loading ||
      conferenceEditions.loading

    if (loading) {
      return {
        loading,
        data: [],
      }
    }

    return {
      loading,
      data: searchResults(
        formatResults('Top results', top.data),
        formatResults('Companies', companies.data),
        formatResults('Industries', industries.data),
        formatResults('Conferences', conferenceEditions.data),
        formatResults('Investors', investors.data),
        formatResults('Advisors', advisors.data),
        formatResults('Legal entities', entities.data)
      ),
    }
  }, [top, companies, entities, industries, investors, advisors, conferenceEditions])
}

export default function SearchResults() {
  const theme = useTheme()
  const params = useParams<MobileSearchParams>()
  const results = useSearchResults()
  const [activeTab, setActiveTab] = useState<number>(0)

  const handleChange = useCallback((event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }, [])

  const handleChangeIndex = useCallback((index: number) => {
    setActiveTab(index)

    window.scrollTo(0, 0)
  }, [])

  if (results.loading) {
    return <SearchResultSkeleton />
  }

  if (results.data.length === 0) {
    return <NoSearchResults query={params.query} />
  }

  return (
    <div>
      <StyledTabs
        onChange={handleChange}
        value={activeTab}
        variant={'scrollable'}>
        {results.data.map((result, index) => (
          <Tab
            key={index}
            label={result.label}
            {...a11yProps(index)}
          />
        ))}
      </StyledTabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeTab}
        onChangeIndex={handleChangeIndex}>
        {results.data.map((result, index) => (
          <TabPanel
            key={index}
            dir={theme.direction}
            index={index}
            value={activeTab}>
            <List disablePadding>
              {result.items.map((item) => (
                <SearchResultListItem
                  key={`${item.type}-${item.id}`}
                  item={item}
                />
              ))}
            </List>
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  )
}
