import List from '@mui/material/List'

import ColumnPickerColumnListItem from './column-picker-column-list-item'
import { Column } from './column-picker-model'
import { ColumnPickerAPI } from './use-column-picker'

export interface ColumnPickerColumnListProps {
  columns: Column[]
  api: ColumnPickerAPI
  activeFilterColumns: string[]
}

export default function ColumnPickerColumnList({
  columns,
  api,
  activeFilterColumns,
}: ColumnPickerColumnListProps) {
  const editableColumns = columns.filter((column) => column.canBeHiddenByUser)

  return (
    <List
      dense
      disablePadding>
      {editableColumns.map((column) => (
        <ColumnPickerColumnListItem
          key={column.name}
          activeFilterColumns={activeFilterColumns}
          api={api}
          label={column.label}
          name={column.name}
        />
      ))}
    </List>
  )
}
