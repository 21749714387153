import { ExternalLinkIcon } from '@gain/components/icons'
import Snackbar from '@gain/components/snackbar'
import Typography from '@gain/components/typography'
import { DealCloudItem, DealCloudStatus } from '@gain/rpc/app-model'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

import DealCloudProgress from './dealcloud-progress'
import DealCloudResults from './dealcloud-result'

const SNACKBAR_KEY = 'add-to-dealcloud'
const SNACKBAR_KEY_RESULTS = 'add-to-dealcloud-results'

export const showDealCloudProgress = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  assetIds: number[]
): SnackbarKey => {
  const assetIdsKey = `${SNACKBAR_KEY}-${assetIds.join('_')}`
  return enqueueSnackbar(undefined, {
    key: assetIdsKey,
    content: () => (
      <DealCloudProgress
        assetIds={assetIds}
        id={assetIdsKey}
        message={''}
      />
    ),
    preventDuplicate: true,
    persist: true,
  })
}

export const showDealCloudResults = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  dealCloudItems: DealCloudItem[]
): SnackbarKey => {
  if (dealCloudItems.length > 1) {
    return enqueueSnackbar(undefined, {
      key: SNACKBAR_KEY_RESULTS,
      content: () => (
        <DealCloudResults
          dealCloudItems={dealCloudItems}
          snackbarKey={SNACKBAR_KEY_RESULTS}
        />
      ),
      persist: true,
    })
  }

  const { url, status, assetId } = dealCloudItems[0]
  const message = (
    <Stack
      alignItems={'center'}
      direction={'row'}
      display={'flex'}
      gap={1}
      justifyContent={'center'}>
      <Typography
        color={'text.primary'}
        variant={'body2'}
        noWrap>
        {status === DealCloudStatus.Failed
          ? 'Failed to create profile'
          : status === DealCloudStatus.Created
          ? 'Company added'
          : 'Profile already exists'}
      </Typography>
      {status !== DealCloudStatus.Failed && (
        <Button
          color={'primary'}
          component={'a'}
          endIcon={<ExternalLinkIcon />}
          href={url}
          rel={'noopener noreferrer'}
          target={'_blank'}
          variant={'text'}>
          Click here to view
        </Button>
      )}
    </Stack>
  )

  return enqueueSnackbar(undefined, {
    key: SNACKBAR_KEY_RESULTS + assetId,
    content: () => (
      <Snackbar
        id={SNACKBAR_KEY_RESULTS + assetId}
        message={message}
        variant={'success'}
      />
    ),
    preventDuplicate: true,
    persist: true,
  })
}
