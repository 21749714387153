import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { ObjectListItemAvatar, ObjectListItemText } from '../../../../object-list-item'
import SearchTopResultSideInfo from './search-top-result-side-info'

export const StyledSubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  color: theme.palette.text.secondary,
}))

export const StyledDescription = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'maxLines',
})<{ maxLines?: number }>(({ theme, maxLines = 4 }) => ({
  ...theme.typography.body2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: maxLines,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.palette.text.primary,
  marginTop: theme.spacing(1.5),
}))

export default function SearchTopResultSkeleton() {
  return (
    <Stack
      direction={'row'}
      gap={4}>
      <Stack flex={1}>
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={1}>
          <Stack>
            <Skeleton>
              <ObjectListItemAvatar size={'big'} />
            </Skeleton>
          </Stack>

          <ObjectListItemText variant={'top-result'}>
            <Skeleton
              variant={'text'}
              width={150}>
              <Typography
                color={'textPrimary'}
                variant={'h5'}>
                &nbsp;
              </Typography>
            </Skeleton>

            <Skeleton
              height={14}
              width={100}>
              <StyledSubTitle>&nbsp;</StyledSubTitle>
            </Skeleton>
          </ObjectListItemText>
        </Stack>

        <Stack mt={1}>
          <TopResultLoadingLines />
        </Stack>
      </Stack>

      <SearchTopResultSideInfo>
        <TopResultLoadingSideInfo />
      </SearchTopResultSideInfo>
    </Stack>
  )
}

export function TopResultLoadingLines({ lines = 4 }: { lines?: number }) {
  return (
    <>
      {Array(lines)
        .fill(null)
        .map((_, index) => (
          <Skeleton
            key={index}
            width={'100%'}>
            <Typography
              color={'textPrimary'}
              variant={'body2'}>
              &nbsp;
            </Typography>
          </Skeleton>
        ))}
    </>
  )
}

export function TopResultLoadingSideInfo() {
  return (
    <List
      dense
      disablePadding>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <ListItem
            key={index}
            disableGutters
            disablePadding>
            <ListItemIcon>
              <Skeleton width={20} />
            </ListItemIcon>
            <Skeleton width={150}>
              <ListItemText> &nbsp;</ListItemText>
            </Skeleton>
          </ListItem>
        ))}
    </List>
  )
}
