import { SlotHome, SlotName } from '@gain/components/slot'
import Head from '@gain/modules/head'
import { useIsXs } from '@gain/utils/responsive'
import { ReactNode } from 'react'

import ConditionalWrapper from '../../common/conditional-wrapper'
import { ListPageActions } from '../../common/list-page/list-page-actions'
import { ListPageHeaderContainer } from '../../common/list-page/list-page-header-container'
import ListPageTitle from '../../common/list-page/list-page-title'
import MainContainer from '../../common/main-container'
import MobilePageContainer from '../mobile-page-container'
import MobilePageHeader from '../mobile-page-header'

interface ListPageLayoutProps {
  title: string
  children: ReactNode
  header?: ReactNode
}

/**
 * Layout for list pages is a 100% wide container with a title and a slot for tabs.
 */
export default function ListPageLayout({ title, header, children }: ListPageLayoutProps) {
  const isXs = useIsXs()

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      <ConditionalWrapper
        condition={!isXs}
        wrapper={(wrappedChildren) => (
          <MainContainer fluid>
            <ListPageHeaderContainer>
              <ListPageTitle>{header ? header : title}</ListPageTitle>
              <ListPageActions>
                <SlotHome slotName={SlotName.Tabs} />
              </ListPageActions>
            </ListPageHeaderContainer>
            {wrappedChildren}
          </MainContainer>
        )}
        wrapperWhenFalse={(wrappedChildren) => (
          <MobilePageContainer>
            <MobilePageHeader
              title={title}
              variant={'list'}
            />
            {wrappedChildren}
          </MobilePageContainer>
        )}>
        {children}
      </ConditionalWrapper>
    </>
  )
}
