import { useAssetList, useInvestorAssetRatings } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { listFilter, listSort } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import Grid from '@mui/material/Grid2'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import Loading from '../../../common/loading'
import { MAX_TREEMAP_ITEMS_LIMIT } from '../../../features/asset/asset-analysis'
import { CompanyTreeMapChart, getDefaultAssetChartGroupType } from '../../../features/chart'
import InvestmentCriteriaCard from '../../../features/investment-criteria/investment-criteria-card'
import { useTrackPageView } from '../../../features/planhat/planhat-hooks'

interface RouteAssessmentProps {
  investor: Investor
  strategy: InvestorStrategy
}

export default function RouteAssessment({ investor, strategy }: RouteAssessmentProps) {
  const isXs = useIsXs()
  const swrRatings = useInvestorAssetRatings({
    investorId: investor.id,
    strategyId: strategy.id,
  })

  useTrackPageView('investor_strategy', {
    id: strategy.id,
    tab: 'assessment',
    requireTab: true,
  })

  const swrAssets = useAssetList({
    filter: [listFilter('strategyIds', '=', strategy.id)],
    sort: [listSort('revenueEur', 'desc')],
    limit: MAX_TREEMAP_ITEMS_LIMIT,
  })

  if (swrAssets.loading) {
    return <Loading />
  }

  return (
    <>
      <Head>
        <title>
          {investor.name} - {strategy.name} - Assessment
        </title>
      </Head>

      <Grid
        spacing={2}
        sx={{ alignSelf: 'stretch' }}
        container>
        <Grid size={12}>
          <Card sx={{ pb: 0 }}>
            <CardHeader
              title={'Overview'}
              absolute
            />
            <CardContent sx={{ pt: isXs ? 7 : 3 }}>
              <CompanyTreeMapChart
                assets={swrAssets.data.items}
                defaultGroupTypeId={getDefaultAssetChartGroupType(swrAssets.data.items)}
                height={400}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid size={12}>
          <InvestmentCriteriaCard
            emptyMessage={'No assessment data available for this investor strategy'}
            rating={swrRatings.data}
            variant={'default'}
          />
        </Grid>
      </Grid>
    </>
  )
}
