import { useTheme } from '@mui/material/styles'

export default function OutlierIcon() {
  const theme = useTheme()
  return (
    <svg
      fill={'none'}
      height={'8'}
      viewBox={'0 0 9 8'}
      width={'9'}
      xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={'M4.33003 6.75L0.865927 0.749999L7.79413 0.75L4.33003 6.75Z'}
        fill={'white'}
        stroke={theme.palette.text.secondary}
      />
    </svg>
  )
}
