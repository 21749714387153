import { UserIcon } from '@gain/components/icons'
import { ObjectType } from '@gain/rpc/shared-model'
import { formatAssetManager } from '@gain/utils/asset'
import { formatManagerPositionSince } from '@gain/utils/manager'

import LinkedInButton from '../../../../common/linked-in-button/linked-in-button'
import ListItem from '../../../../common/list/list-item'
import RequestEmailButton from '../../../../features/request-email-button/request-email-button'
import { generateInvestorPagePath } from '../../../utils'
import { ManagerWithInvestor } from './asset-management-util'

function formatSecondaryLine(manager: ManagerWithInvestor) {
  if (manager.investor) {
    return manager.investor.name
  }

  return formatManagerPositionSince(manager.managementPosition, manager.joinYear, manager.joinMonth)
}

interface ManagementListItemProps {
  manager: ManagerWithInvestor
}

export default function ManagementListItem({ manager }: ManagementListItemProps) {
  // Gather required props for `RequestEmailButton`
  let sourceType = ObjectType.Asset
  let sourceId = manager.assetId
  if (manager.investor) {
    sourceType = ObjectType.Investor
    sourceId = manager.investor.id
  }

  return (
    <ListItem
      action={
        <>
          {manager.person && (
            <RequestEmailButton
              objectId={sourceId}
              objectType={sourceType}
              person={manager.person}
            />
          )}
          <LinkedInButton url={manager.person?.linkedInUrl} />
        </>
      }
      avatarProps={{
        children: <UserIcon />,
      }}
      href={
        manager.investor &&
        generateInvestorPagePath({
          investorId: manager.investor.id,
          investorName: manager.investor.name,
        })
      }
      primary={formatAssetManager(manager)}
      region={manager.person?.locationRegion}
      secondary={formatSecondaryLine(manager)}
    />
  )
}
