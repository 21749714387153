import { formatThousands } from '@gain/utils/number'
import Chip, { ChipProps } from '@mui/material/Chip'
import Fade from '@mui/material/Fade'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

const listItemCountChipClasses = generateUtilityClasses('ListItemCountChipClasses', [
  'outlined',
  'warning',
])

const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(-1),

  [`&.${listItemCountChipClasses.outlined}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`&.${listItemCountChipClasses.warning}`]: {
    color: theme.palette.warning.main,
  },
}))

type ListItemCountChipProps = Omit<ChipProps, 'label'> & {
  total: number
  maxLimit?: number
  maxLimitTooltip?: string
  hide?: boolean
}

function formatCount(value: null | number) {
  if (value === null) {
    return '-'
  }

  if (value >= 10_000) {
    return formatThousands(value)
  }

  return value.toString(10)
}

export function ListItemCountChip({
  total,
  hide,
  variant = 'outlined',
  maxLimit,
  maxLimitTooltip,
  className,
  ...props
}: ListItemCountChipProps) {
  const [label, setLabel] = useState('')

  const exceededMax = !!maxLimit && total > maxLimit

  useEffect(() => {
    if (total > 0 && !hide) {
      setLabel(formatCount(exceededMax ? maxLimit : total))
    }
  }, [exceededMax, setLabel, total, hide, maxLimit])

  return (
    <Fade in={total > 0 && !hide}>
      <Tooltip
        placement={'top'}
        title={exceededMax ? maxLimitTooltip : ''}
        disableInteractive>
        <StyledChip
          label={label}
          variant={variant}
          {...props}
          className={clsx(className, {
            [listItemCountChipClasses.outlined]: variant === 'outlined',
            [listItemCountChipClasses.warning]: exceededMax,
          })}
        />
      </Tooltip>
    </Fade>
  )
}
