import { AssetList, Investor } from '@gain/rpc/app-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { useMemo } from 'react'

import Card from '../../../../common/card/card'
import CardMetrics, { createMetrics } from '../../../../common/metrics/metrics-card'
import {
  generateInvestorPagePath,
  INVESTOR_PAGE_ENTRIES_PATH,
  INVESTOR_PAGE_FUNDS_PATH,
  INVESTOR_PAGE_PORTFOLIO_PATH,
  useInvestorPageParams,
} from '../../../utils'
import { DRY_POWDER_EXPLAINER } from '../../investor-profile-util'
import { useDryPowder, useInvestorDealCount, useMedianEbitda } from './investor-metrics-card-hooks'

interface InvestorMetricsCardProps {
  investor: Investor
  assets: AssetList
}

export default function InvestorMetricsCard({ investor, assets }: InvestorMetricsCardProps) {
  const formatCurrency = useFormatCurrencyCallback()
  const investorPageParams = useInvestorPageParams()
  const dealCount = useInvestorDealCount(investor.id)
  const dryPowderRange = useDryPowder(investor)
  const medianEbitda = useMedianEbitda(assets)

  const metrics = useMemo(() => {
    return createMetrics(
      {
        href: generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_PORTFOLIO_PATH),
        label: 'Companies',
        value: assets.counts.filtered,
      },
      {
        href:
          !!dealCount && generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_ENTRIES_PATH),
        label: 'Platform deals (L5Y)',
        value: dealCount || 0,
      },
      {
        href: generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_FUNDS_PATH),
        label: 'Total live fund size',
        value: formatCurrency(investor.liveFundSizeEur),
      },
      typeof investor.fundsRaisedLastFiveYears === 'number' &&
        investor.fundsRaisedLastFiveYears > 0 && {
          href: generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_FUNDS_PATH),
          label: 'Fundraising (L5Y)',
          value: formatCurrency(investor.fundsRaisedLastFiveYears),
        },
      dryPowderRange !== null && {
        href: generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_FUNDS_PATH),
        label: 'Drypowder est.',
        value: dryPowderRange,
        explainer: DRY_POWDER_EXPLAINER,
      },
      medianEbitda !== null && {
        label: 'Median EBITDA',
        value: formatCurrency(medianEbitda),
        explainer:
          'Median EBITDA is calculated using only positive EBITDA values, to give a better representation of typical investment size',
      }
    )
  }, [
    investorPageParams,
    assets.counts.filtered,
    dealCount,
    investor.fundsRaisedLastFiveYears,
    investor.liveFundSizeEur,
    formatCurrency,
    dryPowderRange,
    medianEbitda,
  ])

  return (
    <Card sx={{ pb: 0 }}>
      <CardMetrics metrics={metrics} />
    </Card>
  )
}
