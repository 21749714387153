import { useUserPermissionsByObject } from '@gain/api/app/hooks'
import {
  Edit3Icon,
  LinkIcon,
  LogOutIcon,
  SearchIcon,
  SlidersIcon,
  Trash2Icon,
  UsersIcon,
} from '@gain/components/icons'
import {
  AssetListItem,
  BookmarkListItem,
  UserPermissionObjectType,
  UserPermissionRole,
} from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import { useDialogState } from '@gain/utils/dialog'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import { useState } from 'react'

import { useAddBookmarks } from '../../../api/bookmark-hooks'
import {
  isAssetBookmarkList,
  isLegacyCustomAssetList,
  useIsBookmarkListOwner,
  useUpdateBookmarkList,
} from '../../../api/bookmark-list-hooks'
import { MenuDivider } from '../../../common/menu-divider'
import AddAssetsDialog from '../../add-assets-dialog'
import { useShareContext } from '../../share-dialog/share-dialog-provider'
import BookmarkListUrlImportDialog from '../bookmark-list-url-import/bookmark-list-url-import-dialog'
import BookmarksListFiltersDialog from './bookmarks-list-filters-dialog'
import BookmarkListRenameDialog from './bookmarks-list-rename-dialog'
import useDeleteBookmarkListConfirm from './use-delete-bookmark-list-confirm'
import useLeaveBookmarkListConfirm from './use-leave-bookmark-list-confirm'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  gap: theme.spacing(1.75),
}))

const StyledDeleteMenuItem = styled(StyledMenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  '&:hover, &:focus': {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
  },
}))

interface BookmarkEditMenuProps extends MenuProps {
  anchorEl: MenuProps['anchorEl']
  list: BookmarkListItem
  onClose: () => void
  onExited?: () => void
  open: boolean

  // disableEditContent hides the "Add company" and "Change filters" menu options
  disableEditContent?: boolean
}

export default function BookmarksListEditMenu({
  anchorEl,
  disableEditContent = false,
  list,
  onExited,
  onClose,
  open,
  ...props
}: BookmarkEditMenuProps) {
  const { openShareDialog } = useShareContext()
  const [isRenameDialogOpen, openRenameDialog, closeRenameDialog] = useDialogState()
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useDialogState()
  const [urlImportDialogOpen, setUrlImportDialogOpen] = useState(false)
  const deleteList = useDeleteBookmarkListConfirm(list)
  const leaveList = useLeaveBookmarkListConfirm(list)
  const updateList = useUpdateBookmarkList()
  const addBookmarks = useAddBookmarks()
  const isOwner = useIsBookmarkListOwner(list.id)
  const swrPermissions = useUserPermissionsByObject({
    objectType: UserPermissionObjectType.BookmarkList,
    objectId: list.id,
  })

  const handleShare = () => {
    onClose()
    openShareDialog(UserPermissionObjectType.BookmarkList, list.id)
  }

  const handleEdit = () => {
    onClose()
    openEditDialog()
  }

  const handleUrlImport = () => {
    onClose()
    setUrlImportDialogOpen(true)
  }

  const handleRename = () => {
    onClose()
    openRenameDialog()
  }

  const handleDelete = () => {
    onClose()
    deleteList()
  }

  const handleLeave = () => {
    onClose()
    leaveList()
  }

  const handleSubmitEditFilters = async (filters: ListFilter<AssetListItem>[] | null) => {
    await updateList({ id: list.id, filters: filters ?? [] })
  }

  const allowEdit = isOwner && !disableEditContent
  const isLegacyAssetList = isLegacyCustomAssetList(list)

  // An owner is only allowed to leave if there are other owners remaining
  const nrOfOwners =
    swrPermissions.data?.filter(({ role }) => role === UserPermissionRole.Owner).length ?? 0
  const allowLeaveList = !isOwner || nrOfOwners > 1

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={onClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 0,
        }}
        {...props}>
        {isOwner && (
          <StyledMenuItem onClick={handleRename}>
            <Edit3Icon /> Rename
          </StyledMenuItem>
        )}

        <StyledMenuItem onClick={handleShare}>
          <UsersIcon /> Share with others
        </StyledMenuItem>

        {allowEdit && isLegacyAssetList && (
          <StyledMenuItem onClick={handleEdit}>
            <SearchIcon /> Add company by name
          </StyledMenuItem>
        )}

        {allowEdit && isLegacyAssetList && (
          <StyledMenuItem onClick={handleUrlImport}>
            <LinkIcon /> Add company by URL
          </StyledMenuItem>
        )}

        {allowEdit && !isLegacyAssetList && (
          <StyledMenuItem onClick={handleEdit}>
            <SlidersIcon /> Change filters
          </StyledMenuItem>
        )}

        <MenuDivider />

        {isOwner && (
          <StyledDeleteMenuItem onClick={handleDelete}>
            <Trash2Icon color={'error'} /> Delete list
          </StyledDeleteMenuItem>
        )}

        {allowLeaveList && (
          <StyledDeleteMenuItem onClick={handleLeave}>
            <LogOutIcon color={'error'} /> Leave list
          </StyledDeleteMenuItem>
        )}
      </Menu>

      <BookmarkListRenameDialog
        list={list}
        onClose={closeRenameDialog}
        open={isRenameDialogOpen}
      />

      {!disableEditContent && isLegacyAssetList && (
        <>
          <AddAssetsDialog
            onClose={closeEditDialog}
            onSave={(assetIds: number[]) => addBookmarks(list, assetIds)}
            open={isEditDialogOpen}
            title={'Add companies to the list'}
          />
          <BookmarkListUrlImportDialog
            listId={list.id}
            onClose={() => setUrlImportDialogOpen(false)}
            open={urlImportDialogOpen}
          />
        </>
      )}

      {!disableEditContent && !isLegacyAssetList && isAssetBookmarkList(list) && (
        <BookmarksListFiltersDialog
          dialogTitle={'Filters applied'}
          list={list}
          onClose={closeEditDialog}
          onSubmit={handleSubmitEditFilters}
          open={isEditDialogOpen}
        />
      )}
    </>
  )
}
