import { BookmarkIcon, MoreVerticalIcon, SlidersIcon } from '@gain/components/icons'
import { BookmarkListItem } from '@gain/rpc/app-model'
import Badge, { badgeClasses } from '@mui/material/Badge'
import IconButton, { iconButtonClasses } from '@mui/material/IconButton'
import { listItemButtonClasses } from '@mui/material/ListItemButton'
import { alpha, styled, useTheme } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { MouseEvent } from 'react'
import { generatePath } from 'react-router-dom'
import { useIntersection } from 'react-use'

import { isLegacyCustomAssetList } from '../../../api/bookmark-list-hooks'
import { BOOKMARKS_FILTERED_PATH, BOOKMARKS_LIST_PATH } from '../../../routes/utils'
import { NavMenuListItem } from '../nav-menu-list-item'

const StyledBadge = styled(Badge)(({ theme }) => ({
  marginLeft: theme.spacing(-1.25),
  scrollMarginBottom: theme.spacing(3),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: 24,
  width: 24,
  display: 'none',
  lineHeight: 0,

  [`& .${svgIconClasses.root}`]: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
}))

const StyledNavMenuListItem = styled(NavMenuListItem, {
  shouldForwardProp: (prop) => prop !== 'showActive',
})<{ showActive: boolean }>(({ theme, showActive }) => ({
  '&:hover, &:focus': {
    // To ensure that the :hover effect is applied to the entire button instead of
    // just the <a/> element, you need to style the parent container that contains
    // both the menu item anchor (<a/>) and the secondary action. Since the parent
    // container is currently unstyled, the :hover effect is disabled on the
    // listItemButton and the style is applied to the parent element instead. That
    // way the :hover effect is triggered for the whole button regardless of whether
    // the listItemButton or the secondary action is hovered over.
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: theme.shape.borderRadius,

    [`& .${listItemButtonClasses.root}`]: {
      backgroundColor: 'transparent',
    },
    [`& .${iconButtonClasses.root}`]: {
      display: 'block',
    },
    [`& .${badgeClasses.badge}`]: {
      display: 'none',
    },
  },
  ...(showActive && {
    '&': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      borderRadius: theme.shape.borderRadius,
    },
    [`& .${iconButtonClasses.root}`]: {
      display: 'block',
    },
    [`& .${badgeClasses.badge}`]: {
      display: 'none',
    },
  }),
}))

interface BookmarksMenuItemProps {
  list: BookmarkListItem
  onLastUpdatedListIntersection?: (element: Element, inView: boolean) => void
  open: boolean
  onOpen: (event: MouseEvent<HTMLButtonElement>, list: BookmarkListItem) => void
}

function BookmarksMenuItem({
  list,
  onLastUpdatedListIntersection,
  open,
  onOpen,
}: BookmarksMenuItemProps) {
  const theme = useTheme()
  const moreMenuRef = useRef(null)
  const [showActive, setShowActive] = useState(false)

  const intersectionRef = useRef<HTMLSpanElement>(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })

  useEffect(() => {
    if (intersection && onLastUpdatedListIntersection && intersectionRef.current) {
      return onLastUpdatedListIntersection(
        intersectionRef.current,
        intersection.intersectionRatio > 0
      )
    }
  }, [intersection, onLastUpdatedListIntersection])

  const isAssetList = isLegacyCustomAssetList(list)
  const path = isAssetList ? BOOKMARKS_LIST_PATH : BOOKMARKS_FILTERED_PATH

  // When user closes the menu an animation plays; during this animation
  // no :hover is registered and the background styling is cleared. To avoid
  // this we delay changing `showActive` to false
  useEffect(() => {
    if (open) {
      setShowActive(true)
      return undefined
    }

    const timeoutId = setTimeout(() => setShowActive(false), theme.transitions.duration.standard)
    return () => clearInterval(timeoutId)
  }, [open, theme.transitions.duration.standard])

  const icon = useMemo(() => {
    return isLegacyCustomAssetList(list) ? <BookmarkIcon /> : <SlidersIcon />
  }, [list])

  return (
    <StyledNavMenuListItem
      key={`${list.type}-${list.id}`}
      icon={icon}
      label={list.title || '-'}
      secondaryAction={
        <>
          <StyledIconButton
            ref={moreMenuRef}
            color={'primary'}
            edge={'end'}
            onClick={(event) => onOpen(event, list)}
            size={'small'}
            sx={{ marginRight: list.updatedCount > 0 ? '7px' : '-3px' }}>
            <MoreVerticalIcon />
          </StyledIconButton>

          {list.updatedCount > 0 && (
            <StyledBadge
              ref={intersectionRef ? intersectionRef : null}
              badgeContent={list.updatedCount || 0}
              color={'error'}
              max={99}
            />
          )}
        </>
      }
      showActive={showActive}
      to={generatePath(path, { listId: list.id })}
      data-hj-suppress
    />
  )
}

export default memo(BookmarksMenuItem)
