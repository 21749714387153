import { ChevronDownIcon } from '@gain/components/icons'
import { ListItemKey } from '@gain/rpc/list-model'
import Button, { buttonClasses } from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import Tooltip from '@mui/material/Tooltip'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { useController, useWatch } from 'react-hook-form'

import { FilterConfigAutocomplete } from '../../filter-config/filter-config-model'
import { AutocompleteFormSchemaType, AutocompleteIncludeMode } from './filter-autocomplete'

const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  padding: theme.spacing(0, 0.5),
  borderRadius: 4,
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
  [`& .${buttonClasses.startIcon}`]: {
    marginRight: theme.spacing(0.5),
  },
  [`& .${svgIconClasses.root}`]: {
    color: 'inherit',
  },
  ...(active && {
    color: theme.palette.primary.main,
  }),
}))

const options: Array<{ label: string; value: AutocompleteIncludeMode }> = [
  { label: 'Include', value: 'include' },
  { label: 'Exclude', value: 'exclude' },
]

interface FilterAutocompleteIncludeModeSelectProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  filter: FilterConfigAutocomplete<Item, FilterField>
}

export default function FilterAutocompleteIncludeModeSelect<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ filter }: FilterAutocompleteIncludeModeSelectProps<Item, FilterField>) {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const open = Boolean(anchorEl)

  const controller = useController<AutocompleteFormSchemaType, 'mode'>({
    name: 'mode',
  })

  const watch = useWatch<AutocompleteFormSchemaType, 'mode'>({
    name: 'mode',
  })

  return (
    <>
      <Tooltip
        placement={'top'}
        title={`Include or exclude ${filter.pluralName}`}
        disableInteractive>
        <StyledButton
          active={open}
          color={'primary'}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          startIcon={<ChevronDownIcon />}>
          {capitalize(watch.slice(0, 4))}.
        </StyledButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
        open={open}
        transformOrigin={{
          vertical: -4,
          horizontal: 8,
        }}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              controller.field.onChange(option.value)
              setAnchorEl(null)
            }}
            selected={watch === option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
