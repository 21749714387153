import { InvestorFundListItem } from '@gain/rpc/app-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'

import Card from '../../../../common/card/card'
import CardMetrics, { createMetrics } from '../../../../common/metrics/metrics-card'
import { DRY_POWDER_EXPLAINER } from '../../../investor/investor-profile-util'
import {
  generateInvestorFundPagePath,
  INVESTOR_FUND_ADD_ONS_PAGE_PATH,
  INVESTOR_FUND_PORTFOLIO_PAGE_PATH,
  useInvestorFundPageParams,
} from '../../route-fund-path'
import { useInvestorFundDryPowder } from './investor-fund-metrics-card-hooks'

export interface InvestorMetricsCardProps {
  fund: InvestorFundListItem
}

export default function InvestorFundMetricsCard({ fund }: InvestorMetricsCardProps) {
  const fundPageParams = useInvestorFundPageParams()
  const dryPowderRange = useInvestorFundDryPowder(fund)
  const formatCurrency = useFormatCurrencyCallback()

  return (
    <Card sx={{ pb: 0 }}>
      <CardMetrics
        metrics={createMetrics(
          {
            href:
              fund.assetsTotal > 0
                ? generateInvestorFundPagePath(fundPageParams, INVESTOR_FUND_PORTFOLIO_PAGE_PATH)
                : false,
            label: 'Companies',
            value: fund.assetsTotal,
          },
          {
            label: 'Fund size',
            value: formatCurrency(fund.fundSizeEur),
          },
          dryPowderRange !== null && {
            label: 'Drypowder est.',
            value: dryPowderRange,
            explainer: DRY_POWDER_EXPLAINER,
          },
          {
            label: 'Vintage date',
            value: `${fund.vintageYear || '-'}`,
          },
          fund.assetEbitdaMedianEur !== null && {
            label: 'Median EBITDA',
            value: formatCurrency(fund.assetEbitdaMedianEur),
            explainer:
              'Median EBITDA is calculated using only positive EBITDA values, to give a better representation of typical investment size',
          },
          // {
          //   label: 'Entries (L5Y)',
          //   href:
          //     (fund.dealsEntriesTotalLastFiveYears || 0) > 0
          //       ? generateInvestorFundPagePath(fundPageParams, INVESTOR_FUND_ENTRIES_PAGE_PATH)
          //       : false,
          //   value: fund.dealsEntriesTotalLastFiveYears || 0,
          // },
          // {
          //   label: 'Exits (L5Y)',
          //   href:
          //     (fund.dealsExitTotalLastFiveYears || 0) > 0
          //       ? generateInvestorFundPagePath(fundPageParams, INVESTOR_FUND_EXITS_PAGE_PATH)
          //       : false,
          //   value: fund.dealsExitTotalLastFiveYears || 0,
          // },
          {
            label: 'Add-ons (L5Y)',
            href:
              (fund.dealsAddOnsTotalLastFiveYears || 0) > 0
                ? generateInvestorFundPagePath(fundPageParams, INVESTOR_FUND_ADD_ONS_PAGE_PATH)
                : false,
            value: fund.dealsAddOnsTotalLastFiveYears || 0,
          }
        )}
      />
    </Card>
  )
}
