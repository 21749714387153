import { CreditListItem, Lender } from '@gain/rpc/app-model'
import { listSort } from '@gain/rpc/utils'
import { compareNumberDesc } from '@gain/utils/common'
import { encodeSortQueryParam } from '@gain/utils/sort'

import Card, { CardContentEmpty, CardHeader } from '../../../common/card/card'
import Table, { createTableColumns } from '../../../common/table'
import AssetContentLink from '../../../features/asset/asset-content-link'
import FinancialValue from '../../../features/financial/financial-value'
import { generateLenderPagePath, LENDER_CREDITS_PATH } from '../lender-path'
import { MIN_TABLE_CARD_HEIGHT } from './route-summary-utils'

const columns = createTableColumns<CreditListItem>(
  {
    field: 'assetName',
    headerName: 'Company name',
    renderCell: ({ row }) => (
      <AssetContentLink
        id={row.assetId}
        logoFileUrl={row.assetLogoFileUrl}
        name={row.assetName}
      />
    ),
  },
  {
    headerName: 'Debt quantum',
    field: 'debtQuantumEur',
    width: 137,
    align: 'right',
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  }
)

interface LargestCreditsCardProps {
  lender: Lender
  credits: CreditListItem[]
}

export default function LargestCreditsCard({ lender, credits }: LargestCreditsCardProps) {
  // Ensure we have the largest 4 credits
  const largestCredits = credits
    .slice()
    .sort((a, b) => compareNumberDesc(a.debtQuantumEur, b.debtQuantumEur))
    .slice(0, 4)

  const path = [
    generateLenderPagePath(lender.id, lender.name, LENDER_CREDITS_PATH),
    `sort=${encodeSortQueryParam<CreditListItem>([listSort('debtQuantumEur', 'desc')])}`,
  ].join('?')

  return (
    <Card
      href={largestCredits.length > 0 ? path : undefined}
      sx={{ minHeight: MIN_TABLE_CARD_HEIGHT }}
      fullHeight>
      <CardHeader title={'Largest credits'} />
      {largestCredits.length === 0 && <CardContentEmpty message={'No data available'} />}
      {largestCredits.length > 0 && (
        <Table
          columns={columns}
          rows={largestCredits}
          dense
          disablePaddingBottomOnLastRow
        />
      )}
    </Card>
  )
}
