import { styled } from '@mui/material/styles'
import { PropsWithChildren } from 'react'

export interface CardSubHeaderProps {
  disableGuttersBottom?: boolean
}

const StyledCardSubHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disableGuttersBottom',
})<CardSubHeaderProps>(({ theme, disableGuttersBottom }) => ({
  padding: theme.spacing(1, 3),
  backgroundColor: theme.palette.grey[50],
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(disableGuttersBottom ? 0 : 2),
}))

export default function CardSubHeader({
  children,
  disableGuttersBottom,
}: PropsWithChildren<CardSubHeaderProps>) {
  return (
    <StyledCardSubHeader disableGuttersBottom={disableGuttersBottom}>
      {children}
    </StyledCardSubHeader>
  )
}
