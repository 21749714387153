import { SalesforceIcon } from '@gain/components/icons'
import { useUserContext } from '@gain/modules/user'
import { useSnackbar } from 'notistack'

import { StyledLoadingButton } from './button-add-to-salesforce.component'
import { showSalesforceProgress } from './salesforce-snackbar'

interface ButtonAddAssetsToSalesforceProps {
  assetIds: number[]
  variant?: 'text' | 'icon'
}

export default function ButtonAddAssetsToSalesforce({
  assetIds,
  variant = 'text',
}: ButtonAddAssetsToSalesforceProps) {
  const { enqueueSnackbar } = useSnackbar()
  const userContext = useUserContext()

  const handleOnClick = async () => {
    showSalesforceProgress(enqueueSnackbar, assetIds)
  }

  if (!userContext.userProfile?.featureSalesforce) {
    return null
  }

  return (
    <StyledLoadingButton
      onClick={handleOnClick}
      startIcon={variant === 'icon' ? undefined : <SalesforceIcon />}
      variant={variant === 'icon' ? 'icon' : 'outlined'}>
      {variant === 'icon' ? <SalesforceIcon /> : 'Add to Salesforce'}
    </StyledLoadingButton>
  )
}
