import { RpcClientFetcher } from '@gain/api/swr'
import { useUserProfileContext } from '@gain/modules/user'
import { RpcMethodMap, UserProfile } from '@gain/rpc/app-model'
import { downloadUrl } from '@gain/utils/download'
import { useCallback } from 'react'

import { ExportContainerMethod } from './export-container'

export type ExportMethodMap = Pick<
  RpcMethodMap,
  | 'admin.exportUsers'
  | 'data.downloadAssetAnnualReportFiles'
  | 'data.downloadAssetFiles'
  | 'data.downloadLegalEntityAnnualReportFiles'
  | 'data.exportAnnualReportFile'
  | 'data.exportFactsheet'
  | 'data.exportAssetFinancials'
  | 'data.exportAssetDeals'
  | 'data.exportLegalEntityFinancials'
  | 'data.exportDeals'
  | 'data.exportAdvisorDeals'
  | 'data.exportAdvisors'
  | 'data.exportIndustryProfile'
  | 'data.exportInvestors'
  | 'data.exportAssets'
  | 'data.exportShareholderFile'
  | 'data.downloadAllShareholderFiles'
  | 'data.exportConferenceEditionExhibitors'
  | 'data.exportAssetBenchmarking'
>

export const UNLIMITED_EXPORTS = -1

function stringResponseHandler(response: string) {
  downloadUrl(response)
}

interface Analytics {
  action: string
  category: string
}

export type ResponseHandlerCallback<Method extends ExportContainerMethod> = (
  response: ExportMethodMap[Method]['result']
) => void

export interface MethodConfig<
  Method extends ExportContainerMethod,
  Params extends RpcMethodMap[Method]['params']
> {
  pluralName: string
  getExportLimits: (profile: UserProfile) => {
    current: number
    max: number
    maxItems?: number
  }
  responseHandler: ResponseHandlerCallback<Method>
  analytics?: Analytics

  // Returns the nr of items in the export. This is used to check if the user is
  // trying to export too many items in the ExportListButton and
  // ExportListContainer components.
  getNrOfItemsInExport?: (params: Params, fetcher: RpcClientFetcher) => Promise<number>

  // Endpoints that return public files have their usage increased
  // after the file has been downloaded which can take a few seconds. This
  // function enables us to update the usage count client-side using SWR mutate.
  //
  // If this function is not set a refresh of the user profile occurs instead.
  increaseExportUsage?: (profile: UserProfile, increase: number) => UserProfile
}

type MethodConfigMap = {
  [Key in keyof ExportMethodMap]: MethodConfig<Key, RpcMethodMap[Key]['params']>
}

export const methodConfigMap: MethodConfigMap = {
  'data.downloadAssetFiles': {
    pluralName: 'annual reports',
    getExportLimits: (_) => ({
      current: 0,
      max: UNLIMITED_EXPORTS, // it's a collection of files and requires a more sophisticated check
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'ASSET',
      action: 'downloadFiles',
    },
  },
  'admin.exportUsers': {
    pluralName: 'users',
    getExportLimits: (_) => ({
      current: 0,
      max: UNLIMITED_EXPORTS,
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'USER',
      action: 'exportUsers',
    },
  },
  'data.exportAssetDeals': {
    pluralName: 'deals',
    getExportLimits: (profile) => ({
      current: profile.featureExportDealsExportsThisMonth,
      max: profile.featureExportDealsMaxExportsPerMonth,
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'ASSET',
      action: 'downloadDeals',
    },
  },
  'data.downloadAssetAnnualReportFiles': {
    pluralName: 'annual reports',
    getExportLimits: (profile) => ({
      current: profile.featureExportAnnualReportThisMonth,
      max: profile.featureExportAnnualReportMaxPerMonth,
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'ASSET',
      action: 'downloadAnnualReports',
    },
  },
  'data.downloadAllShareholderFiles': {
    pluralName: 'annual reports',
    getExportLimits: () => ({
      current: 0,
      max: UNLIMITED_EXPORTS, // Currently not limited
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'LEGAL_ENTITIES',
      action: 'exportListShareholderFiles',
    },
  },
  'data.exportAnnualReportFile': {
    pluralName: 'annual reports',
    getExportLimits: (profile) => ({
      current: profile.featureExportAnnualReportThisMonth,
      max: profile.featureExportAnnualReportMaxPerMonth,
    }),
    responseHandler: (response) => downloadUrl(response.fileURL),
    increaseExportUsage: (profile, increase) => ({
      ...profile,
      featureExportAnnualReportThisMonth: profile.featureExportAnnualReportThisMonth + increase,
    }),
    analytics: {
      category: 'LEGAL_ENTITY',
      action: 'downloadAnnualReport',
    },
  },
  'data.exportShareholderFile': {
    pluralName: 'shareholder files',
    getExportLimits: () => ({
      current: 0,
      max: UNLIMITED_EXPORTS, // Currently not limited
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'LEGAL_ENTITIES',
      action: 'exportListShareholderFile',
    },
  },
  'data.exportFactsheet': {
    pluralName: 'factsheets',
    getExportLimits: (profile) => ({
      current: profile.featureExportFactsheetThisMonth,
      max: profile.featureExportFactsheetMaxPerMonth,
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'ASSET',
      action: 'downloadFactsheet',
    },
  },
  'data.downloadLegalEntityAnnualReportFiles': {
    pluralName: 'annual reports',
    getExportLimits: (profile) => ({
      current: profile.featureExportAnnualReportThisMonth,
      max: profile.featureExportAnnualReportMaxPerMonth,
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'LEGAL_ENTITY',
      action: 'downloadAnnualReports',
    },
  },
  'data.exportLegalEntityFinancials': {
    pluralName: 'financials',
    getExportLimits: (profile) => ({
      current: profile.featureExportFinancialsThisMonth,
      max: profile.featureExportFinancialsMaxPerMonth,
    }),
    increaseExportUsage: (profile, increase) => ({
      ...profile,
      featureExportFinancialsThisMonth: profile.featureExportFinancialsThisMonth + increase,
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'LEGAL_ENTITY',
      action: 'downloadFinancials',
    },
  },
  'data.exportIndustryProfile': {
    pluralName: 'industry profiles',
    getExportLimits: (profile) => ({
      current: profile.featureExportIndustryThisMonth,
      max: profile.featureExportIndustryMaxPerMonth,
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'INDUSTRY',
      action: 'exportIndustryProfile',
    },
  },
  'data.exportAssetFinancials': {
    pluralName: 'financials',
    getExportLimits: (profile) => ({
      current: profile.featureExportFinancialsThisMonth,
      max: profile.featureExportFinancialsMaxPerMonth,
    }),
    responseHandler: stringResponseHandler,
    increaseExportUsage: (profile, increase) => ({
      ...profile,
      featureExportFinancialsThisMonth: profile.featureExportFinancialsThisMonth + increase,
    }),
    analytics: {
      category: 'ASSET',
      action: 'downloadFinancials',
    },
  },
  'data.exportDeals': {
    pluralName: 'deals',
    getExportLimits: (profile) => ({
      current: profile.featureExportDealsExportsThisMonth,
      max: profile.featureExportDealsMaxExportsPerMonth,
      maxItems: profile.featureExportDealsMaxItemsPerExport,
    }),
    getNrOfItemsInExport: async (params, fetcher) => {
      const result = await fetcher({
        method: 'data.listDeals',
        params: {
          filter: params.filter,
          search: '',
          sort: params.sort,
          limit: 0,
          page: 0,
        },
      })

      return result.counts.filtered
    },
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'DEALS',
      action: 'exportList',
    },
  },
  'data.exportAdvisors': {
    pluralName: 'advisors',
    getExportLimits: (profile) => ({
      current: profile.featureExportAdvisorsExportsThisMonth,
      max: profile.featureExportAdvisorsMaxExportsPerMonth,
      maxItems: profile.featureExportAdvisorsMaxItemsPerExport,
    }),
    getNrOfItemsInExport: async (params, fetcher) => {
      const result = await fetcher({
        method: 'data.listAdvisors',
        params: {
          filter: params.filter,
          search: params.search,
          sort: params.sort,
          limit: 0,
          page: 0,
        },
      })

      return result.counts.filtered
    },
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'ADVISORS',
      action: 'exportList',
    },
  },
  'data.exportAdvisorDeals': {
    pluralName: 'deals',
    getExportLimits: (profile) => ({
      current: profile.featureExportDealsExportsThisMonth,
      max: profile.featureExportDealsMaxExportsPerMonth,
      maxItems: profile.featureExportDealsMaxItemsPerExport,
    }),
    getNrOfItemsInExport: async (params, fetcher) => {
      const result = await fetcher({
        method: 'data.listAdvisorDeals',
        params: {
          filter: params.filter,
          search: params.search,
          sort: params.sort,
          limit: 0,
          page: 0,
        },
      })

      return result.counts.filtered
    },
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'ADVISOR_DEALS',
      action: 'exportList',
    },
  },
  'data.exportInvestors': {
    pluralName: 'investors',
    getExportLimits: (profile) => ({
      current: profile.featureExportOwnersExportsThisMonth,
      max: profile.featureExportOwnersMaxExportsPerMonth,
      maxItems: profile.featureExportOwnersMaxItemsPerExport,
    }),
    getNrOfItemsInExport: async (params, fetcher) => {
      const result = await fetcher({
        method: 'data.listInvestors',
        params: {
          filter: params.filter,
          search: params.search,
          sort: params.sort,
          limit: 0,
          page: 0,
        },
      })

      return result.counts.filtered
    },
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'INVESTORS',
      action: 'exportList',
    },
  },
  'data.exportAssets': {
    pluralName: 'companies',
    getExportLimits: (profile) => ({
      current: profile.featureExportAssetsExportsThisMonth,
      max: profile.featureExportAssetsMaxExportsPerMonth,
      maxItems: profile.featureExportAssetsMaxItemsPerExport,
    }),
    getNrOfItemsInExport: async (params, fetcher) => {
      const result = await fetcher({
        method: 'data.listAssets',
        params: {
          filter: params.filter,
          search: params.search,
          sort: params.sort,
          limit: 0,
          page: 0,
        },
      })

      return result.counts.filtered
    },
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'ASSETS',
      action: 'exportList',
    },
  },
  'data.exportConferenceEditionExhibitors': {
    pluralName: 'exhibitors',
    getExportLimits: () => ({
      current: 0,
      max: UNLIMITED_EXPORTS, // Currently not limited
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'CONFERENCE',
      action: 'exportExhibitorsList',
    },
  },
  'data.exportAssetBenchmarking': {
    pluralName: 'benchmarks',
    getExportLimits: () => ({
      current: 0,
      max: UNLIMITED_EXPORTS,
    }),
    responseHandler: stringResponseHandler,
    analytics: {
      category: 'ASSET',
      action: 'exportBenchmarking',
    },
  },
}

/**
 * Returns a function that checks if the user is within the export limit for the given method.
 */
export function useIsWithinExportLimit(): (
  method: keyof typeof methodConfigMap,
  increase: number
) => boolean {
  const userProfile = useUserProfileContext()

  return useCallback(
    (method: keyof typeof methodConfigMap, increase: number) => {
      const config = methodConfigMap[method]
      const { current, max } = config.getExportLimits(userProfile)

      return max === UNLIMITED_EXPORTS || current + increase <= max
    },
    [userProfile]
  )
}
