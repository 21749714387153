import Tooltip from '@gain/components/tooltip'
import Typography from '@gain/components/typography'
import { useUserProfileContext } from '@gain/modules/user'
import { styled } from '@mui/material/styles'

import { ExportContainerMethod } from './export-container'
import { methodConfigMap, UNLIMITED_EXPORTS } from './export-method-config'
import { exportsRemainingMessage } from './index'

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(0.75),
  marginRight: theme.spacing(1),
  minWidth: 80,
}))

interface ExportMaxCountProps<Method extends ExportContainerMethod> {
  method: Method
  disableTooltip?: boolean
}

export default function ExportMaxCount<Method extends ExportContainerMethod>({
  method,
  disableTooltip = false,
}: ExportMaxCountProps<Method>) {
  const userProfile = useUserProfileContext()

  const config = methodConfigMap[method]
  const { current, max } = config.getExportLimits(userProfile)

  // Whatever the case, we don't want to show less than 0
  const remainingDownloads = Math.max(max - current, 0)

  return (
    <StyledContainer>
      <Tooltip
        placement={'top'}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -5], // Because of button styling it looks better if the tooltip is a bit closer
              },
            },
          ],
        }}
        title={disableTooltip ? '' : exportsRemainingMessage(current, max)}>
        <span>
          <Typography
            color={'text.secondary'}
            variant={'body2'}>
            {max === UNLIMITED_EXPORTS ? 'Unlimited' : `${remainingDownloads}/${max}`}
          </Typography>
        </span>
      </Tooltip>
    </StyledContainer>
  )
}
