import { Trash2Icon, UserXIcon } from '@gain/components/icons'
import { UserPermissionRole } from '@gain/rpc/app-model'
import Menu from '@mui/material/Menu'

import { MenuDivider } from '../../common/menu-divider'
import { ShareActions } from './share-actions'
import ShareMenuButton, { ShareMenuButtonState } from './share-menu-button'

interface UserActions {
  removeUser: ShareMenuButtonState
  grantOwner: ShareMenuButtonState
  revokeOwner: ShareMenuButtonState
}

interface ShareMenuProps {
  userId: number
  onClose: (action: ShareActions) => void
  anchorEl: Element
  open: boolean
  actions: UserActions
  isCurrentUserOwner: boolean
}

/**
 * ShareMenu is a small user-specific menu that allows to change user roles or
 * remove users from a list.
 */
export function ShareMenu({
  userId,
  onClose,
  anchorEl,
  open,
  actions,
  isCurrentUserOwner,
}: ShareMenuProps) {
  const handleMakeOwner = () => {
    onClose({ type: 'updateRole', userId: userId, role: UserPermissionRole.Owner })
  }

  const handleRevokeOwner = () => {
    onClose({ type: 'updateRole', userId: userId, role: UserPermissionRole.Editor })
  }

  // The row is hidden first; when the hide animation finishes the user is
  // removed automatically.
  const handleHide = () => {
    onClose({ type: 'hide', userId: userId })
  }

  const handleCancel = () => {
    onClose({ type: 'cancel' })
  }

  if (Object.values(actions).every((action) => action === 'none')) {
    return null
  }

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      onClose={handleCancel}
      open={open}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}>
      {/* Wrap with div because fragment is not allowed */}
      {isCurrentUserOwner && (
        <>
          <ShareMenuButton
            onClick={handleMakeOwner}
            state={actions.grantOwner}>
            <UserXIcon /> Grant owner role
          </ShareMenuButton>

          <ShareMenuButton
            disabledTooltip={'There must be at least one owner'}
            onClick={handleRevokeOwner}
            state={actions.revokeOwner}>
            <UserXIcon /> Revoke owner role
          </ShareMenuButton>

          {actions.removeUser !== ShareMenuButtonState.None && <MenuDivider />}
        </>
      )}

      <ShareMenuButton
        disabledTooltip={'There must be at least one owner'}
        onClick={handleHide}
        state={actions.removeUser}
        isRed>
        <Trash2Icon color={'error'} /> Remove from list
      </ShareMenuButton>
    </Menu>
  )
}
