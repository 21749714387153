import { useIndustryMarketSegment, useRelatedAssets } from '@gain/api/app/hooks'
import { AssetListItem, ListRelatedAssetsSource } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'

import { ListViewAsset } from '../../features/list-view'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import { useSimilarCompaniesDefaultSort } from '../../features/similar-companies/similar-companies-card'
import ListPageLayout from '../../layout/list-page-layout'
import { useRouteSegmentParams } from '../utils'

export default function SegmentSimilarCompanies() {
  const { id } = useRouteSegmentParams()
  const isXs = useIsXs()
  const sort = useSimilarCompaniesDefaultSort()

  useTrackPageView('segment', { id, tab: 'similar_list', requireTab: true })

  const segment = useIndustryMarketSegment({ id })

  const similar = useRelatedAssets({
    source: ListRelatedAssetsSource.IndustryMarketSegment,
    id,
    limit: 100,
  })

  // Make sure we have segment data
  if (!segment.data || segment.loading) {
    return null
  }

  // Make sure we have similar companies data
  if (!similar.data || similar.loading) {
    return null
  }

  const title = `${segment.data.name || ''} - Similar companies`
  const assetIds = similar.data.items.map((item) => item.id)
  const defaultFilter = listFilters<AssetListItem>(listFilter('id', '=', assetIds))

  return (
    <ListPageLayout title={title}>
      <ListViewAsset
        defaultFilter={defaultFilter}
        defaultSort={sort}
        disableFilters={isXs}
        inline={isXs}
      />
    </ListPageLayout>
  )
}
