import { BookmarkFilledIcon, BookmarkIcon, CloseIcon } from '@gain/components/icons'
import { AssetListItem, BookmarkListType } from '@gain/rpc/app-model'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { MouseEvent, PointerEvent, useState } from 'react'

import { BookmarkAssetListItem } from '../../../api/bookmark-list-hooks'
import { ASSET_FILTER_MAP, AssetFilterField } from '../../asset/asset-filter-bar'
import BookmarksFilteredCreateDialog from '../../bookmarks-list/bookmarks-list-create/bookmarks-filtered-create-dialog'
import useDeleteBookmarkListConfirm from '../../bookmarks-list/bookmarks-list-edit/use-delete-bookmark-list-confirm'
import { FilterModel, fromFilterModel } from '../../filter/filter-bar'

const StyledActionButtons = styled('div')({
  marginLeft: 'auto',
  whiteSpace: 'nowrap',
})

const StyledDeleteIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 'inherit',
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body1.fontSize,
  padding: theme.spacing(1, 0.5),
  lineHeight: 0,
}))

const StyledBookmarkIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 32,
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

interface RecentFilterActionsProps {
  list: BookmarkAssetListItem
  recentFilterList: BookmarkAssetListItem
  filterModel: FilterModel<AssetListItem, AssetFilterField>
}

export default function RecentFilterActions({
  list,
  filterModel,
  recentFilterList,
}: RecentFilterActionsProps) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const deleteBookmarkList = useDeleteBookmarkListConfirm(list)
  const deleteRecentlyFilteredList = useDeleteBookmarkListConfirm(recentFilterList)

  const handleClickBookmark = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()

    // Open dialog to create a new saved bookmark list if it's a recently filtered
    // list. Otherwise, delete the saved bookmark list.
    if (list.type === BookmarkListType.RecentAssetsFilter) {
      setDialogOpen(true)
    } else {
      deleteBookmarkList()
    }
  }

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    deleteRecentlyFilteredList()
  }

  const handlePointerDown = (event: PointerEvent<HTMLButtonElement>) => {
    event.preventDefault() // Prevent search input from losing focus
  }

  const handleCloseCreateDialog = () => {
    setDialogOpen(false)
  }

  return (
    <StyledActionButtons>
      <BookmarksFilteredCreateDialog
        filters={fromFilterModel(filterModel, ASSET_FILTER_MAP)}
        onClose={handleCloseCreateDialog}
        open={dialogOpen}
        disableRedirect
      />

      <StyledBookmarkIconButton
        color={'primary'}
        onClick={handleClickBookmark}>
        {list.type === BookmarkListType.RecentAssetsFilter ? (
          <BookmarkIcon
            fontSize={'inherit'}
            strokeWidth={1}
          />
        ) : (
          <BookmarkFilledIcon
            color={'primary'}
            fontSize={'inherit'}
            strokeWidth={1}
          />
        )}
      </StyledBookmarkIconButton>

      <StyledDeleteIconButton
        color={'inherit'}
        onClick={handleDelete}
        onPointerDown={handlePointerDown}
        size={'small'}>
        <CloseIcon color={'inherit'} />
      </StyledDeleteIconButton>
    </StyledActionButtons>
  )
}
