import { ChevronDownIcon } from '@gain/components/icons'

import { orgChartClasses } from './org-chart-utils'

interface ExpandCollapseButtonProps {
  node
  alwaysExpandedNodeIds: string[]
}

export default function ExpandCollapseButton({
  node,
  alwaysExpandedNodeIds,
}: ExpandCollapseButtonProps) {
  // Don't show expand button for nodes that are always expanded
  if (alwaysExpandedNodeIds.includes(node.id)) {
    return null
  }

  return (
    <div className={orgChartClasses.expandButton}>
      <div style={{ display: 'flex' }}>
        <span style={{ alignItems: 'center', display: 'flex' }}>
          <ChevronDownIcon
            fontSize={'inherit'}
            style={{ transform: node.children ? 'rotate(180deg)' : '' }}
          />
        </span>
        <span style={{ marginLeft: '1px', color: '#716E7B' }}>
          {node.data._directSubordinatesPaging}
        </span>
      </div>
    </div>
  )
}
