import { AssetList, AssetListItem, InvestorStrategy } from '@gain/rpc/app-model'
import {
  getLabelFromOption,
  INVESTOR_STRATEGY_PREFERRED_STAKE_OPTIONS,
  InvestorStrategyPreferredStake,
} from '@gain/rpc/shared-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { TypographyProps } from '@mui/material/Typography'
import { median } from 'd3'

interface PreferredStake {
  stake: string | null | undefined
  color: TypographyProps['color']
}

export function useInvestorStrategyPreferredStake(
  strategy: InvestorStrategy,
  assets?: AssetListItem[]
): PreferredStake {
  if (strategy.preferredStake === InvestorStrategyPreferredStake.Unknown) {
    if (!assets || assets?.length === 0) {
      return {
        stake: null,
        color: 'text.secondary',
      }
    }

    const majorityAssets = assets.filter((asset) => asset.majorityOwnerId)
    const hasMajority =
      majorityAssets.length > 0 &&
      majorityAssets.every((asset) => asset.majorityOwnerId === strategy.investorId)

    const minorityAssets = assets.filter((asset) => asset.ownerIds.length > 0)
    const hasMinority =
      minorityAssets.length > 0 &&
      minorityAssets.every((asset) => asset.ownerIds.includes(strategy.investorId))

    let stake = InvestorStrategyPreferredStake.Flexible
    if (hasMajority && !hasMinority) {
      stake = InvestorStrategyPreferredStake.Majority
    } else if (!hasMajority && hasMinority) {
      stake = InvestorStrategyPreferredStake.Minority
    }

    return {
      stake: getLabelFromOption(INVESTOR_STRATEGY_PREFERRED_STAKE_OPTIONS, stake),
      color: 'info.main',
    }
  }

  return {
    stake: getLabelFromOption(INVESTOR_STRATEGY_PREFERRED_STAKE_OPTIONS, strategy.preferredStake),
    color: 'text.primary',
  }
}

export function useInvestorStrategyMedianRevenue(assets?: AssetList) {
  const formatCurrency = useFormatCurrencyCallback()

  if (!assets) {
    return null
  }

  const medianRevenue = median(assets.items.map((asset) => asset.revenueWithAiGeneratedEur))
  if (!medianRevenue) {
    return null
  }

  return formatCurrency(medianRevenue)
}
