import { AssetListItem } from '@gain/rpc/app-model'
import { useOpenLink } from '@gain/utils/router'
import { MouseEvent, useCallback } from 'react'

import Card, { CardContentEmpty, CardHeader } from '../../../../common/card/card'
import TableValueDistribution, {
  ASSET_VALUE_SHARE_SECTOR_CONFIG,
  useValueDistributionRows,
  ValueDistributionRow,
} from '../../../../common/table-value-distribution'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../../features/filter/filter-bar'
import {
  generateInvestorStrategyPagePath,
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../../route-strategy-path'

export interface InvestorStrategySectorCardProps {
  assets: AssetListItem[]
}

export default function InvestorStrategySectorCard({ assets }: InvestorStrategySectorCardProps) {
  const params = useInvestorStrategyPageParams()
  const openLink = useOpenLink()
  const sectorKnown = assets.filter(({ subsector }) => !!subsector)
  const rows = useValueDistributionRows(sectorKnown, ASSET_VALUE_SHARE_SECTOR_CONFIG)

  const handleRowClick = useCallback(
    (row: ValueDistributionRow, event: MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()

      const path = [
        generateInvestorStrategyPagePath(params, INVESTOR_STRATEGY_ASSETS_PAGE_PATH),
        filterModelToQueryString<AssetListItem, 'subsector'>([
          filterValueGroup(
            filterValueItem(
              'subsector',
              row.values?.map((value) => value.type)
            )
          ),
        ]),
      ].join('?')
      openLink(path, event)
    },
    [openLink, params]
  )

  return (
    <Card
      sx={{ minHeight: 304 }}
      fullHeight>
      <CardHeader title={'Portfolio sector'} />
      {rows.length === 0 ? (
        <CardContentEmpty message={'No data available'} />
      ) : (
        <TableValueDistribution
          itemLabel={'Sector'}
          onRowClick={handleRowClick}
          rows={rows}
          shareLabel={'Companies'}
          disableExpand
        />
      )}
    </Card>
  )
}
