import { Asset } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'
import { useCallback, useMemo, useState } from 'react'

import { CardContent, CardTabs } from '../../../../common/card/card'
import { TabOption } from '../../../../common/card/card/card-tabs'
import MeasurableTabPanel from './measurable-tab-panel'

enum AssetBusinessDescriptionTab {
  BusinessModel = 'business-model',
  CustomerBase = 'customer-base',
  CompetitivePosition = 'competitive-position',
}

interface BusinessDescriptionTabOption extends TabOption {
  description: string
}

function useTabs(asset: Asset) {
  return useMemo(() => {
    const tabs = new Array<BusinessDescriptionTabOption>()

    // Check all parts of the business description, add only if available
    if (asset.description?.atAGlance) {
      tabs.push({
        label: 'Business model',
        value: AssetBusinessDescriptionTab.BusinessModel,
        description: asset.description?.atAGlance.split('\n').join('\n\n'),
      })
    }
    if (asset.description?.furtherInformation) {
      tabs.push({
        label: 'Customer base',
        value: AssetBusinessDescriptionTab.CustomerBase,
        description: asset.description?.furtherInformation.split('\n').join('\n\n'),
      })
    }
    if (asset.market?.competition) {
      tabs.push({
        label: 'Competitive position',
        value: AssetBusinessDescriptionTab.CompetitivePosition,
        description: asset.market?.competition.split('\n').join('\n\n'),
      })
    }

    return tabs
  }, [asset])
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(1),

  '& p': {
    whiteSpace: 'pre-wrap',
  },
}))

const TabPanelContainer = styled('div')(({ minHeight }: { minHeight: number }) => ({
  position: 'relative',
  overflow: 'hidden',
  minHeight,
}))

export interface CardAssetBusinessDescriptionTabsProps {
  asset: Asset
}

export function CardAssetBusinessDescriptionTabs({ asset }: CardAssetBusinessDescriptionTabsProps) {
  const tabs = useTabs(asset)
  const [tabHeight, setTabHeight] = useState<Record<string | number, number>>({})
  const [currentTabValue, setCurrentTabValue] = useState(AssetBusinessDescriptionTab.BusinessModel)
  const currentTab = tabs.find((tab) => tab.value === currentTabValue)
  const minHeight = Math.max(...Object.values(tabHeight))

  const handleTabChange = (_, value) => {
    setCurrentTabValue(value)
  }

  const handleHeightChange = useCallback(
    (value: string | number, height: number) => {
      setTabHeight((prev) => ({
        ...prev,
        [value]: height,
      }))
    },
    [setTabHeight]
  )

  if (!currentTab || tabs.length === 0) {
    return null
  }

  return (
    <>
      {/* Only show the tab bar when there are multiple descriptions provided */}
      {tabs.length > 1 && (
        <CardTabs
          onChange={handleTabChange}
          options={tabs}
          value={currentTabValue}
        />
      )}

      <StyledCardContent>
        <TabPanelContainer minHeight={minHeight}>
          {tabs.map((tab) => (
            <MeasurableTabPanel
              key={tab.value}
              description={tab.description}
              isActive={currentTabValue === tab.value}
              onHeightChange={handleHeightChange}
              value={tab.value}
            />
          ))}
        </TabPanelContainer>
      </StyledCardContent>
    </>
  )
}
