import { createContext, ReactNode, useContext } from 'react'

interface BenchmarkingContext {
  // The name of the context in which the benchmarking is done, e.g. the company
  // name or the industry segment name.
  name: string

  // Object type & id are used to store the page settings in session storage
  // under a unique key, and also to provide context for exporting the data to excel.
  objectId: number
  objectType:
    | 'industry'
    | 'investor'
    | 'segment'
    | 'investorStrategy'
    | 'investorFund'
    | 'conferenceEdition'
    | 'bookmarksList'
    | 'bookmarksFilteredList'
    | 'asset'

  // Whether to show the rank column in the benchmarking table.
  showRankColumn?: boolean
}

const BenchmarkingContext = createContext<BenchmarkingContext | null>(null)

export function useBenchmarkingContext() {
  const context = useContext(BenchmarkingContext)
  if (!context) {
    throw new Error('useBenchmarkingContext must be used within a BenchmarkingContext provider')
  }
  return context
}

export function BenchmarkingContextProvider({
  value,
  children,
}: {
  value: BenchmarkingContext
  children: ReactNode
}) {
  return <BenchmarkingContext.Provider value={value}>{children}</BenchmarkingContext.Provider>
}
