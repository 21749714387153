import { useRpcClient } from '@gain/api/swr'
import { MailIcon, SpinnerIcon } from '@gain/components/icons'
import { PersonListItem } from '@gain/rpc/app-model'
import { ObjectType } from '@gain/rpc/shared-model'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useSnackbar } from 'notistack'
import { MouseEvent, useEffect, useState } from 'react'

import CopyToClipboardSnackbar from './copy-to-clipboard-snackbar'
import NoEmailFoundSnackbar from './no-email-found-snackbar'

export type RequestEmailPerson = Pick<PersonListItem, 'id' | 'firstName' | 'lastName'>

interface RequestEmailButtonProps {
  person: RequestEmailPerson
  objectType: ObjectType
  objectId: number
}

export default function RequestEmailButton({
  person,
  objectType,
  objectId,
}: RequestEmailButtonProps) {
  const { enqueueSnackbar } = useSnackbar()
  const rpc = useRpcClient()
  const [loading, setLoading] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const notifyEmailNotFound = () => {
    enqueueSnackbar(undefined, {
      content: (snackbarId) => (
        <NoEmailFoundSnackbar
          person={person}
          snackbarId={snackbarId}
        />
      ),
    })
  }

  const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setLoading(true)

    try {
      // Fetch email address
      const result = await rpc({
        method: 'data.getEmailAddress',
        params: { personId: person.id, objectType: objectType, objectId: objectId },
      })

      // Notify user when nothing was found
      if (!result) {
        notifyEmailNotFound()
        return
      }

      // Notify user that an email address was found
      enqueueSnackbar(undefined, {
        key: `email-found-${result.email}`,
        persist: true,
        preventDuplicate: true,
        content: (snackbarId) => (
          <CopyToClipboardSnackbar
            confidence={result.confidence}
            email={result.email}
            person={person}
            snackbarId={snackbarId}
          />
        ),
      })
    } catch {
      notifyEmailNotFound()
    } finally {
      setLoading(false)
    }
  }

  // Hide tooltip when loading
  useEffect(() => {
    if (loading) {
      setTooltipOpen(false)
    }
  }, [loading])

  return (
    <Tooltip
      onClose={() => setTooltipOpen(false)}
      onOpen={() => setTooltipOpen(true)}
      open={tooltipOpen}
      title={'Request email address'}
      disableInteractive>
      <IconButton
        color={'secondary'}
        disabled={loading}
        onClick={handleClick}
        sx={{ fontSize: 18 }}>
        {loading ? <SpinnerIcon /> : <MailIcon />}
      </IconButton>
    </Tooltip>
  )
}
