import {
  ActivityIcon,
  BriefcaseIcon,
  CompanyIcon,
  LeafIcon,
  ShuffleIcon,
} from '@gain/components/icons'
import { SECTOR_AND_SUBSECTOR_SEARCH_ALIASES } from '@gain/utils/sector'

import { investorFundTableColumns } from '../investor-fund/investor-fund-table/investor-fund-list-view-columns'
import { TopLevelColumnGroup } from './column-picker-model'
import { fromConfig, getRegionAndCountrySearchAliases } from './column-picker-util'

export const columnPickerConfigInvestorFund = new Array<TopLevelColumnGroup>(
  {
    name: 'Key facts',
    Icon: CompanyIcon,
    columns: [
      fromConfig(investorFundTableColumns, 'name', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(investorFundTableColumns, 'assetsTotal', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(investorFundTableColumns, 'investorId', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(investorFundTableColumns, 'strategyClassifications', {
        visibleByDefault: true,
      }),
      fromConfig(investorFundTableColumns, 'fundraisingStatus', {
        visibleByDefault: true,
      }),
      fromConfig(investorFundTableColumns, 'fundSizeEur', {
        visibleByDefault: true,
      }),
      fromConfig(investorFundTableColumns, 'vintageYear', {
        visibleByDefault: true,
      }),
      fromConfig(investorFundTableColumns, 'dryPowderMinEur', {
        visibleByDefault: true,
      }),
    ],
  },
  {
    name: 'Portfolio',
    Icon: BriefcaseIcon,
    columns: [
      fromConfig(investorFundTableColumns, 'assetEbitdasEur', {
        visibleByDefault: true,
      }),
      fromConfig(investorFundTableColumns, 'assetEbitdaMedianEur', {
        visibleByDefault: true,
      }),
      fromConfig(investorFundTableColumns, 'assetRegions', {
        visibleByDefault: true,
        searchAliases: getRegionAndCountrySearchAliases(),
      }),
      fromConfig(investorFundTableColumns, 'assetSectors', {
        visibleByDefault: true,
        searchAliases: SECTOR_AND_SUBSECTOR_SEARCH_ALIASES,
      }),
    ],
  },
  {
    name: 'Deals',
    Icon: ShuffleIcon,
    columns: [
      // fromConfig(investorFundTableColumns, 'dealsTotalLastFiveYears', {
      //   visibleByDefault: true,
      // }),
      // fromConfig(investorFundTableColumns, 'dealsEntriesTotalLastFiveYears'),
      // fromConfig(investorFundTableColumns, 'dealsExitTotalLastFiveYears'),
      fromConfig(investorFundTableColumns, 'dealsAddOnsTotalLastFiveYears'),
    ],
  },
  {
    name: 'ESG',
    Icon: LeafIcon,
    columns: [
      fromConfig(investorFundTableColumns, 'assetsMedianMarketSegmentRatingOverall'), // Overall ESG risk
      fromConfig(investorFundTableColumns, 'assetsMedianMarketSegmentRatingEnvironmental'), // Environmental risk
      fromConfig(investorFundTableColumns, 'assetsMedianMarketSegmentRatingSocial'), // Social risk
    ],
  },
  {
    name: 'Fund performance',
    Icon: ActivityIcon,
    columns: [
      fromConfig(investorFundTableColumns, 'grossIrr'),
      fromConfig(investorFundTableColumns, 'netIrr'),
      fromConfig(investorFundTableColumns, 'twr'),
      fromConfig(investorFundTableColumns, 'moic'),
      fromConfig(investorFundTableColumns, 'dpi'),
      fromConfig(investorFundTableColumns, 'rvpi'),
      fromConfig(investorFundTableColumns, 'tvpi'),
    ],
  }
)
