import { ListedSecurityValuationRatios } from '@gain/rpc/app-model'

/**
 * RatioKey is a union type that contains all keys of a
 * ListedSecurityValuation that can be displayed as a ratio
 */
export type RatioKey = keyof Pick<
  ListedSecurityValuationRatios,
  | 'enterpriseValueRevenueRatio'
  | 'enterpriseValueEbitdaRatio'
  | 'enterpriseValueEbitRatio'
  | 'enterpriseValueEbitdaMinusCapexRatio'
  | 'enterpriseValueInvestedCapitalRatio'
  | 'enterpriseValueFreeCashFlowRatio'
>

/**
 * RatioItemConfig is used to map a key to a label for display purposes.
 */
interface RatioItemConfig {
  key: RatioKey
  label: string
}

/**
 * RATIO_CONFIG defines the ratio keys that can be displayed together
 * with their display label.
 */
const RATIO_CONFIG: RatioItemConfig[] = [
  {
    key: 'enterpriseValueEbitdaRatio',
    label: 'EV / EBITDA',
  },
  {
    key: 'enterpriseValueEbitRatio',
    label: 'EV / EBIT',
  },
  {
    key: 'enterpriseValueRevenueRatio',
    label: 'EV / Sales',
  },
  {
    key: 'enterpriseValueEbitdaMinusCapexRatio',
    label: 'EV / (EBITDA - CAPEX)',
  },
  {
    key: 'enterpriseValueInvestedCapitalRatio',
    label: 'EV / Invested Capital',
  },
  {
    key: 'enterpriseValueFreeCashFlowRatio',
    label: 'EV / Free Cash Flow',
  },
]

function isMeaningfulRatio(ratio: number | null) {
  return ratio !== null && ratio > 0
}

/**
 * useHasValuationRatios returns true if the given valuations contain
 * ratios that can be displayed, false otherwise.
 */
export function hasValuationRatios(valuations?: ListedSecurityValuationRatios[]) {
  if (!Array.isArray(valuations) || valuations.length === 0) {
    return false
  }

  return valuations.some((valuation) => {
    return RATIO_CONFIG.some((config) => {
      return isMeaningfulRatio(valuation[config.key])
    })
  })
}

/**
 * RatioOption is used to display the available ratio options in tabs
 * or dropdown menu.
 */
export interface RatioOption {
  label: string
  key: RatioKey
  ratio: number | null
  disabled?: boolean
}

export function useCurrentValuationRatioOptions(
  currentValuation: ListedSecurityValuationRatios | undefined,
  historicValuations: ListedSecurityValuationRatios[] | undefined
) {
  return RATIO_CONFIG.map((itemConfig) => {
    const latestDefinedHistoricValuation = historicValuations?.find(
      (valuation) => valuation[itemConfig.key] !== null
    )

    return {
      label: itemConfig.label,
      key: itemConfig.key,
      ratio: currentValuation?.[itemConfig.key] ?? null,
      disabled: latestDefinedHistoricValuation === undefined,
    }
  })
}
