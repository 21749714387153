import { PlusIcon } from '@gain/components/icons'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState } from 'react'

import TeamMemberInviteDialog from './team-member-invite-dialog'

interface TeamMembersInviteButtonProps {
  onUserInvited?: () => void
}

export default function TeamMembersInviteButton({ onUserInvited }: TeamMembersInviteButtonProps) {
  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down('sm'))
  const [showInviteDialog, setShowInviteDialog] = useState(false)

  return (
    <>
      <TeamMemberInviteDialog
        keepMounted={false}
        onClose={() => setShowInviteDialog(false)}
        onUserInvited={onUserInvited}
        open={showInviteDialog}
      />
      {!isXsDown && (
        <Button
          color={'primary'}
          onClick={() => setShowInviteDialog(true)}
          startIcon={<PlusIcon fontSize={'inherit'} />}
          variant={'contained'}>
          Invite members
        </Button>
      )}
      {isXsDown && (
        <IconButton
          color={'primary'}
          onClick={() => setShowInviteDialog(true)}
          size={'small'}>
          <PlusIcon />
        </IconButton>
      )}
    </>
  )
}
