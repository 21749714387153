import { alpha, styled } from '@mui/material/styles'

function splitAndHighlightText(text: string, highlightText: string) {
  // RegExp will error if the highlighted text contains characters that need to
  // be escaped such as '\'. As an escape hatch, return the input text without
  // highlighting in such cases
  try {
    const regex = new RegExp(`(${highlightText})`, 'gi')
    return text.split(regex).map((part) => {
      return {
        text: part,
        highlight: regex.test(part),
      }
    })
  } catch {
    return [
      {
        text: text,
        highlight: false,
      },
    ]
  }
}

const StyledSpan = styled('span')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
  borderRadius: 1,
}))

interface TextHighlightProps {
  text: string
  highlightText?: string
}

export default function TextHighlight({ text, highlightText }: TextHighlightProps) {
  if (!highlightText || highlightText.length < 2) {
    return text
  }

  const parts = splitAndHighlightText(text, highlightText)

  return (
    <>
      {parts.map((part, index) =>
        part.highlight ? <StyledSpan key={index}>{part.text}</StyledSpan> : part.text
      )}
    </>
  )
}
