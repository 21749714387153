import {
  Rating as ICRating,
  RatingEsgKey,
  RatingOption,
  roundInvestmentCriteriaValue,
  useRatingColor,
} from '@gain/utils/investment-criteria'
import Rating from '@mui/material/Rating'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'

import {
  InvestmentCriteriaTableCell,
  InvestmentCriteriaTableHeaderCell,
  InvestmentCriteriaTableHeaderTitle,
  InvestmentCriteriaTableRatingOption,
  InvestmentCriteriaTableRowContainer,
} from '../../../investment-criteria/investment-criteria-card'

export interface IndustryEsgAssessmentTableItemProps {
  rating: ICRating<RatingEsgKey>
  value: number | null
}

const StyledRating = styled(InvestmentCriteriaTableRatingOption, {
  shouldForwardProp: (prop) => prop !== 'value',
})<{ value: number | null }>(({ theme, value }) => {
  const ratingWidth = 122

  // Default the position is all the way to the left
  let left: number | string = 6 // the default padding
  if (value !== null) {
    // If the score is between 0.5 and lower than 5 we need to position it somewhere in the center of the bar
    if (value > 0.5 && value < 5) {
      left = `calc(${(value / 5) * 100}% - 10% - 12px)` // 10% = 0,5 score, 12px = padding
    } else if (value === 5) {
      // When the score is 5 position it all the way to the right
      left = `calc(100% - ${ratingWidth + 6}px)` // 6px = padding
    }
  }

  return {
    width: ratingWidth,
    maxWidth: ratingWidth,
    minWidth: ratingWidth,

    ...(value !== null && {
      position: 'absolute',
      height: `calc(100% - ${theme.spacing(1.5)})`,
      left,
    }),
  }
})

export default function IndustryEsgAssessmentTableItem({
  rating,
  value,
}: IndustryEsgAssessmentTableItemProps) {
  const color = useRatingColor(value)

  const option = useMemo(() => {
    if (value === 0) {
      return null
    }

    return rating.options.find(
      (esgRating) => esgRating.value === roundInvestmentCriteriaValue(value)
    ) as RatingOption
  }, [rating, value])

  if (!option) {
    return null
  }

  return (
    <InvestmentCriteriaTableRowContainer>
      <InvestmentCriteriaTableHeaderCell>
        <InvestmentCriteriaTableHeaderTitle rating={rating} />

        {value !== null && (
          <Rating
            precision={0.5}
            sx={{
              color,
            }}
            value={value}
            readOnly
          />
        )}
      </InvestmentCriteriaTableHeaderCell>
      <InvestmentCriteriaTableCell>
        <StyledRating
          color={color}
          value={roundInvestmentCriteriaValue(value) as number}
          selected>
          {option.label}
        </StyledRating>
      </InvestmentCriteriaTableCell>
    </InvestmentCriteriaTableRowContainer>
  )
}
