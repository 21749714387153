import { useInvestorStrategyListItem } from '@gain/api/app/hooks'
import { InvestorFundListItem } from '@gain/rpc/app-model'
import { InvestorFundFundraisingStatus } from '@gain/rpc/shared-model'
import { getYear } from 'date-fns/getYear'
import { subYears } from 'date-fns/subYears'

export function useFundRaisingStatus(fund: InvestorFundListItem) {
  const swrInvestorStrategy = useInvestorStrategyListItem(() => {
    if (fund.fundraisingStatus === null) {
      return fund.strategyId
    }

    return null
  })

  if (fund.fundraisingStatus) {
    return fund.fundraisingStatus
  } else if (swrInvestorStrategy.data?.latestFundId === fund.id) {
    return InvestorFundFundraisingStatus.Closed
  } else if (fund.launchYear !== null && fund.launchYear > getYear(subYears(new Date(), 5))) {
    return InvestorFundFundraisingStatus.FinalClose
  } else if (fund.dealsTotalLastFiveYears === 1) {
    return InvestorFundFundraisingStatus.Closed
  }

  return undefined
}
