import { useAssetList } from '@gain/api/app/hooks'
import { AssetListItem } from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import { listSort } from '@gain/rpc/utils'
import { useScrollSpy, useScrollToSectionCallback } from '@gain/utils/dom'
import { useIsXs } from '@gain/utils/responsive'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback, useRef } from 'react'

import { CardContent } from '../../../common/card/card'
import ChartWrapper from '../../../common/chart/chart-wrapper'
import ListNavigation, { LIST_NAVIGATION_TOP_SPACING } from '../../../common/list-navigation'
import { BenchmarkingMovedBar } from '../../benchmarking'
import {
  AssetChartSizeType,
  CompanyBubbleChart,
  CompanyTreeMapChart,
  getDefaultAssetChartGroupType,
} from '../../chart'
import { CHART_HEIGHT, MAX_TREEMAP_ITEMS_LIMIT } from './chart-constants'

export interface ChartsAssetAnalysisProps {
  filter?: ListFilter<AssetListItem>[]
  spacingTop?: number
  benchmarkingPath: string
}

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  maxWidth: '100%',
  width: '100%',
}))

const StyledCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  minWidth: 0,
  width: '100%',
}))

const SECTIONS = [
  'Overview',
  'Size vs. growth',
  'Growth vs. margin',
  'Strategic maturity',
  'Holding period',
  'CEO tenure',
]

export default function ChartsAssetAnalysis({
  filter,
  spacingTop = LIST_NAVIGATION_TOP_SPACING,
  benchmarkingPath,
}: ChartsAssetAnalysisProps) {
  const isXs = useIsXs()
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const sectionsRef = useRef<Array<HTMLElement | null>>([])
  const activeSectionIndex = useScrollSpy(sectionsRef)
  const handleScrollToSection = useScrollToSectionCallback(
    sectionsRef,
    activeSectionIndex,
    spacingTop
  )

  const { data, loading } = useAssetList(() => {
    if (!filter) {
      return null
    }

    return { filter, sort: [listSort('revenueEur', 'desc')], limit: MAX_TREEMAP_ITEMS_LIMIT }
  })

  const handleSectionRef = useCallback(
    (title: string) => (el: HTMLElement | null) => {
      const index = SECTIONS.indexOf(title)

      if (index === -1) {
        throw new Error(`Could not find ${title} in sections`)
      }

      sectionsRef.current[index] = el
    },
    []
  )

  const defaultGroupTypeId = getDefaultAssetChartGroupType(data.items)

  return (
    <StyledRoot>
      {isLgUp && (
        <ListNavigation
          activeItemIndex={activeSectionIndex}
          items={SECTIONS}
          onItemClick={handleScrollToSection}
          spacingTop={spacingTop}
        />
      )}
      <StyledCardContainer>
        <ChartWrapper
          ref={handleSectionRef('Overview')}
          cardHeaderProps={{ absolute: true }}
          loading={loading}
          minHeight={isXs ? 538 : 508}
          title={'Overview'}>
          <CardContent sx={{ pt: isXs ? 7 : 3 }}>
            <CompanyTreeMapChart
              assets={data.items}
              defaultGroupTypeId={defaultGroupTypeId}
              height={400}
            />
          </CardContent>
        </ChartWrapper>

        <BenchmarkingMovedBar benchmarkingUrl={benchmarkingPath} />

        <ChartWrapper
          ref={handleSectionRef('Size vs. growth')}
          loading={loading}
          title={'Size vs. growth'}>
          <CompanyBubbleChart
            assets={data.items}
            defaultGroupTypeId={defaultGroupTypeId}
            defaultSizeType={AssetChartSizeType.Fte}
            fixedHeight={CHART_HEIGHT}
            xScale={'ebitda'}
            yScale={'ratingOrganicGrowth'}
          />
        </ChartWrapper>

        <ChartWrapper
          ref={handleSectionRef('Growth vs. margin')}
          loading={loading}
          title={'Growth vs. margin'}>
          <CompanyBubbleChart
            assets={data.items}
            defaultGroupTypeId={defaultGroupTypeId}
            defaultSizeType={AssetChartSizeType.Fte}
            fixedHeight={CHART_HEIGHT}
            xScale={'ratingOrganicGrowth'}
            yScale={'ratingEbitda'}
          />
        </ChartWrapper>

        <ChartWrapper
          ref={handleSectionRef('Strategic maturity')}
          loading={loading}
          title={'Strategic maturity'}>
          <CompanyBubbleChart
            assets={data.items}
            defaultGroupTypeId={defaultGroupTypeId}
            defaultSizeType={AssetChartSizeType.Fte}
            fixedHeight={CHART_HEIGHT}
            xScale={'ratingBuyAndBuild'}
            yScale={'ratingMultinational'}
          />
        </ChartWrapper>

        <ChartWrapper
          ref={handleSectionRef('Holding period')}
          loading={loading}
          title={'Holding period'}>
          <CompanyBubbleChart
            assets={data.items}
            defaultGroupTypeId={defaultGroupTypeId}
            defaultSizeType={AssetChartSizeType.Fte}
            fixedHeight={CHART_HEIGHT}
            xScale={'yearsInPortfolio'}
            yScale={'ratingGrowth'}
          />
        </ChartWrapper>

        <ChartWrapper
          ref={handleSectionRef('CEO tenure')}
          loading={loading}
          title={'CEO tenure'}>
          <CompanyBubbleChart
            assets={data.items}
            defaultGroupTypeId={defaultGroupTypeId}
            defaultSizeType={AssetChartSizeType.Fte}
            fixedHeight={CHART_HEIGHT}
            xScale={'ceoAge'}
            yScale={'yearsWithBusiness'}
          />
        </ChartWrapper>
      </StyledCardContainer>
    </StyledRoot>
  )
}
