import Head from '@gain/modules/head'
import { Investor } from '@gain/rpc/app-model'
import { useIsXs } from '@gain/utils/responsive'

import ConditionalWrapper from '../../../common/conditional-wrapper'
import RepresentativesListView from '../../../features/list-view/representative-list-view/representatives-list-view'
import MobilePageListViewContainer from '../../../layout/mobile-page-list-view-container'
import { generateInvestorPagePortfolioTabPath, useInvestorPageParams } from '../../utils'

interface RouteRepresentativesProps {
  investor: Investor
}

export default function RouteRepresentatives({ investor }: RouteRepresentativesProps) {
  const { preview } = useInvestorPageParams()
  const isXs = useIsXs()

  return (
    <>
      <Head>
        <title>{investor.name} - Representatives</title>
      </Head>

      <ConditionalWrapper
        condition={isXs}
        wrapper={(children) => (
          <MobilePageListViewContainer>{children}</MobilePageListViewContainer>
        )}>
        <RepresentativesListView
          investorId={investor.id}
          portfolioPath={generateInvestorPagePortfolioTabPath({
            investorId: investor.id,
            investorName: investor.name,
            preview,
          })}
        />
      </ConditionalWrapper>
    </>
  )
}
