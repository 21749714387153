import { ListItemKey } from '@gain/rpc/list-model'
import { useCallback, useEffect, useState } from 'react'

import { CheckboxList, CheckboxListValue } from '../../../../../common/checkbox-list'
import {
  FilterCheckboxListOption,
  FilterCheckboxListOptions,
  FilterCheckboxListValue,
  FilterConfigCheckboxList,
  OptionValueType,
} from '../../filter-config/filter-config-model'
import { isOptionGroup } from '../../filter-config/filter-config-util'
import { formatCheckboxListValue } from '../../filter-label/filter-value-formatter'
import { useTrackFilterEvent } from '../../use-track-filter-event'
import { useFilterField } from '../filter-field-context-provider'
import FilterCheckboxListSearch from './filter-checkbox-list-search'
import FilterCheckboxOption from './filter-checkbox-option'

function filterOptions<ValueType extends OptionValueType = OptionValueType>(
  options: FilterCheckboxListOptions<ValueType>,
  searchQuery: string
): FilterCheckboxListOptions<ValueType> {
  // Return all options when the search query contains less than 2 characters
  if (searchQuery.trim().length < 2) {
    return options
  }

  return options.reduce((acc, current) => {
    if (isOptionGroup(current)) {
      const matches = filterOptions(current.options, searchQuery)

      if (matches.length) {
        acc.push({
          ...current,
          expanded: true,
          options: matches,
        })
      }
    } else if (current.label.toLowerCase().includes(searchQuery)) {
      acc.push(current)
    }

    return acc
  }, new Array<FilterCheckboxListOption<ValueType>>())
}

interface FilterCheckboxListProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>,
  ValueType extends OptionValueType = OptionValueType
> {
  filter: FilterConfigCheckboxList<Item, FilterField, ValueType>
}

export default function FilterCheckboxList<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>,
  ValueType extends OptionValueType = OptionValueType
>({ filter }: FilterCheckboxListProps<Item, FilterField, ValueType>) {
  const trackEvent = useTrackFilterEvent()
  const field = useFilterField<FilterCheckboxListValue<ValueType>>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [visibleOptions, setVisibleOptions] = useState(filter.options)

  const handleChange = useCallback(
    (value: CheckboxListValue<ValueType>) => {
      field.input.onChange(value)
      const eventLabel = formatCheckboxListValue(value, filter, true)
      if (eventLabel !== null) {
        trackEvent(`Filter ${filter.label} value change`, eventLabel, null, 500)
      }
    },
    [field.input, filter, trackEvent]
  )

  useEffect(() => {
    const options = filterOptions(filter.options, searchQuery.toLowerCase())

    setVisibleOptions((prev) => {
      if (JSON.stringify(options) === JSON.stringify(prev)) {
        return prev
      }

      return options
    })
  }, [filter.options, searchQuery])

  return (
    <>
      {filter.searchable && (
        <FilterCheckboxListSearch
          onChange={setSearchQuery}
          searchQuery={searchQuery}
        />
      )}

      <div>
        <CheckboxList
          onChange={handleChange}
          value={field.input.value}>
          {visibleOptions.map((option) => (
            <FilterCheckboxOption
              key={option.label}
              filter={filter}
              highlightText={searchQuery}
              option={option}
            />
          ))}
        </CheckboxList>
      </div>
    </>
  )
}
