import { styled } from '@mui/material/styles'
import { ChangeEvent, useCallback } from 'react'

import { Option } from '../filter-config/filter-config-model'

export const SelectContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: 4,
  backgroundColor: theme.palette.background.paper,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  height: 32,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)',
}))

const StyledSelect = styled('select')(({ theme }) => ({
  display: 'block',
  flex: 1,
  borderWidth: 0,
  textAlignLast: 'right',
  color: theme.palette.text.primary,
  fontSize: theme.typography.body2.fontSize,
  lineHeight: theme.typography.body2.lineHeight,
  fontWeight: theme.typography.body2.fontWeight,
  appearance: 'none',
  overflow: 'hidden',
  maxWidth: '80%',
  textOverflow: 'ellipsis',
  ':focus': {
    outline: 'none',
  },
}))

const SelectLabel = styled('label')(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
}))

export interface FilterRangeSelectProps {
  label: string
  options: Option<number>[]
  value: number | null
  onChange: (value: number | null) => void
  autoFocus?: boolean
}

export default function FilterRangeSelect({
  label,
  options,
  value,
  onChange,
  autoFocus,
}: FilterRangeSelectProps) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      onChange(event.currentTarget.value === '' ? null : Number(event.currentTarget.value))
    },
    [onChange]
  )

  return (
    <SelectContainer>
      <SelectLabel>{label}</SelectLabel>
      <StyledSelect
        autoFocus={autoFocus}
        onChange={handleChange}
        value={typeof value === 'number' ? value : ''}>
        <option value={''}>Select</option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </SelectContainer>
  )
}
