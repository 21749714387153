import './flag.scss'

import { isDefined } from '@gain/utils/common'
import { COUNTRY_CODE_MAP } from '@gain/utils/countries'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import { DetailedHTMLProps, HTMLAttributes, useMemo } from 'react'

const StyledFlag = styled('div')({
  display: 'inline-block',
  borderRadius: 2,
  width: 16,
  height: 12,
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
})

export interface FlagProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  code?: string | null
}

export default function Flag({ code, className, ...rest }: FlagProps) {
  const flagClass = useMemo(() => {
    if (!isDefined(code) || code.trim().length === 0) {
      return null
    }

    return `fi-${code.toLowerCase()}`
  }, [code])

  if (!flagClass || !code) {
    return null
  }

  return (
    <Tooltip
      title={COUNTRY_CODE_MAP[code as keyof typeof COUNTRY_CODE_MAP]}
      disableInteractive>
      <StyledFlag
        className={clsx(className, flagClass)}
        {...rest}
      />
    </Tooltip>
  )
}
