import { SearchIcon } from '@gain/components/icons'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import { buttonClasses } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { inputAdornmentClasses } from '@mui/material/InputAdornment'
import { inputBaseClasses } from '@mui/material/InputBase'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { StandardTextFieldProps } from '@mui/material/TextField/TextField'
import { Fragment } from 'react'

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .${inputBaseClasses.root}.${autocompleteClasses.inputRoot}`]: {
    height: 40,
    backgroundColor: theme.palette.grey['100'],
    padding: `${theme.spacing(0, 1.5)}`,
    [`& .${inputBaseClasses.input}`]: {
      padding: theme.spacing(0, 1.5),
      [`&.${inputBaseClasses.inputAdornedStart}`]: {
        marginLeft: 0,
      },
      [`&.${inputAdornmentClasses.root}`]: {
        fontSize: 16,
      },
    },
    [`&.${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: theme.palette.grey['100'],
    },
    [`&.${inputBaseClasses.focused}`]: {
      backgroundColor: theme.palette.background.paper,
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.palette.grey['300'],
      },
      [`& .${buttonClasses.root}, .${buttonClasses.endIcon} svg`]: {
        color: theme.palette.primary.main,
      },
    },
  },
}))

type DialogSearchProps = StandardTextFieldProps & {
  loading: boolean
  placeholder: string
}

/**
 * DialogSearch is a search input styled specifically for use in
 * Dialog components.
 */
export default function DialogSearch({
  loading,
  InputProps: { endAdornment, ...InputProps } = {},
  ...params
}: DialogSearchProps) {
  return (
    <StyledTextField
      InputProps={{
        ...InputProps,
        startAdornment: <SearchIcon fontSize={'inherit'} />,
        endAdornment: (
          <Fragment>
            {loading ? (
              <Stack justifyContent={'center'}>
                <CircularProgress
                  color={'primary'}
                  size={20}
                />
              </Stack>
            ) : null}
            {endAdornment}
          </Fragment>
        ),
      }}
      {...params}
    />
  )
}
