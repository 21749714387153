import { UserProfile } from '@gain/rpc/app-model'
import { ListItemKey } from '@gain/rpc/list-model'
import { isDefined } from '@gain/utils/common'
import { formatDate, formatMonthYear } from '@gain/utils/date'
import { isNull } from '@gain/utils/typescript'

import { distanceToMeters, formatRadius } from '../filter/filter-geo-point-util'
import {
  FilterCheckboxListOption,
  FilterCheckboxListValue,
  FilterCityValue,
  FilterConfig,
  FilterConfigCheckboxList,
  FilterConfigRange,
  FilterGeoPointValue,
  FilterGeoPolygonValue,
  FilterRangeValue,
  FilterRangeValuePart,
  FilterRangeValueType,
  Option,
  OptionGroup,
  OptionValueType,
} from '../filter-config/filter-config-model'

function formatRangeFilterItemOption<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  minOrMaxValue: FilterRangeValuePart,
  filter: FilterConfigRange<Item, FilterField>
): string | null {
  if (!isDefined(minOrMaxValue)) {
    return null
  }

  if (filter.options) {
    const result = filter.options.find((option) => option.value === minOrMaxValue)
    if (result !== undefined) {
      return result.label
    }
  }

  return minOrMaxValue.toString(10)
}

function formatRangeFilterOptions<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(value: Exclude<FilterRangeValue, null>, filter: FilterConfigRange<Item, FilterField>) {
  const [min, max] = value.map((minOrMaxValue) =>
    formatRangeFilterItemOption(minOrMaxValue, filter)
  )

  if (isDefined(min) && isDefined(max)) {
    if (min === max) {
      return min
    }
    return `${min} - ${max}`
  }

  if (isDefined(min)) {
    return `>${min}`
  }

  if (isDefined(max)) {
    return `<${max}`
  }

  return null
}

function formatRangeFilterValuePart(valueType: FilterRangeValueType, value: FilterRangeValuePart) {
  if (valueType === 'month' && typeof value === 'string') {
    const [year, month] = value.split('-').map((part) => parseInt(part, 10))
    return formatMonthYear(month, year, { separator: '' })
  } else if (valueType === 'date' && typeof value === 'string') {
    const [year, month, date] = value.split('-').map((part) => parseInt(part, 10))
    return formatDate(new Date(year, month - 1, date), {
      format: 'date',
    })
  }

  return value
}

function formatRangeFilterDateValues<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(value: Exclude<FilterRangeValue, null>, filter: FilterConfigRange<Item, FilterField>) {
  const [min, max] = value.map((part) => formatRangeFilterValuePart(filter.valueType, part))

  if (isDefined(min) && isDefined(max)) {
    if (min === max) {
      return `${min}`
    }
    return `${min} → ${max}`
  }

  if (isDefined(min)) {
    return `After ${min}`
  }

  if (isDefined(max)) {
    return `Before ${max}`
  }

  return null
}

function formatRangeFilterNumberValues(
  value: Exclude<FilterRangeValue, null>,
  prefix: string | undefined,
  suffix: string | undefined
) {
  const [min, max] = value

  if (isDefined(min) && isDefined(max)) {
    if (min === max) {
      return `${prefix || ''}${min}${suffix || ''}`
    }
    return `${prefix || ''}${min}-${max}${suffix || ''}`
  }

  if (isDefined(min)) {
    return `≥ ${prefix || ''}${min}${suffix || ''}`
  }

  if (isDefined(max)) {
    return `≤ ${prefix || ''}${max}${suffix || ''}`
  }

  return null
}

function formatRangeFilterValues<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(value: Exclude<FilterRangeValue, null>, filter: FilterConfigRange<Item, FilterField>) {
  switch (filter.valueType) {
    case 'number':
      return formatRangeFilterNumberValues(value, filter.prefix, filter.suffix)
    case 'month':
    case 'date':
      return formatRangeFilterDateValues(value, filter)
  }
}

export function formatRangeFilterValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(value: FilterRangeValue, filter: FilterConfigRange<Item, FilterField>) {
  if (value === null || !Array.isArray(value) || value.every(isNull) || value.length > 2) {
    return null
  }

  if (filter.options) {
    return formatRangeFilterOptions(value, filter)
  }

  return formatRangeFilterValues(value, filter)
}

/**
 * Returns values of selected options. If all options are selected in an option group it returns
 * the option group's name rather than all individual options.
 *
 * For example: Europe, South Africa, US
 *
 * If > 3 options are selected it will return the nr of selected options instead:
 * "4 selected". To disable this behaviour set disableTruncate.
 *
 * Returns null if no options are selected.
 */
export function formatCheckboxListValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  value: FilterCheckboxListValue,
  filter: FilterConfigCheckboxList<Item, FilterField>,
  disableTruncate?: boolean
): string | null {
  if (value === null) {
    return null
  }

  // Returns all values belonging to a certain option
  const findValues = (
    opt: Option<OptionValueType> | OptionGroup<OptionValueType>
  ): (string | number)[] => {
    if (opt.type === 'option') {
      return [opt.value]
    }

    return opt.options.flatMap((opt2) => findValues(opt2))
  }

  // Recursive function to find selected option labels
  const findSelected = (opt: FilterCheckboxListOption<OptionValueType>): string[] => {
    // Check if a single option is selected
    if (opt.type === 'option') {
      return value.includes(opt.value) ? [opt.label] : []
    }

    // If all values of an option group are selected we return the option group's label
    if (opt.options.flatMap(findValues).every((val) => value.includes(val))) {
      return [opt.label]
    }

    // ... or else make a recursive call to find nested selected options
    return opt.options.flatMap(findSelected)
  }

  const selected = filter.options.flatMap(findSelected)

  if (!disableTruncate && selected.length > 3) {
    return `${selected.length} selected`
  }

  return selected.join(', ')
}

function formatGeoPointFilterValue(value: FilterGeoPointValue, userProfile: UserProfile) {
  if (value === null) {
    return null
  }

  return `${value.location} ${formatRadius(
    Math.round(value.distance / distanceToMeters[userProfile.unitSystem]),
    userProfile
  )}`
}

function formatGeoPolygonFilterValue(value: FilterGeoPolygonValue) {
  return value === null ? null : value.formatted
}

function formatCityFilterValue(value: FilterCityValue) {
  if (value === null) {
    return null
  }

  if (value.length > 1) {
    return `${value.length} selected`
  }

  return value
    .map((item) => `${item.city} (${[item.region, item.countryCode].filter(Boolean).join(', ')})`)
    .join(',')
}

export function formatFilterValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>(value: any, filter: FilterConfig<Item, FilterField>, userProfile: UserProfile) {
  if (value === null) {
    return null
  }

  switch (filter.type) {
    case 'autocomplete':
      return Array.isArray(value) ? value.join(', ') : null // determined in component because it's async
    case 'checkbox':
      return value === true ? 'true' : null
    case 'checkbox-list':
      return formatCheckboxListValue(value, filter)
    case 'range':
      return formatRangeFilterValue(value, filter)
    case 'geo-point':
      return formatGeoPointFilterValue(value, userProfile)
    case 'geo-polygon':
      return formatGeoPolygonFilterValue(value)
    case 'city':
      return formatCityFilterValue(value)
    case 'similar-to':
      return 'TODO' // determined in component because it's async
    case 'text':
      return `${value}`
    default:
      throw new Error(`Unknown filter type ${filter.type}`)
  }
}
