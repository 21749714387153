import Fade from '@mui/material/Fade'
import { iconButtonClasses } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { MouseEvent, RefObject, useCallback, useEffect, useState } from 'react'

import ButtonScroll from '../button-scroll'

const StyledButtonScroll = styled(ButtonScroll)(({ theme }) => ({
  height: 39,
  zIndex: 4,
  [`& .${iconButtonClasses.root}`]: {
    transition: theme.transitions.create('color'),
    '&:hover': {
      backgroundColor: 'initial',
      color: theme.palette.primary.main,
    },
  },
}))

export interface TableScrollButtonsProps {
  scrollContainerRef: RefObject<HTMLElement>
  availableWidth: number
  offsetLeft: number
}

export default function TableScrollButtons({
  scrollContainerRef,
  availableWidth,
  offsetLeft,
}: TableScrollButtonsProps) {
  const [isScrollStart, setIsScrollStart] = useState(true)
  const [isScrollEnd, setIsScrollEnd] = useState(false)

  const handleScroll = useCallback(() => {
    const element = scrollContainerRef.current

    if (!element) {
      return
    }

    setIsScrollStart(element.scrollLeft === 0)
    setIsScrollEnd(element.scrollLeft + element.clientWidth >= element.scrollWidth)
  }, [scrollContainerRef])

  useEffect(() => {
    if (!scrollContainerRef.current) {
      return
    }

    const elem = scrollContainerRef.current

    elem.addEventListener('scroll', handleScroll)

    return () => {
      elem.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, scrollContainerRef])

  const handleScrollTo = useCallback(
    (direction: 'left' | 'right') => (event: MouseEvent) => {
      if (scrollContainerRef.current) {
        event.stopPropagation()
        event.preventDefault()

        const offset =
          direction === 'left'
            ? scrollContainerRef.current.scrollLeft - availableWidth
            : scrollContainerRef.current.scrollLeft + availableWidth

        scrollContainerRef.current.scrollTo({
          left: offset,
          behavior: 'smooth',
        })
      }
    },
    [scrollContainerRef, availableWidth]
  )

  return (
    <>
      <Fade in={!isScrollStart}>
        <StyledButtonScroll
          direction={'left'}
          onClick={handleScrollTo('left')}
          sx={{
            top: 0,
            left: offsetLeft,
            position: 'absolute',
          }}
        />
      </Fade>
      <Fade in={!isScrollEnd}>
        <StyledButtonScroll
          direction={'right'}
          onClick={handleScrollTo('right')}
          sx={{
            top: 0,
            right: 0,
            position: 'absolute',
          }}
        />
      </Fade>
    </>
  )
}
