import Typography from '@gain/components/typography'
import { isTruthy } from '@gain/utils/common'
import { formatManagerPosition } from '@gain/utils/manager'

import InvestorContentLink from '../../../../features/investor/investor-content-link'
import { ManagerWithInvestor } from './asset-management-util'

interface ManagerRoleCellProps {
  manager: ManagerWithInvestor
}

export default function ManagerRoleCell({ manager }: ManagerRoleCellProps) {
  if (manager.investor) {
    return (
      <InvestorContentLink
        id={manager.investor.id}
        logoFileUrl={manager.investor.logoFileUrl}
        name={manager.investor.name}
      />
    )
  } else if (manager.managementPosition) {
    const formattedPosition = [
      formatManagerPosition(manager.managementPosition, { disableEnumCheck: true }),
      manager.joinYear,
    ]
      .filter(isTruthy)
      .join(' since ')

    return (
      <Typography
        color={'inherit'}
        fontSize={'inherit'}
        noWrap>
        {formattedPosition}
      </Typography>
    )
  }

  return <>-</>
}
