import Snackbar from '@gain/components/snackbar'
import { AssetListItem, BookmarkListItem, BookmarkListType } from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import { DialogProps } from '@mui/material/Dialog'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { useCreateBookmarkList } from '../../../api/bookmark-list-hooks'
import { BOOKMARKS_FILTERED_PATH } from '../../../routes/utils'
import BookmarkListTitleDialog from '../bookmark-list-title-dialog/bookmark-list-title-dialog'
import BookmarksListFiltersDialog from '../bookmarks-list-edit/bookmarks-list-filters-dialog'

const SNACKBAR_KEY = 'created-custom-asset-query-list'

interface BookmarksFilteredCreateDialogProps extends Omit<DialogProps, 'onClose'> {
  filters?: ListFilter<AssetListItem>[]
  open: boolean
  onClose: (reason: 'cancel' | 'create', list?: BookmarkListItem) => void
  disableRedirect?: boolean
  dialogTitle?: string
  enableAddFilters?: boolean
}

export default function BookmarksFilteredCreateDialog({
  onClose,
  filters = [],
  open,
  disableRedirect = false,
  enableAddFilters = false,
  dialogTitle = 'Bookmark this filtered list',
  ...props
}: BookmarksFilteredCreateDialogProps) {
  const history = useHistory()
  const [titleDialogOpen, setTitleDialogOpen] = useState(false)
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false)
  const [title, setTitle] = useState('')
  const newList = useRef<BookmarkListItem | null>(null)

  const { enqueueSnackbar } = useSnackbar()
  const createList = useCreateBookmarkList()

  useEffect(() => {
    setTitleDialogOpen(open)
  }, [open])

  // Handle submitting the title, either directly submitting or adding filters
  const handleTitleSubmit = async (newTitle: string) => {
    setTitle(newTitle)
    setTitleDialogOpen(false)

    // Either show the dialog for adding filters or go directly to submitting
    if (enableAddFilters) {
      setFiltersDialogOpen(true)
    } else {
      await handleSubmit(newTitle)
      handleClose()
    }
  }

  // Handle submitting the filters
  const handleFiltersSubmit = async (newFilters: ListFilter<AssetListItem>[] | null) => {
    await handleSubmit(title, newFilters)
    handleClose()
  }

  // Create the list and save the filters
  const handleSubmit = async (
    newTitle: string,
    newFilters?: ListFilter<AssetListItem>[] | null
  ) => {
    // Create the list
    newList.current = await createList(
      newTitle,
      BookmarkListType.LegacyCustomAssetQueryList,
      newFilters ?? filters
    )
    return newList.current
  }

  // Close title dialog and cancel the workflow
  const handleTitleDialogClose = () => {
    setTitleDialogOpen(false)
    onClose('cancel')
  }

  // Close filter dialog and cancel the workflow
  const handleFilterDialogClose = () => {
    setFiltersDialogOpen(false)
    onClose('cancel')
  }

  // Communicate list if success
  const handleClose = () => {
    if (!newList.current) {
      onClose('cancel')
      return
    }

    // Either redirect or show snackbar upon success
    const path = generatePath(BOOKMARKS_FILTERED_PATH, { listId: newList.current.id })
    if (!disableRedirect) {
      history.push(path)
    } else {
      enqueueSnackbar(undefined, {
        key: SNACKBAR_KEY,
        content: () => (
          <Snackbar
            action={{
              title: 'View',
              onClick: () => history.push(path),
            }}
            id={SNACKBAR_KEY}
            message={`Filtered list “${newList.current?.title}” created`}
            variant={'info'}
          />
        ),
        preventDuplicate: true,
      })
    }

    onClose('create', newList.current)
  }

  return (
    <>
      <BookmarkListTitleDialog
        {...props}
        dialogTitle={dialogTitle}
        onClose={handleTitleDialogClose}
        onSubmit={handleTitleSubmit}
        open={titleDialogOpen}
      />

      <BookmarksListFiltersDialog
        dialogTitle={'Apply filters'}
        onClose={handleFilterDialogClose}
        onSubmit={handleFiltersSubmit}
        open={filtersDialogOpen}
      />
    </>
  )
}
