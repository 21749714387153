import { BriefcaseMoneyIcon } from '@gain/components/icons'
import Head from '@gain/modules/head'
import {
  AssetListItem,
  FILTERABLE_INVESTOR_STRATEGY_LIST_ITEM_TO_ASSET_MAP,
  FilterableInvestorStrategyListItem,
  InvestorStrategyListItem,
} from '@gain/rpc/app-model'
import { listSort } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import Hidden from '@mui/material/Hidden'

import {
  MobileListItem,
  MobileListItemIcon,
  MobileListItemText,
  MobileListItemValue,
} from '../../common/list-mobile'
import Logo from '../../common/logo'
import { RowComponentProps } from '../../common/virtual-table'
import { AssetFilterField } from '../../features/asset/asset-filter-bar'
import ColumnPickerButton from '../../features/column-picker/column-picker-button'
import { useFilterModelQueryParam } from '../../features/filter/filter-bar'
import {
  INVESTOR_STRATEGY_DEFAULT_FILTERS,
  INVESTOR_STRATEGY_FILTER_MAP,
  INVESTOR_STRATEGY_FILTER_MENU,
  InvestorStrategyFilterField,
} from '../../features/investor-strategy/investor-strategy-filter-bar'
import filterToInvestorStrategyTableColumnMapping from '../../features/investor-strategy/investor-strategy-table/filter-to-investor-strategy-table-column-mapping'
import { investorStrategyTableColumnNames } from '../../features/investor-strategy/investor-strategy-table/investor-strategy-table-columns'
import useInvestorStrategyListViewColumns from '../../features/investor-strategy/investor-strategy-table/use-investor-strategy-list-view-columns'
import ListView, { createListViewRowLink } from '../../features/list-view'
import useActiveFilterColumns from '../../features/list-view/use-active-filter-columns'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import MobilePageHeader from '../../layout/mobile-page-header'
import {
  generateInvestorStrategyPagePath,
  INVESTOR_STRATEGY_PAGE_PATH,
  RouteInvestorStrategyTabs,
} from '../investor-strategy'

const InvestorStrategyListPageInvestorStrategyRow = createListViewRowLink<
  'data.listInvestorStrategies',
  InvestorStrategyListItem,
  FilterableInvestorStrategyListItem,
  InvestorStrategyFilterField,
  'data.listAssets',
  AssetListItem,
  AssetListItem,
  AssetFilterField
>(`/${RouteInvestorStrategyTabs.Portfolio}`, FILTERABLE_INVESTOR_STRATEGY_LIST_ITEM_TO_ASSET_MAP)

export default function InvestorListPageStrategyTab() {
  const isXs = useIsXs()

  useTrackPageView('investor', { tab: 'strategies', requireTab: true, list: true })

  const [filterModel] = useFilterModelQueryParam()
  const activeFilterColumns = useActiveFilterColumns(
    filterModel,
    INVESTOR_STRATEGY_FILTER_MAP,
    investorStrategyTableColumnNames,
    filterToInvestorStrategyTableColumnMapping
  )
  const columns = useInvestorStrategyListViewColumns(activeFilterColumns)

  return (
    <>
      <Head>
        <title>Investors - Strategies</title>
      </Head>
      {isXs && (
        <MobilePageHeader
          title={'Strategies'}
          variant={'list'}
        />
      )}
      <ListView
        addFilterMenu={INVESTOR_STRATEGY_FILTER_MENU}
        defaultFilterModel={INVESTOR_STRATEGY_DEFAULT_FILTERS}
        defaultSort={[listSort<InvestorStrategyListItem>('fundsRaisedLastFiveYearsEur', 'desc')]}
        filterBarSearchLabel={'Strategy name'}
        filterBarSearchPlaceholder={'E.g. Growth equity'}
        filterConfigMap={INVESTOR_STRATEGY_FILTER_MAP}
        inline={isXs}
        method={'data.listInvestorStrategies'}
        renderPageActions={() => (
          <Hidden mdDown>
            <ColumnPickerButton
              activeFilterColumns={activeFilterColumns}
              columnConfigId={'investorStrategy'}
            />
          </Hidden>
        )}
        sm={{
          variant: 'virtual-table',
          VariantProps: {
            columns,
            RowComponentProps: ({ row }) =>
              ({
                component: InvestorStrategyListPageInvestorStrategyRow,
                to: generateInvestorStrategyPagePath(
                  {
                    investorId: row.investorId,
                    investorName: row.investorName,
                    strategyId: row.strategyId,
                    strategyName: row.strategyName,
                  },
                  INVESTOR_STRATEGY_PAGE_PATH
                ),
              } as RowComponentProps),
          },
        }}
        xs={{
          variant: 'list',
          VariantProps: {
            headerProps: {
              title: 'Name',
              secondaryTitle: 'Companies',
            },
            renderListItem: (item) => (
              <MobileListItem
                key={item.strategyId}
                disableDivider>
                <MobileListItemIcon>
                  <Logo
                    defaultIcon={<BriefcaseMoneyIcon />}
                    size={40}
                    src={item.investorLogoFileUrl}
                  />
                </MobileListItemIcon>
                <MobileListItemText
                  primary={item.strategyName}
                  secondary={item.investorName}
                />
                <MobileListItemValue>{item.assetsTotal}</MobileListItemValue>
              </MobileListItem>
            ),
          },
        }}
        disableOrFilter
      />
    </>
  )
}
