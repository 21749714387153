import { AssetListItem } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { ratingInvestmentCriteriaMap, RatingKeys } from '@gain/utils/investment-criteria'
import { useTableRowHoverContext } from '@gain/utils/table'
import { useMemo } from 'react'

import Rating from '../../../common/rating'

export const useInvestmentCriteriaLabel = (
  field: RatingKeys<AssetListItem>,
  rating: number | null | undefined
) => {
  return useMemo(() => {
    const criteria = ratingInvestmentCriteriaMap[field]

    const label = criteria.options
      .filter((item) => item.value === rating)
      .map((item) => item.label)
      .pop()

    return [label, criteria.suffix].filter(Boolean).join(' ')
  }, [field, rating])
}

export interface IRatingTableCellProps {
  rating: number | null | undefined
  type: RatingKeys<AssetListItem>
}

export function RatingTableCell({ rating, type }: IRatingTableCellProps) {
  const description = useInvestmentCriteriaLabel(type, rating)
  const hover = useTableRowHoverContext()

  if (!isDefined(rating)) {
    return <>-</>
  }

  if (!hover) {
    return <Rating value={rating} />
  }

  return description
}
