import { useResizeObserver } from '@gain/utils/dom'
import { RefObject, useCallback, useState } from 'react'

import { useMenuContext } from './menu-provider'

export default function useHasMenuOverflow<T extends HTMLElement>(ref: RefObject<T>) {
  const [hasOverflow, setHasOverflow] = useState(false)
  const [isOpen] = useMenuContext()

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return
    }

    const isOverflowing = ref.current.scrollHeight > ref.current.clientHeight
    setHasOverflow(isOverflowing && isOpen)
  }, [ref, isOpen])

  useResizeObserver(ref, handleResize)

  return hasOverflow
}
