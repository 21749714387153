import { ChevronRightIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { ReactElement } from 'react'

import Logo from '../../../../apps/app/src/common/logo'

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

const StyledChevronRight = styled(ChevronRightIcon)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.grey['500'],
}))

export interface TooltipHeaderProps {
  region?: string | null
  logoSrc?: string | null
  fullSizeLogo?: boolean
  defaultIcon?: ReactElement
  href?: string
  title: string | null
  subtitle?: string | null
}

export function TooltipHeader({
  region,
  logoSrc,
  fullSizeLogo,
  defaultIcon,
  href,
  title,
  subtitle,
}: TooltipHeaderProps) {
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      spacing={1.5}>
      {(defaultIcon || logoSrc) && (
        <Logo
          defaultIcon={defaultIcon}
          fullSize={fullSizeLogo}
          region={region}
          size={48}
          src={logoSrc}
        />
      )}
      <Stack
        direction={'column'}
        minWidth={0}>
        <StyledLink
          href={href}
          underline={href ? 'hover' : 'none'}>
          <Typography
            color={'textPrimary'}
            variant={'h5'}
            noWrap>
            {title || '-'}
          </Typography>
          {href && <StyledChevronRight />}
        </StyledLink>
        {subtitle && (
          <Typography
            color={'text.secondary'}
            variant={'body2'}
            noWrap>
            {subtitle}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default TooltipHeader
