import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { generatePath } from 'react-router-dom'

import { INDUSTRY_LIST_PATH } from '../../utils'
import { useHomeIndustryList } from '../use-home-industry-list'
import { IndustryListItem } from './industry-list-item'

export const StyledIndustryListButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(-1),
}))

export const StyledIndustryListTitleContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 0.75,
})

export const StyledIndustryListItemContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: theme.spacing(2),
  },
}))

const StyledItem = styled(IndustryListItem)(({ theme }) => ({
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

export interface IndustryListProps {
  nrOfIndustries: number
}

export default function IndustryList({ nrOfIndustries }: IndustryListProps) {
  const { data: industries } = useHomeIndustryList(nrOfIndustries)

  if (industries.items.length === 0) {
    return null
  }

  return (
    <div>
      <StyledIndustryListTitleContainer>
        <Typography
          color={'textPrimary'}
          variant={'subtitle2'}>
          Latest industry reports
        </Typography>
        <StyledIndustryListButton
          color={'primary'}
          {...{ component: Link, href: generatePath(INDUSTRY_LIST_PATH) }}
          variant={'text'}>
          View all
        </StyledIndustryListButton>
      </StyledIndustryListTitleContainer>
      <StyledIndustryListItemContainer>
        {industries.items.map((industry) => (
          <StyledItem
            key={industry.id}
            id={industry.id}
            imageSrc={industry.smallImageFileUrl}
            scope={industry.scope}
            title={industry.title}
          />
        ))}
      </StyledIndustryListItemContainer>
    </div>
  )
}
