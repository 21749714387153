import { Edit3Icon } from '@gain/components/icons'
import { InvestorFundNote } from '@gain/rpc/app-model'
import { useDialogState } from '@gain/utils/dialog'
import Chip from '@mui/material/Chip'

import FundNotesDialog from './fund-notes-dialog'

export interface FundNotesChipProps {
  notes: InvestorFundNote[]
}

export default function FundNotesChip({ notes }: FundNotesChipProps) {
  const [open, handleOpen, handleClose] = useDialogState()

  if (notes.length === 0) {
    return <>-</>
  }

  return (
    <>
      <FundNotesDialog
        notes={notes}
        onClose={handleClose}
        open={open}
      />
      {notes.length > 0 && (
        <Chip
          icon={<Edit3Icon />}
          label={`${notes.length} note${notes.length !== 1 ? 's' : ''}`}
          onClick={handleOpen}
        />
      )}
    </>
  )
}
