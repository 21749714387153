import { uniq } from 'lodash'

import { columnPickerConfigAsset } from './column-picker-config-asset'
import { columnPickerConfigInvestor } from './column-picker-config-investor'
import { columnPickerConfigInvestorFund } from './column-picker-config-investor-fund'
import { columnPickerConfigInvestorStrategy } from './column-picker-config-investor-strategy'
import { Column, NestedColumnGroup, TopLevelColumnGroup } from './column-picker-model'
import { ColumnPickerTypeState, useColumnPickerContext } from './column-picker-provider'

/**
 * Returns a list of all the columns that are defined within a given nested list
 * of groups.
 */
function getColumns(groups: Array<TopLevelColumnGroup | NestedColumnGroup>): Column[] {
  return groups.reduce((acc, current) => {
    if ('columns' in current) {
      acc.push(...current.columns)
    }

    if ('groups' in current) {
      acc.push(...getColumns(current.groups))
    }

    return acc
  }, new Array<Column>())
}

function useSavedState(
  state: ColumnPickerTypeState | undefined,
  visibleByDefaultColumns: string[],
  availableColumnNames: string[]
): ColumnPickerTypeState {
  if (state === undefined) {
    return {
      visibleColumns: visibleByDefaultColumns,
      showColumnsForActiveFilters: true,
    }
  }

  return {
    ...state,
    visibleColumns: state.visibleColumns.filter((name) => availableColumnNames.includes(name)),
  }
}

export interface ColumnPickerAPI {
  visibleColumns: string[]
  showColumn: (name: string) => void
  hideColumn: (name: string) => void
  isColumnVisible: (name: string) => boolean
  groups: TopLevelColumnGroup[]
  resetToDefault: () => void
  showColumnsForActiveFilters: boolean
  setShowColumnsForActiveFilters: (value: boolean) => void
}

const columnPickerConfigMap = {
  asset: columnPickerConfigAsset,
  investor: columnPickerConfigInvestor,
  investorStrategy: columnPickerConfigInvestorStrategy,
  investorFund: columnPickerConfigInvestorFund,
}

export type ColumnPickerConfigType = keyof typeof columnPickerConfigMap

/**
 * API to control column picker state for a given type (e.g. 'asset').
 */
export default function useColumnPicker(type: ColumnPickerConfigType): ColumnPickerAPI {
  const groups = columnPickerConfigMap[type]
  const availableColumns = getColumns(groups)
  const availableColumnNames = availableColumns.map((column) => column.name)
  const visibleByDefaultColumns = availableColumns
    .filter((column) => column.visibleByDefault)
    .map((column) => column.name)

  const [state, setState] = useColumnPickerContext()
  const { visibleColumns, showColumnsForActiveFilters } = useSavedState(
    state[type],
    visibleByDefaultColumns,
    availableColumnNames
  )

  // Write next visible columns to local storage
  const setVisibleColumns = (nextVisibleColumns: string[]) => {
    setState({
      ...state,
      [type]: {
        visibleColumns: nextVisibleColumns,
        showColumnsForActiveFilters,
      },
    })
  }

  // Write next show columns for active filters to local storage
  const setShowColumnsForActiveFilters = (nextShowColumnsForActiveFilters: boolean) => {
    setState({
      ...state,
      [type]: {
        visibleColumns,
        showColumnsForActiveFilters: nextShowColumnsForActiveFilters,
      },
    })
  }

  const showColumn = (name: string) => {
    setVisibleColumns(uniq(visibleColumns.slice().concat(name)))
  }

  const hideColumn = (name: string) => {
    setVisibleColumns(visibleColumns.filter((column) => column !== name))
  }

  const isColumnVisible = (name: string) => {
    return visibleColumns.includes(name)
  }

  const resetToDefault = () => {
    setState({
      ...state,
      [type]: {
        visibleColumns: visibleByDefaultColumns,
        showColumnsForActiveFilters: true,
      },
    })
  }

  return {
    visibleColumns: visibleColumns,
    showColumn,
    hideColumn,
    isColumnVisible,
    groups,
    resetToDefault,
    showColumnsForActiveFilters,
    setShowColumnsForActiveFilters,
  }
}
