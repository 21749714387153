import { UserPermissionObjectType } from '@gain/rpc/app-model'
import { createContext, ReactNode, useCallback, useContext, useState } from 'react'

import ShareDialogContainer from './share-dialog-container'

/**
 * Contains state for the share dialog.
 */
interface ShareContextType {
  objectType?: UserPermissionObjectType
  objectId?: number
  openShareDialog: (objectType: UserPermissionObjectType, objectId: number) => void
  closeShareDialog: () => void
}

const ShareContext = createContext<ShareContextType | null>(null)

export function useShareContext(): ShareContextType {
  const context = useContext(ShareContext)
  if (!context) {
    throw new Error('ShareContext is not provided')
  }
  return context
}

export interface ShareContextProviderProps {
  children: ReactNode
}

/**
 * ShareDialogProvider ensures the share dialog can be opened from anywhere,
 * even when parent components mount and unmount in the background (e.g. menu).
 */
export default function ShareDialogProvider({ children }: ShareContextProviderProps) {
  const [objectType, setObjectType] = useState<UserPermissionObjectType | undefined>(undefined)
  const [objectId, setObjectId] = useState<number | undefined>(undefined)

  const openShareDialog = useCallback((type: UserPermissionObjectType, id: number) => {
    setObjectType(type)
    setObjectId(id)
  }, [])

  const closeShareDialog = useCallback(() => {
    setObjectType(undefined)
    setObjectId(undefined)
  }, [])

  return (
    <ShareContext.Provider value={{ objectType, objectId, openShareDialog, closeShareDialog }}>
      {children}
      <ShareDialogContainer />
    </ShareContext.Provider>
  )
}
