import { SearchIcon } from '@gain/components/icons'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import Hidden from '@mui/material/Hidden'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { useSearchAPI } from '../search-hooks'
import SearchResultsRequestProfile from './request-profile/search-results-request-profile'
import SearchBackButton from './search-back-button'
import SearchRequestProfile from './search-request-profile'
import SearchResults from './search-results'
import SearchTopResult from './search-top-result'

const StyledRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
  height: '100%',
  padding: theme.spacing(2),

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(3, 6),
  },

  [theme.breakpoints.only('md')]: {
    padding: theme.spacing(3, 5),
  },

  [theme.breakpoints.only('sm')]: {
    padding: theme.spacing(3),
  },
}))

export const StyledContainer = styled('div')({
  maxWidth: 960,
  width: '100%',
  minHeight: '100%',
  height: '100%',
})

const StyledCenterContainer = styled('div')(({ theme }) => ({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
}))

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 40,
  height: 40,
  mb: 1,
}))

export default function SearchResultsContainer() {
  const { searching, searchTerm, hasResults } = useSearchAPI()

  return (
    <StyledRoot>
      <StyledContainer>
        {!searching && !hasResults && (
          <>
            <Hidden smDown>
              <div>
                <SearchBackButton />
              </div>
            </Hidden>

            <StyledCenterContainer>
              {!searchTerm && (
                <Fade in>
                  <Box textAlign={'center'}>
                    <StyledSearchIcon strokeWidth={3} />
                    <Typography
                      color={'text.secondary'}
                      variant={'body1'}>
                      Search for anything
                    </Typography>
                  </Box>
                </Fade>
              )}

              {searchTerm && (
                <Fade in>
                  <Box
                    alignItems={'center'}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    textAlign={'center'}>
                    <StyledSearchIcon strokeWidth={3} />

                    <Typography
                      align={'center'}
                      color={'text.secondary'}
                      variant={'body1'}>
                      No results found for “{searchTerm}”
                    </Typography>

                    <Divider sx={{ width: 267 }} />
                    <div>
                      <SearchResultsRequestProfile />
                    </div>
                  </Box>
                </Fade>
              )}
            </StyledCenterContainer>
          </>
        )}

        <Fade in={Boolean(searching || hasResults)}>
          <Stack gap={2}>
            <div>
              <SearchBackButton />
            </div>

            <Stack gap={2}>
              <SearchTopResult />
              <SearchResults />
              <SearchRequestProfile />
            </Stack>
          </Stack>
        </Fade>
      </StyledContainer>
    </StyledRoot>
  )
}
