import Head from '@gain/modules/head'
import { AssetListItem, InvestorFundListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'

import { ListViewAsset } from '../../../features/list-view'
import { useTrackPageView } from '../../../features/planhat/planhat-hooks'

export interface RoutePortfolioProps {
  fund: InvestorFundListItem
}

export default function RoutePortfolio({ fund }: RoutePortfolioProps) {
  useTrackPageView('investor_fund', { id: fund.id, tab: 'assets', requireTab: true })

  return (
    <>
      <Head>
        <title>
          {fund.investorName} - {fund.strategyName || ''} - {fund.name} - Portfolio
        </title>
      </Head>

      <ListViewAsset
        defaultFilter={listFilters<AssetListItem>(
          listFilter('fundIds', '=', fund.id),
          listFilter('ownerIds', '=', fund.investorId)
        )}
      />
    </>
  )
}
