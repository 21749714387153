import { UserProfile } from '@gain/rpc/app-model'
import { useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import {
  EMAIL_UNSUBSCRIBE_PATH,
  FORGOT_PASSWORD_RESET_PATH,
  ONBOARDING_PATH,
  SEARCH_PAGE_PATH,
} from '../../routes/utils'

// Allow use of gtag in TypeScript
declare global {
  function gtag(...args: [string, string, unknown]): void
}

// GaCategory defines the category of events sent to Google Analytics.
// In general these should match with the entities in our api model.
export type GaCategory =
  | 'Asset'
  | 'Advisor'
  | 'Advisor Deal'
  | 'Conference'
  | 'Credit'
  | 'Deal'
  | 'Industry'
  | 'Industry market segment'
  | 'Investor'
  | 'Investor fund'
  | 'Investor manager'
  | 'Investor strategy'
  | 'Legal entity'
  | 'Lender'
  | 'User'
  | 'User session'

/**
 * Returns true if pathname matches the path.
 */
function isPathMatch(pathname: string, path: string, exact = true) {
  const match = matchPath(pathname, {
    path: path,
    exact,
    strict: false,
  })

  return match !== null
}

/**
 * Removes secrets from the given pathname. See sanitizeUrl tests for examples.
 */
function sanitizePath(pathname: string) {
  if (isPathMatch(pathname, FORGOT_PASSWORD_RESET_PATH)) {
    return '/forgot-password/reset'
  } else if (isPathMatch(pathname, ONBOARDING_PATH, false)) {
    return '/onboarding'
  } else if (isPathMatch(pathname, EMAIL_UNSUBSCRIBE_PATH)) {
    return '/unsubscribe'
  }

  return pathname
}

/**
 * Sanitizes the search part of the URL. At the moment we are only interested in
 * tracking the 'q' query parameter on the search page. Every other query
 * parameter is ignored.
 */
function sanitizeSearch(url: URL) {
  if (isPathMatch(url.pathname, SEARCH_PAGE_PATH)) {
    return `q=${url.searchParams.get('q')}`
  }

  return ''
}

/**
 * Returns a sanitized URL where secrets and other URL parts that we're not
 * interested in tracking are removed.
 */
export function sanitizeUrl(urlString: string) {
  const url = new URL(urlString)
  url.pathname = sanitizePath(url.pathname)
  url.search = sanitizeSearch(url)
  url.hash = ''
  return url.toString()
}

/**
 * Returns a sanitized location that is used to track events in Google
 * Analytics. The result will only change when it differs from the previous
 * result.
 */
export default function useGaLocation() {
  const location = useLocation()
  const [url, setUrl] = useState(sanitizeUrl(window.location.toString()))

  useEffect(() => {
    setUrl(sanitizeUrl(window.location.toString()))
  }, [location])

  return url
}

export enum AccountType {
  LoggedOut = 'LOGGED_OUT',
  LoggedIn = 'LOGGED_IN',
  GainPro = 'GAIN_PRO',
}

/**
 * Returns account type dimension for GA4 based on UserProfile
 */
function getAccountType(profile: UserProfile | null | undefined): AccountType {
  if (!profile) {
    return AccountType.LoggedOut
  }

  if (profile.username.endsWith('gain.pro')) {
    return AccountType.GainPro
  }

  return AccountType.LoggedIn
}

/**
 * Sets the accountType and customerName user properties in GA4 based on UserProfile
 */
export function setUserProperties(profile: UserProfile | null) {
  const userProperties = {
    accountType: getAccountType(profile),
    customerName: profile?.customerName || 'LOGGED_OUT',
  }

  gtag('set', 'user_properties', userProperties)
}
