import { XIcon } from '@gain/components/icons'
import { useIsXs } from '@gain/utils/responsive'
import Button from '@mui/material/Button'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { ComponentType, forwardRef, isValidElement, ReactNode, ReactText, useCallback } from 'react'

export interface SnackbarAction {
  title: string
  onClick: () => void
  icon?: ComponentType
  disableCloseOnClick?: boolean
}

export interface SnackbarProps {
  id: ReactText
  message: ReactNode
  icon?: ReactNode
  action?: SnackbarAction
  variant?: 'default' | 'info' | 'success' | 'error' | 'warning' | 'dark'

  disableCloseButton?: boolean
}

export const snackbarClasses = generateUtilityClasses('Snackbar', [
  'root',
  'icon',
  'closeIcon',
  'message',
  'actions',
  'variantDark',
])

function isPaletteColor(
  variant: SnackbarProps['variant']
): variant is 'info' | 'success' | 'error' | 'warning' {
  return !['default', 'dark', undefined].includes(variant)
}

const StyledRoot = styled('div', { shouldForwardProp: (prop) => prop !== 'variant' })<{
  variant: SnackbarProps['variant']
}>(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  border: `1px solid ${theme.palette.divider}`,
  alignItems: 'center',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
  padding: theme.spacing(1, 1, 1, 2),
  boxSizing: 'border-box',
  color: theme.palette.text.primary,
  gap: theme.spacing(4),
  [theme.breakpoints.only('xs')]: {
    gap: theme.spacing(1),
  },

  [`& .${snackbarClasses.icon}`]: {
    display: 'flex',
    alignItems: 'center',
    color: 'unset',

    ...(isPaletteColor(variant) && { color: theme.palette[variant].main }),

    '& svg': {
      width: 20,
      height: 20,
    },
  },

  [`& .${snackbarClasses.closeIcon}`]: { color: theme.palette.grey['500'] },

  [`& .${snackbarClasses.message}`]: { flex: 1 },

  [`&.${snackbarClasses.variantDark}`]: {
    color: theme.palette.grey['50'],
    backgroundColor: theme.palette.grey['800'],

    [`& .${snackbarClasses.closeIcon}`]: {
      color: theme.palette.grey['50'],
    },
  },
}))

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(function Snackbar(
  { id, action, icon, message, disableCloseButton, variant = 'default' },
  ref
) {
  const isXs = useIsXs()
  const { closeSnackbar } = useSnackbar()

  const handleCloseSnackbar = useCallback(() => {
    closeSnackbar(id)
  }, [closeSnackbar, id])

  const handleOnClick = useCallback(() => {
    action?.onClick()

    if (!action?.disableCloseOnClick) {
      handleCloseSnackbar()
    }
  }, [action, handleCloseSnackbar])

  return (
    <StyledRoot
      ref={ref}
      className={clsx(snackbarClasses.root, {
        [snackbarClasses.variantDark]: variant === 'dark',
      })}
      variant={variant}>
      {icon && <div className={snackbarClasses.icon}>{icon}</div>}
      <Typography
        color={'inherit'}
        component={isValidElement(message) ? 'div' : 'span'}
        minWidth={0}
        variant={'body2'}>
        {message}
      </Typography>
      {(action || !disableCloseButton) && (
        <Stack
          alignItems={'center'}
          className={snackbarClasses.actions}
          flexDirection={'row'}
          gap={0.25}>
          {action && (
            <>
              {isXs && action.icon && (
                <IconButton
                  color={'primary'}
                  disableRipple={false}
                  onClick={handleOnClick}>
                  <action.icon />
                </IconButton>
              )}
              {(!isXs || !action.icon) && (
                <Button
                  color={'primary'}
                  disableRipple={false}
                  onClick={handleOnClick}
                  startIcon={action.icon ? <action.icon /> : undefined}
                  variant={'outlined'}>
                  {action.title}
                </Button>
              )}
            </>
          )}
          {!disableCloseButton && (
            <IconButton
              className={snackbarClasses.closeIcon}
              onClick={handleCloseSnackbar}
              size={'small'}>
              <XIcon />
            </IconButton>
          )}
        </Stack>
      )}
    </StyledRoot>
  )
})

export default Snackbar
