import { SearchItemType, SearchResultItem } from '@gain/rpc/app-model'

import SearchResultListItemAdvisor from './search-result-list-item-advisor'
import SearchResultListItemAsset from './search-result-list-item-asset'
import SearchResultListItemConferenceEdition from './search-result-list-item-conference-edition'
import SearchResultListItemIndustry from './search-result-list-item-industry'
import SearchResultListItemInvestor from './search-result-list-item-investor'
import SearchResultListItemLegalEntity from './search-result-list-item-legal-entity'

export interface SearchResultListItemProps {
  item: SearchResultItem
}

export default function SearchResultListItem({ item }: SearchResultListItemProps) {
  switch (item.type) {
    case SearchItemType.Industry:
      return <SearchResultListItemIndustry item={item} />
    case SearchItemType.AutomatedAsset:
    case SearchItemType.CuratedAsset:
      return <SearchResultListItemAsset item={item} />
    case SearchItemType.Entity:
      return <SearchResultListItemLegalEntity item={item} />
    case SearchItemType.Investor:
      return <SearchResultListItemInvestor item={item} />
    case SearchItemType.Advisor:
      return <SearchResultListItemAdvisor item={item} />
    case SearchItemType.ConferenceEdition:
      return <SearchResultListItemConferenceEdition item={item} />
  }
}
