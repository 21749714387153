import Typography from '@gain/components/typography'

import { formatTeamMemberStatus } from '../team-members-list/format-team-member-status'
import useTeamMemberStatusColor from '../team-members-list/use-team-member-status-color-styles'

export interface UserListItemStatusTableCellProps {
  status: string
}

export function UserListItemStatusTableCell({ status }: UserListItemStatusTableCellProps) {
  const color = useTeamMemberStatusColor(status)

  return (
    <Typography
      sx={{ color: color }}
      variant={'body2'}
      noWrap>
      {formatTeamMemberStatus(status)}
    </Typography>
  )
}
