import { useIsXs } from '@gain/utils/responsive'

import Card, {
  CardContent,
  CardContentEmpty,
  CardHeader,
  CardProps,
} from '../../../../common/card/card'
import { ManagerWithInvestor } from './asset-management-util'
import ManagementList from './management-list'
import ManagementTable from './management-table'

interface ManagementCardProps extends CardProps {
  managers: ManagerWithInvestor[]
}

export default function ManagementCard({ managers, ...cardProps }: ManagementCardProps) {
  const isXs = useIsXs()

  return (
    <Card {...cardProps}>
      <CardHeader title={'Management'} />
      {managers.length === 0 && (
        <CardContentEmpty
          message={'No data available'}
          sx={{ pb: 1 }}
        />
      )}
      {managers.length > 0 && !isXs && <ManagementTable managers={managers} />}
      {managers.length > 0 && isXs && (
        <CardContent sx={{ pt: 1, pb: 2 }}>
          <ManagementList managers={managers} />
        </CardContent>
      )}
    </Card>
  )
}
