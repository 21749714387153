import { isDefined } from '@gain/utils/common'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import { scaleLinear, scaleTime } from 'd3-scale'
import { line } from 'd3-shape'
import { range } from 'lodash'
import { useMemo } from 'react'

const width = 17
const height = 11

const StyledLineContainer = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  height: height + 4,
  maxHeight: height + 4,

  '& svg': {
    overflow: 'visible',
  },
})

const StyledLine = styled('path')({
  strokeWidth: 2,
  fill: 'none',
})

const StyledMissingDataLine = styled('path')({
  strokeWidth: 2,
  fill: 'none',
})

export interface LineValue {
  year: number | Date
  value: number | null
}

export interface LatestFinancialsSparkLineProps {
  lineValues?: LineValue[]
}

export function LatestFinancialsSparkLine({ lineValues = [] }: LatestFinancialsSparkLineProps) {
  const theme = useTheme()

  const lastFiveYears = useMemo(() => {
    if (lineValues.length === 0) {
      return []
    } else if (lineValues[0].year instanceof Date) {
      return lineValues
    }

    const filteredLines = lineValues
      .filter((lineValue) => isDefined(lineValue.value))
      .sort((lineA, lineB) => (lineA.year as number) - (lineB.year as number))

    const maxYear = Math.max(...filteredLines.map((financial) => financial.year as number))

    return range(maxYear - 4, maxYear + 1).map((yearTick) => ({
      year: new Date(`${yearTick}-01-01`),
      value: filteredLines.find((financial) => financial.year === yearTick)?.value || null,
    }))
  }, [lineValues])

  const xValues = lastFiveYears.map((lineValue) => lineValue.year)
  const yValues = lastFiveYears
    .filter((lineValue) => lineValue.value !== null)
    .map((lineValue) => lineValue.value) as number[]

  const scaleX = scaleTime()
    .domain([xValues[0], xValues[xValues.length - 1]])
    .range([0, width])

  const color =
    yValues.length > 2 && yValues[0] > yValues[yValues.length - 1]
      ? theme.palette.error.main
      : theme.palette.success.main

  const hasOneDataPoint =
    lastFiveYears.filter((lineValue) => isDefined(lineValue.value)).length === 1

  const yDomain = [Math.min(...yValues), Math.max(...yValues)]

  const scaleY = scaleLinear().domain(yDomain).range([height, 0]).nice(10)
  const generator = line<LineValue>()
    .x((d) => scaleX(d.year))
    .y((d) => scaleY(d.value as number))
    .defined((d) => isDefined(d.value))

  const filteredData = lastFiveYears.filter(generator.defined())
  const pathWithGaps = generator(lastFiveYears)
  const path = generator(filteredData)

  if (hasOneDataPoint || !pathWithGaps || !path) {
    return null
  }

  return (
    <Stack ml={0.5}>
      <StyledLineContainer>
        <svg
          height={height}
          width={width}>
          <g>
            <StyledMissingDataLine
              d={path}
              stroke={color}
              strokeDasharray={'3 3'}
            />
            <StyledLine
              d={pathWithGaps}
              stroke={color}
            />
          </g>
        </svg>
      </StyledLineContainer>
    </Stack>
  )
}
