import { useAssetListItem } from '@gain/api/app/hooks'
import { Asset } from '@gain/rpc/app-model'
import { sortAssetShareholdersByShare } from '@gain/utils/asset'
import { useHasAssetDeals } from '@gain/utils/legal-entity'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'

import Card, { CardContent, CardHeader, CardProps } from '../../../../common/card/card'
import ViewMoreButton from '../../../../common/view-more-button'
import {
  ASSET_PAGE_OWNERSHIP_PATH,
  generateAssetPagePath,
  useAssetPageParams,
} from '../../../utils'
import { useCreditFacilities } from '../../asset-ownership/asset-ownership-hooks'
import { hasFundingMetrics } from '../../asset-ownership/card-asset-funding/card-asset-funding'
import { hasOwnership, useHasRelevantEntityShareholders } from '../../route-asset-hooks'
import AssetOwnershipDefinition from './asset-ownership-definition'
import AssetOwnershipManager from './asset-ownership-manager'
import { getTopManager } from './asset-ownership-utils'
import AssetShareholderList from './asset-shareholder-list'

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
})

const StyledDivider = styled(Divider)({
  marginTop: 3,
  marginBottom: 8,
})

interface AssetOwnershipCardProps extends CardProps {
  asset: Asset
}

export default function AssetOwnershipCard({ asset, ...rest }: AssetOwnershipCardProps) {
  const params = useAssetPageParams()
  const assetListItem = useAssetListItem(params.id)
  const hasDeals = useHasAssetDeals(asset.id)
  const hasFunding = !!(assetListItem.data && hasFundingMetrics(assetListItem.data))
  const hasEntityShareholders = useHasRelevantEntityShareholders(asset)
  const { visible: hasCreditFacilities } = useCreditFacilities(asset.id)
  const hasOwnershipTab = hasOwnership(
    asset,
    hasDeals,
    hasEntityShareholders,
    hasFunding,
    hasCreditFacilities
  )

  const currentShareholders = asset.shareholders.filter(
    (shareholder) => shareholder.isCurrentInvestor
  )
  const maxShareholders = currentShareholders.length > 3 ? 2 : currentShareholders.length

  const visibleShareholders = currentShareholders
    .slice(0, maxShareholders)
    .sort(sortAssetShareholdersByShare)

  const ownershipHref = generateAssetPagePath(
    {
      id: asset.id,
      name: asset.generalInfo?.name,
      preview: params.preview,
    },
    ASSET_PAGE_OWNERSHIP_PATH
  )

  const topManager = getTopManager(asset)

  return (
    <StyledCard
      {...rest}
      href={hasOwnershipTab ? ownershipHref : null}>
      <CardHeader title={'Ownership'} />

      <CardContent>
        <AssetOwnershipDefinition asset={asset} />

        {visibleShareholders.length <= 0 && topManager && <StyledDivider />}

        <AssetOwnershipManager manager={topManager} />

        {visibleShareholders.length > 0 && <StyledDivider />}

        <AssetShareholderList
          maxItems={maxShareholders}
          shareholders={visibleShareholders}
        />
      </CardContent>

      <ViewMoreButton
        amount={currentShareholders.length - visibleShareholders.length}
        href={ownershipHref}
      />
    </StyledCard>
  )
}
