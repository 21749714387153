import { ChipProps } from '@mui/material/Chip'

// Do not add additional values to this interface, instead create a new one
// that extends it (e.g., ChipOption, MenuOption)
export interface Option<Value> {
  value: Value
  label: string
}

export interface OptionGroup<Value> {
  label: string
  options: Option<Value>[]
}

export interface ChipOption<Value> extends Option<Value> {
  color: ChipProps['color']
}

export function isOptionGroup<T>(value: OptionGroup<T> | Option<T>): value is OptionGroup<T> {
  return 'options' in value
}

export function getOption<Value, Opt extends Option<Value> = Option<Value>>(
  options: ReadonlyArray<Opt>,
  value: Value
): Opt | undefined {
  return options.find((option) => option.value === value)
}

export function getLabelFromOption<Value>(
  options: ReadonlyArray<Option<Value>>,
  value: Value,
  emptyValue?: string
): string | undefined {
  return getOption(options, value)?.label || emptyValue
}
