import { TooltipHeader } from '@gain/components/tooltip'
import { LenderListItem } from '@gain/rpc/app-model'
import { getLabelFromOption, LENDER_TYPE_OPTIONS } from '@gain/rpc/shared-model'
import { formatBps } from '@gain/utils/number'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

import { KeyValueList, KeyValueListItem } from '../../../common/key-value/key-value-list'
import FinancialValue from '../../financial/financial-value'

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 320,
}))

interface LenderTooltipTitleProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  lender: LenderListItem
}

export default function LenderTooltipTitle({ lender, ...props }: LenderTooltipTitleProps) {
  const hasStats =
    typeof lender.creditCount === 'number' ||
    typeof lender.assetMedianDebtQuantumEur === 'number' ||
    typeof lender.assetMedianEbitdaEur === 'number' ||
    typeof lender.creditAverageCouponBps === 'number'

  return (
    <div {...props}>
      <StyledContainer
        direction={'column'}
        spacing={2}>
        <TooltipHeader
          logoSrc={lender.logoFileUrl}
          subtitle={getLabelFromOption(LENDER_TYPE_OPTIONS, lender.type)}
          title={lender.name}
        />

        {hasStats && (
          <KeyValueList
            spacing={0.5}
            dense>
            {typeof lender.creditCount === 'number' && (
              <KeyValueListItem
                label={'Credits'}
                value={lender.creditCount}
              />
            )}

            {typeof lender.assetMedianDebtQuantumEur === 'number' && (
              <KeyValueListItem
                label={'Median debt quantum'}
                value={<FinancialValue amount={lender.assetMedianDebtQuantumEur} />}
              />
            )}

            {typeof lender.assetMedianEbitdaEur === 'number' && (
              <KeyValueListItem
                label={'Median company EBITDA'}
                value={<FinancialValue amount={lender.assetMedianEbitdaEur} />}
              />
            )}

            {typeof lender.creditAverageCouponBps === 'number' && (
              <KeyValueListItem
                label={'Average coupon'}
                value={formatBps(lender.creditAverageCouponBps)}
              />
            )}
          </KeyValueList>
        )}
      </StyledContainer>
    </div>
  )
}
