import Head from '@gain/modules/head'
import { DealListItem, Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'

import { useTrackPageView } from '../../../features/planhat/planhat-hooks'
import InvestorDealsList from '../../investor/investor-deals-list'

export interface RouteEntriesProps {
  investor: Investor
  strategy: InvestorStrategy
}

export default function RouteEntries({ investor, strategy }: RouteEntriesProps) {
  useTrackPageView('investor_strategy', {
    id: strategy.id,
    tab: 'entries',
    requireTab: true,
  })

  return (
    <>
      <Head>
        <title>
          {investor.name} - {strategy.name} - Entries
        </title>
      </Head>
      <InvestorDealsList
        defaultFilters={[
          listFilter<DealListItem>('buyerInvestorIds', '=', investor.id),
          listFilter<DealListItem>('buyerStrategyIds', '=', strategy.id),
        ]}
      />
    </>
  )
}
