import { ArrowDownIcon } from '@gain/components/icons'
import Chip from '@mui/material/Chip'
import Fade from '@mui/material/Fade'
import { styled } from '@mui/material/styles'

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.grey['600'],
  position: 'absolute',
  left: 77,
  bottom: 115,
  zIndex: theme.zIndex.drawer,
}))

type MoreUpdatesBadgeProps = {
  show: boolean
  onClick: () => void
}

export default function MoreUpdatesBadge({ show, onClick }: MoreUpdatesBadgeProps) {
  return (
    <Fade in={show}>
      <StyledChip
        icon={<ArrowDownIcon />}
        label={'More updates'}
        onClick={onClick}
      />
    </Fade>
  )
}
