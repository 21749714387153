import { kebabCase } from 'lodash'
import { generatePath, useParams } from 'react-router-dom'

import { LENDER_PAGE_PATH, Params } from '../utils'

export enum RouteLenderTabs {
  Summary = 'summary',
  Credits = 'credits',
  Companies = 'companies',
  Investors = 'investors',
}

export const LENDER_SUMMARY_PATH = `${LENDER_PAGE_PATH}/${RouteLenderTabs.Summary}`
export const LENDER_CREDITS_PATH = `${LENDER_PAGE_PATH}/${RouteLenderTabs.Credits}`
export const LENDER_COMPANIES_PATH = `${LENDER_PAGE_PATH}/${RouteLenderTabs.Companies}`
export const LENDER_INVESTORS_PATH = `${LENDER_PAGE_PATH}/${RouteLenderTabs.Investors}`

export function generateLenderPagePath(
  id: string | number,
  name: string | undefined,
  path = LENDER_SUMMARY_PATH
) {
  return generatePath(path, { id, name: kebabCase(`${name}`) || undefined })
}

export interface LenderPageParams extends Params {
  id: string
  name?: string
}

export function useLenderPageParams() {
  const params = useParams<LenderPageParams>()

  return {
    id: parseInt(params.id),
    name: params.name,
  }
}
