import { useUserProfileContext } from '@gain/modules/user'
import { UserProfile } from '@gain/rpc/app-model'
import { UserUnitSystem } from '@gain/rpc/shared-model'
import { noop } from '@gain/utils/common'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { SubmissionErrors } from 'final-form'
import { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'

import { useUpdateUserProfileCallback } from '../../../api/user-profile-api-hooks'
import { isRequired } from '../../../common/form/form-utils'
import { formatFieldError, showFieldError } from '../../../common/form/print-field-error'
import UpdateAccountCurrency from './update-account-currency'

type UpdateUserProfileParams = Partial<
  Pick<
    UserProfile,
    | 'firstName'
    | 'lastName'
    | 'recommendRegions'
    | 'recommendSubsectors'
    | 'emailSourcingUpdates'
    | 'emailResearchUpdates'
    | 'emailAssetUpdatesNotification'
  >
>

interface OnSubmitParams {
  values: UpdateUserProfileParams
  callback?: (errors?: SubmissionErrors) => void
}

export default function UpdateAccountForm() {
  const { firstName, lastName, email, customerName, currency, unitSystem } = useUserProfileContext()

  const [mode, setMode] = useState<'edit' | 'view'>('view')
  const updateProfile = useUpdateUserProfileCallback()

  const onSubmit = useCallback(
    async ({ values, callback = noop }: OnSubmitParams) => {
      try {
        await updateProfile(values)
        setMode('view')
      } catch {
        // Ignore errors
      }
      callback()
    },
    [updateProfile]
  )

  return (
    <Paper variant={'outlined'}>
      <Box padding={2}>
        <Form
          initialValues={{ firstName, lastName, currency, unitSystem }}
          onSubmit={(values, form, callback) => onSubmit({ values, callback })}>
          {({ submitError, handleSubmit, submitting }) => (
            <form
              onSubmit={handleSubmit}
              style={{ display: 'inline' }}>
              <Grid
                direction={'column'}
                spacing={2}
                container>
                <Grid
                  alignItems={'center'}
                  direction={'row'}
                  justifyContent={'space-between'}
                  spacing={2}
                  container
                  item>
                  <Grid item>
                    <Typography variant={'subtitle1'}>My profile</Typography>
                    {submitError && (
                      <Typography
                        color={'error'}
                        variant={'body2'}>
                        {submitError}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item>
                    <Button
                      color={'primary'}
                      disabled={submitting}
                      onClick={mode === 'view' ? () => setMode('edit') : handleSubmit}
                      type={'button'}
                      variant={'contained'}>
                      {mode === 'view' ? 'Edit' : 'Save'}
                    </Button>
                  </Grid>
                </Grid>

                <Grid item>
                  <Divider />
                </Grid>

                <Grid item>
                  <Field
                    name={'firstName'}
                    validate={isRequired}>
                    {({ input, meta }) => (
                      <TextField
                        error={showFieldError(meta)}
                        helperText={formatFieldError(meta)}
                        label={'First name'}
                        slotProps={{
                          htmlInput: input,
                          input: {
                            readOnly: mode === 'view',
                          },
                        }}
                        variant={'outlined'}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item>
                  <Field
                    name={'lastName'}
                    validate={isRequired}>
                    {({ input, meta }) => (
                      <TextField
                        error={showFieldError(meta)}
                        helperText={formatFieldError(meta)}
                        label={'Last name'}
                        slotProps={{
                          htmlInput: input,
                          input: {
                            readOnly: mode === 'view',
                          },
                        }}
                        variant={'outlined'}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={'currency'}>Display currency</InputLabel>
                    <Field
                      name={'currency'}
                      validate={isRequired}>
                      {({ input }) => (
                        <UpdateAccountCurrency
                          currencyName={currency}
                          input={input}
                          mode={mode}
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={'currency'}>Display unit</InputLabel>
                    <Field
                      name={'unitSystem'}
                      validate={isRequired}>
                      {({ input }) => (
                        <Select
                          inputProps={{
                            ...input,
                            readOnly: mode === 'view',
                          }}
                          label={'Display unit'}
                          readOnly={mode === 'view'}
                          variant={'outlined'}
                          fullWidth>
                          <MenuItem value={UserUnitSystem.Km}>Metric (km)</MenuItem>
                          <MenuItem value={UserUnitSystem.Miles}>Imperial (miles)</MenuItem>
                        </Select>
                      )}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item>
                  <TextField
                    label={'Email address'}
                    slotProps={{
                      input: { readOnly: true },
                    }}
                    value={email}
                    variant={'outlined'}
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <TextField
                    label={'Company'}
                    slotProps={{
                      input: { readOnly: true },
                    }}
                    value={customerName}
                    variant={'outlined'}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </Box>
    </Paper>
  )
}
