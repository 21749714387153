import { kebabCase } from 'lodash'
import { useMemo } from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { DEAL_PAGE_PATH, GeneratePathParams, Params } from './path'

export interface GenerateDealPageParams extends GeneratePathParams {
  id: number | string
  name?: string
}

export function generateDealPagePath(params: GenerateDealPageParams) {
  return generatePath(DEAL_PAGE_PATH, {
    id: params.id,
    name: kebabCase(`${params.name}`) || undefined,
  })
}

export interface DealPageParams extends Params {
  id: string
  name?: string
}

export function useDealPageParams() {
  const params = useParams<DealPageParams>()
  return useMemo(
    () => ({
      ...params,
      id: parseInt(params.id, 10),
    }),
    [params]
  )
}
