import { InvestorFundListItem } from '@gain/rpc/app-model'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'

export function useInvestorFundDryPowder(fund: InvestorFundListItem): string | null {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  if (fund.dryPowderMinEur === null || fund.dryPowderMaxEur === null) {
    return null
  }

  return formatCurrencyRange(fund.dryPowderMinEur, fund.dryPowderMaxEur, {
    round: 'estimate',
  })
}
