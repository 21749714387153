import { SearchIcon, XIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { noop } from '@gain/utils/common'
import { chipClasses } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { FocusEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'

import { GaCategory, useTrackEvent } from '../../../google-analytics'
import FilterBarChip from '../filter-bar-chip'

const StyledInputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
})

const StyledInput = styled('input')(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  flex: 1,
  ...theme.typography.overline,
  '&:focus': {
    outline: 'none',
  },
}))

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  fontSize: theme.typography.overline.fontSize,
}))

const StyledXIcon = styled(XIcon)({
  width: 12,
  height: 12,
  cursor: 'pointer',
})

const StyledFilterBarChip = styled(FilterBarChip, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  // Increase specificity to make sure default styles get overridden
  [`&.${chipClasses.root}`]: {
    transition: theme.transitions.create(['border-radius', 'background-color', 'color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
    // disable default chip box shadow that collides with active state
    boxShadow: 'none !important',
    ...(active && {
      zIndex: theme.zIndex.tooltip + 1,
      width: 220,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 8,
      cursor: 'default',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      },
    }),
    [`& .${chipClasses.label}`]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
    },
  },
}))

interface FilterBarSearchProps {
  onChange: (value: string | null) => void
  label?: string
  placeholder?: string
  initialValue: string | null
  gaCategory: GaCategory
  onSearchStateChanged: (active: boolean) => void
}

/**
 * The filter bar search component is a chip in the filter bar that displays a search input that
 * does a full text search.
 *
 * Despite it being displayed in the list of filters it does not set a `filter: [...]` request
 * value, instead it sets the `search: <value>`-request field.
 */
export default function FilterBarSearch({
  label = 'Text contains',
  placeholder = 'E.g. data center OR web hosting',
  onChange,
  initialValue,
  gaCategory,
  onSearchStateChanged,
}: FilterBarSearchProps) {
  const chipRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const trackEvent = useTrackEvent()
  const [value, setValue] = useState(initialValue)
  const [showInput, setShowInput] = useState(false)

  const handleBlur = useCallback((event: FocusEvent) => {
    // Do nothing if the element receiving focus (relatedTarget) is inside the chip
    if (chipRef.current?.contains(event.relatedTarget)) {
      return
    }

    setShowInput(false)
  }, [])

  const handleClick = useCallback((event: MouseEvent) => {
    // Focus on the input when the user clicks on something other
    // than the input inside the chip (e.g. search icon)
    if (event.currentTarget !== inputRef.current) {
      inputRef.current?.focus()
    }

    setShowInput(true)
  }, [])

  const handleClear = useCallback((event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setValue(null)
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    // Propagate value change
    onChange(value)

    // Track searches in Google Analytics when there is a value
    if (value) {
      const timeoutId = setTimeout(
        () => trackEvent('Search value change', `${gaCategory} search`, value),
        500
      )

      return () => {
        clearTimeout(timeoutId)
      }
    }

    return noop
  }, [value, onChange, trackEvent, gaCategory])

  useEffect(() => {
    // Focus on input when it's rendered
    if (showInput) {
      inputRef.current?.focus()
    }

    // Propagate input state change
    onSearchStateChanged(showInput)
  }, [onSearchStateChanged, showInput])

  return (
    <StyledFilterBarChip
      ref={chipRef}
      active={showInput}
      color={value ? 'info' : 'default'}
      label={
        <>
          {!showInput && (
            <Typography
              sx={{ maxWidth: 240 }}
              variant={'overline'}
              noWrap>
              {label}
              {value && `: ${value}`}
            </Typography>
          )}
          {showInput && (
            <StyledInputContainer>
              <StyledInput
                ref={inputRef}
                autoComplete={'off'}
                onBlur={handleBlur}
                onChange={(event) => setValue(event.target.value || null)}
                placeholder={placeholder}
                value={value || ''}
              />
            </StyledInputContainer>
          )}
          {!value ? <StyledSearchIcon /> : <StyledXIcon onClick={handleClear} />}
        </>
      }
      onClick={handleClick}
      clickable
      {...{ disableRipple: showInput }}
    />
  )
}
