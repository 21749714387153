import { noop } from '@gain/utils/common'
import { isChildOfDescendantAnchorWithHref } from '@gain/utils/dom'
import { RenderCellParams, RowType, useColumnWidths } from '@gain/utils/table'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import { MouseEvent, MutableRefObject, useCallback, useMemo, useRef } from 'react'
import { useVirtual, VirtualItem } from 'react-virtual'

import { TABLE_BODY_CELL_HEIGHT, TABLE_BODY_CELL_SMALL_HEIGHT } from '../../theme'
import useCheckboxSelection from './use-checkbox-selection'
import useCheckboxTooltip from './use-checkbox-tooltip'
import { useColumnOffsets } from './use-column-offsets'
import {
  useGetRowComponentPropsCallback,
  UseGetRowComponentPropsCallbackReturn,
} from './use-get-row-component-props-callback'
import { useLoadMoreEffect } from './use-load-more-effect'
import { useSortActiveCallback, UseSortActiveCallbackFn } from './use-sort-active-callback'
import { useSortCallback, UseSortCallbackFn } from './use-sort-callback'
import { useSortDirectionCallback, UseSortDirectionCallbackFn } from './use-sort-direction-callback'
import { useVirtualColumns, VirtualColumn } from './use-virtual-columns'
import {
  RowClickHandlerFn,
  VirtualTableClasses,
  virtualTableClasses,
  VirtualTableProps,
} from './virtual-table'
import { ColumnConfig, RowClickEvent } from './virtual-table-model'

export const VIRTUAL_TABLE_CHECKBOX_CELL_WIDTH = 52

type ScrollAlignment = 'start' | 'center' | 'end' | 'auto'

interface ScrollToOptions {
  align: ScrollAlignment
}

type ScrollToOffsetOptions = ScrollToOptions
type ScrollToIndexOptions = ScrollToOptions

interface UseVirtualReturnType {
  virtualItems: VirtualItem[]
  totalSize: number
  scrollToOffset: (index: number, options?: ScrollToOffsetOptions) => void
  scrollToIndex: (index: number, options?: ScrollToIndexOptions) => void
  measure: () => void
}

export interface VirtualTableApi<Row extends RowType> {
  containerRef: MutableRefObject<HTMLDivElement | null>
  tableBodyRef: MutableRefObject<HTMLTableSectionElement | null>
  tableHeadRef: MutableRefObject<HTMLTableSectionElement | null>
  containerHeight: number | null
  virtualX: UseVirtualReturnType
  virtualY: UseVirtualReturnType
  disableXScroll: boolean
  totalSize: number
  hideTotalSize: boolean
  overrideClasses: VirtualTableClasses
  virtualColumns: VirtualColumn<Row>[]
  columnWidths: number[]
  columnOffsets: number[]
  shadowColumnIndex: number
  handleSort: UseSortCallbackFn<Row>
  handleSortActive: UseSortActiveCallbackFn<Row>
  handleSortDirection: UseSortDirectionCallbackFn<Row>
  loadingInitial: boolean
  getRowComponentProps: UseGetRowComponentPropsCallbackReturn<Row>
  selectionModel: number[]
  onRowClick: RowClickHandlerFn<Row>
  rows: Row[]
}

export const useVirtualTableApi = <Row extends RowType>({
  rows = [],
  hideTotalSize = false,
  columns: defaultColumns,
  onSort = noop,
  sort = [],
  onLoadMore = noop,
  loadingInitial = false,
  loadingMore = false,
  checkboxSelection = false,
  onSelectionChange = noop,
  selectionModel = [],
  RowComponentProps,
  maxItemsSelected = 100,
  onRowClick,
  classes: overrideClasses = {},
  ...props
}: VirtualTableProps<Row>): VirtualTableApi<Row> => {
  const totalSize = props.totalSize !== undefined ? props.totalSize : rows.length
  const nrOfSelectableItems = totalSize > maxItemsSelected ? maxItemsSelected : totalSize
  const checkboxSelect = useCheckboxSelection(
    selectionModel,
    nrOfSelectableItems,
    onSelectionChange
  )

  const checkboxLabel = useCheckboxTooltip(
    totalSize,
    maxItemsSelected,
    checkboxSelect.isAllSelected
  )

  const columns = useMemo(() => {
    if (checkboxSelection) {
      return [
        {
          field: '__checkbox',
          width: VIRTUAL_TABLE_CHECKBOX_CELL_WIDTH,
          sticky: true,
          align: 'center',
          renderCell: (params: RenderCellParams<Row, never>) => (
            <Checkbox
              checked={selectionModel?.includes(params.rowIndex)}
              onClick={checkboxSelect.toggleCheckbox(params.rowIndex)}
            />
          ),
          renderHeader: () => (
            <Tooltip
              title={checkboxLabel}
              disableInteractive>
              <Checkbox
                checked={checkboxSelect.isAllSelected}
                indeterminate={checkboxSelect.isIndeterminate}
                onChange={checkboxSelect.toggleSelection}
                onClick={(event) => event.stopPropagation()}
              />
            </Tooltip>
          ),
          cellClassName: virtualTableClasses.checkboxCell,
          headerClassName: virtualTableClasses.checkboxHeadCell,
        } as unknown as ColumnConfig<Row>,
        ...defaultColumns,
      ]
    }
    return defaultColumns
  }, [checkboxSelection, defaultColumns, selectionModel, checkboxSelect, checkboxLabel])

  const containerRef = useRef<HTMLDivElement | null>(null)
  const tableHeadRef = useRef<HTMLTableSectionElement | null>(null)
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null)
  const getRowComponentProps = useGetRowComponentPropsCallback(RowComponentProps)

  const columnsMinWidth = columns.reduce((total, column) => total + column.width, 0)

  const containerWidth = containerRef.current?.getBoundingClientRect().width || null
  const containerHeight = containerRef.current?.getBoundingClientRect().height || null

  const columnWidths = useColumnWidths(containerWidth, columnsMinWidth, columns)
  const columnOffsets = useColumnOffsets(columnWidths)

  const shadowColumnIndex = useMemo(() => {
    if (containerWidth === null || containerWidth > columnsMinWidth) {
      return -1
    }
    return columns.reduce((acc, current, index) => (current.sticky ? index : acc), -1)
  }, [containerWidth, columnsMinWidth, columns])

  const virtualY = useVirtual({
    parentRef: containerRef,
    size: useMemo(() => rows.length, [rows.length]),
    paddingStart: props.variant === 'inline' && !props.disablePaddingStart ? 8 : 0,
    paddingEnd: checkboxSelection
      ? props.variant !== 'inline'
        ? TABLE_BODY_CELL_HEIGHT
        : TABLE_BODY_CELL_SMALL_HEIGHT
      : 0,
    estimateSize: useCallback(
      () => (props.variant !== 'inline' ? TABLE_BODY_CELL_HEIGHT : TABLE_BODY_CELL_SMALL_HEIGHT),
      [props.variant]
    ),
  })

  const virtualX = useVirtual({
    parentRef: containerRef,
    size: useMemo(() => columns.length, [columns]),
    estimateSize: useCallback((index: number) => columnWidths[index], [columnWidths]),
    horizontal: true,
  })

  const handleRowClick = useCallback(
    (data: RowClickEvent<Row>, event: MouseEvent) => {
      if (!onRowClick || !(event.target instanceof HTMLElement)) {
        return
      }
      const target = event.currentTarget

      const isAnchorWithHref =
        event.target instanceof HTMLAnchorElement && event.target.hasAttribute('href')
      const isDescendant = target.contains(event.target)
      const isInsideAnchorWithHref = isChildOfDescendantAnchorWithHref(event.target, target)

      if (!isAnchorWithHref && !isInsideAnchorWithHref && isDescendant) {
        onRowClick(data, event)
      }
    },
    [onRowClick]
  )

  const handleSort = useSortCallback(sort, onSort)
  const handleSortActive = useSortActiveCallback(sort)
  const handleSortDirection = useSortDirectionCallback(sort)

  useLoadMoreEffect(rows.length, totalSize, loadingMore, onLoadMore, virtualY.virtualItems)

  const virtualColumns = useVirtualColumns(virtualX.virtualItems, columns)

  return {
    containerRef,
    tableBodyRef,
    tableHeadRef,
    containerHeight,
    virtualX,
    virtualY,
    totalSize,
    hideTotalSize,
    overrideClasses,
    virtualColumns,
    columnWidths,
    columnOffsets,
    shadowColumnIndex,
    handleSort,
    handleSortActive,
    handleSortDirection,
    loadingInitial,
    getRowComponentProps,
    selectionModel,
    onRowClick: handleRowClick,
    rows,

    // Disable x scrolling if the containers width is the same or more as the inner with
    disableXScroll: (containerWidth || 0) >= virtualX.totalSize,
  }
}
