import { MoreVerticalIcon } from '@gain/components/icons'
import { useUserProfileContext } from '@gain/modules/user'
import {
  UserListItem as UserListItemType,
  UserPermissionObjectType,
  UserPermissionRole,
} from '@gain/rpc/app-model'
import LoadingButton from '@mui/lab/LoadingButton'
import Collapse from '@mui/material/Collapse'
import Dialog, { dialogClasses } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import { forwardRef, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePrevious } from 'react-use'

import { useOpenDialog } from '../../common/dialog'
import DialogHeader from '../../common/dialog-header'
import { ShareActions } from './share-actions'
import { ShareMenu } from './share-menu'
import { ShareMenuButtonState } from './share-menu-button'
import ShareUserListItem from './share-user-list-item'
import ShareUserSearch from './share-user-search'

const StyledDialog = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 518,
    maxHeight: 'min(542px, calc(100% - 64px))',
  },
})

const StyledList = styled(List)(({ theme }) => ({
  paddingBlock: theme.spacing(0.25),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const StyledDialogContent = styled(DialogContent)({
  padding: 0,
})

const StyledListHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey['50'],
  fontSize: 12,
  color: theme.palette.text.secondary,
}))

interface ShareDialogProps {
  open: boolean
  objectType?: UserPermissionObjectType
  objectId?: number
  isLoading: boolean
  isOwner: (userId: number) => boolean
  hasChanges: boolean
  selectedUsers: UserListItemType[]
  visibleUsers: UserListItemType[]
  onSave: () => void
  onAction: (action: ShareActions) => void
  onClose: () => void
  currentUserRole: UserPermissionRole
}

const ShareDialog = forwardRef<HTMLElement, ShareDialogProps>(function ShareDialog(
  {
    currentUserRole,
    hasChanges,
    isLoading,
    isOwner,
    onAction,
    onClose,
    onSave,
    open,
    selectedUsers,
    visibleUsers,
  },
  ref
) {
  const userProfile = useUserProfileContext()
  const bottomEl = useRef<HTMLDivElement>(null)
  const [shareMenuAnchor, setShareMenuAnchor] = useState<Element | null>(null)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [shareMenuUserId, setShareMenuUserId] = useState<number>()
  const openDialog = useOpenDialog()
  const prevVisibleItems = usePrevious(visibleUsers)

  const selectedUserIds = useMemo(() => selectedUsers.map(({ userId }) => userId), [selectedUsers])

  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }

  useEffect(() => {
    if (prevVisibleItems && prevVisibleItems.length < visibleUsers.length) {
      scrollToBottom()
    }
  }, [prevVisibleItems, visibleUsers])

  const handleClose = useCallback(
    (event) => {
      event.stopPropagation()

      if (hasChanges) {
        openDialog({
          title: 'Discard changes',
          message: 'The changes have not been saved, are you sure you want to discard them?',
          confirmText: 'Discard changes',
          cancelText: 'Cancel',
          onConfirm: onClose,
        })
      } else {
        onClose()
      }
    },
    [hasChanges, onClose, openDialog]
  )

  useEffect(() => {
    setShareDialogOpen(Boolean(shareMenuAnchor)) // Change open=true for animation
  }, [shareMenuAnchor])

  const handleOpenMenu = useCallback(
    (userId: number) => (event: MouseEvent) => {
      setShareMenuUserId(userId)
      setShareMenuAnchor(event.currentTarget)
      event.stopPropagation()
      event.preventDefault()
    },
    []
  )

  const handleAddUser = (user: UserListItemType) => {
    onAction({ type: 'add', user })
  }

  const handleRemoveUser = (user: UserListItemType) => {
    onAction({ type: 'remove', userId: user.userId })
  }

  const handleCloseMenu = useCallback(
    (action: ShareActions) => {
      onAction(action)
      setShareMenuAnchor(null)
    },
    [onAction]
  )

  // Determines whether the user is the last owner, in which case the user
  // is not allowed to leave the list.
  const nrOfOwners = visibleUsers.filter((visibleUser) => isOwner(visibleUser.userId)).length
  const isCurrentUserOwner = currentUserRole === UserPermissionRole.Owner

  const determineButtonActions = (userId: number) => {
    const actions = {
      grantOwner: ShareMenuButtonState.None,
      revokeOwner: ShareMenuButtonState.None,
      removeUser: ShareMenuButtonState.None,
    }

    if (isCurrentUserOwner && isOwner(userId)) {
      if (nrOfOwners > 1) {
        actions.revokeOwner = ShareMenuButtonState.Enabled
      } else {
        // Cannot remove the last owner
        actions.revokeOwner = ShareMenuButtonState.Disabled
      }
    }

    if (isCurrentUserOwner && !isOwner(userId)) {
      actions.grantOwner = ShareMenuButtonState.Enabled
    }

    // Currently everyone is allowed to add/remove regular users (this may change
    // in the future where users only have viewer access).
    // - Non-owners cannot remove owners.
    // - Owners can remove other owners.
    // - You cannot remove yourself.
    const isSelf = userId === userProfile.id
    if (!isSelf && (!isOwner(userId) || isCurrentUserOwner)) {
      actions.removeUser = ShareMenuButtonState.Enabled

      if (isOwner(userId) && nrOfOwners === 1) {
        actions.removeUser = ShareMenuButtonState.Disabled
      }
    }

    return actions
  }

  return (
    <StyledDialog
      maxWidth={'sm'}
      onClose={handleClose}
      open={open}
      fullWidth>
      <DialogHeader
        onCloseClick={handleClose}
        title={'Share list with your team'}
      />

      <ShareUserSearch
        excludedUserIds={selectedUserIds}
        onSelect={handleAddUser}
      />

      <StyledListHeader>People with access</StyledListHeader>

      <StyledDialogContent ref={ref}>
        <StyledList
          dense
          disablePadding>
          {selectedUsers?.map((user) => {
            const actions = determineButtonActions(user.userId)
            const hasActions = Object.values(actions).some(
              (action) => action !== ShareMenuButtonState.None
            )

            return (
              <Collapse
                key={user.userId}
                in={visibleUsers.includes(user)}
                onExited={() => handleRemoveUser(user)}
                mountOnEnter
                unmountOnExit>
                <ShareUserListItem
                  isOwner={isOwner(user.userId)}
                  secondaryAction={
                    hasActions && (
                      <StyledIconButton
                        onClick={handleOpenMenu(user.userId)}
                        size={'small'}>
                        <MoreVerticalIcon />
                      </StyledIconButton>
                    )
                  }
                  user={user}
                />
              </Collapse>
            )
          })}
        </StyledList>
        <div ref={bottomEl}></div>
      </StyledDialogContent>

      <DialogActions>
        <LoadingButton
          color={'primary'}
          loading={isLoading}
          onClick={onSave}
          variant={'contained'}
          fullWidth>
          Save
        </LoadingButton>
      </DialogActions>

      {shareMenuAnchor && shareMenuUserId && (
        <ShareMenu
          actions={determineButtonActions(shareMenuUserId)}
          anchorEl={shareMenuAnchor}
          isCurrentUserOwner={isCurrentUserOwner}
          onClose={handleCloseMenu}
          open={shareDialogOpen}
          userId={shareMenuUserId}
        />
      )}
    </StyledDialog>
  )
})

export default ShareDialog
