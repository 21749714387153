import type { AxisConfigId } from '../../chart/company-bubble-echart/axis/axis-config'

export interface ChartLibraryOption {
  title: string
  x: AxisConfigId
  y: AxisConfigId
}

export const chartLibraryOptions: ChartLibraryOption[] = [
  { title: 'Growth momentum', y: 'fteGrowthPctSixMonths', x: 'fteCagrPctTwoYears' },
  { title: 'Strategic maturity', y: 'ratingMultinational', x: 'ratingBuyAndBuild' },
  { title: 'Size vs. growth', y: 'fteGrowthPctOneYear', x: 'fte' },
  { title: 'Growth vs. profitability', y: 'revenueGrowthPctOneYear', x: 'ebitdaPctRevenue' },
  { title: 'Holding period', y: 'fteCagrPctTwoYears', x: 'holdingPeriod' },
  { title: 'Upcoming challengers', y: 'fteGrowthPctOneYear', x: 'companyAge' },
  { title: 'CEO experience', y: 'ceoTenure', x: 'ceoAge' },
  { title: 'Growth vs. funding', y: 'fteGrowthPctSixMonths', x: 'latestDealRoundSizeEur' },
]

export function findChartLibraryOptionById(
  x: AxisConfigId,
  y: AxisConfigId
): ChartLibraryOption | null {
  return chartLibraryOptions.find((option) => option.x === x && option.y === y) || null
}
