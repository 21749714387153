import { useBookmarkLists } from '@gain/api/app/hooks'
import { BookmarkIcon, PlusIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { useDialogState } from '@gain/utils/dialog'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useController, useForm } from 'react-hook-form'

import { isLegacyCustomAssetList } from '../../../api/bookmark-list-hooks'
import BookmarksListCreateDialog, {
  BookmarksListCreateCloseHandler,
} from '../bookmarks-list-create/bookmarks-list-create-dialog'

interface BookmarkListSelectProps {
  name: string
}

export default function BookmarkListSelect({ name }: BookmarkListSelectProps) {
  const allLists = useBookmarkLists()

  // TODO remove this when we switch the frontend to hybrid lists
  const lists = allLists.data.items?.filter(isLegacyCustomAssetList)

  const form = useForm()
  const nameControl = useController({ name })
  const [createDialogOpen, openCreateDialog, closeCreateDialog] = useDialogState()

  const handleCloseCreateDialog: BookmarksListCreateCloseHandler = (reason, list) => {
    if (reason === 'createBookmarkList' && list?.id) {
      // Update the field if a new list was created
      nameControl.field.onChange(list.id)
    }
    closeCreateDialog()
  }

  return (
    <>
      <BookmarksListCreateDialog
        onClose={handleCloseCreateDialog}
        open={createDialogOpen}
        disableRedirect
      />
      <Select
        error={!!form.formState.errors?.listId}
        labelId={'selectBookmarkListId'}
        onChange={(event) => {
          if (typeof event.target.value === 'number') {
            nameControl.field.onChange(event.target.value)
          }
        }}
        renderValue={(selectedValue) => {
          const list = lists.find((item) => item.id === selectedValue)
          if (list) {
            return list.title
          }

          return (
            <Typography
              color={'text.secondary'}
              variant={'body2'}>
              Select a bookmark list
            </Typography>
          )
        }}
        value={nameControl.field.value || ''}
        displayEmpty
        fullWidth>
        <MenuItem onClick={openCreateDialog}>
          <ListItemIcon>
            <PlusIcon color={'primary'} />
          </ListItemIcon>
          <ListItemText
            slotProps={{
              primary: { color: 'primary' },
            }}>
            New list
          </ListItemText>
        </MenuItem>

        <Divider />

        {lists.map((list) => (
          <MenuItem
            key={list.id}
            value={list.id}>
            <ListItemIcon>
              <BookmarkIcon />
            </ListItemIcon>
            <ListItemText>{list.title}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
