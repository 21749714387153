import { kebabCase } from 'lodash'
import { useMemo } from 'react'
import { ExtractRouteParams } from 'react-router'
import { generatePath, useParams } from 'react-router-dom'

import { Params } from './path'

export const ARTICLE_PAGE_PATH = '/article/:articleId/:articleTitle?/:preview?'

export interface ArticlePageParams extends Params {
  articleId: string
  articleTitle?: string
  preview?: string
}

export const generateArticlePagePath = (params: ExtractRouteParams<typeof ARTICLE_PAGE_PATH>) =>
  generatePath(ARTICLE_PAGE_PATH, {
    ...params,
    articleTitle: kebabCase(`${params.articleTitle}`) || undefined,
  })

export const useArticlePageParams = () => {
  const params = useParams<ArticlePageParams>()
  return useMemo(
    () => ({
      articleId: parseInt(params.articleId, 10),
      articleTitle: params.articleTitle,
      preview: Boolean(params.preview),
    }),
    [params]
  )
}
