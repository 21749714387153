import { useAssetListItems } from '@gain/api/app/hooks'
import {
  CompanyIcon,
  LeafIcon,
  MapPinIcon,
  ShuffleIcon,
  TrendingUpIcon,
} from '@gain/components/icons'
import { Industry } from '@gain/rpc/app-model'
import { useMedianAssetRatings } from '@gain/utils/asset'
import { formatEsgOverallRating, formatOrganicGrowth } from '@gain/utils/investment-criteria'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import Skeleton from '../../../../../common/skeleton'
import {
  useIndustryDealCount,
  useIndustryEsgOverallRiskMedian,
} from '../../../../../routes/industry/route-summary/industry-metrics-card/industry-metrics-card-hooks'
import { ObjectListItemText } from '../../../../object-list-item'
import { useSearchAPI } from '../../../search-hooks'
import SearchTopResultLocation from './search-top-result-location'
import SearchTopResultSideInfo from './search-top-result-side-info'
import { StyledDescription, StyledSubTitle } from './search-top-result-skeleton'
import SearchTopResultTitle from './search-top-result-title'

const StyledImage = styled('img')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  maxWidth: 120,
  objectFit: 'cover',
}))

export default function SearchTopResultIndustry({ industry }: { industry?: Industry }) {
  const {
    result: { topResult },
  } = useSearchAPI()

  const { data: assets, loading: loadingAssets } = useAssetListItems(
    industry?.assets.map((item) => item.assetId) || null
  )
  const ratings = useMedianAssetRatings(assets.items)
  const dealCount = useIndustryDealCount(industry?.id || null)

  const esgOverallRiskMedian = useIndustryEsgOverallRiskMedian(industry)

  return (
    <Stack
      direction={'row'}
      gap={3}>
      {topResult?.imageUrl && <StyledImage src={topResult.imageUrl} />}
      <Stack flex={1}>
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={2}>
          <ObjectListItemText variant={'top-result'}>
            <SearchTopResultTitle />

            <StyledSubTitle>Industry</StyledSubTitle>
          </ObjectListItemText>
        </Stack>

        <StyledDescription maxLines={5}>
          {industry &&
            industry.keyTakeaways?.length > 0 &&
            industry.keyTakeaways.map(({ text }) => text).join('. ')}

          {!industry &&
            Array(5)
              .fill(null)
              .map((_, index) => (
                <Skeleton
                  key={index}
                  width={'100%'}
                  enabled>
                  <Typography
                    color={'textPrimary'}
                    variant={'body2'}>
                    &nbsp;
                  </Typography>
                </Skeleton>
              ))}
        </StyledDescription>
      </Stack>

      <SearchTopResultSideInfo>
        <List
          dense
          disablePadding>
          {(!industry || industry?.generalInfo?.scope) && (
            <ListItem
              disableGutters
              disablePadding>
              <ListItemIcon>
                <Skeleton
                  enabled={!industry}
                  width={20}>
                  <MapPinIcon />
                </Skeleton>
              </ListItemIcon>
              <Skeleton
                enabled={!industry}
                width={150}>
                <ListItemText>{industry?.generalInfo?.scope}</ListItemText>
              </Skeleton>
              <ListItemIcon></ListItemIcon>
            </ListItem>
          )}

          {industry && !industry?.generalInfo?.scope && <SearchTopResultLocation />}

          <ListItem
            disableGutters
            disablePadding>
            <ListItemIcon>
              <Skeleton
                enabled={!industry}
                width={20}>
                <CompanyIcon />
              </Skeleton>
            </ListItemIcon>
            <Skeleton
              enabled={!industry}
              width={150}>
              <ListItemText>{industry && industry.assets.length} companies</ListItemText>
            </Skeleton>
          </ListItem>

          <ListItem
            disableGutters
            disablePadding>
            <ListItemIcon>
              <Skeleton
                enabled={dealCount === null}
                width={20}>
                <ShuffleIcon />
              </Skeleton>
            </ListItemIcon>
            <Skeleton
              enabled={dealCount === null}
              width={150}>
              <ListItemText>{dealCount} deals</ListItemText>
            </Skeleton>
          </ListItem>

          <ListItem
            disableGutters
            disablePadding>
            <ListItemIcon>
              <Skeleton
                enabled={!industry || loadingAssets}
                width={20}>
                <TrendingUpIcon />
              </Skeleton>
            </ListItemIcon>
            <Skeleton
              enabled={!industry || loadingAssets}
              width={150}>
              <ListItemText>
                {formatOrganicGrowth(ratings.organicGrowth)} revenue growth
              </ListItemText>
            </Skeleton>
          </ListItem>

          <ListItem
            disableGutters
            disablePadding>
            <ListItemIcon>
              <Skeleton
                enabled={!industry}
                width={20}>
                <LeafIcon />
              </Skeleton>
            </ListItemIcon>
            <Skeleton
              enabled={!industry}
              width={150}>
              <ListItemText>{formatEsgOverallRating(esgOverallRiskMedian)} ESG risk</ListItemText>
            </Skeleton>
          </ListItem>
        </List>
      </SearchTopResultSideInfo>
    </Stack>
  )
}
