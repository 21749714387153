import { SearchResultItem } from '@gain/rpc/app-model'
import { createContext, useContext } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

export const SearchResultsContext = createContext({})

export interface UseSearchResult {
  searching: boolean
  searchingOther: boolean
  searchTerm: string
  searchFilter: 'all' | 'entities' | 'assets' | 'industries' | 'investors'
  setSearchQuery: (query: string) => void
  setSearchFilter: (filter: string) => void
  hasResults: boolean
  result: {
    topResult?: SearchResultItem
    items: SearchResultItem[]
  }
}

export const useSearchAPI = () => useContext(SearchResultsContext) as UseSearchResult

export const useSearchQueryParam = () => {
  const [q, setQ] = useQueryParam('q', StringParam)
  return [q || '', setQ] as const
}

export const useSearchFilterQueryParam = () => {
  const [filter, setFilter] = useQueryParam('f', StringParam)
  return [filter || '', setFilter] as const
}
