import { useLenderCreditsPerTypeAndSubtypeDistribution } from '@gain/api/app/hooks'
import { CreditListItem, Lender, LenderCreditTypeAndSubtypeCount } from '@gain/rpc/app-model'
import {
  CREDIT_SUBTYPE_OPTIONS,
  CREDIT_TYPE_OPTIONS,
  getLabelFromOption,
} from '@gain/rpc/shared-model'
import { useOpenLink } from '@gain/utils/router'
import { MouseEvent } from 'react'

import Card, { CardContentEmpty, CardContentLoading, CardHeader } from '../../../common/card/card'
import { chartColorSetGrey } from '../../../common/chart/chart-colors'
import TableValueDistribution, {
  TableValueDistributionConfig,
  useValueDistributionRows,
  ValueDistributionRow,
} from '../../../common/table-value-distribution'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../features/filter/filter-bar'
import { generateLenderPagePath, LENDER_CREDITS_PATH } from '../lender-path'
import { MIN_VALUE_DISTRIBUTION_CARD_HEIGHT } from './route-summary-utils'

const CREDITS_PER_TYPE_CONFIG: TableValueDistributionConfig<LenderCreditTypeAndSubtypeCount> = {
  getValue: (credit) => credit.typeAndSubtype,
  getGroupBy: (key) => key.split('/')[0],
  formatValue: (value) => {
    const [type, subtype] = value.split('/')

    // If there is no subtype, we use the type as value
    if (!subtype) {
      return getLabelFromOption(CREDIT_TYPE_OPTIONS, type) || type
    }

    return getLabelFromOption(CREDIT_SUBTYPE_OPTIONS, subtype) || subtype
  },
  formatGroup: (value) => getLabelFromOption(CREDIT_TYPE_OPTIONS, value) || value.split('/')[0],
  getIncrementBy: (item) => item.count,
  colorSet: chartColorSetGrey.slice().reverse(),
}

interface CreditsPerTypeCardProps {
  lender: Lender
}

export default function CreditsPerTypeCard({ lender }: CreditsPerTypeCardProps) {
  const swrCreditsPerTypeAndSubtype = useLenderCreditsPerTypeAndSubtypeDistribution(lender.id)
  const openLink = useOpenLink()
  const rows = useValueDistributionRows(
    swrCreditsPerTypeAndSubtype.data || [],
    CREDITS_PER_TYPE_CONFIG
  )
  const creditsTabPath = generateLenderPagePath(lender.id, lender.name, LENDER_CREDITS_PATH)

  const handleRowClick = (row: ValueDistributionRow, event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    const types = row.values ? row.values.map((item) => item.type) : row.type
    const path = [
      creditsTabPath,
      filterModelToQueryString<CreditListItem, 'typeAndSubtype'>([
        filterValueGroup(filterValueItem('typeAndSubtype', types)),
      ]),
    ].join('?')

    openLink(path, event)
  }

  const isLoading = swrCreditsPerTypeAndSubtype.loading
  const isEmpty = !isLoading && rows.length === 0
  const hasRows = !isLoading && !isEmpty

  return (
    <Card
      href={hasRows ? creditsTabPath : undefined}
      sx={{ minHeight: MIN_VALUE_DISTRIBUTION_CARD_HEIGHT }}
      fullHeight>
      <CardHeader title={'Credits per type'} />
      {isLoading && <CardContentLoading />}
      {isEmpty && <CardContentEmpty message={'No data available'} />}
      {hasRows && (
        <TableValueDistribution
          itemLabel={'Credit type'}
          onRowClick={handleRowClick}
          rows={rows}
          shareLabel={'Credits'}
          disableExpand
        />
      )}
    </Card>
  )
}
