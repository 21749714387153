import { XIcon } from '@gain/components/icons'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { MouseEvent, ReactElement } from 'react'

const StyledRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disableDivider',
})<{ disableDivider?: boolean }>(({ disableDivider, theme }) => ({
  display: 'flex',
  padding: theme.spacing(1.5),
  alignItems: 'center',
  ...(!disableDivider && { borderBottom: `1px solid ${theme.palette.divider}` }),
}))

const StyledTitle = styled(Typography)({
  flex: 1,
  textAlign: 'center',
})

interface DialogHeaderProps {
  disableDivider?: boolean
  onCloseClick: (event: MouseEvent) => void
  title?: string | ReactElement
  titleProps?: TypographyProps
  className?: string
}

export default function DialogHeader({
  disableDivider,
  onCloseClick,
  title = '',
  titleProps,
}: DialogHeaderProps) {
  return (
    <StyledRoot disableDivider={disableDivider}>
      <StyledTitle
        variant={'h5'}
        {...titleProps}>
        {title}
      </StyledTitle>
      <IconButton
        onClick={onCloseClick}
        size={'small'}>
        <XIcon />
      </IconButton>
    </StyledRoot>
  )
}
