import { CompanyIcon } from '@gain/components/icons'
import { ArticleListItem } from '@gain/rpc/app-model'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import { listItemTextClasses } from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'
import { Waypoint } from 'react-waypoint'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemText,
  MobileListSubheader,
} from '../../../../common/list-mobile'
import Logo from '../../../../common/logo'
import { generateArticlePagePath } from '../../../utils'
import { useNewsFeedItems } from '../../news-feed/use-news-feed-items'
import { useHomeArticleListInfinite } from '../../use-home-article-list-infinite'

const StyledMobileListItemText = styled(MobileListItemText)({
  [`& .${listItemTextClasses.primary}`]: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    whiteSpace: 'initial',
  },
})

interface ArticleLogoProps {
  article: ArticleListItem
}

function ArticleLogo({ article }: ArticleLogoProps) {
  const region = useMemo(() => {
    if (article.highlightedAssetRegion) {
      return article.highlightedAssetRegion
    } else if (article.linkedAssetRegions.length) {
      const index = article.linkedAssetRegions.findIndex((item) => !!item)
      if (index > -1) {
        return article.linkedAssetRegions[index]
      }
    }

    return null
  }, [article.highlightedAssetRegion, article.linkedAssetRegions])

  const logoFileUrl = useMemo(() => {
    if (article.highlightedAssetLogoFileUrl) {
      return article.highlightedAssetLogoFileUrl
    } else if (article.linkedAssetLogoFileUrls.length) {
      const index = article.linkedAssetLogoFileUrls.findIndex((url) => !!url)
      if (index > -1) {
        return article.linkedAssetLogoFileUrls[index]
      }
    }

    return null
  }, [article.highlightedAssetLogoFileUrl, article.linkedAssetLogoFileUrls])

  return (
    <Logo
      defaultIcon={<CompanyIcon />}
      region={region}
      size={40}
      src={logoFileUrl}
    />
  )
}

export default function ArticleList() {
  const articles = useHomeArticleListInfinite()
  const items = useNewsFeedItems(articles.data)

  return (
    <List disablePadding>
      {items.map((item, index) => {
        if (item.type === 'header') {
          return <MobileListSubheader key={index}>{item.data}</MobileListSubheader>
        }

        return (
          <MobileListItem key={index}>
            <MobileListItemButton
              as={Link}
              {...{
                href: generateArticlePagePath({
                  articleId: item.data.id,
                  articleTitle: item.data.title || '',
                  preview: false,
                }),
              }}>
              <MobileListItemIcon>
                <ArticleLogo article={item.data} />
              </MobileListItemIcon>
              <StyledMobileListItemText primary={item.data.title} />
            </MobileListItemButton>
          </MobileListItem>
        )
      })}
      {!articles.isLoadingInitial && !articles.isReachingEnd && (
        <Waypoint
          bottomOffset={'-50%'}
          onEnter={articles.fetchMore}
        />
      )}
    </List>
  )
}
