import { useTrackVisitApi } from '@gain/api/app/hooks'
import { useAppMutateSwr, useRpcClient } from '@gain/api/swr'
import { UserRecentVisitList } from '@gain/rpc/app-model'
import { ObjectType } from '@gain/rpc/shared-model'
import { useCallback } from 'react'

export function useTrackVisit(objectId: number, objectType: ObjectType) {
  const mutateAssetList = useAppMutateSwr('account.listRecentVisits')
  useTrackVisitApi(
    { objectId, objectType },
    // There's an issue with infinite re-renders on the industry profile page when suspense
    // is set to true. It's unclear why this is happening.
    { onSuccess: () => mutateAssetList(), suspense: false }
  )
}

export function useDeleteRecentVisit() {
  const mutate = useAppMutateSwr('account.listRecentVisits')
  const fetcher = useRpcClient()

  return useCallback(
    async (id: number) => {
      await fetcher({
        method: 'account.deleteRecentVisit',
        params: { id },
      })

      await mutate(
        async (list: UserRecentVisitList | undefined) => {
          return list ? { ...list, items: list.items.filter((item) => item.id !== id) } : undefined
        },
        { revalidate: false }
      )
    },
    [mutate, fetcher]
  )
}
