import { styled } from '@mui/material/styles'
import { useState } from 'react'

import Card, { CardHeader } from '../../../common/card/card'
import { DropdownMenuOptions } from '../../../common/dropdown-menu'
import { AxisConfig, AxisConfigId } from '../../chart/company-bubble-echart/axis/axis-config'
import {
  ChartLibraryOption,
  chartLibraryOptions,
  findChartLibraryOptionById,
} from './chart-library-config'
import ChartToggleButton from './chart-toggle-button'

const StyledChartLibraryCard = styled(Card)({
  // This ensures the card takes up 100% of the height of the chart and
  // causes siblings to overflow if they take up more space.
  height: 0,
  minHeight: '100%',
  width: '100%',
})

const StyledChartLibraryMenu = styled('div', {
  shouldForwardProp: (propName) => propName !== 'shadow',
})<{ shadow: boolean }>(({ theme, shadow }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  overflow: 'hidden',
  overflowY: 'auto',
  overscrollBehavior: 'contain',
  borderTop: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create('boxShadow', {
    duration: theme.transitions.duration.shortest,
    easing: theme.transitions.easing.easeInOut,
  }),
  boxShadow: 'inset 0px 8px 8px -8px rgba(0, 0, 0, 0)',
  ...(shadow && {
    boxShadow: 'inset 0px 8px 8px -8px rgba(0, 0, 0, 0.04)',
  }),
}))

const StyledChartLibraryCardHeader = styled(CardHeader)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}))

interface ChartLibraryProps {
  axisConfig: DropdownMenuOptions<AxisConfig>
  xAxisId: AxisConfigId
  yAxisId: AxisConfigId
  onChange: (option: ChartLibraryOption) => void
}

export default function ChartLibrary({ xAxisId, yAxisId, ...other }: ChartLibraryProps) {
  const [hasScrolled, setHasScrolled] = useState(false)

  return (
    <StyledChartLibraryCard>
      <StyledChartLibraryCardHeader title={'Chart library'} />

      <StyledChartLibraryMenu
        onScroll={(event) => setHasScrolled(event.currentTarget.scrollTop > 0)}
        shadow={hasScrolled}>
        {chartLibraryOptions.map((option) => (
          <ChartToggleButton
            key={option.title}
            option={option}
            selected={findChartLibraryOptionById(xAxisId, yAxisId) === option}
            {...other}
          />
        ))}
      </StyledChartLibraryMenu>
    </StyledChartLibraryCard>
  )
}
