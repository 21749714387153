interface GridProps {
  x1: number
  x2: number
  yTicks: Array<{ value: number; y: number }>
  xTicks: Array<{ value: number; x: number }>
  y1: number
  y2: number
  disableYAxisLines?: boolean
}

export default function Grid({ x1, x2, yTicks, xTicks, y1, y2, disableYAxisLines }: GridProps) {
  return (
    <>
      {yTicks.map((tick) => (
        <line
          key={tick.y}
          stroke={'#eaecee'}
          strokeWidth={1}
          x1={x1}
          x2={x2}
          y1={tick.y}
          y2={tick.y}
        />
      ))}
      {!disableYAxisLines &&
        xTicks.map((tick) => (
          <line
            key={tick.x}
            stroke={'#eaecee'}
            strokeWidth={1}
            x1={tick.x}
            x2={tick.x}
            y1={y1}
            y2={y2}
          />
        ))}
    </>
  )
}
