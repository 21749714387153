import { useMemo } from 'react'
import { animated, useTransition } from 'react-spring'

interface GridProps {
  x1: number
  x2: number
  ticks: Array<{ value: number; y: number }>
  height: number
}

export default function Grid({ x1, x2, height, ticks }: GridProps) {
  const withIndices = useMemo(() => ticks.map((tick, index) => ({ ...tick, index })), [ticks])

  const transitions = useTransition(withIndices, {
    from: {
      transform: 'translate(0, 0px)',
      opacity: 0,
    },
    initial: {
      opacity: 0,
      transform: `translate(0, ${height}px)`,
    },
    enter: (item) => ({
      transform: `translate(0, ${item.y}px)`,
      opacity: 1,
    }),
    leave: {
      transform: 'translate(0, 0px)',
      opacity: 0,
    },
    update: (item) => ({
      transform: `translate(0, ${item.y}px)`,
    }),
    keys: (item) => item.index,
  })

  return transitions((style) => (
    <animated.line
      stroke={'#eaecee'}
      strokeWidth={1}
      style={style}
      x1={x1}
      x2={x2}
      y1={0}
      y2={0}
    />
  ))
}
