import Typography from '@gain/components/typography'
import Grid, { GridProps } from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import { KeyValue } from './key-value-grid-utils'

export interface KeyValueGridItemProps extends GridProps {
  keyValue: KeyValue
}

export const KeyValueGridItemContainer = styled(Grid)<GridProps>(({ theme }) => ({
  padding: theme.spacing(0.75, 3),
  minWidth: 0,
}))

export default function KeyValueGridItem({ keyValue, ...rest }: KeyValueGridItemProps) {
  return (
    <KeyValueGridItemContainer
      item
      {...rest}>
      {keyValue.label && (
        <Typography
          color={'text.secondary'}
          variant={'body2'}>
          {keyValue.label}
        </Typography>
      )}

      <Stack
        alignContent={'center'}
        alignItems={'center'}
        direction={'row'}
        gap={1}
        maxHeight={32}
        minHeight={32}>
        {keyValue.value && (
          <Typography
            color={'text.primary'}
            variant={'body2'}
            noWrap>
            {keyValue.value}
          </Typography>
        )}

        {keyValue.valueNode}
      </Stack>
    </KeyValueGridItemContainer>
  )
}
