import Typography from '@gain/components/typography'
import { isDefined } from '@gain/utils/common'
import { isNotNull } from '@gain/utils/typescript'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import { extent, scaleLinear, scaleTime, timeYear } from 'd3'
import { groupBy } from 'lodash'
import { Fragment, MouseEvent, useCallback, useMemo, useState } from 'react'

import { ChartGroupValueType } from '../../../../common/chart/chart-groups'
import { useMaxTextWidth } from '../../chart-utils/use-max-text-width'

const stackedBarChartClasses = generateUtilityClasses('StackedBarChart', ['clickable'])

const MARGIN = {
  top: 8 + 4,
  right: 0,
  bottom: 24 + 12,
}

const Y_LABEL_GAP = 8

const CHART_PADDING = 24

export const STACKED_BAR_CHART_BAR_WIDTH = 16

export const StyledBar = styled('rect')({
  width: STACKED_BAR_CHART_BAR_WIDTH,
  [`&.${stackedBarChartClasses.clickable}`]: {
    cursor: 'pointer',
  },
})

export const StyledXAxisLabel = styled('text')(({ theme }) => ({
  ...theme.typography.overline,
  fill: theme.palette.text.secondary,
  textAnchor: 'middle',
  transform: 'translateY(20px)',
}))

export const StyledYAxisLabel = styled('text')(({ theme }) => ({
  ...theme.typography.overline,
  fill: theme.palette.text.secondary,
  alignmentBaseline: 'middle',
  textAnchor: 'end',
}))

export const StyledYAxisLine = styled('line')(({ theme }) => ({
  stroke: theme.palette.divider,
}))

export interface ScaleConfig<D> {
  label?: string
  getValue: (d: D) => number | null
  getLabel: (value: number) => string
  getAxisLabel?: (value: number) => string
  type?: 'number' | 'date'
}

export interface StackedBarChartProps<Data> {
  width: number
  height: number
  data: Data[]
  xScaleConfig: ScaleConfig<Data>
  yScaleConfig: ScaleConfig<Data>
  getColor: (d: Data) => string
  getHighlightGroup: (d: Data) => number
  highlightGroup?: ChartGroupValueType
  onGroupClick?: (d: Data, event: MouseEvent) => void
}

export default function StackedBarChart<Data>({
  width,
  height,
  data,
  xScaleConfig,
  yScaleConfig,
  getColor,
  getHighlightGroup,
  highlightGroup,
  onGroupClick,
}: StackedBarChartProps<Data>) {
  const innerHeight = height - MARGIN.top - MARGIN.bottom

  const grouped = useMemo(() => {
    return groupBy(data, xScaleConfig.getValue)
  }, [data, xScaleConfig.getValue])

  const xValues = useMemo(() => {
    return data
      .map((value) => xScaleConfig.getValue(value))
      .filter(isNotNull)
      .map((value) => new Date(value, 0, 1))
  }, [xScaleConfig, data])

  const yValues = useMemo(() => {
    return Object.keys(grouped).reduce((xValueAcc, xValue) => {
      return xValueAcc.concat(
        grouped[xValue].reduce((acc, current) => acc + (yScaleConfig.getValue(current) || 0), 0)
      )
    }, new Array<number>())
  }, [grouped, yScaleConfig])

  const isEmpty = useMemo(() => {
    return xValues.length === 0 || yValues.length === 0
  }, [xValues, yValues])

  const yScale = useMemo(() => {
    const [, max] = extent(yValues)

    return scaleLinear()
      .domain([0, max ? max : 1])
      .range([innerHeight, 0])
      .nice(5)
  }, [innerHeight, yValues])

  const yTicks = useMemo(() => {
    return yScale.ticks(5)
  }, [yScale])

  const maxLabelWidth = useMaxTextWidth(
    yTicks.map((yTick) => yScaleConfig?.getAxisLabel?.(yTick) ?? yTick.toString())
  )
  const marginLeft = maxLabelWidth + Y_LABEL_GAP
  const innerWidth = width - MARGIN.right - marginLeft

  const xScale = useMemo(() => {
    const [min, max] = extent(xValues)
    return scaleTime()
      .domain([min || 0, max || 1])
      .range([0, innerWidth - CHART_PADDING * 2])
  }, [innerWidth, xValues])

  const [hoverGroup, setHoverGroup] = useState<number | null>(null)

  const handleMouseEnter = useCallback(
    (item: Data) => () => {
      setHoverGroup(getHighlightGroup(item))
    },
    [getHighlightGroup]
  )

  const handleMouseLeave = useCallback(() => {
    setHoverGroup(null)
  }, [])

  const handleGetItemHighlight = useCallback(
    (item: Data) => {
      const activeKey = isDefined(highlightGroup) ? highlightGroup : hoverGroup

      return activeKey === null || activeKey === getHighlightGroup(item)
    },
    [getHighlightGroup, highlightGroup, hoverGroup]
  )

  const handleGetItemLabelHighlight = useCallback(
    (item: Data) => {
      const activeKey = isDefined(highlightGroup) ? highlightGroup : hoverGroup

      return activeKey === getHighlightGroup(item)
    },
    [getHighlightGroup, highlightGroup, hoverGroup]
  )

  const handleBarClick = useCallback(
    (item: Data) => (event: MouseEvent) => {
      if (onGroupClick) {
        onGroupClick(item, event)
      }
    },
    [onGroupClick]
  )

  if (isEmpty) {
    return (
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ width, height, pb: 3 }}>
        <Typography
          color={'text.secondary'}
          variant={'body2'}>
          No data available
        </Typography>
      </Stack>
    )
  }

  return (
    <svg
      height={height}
      width={width}>
      <g transform={`translate(0, ${MARGIN.top})`}>
        {yTicks.map((value, index) => (
          <Fragment key={index}>
            <StyledYAxisLine
              x1={marginLeft}
              x2={width - MARGIN.right}
              y1={yScale(value)}
              y2={yScale(value)}
            />
            <StyledYAxisLabel
              x={marginLeft - Y_LABEL_GAP}
              y={yScale(value)}>
              {yScaleConfig.getAxisLabel ? yScaleConfig.getAxisLabel(value) : value}
            </StyledYAxisLabel>
          </Fragment>
        ))}
        <g transform={`translate(${CHART_PADDING + marginLeft}, 0)`}>
          {xScale.ticks(timeYear).map((value, index) => (
            <g
              key={index}
              transform={`translate(${xScale(value)}, ${innerHeight})`}>
              <StyledXAxisLabel>{value.getFullYear()}</StyledXAxisLabel>
            </g>
          ))}
          {xScale.ticks(timeYear).map((date, xIndex) => {
            const group = grouped[date.getFullYear()]

            if (!group) {
              return null
            }
            let currentHeight = 0
            return (
              <g key={xIndex}>
                {group.map((item, index) => {
                  const yValue = yScaleConfig.getValue(item)
                  if (yValue === null || yValue < 1) {
                    return null
                  }

                  const yPos = yScale(yValue) - currentHeight
                  const xPos = xScale(date) - STACKED_BAR_CHART_BAR_WIDTH / 2
                  const barHeight = innerHeight - yScale(yValue)
                  currentHeight += innerHeight - yScale(yValue)

                  return (
                    <Fragment key={index}>
                      <Tooltip
                        open={handleGetItemLabelHighlight(item)}
                        placement={'top'}
                        slotProps={{
                          popper: {
                            sx: {
                              zIndex: 1,
                            },
                          },
                        }}
                        title={yScaleConfig.getLabel(yValue)}>
                        <StyledBar
                          className={clsx({
                            [stackedBarChartClasses.clickable]: onGroupClick,
                          })}
                          fill={getColor(item)}
                          height={barHeight}
                          onClick={handleBarClick(item)}
                          onMouseEnter={handleMouseEnter(item)}
                          onMouseLeave={handleMouseLeave}
                          opacity={handleGetItemHighlight(item) ? 1 : 0.2}
                          x={xPos}
                          y={yPos}
                        />
                      </Tooltip>
                    </Fragment>
                  )
                })}
              </g>
            )
          })}
        </g>
      </g>
    </svg>
  )
}
