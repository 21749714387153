import Snackbar from '@gain/components/snackbar'
import Typography from '@gain/components/typography'
import Stack from '@mui/material/Stack'
import { SnackbarKey } from 'notistack'
import { forwardRef } from 'react'

import { RequestEmailPerson } from './request-email-button'

interface NoEmailFoundSnackbarProps {
  snackbarId: SnackbarKey
  person: RequestEmailPerson
}

/**
 * Snackbar that is shown when an email address could not be resolved.
 */
const NoEmailFoundSnackbar = forwardRef<HTMLDivElement, NoEmailFoundSnackbarProps>(
  ({ snackbarId, person }, ref) => {
    return (
      <Snackbar
        ref={ref}
        id={snackbarId}
        message={
          <Stack>
            <Typography
              color={'text.secondary'}
              variant={'overline'}>
              {person.firstName} {person.lastName}
            </Typography>
            <Typography
              color={'text.primary'}
              variant={'subtitle2'}>
              We were unable to find an email address
            </Typography>
          </Stack>
        }
      />
    )
  }
)

export default NoEmailFoundSnackbar
