import { useLenderCreditsPerCountryCodeDistribution } from '@gain/api/app/hooks'
import { CreditListItem, Lender, LenderCreditCountryCodeCount } from '@gain/rpc/app-model'
import { CountryCode, getCountry, getRegionForCountryCode } from '@gain/utils/countries'
import { useOpenLink } from '@gain/utils/router'
import { MouseEvent } from 'react'

import Card, { CardContentEmpty, CardContentLoading, CardHeader } from '../../../common/card/card'
import { chartColorSet } from '../../../common/chart/chart-colors'
import TableValueDistribution, {
  TableValueDistributionConfig,
  useValueDistributionRows,
  ValueDistributionRow,
} from '../../../common/table-value-distribution'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../features/filter/filter-bar'
import { generateLenderPagePath, LENDER_CREDITS_PATH } from '../lender-path'
import { MIN_VALUE_DISTRIBUTION_CARD_HEIGHT } from './route-summary-utils'

const CREDITS_PER_REGION_CONFIG: TableValueDistributionConfig<LenderCreditCountryCodeCount> = {
  getValue: (credit) => credit.countryCode,
  getGroupBy: (key) => getRegionForCountryCode(key) || 'Other',
  formatValue: (value) => getCountry(value as CountryCode) || 'Unknown',
  formatGroup: (value) => value,
  getIncrementBy: (item) => item.count,
  colorSet: chartColorSet,
}

interface CreditsPerRegionCardProps {
  lender: Lender
}

export default function CreditsPerRegionCard({ lender }: CreditsPerRegionCardProps) {
  const swrCreditsPerCountryCode = useLenderCreditsPerCountryCodeDistribution(lender.id)
  const openLink = useOpenLink()
  const rows = useValueDistributionRows(
    swrCreditsPerCountryCode.data || [],
    CREDITS_PER_REGION_CONFIG
  )
  const creditsTabPath = generateLenderPagePath(lender.id, lender.name, LENDER_CREDITS_PATH)

  const handleRowClick = (row: ValueDistributionRow, event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    const regions = row.values ? row.values.map((item) => item.type) : row.type
    const path = [
      creditsTabPath,
      filterModelToQueryString<CreditListItem, 'assetHqCountryCode'>([
        filterValueGroup(filterValueItem('assetHqCountryCode', regions)),
      ]),
    ].join('?')

    openLink(path, event)
  }

  const isLoading = swrCreditsPerCountryCode.loading
  const isEmpty = !isLoading && rows.length === 0
  const hasRows = !isLoading && !isEmpty

  return (
    <Card
      href={hasRows ? creditsTabPath : undefined}
      sx={{ minHeight: MIN_VALUE_DISTRIBUTION_CARD_HEIGHT }}
      fullHeight>
      <CardHeader title={'Credits per region'} />
      {isLoading && <CardContentLoading />}
      {isEmpty && <CardContentEmpty message={'No data available'} />}
      {hasRows && (
        <TableValueDistribution
          itemLabel={'Region'}
          onRowClick={handleRowClick}
          rows={rows}
          shareLabel={'Credits'}
          disableExpand
        />
      )}
    </Card>
  )
}
