import { CreditListItem, Lender, LenderInvestorListItem } from '@gain/rpc/app-model'
import { listSort, listSortArray } from '@gain/rpc/utils'
import { formatMonthYear } from '@gain/utils/date'
import { encodeSortQueryParam } from '@gain/utils/sort'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import { useMemo, useRef } from 'react'

import Card, { CardContentEmpty, CardHeader } from '../../../common/card/card'
import ContentLink from '../../../common/content-link'
import Table, { createTableColumns } from '../../../common/table'
import CompaniesCell from '../../../features/asset/companies-cell'
import {
  autocompleteIncludeFilterValue,
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../features/filter/filter-bar'
import FinancialValue from '../../../features/financial/financial-value'
import InvestorContentLink from '../../../features/investor/investor-content-link'
import { generateLenderPagePath, LENDER_CREDITS_PATH, LENDER_INVESTORS_PATH } from '../lender-path'
import { MIN_TABLE_CARD_HEIGHT } from './route-summary-utils'

function useColumns(lender: Lender) {
  return useMemo(() => {
    return createTableColumns<LenderInvestorListItem>(
      {
        field: 'investorName',
        headerName: 'Investor name',
        renderCell: ({ row }) => (
          <InvestorContentLink
            id={row.investorId}
            logoFileUrl={row.investorLogoFileUrl}
            name={row.investorName}
          />
        ),
      },
      {
        headerName: 'Total debt',
        field: 'totalDebtQuantumEur',
        width: 120,
        align: 'right',
        renderCell: ({ value }) => <FinancialValue amount={value} />,
      },
      {
        headerName: 'Companies',
        field: 'linkedAssets',
        width: 120,
        renderCell: ({ value }) => <CompaniesCell assets={value} />,
      },
      {
        headerName: 'Credits',
        field: 'creditCount',
        width: 80,
        align: 'right',
        renderCell: ({ row }) => {
          const href = [
            generateLenderPagePath(lender.id, lender.name, LENDER_CREDITS_PATH),
            filterModelToQueryString<CreditListItem, 'dealBuyerInvestorIds'>([
              filterValueGroup(
                filterValueItem(
                  'dealBuyerInvestorIds',
                  autocompleteIncludeFilterValue([row.investorId])
                )
              ),
            ]),
          ].join('?')

          return (
            <ContentLink
              href={href}
              label={row.creditCount.toString()}
            />
          )
        },
      },
      {
        // Don't show latest issuance date
        headerName: 'Latest issuance',
        field: 'latestDealYear',
        width: 120,
        align: 'right',
        valueFormatter: ({ row }) => formatMonthYear(row.latestDealMonth, row.latestDealYear),
      }
    )
  }, [lender.id, lender.name])
}
export const KEY_INVESTORS_SORT = listSortArray<LenderInvestorListItem>(
  listSort('totalDebtQuantumEur', 'desc'),
  listSort('creditCount', 'desc')
)

interface KeyInvestorsCardProps {
  lender: Lender
  investors: LenderInvestorListItem[]
}

export const columnVisibility: ColumnVisibilityModel<LenderInvestorListItem> = {
  latestDealYear: 680,
}

export default function KeyInvestorsCard({ lender, investors }: KeyInvestorsCardProps) {
  const tableRef = useRef<HTMLDivElement>(null)
  const path = [
    generateLenderPagePath(lender.id, lender.name, LENDER_INVESTORS_PATH),
    `sort=${encodeSortQueryParam<LenderInvestorListItem>(KEY_INVESTORS_SORT)}`,
  ].join('?')

  const visibleColumns = useVisibleColumns(tableRef, useColumns(lender), columnVisibility)

  return (
    <Card
      href={investors.length > 0 ? path : undefined}
      sx={{ minHeight: MIN_TABLE_CARD_HEIGHT }}
      fullHeight>
      <CardHeader title={'Key investors'} />
      {investors.length === 0 && <CardContentEmpty message={'No data available'} />}
      {investors.length > 0 && (
        <Table
          ref={tableRef}
          columns={visibleColumns}
          rows={investors}
          dense
          disablePaddingBottomOnLastRow
          disableStickyHeader
        />
      )}
    </Card>
  )
}
