import { DownloadIcon } from '@gain/components/icons'
import { Asset } from '@gain/rpc/app-model'
import Button from '@mui/material/Button'
import Hidden from '@mui/material/Hidden'
import { useCallback, useState } from 'react'

import { useAssetPageParams } from '../../utils'
import AssetDownloadsDialog from './asset-downloads-dialog'

export interface AssetHeaderDownloadsProps {
  asset: Asset
}

export default function AssetHeaderDownloads({ asset }: AssetHeaderDownloadsProps) {
  const [showDownloadsDialog, setShowDownloadsDialog] = useState(false)
  const params = useAssetPageParams()

  const handleShowDownloadsDialog = useCallback(() => {
    setShowDownloadsDialog(true)
  }, [])

  const handleCloseDownloadsDialog = useCallback(() => {
    setShowDownloadsDialog(false)
  }, [])

  return (
    <>
      <AssetDownloadsDialog
        asset={asset}
        onClose={handleCloseDownloadsDialog}
        open={showDownloadsDialog}
        preview={params.preview}
      />

      <Hidden xsDown>
        <Button
          color={'primary'}
          onClick={handleShowDownloadsDialog}
          startIcon={<DownloadIcon />}
          variant={'contained'}>
          Downloads
        </Button>
      </Hidden>
    </>
  )
}
