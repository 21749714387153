import Button, { buttonClasses } from '@mui/material/Button'
import { DialogProps } from '@mui/material/Dialog'
import DialogActions, { dialogActionsClasses } from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText, { dialogContentTextClasses } from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import { MouseEvent, ReactElement, useCallback } from 'react'

import { StyledDialog } from './styled-dialog'

export interface AlertDialogProps extends Omit<DialogProps, 'onClose'> {
  title?: string
  message: string | ReactElement
  buttonText?: string
  onClose?: (event: MouseEvent, reason: 'backdropClick' | 'escapeKeyDown' | 'button') => void
}

const StyledAlertDialog = styled(StyledDialog)<DialogProps>(({ theme }) => ({
  [`& .${dialogActionsClasses.root}`]: {
    justifyContent: 'center',
  },

  [`& .${buttonClasses.root}`]: {
    minWidth: 132,
  },

  [`& .${dialogContentTextClasses.root} p + p`]: {
    // Ensure proper spacing when the dialog contains multiple paragraphs
    marginTop: theme.spacing(1),
  },
}))

export function AlertDialog({
  title,
  message,
  buttonText = 'Ok',
  onClose,
  ...props
}: AlertDialogProps) {
  const handleOnClose = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()

      onClose?.(event, 'backdropClick')
    },
    [onClose]
  )

  const handleCloseClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()

      onClose?.(event, 'button')
    },
    [onClose]
  )

  return (
    <StyledAlertDialog
      maxWidth={'xs'}
      onClose={handleOnClose}
      {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color={'primary'}
          onClick={handleCloseClick}
          variant={'outlined'}
          autoFocus>
          {buttonText}
        </Button>
      </DialogActions>
    </StyledAlertDialog>
  )
}
