import { ChevronDownIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { formatRatio } from '@gain/utils/number'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { MouseEvent, useState } from 'react'

import { RatioOption } from './ratio-utils'

export interface RatioSelectProps {
  value: number
  onChange: (value: number) => void
  options: RatioOption[]
}

/**
 * RatioSelect displays a button that toggles a dropdown menu to select
 * the ratio that should be displayed in the chart. It also shows the most
 * recently reported value for the given ratio. Used on mobile only.
 */
export default function RatioSelect({ value, onChange, options }: RatioSelectProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (newValue: number) => () => {
    onChange(newValue)
    handleClose()
  }

  // Bail out if options are not available
  if (options.length === 0) {
    return null
  }

  return (
    <Stack>
      <Stack
        alignItems={'center'}
        direction={'row'}>
        <Typography
          color={'text.secondary'}
          variant={'button'}>
          Selected ratio:
        </Typography>
        <Button
          color={'primary'}
          endIcon={<ChevronDownIcon />}
          onClick={handleClick}>
          {options[value].label}
        </Button>
        <Menu
          anchorEl={anchorEl}
          onClose={handleClose}
          open={open}>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              disabled={option.disabled}
              onClick={handleChange(index)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
      <Typography
        color={'text.primary'}
        my={0.5}
        variant={'h5'}>
        {formatRatio(options[value].ratio)}
      </Typography>
    </Stack>
  )
}
