import { AppSwrKey, useRpcClient } from '@gain/api/swr'
import Snackbar from '@gain/components/snackbar'
import { BookmarkListItem, BookmarkListType } from '@gain/rpc/app-model'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { mutate } from 'swr'

import { BOOKMARKS_LIST_PATH } from '../routes/utils'
import { useCheckBookmarkListError, useMutateBookmarkList } from './bookmark-list-hooks'

const SNACKBAR_KEY = 'bookmarks-list-'

function useShowBookmarkListSnackbar() {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  return useCallback(
    async (list: BookmarkListItem, assetIds: number[], action: 'added' | 'removed') => {
      const path = generatePath(BOOKMARKS_LIST_PATH, { listId: list.id })

      const actionMsg = action === 'added' ? 'added to' : 'removed from'
      const msg =
        assetIds.length === 1
          ? `Company was ${actionMsg} “${list.title}”`
          : `${assetIds.length} companies were ${actionMsg} “${list.title}”`

      // Add action to key to show separate snackbar for each action
      const key = `${SNACKBAR_KEY}-${action}`

      enqueueSnackbar(undefined, {
        key,
        content: () => (
          <Snackbar
            action={{
              title: 'View',
              onClick: () => history.push(path),
            }}
            id={key}
            message={msg}
            variant={'success'}
          />
        ),
        preventDuplicate: true,
      })
    },
    [enqueueSnackbar, history]
  )
}

export function useAddBookmarks() {
  const mutateList = useMutateBookmarkList()
  const checkListError = useCheckBookmarkListError()
  const fetcher = useRpcClient()
  const showSnackbar = useShowBookmarkListSnackbar()

  return useCallback(
    async (
      list: BookmarkListItem,
      objectIds: number[],
      disableSnackbar = false,
      disableMutate = false
    ) => {
      try {
        await fetcher({
          method: 'lists.addBookmarks',
          params: { bookmarkListId: list.id, objectIds },
        })

        if (!disableSnackbar) {
          await showSnackbar(list, objectIds, 'added')
        }

        if (!disableMutate) {
          await mutateList(list.id)
        }

        // To show the bookmark icon as "enabled", mutate the SWR key for the
        // lists.getBookmarkListsByObject request that contains the object.
        await mutateGetBookmarkListsByObject(list.type, ...objectIds)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, fetcher, mutateList, showSnackbar]
  )
}

export function useDeleteBookmarks() {
  const mutateList = useMutateBookmarkList()
  const checkListError = useCheckBookmarkListError()
  const fetcher = useRpcClient()
  const showSnackbar = useShowBookmarkListSnackbar()

  return useCallback(
    async (
      list: BookmarkListItem,
      objectIds: number[],
      disableSnackbar = false,
      disableMutate = false
    ) => {
      try {
        await fetcher({
          method: 'lists.deleteBookmarks',
          params: { bookmarkListId: list.id, objectIds },
        })

        if (!disableSnackbar) {
          await showSnackbar(list, objectIds, 'removed')
        }

        if (!disableMutate) {
          await mutateList(list.id)
        }

        // To show the bookmark icon as "disabled", mutate the SWR key for the
        // lists.getBookmarkListsByObject request that contains the object.
        await mutateGetBookmarkListsByObject(list.type, ...objectIds)
      } catch (err) {
        checkListError(err)
      }
    },
    [checkListError, fetcher, mutateList, showSnackbar]
  )
}

/**
 * Mutate the SWR key for getBookmarkListsByObject requests that contain the
 * given objects.
 */
function mutateGetBookmarkListsByObject(objectType: BookmarkListType, ...objectIds: number[]) {
  return mutate((key?: AppSwrKey<'lists.getBookmarkListsByObject'>) => {
    return (
      key?.method === 'lists.getBookmarkListsByObject' &&
      key?.params?.objectType === objectType &&
      key?.params?.objectId &&
      objectIds.includes(key.params.objectId)
    )
  })
}
