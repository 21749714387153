import OverflowContainer from '@gain/components/overflow-container'
import { AssetListItem } from '@gain/rpc/app-model'
import { AssetShareholderShare } from '@gain/rpc/shared-model'
import { RenderCellParams } from '@gain/utils/table'
import { useMemo } from 'react'

import { InvestorListItemChip } from '../../investor/investor-list-item-chip/investor-list-item-chip'

export function AssetListItemTableCellMinorityInvestors({
  row,
}: RenderCellParams<AssetListItem, 'ownerNames'>) {
  const investors = useMemo(() => {
    const minorityInvestors = [] as { id: number; name: string }[]

    // Loop through all the shares and get the investors that have a minority share
    row.ownerShares.forEach((share, index) => {
      if (share === AssetShareholderShare.Minority) {
        minorityInvestors.push({
          id: row.ownerIds[index],
          name: row.ownerNames[index],
        })
      }
    })

    return minorityInvestors
  }, [row])

  if (!row.ownerShares || row.ownerShares.length === 0) {
    return <>-</>
  }

  return (
    <OverflowContainer
      chipSize={'medium'}
      lineHeight={24}
      maxLines={1}
      disableObserver>
      {investors.map((investor) => (
        <InvestorListItemChip
          key={investor.id}
          id={investor.id}
          label={investor.name}
        />
      ))}
    </OverflowContainer>
  )
}
