import { LegalEntity } from '@gain/rpc/app-model'
import { compareNumberDesc } from '@gain/utils/common'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useState } from 'react'

import Card, { CardHeader } from '../../../../common/card/card'
import { cardHeaderClasses } from '../../../../common/card/card/card-header'
import SelectMenu from '../../../../common/select-menu/select-menu'
import LegalStructuresAndShareholdersTable from './legal-structures-and-shareholders-table'
import OrgChart from './org-chart/org-chart'
import { TempLegalEntityStructureNode } from './org-chart/org-chart-model'

enum DisplayType {
  Table,
  Tree,
}

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  gap: theme.spacing(1),

  // On mobile, list card header items below each other
  [theme.breakpoints.only('xs')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
    height: 'auto',
    alignItems: 'flex-start',
  },
  [`& .${cardHeaderClasses.title}`]: {
    whiteSpace: 'nowrap',
  },
  [`& .${cardHeaderClasses.actionsContainer}`]: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),

    // On mobile show actions full width below each other
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      gap: theme.spacing(1.5),
      flex: 1,
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
}))

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    width: '100%',
  },
}))

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  whiteSpace: 'nowrap',
  [theme.breakpoints.only('xs')]: {
    flex: 1,
  },
}))

const StyledSelectMenu = styled(SelectMenu)(({ theme }) => ({
  maxWidth: 250,
  minWidth: 0,
  width: 0,
  flexGrow: 1,
  justifyContent: 'flex-end',
  [theme.breakpoints.only('xs')]: {
    maxWidth: '100%',
    width: '100%',
    justifyContent: 'flex-start',
  },
})) as typeof SelectMenu // Cast back to SelectMenu to ensure generics work

function compareLegalEntityByRevenueAndName(a: LegalEntity, b: LegalEntity) {
  const aRevenue = a.financialLatests?.revenue
  const bRevenue = b.financialLatests?.revenue

  const revenueCompare = compareNumberDesc(aRevenue, bRevenue)
  if (revenueCompare !== 0) {
    return revenueCompare
  }

  return a.name.localeCompare(b.name)
}

interface LegalStructuresAndShareholdersPropsCard {
  structure: TempLegalEntityStructureNode[]
  legalEntities: LegalEntity[]
}

export default function LegalStructuresAndShareholdersCard({
  structure,
  legalEntities,
}: LegalStructuresAndShareholdersPropsCard) {
  const sortedEntities = legalEntities.sort(compareLegalEntityByRevenueAndName)
  const [displayType, setDisplayType] = useState(
    structure.length === 0 ? DisplayType.Table : DisplayType.Tree
  )
  const selectActiveStructureOptions = structure.map((item, index) => ({
    label: item.name,
    value: index,
  }))

  // Only used for the tree view to select the active top level legal entity
  // for which the chart is rendered
  const [activeStructureIndex, setActiveStructureIndex] = useState(0)

  const handleSetDisplayType = (_, value: DisplayType) => {
    if (value !== null) {
      setDisplayType(value)
    }
  }

  return (
    <Card sx={{ pb: 0, overflow: 'hidden' }}>
      <StyledCardHeader
        actions={
          structure.length > 0 && (
            <>
              {structure.length > 1 && displayType === DisplayType.Tree && (
                <StyledSelectMenu
                  label={'Entity'}
                  onChange={setActiveStructureIndex}
                  options={selectActiveStructureOptions}
                  value={activeStructureIndex}
                />
              )}
              <StyledToggleButtonGroup
                onChange={handleSetDisplayType}
                size={'small'}
                value={displayType}
                exclusive>
                <StyledToggleButton value={DisplayType.Tree}>Tree view</StyledToggleButton>
                <StyledToggleButton value={DisplayType.Table}>Table view</StyledToggleButton>
              </StyledToggleButtonGroup>
            </>
          )
        }
        title={'Legal structure'}
      />
      {displayType === DisplayType.Table && (
        <LegalStructuresAndShareholdersTable legalEntities={sortedEntities} />
      )}
      {displayType === DisplayType.Tree && structure.length > 0 && (
        <OrgChart
          // Force the chart to re-render when a different structure is selected
          key={activeStructureIndex}
          structure={structure[activeStructureIndex]}
        />
      )}
    </Card>
  )
}
