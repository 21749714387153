import { BookmarkListItem } from '@gain/rpc/app-model'
import { MenuProps } from '@mui/material/Menu'
import { PopoverOrigin } from '@mui/material/Popover'
import { useCallback } from 'react'

import { useAddBookmarks, useDeleteBookmarks } from '../../../api/bookmark-hooks'
import { isLegacyCustomAssetList, useSortedBookmarkLists } from '../../../api/bookmark-list-hooks'
import BookmarkListMenu, { BookmarkListMenuCloseReason } from './bookmark-list-menu'

export type BookmarkListAddMenuProps = Omit<
  MenuProps,
  'transformOrigin' | 'onClose' | 'anchorOrigin'
> & {
  objectIds: number[]
  onClose: () => void
  excludeListIds?: number[]
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  disableCloseOnSelect?: boolean
  disableSnackbar?: boolean
}

export default function BookmarkListAddMenu({
  objectIds,
  onClose,
  excludeListIds,
  open,
  anchorEl,
  disableCloseOnSelect,
  disableSnackbar,
  ...props
}: BookmarkListAddMenuProps) {
  const bookmarkLists = useSortedBookmarkLists()
  const addBookmarks = useAddBookmarks()
  const deleteBookmarks = useDeleteBookmarks()

  // For now, we only support adding bookmarks to LegacyCustomAssetLists. In the
  // future we will allow hybrid bookmark lists which should be handled differently.
  const legacyCustomAssetLists = bookmarkLists.filter(isLegacyCustomAssetList)

  const handleClose = useCallback(
    (reason: BookmarkListMenuCloseReason, list?: BookmarkListItem) => {
      if (['addBookmark', 'createBookmarkList'].includes(reason) && objectIds.length > 0 && list) {
        // If the user adds items or creates a new list, add the objects to the list.
        addBookmarks(list, objectIds, disableSnackbar, true)
      } else if (reason === 'removeBookmark' && list) {
        // If the user deselects an item, remove the object from the list. This
        // only happens if you select 1 item
        deleteBookmarks(list, objectIds, disableSnackbar, true)
      }

      // When disableCloseOnSelect is true we might want to add or remove bookmarks
      // to multiple lists. In this case we don't want to close the menu.
      if (disableCloseOnSelect && ['addBookmark', 'removeBookmark'].includes(reason)) {
        return
      }

      onClose()
    },
    [objectIds, onClose, addBookmarks, deleteBookmarks, disableCloseOnSelect, disableSnackbar]
  )

  return (
    <BookmarkListMenu
      anchorEl={anchorEl}
      excludeListIds={excludeListIds}
      lists={legacyCustomAssetLists}
      objectIds={objectIds}
      onClose={handleClose}
      open={open}
      {...props}
    />
  )
}
