import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import { useLocation } from 'react-router-dom'

import { createTabProps } from '../../common/create-tab-props'
import {
  INVESTOR_FUND_LIST_PATH,
  INVESTOR_LIST_PATH,
  INVESTOR_STRATEGIES_LIST_PATH,
} from '../utils'

const StyledTabs = styled(Tabs)({
  [`& .${tabsClasses.indicator}`]: {
    transition: 'none',
  },
})

export function InvestorListPageTabs() {
  const location = useLocation()

  return (
    <StyledTabs value={location.pathname}>
      <Tab
        label={'Investors'}
        {...createTabProps(0, INVESTOR_LIST_PATH)}
      />
      <Tab
        label={'Strategies'}
        {...createTabProps(1, INVESTOR_STRATEGIES_LIST_PATH)}
      />
      <Tab
        label={'Funds'}
        {...createTabProps(2, INVESTOR_FUND_LIST_PATH)}
      />
    </StyledTabs>
  )
}
