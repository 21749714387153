import { AssetListItem } from '@gain/rpc/app-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { formatPercentage, formatThousands } from '@gain/utils/number'
import Chip from '@mui/material/Chip'
import { useMemo } from 'react'

import { ChartSizeTypeConfig } from '../../../common/chart/chart-select'
import { calculateFteRangeAvg } from '../../../common/fte'

export enum AssetChartSizeType {
  Revenue = 'revenue',
  GrossMargin = 'grossMargin',
  Ebitda = 'ebitda',
  EbitdaPercentage = 'ebitdaPercentage',
  Fte = 'fte',
  EV = 'EV',
}

/**
 * Returns a list of types to change the size of the assets in a chart.
 */
export function useAssetChartSizeTypes(): ChartSizeTypeConfig<AssetListItem>[] {
  const formatCurrency = useFormatCurrencyCallback()

  return useMemo(() => {
    return [
      {
        id: AssetChartSizeType.Fte,
        getValue: (asset) => {
          if (typeof asset.fte === 'number') {
            return asset.fte
          }

          // If fte is not defined but the fte range is, we use the midpoint of the range.
          if (typeof asset.fteRange === 'string') {
            return calculateFteRangeAvg(asset.fteRange)
          }

          return null
        },
        label: 'FTE',
        formatter: (value, asset) => {
          if (typeof asset.fte === 'number') {
            return formatThousands(value)
          }

          if (typeof asset.fteRange === 'string') {
            return asset.fteRange
          }

          return '-'
        },
      },
      {
        id: AssetChartSizeType.Revenue,
        getValue: (asset) => asset.revenueWithAiGeneratedEur,
        label: 'Revenue',
        formatTooltipLabel: (asset) => (
          <>
            Revenue
            {asset.revenueIsAiGenerated && (
              <>
                &nbsp;
                <Chip
                  color={'warning'}
                  label={'AI'}
                  size={'small'}
                />
              </>
            )}
          </>
        ),
        formatter: (value) => formatCurrency(value),
      },
      {
        id: AssetChartSizeType.GrossMargin,
        getValue: (asset) => asset.grossMarginEur,
        label: 'Gross margin',
        formatter: (value) => formatCurrency(value),
      },
      {
        id: AssetChartSizeType.Ebitda,
        getValue: (asset) => asset.ebitdaWithAiGeneratedEur,
        label: 'EBITDA',
        formatter: (value) => formatCurrency(value),
        formatTooltipLabel: (asset) => (
          <>
            EBITDA
            {asset.ebitdaIsAiGenerated && (
              <>
                &nbsp;
                <Chip
                  color={'warning'}
                  label={'AI'}
                  size={'small'}
                />
              </>
            )}
          </>
        ),
      },
      {
        id: AssetChartSizeType.EbitdaPercentage,
        getValue: (asset) => asset.ebitdaPctRevenueWithAiGenerated,
        label: 'EBITDA %',
        formatter: (value) => formatPercentage(value),
        formatTooltipLabel: (asset) => (
          <>
            EBITDA %
            {asset.ebitdaIsAiGenerated && (
              <>
                &nbsp;
                <Chip
                  color={'warning'}
                  label={'AI'}
                  size={'small'}
                />
              </>
            )}
          </>
        ),
      },
      {
        id: AssetChartSizeType.EV,
        getValue: (asset) => asset.enterpriseValueEur ?? asset.nextYearPredictedEvEur,
        label: 'EV',
        explainer: {
          title: 'Enterprise Values',
          message: `
            For public companies, Enterprise Values are based on share price data.
            For privately owned companies, Enterprise Values are estimated.`,
        },
        formatter: (value) => formatCurrency(value),
        formatTooltipLabel: (asset) => {
          if (asset.enterpriseValueEur) {
            return 'EV'
          }

          return 'est. EV'
        },
      },
    ]
  }, [formatCurrency])
}
