import { isDefined } from '@gain/utils/common'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'

import { cardClasses } from '../../../common/card/card'
import Rating from '../../../common/rating'
import RatingLabel from '../../../common/rating/rating-label'
import Table, { ColumnConfig } from '../../../common/table'

const industryAssessmentTableClasses = generateUtilityClasses('IndustryAssessmentTable', [
  'ratingValue',
  'ratingFormattedValue',
])

const StyledRatingContainer = styled('div')({
  position: 'relative',
  height: '100%',
})

const StyledRatingPart = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  transition: theme.transitions.create('opacity'),
  opacity: 0,
  justifyContent: 'flex-end',
  [`&.${industryAssessmentTableClasses.ratingValue}`]: {
    [`.${cardClasses.root}:not(.${cardClasses.hover}) &`]: {
      opacity: 1,
    },
    [`.${cardClasses.root}.${cardClasses.hover} &`]: {
      opacity: 0,
    },
  },
  [`&.${industryAssessmentTableClasses.ratingFormattedValue}`]: {
    [`.${cardClasses.root}:not(.${cardClasses.hover}) &`]: {
      opacity: 0,
    },
    [`.${cardClasses.root}.${cardClasses.hover} &`]: {
      opacity: 1,
    },
  },
}))

export interface RatingRow {
  label: string
  value: number | null
  formattedValue: string
}

export interface CardAssessmentSummaryProps {
  rows: RatingRow[]
}

export default function CardAssessmentSummaryTable({ rows }: CardAssessmentSummaryProps) {
  const columns = useMemo(
    () =>
      [
        {
          headerName: 'Investment criteria',
          field: 'label',
          width: 160,
        },
        {
          field: 'value',
          headerName: 'Rating',
          align: 'right',
          renderCell: ({ value, row }) => (
            <StyledRatingContainer>
              <StyledRatingPart className={industryAssessmentTableClasses.ratingValue}>
                {isDefined(value) ? <Rating value={value} /> : '-'}
              </StyledRatingPart>
              <StyledRatingPart className={industryAssessmentTableClasses.ratingFormattedValue}>
                {isDefined(value) ? (
                  <RatingLabel rating={value}>{row.formattedValue}</RatingLabel>
                ) : (
                  '-'
                )}
              </StyledRatingPart>
            </StyledRatingContainer>
          ),
        },
      ] as ColumnConfig<RatingRow>[],

    []
  )

  return (
    <Table
      columns={columns}
      rows={rows}
      dense
      disablePaddingBottomOnLastRow
      disableStickyHeader
    />
  )
}
