import OverflowContainer from '@gain/components/overflow-container'
import { InvestorStrategyListItem } from '@gain/rpc/app-model'
import {
  getLabelFromOption,
  INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS,
  InvestorStrategyClassification,
} from '@gain/rpc/shared-model'

import Tag from '../../../common/tag'
import { INVESTOR_STRATEGIES_LIST_PATH } from '../../../routes/utils'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../filter/filter-bar'

interface InvestorClassificationsTableCellProps {
  classifications: InvestorStrategyClassification[]
}

export default function InvestorClassificationsTableCell({
  classifications,
}: InvestorClassificationsTableCellProps) {
  if (!classifications || classifications.length === 0) {
    return <>-</>
  }

  return (
    <OverflowContainer
      chipSize={'medium'}
      lineHeight={24}
      maxLines={1}
      disableObserver>
      {classifications.map((classification) => (
        <Tag
          key={classification}
          href={[
            INVESTOR_STRATEGIES_LIST_PATH,
            filterModelToQueryString<InvestorStrategyListItem, 'classifications'>([
              filterValueGroup(filterValueItem('classifications', [classification])),
            ]),
          ].join('?')}
          label={`${getLabelFromOption(INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS, classification)}`}
          size={'medium'}
        />
      ))}
    </OverflowContainer>
  )
}
