import { useBookmarkLists, useGetBookmarkListsByObject, useUserCurrency } from '@gain/api/app/hooks'
import { AppSwrKey, UseAppListSwrResult, useRpcClient } from '@gain/api/swr'
import {
  AssetListItem,
  BookmarkList,
  BookmarkListItem,
  BookmarkListType,
  UpdateBookmarkListParams,
  UserPermissionObjectType,
  UserPermissionRole,
} from '@gain/rpc/app-model'
import { List, ListFilter } from '@gain/rpc/list-model'
import { RpcError, RpcErrorCode } from '@gain/rpc/utils'
import { compareNumberDesc } from '@gain/utils/common'
import { isXs } from '@gain/utils/responsive'
import { compareDesc } from 'date-fns/compareDesc'
import { useCallback, useMemo, useRef } from 'react'
import { generatePath, matchPath, useHistory } from 'react-router-dom'
import { mutate } from 'swr'

import { AlertDialogContextProps, changesNotSavedDialog, useOpenDialog } from '../common/dialog'
import {
  BOOKMARKS_FILTERED_PATH,
  BOOKMARKS_LIST_PATH,
  BOOKMARKS_PATH,
  BookmarksPathParams,
  HOME_PATH,
} from '../routes/utils'
import { useCurrentUserHasPermission } from './user-permission-hooks'

/**
 * The returned list contains all bookmark lists of all types ordered by the
 * highest number of updated assets. If two or more items have the same asset
 * update count they will be ordered alphabetically.
 *
 * After the first retrieval the list order is stored and maintained until the
 * user refreshes the page. This prevents unexpected reordering when the
 * updated asset count is reset to 0.
 */
export function useSortedBookmarkLists(): BookmarkListItem[] {
  const bookmarkLists = useBookmarkLists() // Don't apply filters here to utilize the cache
  const prev = useRef<BookmarkListItem[]>([])

  return useMemo(() => {
    if (bookmarkLists.loading) {
      return []
    }

    const previousOrder = prev.current.map(({ id }) => id)

    let result = [...bookmarkLists.data.items.filter(isLegacyList)]
    if (previousOrder.length > 0) {
      // If we had a previous order maintain said order until the user refreshes the page
      result = result.sort((a, b) => {
        return previousOrder.indexOf(a.id) - previousOrder.indexOf(b.id)
      })
    } else {
      // Order by updatedCount DESC, title ASC
      result = result.sort((a, b) => {
        if (a.updatedCount && b.updatedCount) {
          return compareNumberDesc(a.updatedCount, b.updatedCount)
        } else if (a.updatedCount) {
          return -1
        } else if (b.updatedCount) {
          return 1
        }

        return a.title.localeCompare(b.title)
      })
    }

    prev.current = result

    return prev.current
  }, [bookmarkLists.data.items, bookmarkLists.loading])
}

/**
 * Due to list sharing, it is possible that a list may be removed or the user's
 * access to the list is revoked while they are modifying it. In such a scenario,
 * this shows a dialog box and redirects the user to the lists page if they are
 * currently viewing the affected list.
 *
 * Returns true if the error was handled, otherwise false.
 */
export function useCheckBookmarkListError() {
  const openDialog = useOpenDialog()
  const history = useHistory()

  return useCallback(
    (err: unknown, fallback: AlertDialogContextProps = changesNotSavedDialog) => {
      // Return false if we don't recognize the error so that the caller
      // can handle the error in a different way.
      if (
        !(err instanceof RpcError) ||
        ![RpcErrorCode.Unauthorized, RpcErrorCode.InvalidResourceIdentifier].includes(err.code)
      ) {
        if (fallback) {
          openDialog(fallback)
        }
        return false
      }

      openDialog({
        title: 'List not available',
        message:
          'The list you are trying to access isn’t available to you. The list might be deleted or someone might have revoked your access.',
        buttonText: 'Ok',
      })

      // Redirect to the home page if we're currently viewing a list path that no longer exists
      if (
        matchPath(window.location.pathname, { path: BOOKMARKS_LIST_PATH }) ||
        matchPath(window.location.pathname, { path: BOOKMARKS_FILTERED_PATH })
      ) {
        history.push(generatePath(HOME_PATH))
      }

      return true
    },
    [history, openDialog]
  )
}

/**
 * The returned callback should be called after deleting or leaving a bookmarks page.
 *
 * On mobile this redirects the user to the "My lists"-page.
 *
 * On desktop, it redirects to the bookmarks list on the top. If no bookmark lists remain
 * after deleting or leaving all lists it redirects to the homepage instead.
 */
export function useNavigateToNextBookmarkList() {
  const history = useHistory()
  const xs = isXs()
  const bookmarkLists = useSortedBookmarkLists()

  return useCallback(
    (deletedList: BookmarkListItem) => {
      // Navigate away from the list page being deleted only if it is currently being viewed.
      const currentPath = isLegacyCustomAssetList(deletedList)
        ? BOOKMARKS_LIST_PATH
        : BOOKMARKS_FILTERED_PATH
      const match = matchPath<BookmarksPathParams>(window.location.pathname, {
        path: currentPath,
        strict: false,
      })
      if (!match || match.params['listId'] !== `${deletedList.id}`) {
        return
      }

      // Navigate to "Bookmarks"-page on mobile
      if (xs) {
        history.push(generatePath(BOOKMARKS_PATH))
        return
      }

      // Navigate to top list on desktop, or the homepage if none are left
      const next = bookmarkLists?.find((list) => list.id !== deletedList.id)
      if (next) {
        const nextPath = isLegacyCustomAssetList(next)
          ? BOOKMARKS_LIST_PATH
          : BOOKMARKS_FILTERED_PATH
        history.push(generatePath(nextPath, { listId: next.id }))
      } else {
        history.push(generatePath(HOME_PATH))
      }
    },
    [history, bookmarkLists, xs]
  )
}

/**
 * This type guard checks whether a given list is a legacy static asset
 * bookmarks list.
 *
 * TODO: replaced by the new hybrid lists, hence the "legacy" in its name.
 */
export function isLegacyCustomAssetList(list?: BookmarkListItem) {
  if (!list) {
    return false
  }

  return list.type === BookmarkListType.LegacyCustomAssetList
}

/**
 * This type guard checks whether a given list is an asset or a filtered legacy
 * bookmarks list.
 *
 * TODO: replaced by the new hybrid lists, hence the "legacy" in its name.
 */
export function isLegacyList(list?: BookmarkListItem) {
  if (!list) {
    return false
  }

  return (
    list.type === BookmarkListType.LegacyCustomAssetList ||
    list.type === BookmarkListType.LegacyCustomAssetQueryList
  )
}

/**
 * The BookmarkAssetListItem changes the filters to be compatible with an asset
 * list item. This is necessary because the backend does not know the type of
 * the filters returned and provides no type information for them.
 */
export interface BookmarkAssetListItem extends Omit<BookmarkListItem, 'filters'> {
  filters: ListFilter<AssetListItem>[]
}

/**
 * This type guard checks whether a given list is an asset-list. Currently, this
 * should always be true, but this will change when we add investor bookmarks.
 */
export function isAssetBookmarkList(list?: BookmarkListItem): list is BookmarkAssetListItem {
  return list !== undefined && isAssetBookmarkListType(list.type)
}

/**
 * This type guard checks whether a given list is an asset-list. Currently, this
 * should always be true, but this will change when we add investor bookmarks.
 */
export function isAssetBookmarkListType(type: BookmarkListType): boolean {
  return (
    type === BookmarkListType.LegacyCustomAssetList ||
    type === BookmarkListType.LegacyCustomAssetQueryList ||
    type === BookmarkListType.RecentAssetsFilter ||
    type === BookmarkListType.Assets
  )
}

/**
 * Returns all bookmark lists that are of type `BookmarkListType.RecentAssetsFilter`.
 */
export function useRecentlyFilteredBookmarkLists() {
  const bookmarkLists = useBookmarkLists() // Don't apply filters here to utilize the cache

  const items = [
    ...bookmarkLists.data.items.filter(({ type }) => type === BookmarkListType.RecentAssetsFilter),
  ] as BookmarkAssetListItem[]
  items.sort((a, b) => compareDesc(a.createdAt, b.createdAt))

  return {
    ...bookmarkLists,
    data: { ...bookmarkLists.data, items },
  }
}

export function useCreateBookmarkList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarkList()
  const openDialog = useOpenDialog()
  const userCurrency = useUserCurrency()

  return useCallback(
    async (
      title: string,
      type: BookmarkListType,
      filters: ListFilter<AssetListItem>[],
      objectIds: number[] = []
    ): Promise<BookmarkListItem | null> => {
      try {
        const result = await fetcher({
          method: 'lists.createBookmarkList',
          params: {
            title,
            type,
            filters,
            objectIds,

            // What the user sees in their filters is a conversion from EUR to
            // their user currency. When the exchange rate is updated in the
            // backend we use the currency and historical exchange rate to keep
            // the filters the same.
            currency: userCurrency.name,
            exchangeRate: userCurrency.toEur,
          },
        })

        // Add list to SWR cache
        await mutateList(result.id, () => result)

        return result
      } catch (err) {
        // If the list was generated from a recent filter operation showing an error
        // would just confuse the user as he didn't explicitly created the list.
        if (type !== BookmarkListType.RecentAssetsFilter) {
          console.error('error creating bookmark list', err) // eslint-disable-line no-console
          openDialog({
            title: 'Create list failed',
            message: 'Something went wrong while creating the list. Please try again.',
            buttonText: 'Ok',
          })
        }
        return null
      }
    },
    [fetcher, mutateList, openDialog, userCurrency]
  )
}

export function useUpdateBookmarkList() {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarkList()
  const checkListError = useCheckBookmarkListError()

  return useCallback(
    async (params: UpdateBookmarkListParams): Promise<BookmarkList | null> => {
      try {
        const result = await fetcher({
          method: 'lists.updateBookmarkList',
          params: params,
        })

        // Update list in cache
        await mutateList(params.id, () => result)

        return result
      } catch (err) {
        checkListError(err)
        return null
      }
    },
    [checkListError, fetcher, mutateList]
  )
}

/**
 * Returns bookmark lists the current user has access to that contain the given object.
 * If no objectId is provided, no request is made and an empty array is returned.
 */
export function useBookmarkListsByObject(
  objectType: BookmarkListType,
  objectId?: number
): BookmarkList[] {
  const swrBookmarks = useGetBookmarkListsByObject(objectId ? { objectType, objectId } : undefined)

  if (swrBookmarks.loading || !swrBookmarks.data) {
    return []
  }

  return swrBookmarks.data ?? []
}

/**
 * Returns whether the current user has the Owner role for the given bookmark list.
 */
export function useIsBookmarkListOwner(listId?: number): boolean {
  return useCurrentUserHasPermission(
    UserPermissionObjectType.BookmarkList,
    listId,
    UserPermissionRole.Owner
  )
}

type MutatorCallback<T> = (item: T | undefined) => T | undefined

/**
 * Updates a bookmark list in the SWR cache, ensuring it updates immediately
 * without waiting for the next revalidation.
 */
export function useMutateBookmarkList() {
  const fetcher = useRpcClient()

  return useCallback(
    async (listId: number, mutator?: MutatorCallback<BookmarkListItem>) => {
      // Fetch the updated list from the backend if no mutator is provided
      let updatedList: BookmarkListItem | undefined
      if (!mutator) {
        updatedList = await fetcher({
          method: 'lists.getBookmarkList',
          params: { id: listId },
        })
      }

      // Return a promise that waits for all mutations to complete.
      return Promise.all([
        mutateListBookmarkLists(listId, mutator, updatedList),
        mutateGetBookmarkList(listId, mutator, updatedList),
      ])
    },
    [fetcher]
  )
}

/**
 * Mutation for updating the lists bookmark lists request. It loops through all
 * caches and executes the mutator if available, or uses the updatedList if
 * provided.
 */
function mutateListBookmarkLists(
  listId: number,
  mutator?: MutatorCallback<BookmarkListItem>,
  updatedList?: BookmarkListItem
) {
  return mutate(
    (key: AppSwrKey<'lists.listBookmarkLists'>) => {
      return key.method === 'lists.listBookmarkLists'
    },
    (lists: UseAppListSwrResult<List<BookmarkListItem, BookmarkListItem>>['data'] | undefined) => {
      if (!lists) {
        return lists
      }
      let items = [...lists.items]
      const index = items.findIndex((item) => item.id === listId)

      const newValue = updatedList ?? mutator?.(items[index])
      if (!newValue) {
        // List was removed
        items.splice(index, 1)
      } else if (index >= 0) {
        // List was updated
        items[index] = newValue
      } else {
        // List was added
        items = [newValue, ...lists.items]
      }

      return { ...lists, items }
    },
    { revalidate: false }
  )
}

/**
 * Mutation for updating the individual list query by finding any cache entry
 * for 'lists.getBookmarkList' that has the matching listId.
 */
function mutateGetBookmarkList(
  listId: number,
  mutator?: MutatorCallback<BookmarkListItem>,
  updatedList?: BookmarkListItem
) {
  return mutate(
    (key: AppSwrKey<'lists.getBookmarkList'>) => {
      return key.method === 'lists.getBookmarkList' && key.params?.id === listId
    },
    (listItem: BookmarkListItem | undefined) => {
      return mutator ? mutator(listItem) : updatedList
    },
    { revalidate: false }
  )
}
