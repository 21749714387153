import { validateUrl } from '@gain/utils/common'
import { formatMonthYear } from '@gain/utils/date'
import { Source } from '@gain/utils/source'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled, useTheme } from '@mui/material/styles'
import clsx from 'clsx'
import { useMemo } from 'react'

import { PROFILE_TAB_BAR_STICKY_HEIGHT } from '../../../common/profile-tab-bar'
import { NAV_BAR_HEIGHT } from '../../../layout/nav-bar'

export const listSourcesTableClasses = generateUtilityClasses('ListSourcesTable', [
  'table',
  'section',
  'head',
  'body',
  'row',
  'cell',
  'dateCell',
  'titleCell',
  'sourceCell',
])

const StyledRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
})

const StyledCell = styled('div')(({ theme }) => ({
  textAlign: 'left',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  '&:first-child': {
    paddingLeft: theme.spacing(3),
  },
  '&:last-child': {
    paddingRight: theme.spacing(3),
  },
  minHeight: 40,
  boxSizing: 'border-box',
  [`&.${listSourcesTableClasses.dateCell}`]: {
    minWidth: 104,
    width: 104,
    color: theme.palette.text.secondary,
  },
  [`&.${listSourcesTableClasses.titleCell}`]: {
    flex: 1,
  },
  [`&.${listSourcesTableClasses.sourceCell}`]: {
    minWidth: 232,
    width: 232,
    color: theme.palette.text.secondary,
  },
}))

const StyledHeadSection = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: NAV_BAR_HEIGHT + PROFILE_TAB_BAR_STICKY_HEIGHT,
  backgroundColor: 'inherit',
  [`& .${listSourcesTableClasses.cell}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.secondary,
  },
}))

const StyledBodySection = styled('div')(({ theme }) => ({
  [`& .${listSourcesTableClasses.titleCell}`]: {
    color: theme.palette.text.primary,
  },
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(),
}))

const StyledBodyCell = styled(StyledCell)({
  verticalAlign: 'top',
})

const StyledBodyRow = styled('a')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  textDecoration: 'none',
  color: theme.palette.text.primary,
  '&[href]': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey['50'],
      [`& .${listSourcesTableClasses.titleCell}`]: {
        textDecoration: 'underline',
      },
    },
  },
}))

export interface ListSourcesTableProps {
  sources: Source[]
  className?: string
  backgroundColor?: 'grey' | 'paper'
}

export default function ListSourcesTable({
  sources,
  className,
  backgroundColor = 'paper',
}: ListSourcesTableProps) {
  const theme = useTheme()

  const color = useMemo(() => {
    switch (backgroundColor) {
      case 'grey':
        return theme.palette.grey['100']
      case 'paper':
        return theme.palette.background.paper
    }
  }, [theme, backgroundColor])

  return (
    <div className={clsx(className, listSourcesTableClasses.table)}>
      <StyledHeadSection
        className={clsx(listSourcesTableClasses.section, listSourcesTableClasses.head)}
        sx={{ backgroundColor: color }}>
        <StyledRow className={listSourcesTableClasses.row}>
          <StyledCell
            className={clsx(listSourcesTableClasses.cell, listSourcesTableClasses.dateCell)}>
            Date
          </StyledCell>
          <StyledCell
            className={clsx(listSourcesTableClasses.cell, listSourcesTableClasses.titleCell)}>
            Title
          </StyledCell>
          <StyledCell
            className={clsx(listSourcesTableClasses.cell, listSourcesTableClasses.sourceCell)}>
            Source
          </StyledCell>
        </StyledRow>
      </StyledHeadSection>
      <StyledBodySection
        className={clsx(listSourcesTableClasses.section, listSourcesTableClasses.body)}>
        {sources.map((source, index) => (
          <StyledBodyRow
            key={index}
            className={listSourcesTableClasses.row}
            href={validateUrl(source.url)}
            rel={'noopener noreferrer'}
            target={'_blank'}>
            <StyledBodyCell
              className={clsx(listSourcesTableClasses.cell, listSourcesTableClasses.dateCell)}>
              {formatMonthYear(source.publicationMonth, source.publicationYear)}
            </StyledBodyCell>
            <StyledBodyCell
              className={clsx(listSourcesTableClasses.cell, listSourcesTableClasses.titleCell)}>
              {source.title}
            </StyledBodyCell>
            <StyledBodyCell
              className={clsx(listSourcesTableClasses.cell, listSourcesTableClasses.sourceCell)}>
              {source['publisher'] || '-'}
            </StyledBodyCell>
          </StyledBodyRow>
        ))}
      </StyledBodySection>
    </div>
  )
}
