import { useIsXs } from '@gain/utils/responsive'

import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import HomeMobile from './home-mobile'
import HomePage from './home-page'

export default function HomePageContainer() {
  const isXs = useIsXs()
  useTrackPageView('home')

  if (isXs) {
    return <HomeMobile />
  }

  return <HomePage />
}
