import Head from '@gain/modules/head'
import Hidden from '@mui/material/Hidden'
import { styled } from '@mui/material/styles'
import { Suspense } from 'react'
import { SWRConfig } from 'swr'

import Spacer from '../../common/spacer'
import BeamerFeaturePromo from './beamer-feature-promo'
import FeedLimit from './feed-limit'
import HomePageWelcome from './home-page-welcome'
import IndustryList from './industry-list/industry-list'
import IndustryListSkeleton from './industry-list/industry-list-skeleton'
import NewsFeed from './news-feed/news-feed'
import NewsFeedSkeleton from './news-feed/news-feed-skeleton'

const StyledRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  minHeight: '100%',
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(4, 6),
  },
  [theme.breakpoints.only('md')]: {
    padding: theme.spacing(4, 5),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 3),
  },
}))

const StyledContainer = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100%',
  [theme.breakpoints.up('lg')]: {
    maxWidth: 920,
    display: 'grid',
    gridTemplateColumns: '624px 240px',
    columnGap: theme.spacing(7),
    gridTemplateRows: 'min-content 1fr',
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    maxWidth: 624,
  },
}))

const StyledSidebar = styled('div')(({ theme }) => ({
  '& > *:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

export default function HomePage() {
  return (
    <SWRConfig value={{ suspense: true }}>
      <Head>
        <title>Home</title>
      </Head>
      <StyledRoot>
        <StyledContainer>
          <div>
            <HomePageWelcome />
            <FeedLimit />
          </div>
          <div />
          <div>
            <Hidden
              lgUp
              smDown>
              <Suspense fallback={<IndustryListSkeleton nrOfIndustries={3} />}>
                <IndustryList nrOfIndustries={3} />
              </Suspense>
            </Hidden>
            <Spacer
              direction={'vertical'}
              xs={2}
            />
            <Suspense fallback={<NewsFeedSkeleton />}>
              <NewsFeed />
            </Suspense>
          </div>
          <Hidden lgDown>
            <StyledSidebar>
              <BeamerFeaturePromo />

              <Suspense fallback={<IndustryListSkeleton nrOfIndustries={4} />}>
                <IndustryList nrOfIndustries={4} />
              </Suspense>
            </StyledSidebar>
          </Hidden>
        </StyledContainer>
      </StyledRoot>
    </SWRConfig>
  )
}
