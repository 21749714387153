import { CreditReferenceRate, CreditSubtype, CreditType } from './lib/rpc-shared-model'
import { getOption, Option, OptionGroup } from './option'

export const CREDIT_TYPE_OPTIONS: ReadonlyArray<Option<CreditType>> = [
  {
    label: 'Senior debt',
    value: CreditType.SeniorDebt,
  },
  {
    label: 'Subordinated debt',
    value: CreditType.SubordinatedDebt,
  },
  {
    label: 'Revolving credit',
    value: CreditType.RevolvingCredit,
  },
  {
    label: 'Hybrid financing',
    value: CreditType.HybridFinancing,
  },
  {
    label: 'Purpose-specific financing',
    value: CreditType.PurposeSpecificFinancing,
  },
  {
    label: 'Sustainability-linked financing',
    value: CreditType.SustainabilityLinkedFinancing,
  },
  {
    label: 'Corporate bond',
    value: CreditType.CorporateBond,
  },
  {
    label: 'Equity',
    value: CreditType.Equity,
  },
  {
    label: 'Unknown',
    value: CreditType.Unknown,
  },
]

export const CREDIT_SUBTYPE_OPTIONS: ReadonlyArray<Option<CreditSubtype>> = [
  // Senior debt
  {
    label: 'First-lien',
    value: CreditSubtype.FirstLien,
  },
  {
    label: 'Second-lien',
    value: CreditSubtype.SecondLien,
  },
  {
    label: 'TLA',
    value: CreditSubtype.Tla,
  },
  {
    label: 'TLB',
    value: CreditSubtype.Tlb,
  },

  // Subordinated debt
  {
    label: 'Subordinated loan',
    value: CreditSubtype.SubordinatedLoan,
  },
  {
    label: 'Mezzanine',
    value: CreditSubtype.Mezzanine,
  },
  {
    label: 'Payment-in-kind',
    value: CreditSubtype.PaymentInKind,
  },

  // Hybrid financing
  {
    label: 'Unitranche debt',
    value: CreditSubtype.UnitrancheDebt,
  },
  {
    label: 'Convertible debt',
    value: CreditSubtype.ConvertibleDebt,
  },
  {
    label: 'Structured debt',
    value: CreditSubtype.StructuredDebt,
  },

  // Purpose-specific financing
  {
    label: 'Bridge loan',
    value: CreditSubtype.BridgeLoan,
  },
  {
    label: 'Debenture',
    value: CreditSubtype.Debenture,
  },

  // Equity
  {
    label: 'Common stock',
    value: CreditSubtype.CommonStock,
  },
  {
    label: 'Preferred stock',
    value: CreditSubtype.PreferredStock,
  },
  {
    label: 'Warrants',
    value: CreditSubtype.Warrants,
  },
]

export const CREDIT_SUB_TYPE_LOOKUP: Record<CreditType, CreditSubtype[]> = {
  [CreditType.SeniorDebt]: [
    CreditSubtype.FirstLien,
    CreditSubtype.SecondLien,
    CreditSubtype.Tla,
    CreditSubtype.Tlb,
  ],
  [CreditType.SubordinatedDebt]: [
    CreditSubtype.SubordinatedLoan,
    CreditSubtype.Mezzanine,
    CreditSubtype.PaymentInKind,
  ],
  [CreditType.RevolvingCredit]: [],
  [CreditType.HybridFinancing]: [
    CreditSubtype.UnitrancheDebt,
    CreditSubtype.ConvertibleDebt,
    CreditSubtype.StructuredDebt,
  ],
  [CreditType.PurposeSpecificFinancing]: [CreditSubtype.BridgeLoan, CreditSubtype.Debenture],
  [CreditType.SustainabilityLinkedFinancing]: [],
  [CreditType.CorporateBond]: [],
  [CreditType.Equity]: [
    CreditSubtype.CommonStock,
    CreditSubtype.PreferredStock,
    CreditSubtype.Warrants,
  ],
  [CreditType.Unknown]: [],
}

// Create credit type and subtype options tree as used in the credit type filter
const HIDDEN_CREDIT_TYPES = [CreditType.Equity, CreditType.SustainabilityLinkedFinancing]
const HIDDEN_CREDIT_SUBTYPES = [CreditSubtype.PaymentInKind]
const CREDIT_TYPES_WITHOUT_OTHER_OPTION = [CreditType.HybridFinancing]

export const CREDIT_TYPE_AND_SUBTYPE_OPTIONS: ReadonlyArray<Option<string> | OptionGroup<string>> =
  CREDIT_TYPE_OPTIONS.filter((typeOption) => !HIDDEN_CREDIT_TYPES.includes(typeOption.value)).map(
    (typeOption) => {
      const subTypes = CREDIT_SUB_TYPE_LOOKUP[typeOption.value]

      if (subTypes.length === 0) {
        return typeOption
      }

      const subTypeOptions: Array<Option<string>> = subTypes
        .filter((subType) => !HIDDEN_CREDIT_SUBTYPES.includes(subType))
        .map((subType) => {
          const option = getOption(CREDIT_SUBTYPE_OPTIONS, subType)

          // Programmer error
          if (!option) {
            throw new Error(`Could not find option for subtype ${subType}`)
          }

          return {
            label: option.label,
            value: `${typeOption.value}/${option.value}`,
          }
        })

      if (!CREDIT_TYPES_WITHOUT_OTHER_OPTION.includes(typeOption.value)) {
        subTypeOptions.push({
          label: `Unspecified ${typeOption.label.toLowerCase()}`,
          value: typeOption.value,
        })
      }

      return {
        label: typeOption.label,
        options: subTypeOptions,
      }
    }
  )

// Lookup map to find the label for a credit typeAndSubtype value
export const FLAT_CREDIT_TYPE_AND_SUBTYPE_OPTIONS = CREDIT_TYPE_AND_SUBTYPE_OPTIONS.reduce(
  (acc, current) => {
    if ('options' in current) {
      return acc.concat(
        current.options.map((option) => ({
          label: option.label,
          value: option.value,
        }))
      )
    }

    return acc
  },
  [] as ReadonlyArray<Option<string>>
)

export const CREDIT_REFERENCE_RATE_OPTIONS: ReadonlyArray<Option<CreditReferenceRate>> = [
  {
    label: 'LIBOR',
    value: CreditReferenceRate.Libor,
  },
  {
    label: 'SOFR',
    value: CreditReferenceRate.Sofr,
  },
  {
    label: 'Prime rate',
    value: CreditReferenceRate.Prime,
  },
  {
    label: 'Federal funds rate',
    value: CreditReferenceRate.FederalFunds,
  },
  {
    label: 'EURIBOR',
    value: CreditReferenceRate.Euribor,
  },
  {
    label: 'SONIA',
    value: CreditReferenceRate.Sonia,
  },
  {
    label: 'STIBOR',
    value: CreditReferenceRate.Stibor,
  },
  {
    label: 'NIBOR',
    value: CreditReferenceRate.Nibor,
  },
  {
    label: 'CDOR',
    value: CreditReferenceRate.Cdor,
  },
  {
    label: 'CORRA',
    value: CreditReferenceRate.Corra,
  },
  {
    label: 'BBSY',
    value: CreditReferenceRate.Bbsy,
  },
  {
    label: 'TIBOR',
    value: CreditReferenceRate.Tibor,
  },
  {
    label: 'HIBOR',
    value: CreditReferenceRate.Hibor,
  },
  {
    label: 'SIBOR',
    value: CreditReferenceRate.Sibor,
  },
  {
    label: 'BKBM',
    value: CreditReferenceRate.Bkbm,
  },
  {
    label: 'PRIBOR',
    value: CreditReferenceRate.Pribor,
  },
  {
    label: 'BUBOR',
    value: CreditReferenceRate.Bubor,
  },
  {
    label: 'KLIBOR',
    value: CreditReferenceRate.Klibor,
  },
  {
    label: 'PHIREF',
    value: CreditReferenceRate.Phiref,
  },
  {
    label: 'TAIBOR',
    value: CreditReferenceRate.Taibor,
  },
  {
    label: 'BSBY',
    value: CreditReferenceRate.Bsby,
  },
]
