import { formatPercentage, FormatPercentageOptions } from './format-percentage'

/**
 * Formats a range of percentages based on the provided minimum and maximum values.
 * If both minimum and maximum values are provided and formatted, the result is a range (e.g., "30%-40%").
 * If only one value is present, the result includes an appropriate inequality symbol (e.g., "≥30%" or "≤40%").
 * If neither value is provided, the result is null.
 */
export function formatPercentageRange(min: number | null, max: number | null): string | null {
  const options: FormatPercentageOptions = { round: 'auto', suffix: '', emptyValue: '' }

  const minFormatted = formatPercentage(min, options)
  const maxFormatted = formatPercentage(max, options)

  if (minFormatted && maxFormatted) {
    // "30%"
    if (minFormatted === maxFormatted) {
      return `${minFormatted}%`
    }
    // "30-40%"
    return `${minFormatted}-${maxFormatted}%`
  }

  if (minFormatted) {
    // "≤ 30%"
    return `≥ ${minFormatted}%`
  }

  if (maxFormatted) {
    // "≤ 40%"
    return `≤ ${maxFormatted}%`
  }

  return null
}
