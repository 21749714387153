import { RotateCcwIcon } from '@gain/components/icons'
import { useUserProfileContext } from '@gain/modules/user'
import { isGainProUser } from '@gain/utils/user'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { useState } from 'react'

import { useSortedBookmarkLists } from '../../api/bookmark-list-hooks'
import { useUpdateBookmarkListLastViewedAt } from '../../api/bookmark-list-last-viewed-hooks'
import DialogHeader from '../../common/dialog-header'

export function ResetBadgeMenuItem() {
  const [open, setOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [selectedDate, handleDateChange] = useState(new Date())
  const profile = useUserProfileContext()
  const setBookmarkListLastViewedAt = useUpdateBookmarkListLastViewedAt()

  const bookmarkLists = useSortedBookmarkLists()

  const handleSave = async () => {
    setIsSaving(true)
    const results = await Promise.all(
      bookmarkLists.map(({ id }) => setBookmarkListLastViewedAt(id, selectedDate))
    )
    if (results.some((result) => !result)) {
      setIsSaving(false)
    } else {
      window.location.reload() // No need to set saving to false
    }
  }

  if (!isGainProUser(profile)) {
    return null
  }

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>
        <ListItemIcon>
          <RotateCcwIcon />
        </ListItemIcon>
        <ListItemText>Reset badges</ListItemText>
      </MenuItem>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}>
        <DialogHeader
          onCloseClick={() => setOpen(false)}
          title={'Pick a date'}
        />
        <DialogContent>
          <DateTimePicker
            ampm={false}
            onChange={(e) => handleDateChange(e as Date)}
            value={selectedDate}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color={'primary'}
            loading={isSaving}
            onClick={handleSave}
            variant={'contained'}
            fullWidth>
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
