import Tooltip from '@gain/components/tooltip'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import { ReactNode } from 'react'

const StyledMenuItem = styled(MenuItem)<{ isRed?: boolean }>(({ theme, isRed }) => ({
  gap: theme.spacing(1.75),

  ...(isRed && {
    color: theme.palette.error.main,
    '&:hover, &:focus': {
      backgroundColor: alpha(theme.palette.error.main, 0.1),
    },
  }),
}))

export enum ShareMenuButtonState {
  None = 'none',
  Disabled = 'disabled',
  Enabled = 'enabled',
}

interface ShareMenuButtonProps {
  children: ReactNode
  disabledTooltip?: string
  state: ShareMenuButtonState
  isRed?: boolean
  onClick: () => void
}

export default function ShareMenuButton({
  children,
  disabledTooltip,
  state,
  isRed,
  onClick,
}: ShareMenuButtonProps) {
  switch (state) {
    case ShareMenuButtonState.None:
      return null
    case ShareMenuButtonState.Enabled:
      return (
        <StyledMenuItem
          isRed={isRed}
          onClick={onClick}>
          {children}
        </StyledMenuItem>
      )
    case ShareMenuButtonState.Disabled:
      return (
        <Tooltip
          disabled={!disabledTooltip}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            },
          }}
          title={disabledTooltip}>
          <span>
            <StyledMenuItem
              isRed={isRed}
              disabled>
              {children}
            </StyledMenuItem>
          </span>
        </Tooltip>
      )
    default:
      throw new Error(`Unknown state: ${state}`)
  }
}
