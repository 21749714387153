import { CompanyIcon, DebtIcon } from '@gain/components/icons'
import { CreditListItem } from '@gain/rpc/app-model'

import { dropdownMenuGroup, DropdownMenuOptions } from '../../../common/dropdown-menu'
import {
  asset,
  createFilterMap,
  creditType,
  FilterModel,
  filterValueGroup,
  filterValueItem,
  investor,
  lender,
  rangeCurrency,
  rangeDate,
  rangeNumber,
  region,
  sector,
  tag,
} from '../../filter/filter-bar'
import { FilterConfig } from '../../filter/filter-bar/filter-config/filter-config-model'

const CREDIT_FILTER_FIELDS = [
  'typeAndSubtype',
  'debtQuantumEur',
  'issuedAt',
  'maturedAt',
  'couponBps',
  'assetTagIds',
  'assetHqCountryCode',
  'assetSubsector',
  'lenderIds',
  'assetId',
  'dealBuyerInvestorIds',
] as const

export type CreditFilterField = (typeof CREDIT_FILTER_FIELDS)[number]

/**
 * All possible filters available to the credit list.
 */
export const CREDIT_FILTER_MAP = createFilterMap<CreditListItem, typeof CREDIT_FILTER_FIELDS>(
  creditType('typeAndSubtype', 'Credit type'),
  rangeCurrency('debtQuantumEur', 'Debt quantum'),
  rangeDate('issuedAt', 'Issuance date'),
  rangeDate('maturedAt', 'Maturity date'),
  rangeNumber('couponBps', 'Coupon', {
    suffix: 'bps',
  }),
  tag('assetTagIds', 'Tags'),
  region('assetHqCountryCode', 'Region'),
  sector('assetSubsector', 'Sector'),
  lender('lenderIds', 'Lender'),
  asset('assetId', 'Company'),
  investor('dealBuyerInvestorIds', 'Investor')
)

/**
 * The filters that are displayed by default in the filter bar.
 */
export const CREDIT_DEFAULT_FILTERS: FilterModel<CreditListItem, CreditFilterField> = [
  filterValueGroup(filterValueItem('typeAndSubtype')),
  filterValueGroup(filterValueItem('debtQuantumEur')),
  filterValueGroup(filterValueItem('maturedAt')),
  filterValueGroup(filterValueItem('couponBps')),
  filterValueGroup(filterValueItem('assetTagIds')),
  filterValueGroup(filterValueItem('assetHqCountryCode')),
  filterValueGroup(filterValueItem('assetSubsector')),
]

/**
 * The list of filters that shown in the "+ Add filter" dropdown.
 */
export const CREDIT_FILTER_MENU: DropdownMenuOptions<
  FilterConfig<CreditListItem, CreditFilterField>
> = [
  dropdownMenuGroup('creditDetails', 'Credit details', {
    icon: DebtIcon,
    children: [
      CREDIT_FILTER_MAP.typeAndSubtype,
      CREDIT_FILTER_MAP.debtQuantumEur,
      CREDIT_FILTER_MAP.couponBps,
      CREDIT_FILTER_MAP.issuedAt,
      CREDIT_FILTER_MAP.maturedAt,
    ],
  }),
  dropdownMenuGroup('company', 'Company', {
    icon: CompanyIcon,
    children: [
      CREDIT_FILTER_MAP.assetId,
      CREDIT_FILTER_MAP.assetSubsector,
      CREDIT_FILTER_MAP.assetHqCountryCode,
      CREDIT_FILTER_MAP.assetTagIds,
    ],
  }),
  CREDIT_FILTER_MAP.lenderIds,
  CREDIT_FILTER_MAP.dealBuyerInvestorIds,
]
