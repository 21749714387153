import { TableRowHoverContext } from '@gain/utils/table'
import { OverridableComponent, OverrideProps } from '@mui/material/OverridableComponent'
import { keyframes, styled, Theme } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import { TableRowClasses } from '@mui/material/TableRow/tableRowClasses'
import { SxProps } from '@mui/system'
import { ElementType, memo, MouseEvent, ReactNode, useCallback, useState } from 'react'

const enter = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  left: 0,
  right: 0,
  textDecoration: 'none',
  animation: `${enter} ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeIn}`,
}))

// eslint-disable-next-line @typescript-eslint/ban-types
export interface VirtualTableBodyRowTypeMap<P = {}, D extends ElementType = 'div'> {
  props: P & {
    children?: ReactNode
    classes?: Partial<TableRowClasses>
    hover?: boolean
    selected?: boolean
    sx?: SxProps<Theme>
    width: number
    top: number
  }
  defaultComponent: D
}

export type VirtualTableBodyRowProps<
  D extends ElementType = VirtualTableBodyRowTypeMap['defaultComponent'],
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
> = OverrideProps<VirtualTableBodyRowTypeMap<P, D>, D>

type TVirtualTableBodyRow = OverridableComponent<VirtualTableBodyRowTypeMap>

function BaseVirtualTableBodyRow({
  width,
  top,
  children,
  style = {},
  component = 'div',
  onMouseEnter = () => void 0,
  onMouseLeave = () => void 0,
  ...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  const [hover, setHover] = useState(false)

  const handleMouseEnter = useCallback(
    (event: MouseEvent<HTMLElement, MouseEvent>) => {
      setHover(true)
      onMouseEnter(event)
    },
    [onMouseEnter, setHover]
  )

  const handleMouseLeave = useCallback(
    (event: MouseEvent<HTMLElement, MouseEvent>) => {
      setHover(false)
      onMouseLeave(event)
    },
    [onMouseLeave, setHover]
  )

  return (
    <TableRowHoverContext.Provider value={hover}>
      <StyledTableRow
        component={component}
        style={{
          ...style,
          top,
        }}
        {...props}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {children}
      </StyledTableRow>
    </TableRowHoverContext.Provider>
  )
}

export const VirtualTableBodyRow = memo(BaseVirtualTableBodyRow) as TVirtualTableBodyRow
