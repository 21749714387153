import { useListLenderInvestorsInfinite } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { CreditListItem, Lender, LenderInvestorListItem } from '@gain/rpc/app-model'
import { listSort, listSortArray } from '@gain/rpc/utils'
import { formatMonthYear } from '@gain/utils/date'
import Box from '@mui/material/Box'
import { useState } from 'react'

import Card, { CardHeader } from '../../../common/card/card'
import ContentLink from '../../../common/content-link'
import { createVirtualTableColumns, VirtualSort } from '../../../common/virtual-table'
import VirtualTable from '../../../common/virtual-table/virtual-table'
import CompaniesCell from '../../../features/asset/companies-cell'
import {
  autocompleteIncludeFilterValue,
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../features/filter/filter-bar'
import FinancialValue from '../../../features/financial/financial-value'
import InvestorContentLink from '../../../features/investor/investor-content-link'
import { LENDER_MEDIAN_DEBT_QUANTUM_EXPLAINER } from '../../../features/lender/lender-utils'
import { generateLenderPagePath, LENDER_CREDITS_PATH } from '../lender-path'

function createColumns(lender: Lender) {
  return createVirtualTableColumns<LenderInvestorListItem>(
    {
      field: 'investorName',
      headerName: 'Name',
      width: 196,
      flex: 1,
      renderCell: ({ row }) => (
        <InvestorContentLink
          id={row.investorId}
          logoFileUrl={row.investorLogoFileUrl}
          name={row.investorName}
        />
      ),
    },
    {
      headerName: 'Total debt',
      field: 'totalDebtQuantumEur',
      width: 172,
      align: 'right',
      renderCell: ({ value }) => <FinancialValue amount={value} />,
    },
    {
      headerName: 'Companies',
      field: 'assetCount',
      width: 200,
      renderCell: ({ row }) => <CompaniesCell assets={row.linkedAssets} />,
    },
    {
      headerName: 'Credits',
      field: 'creditCount',
      width: 102,
      align: 'right',
      renderCell: ({ row }) => {
        const href = [
          generateLenderPagePath(lender.id, lender.name, LENDER_CREDITS_PATH),
          filterModelToQueryString<CreditListItem, 'dealBuyerInvestorIds'>([
            filterValueGroup(
              filterValueItem(
                'dealBuyerInvestorIds',
                autocompleteIncludeFilterValue([row.investorId])
              )
            ),
          ]),
        ].join('?')

        return (
          <ContentLink
            href={href}
            label={row.creditCount.toString()}
          />
        )
      },
    },
    {
      headerName: 'Median debt quantum',
      field: 'medianDebtQuantumEur',
      width: 196,
      align: 'right',
      headerExplainer: LENDER_MEDIAN_DEBT_QUANTUM_EXPLAINER,
      renderCell: ({ value }) => <FinancialValue amount={value} />,
    },
    {
      headerName: 'Latest deal',
      field: 'latestDealYear',
      width: 120,
      align: 'left',
      sortFields: ['latestDealYear', 'latestDealMonth'],
      valueFormatter: ({ row }) => formatMonthYear(row.latestDealMonth, row.latestDealYear),
    }
  )
}

interface RouteInvestorsProps {
  lender: Lender
}

export default function RouteInvestors({ lender }: RouteInvestorsProps) {
  const [sort, setSort] = useState<VirtualSort<LenderInvestorListItem>[]>(
    listSortArray(listSort('totalDebtQuantumEur', 'desc'), listSort('assetCount', 'desc'))
  )
  const swrLenderInvestorsInfinite = useListLenderInvestorsInfinite({
    sort: sort,
    lenderId: lender.id,
    limit: 50,
  })

  return (
    <>
      <Head>
        <title>{lender.name} - Investors</title>
      </Head>

      <Card sx={{ alignSelf: 'stretch', height: '100%', pb: 0 }}>
        <CardHeader title={'Investors'} />
        <Box sx={{ position: 'relative', height: '100%' }}>
          <Box sx={{ position: 'absolute', inset: 0, display: 'flex' }}>
            <VirtualTable
              columns={createColumns(lender)}
              loadingInitial={swrLenderInvestorsInfinite.isLoadingInitial}
              loadingMore={swrLenderInvestorsInfinite.isLoadingMore}
              onLoadMore={swrLenderInvestorsInfinite.fetchMore}
              onSort={setSort}
              RowComponentProps={{
                hover: false,
              }}
              rows={swrLenderInvestorsInfinite.data?.flatMap((page) => page.items) || []}
              sort={sort}
              totalSize={swrLenderInvestorsInfinite.data?.[0]?.counts.filtered}
              variant={'inline'}
            />
          </Box>
        </Box>
      </Card>
    </>
  )
}
