import { FilterableInvestorFundListItem } from '@gain/rpc/app-model'
import { useMemo } from 'react'

import useListViewColumnVisibilityModel from '../../list-view/use-list-view-column-visibility-model'
import {
  investorFundTableColumnNames,
  investorFundTableColumns,
} from './investor-fund-list-view-columns'

export default function useInvestorFundListViewColumns(
  activeFilterColumns: (keyof FilterableInvestorFundListItem)[]
) {
  const visibleColumns = useListViewColumnVisibilityModel(
    'investorFund',
    investorFundTableColumnNames,
    activeFilterColumns
  )

  return useMemo(() => {
    return investorFundTableColumns.filter(({ field }) => visibleColumns[field])
  }, [visibleColumns])
}
