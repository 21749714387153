import { isTruthy } from '@gain/utils/common'
import { kebabCase } from 'lodash'
import { stringify } from 'query-string'
import { useMemo } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { BooleanParam, encodeQueryParams, useQueryParam } from 'use-query-params'

import { Params } from './path'

interface RouteParams extends Params {
  id: string
  title?: string
}

export interface RouteIndustryParams {
  id: number
  title: string
  preview: boolean
}

export function useIndustryPageParams(): RouteIndustryParams {
  const params = useParams<RouteParams>()
  const [preview] = useQueryParam('preview', BooleanParam)

  return useMemo(() => {
    return {
      id: parseInt(params.id),
      title: params.title ? decodeURIComponent(params.title) : '',
      preview: preview || false,
    }
  }, [params, preview])
}

export const INDUSTRY_PAGE_PATH = '/research/:id/:title?/:preview?'

export interface GenerateIndustryPagePathParams {
  id: number | string
  title?: string | null
  preview?: boolean
}

export function generateIndustryPagePath(
  params: GenerateIndustryPagePathParams,
  path = INDUSTRY_PAGE_PATH
) {
  const generatedPath = generatePath(path, {
    id: params.id,
    title: kebabCase(`${params.title}`) || undefined,
  })

  const queryParams = stringify(
    encodeQueryParams({ preview: BooleanParam }, { preview: params.preview || undefined })
  )

  return [generatedPath, queryParams].filter(isTruthy).join('?')
}

export const INDUSTRY_PAGE_SUMMARY_PATH = `${INDUSTRY_PAGE_PATH}/summary`
export const INDUSTRY_PAGE_SEGMENTS_PATH = `${INDUSTRY_PAGE_PATH}/segments`
export const INDUSTRY_PAGE_ASSESSMENT_PATH = `${INDUSTRY_PAGE_PATH}/assessment`
export const INDUSTRY_PAGE_DEALS_PATH = `${INDUSTRY_PAGE_PATH}/deals`
export const INDUSTRY_PAGE_COMPANIES_PATH = `${INDUSTRY_PAGE_PATH}/companies`
export const INDUSTRY_PAGE_INVESTOR_SUMMARY_PATH = `${INDUSTRY_PAGE_PATH}/investor-summary`
export const INDUSTRY_PAGE_ADVISORS_PATH = `${INDUSTRY_PAGE_PATH}/advisors`
export const INDUSTRY_PAGE_BENCHMARKING_PATH = `${INDUSTRY_PAGE_PATH}/benchmarking`
export const INDUSTRY_PAGE_VALUATION_PATH = `${INDUSTRY_PAGE_PATH}/valuation`
export const INDUSTRY_PAGE_CONFERENCES_PATH = `${INDUSTRY_PAGE_PATH}/conferences`
export const INDUSTRY_PAGE_SOURCES_PATH = `${INDUSTRY_PAGE_PATH}/sources`

export const INDUSTRY_PAGE_ASSETS_PATH = `${INDUSTRY_PAGE_PATH}/assets`
export const INDUSTRY_PAGE_SIMILAR_ASSETS_PATH = `${INDUSTRY_PAGE_PATH}/similar-assets`
