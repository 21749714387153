import { ExternalLinkIcon } from '@gain/components/icons'
import Snackbar from '@gain/components/snackbar'
import Typography from '@gain/components/typography'
import { SalesforceItem, SalesforceStatus } from '@gain/rpc/app-model'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

import SalesforceProgress from './salesforce-progress.component'
import SalesforceResults from './salesforce-result.component'

const SNACKBAR_KEY = 'add-to-salesforce'
const SNACKBAR_KEY_RESULTS = 'add-to-salesforce-results'

export const showSalesforceProgress = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  assetIds: number[]
): SnackbarKey => {
  const assetIdsKey = `${SNACKBAR_KEY}-${assetIds.join('_')}`
  return enqueueSnackbar(undefined, {
    key: assetIdsKey,
    content: () => (
      <SalesforceProgress
        assetIds={assetIds}
        id={assetIdsKey}
        message={''}
      />
    ),
    preventDuplicate: true,
    persist: true,
  })
}

export const showSalesforceResults = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  salesforceItems: SalesforceItem[]
): SnackbarKey => {
  if (salesforceItems.length > 1) {
    return enqueueSnackbar(undefined, {
      key: SNACKBAR_KEY_RESULTS,
      content: () => (
        <SalesforceResults
          salesforceItems={salesforceItems}
          snackbarKey={SNACKBAR_KEY_RESULTS}
        />
      ),
      persist: true,
    })
  }

  const { url, status, assetId } = salesforceItems[0]
  const message = (
    <Stack
      alignItems={'center'}
      direction={'row'}
      display={'flex'}
      gap={1}
      justifyContent={'center'}>
      <Typography
        color={'text.primary'}
        variant={'body2'}
        noWrap>
        {status === SalesforceStatus.Failed
          ? 'Failed to create profile'
          : status === SalesforceStatus.Created
          ? 'Company added'
          : 'Profile updated'}
      </Typography>
      {status !== SalesforceStatus.Failed && (
        <Button
          color={'primary'}
          component={'a'}
          endIcon={<ExternalLinkIcon />}
          href={url}
          rel={'noopener noreferrer'}
          target={'_blank'}
          variant={'text'}>
          Click here to view
        </Button>
      )}
    </Stack>
  )

  return enqueueSnackbar(undefined, {
    key: SNACKBAR_KEY_RESULTS + assetId,
    content: () => (
      <Snackbar
        id={SNACKBAR_KEY_RESULTS + assetId}
        message={message}
        variant={'success'}
      />
    ),
    preventDuplicate: true,
    persist: true,
  })
}
