import { useMemo } from 'react'

import { ECOption } from '../echart'
import { BarChartItem } from './bar-chart-utils'

export default function useBarChartOption(data: BarChartItem[], color: string) {
  return useMemo((): ECOption => {
    return {
      grid: {
        top: 10, // Allow top most y-axis label to fit in
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: true,
      },

      // Define x-axis containing years
      xAxis: [
        {
          type: 'category',
          data: data.map((item) => item.label),
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
      ],

      // Define y-axis containing credit counts per year
      yAxis: [
        {
          type: 'value',
          minInterval: 1,
        },
      ],

      // Define bar chart data series
      series: [
        {
          type: 'bar',
          barWidth: 16,
          data: data.map((item) => {
            return {
              value: item.value,
              itemStyle: {
                color: color,
              },
            }
          }),
        },
      ],
    }
  }, [data, color])
}
