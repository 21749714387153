import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { DetailedHTMLProps, FormHTMLAttributes } from 'react'

// Properly let form scale with available width
const StyledForm = styled('form')({
  display: 'flex',
  alignSelf: 'stretch',
})

const StyledFormStack = styled(Stack)({
  flexGrow: 1,
})

/**
 * FullWidthForm renders a form that grows along with the
 * available flexbox width and initializes a stack for it's
 * contents.
 */
export default function FullWidthForm(
  props: DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
) {
  const { children, ...restProps } = props

  return (
    <StyledForm {...restProps}>
      <StyledFormStack spacing={1}>{children}</StyledFormStack>
    </StyledForm>
  )
}
