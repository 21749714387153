import Typography from '@gain/components/typography'
import { useResizeObserver } from '@gain/utils/dom'
import { useCallback, useRef } from 'react'

interface MeasurableTabPanelProps {
  description: string
  isActive: boolean
  onHeightChange: (value: string | number, height: number) => void
  // The value this tab panel belongs to, used to communicate identity in `onHeightChange`
  value: string | number
}

export default function MeasurableTabPanel({
  value,
  description,
  isActive,
  onHeightChange,
}: MeasurableTabPanelProps) {
  const ref = useRef<HTMLParagraphElement>(null)

  // Listen for any size changes to communicate the latest height
  const handleSizeChange = useCallback(() => {
    if (ref.current) {
      const computedStyle = getComputedStyle(ref.current)
      const marginTop = parseFloat(computedStyle.marginTop) ?? 0
      const marginBottom = parseFloat(computedStyle.marginBottom) ?? 0
      onHeightChange(value, ref.current.scrollHeight + marginTop + marginBottom)
    }
  }, [value, onHeightChange])
  useResizeObserver(ref, handleSizeChange)

  return (
    <Typography
      ref={ref}
      sx={{
        visibility: isActive ? 'visible' : 'hidden',
        position: isActive ? 'static' : 'absolute',
      }}
      variant={'body2'}
      paragraph>
      {description}
    </Typography>
  )
}
