import { IndustryMarketSegmentListItem } from '@gain/rpc/app-model'
import { ratingEsgGroup } from '@gain/utils/investment-criteria'
import { styled } from '@mui/material/styles'

import IndustryEsgAssessmentTableFooter from './industry-esg-table-footer'
import IndustryEsgAssessmentTableItem from './industry-esg-table-item'

const StyledTable = styled('table')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderCollapse: 'collapse',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,
}))

const StyledTableBody = styled('tbody')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

export interface InvestmentCriteriaTableProps {
  segment: IndustryMarketSegmentListItem
}

export default function IndustryEsgAssessmentTable({ segment }: InvestmentCriteriaTableProps) {
  return (
    <StyledTable>
      <StyledTableBody>
        {ratingEsgGroup.ratings.map((rating) => (
          <IndustryEsgAssessmentTableItem
            key={rating.key}
            rating={rating}
            value={segment[rating.key]}
          />
        ))}

        <IndustryEsgAssessmentTableFooter />
      </StyledTableBody>
    </StyledTable>
  )
}
