import { iconButtonClasses } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

import InfoButton from '../info-button'
import { useHorizontalTableContext } from './horizontal-table-hooks'
import HorizontalTableRowCell from './horizontal-table-row-cell'
import type { RowGroupConfig } from './horizontal-table-utils'

interface StyledRowHeaderProps {
  width: number
  height?: number
  paddingX: number
  divider?: boolean
}

const StyledRowHeader = styled('th', {
  shouldForwardProp: (prop) =>
    !['width', 'height', 'paddingX', 'divider', 'sx'].includes(prop.toString()),
})<StyledRowHeaderProps>(({ theme, width, height, paddingX, divider }) => ({
  textAlign: 'left',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  height: height || 22,
  position: 'sticky',
  minWidth: width,
  maxWidth: width,
  width: width,
  left: 0,
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(0, paddingX),
  zIndex: 2,
  ...(divider && {
    borderRight: `1px solid ${theme.palette.divider}`,
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.09)',
    clipPath: 'inset(0px -15px 0px 0px)',
  }),
}))

interface StyledRowProps {
  bold?: boolean
  thickBorders?: boolean
  valueCellHeight?: number
  labelOnlyRow?: boolean
}

const StyledRow = styled('tr', {
  shouldForwardProp: (prop) =>
    !['bold', 'valueCellHeight', 'thickBorders', 'labelOnlyRow'].includes(prop.toString()),
})<StyledRowProps>(({ theme, valueCellHeight = 22, bold, thickBorders, labelOnlyRow }) => ({
  ...(bold && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
  }),

  ...(!bold && {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  }),

  [`& .${iconButtonClasses.root}`]: {
    color: 'inherit',
  },

  '&:first-of-type': {
    '& td, & th': {
      borderTop: `${thickBorders ? 4 : 1}px solid ${theme.palette.divider}`,
      paddingTop: labelOnlyRow ? valueCellHeight / 2 : 0,
    },
  },
}))

export interface HorizontalTableRowProps<Row> extends StyledRowProps {
  getRowId: (row: Row) => number | string
  rows: ReadonlyArray<Row>
  group: RowGroupConfig<Row>
  divider?: boolean
}

export default function HorizontalTableRow<Row>({
  group,
  getRowId,
  rows,
  divider,
  bold,
  ...styledProps
}: HorizontalTableRowProps<Row>) {
  const { labelCellWidth, valueCellHeight, cellPaddingX, leftCellPaddingLeft } =
    useHorizontalTableContext()

  const formatRowValue = (row: Row) => {
    if (!group.valueFn) {
      return null
    }

    const rowValue = group.valueFn(row)
    return (group.formatFn?.(rowValue) ?? rowValue) || '-'
  }

  return (
    <StyledRow
      bold={bold}
      labelOnlyRow={!group.valueFn && !group.renderFn}
      {...styledProps}>
      <StyledRowHeader
        divider={divider}
        height={valueCellHeight}
        paddingX={cellPaddingX}
        sx={{
          pl: leftCellPaddingLeft,
          letterSpacing: group.labelLetterSpacing,
        }}
        width={labelCellWidth}>
        {group.label}

        {group.explainer && (
          <InfoButton
            dialogMessage={group.explainer}
            dialogTitle={group.label}
            sx={{ ml: 0.25, mb: '1px' }}
          />
        )}
      </StyledRowHeader>

      {rows.map((row) => (
        <HorizontalTableRowCell
          key={getRowId(row)}
          bold={bold}
          className={group.cellClassName?.(row)}>
          {group.renderFn?.(row) ?? formatRowValue(row)}
        </HorizontalTableRowCell>
      ))}
    </StyledRow>
  )
}
