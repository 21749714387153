import { CompanyIcon, FileTextIcon, HashIcon, IndustryIcon } from '@gain/components/icons'
import Tooltip from '@gain/components/tooltip'
import { LegalEntity, SearchItemType } from '@gain/rpc/app-model'
import { formatSubsector } from '@gain/utils/sector'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'

import Skeleton from '../../../../../common/skeleton'
import { LatestFinancials } from '../../../../financial/latest-financials'
import { ObjectListItemAvatar, ObjectListItemText } from '../../../../object-list-item'
import SearchTopResultLocation from './search-top-result-location'
import SearchTopResultSideInfo from './search-top-result-side-info'
import { StyledSubTitle } from './search-top-result-skeleton'
import SearchTopResultTags from './search-top-result-tags'
import SearchTopResultTitle from './search-top-result-title'

export default function SearchTopResultLegalEntity({ legalEntity }: { legalEntity?: LegalEntity }) {
  const subsector = formatSubsector(legalEntity?.subsector)

  return (
    <Stack
      direction={'row'}
      gap={4}>
      <Stack flex={1}>
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={2}>
          <ObjectListItemAvatar
            size={'big'}
            type={SearchItemType.Entity}
            variant={'rounded'}>
            <CompanyIcon />
          </ObjectListItemAvatar>

          <ObjectListItemText variant={'top-result'}>
            <SearchTopResultTitle />
            <StyledSubTitle>Legal entity</StyledSubTitle>
          </ObjectListItemText>
        </Stack>

        {legalEntity && <SearchTopResultTags tags={legalEntity.tags.map(({ tag }) => tag)} />}

        {legalEntity && legalEntity.financialLatests && (
          <LatestFinancials
            dataCurrency={legalEntity?.currency}
            ebitda={legalEntity.financialLatests.ebitda}
            ebitdaLines={legalEntity.financialResults.map(({ ebitda, year }) => ({
              value: ebitda || null,
              year,
            }))}
            fte={legalEntity.financialLatests.fte}
            fteLines={legalEntity.financialResults.map(({ fte, year }) => ({
              value: fte || null,
              year,
            }))}
            grossMargin={legalEntity.financialLatests.grossMargin}
            grossMarginLines={legalEntity.financialResults.map(({ grossMargin, year }) => ({
              value: grossMargin || null,
              year,
            }))}
            revenue={legalEntity.financialLatests.revenue}
            revenueLines={legalEntity.financialResults.map(({ revenue, year }) => ({
              value: revenue || null,
              year,
            }))}
            variant={'vertical'}
          />
        )}
      </Stack>

      <SearchTopResultSideInfo>
        <List
          dense
          disablePadding>
          <SearchTopResultLocation />

          {subsector && (
            <ListItem
              disableGutters
              disablePadding>
              <ListItemIcon>
                <Skeleton
                  enabled={!legalEntity}
                  width={20}>
                  <IndustryIcon />
                </Skeleton>
              </ListItemIcon>
              <Skeleton
                enabled={!legalEntity}
                width={150}>
                <ListItemText>
                  <Tooltip
                    title={'Sector'}
                    disableInteractive>
                    <span>{subsector}</span>
                  </Tooltip>
                </ListItemText>
              </Skeleton>
            </ListItem>
          )}

          {(!legalEntity || legalEntity.tags?.length > 0 || legalEntity.financialLatests) && (
            <ListItem
              disableGutters
              disablePadding>
              <ListItemIcon>
                <Skeleton
                  enabled={!legalEntity}
                  width={20}>
                  <FileTextIcon />
                </Skeleton>
              </ListItemIcon>
              <Skeleton
                enabled={!legalEntity}
                width={150}>
                <ListItemText>{legalEntity?.annualReports.length} annual reports</ListItemText>
              </Skeleton>
            </ListItem>
          )}

          {(!legalEntity || legalEntity.tags?.length > 0 || legalEntity.financialLatests) &&
            (!legalEntity || legalEntity.externalId) && (
              <ListItem
                disableGutters
                disablePadding>
                <ListItemIcon>
                  <Skeleton
                    enabled={!legalEntity}
                    width={20}>
                    <HashIcon />
                  </Skeleton>
                </ListItemIcon>
                <Skeleton
                  enabled={!legalEntity}
                  width={150}>
                  <ListItemText>{legalEntity ? legalEntity.externalId : 'loading...'}</ListItemText>
                </Skeleton>
              </ListItem>
            )}
        </List>
      </SearchTopResultSideInfo>
    </Stack>
  )
}
