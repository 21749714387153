import { MOBILE_NAVIGATION_HEIGHT } from '@app/layout/mobile-navigation'
import { XIcon } from '@gain/components/icons'
import { useIsXs } from '@gain/utils/responsive'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import GlobalStyles from '@mui/material/GlobalStyles'
import IconButton from '@mui/material/IconButton'
import { SnackbarKey, SnackbarOrigin, SnackbarProvider } from 'notistack'
import { createRef, ReactNode, useMemo } from 'react'

const snackbarProviderClasses = generateUtilityClasses('SnackbarProvider', [
  'variantSuccess',
  'containerAnchorOriginTopRight',
  'containerAnchorOriginBottomRight',
])

export interface SnackbarRootProviderProps {
  children: ReactNode
}

export default function SnackbarRootProvider({ children }: SnackbarRootProviderProps) {
  const notistackRef = createRef<SnackbarProvider>()
  const isXs = useIsXs()

  const handleDismiss = (key: SnackbarKey) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key)
    }
  }

  const anchorOrigin = useMemo(() => {
    return {
      vertical: isXs ? 'bottom' : 'top',
      horizontal: 'right',
    } as SnackbarOrigin
  }, [isXs])

  return (
    <>
      <GlobalStyles
        styles={{
          [`.${snackbarProviderClasses.containerAnchorOriginTopRight}`]: {
            top: `${64 + 14}px !important`,
          },
          [`.${snackbarProviderClasses.containerAnchorOriginBottomRight}`]: {
            bottom: `${14 + MOBILE_NAVIGATION_HEIGHT}px !important`,
          },
        }}
      />
      <SnackbarProvider
        ref={notistackRef}
        action={(key) => (
          <IconButton
            color={'inherit'}
            onClick={handleDismiss(key)}
            size={'small'}>
            <XIcon />
          </IconButton>
        )}
        anchorOrigin={anchorOrigin}
        autoHideDuration={5000}
        classes={snackbarProviderClasses}
        maxSnack={3}
        hideIconVariant>
        {children}
      </SnackbarProvider>
    </>
  )
}
