import Typography from '@gain/components/typography'
import { formatYear } from '@gain/utils/date'
import { formatPercentage } from '@gain/utils/number'
import { useTableRowHoverContext } from '@gain/utils/table'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

import Sparkline, { mapSparklineValues } from '../../../../common/sparkline'

const StyledRoot = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 6,
})

export interface PercentageTableCellProps {
  percentage: number | null
  year?: number | null
  years?: number[]
  results?: number[]

  // In finance some numbers are not sensible, e.g. EBITDA % > 100%. If this
  // happens, we display 'n.m.' (not meaningful) instead of the number.
  enableNotMeaningful?: boolean

  // The lower bound for the percentage to be considered meaningful. Defaults
  // to -100%, but for some it might be even lower (e.g. -500%).
  notMeaningfulMin?: number

  // If true the ai chip is shown
  isAiGenerated?: boolean | null
}

/**
 * Percentage table cell to display a percentage (e.g. EBITDA %). When hovered
 * it will display a sparkline of the historic values if those are available.
 */
export function PercentageTableCell({
  percentage,
  years,
  year,
  results,
  enableNotMeaningful = false,
  notMeaningfulMin = -100,
  isAiGenerated = false,
}: PercentageTableCellProps) {
  const hover = useTableRowHoverContext()

  if (hover && years && results) {
    const financials = mapSparklineValues(years, results)
    if (financials && percentage) {
      return (
        <Sparkline
          label={formatPercentage(percentage, { round: 0 })}
          values={financials}
        />
      )
    }
  }

  // Determine text color
  let textColor = 'text.primary'
  if (isAiGenerated) {
    textColor = 'text.secondary'
  }

  // Determine value to display
  let value: string
  if (percentage === null) {
    value = '-'
  } else if (enableNotMeaningful && (percentage < notMeaningfulMin || percentage > 100)) {
    value = 'n.m.'
  } else {
    value = formatPercentage(percentage)
  }

  return (
    <StyledRoot>
      <Typography
        color={textColor}
        fontWeight={'inherit'}
        variant={'inherit'}
        noWrap>
        {value}
      </Typography>
      {isAiGenerated && percentage !== null ? (
        <Chip
          color={'warning'}
          label={'AI'}
          size={'small'}
        />
      ) : year && percentage !== null ? (
        <Chip
          label={formatYear(year, 'short')}
          size={'small'}
        />
      ) : null}
    </StyledRoot>
  )
}
