import Chip, { ChipProps } from '@mui/material/Chip'
import Link, { LinkProps } from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'

const StyledLink = styled(Link)({
  cursor: 'pointer',
})

export interface TagProps extends ChipProps<'a', LinkProps> {
  href: string
  label: string
}

export const Tag = forwardRef<HTMLAnchorElement, TagProps>(
  ({ href, label, size = 'small', underline = 'none', ...chipProps }, ref) => {
    return (
      <Chip
        ref={ref}
        component={StyledLink}
        href={href}
        label={label}
        size={size}
        sx={{ cursor: 'pointer' }}
        underline={underline}
        {...chipProps}
      />
    )
  }
)

export default Tag
