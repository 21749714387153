import Head from '@gain/modules/head'
import { CreditListItem, Lender } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'

import { CreditListView } from '../../../features/list-view'

interface RouteCreditsProps {
  lender: Lender
}

export default function RouteCredits({ lender }: RouteCreditsProps) {
  return (
    <>
      <Head>
        <title>{lender.name} - Credits</title>
      </Head>
      <CreditListView
        defaultFilter={listFilters(listFilter<CreditListItem>('lenderIds', '=', lender.id))}
      />
    </>
  )
}
