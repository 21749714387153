import { InvestorFund } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid2'

import ListSources from '../source/source-list'

interface InvestorFundPerformanceSourcesProps {
  funds: InvestorFund[]
}

export default function InvestorFundPerformanceSources({
  funds,
}: InvestorFundPerformanceSourcesProps) {
  const hasPerformance = funds.some(
    (fund) =>
      fund.netIrr !== null ||
      fund.grossIrr !== null ||
      fund.twr !== null ||
      fund.tvpi !== null ||
      fund.moic !== null ||
      fund.dpi !== null ||
      fund.rvpi !== null
  )

  if (!hasPerformance) {
    return null
  }

  return (
    <Grid size={12}>
      <ListSources
        sources={[
          {
            title:
              'Fund performance data is collected through Freedom of Information Act (FOIA) requests made to public institutions in the US',
            publicationYear: null,
            publicationMonth: null,
            url: null,
            publisher: null,
          },
        ]}
      />
    </Grid>
  )
}
