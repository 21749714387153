import { useAssetList } from '@gain/api/app/hooks'
import { AssetListItem, ListedSecurityValuationRatios } from '@gain/rpc/app-model'
import { ListFilter, ListSort } from '@gain/rpc/list-model'
import { Option } from '@gain/rpc/shared-model'
import { listSort } from '@gain/rpc/utils'
import { useMemo } from 'react'

export type ValuationPeriod =
  | 'LastFiscalYear'
  | 'LastTwelveMonths'
  | 'CurrentFiscalYear'
  | 'NextTwelveMonths'
  | 'NextFiscalYear'

/**
 * This hook can be used to fetch assets for valuation cards. The reason for this hook
 * is to standardize the valuation size and sort order, with the ability to override
 * the defaults.
 */
export function useValuationAssets(
  filters: ListFilter<AssetListItem>[],
  limit = 150,
  initialSort: ListSort<AssetListItem>[] = [listSort('enterpriseValueEur', 'desc')]
) {
  return [
    initialSort,
    useAssetList({
      filter: filters,
      limit,
      sort: initialSort,
    }),
  ] as const
}

interface ValuationPeriodOption extends Option<ValuationPeriod> {
  matches: (valuation: ListedSecurityValuationRatios) => boolean
}

export function useValuationPeriodOptions() {
  return useMemo(() => {
    const date = new Date()
    const year = date.getFullYear()

    return new Array<ValuationPeriodOption>(
      {
        label: `Last year (${year - 1})`,
        value: 'LastFiscalYear',
        matches: (valuation) =>
          valuation.financialResultPeriodicity === 'annual' &&
          valuation.financialResultFiscalYear === year - 1,
      },
      {
        label: 'LTM',
        value: 'LastTwelveMonths',
        matches: (valuation) => valuation.financialResultPeriodicity === 'lastTwelveMonths',
      },
      {
        label: `Current year (${year}E)`,
        value: 'CurrentFiscalYear',
        matches: (valuation) =>
          valuation.financialResultPeriodicity === 'annual' &&
          valuation.financialResultFiscalYear === year,
      },
      {
        label: 'NTM',
        value: 'NextTwelveMonths',
        matches: (valuation) => valuation.financialResultPeriodicity === 'nextTwelveMonths',
      },
      {
        label: `Next year (${year + 1}E)`,
        value: 'NextFiscalYear',
        matches: (valuation) =>
          valuation.financialResultPeriodicity === 'annual' &&
          valuation.financialResultFiscalYear === year + 1,
      }
    )
  }, [])
}
