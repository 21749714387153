import { useInvestorFundListItem } from '@gain/api/app/hooks'
import { BriefcaseMoneyIcon, ChevronRightIcon } from '@gain/components/icons'
import { SlotHome, SlotName } from '@gain/components/slot'
import Head from '@gain/modules/head'
import { isJsonRpcError, RpcErrorCode } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Suspense } from 'react'
import { generatePath, Redirect, Route, Switch, useHistory } from 'react-router-dom'

import Loading from '../../common/loading'
import ProfileTabBar, { ProfileTabContainer, useActiveTab } from '../../common/profile-tab-bar'
import MobilePageHeader from '../../layout/mobile-page-header'
import { generateInvestorStrategyPagePath } from '../investor-strategy'
import NotFound from '../not-found'
import { generateInvestorPagePath, INVESTOR_PAGE_SUMMARY_PATH } from '../utils'
import RouteAddOns from './route-add-ons'
import RouteAssessment from './route-assessment'
import RouteBenchmarking from './route-benchmarking'
import RouteEntries from './route-entries'
import RouteExits from './route-exits'
import { useFundTabs } from './route-fund-hooks'
import {
  INVESTOR_FUND_ADD_ONS_PAGE_PATH,
  INVESTOR_FUND_ASSESSMENT_PAGE_PATH,
  INVESTOR_FUND_BENCHMARKING_PAGE_PATH,
  INVESTOR_FUND_ENTRIES_PAGE_PATH,
  INVESTOR_FUND_EXITS_PAGE_PATH,
  INVESTOR_FUND_PORTFOLIO_PAGE_PATH,
  INVESTOR_FUND_SUMMARY_PAGE_PATH,
  useInvestorFundPageParams,
} from './route-fund-path'
import RoutePortfolio from './route-portfolio'
import RouteSummary from './route-summary'

const StyledPartialNameLink = styled(Link)(({ theme }) => ({
  transition: theme.transitions.create(['color'], {
    duration: theme.transitions.duration.shortest,
    easing: theme.transitions.easing.easeInOut,
  }),
  willChange: 'color',

  '&:hover': {
    color: theme.palette.text.primary,
  },
}))

const StyledProfileTabContainer = styled(ProfileTabContainer)({
  flex: 1,
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
})

export default function RouteFundStrategy() {
  const params = useInvestorFundPageParams()
  const history = useHistory()
  const isXs = useIsXs()

  const swrInvestorFund = useInvestorFundListItem(params.id)

  const loading = swrInvestorFund.loading
  const tabs = useFundTabs(swrInvestorFund.data)
  const activeTab = useActiveTab(tabs)

  if (loading || !swrInvestorFund.data) {
    return <Loading />
  }

  if (
    isJsonRpcError(swrInvestorFund.error) &&
    swrInvestorFund.error.code === RpcErrorCode.InvalidResourceIdentifier
  ) {
    return <NotFound />
  }

  const title = `${swrInvestorFund.data.investorName} - ${swrInvestorFund.data.strategyName} - ${swrInvestorFund.data.name}`

  const investorPagePath = generateInvestorPagePath(
    swrInvestorFund.data,
    INVESTOR_PAGE_SUMMARY_PATH
  )

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      {isXs && <MobilePageHeader title={title} />}

      <ProfileTabBar
        activeTab={activeTab}
        defaultIcon={BriefcaseMoneyIcon}
        logoFileUrl={swrInvestorFund.data.investorLogoFileUrl}
        onLogoClick={() => {
          history.push(investorPagePath)
        }}
        tabActions={<SlotHome slotName={SlotName.Tabs} />}
        tabs={tabs}
        title={
          <Stack
            alignItems={'center'}
            direction={'row'}>
            <StyledPartialNameLink
              color={'text.secondary'}
              href={investorPagePath}
              underline={'none'}>
              {swrInvestorFund.data.investorName}
            </StyledPartialNameLink>
            {swrInvestorFund.data.strategyId && (
              <>
                &nbsp;
                <ChevronRightIcon fontSize={'inherit'} />
                &nbsp;
                <StyledPartialNameLink
                  color={'text.secondary'}
                  href={generateInvestorStrategyPagePath({
                    investorId: swrInvestorFund.data.investorId,
                    investorName: swrInvestorFund.data.investorName,
                    strategyId: swrInvestorFund.data.strategyId,
                    strategyName: swrInvestorFund.data.strategyName,
                  })}
                  underline={'none'}>
                  {swrInvestorFund.data.strategyName}
                </StyledPartialNameLink>
              </>
            )}
            &nbsp;
            <ChevronRightIcon fontSize={'inherit'} />
            &nbsp;
            <Typography variant={'inherit'}>{swrInvestorFund.data.name}</Typography>
          </Stack>
        }
        disableIndicatorAnimation
        disableTitleClick
        replaceUrl
        sticky
      />

      <StyledProfileTabContainer
        maxWidth={'lg'}
        disableGuttersTop>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route
              path={INVESTOR_FUND_SUMMARY_PAGE_PATH}
              exact>
              <RouteSummary fund={swrInvestorFund.data} />
            </Route>

            <Route
              path={INVESTOR_FUND_PORTFOLIO_PAGE_PATH}
              exact>
              <RoutePortfolio fund={swrInvestorFund.data} />
            </Route>
            <Route
              path={INVESTOR_FUND_BENCHMARKING_PAGE_PATH}
              exact>
              <RouteBenchmarking fund={swrInvestorFund.data} />
            </Route>
            <Route
              path={INVESTOR_FUND_ASSESSMENT_PAGE_PATH}
              exact>
              <RouteAssessment fund={swrInvestorFund.data} />
            </Route>
            <Route
              path={INVESTOR_FUND_EXITS_PAGE_PATH}
              exact>
              <RouteExits fund={swrInvestorFund.data} />
            </Route>
            <Route
              path={INVESTOR_FUND_ENTRIES_PAGE_PATH}
              exact>
              <RouteEntries fund={swrInvestorFund.data} />
            </Route>
            <Route
              path={INVESTOR_FUND_ADD_ONS_PAGE_PATH}
              exact>
              <RouteAddOns fund={swrInvestorFund.data} />
            </Route>
            <Route path={'*'}>
              <Redirect to={generatePath(INVESTOR_FUND_SUMMARY_PAGE_PATH, params)} />
            </Route>
          </Switch>
        </Suspense>
      </StyledProfileTabContainer>
    </>
  )
}
