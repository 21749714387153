import { useInvestorStrategyListItem } from '@gain/api/app/hooks'
import Tooltip, { TooltipProps } from '@gain/components/tooltip'
import { InvestorStrategyListItem } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { PropsWithChildren, ReactElement, useCallback, useMemo, useState } from 'react'

import TooltipInvestorStrategyTitle, {
  InvestorStrategyWithIdListItem,
} from './tooltip-investor-title-strategy.component'

export type TooltipInvestorStrategyProps = PropsWithChildren<
  Omit<TooltipProps, 'title' | 'open' | 'id'> & {
    id: number
    strategy?: InvestorStrategyListItem
    children: ReactElement
    open?: boolean
  }
>

export default function TooltipInvestorStrategy({
  id,
  strategy: defaultStrategy,
  variant = 'preview',
  disablePadding = true,
  children,
  onOpen,
  open = false,
  ...props
}: TooltipInvestorStrategyProps) {
  const [shouldOpen, setShouldOpen] = useState<boolean>(open)
  const strategy = useInvestorStrategyListItem(
    shouldOpen && !defaultStrategy && isDefined(id) ? id : null
  )
  const handleOpen = useCallback(() => setShouldOpen(true), [])
  const handleClose = useCallback(() => setShouldOpen(false), [])
  const useStrategy = defaultStrategy || strategy.data
  const isOpen = useMemo(() => shouldOpen && isDefined(useStrategy), [shouldOpen, useStrategy])

  if (useStrategy && typeof useStrategy.strategyId !== 'number') {
    return children
  }

  return (
    <Tooltip
      disablePadding={disablePadding}
      onClose={handleClose}
      onOpen={handleOpen}
      open={isOpen}
      title={
        useStrategy ? (
          <TooltipInvestorStrategyTitle
            onClick={(event) => event.stopPropagation()}
            strategy={useStrategy as InvestorStrategyWithIdListItem}
          />
        ) : (
          ''
        )
      }
      variant={variant}
      disableInteractive
      {...props}>
      {children}
    </Tooltip>
  )
}
