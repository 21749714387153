import { useUserProfile } from '@gain/api/app/hooks'
import { RpcClientFetcher, useRpcClient } from '@gain/api/swr'
import { useUserProfileContext } from '@gain/modules/user'
import { RpcError, RpcErrorCode } from '@gain/rpc/utils'
import { useDialogState } from '@gain/utils/dialog'
import { useSnackbar } from 'notistack'
import { MouseEvent, ReactElement, useCallback, useState } from 'react'

import { useTrackEvent } from '../../google-analytics'
import { useTrackDownload } from '../../planhat/planhat-hooks'
import ExportDialogLimitReached from './export-dialog-limit-reached'
import {
  ExportMethodMap,
  methodConfigMap,
  ResponseHandlerCallback,
  UNLIMITED_EXPORTS,
} from './export-method-config'
import { ExportMaxCount } from './index'

export type ExportContainerMethod = keyof ExportMethodMap

async function createDownloadTask<
  Method extends ExportContainerMethod,
  Params extends ExportMethodMap[Method]['params']
>(
  params: Params,
  method: Method,
  fetcher: RpcClientFetcher,
  responseHandler: ResponseHandlerCallback<Method>
) {
  const response = await fetcher<Method, Params>({
    method,
    params,
  })

  responseHandler(response)
}

export interface ExportContainerApi {
  download: (event: MouseEvent) => Promise<boolean>
  loading: boolean
  disabled?: boolean
  currentExports: number
  maxExports: number
}

export interface ExportContainerProps<
  Method extends ExportContainerMethod,
  Params extends ExportMethodMap[Method]['params'] = ExportMethodMap[Method]['params']
> {
  method: Method
  params: Params
  children: (api: ExportContainerApi) => ReactElement
  showMaxExportCount?: boolean
  nrOfExports?: number
}

export default function ExportContainer<
  Method extends ExportContainerMethod,
  Params extends ExportMethodMap[Method]['params']
>({
  method,
  params,
  children,
  showMaxExportCount = false,
  nrOfExports = 1,
}: ExportContainerProps<Method, Params>) {
  const userProfile = useUserProfileContext()
  const { mutate } = useUserProfile()
  const [showLimitDialog, openLimitDialog, closeLimitDialog] = useDialogState()
  const [limitReachedErrorMessage, setLimitReachedErrorMessage] = useState<string>()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const trackEvent = useTrackEvent()
  const trackDownload = useTrackDownload()
  const apiFetcher = useRpcClient()

  const config = methodConfigMap[method]

  const handleHideLimitReached = useCallback(
    (event: MouseEvent) => {
      if (event) {
        event.stopPropagation()
      }

      closeLimitDialog()
    },
    [closeLimitDialog]
  )

  const handleDownload = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()

      const { responseHandler, analytics, increaseExportUsage } = config
      setLoading(true)

      try {
        await createDownloadTask(params, method, apiFetcher, responseHandler)

        if (analytics) {
          trackEvent(analytics.action, analytics.category)
          trackDownload(analytics.action, analytics.category)
        }

        // Refresh the export usage. For non-single-use downloads, we need to wait a bit
        // to ensure the export usage has been updated.
        if (increaseExportUsage) {
          await mutate(increaseExportUsage(userProfile, nrOfExports))
        } else {
          await mutate()
        }

        return true
      } catch (err) {
        if (err instanceof RpcError && err.code === RpcErrorCode.ExportLimitReached) {
          setLimitReachedErrorMessage(err.message)
          openLimitDialog()
        } else {
          enqueueSnackbar('Something went wrong while exporting data.', {
            id: 'export-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setLoading(false)
      }
    },
    [
      config,
      params,
      method,
      apiFetcher,
      trackEvent,
      trackDownload,
      mutate,
      userProfile,
      nrOfExports,
      openLimitDialog,
      enqueueSnackbar,
    ]
  )

  // Check if the user has reached the export limit
  const { current, max } = config.getExportLimits(userProfile)
  const hasMaxExports = max !== UNLIMITED_EXPORTS && current + nrOfExports > max

  return (
    <>
      {showMaxExportCount && <ExportMaxCount method={method} />}

      {children({
        loading,
        currentExports: current,
        maxExports: max,
        disabled: hasMaxExports,
        download: handleDownload,
      })}

      <ExportDialogLimitReached
        message={limitReachedErrorMessage}
        onClose={handleHideLimitReached}
        open={showLimitDialog}
      />
    </>
  )
}
