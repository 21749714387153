import { useRpcClient } from '@gain/api/swr'
import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { UserProfile } from '@gain/rpc/app-model'
import { ObjectType } from '@gain/rpc/shared-model'
import { isGainProUser, isPlanhatTestUser } from '@gain/utils/user'
import { useCallback, useEffect, useRef } from 'react'

type CombinedPathType =
  | 'home'
  | 'search'
  | 'help'
  | 'settings'
  | 'article'
  | 'deal'
  | 'segment'
  | 'bookmark'
  | 'filtered'
  | 'investor_strategy'
  | 'investor_fund'
  | 'conference'
  | `${ObjectType}`

const BROWSER_EXTENSION = 'Browser Extension'
const PAGE_VIEW = 'PageView'
const COUNTRY = 'Country'
const LIST = 'list'
const TAB = 'tab'

/**
 * Returns a callback that is used to track an activity in planhat.
 */
export function useTrackActivityCallback() {
  const rpc = useRpcClient()

  return useCallback(
    (actionOrActions: string | string[]) => {
      const actions = Array.isArray(actionOrActions) ? actionOrActions : [actionOrActions]

      if (actions.length === 0) {
        return
      }

      rpc({
        method: 'data.trackActivity',
        params: {
          actions: actions,
        },
      }).catch(() => {
        // Should not happen but just in case it does, we silently ignore errors
        // for the user as activity tracking should not interfere with the UX
      })
    },
    [rpc]
  )
}

interface PageViewOptions {
  id?: number
  list?: boolean
  tab?: string | undefined
  requireTab?: boolean
  country?: string
}

export function useTrackPageView(path: CombinedPathType, options?: PageViewOptions) {
  const trackActivity = useTrackActivityCallback()
  const isBrowserExtension = useIsBrowserExtension()
  const previousId = useRef<number>(0)
  const previousTab = useRef<string | undefined>(undefined)

  useEffect(() => {
    if (!path) {
      return
    }

    const isDifferentId = (options?.id || -1) !== previousId.current
    const isDifferentTab = options?.tab !== previousTab.current

    if ((isDifferentId || isDifferentTab) && (!options?.requireTab || options?.tab !== undefined)) {
      const pageView = isBrowserExtension ? `${PAGE_VIEW} ${BROWSER_EXTENSION}` : PAGE_VIEW

      let pageViewAction = `${pageView} ${path}`
      if (options?.list && options?.tab) {
        pageViewAction = `${pageView} ${LIST} ${path} ${TAB} ${options.tab}`
      } else if (options?.list) {
        pageViewAction = `${pageView} ${LIST} ${path}`
      } else if (options?.tab) {
        pageViewAction = `${pageView} ${path} ${TAB} ${options.tab}`
      }

      const actions: string[] = [pageView, pageViewAction]
      if (options?.country) {
        actions.push(`${COUNTRY} ${path} ${options.country}`)
      }

      trackActivity(actions)
    }

    previousId.current = options?.id || -1
    previousTab.current = options?.tab
  }, [isBrowserExtension, path, options, trackActivity])
}

export function useTrackDownload() {
  const trackActivity = useTrackActivityCallback()

  return useCallback(
    (action: string, category: string) => {
      trackActivity(['Download', `Download ${action} ${category}`])
    },
    [trackActivity]
  )
}

export function useTrackUpload() {
  const trackActivity = useTrackActivityCallback()

  return useCallback(
    (action: string, category: string) => {
      trackActivity(['Upload', `Upload ${action} ${category}`])
    },
    [trackActivity]
  )
}

// Allow use of plantrack (Planhat script) in TypeScript
declare global {
  const plantrack: {
    identify: (
      userId: string | null,
      extraInfo?: { email: string; companyExternalId: string }
    ) => void
    checkInAppMessage: () => void
    load: (endpoint: string) => void
  }
}

// Identifies the user in Planhat and (implicitly) starts polling for messages
// (e.g. show NPS dialog).
// See: https://docs.planhat.com/#user_activities
// For testing see: ./README.md on how to test
export function useIdentifyPlanhat(userProfile: UserProfile) {
  const isBrowserExtension = useIsBrowserExtension()
  const isInitializedRef = useRef(false)

  useEffect(() => {
    // Ensure we don't use third party scripts in the browser extension
    if (isBrowserExtension) {
      return
    }

    // Tracking Gain.pro users in Planhat is not supported
    if (isGainProUser(userProfile)) {
      return
    }

    // We cannot guarantee that the Planhat session cookie has been removed on
    // logout (which can also be caused by an external factor like changing the
    // security settings). This cookie could cause the NPS survey to show up
    // unexpectedly on the login page for example. In order to avoid this, we
    // load the actual script after the user has signed in.
    if (!isInitializedRef.current) {
      plantrack.load('https://analytics.planhat.com/analytics/9a1f9280-20d5-4628-8a11-825120e85e45')
      isInitializedRef.current = true
    }

    // Identify the user within the Planhat script. Note that this doesn't
    // necessarily execute the actual request to Planhat immediately.
    plantrack.identify(userProfile.id.toString(10), {
      email: userProfile.email,
      companyExternalId: `${userProfile.customerId}`,
    })

    // If the user doesn't belong to the Planhat test customer account, we're
    // all set.
    if (!isPlanhatTestUser(userProfile)) {
      // Reset Planhat user on logout
      return () => {
        plantrack.identify(null)
      }
    }

    // When the user is part of the Planhat test customer account, execute
    // additional logic to trigger received messages (e.g. show NPS dialog) as
    // quickly as possible to speed up testing.
    //
    // The Planhat script internally executes a function every 10 milliseconds
    // that checks if the location.pathname has changed. When this happens it
    // executes checkInAppMessage.
    //
    // checkInAppMessage is used to schedule requests (e.g. "identify") and
    // receive messages (e.g. "show NPS dialog") from Planhat. It will not
    // do anything until inappTimeout (timestamp in localStorage) has expired.
    //
    // checkInAppMessage executes an API call to receive new messages (e.g.
    // "show NPS dialog") and, on success, sets the inappTimeout 5 minutes
    // ahead. It will not do anything again until the inappTimeout has expired.
    //
    // In order to receive messages as quickly as possible for testing purposes,
    // clear inappTimeout in localStorage and execute checkInAppMessage without
    // the pathname check.
    const id = setInterval(() => {
      localStorage.removeItem('inappTimeout')
      plantrack.checkInAppMessage()
    }, 10000)

    return () => {
      clearInterval(id)
      plantrack.identify(null)
    }
  }, [userProfile, isBrowserExtension])
}
