import { ChevronRightIcon } from '@gain/components/icons'
import { isTruthy, validateUrl } from '@gain/utils/common'
import { formatMonthYear } from '@gain/utils/date'
import { Source } from '@gain/utils/source'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'

export interface SourceProps {
  source: Source
}

const listSourcesItemClasses = generateUtilityClasses('ListSourcesItem', ['primary'])

const StyledContainer = styled('a')(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),
  textDecoration: 'none',
  padding: theme.spacing(0.75, 3),
  [`&:hover[href] .${listSourcesItemClasses.primary}`]: {
    textDecoration: 'underline',
  },
  '&:hover[href]': {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey['50'],
  },
}))

export default function ListSourcesItem({ source }: SourceProps) {
  const secondary = useMemo(() => {
    return [
      formatMonthYear(source.publicationMonth, source.publicationYear, { emptyValue: '' }),
      source['publisher'],
    ]
      .filter(isTruthy)
      .join(' - ')
  }, [source])

  return (
    <StyledContainer
      href={validateUrl(source.url)}
      rel={'noopener noreferrer'}
      target={'_blank'}>
      <Stack direction={'column'}>
        <Typography
          className={listSourcesItemClasses.primary}
          color={'text.primary'}
          variant={'body2'}>
          {source.title}
        </Typography>
        {secondary && (
          <Typography
            color={'text.secondary'}
            variant={'body2'}>
            {secondary}
          </Typography>
        )}
      </Stack>
      {source.url && <ChevronRightIcon sx={{ fontSize: 16, color: 'grey.600' }} />}
    </StyledContainer>
  )
}
