import { useUserProfile } from '@gain/api/app/hooks'
import { getRegionAndCountryNames } from '@gain/utils/countries'
import { SECTORS, SUBSECTORS } from '@gain/utils/sector'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { BooleanParam, useQueryParam } from 'use-query-params'

import NewsPreferencesDialog from './news-preferences-dialog'
import { getSectorAndSubsectorNames } from './use-subsector-format'

function formatLimits(values: string[], type: string) {
  if (values.length === 0) {
    return null
  }

  if (values.length < 3) {
    return values.join(', ')
  }

  return `${values.length} ${type}`
}

function NewsFilterText() {
  const { data: userProfile } = useUserProfile()

  if (!userProfile) {
    return (
      <Skeleton
        variant={'text'}
        width={100}
      />
    )
  }

  const regionAndCountryNames = getRegionAndCountryNames(userProfile.recommendRegions)
  const sectorAndSubsectorNames = getSectorAndSubsectorNames(
    userProfile.recommendSubsectors,
    SECTORS,
    SUBSECTORS
  )

  const regionLimitText = formatLimits(regionAndCountryNames, 'regions')
  const sectorLimitText = formatLimits(sectorAndSubsectorNames, 'sectors')
  const limits = [regionLimitText, sectorLimitText].filter(Boolean)

  if (limits.length === 0) {
    return <>Filter news by country and sector</>
  }

  return <>News limited to {limits.join(' and ')}</>
}

const StyledTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'baseline',
})

export default function FeedLimit() {
  const [open, setOpen] = useQueryParam('news-preferences', BooleanParam)

  return (
    <>
      <NewsPreferencesDialog
        onClose={() => setOpen(undefined)}
        open={open || false}
      />
      <div>
        <StyledTypography
          color={'text.secondary'}
          variant={'body2'}>
          <NewsFilterText />
          <Button
            color={'primary'}
            onClick={() => setOpen(true)}
            variant={'text'}>
            Filters
          </Button>
        </StyledTypography>
      </div>
    </>
  )
}
