import { CompanyIcon } from '@gain/components/icons'
import { TooltipHeader } from '@gain/components/tooltip'
import { AdvisorListItem } from '@gain/rpc/app-model'
import { getAdvisoryActivityLabel } from '@gain/utils/advisor'
import { formatCountryAndCity } from '@gain/utils/countries'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { median } from 'd3'

import EbitdaValuesRange from '../../../common/ebitda-values-range'
import KeyValueList, { KeyValueListItem } from '../../../common/key-value/key-value-list'
import FinancialValue from '../../financial/financial-value'

const StyledEbitdaValuesRange = styled(EbitdaValuesRange)({
  width: 160,
})

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 320,
}))

interface TooltipAdvisorTitleProps {
  advisor: AdvisorListItem
}

export default function TooltipAdvisorTitle({ advisor }: TooltipAdvisorTitleProps) {
  return (
    <StyledContainer
      direction={'column'}
      spacing={2}>
      <TooltipHeader
        defaultIcon={<CompanyIcon />}
        logoSrc={advisor.logoFileUrl}
        region={advisor.operationalHqCountryCode}
        subtitle={advisor.dealCount > 0 ? `Advised ${advisor.dealCount} deals (L5Y)` : undefined}
        title={advisor.name}
      />
      <KeyValueList
        spacing={0.5}
        dense>
        <KeyValueListItem
          label={'Advisor HQ'}
          value={formatCountryAndCity(advisor.operationalHqCountryCode, advisor.operationalHqCity)}
        />

        {advisor.coreFocus && (
          <KeyValueListItem
            label={'Core focus'}
            value={getAdvisoryActivityLabel(advisor.coreFocus)}
          />
        )}
      </KeyValueList>

      {advisor.dealEbitdasEur.length > 0 && (
        <>
          <Divider />

          <KeyValueList
            spacing={0.5}
            dense>
            <KeyValueListItem
              label={'Deal EBITDA range'}
              value={<StyledEbitdaValuesRange ebitdaValues={advisor.dealEbitdasEur} />}
            />

            <KeyValueListItem
              label={'Median EBITDA'}
              value={<FinancialValue amount={median(advisor.dealEbitdasEur)} />}
            />
          </KeyValueList>
        </>
      )}
    </StyledContainer>
  )
}
