import { ReactNode } from 'react'

import {
  CheckboxListContext,
  CheckboxListContextType,
  useCheckboxListContext,
} from './checkbox-list-context'

export type CheckboxListValueType = string | number
export type CheckboxListValue<ValueType extends CheckboxListValueType = CheckboxListValueType> =
  | ValueType[]
  | null

export interface CheckboxListProps<ValueType extends CheckboxListValueType> {
  value: CheckboxListValue<ValueType>
  onChange?: (value: CheckboxListValue<ValueType>) => void
  children: ReactNode
}

export default function CheckboxList<ValueType extends CheckboxListValueType>({
  children,
  ...props
}: CheckboxListProps<ValueType>) {
  const context = useCheckboxListContext<ValueType>(props)

  return (
    <CheckboxListContext.Provider
      value={context as unknown as CheckboxListContextType<CheckboxListValueType>}>
      {children}
    </CheckboxListContext.Provider>
  )
}
