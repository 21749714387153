import * as echarts from 'echarts/core'
import { isEqual } from 'lodash'
import { useCallback, useState } from 'react'

interface Rect {
  width: number
  height: number
  x: number
  y: number
}

/**
 * ActiveBar is the point that is being hovered upon with its position,
 * dimension and data within the chart. This is used to allow
 * us to display a MUI tooltip above a hovered point within the chart.
 */
export interface ActiveBar {
  rect: Rect
  value: number
}

/**
 * useBarChartChartHover returns the currently hovered data point and mouse
 * event handlers that convert EChart event state to an ActiveBar. This
 * enables us to plot a tooltip above a hovered data point.
 */
export default function useBarChartChartHover() {
  const [activeBar, setActiveBar] = useState<ActiveBar | null>(null)

  const handleSetActiveBar = useCallback((options: ActiveBar) => {
    // Set the active bar but only if the actual data point has changed
    // to prevent unnecessary state changes
    setActiveBar((prev) => {
      const next = {
        ...options,
        rect: {
          ...options.rect,
          x: options.rect.x,
          y: options.rect.y,
        },
      }

      if (isEqual(prev, next)) {
        return prev
      }

      return next
    })
  }, [])

  const handleMouseMove = useCallback(
    (event: echarts.ECElementEvent) => {
      // Bail out on events that don't require action
      if (
        !event.event ||
        event.data == null ||
        typeof event.data !== 'object' ||
        !event.event.topTarget
      ) {
        return
      }

      // Detect when a bar is hovered
      if (
        event.componentType === 'series' &&
        'value' in event.data &&
        typeof event.data.value === 'number'
      ) {
        handleSetActiveBar({
          rect: event.event.topTarget.getBoundingRect(),
          value: event.data.value,
        })
      }
    },
    [handleSetActiveBar]
  )

  // Resets the state on mouse out.
  const handleMouseOut = useCallback(() => {
    setActiveBar(null)
  }, [])

  return [activeBar, handleMouseMove, handleMouseOut] as const
}
