import { getOption, ManagementPositionType } from '@gain/rpc/shared-model'
import { MANAGER_POSITION_OPTIONS } from '@gain/rpc/shared-model'
import { isDefined, valueToEnum } from '@gain/utils/common'
import { capitalize } from '@mui/material/utils'

export interface FormatManagerPositionOptions {
  emptyValue?: string
  disableEnumCheck?: boolean
  variant?: 'short' | 'default'
}

export const formatManagerPosition = (
  position: string | null | undefined,
  {
    emptyValue = '-',
    disableEnumCheck = false,
    variant = 'default',
  }: FormatManagerPositionOptions = {}
) => {
  if (!isDefined(position)) {
    return emptyValue
  }

  const parsed = valueToEnum(ManagementPositionType, position)

  if (!isDefined(parsed)) {
    return disableEnumCheck ? capitalize(position) : emptyValue
  }

  const option = getOption(MANAGER_POSITION_OPTIONS, parsed)

  if (!option) {
    return emptyValue
  }

  return (variant === 'short' && option.shortLabel) || option.label
}
