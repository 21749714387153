import { ColumnsIcon } from '@gain/components/icons'
import Button from '@mui/material/Button'
import { useState } from 'react'

import { useTrackEvent } from '../google-analytics'
import ColumnPickerDialog from './column-picker-dialog'
import { ColumnPickerConfigType } from './use-column-picker'

export interface ColumnPickerButtonProps {
  columnConfigId: ColumnPickerConfigType
  activeFilterColumns: string[]
}

/**
 * The ColumnPickerButton is used to display a styled button that opens
 * the ColumnPickerDialog on click.
 */
export default function ColumnPickerButton({
  columnConfigId,
  activeFilterColumns,
}: ColumnPickerButtonProps) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const trackEvent = useTrackEvent()

  const handleOpenDialog = () => {
    const action = `open ${columnConfigId} column dialog`
    trackEvent(action, 'COLUMN_PICKER')
    setDialogOpen(true)
  }

  return (
    <>
      <ColumnPickerDialog
        activeFilterColumns={activeFilterColumns}
        columnConfigId={columnConfigId}
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
      />

      <Button
        onClick={handleOpenDialog}
        startIcon={<ColumnsIcon />}>
        Columns
      </Button>
    </>
  )
}
