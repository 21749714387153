import { Asset } from '@gain/rpc/app-model'
import { buildAssetFileName } from '@gain/utils/asset'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { MouseEvent, SyntheticEvent, useState } from 'react'

import DialogHeader from '../../../../common/dialog-header'
import { ExportButton, useIsWithinExportLimit } from '../../../../features/export/export-button'
import {
  useAcquisitionsAndDivestmentsDeals,
  useAssetDealHistoryDeals,
} from '../../asset-ownership/asset-ownership-hooks'
import AssetDownloads from './asset-downloads'
import { useLegalEntitiesWithAnnualReports } from './asset-downloads-dialog.hooks'
import LegalEntityDownloadAccordion from './legal-entity-download-accordion'

export interface AssetDownloadsDialogProps extends Pick<DialogProps, 'open' | 'onClose'> {
  asset: Asset
  preview: boolean
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  minWidth: 'calc(100vw - 64px)',

  [theme.breakpoints.up(648)]: {
    minWidth: 600,
  },
}))

export default function AssetDownloadsDialog({
  open,
  onClose,
  asset,
  preview,
}: AssetDownloadsDialogProps) {
  const isWithinExportLimit = useIsWithinExportLimit()
  const [expandedEntity, setExpandedEntity] = useState<number | false>(false)

  const assetDealHistoryDeals = useAssetDealHistoryDeals(asset.id, 1)
  const acquisitionsAndDivestmentsDeals = useAcquisitionsAndDivestmentsDeals(asset.id, 1)

  const legalEntities = useLegalEntitiesWithAnnualReports(
    asset.legalEntities.map((legalEntity) => legalEntity.legalEntityId)
  )

  const handleChange = (entity: number) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpandedEntity(isExpanded ? entity : false)
  }

  const handleClose = (event: MouseEvent) => {
    onClose?.(event, 'backdropClick')
  }

  // Calculate the total number of annual reports
  const totalNrOfAnnualReports =
    legalEntities?.reduce((acc, entity) => acc + entity.annualReports.length, 0) ?? 0

  // Only enable "Download all" if all underlying files are within the export limits
  const enableDownloadAll =
    isWithinExportLimit('data.exportFactsheet', 1) &&
    isWithinExportLimit('data.exportDeals', 1) &&
    isWithinExportLimit('data.exportAssetFinancials', 1) &&
    isWithinExportLimit('data.downloadAssetAnnualReportFiles', totalNrOfAnnualReports)

  return (
    <Dialog
      onClose={onClose}
      open={open}>
      <DialogHeader
        onCloseClick={handleClose}
        title={'Downloads'}
      />

      <StyledDialogContent>
        <AssetDownloads
          asset={asset}
          assetName={asset.generalInfo?.name}
          excelFileId={asset?.generalInfo?.excelFileId}
          preview={preview}
          withDeals={
            assetDealHistoryDeals.data.items.length > 0 ||
            acquisitionsAndDivestmentsDeals.data.length > 0
          }
        />

        {legalEntities.length > 0 && (
          <Stack
            alignItems={'center'}
            direction={'row'}
            justifyContent={'space-between'}
            mr={1}
            mt={2}>
            <Typography variant={'h6'}>Legal entities</Typography>

            <ExportButton
              method={'data.downloadAssetAnnualReportFiles'}
              nrOfExports={totalNrOfAnnualReports}
              params={{
                assetId: asset.id,
                filename: buildAssetFileName(asset, 'legal entities.zip'),
              }}
            />
          </Stack>
        )}

        {legalEntities.map((entity) => (
          <LegalEntityDownloadAccordion
            key={entity.id}
            expanded={expandedEntity === entity.id}
            legalEntity={entity}
            onChange={handleChange(entity.id)}
          />
        ))}
      </StyledDialogContent>

      <DialogActions>
        <ExportButton
          color={'primary'}
          disabled={!enableDownloadAll}
          method={'data.downloadAssetFiles'}
          params={{
            assetId: asset.id,
            filename: buildAssetFileName(asset, '.zip'),
          }}
          text={'Download all'}
          tooltipMode={'never'}
          variant={'contained'}
          fullWidth
        />
      </DialogActions>
    </Dialog>
  )
}
