import { useAdvisorListItem, useInvestorStrategyListItem } from '@gain/api/app/hooks'
import { DealListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { decodeQueryParams, DelimitedNumericArrayParam } from 'serialize-query-params'

import { ListViewDeal } from '../../features/list-view'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import ListPageLayout from '../../layout/list-page-layout'
import { useInvestorStrategyAdvisorDealsPageParams } from './route-investor-strategy-advisor-deals-path'

/**
 * This is a list of deals that are associated with the investor strategy a particular
 * advisor for both entries and exits.
 *
 * For the actual investor strategy page we separate entries and exits into two separate tabs;
 * there is no consolidated deals page we can use.
 */
export default function RouteInvestorStrategyAdvisorDeals() {
  const location = useLocation()
  const { strategyId, advisorId } = useInvestorStrategyAdvisorDealsPageParams()

  const swrAdvisor = useAdvisorListItem(advisorId)
  const swrStrategy = useInvestorStrategyListItem(strategyId)

  const investorName = swrStrategy.data?.investorName || '-'
  const strategyName = swrStrategy.data?.strategyName || '-'
  const advisorName = swrAdvisor.data?.name || '-'

  useTrackPageView('investor_strategy', { tab: 'deals', requireTab: true })

  // Currently we do not have "buyerAdvisorIds" or "sellerAdvisorIds" in the deal model,
  // hence we pass the deal ids explicitly.
  const searchParams = parse(location.search)
  const decoded = decodeQueryParams({ dealIds: DelimitedNumericArrayParam }, searchParams)
  if (!decoded.dealIds) {
    return null
  }

  const dealIds = decoded.dealIds.filter((item) => item !== null) as number[]
  const defaultFilters = listFilters<DealListItem>(listFilter('id', '=', dealIds))

  if (swrAdvisor.loading || swrStrategy.loading) {
    // The list view already shows a spinner so in order to avoid duplicate
    // spinners, we render an empty page while the strategy and advisor are
    // loading
    return null
  }

  return (
    <ListPageLayout title={`${investorName} - ${strategyName} deals - advised by ${advisorName}`}>
      <ListViewDeal defaultFilter={defaultFilters} />
    </ListPageLayout>
  )
}
