import { AssetListItem } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'
import { Fragment } from 'react'

import { ASSET_FILTER_MAP, AssetFilterField } from '../../asset/asset-filter-bar'
import { FilterValueGroup, FilterValueLabel } from '../../filter/filter-bar'
import RecentFilterValueLabel from './recent-filter-value-label'
import { OnChangeValueLabelType } from './use-calculate-number-of-filters'

const StyledOr = styled('span')(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 'bold',
  padding: theme.spacing(0, 0.5),
}))

interface RecentFilterListItemGroupProps {
  index: number
  hidden?: boolean
  group: FilterValueGroup<AssetListItem, AssetFilterField>
  onChangeValueLabel?: OnChangeValueLabelType
}

export default function RecentFilterListItemGroup({
  index,
  group,
  hidden,
  onChangeValueLabel,
}: RecentFilterListItemGroupProps) {
  return (
    <span style={{ display: hidden ? 'none' : 'initial' }}>
      {index > 0 && <>, </>}

      {group.value.map((item, itemIndex) => (
        <Fragment key={itemIndex}>
          {itemIndex > 0 && <StyledOr>OR</StyledOr>}

          <FilterValueLabel
            filter={ASSET_FILTER_MAP[item.filterId]}
            value={item.value}
            ValueLabelComponent={(props) => (
              <RecentFilterValueLabel
                isOrFilter={itemIndex > 0}
                onChange={onChangeValueLabel?.(index, itemIndex)}
                {...props}
              />
            )}
          />
        </Fragment>
      ))}
    </span>
  )
}
