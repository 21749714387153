import { kebabCase } from 'lodash'
import { generatePath, useParams } from 'react-router-dom'

import { Params } from '../utils'

export enum RouteInvestorFundTabs {
  Summary = 'summary',
  Portfolio = 'portfolio',
  Assessment = 'assessment',
  Benchmarking = 'benchmarking',
  Entries = 'entries',
  Exits = 'exits',
  AddOns = 'add-ons',
}

export const INVESTOR_FUND_PAGE_PATH = '/funds/:id/:name'
export const INVESTOR_FUND_SUMMARY_PAGE_PATH = `${INVESTOR_FUND_PAGE_PATH}/${RouteInvestorFundTabs.Summary}`
export const INVESTOR_FUND_PORTFOLIO_PAGE_PATH = `${INVESTOR_FUND_PAGE_PATH}/${RouteInvestorFundTabs.Portfolio}`
export const INVESTOR_FUND_ASSESSMENT_PAGE_PATH = `${INVESTOR_FUND_PAGE_PATH}/${RouteInvestorFundTabs.Assessment}`
export const INVESTOR_FUND_BENCHMARKING_PAGE_PATH = `${INVESTOR_FUND_PAGE_PATH}/${RouteInvestorFundTabs.Benchmarking}`
export const INVESTOR_FUND_ENTRIES_PAGE_PATH = `${INVESTOR_FUND_PAGE_PATH}/${RouteInvestorFundTabs.Entries}`
export const INVESTOR_FUND_EXITS_PAGE_PATH = `${INVESTOR_FUND_PAGE_PATH}/${RouteInvestorFundTabs.Exits}`
export const INVESTOR_FUND_ADD_ONS_PAGE_PATH = `${INVESTOR_FUND_PAGE_PATH}/${RouteInvestorFundTabs.AddOns}`

export interface InvestorFundPageParams extends Params {
  id: string
  name: string
}

export interface InvestorFundPagePathParams {
  id: number | string
  name: string
}

export function useInvestorFundPageParams() {
  const params = useParams<InvestorFundPageParams>()

  return {
    id: parseInt(params.id),
    name: params.name,
  }
}

export function generateInvestorFundPagePath(
  params: InvestorFundPagePathParams,
  path = INVESTOR_FUND_SUMMARY_PAGE_PATH
) {
  return generatePath(path, {
    ...params,
    id: params.id,
    name: kebabCase(params.name),
  })
}
