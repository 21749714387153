import { CollapseIcon, ExpandIcon, MinusIcon, PlusIcon } from '@gain/components/icons'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { useRef, useState } from 'react'

import { TempLegalEntityStructureNode } from './org-chart-model'
import {
  CHART_CONTAINER_PADDING_BOTTOM,
  CHART_CONTAINER_PADDING_TOP,
  CHART_CONTAINER_PADDING_X,
  CHART_HEIGHT,
  DIVIDER_WIDTH,
  getDepth,
  NODE_HEIGHT,
  NODE_HORIZONTAL_PADDING,
  NODE_ICON_LABEL_GAP,
  NODE_ICON_SIZE,
  NODE_ITEM_GAP,
  orgChartClasses,
  useChartData,
  useChartTooltip,
  useInitializeChart,
} from './org-chart-utils'

const ICON_TOOLTIP_DELAY = 500
const ICON_TOOLTIP_PLACEMENT = 'left'

const StyledContainer = styled('div')(({ theme }) => ({
  paddingTop: CHART_CONTAINER_PADDING_TOP,
  paddingBottom: CHART_CONTAINER_PADDING_BOTTOM,
  paddingLeft: CHART_CONTAINER_PADDING_X,
  paddingRight: CHART_CONTAINER_PADDING_X,
  position: 'relative',
}))

const StyledControlGroupsContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(3),
  bottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const StyledControlGroup = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

const StyledControlDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(0, 0.5),
}))

/**
 * div containing the org chart. Since d3-org-chart uses d3 behind the scenes
 * to render the actual HTML, we cannot control the styling using styled
 * components in React. Instead, chart elements are styled using classes which
 * are targeted from this container div.
 */
const StyledOrgChart = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: 14,
  height: CHART_HEIGHT,
  overflow: 'hidden',

  // Targets the containing node element rendered by the d3-org-chart lib
  '& .node': {
    // Nodes are not clickable for now
    cursor: 'default !important',
  },

  // Targets the clickable expand/collapse area controlled by d3-org-chart
  '& .node-button-rect': {
    // Move the area a bit below the actual node instead of overlapping it
    y: '5px !important',
  },

  // Targets the expand/collapse content area, this element contains the actual
  // rendered content for the expand/collapse area and is not clickable by
  // default
  '& .node-button-foreign-object': {
    // Nodes are not clickable for now
    cursor: 'default',
    [`& .${orgChartClasses.expandButton}`]: {
      marginTop: 20 + 5,
    },
  },

  '& .node-button-div': {
    // d3-org-chart forces all expand/collapse buttons to be clickable and adds
    // a pointer cursor. We want to disable this behaviour for initially
    // expanded nodes. To fix this, allow pointer events to be dispatched from
    // elements that are defined within this d3-org-chart controlled element,
    // so we can turn the cursor into a pointer there. See: expandButton class.
    pointerEvents: 'all !important',
  },

  // Node content container
  [`& .${orgChartClasses.node}`]: {
    boxShadow: theme.palette.shadow.level1d,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: NODE_ITEM_GAP,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: NODE_HEIGHT,
    paddingLeft: NODE_HORIZONTAL_PADDING,
    paddingRight: NODE_HORIZONTAL_PADDING,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  // Container for an item within a node (a legal entity has one, shareholders
  // can have multiple)
  [`& .${orgChartClasses.itemContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: NODE_ICON_LABEL_GAP,
    minWidth: 0,
  },

  // Item label
  [`& .${orgChartClasses.label}`]: {
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    ...theme.typography.body2,
  },

  // Utility class to truncate text when needed
  [`& .${orgChartClasses.truncateText}`]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  // Utility class to display text in secondary color
  [`& .${orgChartClasses.textSecondary}`]: {
    color: theme.palette.text.secondary,
  },

  // Divider used between items
  [`& .${orgChartClasses.divider}`]: {
    minWidth: DIVIDER_WIDTH,
    height: 28,
    backgroundColor: theme.palette.divider,
  },

  // Container of the icon of an item
  [`& .${orgChartClasses.iconContainer}`]: {
    minWidth: NODE_ICON_SIZE,
    minHeight: NODE_ICON_SIZE,

    backgroundColor: theme.palette.grey['100'],
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      width: 12,
      height: 12,
      color: theme.palette.text.secondary,
    },
  },

  // Expand/collapse button rendered below a node when it has children
  [`& .${orgChartClasses.expandButton}`]: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    margin: 'auto auto',
    padding: theme.spacing(0.5, 1),
    whiteSpace: 'nowrap',
  },
}))

interface OrgChartProps {
  structure: TempLegalEntityStructureNode
}

export default function OrgChart({ structure }: OrgChartProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isExpanded, setIsExpanded] = useState(false)

  // Prepare date required to render and interact with the chart
  const { data, alwaysExpandedNodeIds } = useChartData(structure)
  const maxDepth = getDepth(structure)
  const chartRef = useInitializeChart(containerRef, data, alwaysExpandedNodeIds)
  const [tooltip, handleMouseMove, handleCloseTooltip] = useChartTooltip(chartRef, containerRef)

  const handleExpandAll = () => {
    // Expand all nodes and fit within the viewport
    chartRef.current.expandAll().fit()
    setIsExpanded(true)
  }

  const handleCollapseAll = () => {
    chartRef.current
      .collapseAll()
      .initialExpandLevel(1) // Re-expand the first level
      .initialZoom(1) // Reset zoom to 100%
      .render()

      // Fit result within the viewport
      .fit({
        scale: false,
      })
    setIsExpanded(false)
  }

  const handleZoomIn = () => {
    chartRef.current.zoomIn()
  }

  const handleZoomOut = () => {
    chartRef.current.zoomOut()
  }

  return (
    <StyledContainer onMouseLeave={handleCloseTooltip}>
      {
        // Tooltips are rendered by placing a div on the exact position of where
        // the tooltip should be made visible. This is a bit of a workaround
        // since we cannot render JSX within the actual chart. As a downside,
        // the exit animation will not work.
      }
      {tooltip && (
        <Tooltip
          title={tooltip?.content}
          disableInteractive
          open>
          <div
            style={{
              position: 'absolute',
              top: tooltip.top,
              left: tooltip.left,
              width: tooltip.width,
              height: tooltip.height,
            }}
          />
        </Tooltip>
      )}

      <StyledOrgChart
        ref={containerRef}
        onMouseMove={handleMouseMove}></StyledOrgChart>

      <StyledControlGroupsContainer>
        {/* Don't show expand/collapse all when the hierarchy has a max depth of
         2 or less*/}
        {maxDepth > 2 && (
          <StyledControlGroup>
            {!isExpanded && (
              <Tooltip
                enterDelay={ICON_TOOLTIP_DELAY}
                placement={ICON_TOOLTIP_PLACEMENT}
                title={'Expand'}
                disableInteractive>
                <StyledIconButton onClick={handleExpandAll}>
                  <ExpandIcon />
                </StyledIconButton>
              </Tooltip>
            )}
            {isExpanded && (
              <Tooltip
                enterDelay={ICON_TOOLTIP_DELAY}
                placement={ICON_TOOLTIP_PLACEMENT}
                title={'Collapse'}
                disableInteractive>
                <StyledIconButton onClick={handleCollapseAll}>
                  <CollapseIcon />
                </StyledIconButton>
              </Tooltip>
            )}
          </StyledControlGroup>
        )}

        <StyledControlGroup>
          <Tooltip
            enterDelay={ICON_TOOLTIP_DELAY}
            placement={ICON_TOOLTIP_PLACEMENT}
            title={'Zoom in'}
            disableInteractive>
            <StyledIconButton onClick={handleZoomIn}>
              <PlusIcon />
            </StyledIconButton>
          </Tooltip>
          <StyledControlDivider />
          <Tooltip
            enterDelay={ICON_TOOLTIP_DELAY}
            placement={ICON_TOOLTIP_PLACEMENT}
            title={'Zoom out'}
            disableInteractive>
            <StyledIconButton onClick={handleZoomOut}>
              <MinusIcon />
            </StyledIconButton>
          </Tooltip>
        </StyledControlGroup>
      </StyledControlGroupsContainer>
    </StyledContainer>
  )
}
