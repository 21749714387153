import { MoreHorizontalIcon } from '@gain/components/icons'
import { UserListItem } from '@gain/rpc/app-model'
import { RenderCellParams } from '@gain/utils/table'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'

import { useListView } from '../list-view'
import TeamMemberMenu from '../team-members-list/team-member-menu'

export default function UserListItemActionsTableCell({
  row,
}: RenderCellParams<UserListItem, 'userId'>) {
  const listView = useListView()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  return (
    <>
      <TeamMemberMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={(e) => {
          if ('stopPropagation' in e && typeof e.stopPropagation === 'function') {
            e.stopPropagation()
          }
          setAnchorEl(null)
        }}
        onUserUpdated={() => listView.refreshGrid()}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        user={row}
      />
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          setAnchorEl(event.currentTarget)
        }}
        size={'small'}>
        <MoreHorizontalIcon />
      </IconButton>
    </>
  )
}
