import { useCurrencyListItems } from '@gain/api/app/hooks'
import { useUserProfileContext } from '@gain/modules/user'
import { PropsWithChildren, useEffect } from 'react'

import { useBookmarkListLastViewedListener } from '../api/bookmark-list-last-viewed-hooks'
import { setUserProperties } from '../features/google-analytics'
import { useIdentifyPlanhat } from '../features/planhat/planhat-hooks'
import useSaveRecentlyFilteredBookmarks from './bookmarks/route-filtered/use-save-recently-filtered'

/**
 * Triggers requests to warm up the SWR cache
 */
export default function InitialDataLoader({ children }: PropsWithChildren) {
  // RouteListeners registers route change listeners that auto-update
  // backend state during certain navigation events.
  useBookmarkListLastViewedListener()
  useSaveRecentlyFilteredBookmarks()

  const currencyList = useCurrencyListItems()
  const userProfile = useUserProfileContext()

  useIdentifyPlanhat(userProfile)

  // Set Google Analytics and Planhat user properties
  useEffect(() => {
    setUserProperties(userProfile)

    return () => {
      // When leaving private routes clear user properties in GA and Planhat
      setUserProperties(null)
    }
  }, [userProfile])

  if (!userProfile || !currencyList || currencyList.loading) {
    // Don't show loader, it causes too many layout shifts and distracts the user
    return null
  }

  return children
}
