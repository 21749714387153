import { compareSourceDateDesc, Source } from '@gain/utils/source'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo } from 'react'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import { ListSourcesItem, ListSourcesTable } from '../source-list'

const StyledCard = styled(Card)({
  padding: 0,
  width: '100%',
})

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1, 0, 2),
}))

const StyledList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

interface CardSourcesProps {
  sources: Source[]
  title?: string
}

export default function CardSources({ sources, title = 'Web' }: CardSourcesProps) {
  const theme = useTheme()
  const showTable = useMediaQuery(theme.breakpoints.up(888))
  const sortedSources = useMemo(() => {
    return sources.slice().sort(compareSourceDateDesc)
  }, [sources])

  return (
    <StyledCard>
      <CardHeader title={title} />
      {showTable && <ListSourcesTable sources={sortedSources} />}
      {!showTable && (
        <StyledCardContent>
          <StyledList>
            {sortedSources.map((source, index) => (
              <ListSourcesItem
                key={index}
                source={source}
              />
            ))}
          </StyledList>
        </StyledCardContent>
      )}
    </StyledCard>
  )
}
