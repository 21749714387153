import Typography from '@gain/components/typography'
import { styled } from '@mui/material/styles'

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: theme.spacing(0.25),
}))

interface InvestorListItemFlagshipFundNameTableCellProps {
  value: string | null
  year?: number | null
}

export function InvestorListItemFlagshipFundNameTableCell({
  value,
  year,
}: InvestorListItemFlagshipFundNameTableCellProps) {
  if (value === null) {
    return (
      <Typography
        color={'text.secondary'}
        variant={'body2'}>
        Not Specified
      </Typography>
    )
  }

  return (
    <StyledContainer>
      <Typography
        color={'textPrimary'}
        variant={'subtitle2'}
        noWrap>
        {value}
      </Typography>
      {year && (
        <Typography
          color={'text.secondary'}
          variant={'body2'}>
          {year}
        </Typography>
      )}
    </StyledContainer>
  )
}
