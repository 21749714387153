import { AssetListItem } from '@gain/rpc/app-model'
import { AssetUpdateContext } from '@gain/utils/asset'
import { RenderCellParams } from '@gain/utils/table'
import { useContext } from 'react'

import { NameVirtualTableCell } from '../../../common/virtual-table/cells'

export function AssetListItemTableCellName({ row }: RenderCellParams<AssetListItem, 'name'>) {
  const ctx = useContext(AssetUpdateContext)
  const hasUpdates = ctx && ctx.hasUpdates(row)

  return (
    <NameVirtualTableCell
      description={row.description}
      logoDot={hasUpdates ? 'error' : undefined}
      logoFileUrl={row.logoFileUrl}
      name={row.name}
    />
  )
}
