import { ExcelIcon } from '@gain/components/icons'
import { AssetListItem, DealListItem, Investor } from '@gain/rpc/app-model'
import {
  listFilter,
  listFilters,
  listSort,
  listSortArray,
  serializeListSortArray,
} from '@gain/rpc/utils'
import Accordion from '@mui/material/Accordion'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'

import AccordionList from '../../../common/accordion/accordion-list'
import { ExportListButton } from '../../../features/export/export-button'
import { createInvestorPortfolioFilters } from '../investor-profile-util'

export const buildInvestorFileName = (investor: Investor, file: string) => {
  return `${investor.name} - Gain.pro ${file}`
}

export interface InvestorDownloadsProps {
  investor: Investor
  assets: AssetListItem[]
}

export default function InvestorDownloads({ investor, assets }: InvestorDownloadsProps) {
  return (
    <Stack width={'100%'}>
      <Accordion expanded>
        <AccordionList>
          <ListItem disableGutters>
            <ListItemIcon>
              <ExcelIcon />
            </ListItemIcon>

            <ListItemText primary={'Companies in Excel'} />

            <ExportListButton
              method={'data.exportAssets'}
              params={{
                columns: [],
                search: '',
                filter: createInvestorPortfolioFilters(investor),
                sort: serializeListSortArray(
                  listSortArray<AssetListItem>(listSort('financialsAt', 'desc'))
                ),
                filename: buildInvestorFileName(investor, 'companies.xlsx'),
              }}
              variant={'icon'}
              showMaxExportCount
            />
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon>
              <ExcelIcon />
            </ListItemIcon>

            <ListItemText primary={'Entry deals in Excel'} />

            <ExportListButton
              method={'data.exportDeals'}
              params={{
                filter: listFilters<DealListItem>(listFilter('buyerInvestorIds', '=', investor.id)),
                sort: serializeListSortArray(
                  listSortArray<DealListItem>(
                    listSort('announcementDate', 'desc'),
                    listSort('publicationDate', 'desc')
                  )
                ),
                columns: [],
                filename: buildInvestorFileName(investor, 'entry deals.xlsx'),
              }}
              variant={'icon'}
              showMaxExportCount
            />
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon>
              <ExcelIcon />
            </ListItemIcon>

            <ListItemText primary={'Exit deals in Excel'} />

            <ExportListButton
              method={'data.exportDeals'}
              params={{
                filter: listFilters<DealListItem>(
                  listFilter('sellerInvestorIds', '=', investor.id)
                ),
                sort: serializeListSortArray(
                  listSortArray<DealListItem>(
                    listSort('announcementDate', 'desc'),
                    listSort('publicationDate', 'desc')
                  )
                ),
                columns: [],
                filename: buildInvestorFileName(investor, 'exit deals.xlsx'),
              }}
              variant={'icon'}
              showMaxExportCount
            />
          </ListItem>

          <ListItem disableGutters>
            <ListItemIcon>
              <ExcelIcon />
            </ListItemIcon>

            <ListItemText primary={'Add-on deals in Excel'} />

            <ExportListButton
              method={'data.exportDeals'}
              params={{
                filter: listFilters<DealListItem>(
                  listFilter<DealListItem>(
                    'buyerAssetIds',
                    '=',
                    assets.map((item) => item.id)
                  )
                ),
                sort: serializeListSortArray(
                  listSortArray<DealListItem>(
                    listSort('announcementDate', 'desc'),
                    listSort('publicationDate', 'desc')
                  )
                ),
                columns: [],
                filename: buildInvestorFileName(investor, 'add-on deals.xlsx'),
              }}
              variant={'icon'}
              showMaxExportCount
            />
          </ListItem>
        </AccordionList>
      </Accordion>
    </Stack>
  )
}
