import OverflowContainer from '@gain/components/overflow-container'
import { Investor, InvestorStrategyListItem } from '@gain/rpc/app-model'
import {
  getLabelFromOption,
  INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS,
  INVESTOR_STRATEGY_PREFERRED_STAKE_OPTIONS,
  InvestorStrategyClassification,
} from '@gain/rpc/shared-model'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { formatPercentageRange } from '@gain/utils/number'
import Chip from '@mui/material/Chip'

import ContentLink from '../../../common/content-link'
import { horizontalTableClasses, RowGroupsConfig } from '../../../common/horizontal-table'
import { AssetRegionsTableCell } from '../../../features/asset/asset-regions-table-cell'
import { AssetSectorsTableCell } from '../../../features/asset/asset-sectors-table-cell'
import FinancialValue from '../../../features/financial/financial-value'
import { generateInvestorStrategyPagePath } from '../../investor-strategy'
import { DRY_POWDER_EXPLAINER } from '../investor-profile-util'

function renderClassificationChips(classifications: Array<InvestorStrategyClassification | null>) {
  return (
    <OverflowContainer
      lineHeight={16}
      maxLines={1}
      spacing={0.5}>
      {classifications.filter(Boolean).map((classification, index) => (
        <Chip
          key={index}
          label={getLabelFromOption(INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS, classification)}
          size={'small'}
        />
      ))}
    </OverflowContainer>
  )
}

export function useStrategiesGroups(investor: Investor) {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  return new Array<RowGroupsConfig<InvestorStrategyListItem>>(
    [
      {
        label: 'Strategy classification',
        bold: false,
        renderFn: (row) =>
          row.classifications.length > 0 ? renderClassificationChips(row.classifications) : '-',
      },
      {
        label: 'Latest fund',
        bold: false,
        valueFn: (row) => row.latestFundName,
      },
      {
        label: 'Latest fund size',
        renderFn: (row) => <FinancialValue amount={row.latestFundSizeEur} />,
      },
      {
        label: 'Vintage date',
        valueFn: (row) => row.latestFundVintageDateYear,
      },
      {
        label: 'Drypowder est.',
        explainer: DRY_POWDER_EXPLAINER,
        cellClassName: (row) => {
          if (row.dryPowderMinEur !== null && row.dryPowderMaxEur !== null) {
            return horizontalTableClasses.cellEstimate
          }

          return undefined
        },
        valueFn: (row) =>
          row.dryPowderMinEur !== null && row.dryPowderMaxEur !== null
            ? formatCurrencyRange(row.dryPowderMinEur, row.dryPowderMaxEur, {
                round: 'estimate',
              })
            : '',
      },
    ],

    [
      {
        label: 'Portfolio analysis',
      },
      {
        label: '# of companies',
        renderFn: (row) => (
          <ContentLink
            href={generateInvestorStrategyPagePath({
              investorId: investor.id,
              investorName: investor.name,
              strategyId: row.strategyId,
              strategyName: row.strategyName,
            })}
            label={`${row.assetsTotal}`}
          />
        ),
      },
      {
        label: 'Median EBITDA',
        renderFn: (row) => (
          <FinancialValue
            amount={row.assetEbitdaEurMedian === 0 ? null : row.assetEbitdaEurMedian}
          />
        ),
      },
      {
        label: 'Active in region',
        renderFn: (row) => (
          <AssetRegionsTableCell
            maxItems={5}
            regionNames={row.assetRegions}
            dense
          />
        ),
      },
      {
        label: 'Active in sector',
        renderFn: (row) => (
          <AssetSectorsTableCell
            maxItems={2}
            sectorNames={row.assetSectors}
            dense
          />
        ),
      },
    ],

    [
      {
        label: 'Self-declared',
      },
      {
        label: 'Inv. ticket size',
        valueFn: (row) =>
          formatCurrencyRange(row.investmentTicketSizeMinEur, row.investmentTicketSizeMaxEur),
      },
      {
        label: 'EV range',
        valueFn: (row) => formatCurrencyRange(row.evRangeMinEur, row.evRangeMaxEur),
      },
      {
        label: 'EBITDA range',
        valueFn: (row) => formatCurrencyRange(row.ebitdaRangeMinEur, row.ebitdaRangeMaxEur),
      },
      {
        label: 'Revenue range',
        valueFn: (row) => formatCurrencyRange(row.revenueRangeMinEur, row.revenueRangeMaxEur),
      },
      {
        label: 'Equity stake',
        valueFn: (row) =>
          formatPercentageRange(row.preferredEquityStakeMinPct, row.preferredEquityStakeMaxPct),
      },
      {
        label: 'Preferred stake',
        formatFn: (value) =>
          getLabelFromOption(INVESTOR_STRATEGY_PREFERRED_STAKE_OPTIONS, value) || '-',
        // Only return the preferredStake option when one of the other values in the group exists; this is to prevent
        // we show a group with only "-" everywhere because preferredStake has a default value
        valueFn: (row) =>
          row.investmentTicketSizeMinEur !== null ||
          row.investmentTicketSizeMaxEur !== null ||
          row.evRangeMinEur !== null ||
          row.evRangeMaxEur !== null ||
          row.ebitdaRangeMinEur !== null ||
          row.ebitdaRangeMaxEur !== null ||
          row.revenueRangeMinEur !== null ||
          row.preferredEquityStakeMaxPct !== null
            ? row.preferredStake
            : null,
      },
    ]
  )
}
