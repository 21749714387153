import { noop } from '@gain/utils/common'
import Box from '@mui/material/Box'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import clsx from 'clsx'
import { MouseEvent, Suspense, useCallback } from 'react'

import { MobileListSkeleton } from '../../../../common/list-mobile'
import SearchField from '../search/search-field'
import ArticleList from './article-list'
import IndustryList from './industry-list'
import Logo from './logo'
import UserAvatar from './user-avatar'

export const LOGO_HEIGHT = 74

const newsClasses = generateUtilityClasses('News', ['stickySearch'])

const StyledLogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  padding: theme.spacing(3, 3, 2, 3),
  textAlign: 'center',
}))

const StyledSearchContainer = styled('div')(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  position: 'sticky',
  top: 0,
  padding: theme.spacing(1, 3, 1),
  borderBottom: '1px solid transparent',
  backgroundColor: 'white',
  transition: theme.transitions.create('border-bottom-color', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  [`&.${newsClasses.stickySearch}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

export interface NewsProps {
  onSearchClick?: () => void
}

export default function News({ onSearchClick = noop }: NewsProps) {
  const stuck = useScrollTrigger({
    threshold: LOGO_HEIGHT,
    disableHysteresis: true,
  })

  const handleSearchClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      onSearchClick()
    },
    [onSearchClick]
  )

  return (
    <div>
      <StyledLogoContainer>
        <Box sx={{ width: 40 }} />
        <Logo />
        <UserAvatar />
      </StyledLogoContainer>
      <StyledSearchContainer
        className={clsx({
          [newsClasses.stickySearch]: stuck,
        })}>
        <SearchField
          onClick={handleSearchClick}
          disableClear
        />
      </StyledSearchContainer>
      <Suspense
        fallback={
          <MobileListSkeleton
            nrOfItems={3}
            title={'Latest industries'}
          />
        }>
        <IndustryList />
      </Suspense>
      <Suspense
        fallback={
          <MobileListSkeleton
            nrOfItems={10}
            title={'In the news'}
          />
        }>
        <ArticleList />
      </Suspense>
    </div>
  )
}
