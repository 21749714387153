import { InfoIcon } from '@gain/components/icons'
import { useDialogState } from '@gain/utils/dialog'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import AiExplainerDialog, { AIExplainerFinancial } from './ai-explainer-dialog'

const StyledStack = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(1),
}))

interface FilterAIFinancialExplainerProps {
  financial: AIExplainerFinancial
}

export default function FilterAiFinancialExplainer({ financial }: FilterAIFinancialExplainerProps) {
  const [isOpen, handleOpen, handleClose] = useDialogState()

  return (
    <>
      <StyledStack onClick={handleOpen}>
        <Chip
          color={'warning'}
          label={'AI'}
          size={'small'}
        />
        <Typography variant={'body2'}>Include AI estimates</Typography>
        <InfoIcon
          color={'inherit'}
          fontSize={'inherit'}
        />
      </StyledStack>
      <AiExplainerDialog
        financial={financial}
        handleClose={handleClose}
        isOpen={isOpen}
      />
    </>
  )
}
