import { ShuffleIcon } from '@gain/components/icons'
import { TooltipHeader } from '@gain/components/tooltip'
import { AssetListItem, Deal } from '@gain/rpc/app-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { formatMonthYear } from '@gain/utils/date'
import { formatDealFundingSeriesLabel } from '@gain/utils/deal'
import { formatNumber } from '@gain/utils/number'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { DetailedHTMLProps, HTMLAttributes, useCallback } from 'react'

import { KeyValueList, KeyValueListItem } from '../../../common/key-value/key-value-list'
import DealMetricListItem from './deal-metric-list-item.component'

type DealTargetMetrics = Pick<Deal, 'revenue' | 'ebitda' | 'ebit' | 'totalAssets' | 'fte'>
type DealTargetMetricKey = keyof DealTargetMetrics

const dealTargetMetricKeys: DealTargetMetricKey[] = [
  'revenue',
  'ebitda',
  'ebit',
  'totalAssets',
  'fte',
]

function hasDealTargetMetrics(deal: Deal) {
  return dealTargetMetricKeys.findIndex((key) => deal[key] !== null) > -1
}

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  minWidth: 320,
  width: 320,
}))

type TooltipDealTitleProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  asset: AssetListItem | null
  deal: Deal
}

export default function TooltipDealTitle({ asset, deal, ...props }: TooltipDealTitleProps) {
  const formatCurrency = useFormatCurrencyCallback()

  const financialFormatter = useCallback(
    (value: number) => formatCurrency(value, { dataCurrency: deal.currency ?? undefined }),
    [formatCurrency, deal]
  )
  const fteFormatter = useCallback((value: number) => formatNumber(value), [])

  const hasOverview =
    deal.fundingRoundType !== null ||
    deal.fundingRoundPreMoneyValuation !== null ||
    deal.ev !== null

  const hasMetrics = hasDealTargetMetrics(deal)

  return (
    <div {...props}>
      <StyledContainer
        direction={'column'}
        spacing={2}>
        <TooltipHeader
          defaultIcon={<ShuffleIcon />}
          logoSrc={asset?.logoFileUrl}
          subtitle={formatMonthYear(deal.announcementDate?.month, deal.announcementDate?.year)}
          title={`Deal - ${deal.asset}`}
        />

        {hasOverview && (
          <KeyValueList
            spacing={0.5}
            dense>
            <Typography variant={'subtitle2'}>Overview</Typography>

            {deal.fundingRoundType !== null && (
              <KeyValueListItem
                label={'Funding round'}
                value={formatDealFundingSeriesLabel(deal.fundingRoundType)}
              />
            )}

            {deal.fundingRoundPreMoneyValuation !== null && (
              <DealMetricListItem
                formatter={financialFormatter}
                label={'Pre-money valuation'}
                value={deal.fundingRoundPreMoneyValuation}
              />
            )}

            {deal.ev !== null && (
              <DealMetricListItem
                formatter={financialFormatter}
                label={'EV'}
                value={deal.ev}
              />
            )}
          </KeyValueList>
        )}

        {hasOverview && hasMetrics && <Divider />}

        {hasMetrics && (
          <KeyValueList
            maxItems={4}
            spacing={0.5}
            dense>
            <Typography variant={'subtitle2'}>Target at time of deal</Typography>

            {deal.revenue !== null && (
              <DealMetricListItem
                formatter={financialFormatter}
                label={'Revenue'}
                value={deal.revenue}
              />
            )}

            {deal.ebitda !== null && (
              <DealMetricListItem
                formatter={financialFormatter}
                label={'EBITDA'}
                value={deal.ebitda}
              />
            )}

            {deal.ebit !== null && (
              <DealMetricListItem
                formatter={financialFormatter}
                label={'EBIT'}
                value={deal.ebit}
              />
            )}

            {deal.totalAssets !== null && (
              <DealMetricListItem
                formatter={financialFormatter}
                label={'Total assets'}
                value={deal.totalAssets}
              />
            )}

            {deal.fte !== null && (
              <DealMetricListItem
                formatter={fteFormatter}
                label={'FTEs'}
                value={deal.fte}
              />
            )}
          </KeyValueList>
        )}
      </StyledContainer>
    </div>
  )
}
