import { useAssetList } from '@gain/api/app/hooks'
import { ChevronDownIcon, XIcon } from '@gain/components/icons'
import { AssetListItem, DealCloudItem, DealCloudStatus } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Stack, { StackProps } from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import { forwardRef, Fragment, useCallback, useMemo } from 'react'

import DealCloudLink from './dealcloud-link'

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
  width: 434,
}))

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  flexDirection: 'row-reverse',
  backgroundColor: theme.palette.common.white,

  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    marginRight: 'auto',
  },

  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: 12,
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  maxHeight: 265,
  overflowY: 'auto',
  backgroundColor: theme.palette.grey['50'],
  paddingBottom: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(2),
}))

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.grey['200']}`,
  padding: theme.spacing(0, 2),
}))

interface DealCloudResultsProps {
  snackbarKey: string
  dealCloudItems: DealCloudItem[]
}

const DealCloudResults = forwardRef<HTMLDivElement, DealCloudResultsProps>(function Snackbar(
  { snackbarKey, dealCloudItems },
  ref
) {
  const { closeSnackbar } = useSnackbar()
  const handleCloseSnackbar = useCallback(() => {
    closeSnackbar(snackbarKey)
  }, [closeSnackbar, snackbarKey])

  const assetIds = useMemo(() => dealCloudItems.map(({ assetId }) => assetId), [dealCloudItems])
  const {
    data: { items: assets },
  } = useAssetList({
    page: 0,
    limit: 100,
    filter: listFilters<AssetListItem>(assetIds.length !== 0 && listFilter('id', '=', assetIds)),
  })

  const results = useMemo(
    () =>
      [
        {
          message: 'Newly created profiles',
          items: dealCloudItems.filter(({ status }) => DealCloudStatus.Created === status),
        },
        {
          message: 'Existing profiles',
          items: dealCloudItems.filter(
            ({ status }) =>
              DealCloudStatus.Updated === status || DealCloudStatus.Existing === status
          ),
        },
        {
          message: 'Failed to create profiles',
          items: dealCloudItems.filter(({ status }) => DealCloudStatus.Failed === status),
        },
      ].filter(({ items }) => items.length !== 0),
    [dealCloudItems]
  )

  return (
    <StyledAccordion ref={ref}>
      <StyledStack>
        <StyledAccordionSummary expandIcon={<ChevronDownIcon fontSize={'small'} />}>
          <Typography
            color={'textPrimary'}
            variant={'body2'}
            noWrap>
            Exported {dealCloudItems.length} companies to DealCloud
          </Typography>
        </StyledAccordionSummary>
        <IconButton
          onClick={handleCloseSnackbar}
          size={'small'}>
          <XIcon />
        </IconButton>
      </StyledStack>
      <StyledAccordionDetails>
        {results.map(({ message, items }, index) => (
          <Fragment key={message}>
            <Typography
              color={'textPrimary'}
              pl={1}
              variant={'subtitle2'}
              noWrap>
              {message}
            </Typography>
            <List dense>
              {items.map((item) => (
                <ListItem
                  key={item.assetId}
                  disablePadding>
                  <DealCloudLink
                    asset={assets && assets.find(({ id }) => id === item.assetId)}
                    dealCloudItem={item}
                  />
                </ListItem>
              ))}
            </List>
            {index + 1 < results.length && <Divider sx={{ mb: 1, width: '100%' }} />}
          </Fragment>
        ))}
      </StyledAccordionDetails>
    </StyledAccordion>
  )
})

export default DealCloudResults
