import { DealFactFloat } from '@gain/rpc/app-model'

import YearChip from '../../../common/year-chip'
import DealMetricValue from './deal-metric-value.component'

export interface DealMetricProps {
  value: DealFactFloat
  formatter: (value: number) => string
}

export default function DealMetric({ value, formatter }: DealMetricProps) {
  return (
    <>
      <DealMetricValue confidence={value.confidence}>{formatter(value.value)}</DealMetricValue>
      <YearChip
        sx={{ ml: 1 }}
        year={value.date?.year}
      />
    </>
  )
}
