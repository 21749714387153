import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { useTableRowHoverContext } from '@gain/utils/table'

import Sparkline, { mapSparklineValues } from '../../../../common/sparkline'
import FinancialValue from '../../../financial/financial-value'

export type FinancialSparklineTableCellProps = {
  year: number | null
  years: number[]
  results: number[]
  financial: number | null
  isAiGenerated?: boolean
}

export function FinancialTableCell({
  year,
  years,
  results,
  financial,
  isAiGenerated,
}: FinancialSparklineTableCellProps) {
  const formatCurrency = useFormatCurrencyCallback()
  const hover = useTableRowHoverContext()

  if (hover) {
    const financials = mapSparklineValues(years, results)

    if (financials && financial) {
      return (
        <Sparkline
          label={formatCurrency(financial, { disablePrefix: true })}
          values={financials}
        />
      )
    }
  }

  return (
    <FinancialValue
      amount={financial}
      isAiGenerated={isAiGenerated}
      year={year}
    />
  )
}
