import Head from '@gain/modules/head'
import { styled } from '@mui/material/styles'
import { useCallback } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { MOBILE_NAVIGATION_HEIGHT } from '../../../layout/mobile-navigation'
import News from './news'
import SearchResults from './search'

const MOBILE_HOME_PATH = '/home'
export const MOBILE_SEARCH_EMPTY_PATH = '/home/search'

export interface MobileSearchParams {
  query: string
}

export const MOBILE_SEARCH_PATH = '/home/search/:query'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  flex: 1,
  minHeight: `calc(100vh - ${MOBILE_NAVIGATION_HEIGHT}px)`,
}))

export default function HomeMobile() {
  const history = useHistory()
  const handleSearchClick = useCallback(() => {
    history.push('/home/search')
  }, [history])

  return (
    <>
      <Head>
        <title>Home</title>
      </Head>
      <StyledRoot>
        <Switch>
          <Route
            component={SearchResults}
            path={'/home/search'}
          />
          <Route
            path={'/home'}
            exact>
            <News onSearchClick={handleSearchClick} />
          </Route>
          <Route path={'*'}>
            <Redirect to={MOBILE_HOME_PATH} />
          </Route>
        </Switch>
      </StyledRoot>
    </>
  )
}
