import LegalEntityNode from './LegalEntityNode'
import { isLegalEntityNode, isShareholdersNode } from './org-chart-model'
import { orgChartClasses } from './org-chart-utils'
import ShareholdersNode from './ShareholdersNode'

interface NodeContentProps {
  d
}

export default function NodeContent({ d }: NodeContentProps) {
  return (
    <div
      className={orgChartClasses.node}
      data-node-id={d.id}>
      {isShareholdersNode(d.data) && <ShareholdersNode node={d.data} />}
      {isLegalEntityNode(d.data) && <LegalEntityNode node={d.data} />}
    </div>
  )
}
