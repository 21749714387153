import Head from '@gain/modules/head'
import { DealListItem, InvestorFundListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'

import { useTrackPageView } from '../../../features/planhat/planhat-hooks'
import InvestorDealsList from '../../investor/investor-deals-list'

export interface RouteExistsProps {
  fund: InvestorFundListItem
}

export default function RouteExits({ fund }: RouteExistsProps) {
  useTrackPageView('investor_fund', {
    id: fund.id,
    tab: 'exits',
    requireTab: true,
  })

  return (
    <>
      <Head>
        <title>
          {fund.investorName} - {fund.strategyName || ''} - {fund.name} - Exits
        </title>
      </Head>

      <InvestorDealsList
        defaultFilters={listFilters<DealListItem>(
          listFilter('sellerInvestorIds', '=', fund.investorId),
          listFilter('sellerFundIds', '=', fund.id)
        )}
      />
    </>
  )
}
