import { AssetListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { useState } from 'react'

import Card, { CardHeader } from '../../common/card/card'
import SelectMenu from '../../common/select-menu/select-menu'
import { useTrackEvent } from '../google-analytics'
import AssetValuationTable from './asset-valuation-table'
import { useValuationPeriodOptions, ValuationPeriod } from './valuation-util'

interface ValuationCardProps {
  title: string
  assets: AssetListItem[]
  similarToAssetName?: string
  initialSort: ListSort<AssetListItem>[]
}

export default function ValuationCard({
  title,
  assets,
  similarToAssetName,
  initialSort,
}: ValuationCardProps) {
  const trackEvent = useTrackEvent()

  const [valuationPeriod, setValuationPeriod] = useState<ValuationPeriod>('LastTwelveMonths')
  const valuationPeriodOptions = useValuationPeriodOptions()

  return (
    <Card>
      <CardHeader
        actions={
          <SelectMenu
            label={'Valuation period'}
            onChange={(value) => {
              setValuationPeriod(value)
              trackEvent('Set valuation period', 'Valuation table', value)
            }}
            options={valuationPeriodOptions}
            sx={{ mr: -0.5 }}
            value={valuationPeriod}
          />
        }
        title={title}
      />
      <AssetValuationTable
        assets={assets}
        initialSort={initialSort}
        period={valuationPeriod}
        similarToAssetName={similarToAssetName}
      />
    </Card>
  )
}
