import { useAssetList } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { AssetListItem, DealListItem, InvestorFundListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'

import { useTrackPageView } from '../../../features/planhat/planhat-hooks'
import InvestorDealsList from '../../investor/investor-deals-list'

export interface RouteAddOnsProps {
  fund: InvestorFundListItem
}

export default function RouteAddOns({ fund }: RouteAddOnsProps) {
  useTrackPageView('investor_fund', {
    id: fund.id,
    tab: 'add-ons',
    requireTab: true,
  })

  const assets = useAssetList({
    filter: listFilters<AssetListItem>(
      listFilter('ownerIds', '=', fund.investorId),
      listFilter('fundIds', '=', fund.id)
    ),
    sort: [{ field: 'ebitda', direction: 'desc' }],
    limit: 500,
  })

  return (
    <>
      <Head>
        <title>
          {fund.investorName} - {fund.strategyName || ''} - {fund.name} - Add-ons
        </title>
      </Head>

      <InvestorDealsList
        defaultFilters={[
          listFilter<DealListItem>(
            'buyerAssetIds',
            '=',
            assets.data.items.map((item) => item.id)
          ),
        ]}
      />
    </>
  )
}
