import { useAdvisorListItem, useAssetListItem } from '@gain/api/app/hooks'
import { AdvisorDealListItem } from '@gain/rpc/app-model'
import { DealAdvisorAdvised } from '@gain/rpc/shared-model'
import { listAndFilter, listFilter, listFilters, listOrFilter } from '@gain/rpc/utils'

import ListPageLayout from '../../layout/list-page-layout'
import ListViewAdvisorDeals from '../advisor/advisor-deals/list-view-advisor-deals'
import { useAdvisorAssetClientsPageParams } from '../utils'

/**
 * AdvisorAssetDeals displays a list of deals for which the
 * advisor advised a particular company.
 */
export default function AdvisorAssetDeals() {
  const { advisorId, assetId } = useAdvisorAssetClientsPageParams()

  const swrAdvisor = useAdvisorListItem(advisorId)
  const swrAsset = useAssetListItem(assetId)

  const advisorName = swrAdvisor.data?.name || '-'
  const assetName = swrAsset.data?.name || '-'

  const defaultFilters = listFilters<AdvisorDealListItem>(
    listOrFilter(
      listFilters(
        listAndFilter(
          listFilters(
            listFilter('advisorId', '=', advisorId),
            listFilter('linkedAssetId', '=', assetId)
          )
        ),
        listAndFilter(
          listFilters(
            listFilter('advisorId', '=', advisorId),
            listFilter('advised', '=', DealAdvisorAdvised.Buyers),
            listFilter('buyerAssetIds', '=', assetId)
          )
        ),
        listAndFilter(
          listFilters(
            listFilter('advisorId', '=', advisorId),
            listFilter('advised', '=', DealAdvisorAdvised.Sellers),
            listFilter('sellerAssetIds', '=', assetId)
          )
        )
      )
    )
  )

  if (swrAdvisor.loading || swrAsset.loading) {
    // The list view already shows a spinner so in order to avoid duplicate
    // spinners, we render an empty page while the asset and advisor are loading
    return null
  }

  const title = `${assetName} deals - advised by ${advisorName}`

  return (
    <ListPageLayout title={title}>
      <ListViewAdvisorDeals
        advisorName={advisorName}
        defaultFilter={defaultFilters}
      />
    </ListPageLayout>
  )
}
