import Typography from '@gain/components/typography'
import { AssetOwnershipType } from '@gain/rpc/shared-model'

import { CardProps } from '../../../common/card/card'
import InfoButton from '../../../common/info-button'
import AssetOwnershipUnverifiedChip from './asset-ownership-unverified-chip'
import { formatAssetOwnershipType } from './format-asset-ownership-type'
import { OWNERSHIP_TYPES } from './index'

interface AssetOwnershipTypeExplainerProps extends CardProps {
  ownershipType?: AssetOwnershipType | null
  isVerified?: boolean // When false it will show an unverified chip; defaults to true
  disableExplainer?: boolean // When true it won't show the (i) icon; defaults to false
}

export default function AssetOwnershipTypeExplainer({
  ownershipType,
  isVerified = true,
  disableExplainer = false,
}: AssetOwnershipTypeExplainerProps) {
  return (
    <Typography
      color={isVerified ? 'text.primary' : 'text.secondary'}
      variant={'body2'}>
      {formatAssetOwnershipType(ownershipType)}

      {!isVerified && ownershipType && (
        <AssetOwnershipUnverifiedChip ownershipType={ownershipType} />
      )}

      {isVerified && ownershipType && OWNERSHIP_TYPES[ownershipType] && !disableExplainer && (
        <InfoButton
          dialogMessage={OWNERSHIP_TYPES[ownershipType].explainer}
          dialogTitle={OWNERSHIP_TYPES[ownershipType].title}
          sx={{ ml: 0.5 }}
        />
      )}
    </Typography>
  )
}
