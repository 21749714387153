// This is used to map filters to the investor table column name that do
// not map 1-to-1 with a table column field.
import { FilterableInvestorFundListItem } from '@gain/rpc/app-model'

const filterToInvestorFundTableColumnMapping: Partial<
  Record<keyof FilterableInvestorFundListItem, Array<keyof FilterableInvestorFundListItem>>
> = {
  assetsFiltered: ['assetsTotal'],
  assetSubsectors: ['assetSectors'],
  'asset.ebitdaEur': ['assetEbitdasEur'],
}

export default filterToInvestorFundTableColumnMapping
