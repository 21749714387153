import { LoadingButtonProps } from '@mui/lab/LoadingButton'

import { ExportContainerProps } from './export-container'
import ExportListContainer, { ExportListContainerMethod } from './export-list-container'
import ExportLoadingButton, { ExportLoadingButtonProps } from './export-loading-button'
import { ExportMethodMap } from './export-method-config'

export interface ExportListButtonProps<
  Method extends ExportListContainerMethod,
  Params extends ExportMethodMap[Method]['params']
> extends Omit<ExportContainerProps<Method, Params>, 'children'> {
  color?: LoadingButtonProps['color']
  variant?: ExportLoadingButtonProps['variant']
  tooltipMode?: ExportLoadingButtonProps['tooltipMode']
}

/**
 * The ExportListButton component is a button used for table exports. We
 * only allow to download the first 100 rows of the table; if there are
 * more we show a snackbar with a message to contact customer support
 * for the full export.
 */
export default function ExportListButton<
  Method extends ExportListContainerMethod,
  Params extends ExportMethodMap[Method]['params']
>({ variant, color, tooltipMode, ...props }: ExportListButtonProps<Method, Params>) {
  return (
    <ExportListContainer {...props}>
      {(api) => (
        <ExportLoadingButton
          {...api}
          color={color}
          tooltipMode={tooltipMode}
          variant={variant}
        />
      )}
    </ExportListContainer>
  )
}
