import { InvestorStrategyListItem } from '@gain/rpc/app-model'

export function sortStrategies(
  strategyA: InvestorStrategyListItem,
  strategyB: InvestorStrategyListItem
) {
  // If one of the strategies does not have any linked assets, push it back
  if (strategyA.assetsTotal !== 0 && strategyB.assetsTotal === 0) {
    return -1
  } else if (strategyB.assetsTotal !== 0 && strategyA.assetsTotal === 0) {
    return 1
  }

  // If one of the strategies does not have a fund size, push it back
  if (!strategyA.latestFundSizeEur) {
    return 1
  } else if (!strategyB.latestFundSizeEur) {
    return -1
  }

  // All above is not true, check which strategy has the biggest
  return strategyB.latestFundSizeEur > strategyA.latestFundSizeEur ? 1 : -1
}
