import { Asset } from '@gain/rpc/app-model'

import Card, { CardHeader } from '../../../../common/card/card'
import { CardAssetBusinessDescriptionTabs } from './card-asset-business-description-tabs'

export interface CardAssetBusinessDescriptionProps {
  asset: Asset
}

export default function CardAssetBusinessDescription({ asset }: CardAssetBusinessDescriptionProps) {
  return (
    <Card
      sx={{ minHeight: 398 }}
      fullHeight>
      <CardHeader title={'Business description'} />
      <CardAssetBusinessDescriptionTabs asset={asset} />
    </Card>
  )
}
