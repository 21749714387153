import Head from '@gain/modules/head'
import { AssetListItem, Investor, InvestorStrategyListItem } from '@gain/rpc/app-model'

import Benchmarking from '../../../features/benchmarking'
import { BenchmarkingContextProvider } from '../../../features/benchmarking/benchmarking-context'

export interface RouteBenchmarkingProps {
  investor: Investor
  assets: AssetListItem[]
  strategies: InvestorStrategyListItem[]
}

export default function RouteBenchmarking({
  assets,
  investor,
  strategies,
}: RouteBenchmarkingProps) {
  const name = investor.name

  return (
    <>
      <Head>
        <title>{name} - Benchmarking</title>
      </Head>

      <BenchmarkingContextProvider
        value={{
          name,
          objectId: investor.id,
          objectType: 'investor',
        }}>
        <Benchmarking
          initialAssets={assets}
          investorStrategies={strategies}
          loadingInitialAssets={false}
        />
      </BenchmarkingContextProvider>
    </>
  )
}
