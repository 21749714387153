import { ListItemKey } from '@gain/rpc/list-model'
import { MouseEvent, useCallback, useEffect, useState } from 'react'

import { FilterCheckboxListValue, OptionValueType } from '../../filter-config/filter-config-model'
import { useFilterContext } from '../../filter-context'

export function useCheckboxListFilterToggle<
  ValueType extends OptionValueType,
  Item extends object,
  Field extends ListItemKey<Item>
>(currentValue: OptionValueType[], field?: Field) {
  const filterApi = useFilterContext<Item, Field>()
  const [active, setActive] = useState<ValueType[]>([])

  const handleClick = useCallback(
    (clickedValues: ValueType[]) => {
      return (event: MouseEvent) => {
        event.stopPropagation()
        event.preventDefault()

        if (!filterApi || !field) {
          return
        }

        filterApi.addOrUpdate(
          field,
          (filterValue: FilterCheckboxListValue): FilterCheckboxListValue => {
            if (!filterValue) {
              return clickedValues
            }

            const nextFilterValue = clickedValues.reduce((acc, current) => {
              if (acc.includes(current)) {
                return acc.filter((v) => v !== current)
              }

              return acc.concat(current)
            }, filterValue)

            if (nextFilterValue.length === 0) {
              return null
            }

            return nextFilterValue
          }
        )
      }
    },
    [field, filterApi]
  )

  useEffect(() => {
    if (!filterApi || !field) {
      return
    }

    return filterApi.subscribe((model) => {
      setActive((prev) => {
        const next = currentValue.filter((item) =>
          model.some((group) =>
            group.value.some((filter) => {
              if (filter.filterId === field && filter.value) {
                const filterValue: FilterCheckboxListValue = filter.value
                return filterValue?.includes(item)
              }

              return false
            })
          )
        )

        if (JSON.stringify(next) === JSON.stringify(prev)) {
          return prev
        }

        return next as ValueType[]
      })
    })
  }, [field, filterApi, currentValue])

  return [active, handleClick] as const
}
