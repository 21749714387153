import Head from '@gain/modules/head'
import { Investor, InvestorStrategyListItem } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid2'

import Card, { CardHeader } from '../../../common/card/card'
import InvestorFundPerformanceSources from '../../../features/investor-fund/investor-fund-performance-sources'
import InvestorFundraisingCard from './investor-fundraising-card'
import InvestorFundsTable from './investor-funds-table'

export interface RouteFundsProps {
  investor: Investor
  strategies: InvestorStrategyListItem[]
}

export default function RouteFunds({ investor, strategies }: RouteFundsProps) {
  return (
    <Grid
      spacing={2}
      sx={{ alignSelf: 'stretch' }}
      container>
      <Head>
        <title>{investor?.name || ''} - Funds</title>
      </Head>

      <Grid size={12}>
        <InvestorFundraisingCard
          investor={investor}
          strategies={strategies}
        />
      </Grid>

      <Grid size={12}>
        <Card sx={{ pb: 0, overflow: 'clip' }}>
          <CardHeader title={'Funds'} />
          <InvestorFundsTable
            funds={investor.funds}
            investor={investor}
            strategies={strategies}
          />
        </Card>
      </Grid>

      <InvestorFundPerformanceSources funds={investor.funds} />
    </Grid>
  )
}
