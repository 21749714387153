import Typography from '@gain/components/typography'
import { AssetListItem, BookmarkListItem } from '@gain/rpc/app-model'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'

import {
  BookmarkAssetListItem,
  isAssetBookmarkList,
  useSortedBookmarkLists,
} from '../../../api/bookmark-list-hooks'
import Skeleton from '../../../common/skeleton'
import { ASSET_FILTER_MAP, AssetFilterField } from '../../asset/asset-filter-bar'
import { FilterModel, toFilterModel } from '../../filter/filter-bar'
import RecentFilterListItem from './recent-filter-list-item'

const NR_OF_ITEMS_COLLAPSED = 3

const StyledRecentFilterTitle = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  paddingLeft: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))

const StyledViewMoreButton = styled(Button)({
  alignSelf: 'start',
})

interface ListAndFilterModel {
  filterModel?: FilterModel<AssetListItem, AssetFilterField>
  listItem: BookmarkAssetListItem | null
  recentFilterList?: BookmarkAssetListItem
  isReady?: boolean
}

interface RecentFilterListProps {
  recentFilterLists: (BookmarkAssetListItem | null)[]
  onClick?: () => void
}

export default function RecentFilterList({ recentFilterLists, onClick }: RecentFilterListProps) {
  const [showAll, setShowAll] = useState(false)
  const [showSkeleton, setShowSkeleton] = useState(false)
  const savedLists = useSortedBookmarkLists()
  const [listState, setListState] = useState<ListAndFilterModel[]>([])

  useEffect(() => {
    const listFilterModels = recentFilterLists.map((recentFilterList) => {
      if (!recentFilterList) {
        return { listItem: null }
      }

      // If there is saved list with identical filters use that instead
      const listItem: BookmarkAssetListItem =
        savedLists
          .filter(isAssetBookmarkList)
          .find((item) => isEqual(item.filters, recentFilterList.filters)) ?? recentFilterList

      // Remove empty filters
      const filterModel = toFilterModel(listItem.filters, ASSET_FILTER_MAP)

      return {
        listItem,
        filterModel,
        recentFilterList: recentFilterList,
        isReady: false,
      }
    })

    // Update ready state; any previously loaded list should be marked as ready
    // and the rest should be marked as not ready. This prevents flickering when
    // the list is expanded for lists that are already loaded.
    setListState((prev) => {
      return listFilterModels.map((listFilterModel) => {
        const found = prev.find(
          (item) => item.recentFilterList?.id === listFilterModel.recentFilterList?.id
        )
        return { ...listFilterModel, ready: found?.isReady ?? false }
      })
    })
  }, [recentFilterLists, savedLists])

  // Only show a "global" skeleton for the collapsed list; an expanded list
  // is triggered manually and loads remaining items individually.
  //
  // If the skeleton was disabled at some point in the past never re-enable
  // it; instead individual items will handle their own loading state.
  useEffect(() => {
    setShowSkeleton(
      (prev) => prev && listState.slice(0, NR_OF_ITEMS_COLLAPSED).some((item) => !item.isReady)
    )
  }, [listState])

  // Mark list as ready
  const handleLoad = (list: BookmarkListItem) => {
    setListState((prev) => {
      const idx = prev.findIndex((item) => item.listItem?.id === list.id)
      if (idx > -1 && !prev[idx].isReady) {
        const result = [...prev]
        result[idx] = { ...prev[idx], isReady: true }
        return result
      }
      return prev
    })
  }

  if (recentFilterLists.length === 0) {
    return null
  }

  return (
    <Stack>
      <Skeleton enabled={showSkeleton}>
        <StyledRecentFilterTitle
          color={'text.secondary'}
          variant={'body2'}>
          Recently filtered
        </StyledRecentFilterTitle>
      </Skeleton>

      {listState.slice(0, NR_OF_ITEMS_COLLAPSED).map(({ isReady, ...other }, listIndex) => (
        <RecentFilterListItem
          key={listIndex}
          onClick={onClick}
          onLoad={handleLoad}
          showSkeleton={showSkeleton}
          {...other}
        />
      ))}

      <Collapse in={showAll}>
        {listState.slice(NR_OF_ITEMS_COLLAPSED).map(({ isReady, ...other }, listIndex) => (
          <RecentFilterListItem
            key={listIndex}
            onClick={onClick}
            {...other}
          />
        ))}
      </Collapse>

      {listState.length > NR_OF_ITEMS_COLLAPSED && (
        <Skeleton enabled={showSkeleton}>
          <StyledViewMoreButton
            color={'primary'}
            onClick={() => setShowAll((prev) => !prev)}
            variant={'text'}>
            {showAll ? 'View less' : 'View more'}
          </StyledViewMoreButton>
        </Skeleton>
      )}
    </Stack>
  )
}
