import { isDefined } from '@gain/utils/common'
import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions, { dialogActionsClasses } from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { ComponentType, useState } from 'react'

import DialogHeader from '../../common/dialog-header'
import AIExplainerEbitdaContent from './ai-explainer-ebitda-content'
import AIExplainerRevenueContent from './ai-explainer-revenue-content'

const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  [`& .${dialogActionsClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
  },
}))

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(0, 3),
}))

export enum AIExplainerFinancial {
  Revenue,
  EBITDA,
}

interface AIExplainerTabData {
  title: string
  component: ComponentType
}

const tabLookup: Record<AIExplainerFinancial, AIExplainerTabData> = {
  [AIExplainerFinancial.Revenue]: {
    title: 'Revenue AI estimates',
    component: AIExplainerRevenueContent,
  },
  [AIExplainerFinancial.EBITDA]: {
    title: 'EBITDA AI estimates',
    component: AIExplainerEbitdaContent,
  },
}

type AIExplainerDialogProps = {
  isOpen: boolean
  handleClose: () => void

  // When provided, tabs are hidden and only the explanation for this financial
  // is shown
  financial?: AIExplainerFinancial
}

export default function AiExplainerDialog({
  isOpen,
  handleClose,
  financial,
}: AIExplainerDialogProps) {
  const [activeTab, setActiveTab] = useState(financial || AIExplainerFinancial.Revenue)

  let title = 'AI estimates'
  if (isDefined(financial)) {
    title = tabLookup[financial].title
  }

  const handleTabChange = (_, newValue: number) => {
    setActiveTab(newValue)
  }

  return (
    <StyledDialog
      maxWidth={'sm'}
      onClose={handleClose}
      open={isOpen}>
      <DialogHeader
        onCloseClick={handleClose}
        title={title}
      />
      {!isDefined(financial) && (
        <StyledTabs
          onChange={handleTabChange}
          value={activeTab}>
          <Tab
            label={'Revenue'}
            value={AIExplainerFinancial.Revenue}
          />
          <Tab
            label={'EBITDA'}
            value={AIExplainerFinancial.EBITDA}
          />
        </StyledTabs>
      )}
      <DialogContent>
        {activeTab === 0 && <AIExplainerRevenueContent />}
        {activeTab === 1 && <AIExplainerEbitdaContent />}
      </DialogContent>
      <DialogActions>
        <Button
          color={'primary'}
          onClick={handleClose}
          sx={{ minWidth: 83 }}
          variant={'outlined'}
          autoFocus>
          Ok
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}
