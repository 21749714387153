import { isDefined } from '@gain/utils/common'
import { kebabCase } from 'lodash'
import { useMemo } from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { INVESTOR_PAGE_PATH, InvestorPageParams, InvestorPagePathParams } from '../utils'

export enum RouteInvestorStrategyTabs {
  Summary = 'summary',
  Portfolio = 'assets',
  Funds = 'funds',
  Assessment = 'assessment',
  Benchmarking = 'benchmarking',
  Entries = 'entries',
  Exits = 'exits',
  AddOns = 'add-ons',
  Advisors = 'advisors',
  Representatives = 'representatives',
}

export const INVESTOR_STRATEGY_PAGE_PATH = `${INVESTOR_PAGE_PATH}/strategy/:strategyId/:strategyName?`
export const INVESTOR_STRATEGY_SUMMARY_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/${RouteInvestorStrategyTabs.Summary}`
export const INVESTOR_STRATEGY_ASSETS_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/${RouteInvestorStrategyTabs.Portfolio}`
export const INVESTOR_STRATEGY_FUNDS_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/${RouteInvestorStrategyTabs.Funds}`
export const INVESTOR_STRATEGY_ASSESSMENT_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/${RouteInvestorStrategyTabs.Assessment}`
export const INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/${RouteInvestorStrategyTabs.Benchmarking}`
export const INVESTOR_STRATEGY_ENTRIES_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/${RouteInvestorStrategyTabs.Entries}`
export const INVESTOR_STRATEGY_EXITS_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/${RouteInvestorStrategyTabs.Exits}`
export const INVESTOR_STRATEGY_ADD_ONS_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/${RouteInvestorStrategyTabs.AddOns}`
export const INVESTOR_STRATEGY_ADVISORS_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/${RouteInvestorStrategyTabs.Advisors}`
export const INVESTOR_STRATEGY_REPRESENTATIVES_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/${RouteInvestorStrategyTabs.Representatives}`

export interface InvestorStrategyPageParams extends InvestorPagePathParams {
  strategyId: string
  strategyName?: string
}

export interface InvestorStrategyPagePathParams extends InvestorPageParams {
  strategyId: number | string
  strategyName?: string | null
}

export function useInvestorStrategyPageParams() {
  const params = useParams<InvestorStrategyPageParams>()

  return useMemo(
    () => ({
      investorId: parseInt(params.investorId, 10),
      investorName: decodeURIComponent(params.investorName),
      preview:
        isDefined(params.preview) && params.preview !== 'false'
          ? true
          : params.preview === 'false'
          ? false
          : undefined,
      strategyId: parseInt(params.strategyId),
      strategyName: decodeURIComponent(params.strategyName || ''),
    }),
    [params]
  )
}

export function generateInvestorStrategyPagePath(
  params: InvestorStrategyPagePathParams,
  path = INVESTOR_STRATEGY_SUMMARY_PAGE_PATH
) {
  return generatePath(path, {
    ...params,
    investorId: params.investorId,
    investorName: kebabCase(params.investorName) || '-',
    strategyId: params.strategyId,
    strategyName: kebabCase(`${params.strategyName}`) || undefined,
  })
}
