import { MoreHorizontalIcon } from '@gain/components/icons'
import { UserListItem } from '@gain/rpc/app-model'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import { capitalize } from '@mui/material/utils'
import { formatDistance } from 'date-fns/formatDistance'
import { parseISO } from 'date-fns/parseISO'
import { useState } from 'react'

import { useListView } from '../list-view'
import { formatTeamMemberStatus } from './format-team-member-status'
import TeamMemberMenu from './team-member-menu'
import useTeamMemberStatusColor from './use-team-member-status-color-styles'

const formatLastLogin = (lastAccessAt: string | null) => {
  if (lastAccessAt) {
    return capitalize(
      formatDistance(parseISO(lastAccessAt).getTime(), new Date(), {
        addSuffix: true,
        includeSeconds: true,
      })
    )
  }

  return 'Never'
}

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (propName) => propName !== 'secondaryColor',
})<{ secondaryColor?: string }>(({ theme, secondaryColor }) => ({
  ...(secondaryColor && {
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    color: secondaryColor,
  }),
}))

export interface TeamMemberListItemProps {
  user: UserListItem
}

export function TeamMemberListItem({ user }: TeamMemberListItemProps) {
  const listView = useListView()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const statusColor = useTeamMemberStatusColor(user.status)
  const fullName = [user.firstName, user.lastName].join(' ')

  let secondary = formatTeamMemberStatus(user.status)
  if (user.status === 'active') {
    secondary = `Last login: ${formatLastLogin(user.lastAccessAt)}`
  }

  return (
    <>
      <TeamMemberMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={() => setAnchorEl(null)}
        onUserUpdated={() => listView.refreshGrid()}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        user={user}
      />
      <ListItem
        secondaryAction={
          <IconButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            size={'small'}>
            <MoreHorizontalIcon />
          </IconButton>
        }>
        <StyledListItemText
          primary={fullName}
          secondary={secondary}
          secondaryColor={user.status !== 'active' ? statusColor : undefined}
        />
      </ListItem>
    </>
  )
}
