import Head from '@gain/modules/head'
import { Asset } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid'

import CardSources from '../../../features/source/sources-card'

interface AssetSourcesProps {
  asset: Asset
}

export default function AssetSources({ asset }: AssetSourcesProps) {
  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Sources</title>
      </Head>
      <Grid
        spacing={2}
        container>
        <Grid
          xs={12}
          item>
          <CardSources sources={asset.sources} />
        </Grid>
      </Grid>
    </>
  )
}
