import {
  BriefcaseIcon,
  CompanyIcon,
  FlagIcon,
  LeafIcon,
  ShuffleIcon,
  Strategy2Icon,
  TrendingUpIcon,
} from '@gain/components/icons'
import { FilterableInvestorFundListItem } from '@gain/rpc/app-model'
import {
  INVESTOR_FUND_FUNDRAISING_STATUS_OPTIONS,
  INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS,
} from '@gain/rpc/shared-model'
import { ratingEsgMap } from '@gain/utils/investment-criteria'

import { dropdownMenuGroup, DropdownMenuOptions } from '../../../common/dropdown-menu'
import {
  checkboxList,
  createFilterMap,
  FilterModel,
  filterValueGroup,
  filterValueItem,
  investor,
  option,
  rangeCurrency,
  rangeMultiple,
  rangeNumber,
  rangePercentage,
  rangeYear,
  rating,
  region,
  sector,
  tag,
} from '../../filter/filter-bar'
import { FilterConfig } from '../../filter/filter-bar/filter-config/filter-config-model'

export const INVESTOR_FUND_FILTER_FIELDS = [
  'investorId',
  'assetsFiltered',
  'fundSizeEur',
  'fundraisingStatus',
  'vintageYear',
  'dryPowderMinEur',
  'strategyClassifications',

  'asset.ebitdaEur',
  'assetEbitdaMedianEur',
  'assetRegions',
  'assetSubsectors',
  'asset.tagIds',

  // 'dealsTotalLastFiveYears',
  'dealsAddOnsTotalLastFiveYears',
  // 'dealsEntriesTotalLastFiveYears',
  // 'dealsExitTotalLastFiveYears',

  'assetsMedianMarketSegmentRatingOverall',
  'assetsMedianMarketSegmentRatingEnvironmental',
  'assetsMedianMarketSegmentRatingSocial',

  'netIrr',
  'grossIrr',
  'twr',
  'tvpi',
  'moic',
  'dpi',
  'rvpi',
] as const

export type InvestorFundFilterField = (typeof INVESTOR_FUND_FILTER_FIELDS)[number]

export const INVESTOR_FUND_FILTER_MAP = createFilterMap<
  FilterableInvestorFundListItem,
  typeof INVESTOR_FUND_FILTER_FIELDS
>(
  investor('investorId', 'Investor'),
  rangeNumber('assetsFiltered', 'Number of companies', {
    icon: CompanyIcon,
    hint: 'The number of companies of the investor portfolio that must match the region, sector and EBITDA range filter.',
  }),
  rangeCurrency('fundSizeEur', 'Fund size'),
  checkboxList(
    'fundraisingStatus',
    'Fundraising status',
    INVESTOR_FUND_FUNDRAISING_STATUS_OPTIONS.map((status) => option(status.label, status.value))
  ),
  rangeYear('vintageYear', 'Vintage date'),
  rangeCurrency('dryPowderMinEur', 'Drypowder estimate', { maxFilterField: 'dryPowderMaxEur' }),
  checkboxList(
    'strategyClassifications',
    'Strategy classification',
    INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS.map((classification) =>
      option(classification.label, classification.value)
    ),
    {
      icon: Strategy2Icon,
    }
  ),

  rangeCurrency('asset.ebitdaEur', 'EBITDA range'),
  rangeCurrency('assetEbitdaMedianEur', 'Median EBITDA'),
  region('assetRegions', 'Active in region'),
  sector('assetSubsectors', 'Active in sector'),
  tag('asset.tagIds', 'Tags'),

  // rangeNumber('dealsTotalLastFiveYears', 'Deals (L5Y)'),
  rangeNumber('dealsAddOnsTotalLastFiveYears', 'Add-ons (L5Y)'),
  // rangeNumber('dealsEntriesTotalLastFiveYears', 'Entries (L5Y)'),
  // rangeNumber('dealsExitTotalLastFiveYears', 'Exits (L5Y)'),

  rating('assetsMedianMarketSegmentRatingOverall', ratingEsgMap.ratingOverall),
  rating('assetsMedianMarketSegmentRatingEnvironmental', ratingEsgMap.ratingEnvironmental),
  rating('assetsMedianMarketSegmentRatingSocial', ratingEsgMap.ratingSocial),

  rangePercentage('netIrr', 'Net IRR'),
  rangePercentage('grossIrr', 'Gross IRR'),
  rangePercentage('twr', 'TWR'),
  rangeMultiple('tvpi', 'TVPI'),
  rangeMultiple('moic', 'MOIC'),
  rangeMultiple('dpi', 'DPI'),
  rangeMultiple('rvpi', 'RVPI')
)

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
export const INVESTOR_FUND_FILTER_MENU: DropdownMenuOptions<
  FilterConfig<FilterableInvestorFundListItem, InvestorFundFilterField>
> = [
  INVESTOR_FUND_FILTER_MAP.investorId,
  INVESTOR_FUND_FILTER_MAP.assetsFiltered,
  INVESTOR_FUND_FILTER_MAP.strategyClassifications,

  dropdownMenuGroup('fundDetails', 'Fund details', {
    icon: FlagIcon,
    children: [
      INVESTOR_FUND_FILTER_MAP.fundraisingStatus,
      INVESTOR_FUND_FILTER_MAP.fundSizeEur,
      INVESTOR_FUND_FILTER_MAP.vintageYear,
      INVESTOR_FUND_FILTER_MAP.dryPowderMinEur,
    ],
  }),
  dropdownMenuGroup('portfolio', 'Portfolio', {
    icon: BriefcaseIcon,
    children: [
      INVESTOR_FUND_FILTER_MAP['asset.ebitdaEur'],
      INVESTOR_FUND_FILTER_MAP.assetEbitdaMedianEur,
      INVESTOR_FUND_FILTER_MAP.assetRegions,
      INVESTOR_FUND_FILTER_MAP.assetSubsectors,
      INVESTOR_FUND_FILTER_MAP['asset.tagIds'],
    ],
  }),
  dropdownMenuGroup('deals', 'Deals', {
    icon: ShuffleIcon,
    children: [
      // INVESTOR_FUND_FILTER_MAP.dealsTotalLastFiveYears,
      // INVESTOR_FUND_FILTER_MAP.dealsEntriesTotalLastFiveYears,
      // INVESTOR_FUND_FILTER_MAP.dealsExitTotalLastFiveYears,
      INVESTOR_FUND_FILTER_MAP.dealsAddOnsTotalLastFiveYears,
    ],
  }),
  dropdownMenuGroup('esg', 'ESG', {
    icon: LeafIcon,
    children: [
      INVESTOR_FUND_FILTER_MAP.assetsMedianMarketSegmentRatingOverall,
      INVESTOR_FUND_FILTER_MAP.assetsMedianMarketSegmentRatingEnvironmental,
      INVESTOR_FUND_FILTER_MAP.assetsMedianMarketSegmentRatingSocial,
    ],
  }),
  dropdownMenuGroup('fundPerformance', 'Fund performance', {
    icon: TrendingUpIcon,
    children: [
      INVESTOR_FUND_FILTER_MAP.netIrr,
      INVESTOR_FUND_FILTER_MAP.grossIrr,
      INVESTOR_FUND_FILTER_MAP.twr,
      INVESTOR_FUND_FILTER_MAP.tvpi,
      INVESTOR_FUND_FILTER_MAP.moic,
      INVESTOR_FUND_FILTER_MAP.dpi,
      INVESTOR_FUND_FILTER_MAP.rvpi,
    ],
  }),
]

export const INVESTOR_FUND_DEFAULT_FILTERS: FilterModel<
  FilterableInvestorFundListItem,
  InvestorFundFilterField
> = [
  filterValueGroup(filterValueItem('investorId')),
  filterValueGroup(filterValueItem('fundSizeEur')),
  filterValueGroup(filterValueItem('vintageYear')),
  filterValueGroup(filterValueItem('assetRegions')),
  filterValueGroup(filterValueItem('assetSubsectors')),
  filterValueGroup(filterValueItem('asset.ebitdaEur')),
  filterValueGroup(filterValueItem('assetsFiltered')),
]
