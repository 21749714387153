import { AssetOwnershipType } from '@gain/rpc/shared-model'

import InfoButton from '../../../common/info-button'
import { OWNERSHIP_TYPES } from './ownership-types'

interface AssetOwnershipUnverifiedChipProps {
  ownershipType: AssetOwnershipType
}

export default function AssetOwnershipUnverifiedChip({
  ownershipType,
}: AssetOwnershipUnverifiedChipProps) {
  // Append explainerUnverified to dialog message if it exists
  let dialogMessage = 'We have not been able to verify the ownership status of this company yet.'
  if (OWNERSHIP_TYPES[ownershipType].explainerUnverified) {
    dialogMessage += ` ${OWNERSHIP_TYPES[ownershipType].explainerUnverified}`
  }

  return (
    <InfoButton
      dialogMessage={dialogMessage}
      dialogTitle={'Ownership type unverified'}
      label={'Unverified'}
      sx={{ ml: 0.5 }}
    />
  )
}
