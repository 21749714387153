import {
  useAssetList,
  useInvestor,
  useInvestorStrategyListItems,
  usePersonList,
  useRelatedDealAdvisors,
} from '@gain/api/app/hooks'
import { BriefcaseMoneyIcon } from '@gain/components/icons'
import { SlotHome, SlotName } from '@gain/components/slot'
import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { AssetSourceType, Investor } from '@gain/rpc/app-model'
import { ObjectType } from '@gain/rpc/shared-model'
import {
  isJsonRpcError,
  listFilters,
  listSort,
  listSourceFilter,
  RpcErrorCode,
} from '@gain/rpc/utils'
import { isTruthy } from '@gain/utils/common'
import { useVisibleContainerHeight } from '@gain/utils/dom'
import { useIsXs } from '@gain/utils/responsive'
import { styled } from '@mui/material/styles'
import { Suspense, useCallback, useMemo } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { useTrackVisit } from '../../api/recent-visit-hooks'
import Loading from '../../common/loading'
import ProfileTabBar, { ProfileTabContainer, useActiveTab } from '../../common/profile-tab-bar'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import MobilePageHeader from '../../layout/mobile-page-header'
import NotFound from '../not-found'
import {
  generateInvestorPagePath,
  generateInvestorPagePortfolioTabPath,
  INVESTOR_PAGE_ADD_ONS_PATH,
  INVESTOR_PAGE_ADVISORS_PATH,
  INVESTOR_PAGE_ASSESSMENT_PATH,
  INVESTOR_PAGE_BENCHMARKING_PATH,
  INVESTOR_PAGE_ENTRIES_PATH,
  INVESTOR_PAGE_EXISTS_PATH,
  INVESTOR_PAGE_FUNDS_PATH,
  INVESTOR_PAGE_PORTFOLIO_PATH,
  INVESTOR_PAGE_REPRESENTATIVES_PATH,
  INVESTOR_PAGE_STRATEGIES_PATH,
  INVESTOR_PAGE_SUMMARY_PATH,
  useInvestorPageParams,
} from '../utils'
import InvestorDownloadsButton from './investor-downloads-button'
import InvestorPageOffline from './investor-page-offline'
import { createInvestorPortfolioFilters } from './investor-profile-util'
import RouteAddOns from './route-add-ons'
import RouteAdvisors from './route-advisors'
import RouteAssessment from './route-assessment'
import RouteBenchmarking from './route-benchmarking'
import RouteEntries from './route-entries'
import RouteExits from './route-exits'
import RouteFunds from './route-funds'
import RoutePortfolio from './route-portfolio'
import RouteRepresentatives from './route-representatives'
import RouteStrategies from './route-strategies'
import { sortStrategies } from './route-strategies/route-strategies-utils'
import RouteSummary from './route-summary'

export interface RouteInvestorProps {
  // Used in the browser extension so we can add the banner "View in Gain.pro"
  onInvestorFetched?: (investor: Investor) => void
}

const StyledLoadingContainer = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
})

const StyledProfileTabContainer = styled(ProfileTabContainer)({
  flex: 1,
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
})

interface UseTabsProps {
  showStrategies: boolean
  showFunds: boolean
  showAdvisors: boolean
  showAssets: boolean
  showBenchmarking: boolean
  showRepresentatives: boolean
}

function useTabs({
  showStrategies,
  showFunds,
  showAssets,
  showBenchmarking,
  showAdvisors,
  showRepresentatives,
}: UseTabsProps) {
  const params = useInvestorPageParams()

  return useMemo(() => {
    return [
      {
        label: 'Summary',
        path: generateInvestorPagePath(params, INVESTOR_PAGE_SUMMARY_PATH),
        value: 'summary',
      },
      showAssets && {
        label: 'Portfolio',
        path: generateInvestorPagePortfolioTabPath(params),
        value: 'portfolio',
      },
      showFunds && {
        label: 'Funds',
        path: generateInvestorPagePath(params, INVESTOR_PAGE_FUNDS_PATH),
        value: 'funds',
      },
      showStrategies && {
        label: 'Strategies',
        path: generateInvestorPagePath(params, INVESTOR_PAGE_STRATEGIES_PATH),
        value: 'strategies',
      },
      showAssets && {
        label: 'Assessment',
        path: generateInvestorPagePath(params, INVESTOR_PAGE_ASSESSMENT_PATH),
        value: 'assessment',
      },
      showBenchmarking && {
        label: 'Benchmarking',
        path: generateInvestorPagePath(params, INVESTOR_PAGE_BENCHMARKING_PATH),
        value: 'benchmarking',
      },
      {
        label: 'Entries',
        path: generateInvestorPagePath(params, INVESTOR_PAGE_ENTRIES_PATH),
        value: 'entries',
      },
      {
        label: 'Exits',
        path: generateInvestorPagePath(params, INVESTOR_PAGE_EXISTS_PATH),
        value: 'exits',
      },
      {
        label: 'Add-ons',
        path: generateInvestorPagePath(params, INVESTOR_PAGE_ADD_ONS_PATH),
        value: 'add-ons',
      },
      showRepresentatives && {
        label: 'Representatives',
        path: generateInvestorPagePath(params, INVESTOR_PAGE_REPRESENTATIVES_PATH),
        value: 'representatives',
      },
      showAdvisors && {
        label: 'Advisors',
        path: generateInvestorPagePath(params, INVESTOR_PAGE_ADVISORS_PATH),
        value: 'advisors',
      },
    ].filter(isTruthy)
  }, [
    params,
    showAdvisors,
    showAssets,
    showFunds,
    showRepresentatives,
    showStrategies,
    showBenchmarking,
  ])
}

export default function RouteInvestor({ onInvestorFetched }: RouteInvestorProps) {
  const [containerRef, availableHeight] = useVisibleContainerHeight<HTMLDivElement>()
  const params = useInvestorPageParams()
  const isBrowserExtension = useIsBrowserExtension()
  const isXs = useIsXs()

  useTrackVisit(params.investorId, ObjectType.Investor)

  const {
    data: investor,
    error,
    loading: investorLoading,
  } = useInvestor(
    {
      id: params.investorId,
      preview: params.preview,
    },
    {
      onSuccess: onInvestorFetched,
    }
  )

  const swrStrategies = useInvestorStrategyListItems([params.investorId])

  const swrDealAdvisors = useRelatedDealAdvisors({
    source: AssetSourceType.Investor,
    id: params.investorId,
  })

  const swrAssets = useAssetList(() => {
    // don't fetch assets if the investor is still loading
    if (!investor) {
      return null
    }

    return {
      page: 0,
      limit: 500,
      sort: [listSort('ebitdaEur', 'desc')],
      filter: createInvestorPortfolioFilters(investor),
    }
  })

  const swrRepresentatives = usePersonList({
    filter: listFilters(
      listSourceFilter('id', {
        source: 'investorManagers',
        id: params.investorId,
      })
    ),
    limit: 0,
  })

  const hasFunds = Boolean(investor?.funds?.length)
  const hasStrategies = Boolean((swrStrategies.data?.items?.length ?? 0) > 1)
  const hasAssets = swrAssets.data.items.length > 0
  const hasAdvisors = Boolean(swrDealAdvisors.data?.length)
  const hasRepresentatives = swrRepresentatives.data.counts.filtered > 0
  const loading =
    investorLoading ||
    swrAssets.loading ||
    swrRepresentatives.loading ||
    swrDealAdvisors.loading ||
    swrStrategies.loading

  const tabs = useTabs({
    showStrategies: hasStrategies,
    showFunds: hasFunds,
    showAssets: hasAssets,
    showBenchmarking: hasAssets && !isXs,
    showAdvisors: hasAdvisors,
    showRepresentatives: hasRepresentatives,
  })
  const activeTab = useActiveTab(tabs)
  const history = useHistory()

  useTrackPageView('investor', { id: params.investorId, tab: activeTab, requireTab: true })

  const isOffline = isJsonRpcError(error) && error.code === RpcErrorCode.ResourceOffline
  const sortedStrategies = (swrStrategies.data?.items || []).sort(sortStrategies)

  const handleLogoClick = useCallback(() => {
    history.push(generateInvestorPagePath(params, INVESTOR_PAGE_SUMMARY_PATH))
  }, [history, params])

  if (loading) {
    return <Loading />
  }

  if (isJsonRpcError(error) && error.code === RpcErrorCode.InvalidResourceIdentifier) {
    return <NotFound />
  }

  if (isOffline) {
    return <InvestorPageOffline />
  }

  if (!investor) {
    return null
  }

  return (
    <>
      {isXs && !isBrowserExtension && <MobilePageHeader title={investor?.name} />}

      <ProfileTabBar
        activeTab={activeTab}
        defaultIcon={BriefcaseMoneyIcon}
        infoActions={[
          !isXs && (
            <InvestorDownloadsButton
              key={'investor-downloads'}
              assets={swrAssets.data.items}
              investor={investor}
            />
          ),
        ].filter(isTruthy)}
        logoFileUrl={investor.logoFileUrl}
        onLogoClick={handleLogoClick}
        subtitleUrl={investor.url}
        tabActions={<SlotHome slotName={SlotName.Tabs} />}
        tabs={tabs}
        title={investor.name}
        disableIndicatorAnimation
        replaceUrl
        sticky
      />

      <StyledProfileTabContainer
        ref={containerRef}
        maxWidth={'lg'}
        disableGuttersTop>
        <Suspense
          fallback={
            <StyledLoadingContainer>
              <Loading />
            </StyledLoadingContainer>
          }>
          <Switch>
            <Route
              path={INVESTOR_PAGE_SUMMARY_PATH}
              exact>
              <RouteSummary
                assets={swrAssets.data}
                investor={investor}
                strategies={sortedStrategies}
              />
            </Route>
            <Route
              path={INVESTOR_PAGE_STRATEGIES_PATH}
              exact>
              <RouteStrategies
                investor={investor}
                strategies={sortedStrategies}
              />
            </Route>
            <Route
              path={INVESTOR_PAGE_FUNDS_PATH}
              exact>
              <RouteFunds
                investor={investor}
                strategies={sortedStrategies}
              />
            </Route>
            <Route
              path={INVESTOR_PAGE_PORTFOLIO_PATH}
              exact>
              <RoutePortfolio investor={investor} />
            </Route>
            <Route
              path={INVESTOR_PAGE_ASSESSMENT_PATH}
              exact>
              <RouteAssessment
                assets={swrAssets.data.items}
                investor={investor}
                strategies={swrStrategies.data?.items || []}
              />
            </Route>
            <Route
              path={INVESTOR_PAGE_BENCHMARKING_PATH}
              exact>
              <RouteBenchmarking
                assets={swrAssets.data.items}
                investor={investor}
                strategies={swrStrategies.data?.items || []}
              />
            </Route>
            <Route
              path={INVESTOR_PAGE_REPRESENTATIVES_PATH}
              exact>
              <RouteRepresentatives investor={investor} />
            </Route>
            <Route
              path={INVESTOR_PAGE_ADVISORS_PATH}
              exact>
              <RouteAdvisors
                availableHeight={availableHeight}
                dealAdvisors={swrDealAdvisors.data ?? []}
                investor={investor}
              />
            </Route>
            <Route
              path={INVESTOR_PAGE_ENTRIES_PATH}
              exact>
              <RouteEntries investor={investor} />
            </Route>
            <Route
              path={INVESTOR_PAGE_EXISTS_PATH}
              exact>
              <RouteExits investor={investor} />
            </Route>
            <Route
              path={INVESTOR_PAGE_ADD_ONS_PATH}
              exact>
              <RouteAddOns investor={investor} />
            </Route>
            <Route path={'*'}>
              <Redirect
                to={generateInvestorPagePath(
                  {
                    investorId: investor.id,
                    investorName: investor.name,
                    preview: params.preview,
                  },
                  INVESTOR_PAGE_SUMMARY_PATH
                )}
              />
            </Route>
          </Switch>
        </Suspense>
      </StyledProfileTabContainer>
    </>
  )
}
