import { useRpcClient } from '@gain/api/swr'
import Snackbar from '@gain/components/snackbar'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import DialogHeader from '../../common/dialog-header'
import { ShareFeedbackBarProps } from './share-feedback-bar'

const SNACKBAR_KEY = 'feedback-shared'

interface ShareFeedbackDialogProps extends Omit<ShareFeedbackBarProps, 'infoDescriptions'> {
  open: boolean
  onClose: () => void
}

export default function ShareFeedbackDialog({
  title,
  type,
  attributes,
  disabledInfoFields,

  open,
  onClose,
}: ShareFeedbackDialogProps) {
  const fetcher = useRpcClient()
  const [error, setError] = useState<null | string>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const form = useForm({
    defaultValues: {
      feedback: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        feedback: yup.string().required('Feedback field is required'),
      })
    ),
  })

  const onSubmit = form.handleSubmit(async (data) => {
    setSubmitting(true)
    setError(null)

    try {
      await fetcher({
        method: 'account.createTicket',
        params: {
          type,
          body: data.feedback,
          attributes,
        },
      })

      enqueueSnackbar(undefined, {
        key: SNACKBAR_KEY,
        content: () => (
          <Snackbar
            id={SNACKBAR_KEY}
            message={'Your feedback has been received. We will get back to you shortly.'}
          />
        ),
        preventDuplicate: true,
      })
      onClose()
    } catch {
      setError('An error occurred while submitting the form, please try again later.')
    }

    setSubmitting(false)
  })

  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={open}
      TransitionProps={{
        onExited: () => form.reset(),
      }}
      fullWidth>
      <form
        onSubmit={onSubmit}
        noValidate>
        <DialogHeader
          onCloseClick={onClose}
          title={title}
        />

        <DialogContent>
          <Grid
            rowSpacing={2}
            container>
            {error && (
              <Grid size={12}>
                <Alert severity={'error'}>{error}</Alert>
              </Grid>
            )}
            {disabledInfoFields.map(({ label, value }) => (
              <Grid
                key={label}
                size={12}>
                <TextField
                  key={label}
                  label={label}
                  name={label}
                  value={value}
                  disabled
                  fullWidth
                />
              </Grid>
            ))}

            <Grid size={12}>
              <TextField
                error={!!form.formState.errors.feedback}
                helperText={form.formState.errors.feedback?.message}
                label={'Feedback'}
                maxRows={10}
                minRows={10}
                name={'feedback'}
                placeholder={'Please enter your feedback here.'}
                slotProps={{
                  htmlInput: { ...form.register('feedback') },
                }}
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            color={'primary'}
            disabled={!form.formState.isDirty || !form.formState.isValid}
            loading={submitting}
            type={'submit'}
            variant={'contained'}
            fullWidth>
            Send
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
