import { useBookmarkLists } from '@gain/api/app/hooks'
import { BookmarkIcon, HelpCircleIcon, HomeIcon } from '@gain/components/icons'
import { SlotHome, SlotName } from '@gain/components/slot'
import { styled } from '@mui/material/styles'
import { generatePath } from 'react-router-dom'

import { BOOKMARKS_PATH, HELP_OPTIONS_PATH, HOME_PATH } from '../../routes/utils'
import MobileNavigationItem from './mobile-navigation-item'

export const MOBILE_NAVIGATION_HEIGHT = 56

const MobileRoot = styled('div')(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px -8px 24px rgba(0, 0, 0, 0.06)',
  paddingBottom: 'env(safe-area-inset-bottom)',
  zIndex: theme.zIndex.appBar - 3,
}))

const MobileContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  height: MOBILE_NAVIGATION_HEIGHT,

  position: 'relative',
  zIndex: theme.zIndex.appBar,
  backgroundColor: theme.palette.common.white,
  borderTop: `1px solid ${theme.palette.divider}`,

  [theme.breakpoints.up(600)]: {
    display: 'none',
  },
}))

export default function MobileNavigation() {
  const items = useBookmarkLists()
  const totalUpdates = items.data.items.reduce((a, b) => a + b.updatedCount, 0)

  return (
    <MobileRoot>
      <SlotHome slotName={SlotName.MobileBottom} />

      <MobileContainer>
        <MobileNavigationItem
          icon={HomeIcon}
          label={'Home'}
          to={HOME_PATH}
        />
        <MobileNavigationItem
          icon={BookmarkIcon}
          label={'Bookmarks'}
          to={generatePath(BOOKMARKS_PATH)}
          updates={totalUpdates}
        />
        <MobileNavigationItem
          icon={HelpCircleIcon}
          label={'Help'}
          to={HELP_OPTIONS_PATH}
        />
      </MobileContainer>
    </MobileRoot>
  )
}
