import { BookmarkIcon, PlusIcon } from '@gain/components/icons'
import { BookmarkListItem } from '@gain/rpc/app-model'
import { useDialogState } from '@gain/utils/dialog'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useSortedBookmarkLists } from '../../../api/bookmark-list-hooks'
import { BOOKMARKS_CREATE_PATH } from '../../../routes/utils'
import BookmarksListEditMenu from '../../bookmarks-list/bookmarks-list-edit/bookmarks-list-edit-menu'
import MenuListItem from '../menu-list-item'
import MenuSubHeader from '../menu-sub-header'
import BookmarksMenuCollapsed from './bookmarks-menu-collapsed'
import BookmarksListMenuItem from './bookmarks-menu-item'
import ListCollapse from './list-collapse'

const StyledMenuSubHeader = styled(MenuSubHeader)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

const StyledPlusButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(-0.75),
}))

const StyledEmptyMenuListItem = styled(MenuListItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontStyle: 'italic',
  ...theme.typography.body2,
}))

interface BookmarksMenuProps {
  onLastUpdatedListIntersection?: (element: Element, inView: boolean) => void
  isOpen: boolean
}

export function BookmarksMenu({ onLastUpdatedListIntersection, isOpen }: BookmarksMenuProps) {
  const [editMenuList, setEditMenuList] = useState<BookmarkListItem | null>(null)
  const plusIconRef = useRef<HTMLButtonElement>(null)
  const bookmarkLists = useSortedBookmarkLists()
  const [isMoreMenuOpen, openMoreMenu, closeMoreMenu] = useDialogState()
  const [collapsedMenuAnchor, setCollapsedMenuAnchor] = useState<Element | null>(null)
  const [editMenuAnchor, setEditMenuAnchor] = useState<Element | null>(null)

  const totalUpdates = bookmarkLists.reduce((a, b) => a + b.updatedCount, 0)

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setCollapsedMenuAnchor(null)
  }, [location, setCollapsedMenuAnchor])

  const handleMoreMenuOpen = (event: MouseEvent<HTMLButtonElement>, list: BookmarkListItem) => {
    setEditMenuList(list)
    setEditMenuAnchor(event.currentTarget)
    openMoreMenu()
  }

  // If the last updated list is below the fold we show the "More updates" badge.
  const lastUpdatedBookmarkList = useMemo(() => {
    for (let i = bookmarkLists.length - 1; i >= 0; i--) {
      if (bookmarkLists[i].updatedCount > 0) {
        return bookmarkLists[i]
      }
    }

    return null
  }, [bookmarkLists])

  return (
    <>
      <StyledMenuSubHeader
        actions={
          <StyledPlusButton
            ref={plusIconRef}
            color={'primary'}
            edge={'end'}
            onClick={() => history.push(BOOKMARKS_CREATE_PATH)}
            size={'small'}>
            <PlusIcon />
          </StyledPlusButton>
        }
        isOpen={isOpen}
        title={'Bookmarks'}
      />

      <ListCollapse
        collapsed={
          <>
            <MenuListItem
              icon={<BookmarkIcon />}
              iconBadge={totalUpdates}
              label={'Bookmarks'}
              onClick={(event: MouseEvent) => setCollapsedMenuAnchor(event.target as Element)}
            />
            <BookmarksMenuCollapsed
              anchorEl={collapsedMenuAnchor}
              items={bookmarkLists}
              onClose={() => setCollapsedMenuAnchor(null)}
            />
          </>
        }
        expanded={
          <>
            {bookmarkLists.map((bookmarkList, i) => {
              // The last updated list item we track its visibility status to
              // trigger the "More updates" badge.
              const isLastUpdatedItem = lastUpdatedBookmarkList === bookmarkList
              const handleIntersection =
                isLastUpdatedItem && onLastUpdatedListIntersection
                  ? onLastUpdatedListIntersection
                  : undefined

              return (
                <BookmarksListMenuItem
                  key={bookmarkList.id}
                  list={bookmarkList}
                  onLastUpdatedListIntersection={handleIntersection}
                  onOpen={handleMoreMenuOpen}
                  open={editMenuList === bookmarkList && isMoreMenuOpen}
                />
              )
            })}

            {bookmarkLists.length === 0 && <StyledEmptyMenuListItem label={'No lists available'} />}
          </>
        }
        isOpen={isOpen}
      />

      {/* To avoid triggering unnecessary network requests we set it up just once in this parent component. */}
      {editMenuList && (
        <BookmarksListEditMenu
          anchorEl={editMenuAnchor}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          list={editMenuList}
          onClose={closeMoreMenu}
          open={isMoreMenuOpen}
          transformOrigin={{
            horizontal: -6,
            vertical: 6,
          }}
          disableEditContent
        />
      )}
    </>
  )
}
