import { useAssetList } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { InvestorFundListItem } from '@gain/rpc/app-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'

import Benchmarking from '../../../features/benchmarking'
import { BenchmarkingContextProvider } from '../../../features/benchmarking/benchmarking-context'
import { useTrackPageView } from '../../../features/planhat/planhat-hooks'

export interface RouteBenchmarkingProps {
  fund: InvestorFundListItem
}

export default function RouteBenchmarking({ fund }: RouteBenchmarkingProps) {
  useTrackPageView('investor_fund', {
    id: fund.id,
    tab: 'benchmarking',
    requireTab: true,
  })

  const swrAssets = useAssetList({
    filter: listFilters(
      listFilter('ownerIds', '=', fund.investorId),
      listFilter('fundIds', '=', fund.id)
    ),
    sort: [listSort('revenueEur', 'desc')],
    limit: 500,
  })

  const name = `${fund.investorName} - ${fund.strategyName} - ${fund.name}`

  return (
    <>
      <Head>
        <title>{name} - Analysis</title>
      </Head>

      <BenchmarkingContextProvider
        value={{
          name,
          objectId: fund.id,
          objectType: 'investorFund',
        }}>
        <Benchmarking
          initialAssets={swrAssets.data.items}
          loadingInitialAssets={swrAssets.loading}
        />
      </BenchmarkingContextProvider>
    </>
  )
}
