import { useListedSecurityValuations } from '@gain/api/app/hooks'
import { CurrencyListItem, ListedSecurityListItem } from '@gain/rpc/app-model'
import { useIsXs } from '@gain/utils/responsive'
import Grid from '@mui/material/Grid2'
import { getMonth } from 'date-fns/getMonth'
import { getYear } from 'date-fns/getYear'
import { useState } from 'react'

import SourcesList from '../../../features/source/source-list/list-sources'
import AssetValuationMetricsCard from './asset-valuation-metrics-card'
import MarketDataChart from './market-data-chart/market-data-chart'
import { hasValuationRatios } from './ratios-card/ratio-utils'
import RatiosCard from './ratios-card/ratios-card'

export interface AssetValuationProps {
  listedSecurity: ListedSecurityListItem
}

export default function ListedAssetValuation({ listedSecurity }: AssetValuationProps) {
  const isXs = useIsXs()
  const swrValuations = useListedSecurityValuations(listedSecurity?.id)
  const hasValuations = hasValuationRatios(swrValuations.data)

  // Handle state for selected currency and time range
  const [currency, setCurrency] = useState<CurrencyListItem | null>(null)
  const selectedCurrency = currency?.name ?? 'EUR'

  // Don't render when there's no listed security
  if (!listedSecurity || !listedSecurity.tradingCurrency) {
    return null
  }

  return (
    <Grid
      spacing={2}
      sx={{ width: '100%' }}
      container>
      {/* Valuation metrics bar */}
      <Grid size={12}>
        <AssetValuationMetricsCard
          currency={selectedCurrency}
          listedSecurityCurrency={listedSecurity.tradingCurrency}
          listedSecurityId={listedSecurity.id}
        />
      </Grid>
      {/* Market data chart, hide it on mobile */}
      {!isXs && (
        <Grid size={12}>
          <MarketDataChart
            currency={currency}
            listedSecurity={listedSecurity}
            listedSecurityCurrency={listedSecurity.tradingCurrency}
            onCurrencyChange={setCurrency}
          />
        </Grid>
      )}
      {/* Ratios data chart */}
      {hasValuations && (
        <Grid size={12}>
          <RatiosCard
            historicalValuations={swrValuations.data || []}
            listedSecurity={listedSecurity}
          />
        </Grid>
      )}
      {/* Copyright notice */}
      <Grid size={12}>
        <SourcesList
          sources={[
            {
              publisher: 'FactSet',
              title: `Copyright © ${getYear(new Date())} FactSet UK Limited. All rights reserved.`,
              publicationYear: getYear(new Date()),
              publicationMonth: getMonth(new Date()) + 1,
              url: null,
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}
