import { InvestorFundListItem } from '@gain/rpc/app-model'
import { isTruthy } from '@gain/utils/common'
import { useIsXs } from '@gain/utils/responsive'
import { generatePath } from 'react-router-dom'

import {
  INVESTOR_FUND_ADD_ONS_PAGE_PATH,
  INVESTOR_FUND_ASSESSMENT_PAGE_PATH,
  INVESTOR_FUND_BENCHMARKING_PAGE_PATH,
  INVESTOR_FUND_PORTFOLIO_PAGE_PATH,
  INVESTOR_FUND_SUMMARY_PAGE_PATH,
  RouteInvestorFundTabs,
  useInvestorFundPageParams,
} from './route-fund-path'

export function useFundTabs(fund: InvestorFundListItem | null) {
  const params = useInvestorFundPageParams()
  const isXs = useIsXs()

  const hasAssets = (fund?.assetsTotal ?? 1) > 0
  return [
    {
      label: 'Summary',
      path: generatePath(INVESTOR_FUND_SUMMARY_PAGE_PATH, params),
      value: RouteInvestorFundTabs.Summary,
    },
    hasAssets && {
      label: 'Portfolio',
      path: generatePath(INVESTOR_FUND_PORTFOLIO_PAGE_PATH, params),
      value: RouteInvestorFundTabs.Portfolio,
    },
    hasAssets && {
      label: 'Assessment',
      path: generatePath(INVESTOR_FUND_ASSESSMENT_PAGE_PATH, params),
      value: RouteInvestorFundTabs.Assessment,
    },
    !isXs &&
      hasAssets && {
        label: 'Benchmarking',
        path: generatePath(INVESTOR_FUND_BENCHMARKING_PAGE_PATH, params),
        value: RouteInvestorFundTabs.Benchmarking,
      },
    // (fund?.dealsEntriesTotalLastFiveYears ?? 1) > 0 && {
    //   label: 'Entries',
    //   path: generatePath(INVESTOR_FUND_ENTRIES_PAGE_PATH, params),
    //   value: RouteInvestorFundTabs.Entries,
    // },
    // (fund?.dealsExitTotalLastFiveYears ?? 1) > 0 && {
    //   label: 'Exits',
    //   path: generatePath(INVESTOR_FUND_EXITS_PAGE_PATH, params),
    //   value: RouteInvestorFundTabs.Exits,
    // },
    (fund?.dealsAddOnsTotalLastFiveYears ?? 1) > 0 && {
      label: 'Add-ons',
      path: generatePath(INVESTOR_FUND_ADD_ONS_PAGE_PATH, params),
      value: RouteInvestorFundTabs.AddOns,
    },
  ].filter(isTruthy)
}
