import { isDefined } from '@gain/utils/common'
import {
  Rating as ICRating,
  RatingInvestmentCriteriaKey,
  roundInvestmentCriteriaValue,
  useRatingColor,
} from '@gain/utils/investment-criteria'
import Rating from '@mui/material/Rating'
import { alpha, styled } from '@mui/material/styles'

import InvestmentCriteriaTableHeaderTitle from './investment-criteria-table-header-title'

export const StyledRow = styled('tr')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  minHeight: 56,
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}))

export const StyledHeaderCell = styled('th')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  padding: theme.spacing(1, 0, 1, 2),
  borderRight: `1px solid ${theme.palette.divider}`,
  width: 172,
}))

export const StyledCell = styled('td')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(0.75),
  position: 'relative',
}))

interface StyledRatingOptionProps {
  selected?: boolean
  color?: string
}

export const StyledRatingOption = styled('div', {
  shouldForwardProp: (prop) => !['selected', 'color'].includes(prop as string),
})<StyledRatingOptionProps>(({ theme, selected, color }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flex: 1,
  borderRadius: 4,
  ...theme.typography.body2,
  color: theme.palette.grey['400'],
  padding: theme.spacing(0.25, 0.5),
  ...(selected &&
    color && {
      ...theme.typography.subtitle2,
      backgroundColor: alpha(color, 0.1),
      color,
    }),
}))

const StyledRatingOptionSuffix = styled('span')(({ theme }) => ({
  ...theme.typography.overline,
}))

interface RatingOptionProps {
  selected: boolean
  children: string
  color: string
  suffix?: string
}

function RatingOption({ selected, color, children, suffix }: RatingOptionProps) {
  return (
    <StyledRatingOption
      color={color}
      selected={selected}>
      {children}
      {selected && suffix && <StyledRatingOptionSuffix>{suffix}</StyledRatingOptionSuffix>}
    </StyledRatingOption>
  )
}

export interface InvestmentCriteriaTableRowProps {
  rating: ICRating<RatingInvestmentCriteriaKey>
  value?: number | null
}

export default function InvestmentCriteriaTableRow({
  rating,
  value,
}: InvestmentCriteriaTableRowProps) {
  const color = useRatingColor(value)

  return (
    <StyledRow>
      <StyledHeaderCell>
        <InvestmentCriteriaTableHeaderTitle rating={rating} />

        {isDefined(value) && (
          <Rating
            precision={0.5}
            sx={{
              color,
            }}
            value={value}
            readOnly
          />
        )}
      </StyledHeaderCell>
      <StyledCell>
        {!isDefined(value) && <StyledRatingOption>Rating not available</StyledRatingOption>}
        {isDefined(value) &&
          rating.options.map((option) => (
            <RatingOption
              key={option.value}
              color={color}
              selected={option.value === roundInvestmentCriteriaValue(value)}
              suffix={rating.suffix}>
              {option.label}
            </RatingOption>
          ))}
      </StyledCell>
    </StyledRow>
  )
}
