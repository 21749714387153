import {
  CopyIcon,
  MailIcon,
  SlashIcon,
  ToolIcon,
  ToolOffIcon,
  Trash2Icon,
  UserCheckIcon,
} from '@gain/components/icons'
import { UserListItem } from '@gain/rpc/app-model'
import { UserStatus } from '@gain/rpc/shared-model'
import { isAdministrator } from '@gain/utils/user'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu, { MenuProps as MuiMenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useCallback } from 'react'

import { MenuDivider } from '../../common/menu-divider'
import {
  useActivateUserAccountCallback,
  useCopyInvitationLinkCallback,
  useDeactivateUserAccountCallback,
  useDeleteUserAccountCallback,
  useGiveAdminRoleCallback,
  useRemoveAdminRoleCallback,
  useResendInvitationCallback,
} from './team-member-hooks'

export type TeamMemberMenuProps = MuiMenuProps & {
  user: UserListItem
  onUserUpdated?: () => void
}

export default function TeamMemberMenu({
  open,
  user,
  onClose,
  onUserUpdated,
  ...MenuProps
}: TeamMemberMenuProps) {
  const deactivateUser = useDeactivateUserAccountCallback()
  const activateUser = useActivateUserAccountCallback()
  const deleteUser = useDeleteUserAccountCallback()
  const resendInvitation = useResendInvitationCallback()
  const giveAdminRole = useGiveAdminRoleCallback()
  const removeAdminRole = useRemoveAdminRoleCallback()
  const copyInvitationLink = useCopyInvitationLinkCallback()

  const handleClose = useCallback(() => {
    onClose && onClose({}, 'backdropClick')
  }, [onClose])

  return (
    <Menu
      {...MenuProps}
      onClose={onClose}
      open={open}>
      {user.status === UserStatus.Invited && (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            resendInvitation(user.userId).finally(handleClose)
          }}>
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText>Resend invitation email</ListItemText>
        </MenuItem>
      )}
      {user.status === UserStatus.Invited && (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            copyInvitationLink(user.userId).finally(handleClose)
          }}>
          <ListItemIcon>
            <CopyIcon />
          </ListItemIcon>
          <ListItemText>Copy invitation link</ListItemText>
        </MenuItem>
      )}
      {!isAdministrator(user.role) && (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            giveAdminRole(user.userId, onUserUpdated).finally(handleClose)
          }}>
          <ListItemIcon>
            <ToolIcon />
          </ListItemIcon>
          <ListItemText>Give admin rights</ListItemText>
        </MenuItem>
      )}
      {isAdministrator(user.role) && (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            removeAdminRole(user.userId, onUserUpdated).finally(handleClose)
          }}>
          <ListItemIcon>
            <ToolOffIcon />
          </ListItemIcon>
          <ListItemText>Remove admin rights</ListItemText>
        </MenuItem>
      )}
      {user.status === UserStatus.Deactivated && (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            activateUser(user.userId, onUserUpdated).finally(handleClose)
          }}>
          <ListItemIcon>
            <UserCheckIcon />
          </ListItemIcon>
          <ListItemText>Activate account</ListItemText>
        </MenuItem>
      )}
      {user.status === UserStatus.Active && (
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            deactivateUser(user.userId, onUserUpdated).finally(handleClose)
          }}>
          <ListItemIcon>
            <SlashIcon />
          </ListItemIcon>
          <ListItemText>Deactivate account</ListItemText>
        </MenuItem>
      )}
      <MenuDivider />
      <MenuItem
        color={'error'}
        onClick={(event) => {
          event.stopPropagation()
          deleteUser(user.userId, onUserUpdated).finally(handleClose)
        }}>
        <ListItemIcon>
          <Trash2Icon color={'error'} />
        </ListItemIcon>
        <ListItemText>Delete account</ListItemText>
      </MenuItem>
    </Menu>
  )
}
