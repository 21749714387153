import { ListItemKey } from '@gain/rpc/list-model'
import { EMPTY_ARRAY, noop } from '@gain/utils/common'
import { useIsXs } from '@gain/utils/responsive'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { FormApi } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { isEqual } from 'lodash'
import {
  Children,
  cloneElement,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import { DropdownMenuOptions } from '../../../../common/dropdown-menu'
import { GaCategory } from '../../../google-analytics'
import FilterChip from '../filter-chip'
import { FilterConfig, FilterConfigMap } from '../filter-config/filter-config-model'
import { FilterModel } from '../filter-model'
import { isEmpty } from '../filter-utils'
import { filterValueGroup, filterValueItem } from '../filter-value-builders'
import AddFilterButton from './add-filter-button'
import AddFilterDropdownMenu from './add-filter-dropdown-menu'
import FilterBarActionsContainer from './filter-bar-actions-container'
import FilterApiRenderer, { FilterApi } from './filter-bar-api'
import FilterBarClearButton from './filter-bar-clear-button'
import { FilterBarContext, FilterContextType } from './filter-bar-context'
import FilterBarSearch from './filter-bar-search'
import { FilterBarValueChangeSpy } from './filter-bar-value-change-spy'
import { FilterFormValues, filterModelToValues } from './filter-form-value'

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: Ref<T>) => ReactElement | null
  ): (props: P & RefAttributes<T>) => ReactElement | null
}

type FilterBarVariant = 'default' | 'inline'

const StyledFilterBarContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: FilterBarVariant }>(({ theme, variant }) => ({
  position: 'relative',
  padding: variant === 'inline' ? theme.spacing(0, 3) : undefined,
  [theme.breakpoints.only('xs')]: {
    display: 'flex',
    flexWrap: 'noWrap',
    gap: theme.spacing(1),
    alignItems: 'center',
    padding: variant === 'inline' ? theme.spacing(0, 3) : theme.spacing(2, 3),
    overflow: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}))

export const StyledFilterBar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disablePadding',
})<{ disablePadding?: boolean }>(({ theme, disablePadding }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    ...(!disablePadding && {
      padding: theme.spacing(0, 0, 2, 0),
    }),
  },
}))

const StyledFilterContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
  alignItems: 'center',
  minWidth: 0,
  width: '100%',

  [theme.breakpoints.only('xs')]: {
    flexWrap: 'nowrap',
    overflow: 'auto',
  },
}))

const StyledActionsContainer = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
})

const StyledOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.tooltip,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}))

const DEFAULT_FILTER_MODEL: FilterModel = []

function mergeInitialValues<Item extends object, FilterField extends ListItemKey<Item>>(
  initialFilterModel?: FilterModel<Item, FilterField>,
  defaultFilterModel?: FilterModel<Item, FilterField>
): FilterModel<Item, FilterField> {
  return Array.isArray(initialFilterModel)
    ? initialFilterModel
    : defaultFilterModel
    ? defaultFilterModel
    : []
}

export interface FilterBarProps<Item extends object, FilterField extends ListItemKey<Item>> {
  actions?: (() => ReactNode) | ReactNode
  addFilterMenu?: DropdownMenuOptions<FilterConfig<Item, FilterField>>
  defaultFilterModel?: FilterModel<Item, FilterField>
  disableAddFilter?: boolean
  disableClear?: boolean
  disableDeleteFilter?: boolean
  disableOrFilter?: boolean
  disablePadding?: boolean
  searchLabel?: string
  searchPlaceholder?: string
  filterConfigMap: FilterConfigMap<Item, FilterField>
  gaCategory: GaCategory
  initialFilterModel?: FilterModel<Item, FilterField>
  onFilterChange?: (value: FilterModel<Item, FilterField>) => void
  onSearchValueChange?: (value: string | null) => void
  readOnly?: boolean
  searchValue?: string | null
  variant?: FilterBarVariant
}

const FilterBar = forwardRef(function FilterBar<
  Item extends object,
  FilterField extends ListItemKey<Item>
>(
  {
    actions,
    addFilterMenu = EMPTY_ARRAY,
    children,
    defaultFilterModel = DEFAULT_FILTER_MODEL,
    disableAddFilter,
    disableClear,
    disableDeleteFilter,
    disableOrFilter,
    disablePadding,
    filterConfigMap,
    gaCategory,
    initialFilterModel,
    onFilterChange,
    onSearchValueChange,
    readOnly,
    searchValue = null,
    searchLabel,
    searchPlaceholder,
    variant = 'default',
  }: PropsWithChildren<FilterBarProps<Item, FilterField>>,
  filterBarApiRef: ForwardedRef<FilterApi<Item, FilterField>>
) {
  // Using useRef to improve performance, continuous re-renders slows down inputs too much
  const initialValues = useRef(
    filterModelToValues(mergeInitialValues(initialFilterModel, defaultFilterModel))
  )
  const isXs = useIsXs()
  const [filterPickerAnchorEl, setFilterPickerAnchorEl] = useState<HTMLElement | null>(null)
  const [activeGroupIndex, setActiveGroupIndex] = useState<number | null>(null)
  const defaultValues = useRef(filterModelToValues(defaultFilterModel))
  const prevFormValues = useRef<FilterModel<Item, FilterField>>()
  const formApi = useRef<FormApi<FilterFormValues<Item, FilterField>>>()
  const [isSearchActive, setIsSearchActive] = useState(false)

  // Detect whether the query param `?filter=...` was changed due to an external
  // event (e.g. by clicking a "Recently filtered" while on the companies page).
  // If so, reset the form to reflect that change.
  useEffect(() => {
    const newModel = filterModelToValues(mergeInitialValues(initialFilterModel, defaultFilterModel))
    if (
      formApi.current &&
      prevFormValues.current &&
      !isEqual(newModel.groups, prevFormValues.current)
    ) {
      formApi.current.reset(newModel)
    }
  }, [defaultFilterModel, initialFilterModel])

  const handleSetActiveGroupIndex = useCallback(
    (groupIndex: number | null) => () => {
      setActiveGroupIndex(groupIndex)
    },
    [setActiveGroupIndex]
  )

  const handleFilterChange = (value: FilterModel<Item, FilterField>) => {
    prevFormValues.current = value // Keep track of last-known form state
    onFilterChange?.(value)
  }

  // Add "filters" to the GA category for filter events
  const gaFiltersCategory = `${gaCategory} filters`

  const filterBarContext = useMemo(
    () => ({
      filterConfigMap,
      addFilterMenu,
      gaCategory: gaFiltersCategory,
      disableOrFilter,
    }),
    [filterConfigMap, addFilterMenu, gaFiltersCategory, disableOrFilter]
  )

  const handleSelectFilter = useCallback(
    (form: FormApi<FilterFormValues<Item, FilterField>>) =>
      (filter: FilterConfig<Item, FilterField>, allowOpenNonEmpty?: boolean) => {
        const values = form.getState().values
        const groupIndex = values.groups.findIndex((group) => {
          return group.value.some(
            (item) => item.filterId === filter.id && (allowOpenNonEmpty || item.value === null)
          )
        })

        // If we already have a filter with an empty value for the given field we open that one
        if (groupIndex !== -1) {
          setActiveGroupIndex(groupIndex)
        } else {
          // Otherwise we add the filter
          form.mutators['push'](
            'groups',
            filterValueGroup(filterValueItem<Item, FilterField>(filter.id))
          )
          const groups = form.getState().values.groups
          setActiveGroupIndex(groups.length !== undefined ? groups.length - 1 : null)
        }
        setFilterPickerAnchorEl(null)
      },
    []
  )

  const handleSearchStateChanged = useCallback((active: boolean) => {
    setIsSearchActive(active)
  }, [])

  if (isEmpty(initialValues.current.groups) && readOnly) {
    return null
  }

  return (
    <StyledFilterBarContainer variant={variant}>
      {isXs && (
        <Typography
          color={'text.secondary'}
          variant={'body2'}>
          Filters:
        </Typography>
      )}

      <FilterBarContext.Provider value={filterBarContext as unknown as FilterContextType}>
        <Form
          initialValues={initialValues.current}
          mutators={arrayMutators as never}
          onSubmit={noop}
          subscription={{ values: false }}>
          {({ form }) => {
            // Cannot get form api through ref, so we set it here
            formApi.current = form as unknown as FormApi<FilterFormValues<Item, FilterField>>
            return (
              <FieldArray name={'groups'}>
                {({ fields }) => (
                  <FilterApiRenderer
                    ref={filterBarApiRef}
                    form={form as unknown as FormApi<FilterFormValues<Item, FilterField>>}
                    initialValues={defaultValues.current}>
                    <StyledFilterBar disablePadding={disablePadding}>
                      <StyledFilterContainer>
                        <FilterBarValueChangeSpy onChange={handleFilterChange} />
                        {onSearchValueChange && (
                          <FilterBarSearch
                            gaCategory={gaCategory}
                            initialValue={searchValue}
                            label={searchLabel}
                            onChange={onSearchValueChange}
                            onSearchStateChanged={handleSearchStateChanged}
                            placeholder={searchPlaceholder}
                          />
                        )}

                        {/* Block interaction with other filter bar components when search is active */}
                        {isSearchActive && <StyledOverlay />}

                        {fields.map((name, groupIndex) => (
                          <FilterChip
                            key={groupIndex}
                            disableDelete={disableDeleteFilter}
                            groupIndex={groupIndex}
                            onClick={handleSetActiveGroupIndex(groupIndex)}
                            onClose={handleSetActiveGroupIndex(null)}
                            onRemoveGroup={(groupIndexToRemove) => {
                              // fields.remove changes the groups value to undefined when removing
                              // the last value in the array, groups array must always be an array
                              if (fields.length === 1) {
                                form.change('groups', [])
                              } else {
                                fields.remove(groupIndexToRemove)
                              }
                              setActiveGroupIndex(null)
                            }}
                            open={activeGroupIndex === groupIndex}
                            readOnly={readOnly}
                          />
                        ))}

                        {!readOnly && !disableAddFilter && (
                          <AddFilterButton
                            onClick={(event) => setFilterPickerAnchorEl(event.currentTarget)}
                          />
                        )}

                        {!readOnly && (
                          <AddFilterDropdownMenu
                            anchorEl={filterPickerAnchorEl}
                            gaCategory={gaFiltersCategory}
                            onClose={() => setFilterPickerAnchorEl(null)}
                            onSelect={handleSelectFilter(
                              form as unknown as FormApi<FilterFormValues<Item, FilterField>>
                            )}
                            open={Boolean(filterPickerAnchorEl)}
                            options={addFilterMenu}
                            searchPlaceholder={'Search filter'}
                          />
                        )}

                        <StyledActionsContainer>
                          {!readOnly && !disableClear && (
                            <FilterBarClearButton initialValues={defaultValues.current} />
                          )}
                          {actions && (
                            <FilterBarActionsContainer>{actions}</FilterBarActionsContainer>
                          )}
                        </StyledActionsContainer>
                      </StyledFilterContainer>

                      {children &&
                        Children.map(children, (child) =>
                          cloneElement(child as never, {
                            onSelectFilter: handleSelectFilter(
                              form as unknown as FormApi<FilterFormValues<Item, FilterField>>
                            ),
                          })
                        )}
                    </StyledFilterBar>
                  </FilterApiRenderer>
                )}
              </FieldArray>
            )
          }}
        </Form>
      </FilterBarContext.Provider>
    </StyledFilterBarContainer>
  )
})

export default FilterBar
