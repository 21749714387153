import { AssetListItem } from '@gain/rpc/app-model'
import { useOpenLink } from '@gain/utils/router'
import { MouseEvent, useCallback } from 'react'

import Card, { CardContentEmpty, CardHeader } from '../../../../common/card/card'
import TableValueDistribution, {
  ASSET_VALUE_SHARE_REGION_CONFIG,
  useValueDistributionRows,
  ValueDistributionRow,
} from '../../../../common/table-value-distribution'
import { useFlattenedDistributionRows } from '../../../../common/table-value-distribution/table-value-distribution-utils'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../../features/filter/filter-bar'
import {
  generateInvestorStrategyPagePath,
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../../route-strategy-path'

export interface InvestorStrategyRegionCardProps {
  assets: AssetListItem[]
}

export default function InvestorStrategyRegionCard({ assets }: InvestorStrategyRegionCardProps) {
  const params = useInvestorStrategyPageParams()
  const openLink = useOpenLink()
  const rows = useValueDistributionRows(assets, ASSET_VALUE_SHARE_REGION_CONFIG)

  const flattenedRows = useFlattenedDistributionRows(rows)

  const handleRowClick = useCallback(
    (row: ValueDistributionRow, event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      const regions = row.values ? row.values.map((item) => item.type) : row.type

      const path = [
        generateInvestorStrategyPagePath(params, INVESTOR_STRATEGY_ASSETS_PAGE_PATH),
        filterModelToQueryString<AssetListItem, 'region'>([
          filterValueGroup(filterValueItem('region', regions)),
        ]),
      ].join('?')
      openLink(path, event)
    },
    [openLink, params]
  )

  return (
    <Card
      sx={{ minHeight: 304 }}
      fullHeight>
      <CardHeader title={'Portfolio region'} />
      {flattenedRows.length === 0 ? (
        <CardContentEmpty message={'No data available'} />
      ) : (
        <TableValueDistribution
          itemLabel={'Region'}
          onRowClick={handleRowClick}
          rows={flattenedRows}
          shareLabel={'Companies'}
          disableExpand
        />
      )}
    </Card>
  )
}
