import { useUserProfileContext } from '@gain/modules/user'
import Avatar, { AvatarProps } from '@mui/material/Avatar'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { forwardRef } from 'react'

export type ProfileAvatarProps = AvatarProps

export const ProfileAvatar = forwardRef<HTMLDivElement, ProfileAvatarProps>((props, ref) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))
  const profile = useUserProfileContext()

  return (
    <Avatar
      {...props}
      ref={ref}
      src={!isSmDown ? profile.customerLogoUrl : undefined}>
      {[profile.firstName.slice(0, 1), profile.lastName.slice(0, 1)]
        .filter(Boolean)
        .map((char) => char.toUpperCase())
        .join('')}
    </Avatar>
  )
})
