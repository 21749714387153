import { UserPermissionChange, UserPermissionObjectType } from '@gain/rpc/app-model'
import { useCallback, useState } from 'react'

import {
  mutateGetUserPermissionsByObject,
  useDeleteUserPermissions,
  useUpsertUserPermissions,
} from '../../api/user-permission-hooks'
import { useShareContext } from './share-dialog-provider'

export default function useUpdateShareSettings() {
  const upsertPermissions = useUpsertUserPermissions()
  const deletePermissions = useDeleteUserPermissions()
  const [isLoading, setIsLoading] = useState(false)
  const { closeShareDialog } = useShareContext()

  const updatePermissions = useCallback(
    async (
      objectType: UserPermissionObjectType,
      objectId: number,
      permissions: UserPermissionChange[],
      deletePermissionIds: number[]
    ) => {
      setIsLoading(true)
      try {
        // Add new users to the object
        if (permissions.length > 0) {
          await upsertPermissions(objectType, objectId, permissions)
        }

        // Remove users from the object
        if (deletePermissionIds.length > 0) {
          await deletePermissions(deletePermissionIds)
        }

        // Refresh the user permissions state. There's no need to await here
        // since it has no effect on the current operation.
        mutateGetUserPermissionsByObject(objectType, objectId)

        closeShareDialog()
      } finally {
        setIsLoading(false)
      }
    },
    [closeShareDialog, deletePermissions, upsertPermissions]
  )

  return { updatePermissions, isLoading }
}
