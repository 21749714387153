import { styled } from '@mui/material/styles'
import MuiTooltip, { tooltipClasses, TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip'
import { useEffect, useMemo } from 'react'

export type TooltipVariant = 'preview' | 'none' | 'default' | 'prediction'

export type TooltipProps = Omit<MuiTooltipProps, 'classes'> & {
  variant?: TooltipVariant
  disablePadding?: boolean
  disabled?: boolean
}

interface StyledTooltipProps {
  variant: TooltipVariant
  disablePadding?: boolean
}

const StyledTooltip = styled(
  ({ variant, disablePadding, className, ...props }: MuiTooltipProps & StyledTooltipProps) => (
    <MuiTooltip
      classes={{ popper: className }}
      {...props}
    />
  )
)(({ theme, variant, disablePadding }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...(variant === 'preview' && {
      pointerEvents: 'auto',
      borderRadius: 8,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      padding: theme.spacing(3),
      ...theme.typography.body2,
      boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 30px 90px -20px rgba(0, 0, 0, 0.3)',
      ...(disablePadding && {
        padding: theme.spacing(0),
      }),
    }),
    ...(variant === 'none' && {
      borderRadius: 8,
      backgroundColor: 'transparent',
      borderWidth: 0,
      color: 'inherit',
      padding: 0,
      boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 30px 90px -20px rgba(0, 0, 0, 0.3)',
    }),
    ...(variant === 'prediction' && {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.background.paper,
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.warning.main,
      },
      [`& .${tooltipClasses.popper}`]: {
        zIndex: 10,
      },
    }),
  },
}))

const getDefaultProps = (
  variant: TooltipVariant
): Omit<TooltipProps, 'classes' | 'title' | 'children'> => {
  switch (variant) {
    case 'preview':
      return {
        arrow: false,
        enterDelay: 100,
        enterNextDelay: 100,
      }
    case 'none':
      return {
        arrow: false,
      }
    default:
      return {}
  }
}

export default function Tooltip({
  variant = 'default',
  disablePadding = false,
  children,
  onClose,
  open,
  disabled = false,
  ...props
}: TooltipProps) {
  const defaultProps = useMemo(() => getDefaultProps(variant), [variant])

  // The browser pauses the events for a long period of time when the users are scrolling.
  // It helps to keep 60 FPS. In our case, the browser defers the call of the mouseleave event.
  // It's why you will keep seeing the tooltip. Closing tooltips on scroll events mitigates
  // this issue but doesn't completely solve it.
  useEffect(() => {
    if (!onClose || !open) {
      return
    }

    window.addEventListener('scroll', onClose)
    return () => {
      window.removeEventListener('scroll', onClose)
    }
  }, [open, onClose])

  // If the tooltip is disabled show the children directly
  if (disabled) {
    return children
  }

  return (
    <StyledTooltip
      {...defaultProps}
      {...props}
      disablePadding={disablePadding}
      onClose={onClose}
      open={open}
      variant={variant}>
      {children}
    </StyledTooltip>
  )
}
