import { TagOutlinedIcon } from '@gain/components/icons'
import OverflowContainer from '@gain/components/overflow-container'
import Chip, { chipClasses } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(-0.5),
}))

const StyledChip = styled(Chip)(({ theme }) => ({
  // Makes sure the link below it will be clicked
  pointerEvents: 'none',
  color: theme.palette.text.primary,

  [`& .${chipClasses.icon}`]: {
    color: theme.palette.grey[600],
  },
}))

export default function SearchTopResultTags({ tags }: { tags: string[] }) {
  if (tags.length === 0) {
    return null
  }

  return (
    <Container>
      <OverflowContainer maxLines={2}>
        {tags.map((tag) => (
          <StyledChip
            key={tag}
            icon={<TagOutlinedIcon />}
            label={tag}
            size={'small'}
            variant={'outlined'}
          />
        ))}
      </OverflowContainer>
    </Container>
  )
}
