import Head from '@gain/modules/head'
import { Investor, RelatedDealAdvisor } from '@gain/rpc/app-model'
import isEmpty from 'lodash/isEmpty'
import { stringify } from 'query-string'
import { DelimitedNumericArrayParam } from 'serialize-query-params'
import { encodeQueryParams } from 'use-query-params'

import Card, { CardContentEmpty, CardHeader } from '../../../common/card/card'
import { calculateVisibleRows } from '../../../common/visible-rows'
import RelatedDealAdvisorsTable from '../../../features/related-deal-advisors-table'
import { generateInvestorAdvisorDealsPath, useInvestorPageParams } from '../../utils'

interface RouteAdvisorsProps {
  investor: Investor
  dealAdvisors: RelatedDealAdvisor[]
  availableHeight: number
}

export default function RouteAdvisors({
  investor,
  dealAdvisors,
  availableHeight,
}: RouteAdvisorsProps) {
  const { preview } = useInvestorPageParams()

  const nrOfVisibleRows = calculateVisibleRows(availableHeight)
  const generateDealsPath = (advisor: RelatedDealAdvisor) => {
    const path = generateInvestorAdvisorDealsPath({
      investorId: investor.id,
      investorName: investor.name,
      advisorName: advisor.advisorName,
      advisorId: advisor.advisorId,
      preview,
    })

    // Currently we do not have "buyerAdvisorIds" or "sellerAdvisorIds" in the deal model,
    // hence we pass the deal ids explicitly.
    const dealIds = encodeQueryParams(
      { dealIds: DelimitedNumericArrayParam },
      { dealIds: advisor.dealIds }
    )
    return `${path}?${stringify(dealIds)}`
  }

  return (
    <>
      <Head>
        <title>{investor.name} - Advisors</title>
      </Head>

      <Card sx={{ pb: 1, width: '100%', minHeight: 256 }}>
        <CardHeader title={'Deal advisors'} />
        {!isEmpty(dealAdvisors) ? (
          <RelatedDealAdvisorsTable
            dealsPath={generateDealsPath}
            items={dealAdvisors ?? []}
            nrOfVisibleRows={nrOfVisibleRows}
          />
        ) : (
          <CardContentEmpty message={'There are no advisors associated with this investor'} />
        )}
      </Card>
    </>
  )
}
