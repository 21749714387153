import { CompanyIcon, ExternalLinkIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { AssetListItem, SalesforceItem } from '@gain/rpc/app-model'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import Logo from '../../common/logo'

const StyledLink = styled(Link)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: 0,
  width: '100%',

  '&:hover': {
    backgroundColor: theme.palette.grey['200'],
    borderRadius: theme.spacing(1),
  },
}))

const StyledExternalLinkIcon = styled(ExternalLinkIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: 16,
  height: 16,
  marginLeft: theme.spacing(1),
}))

interface SalesforceLinkProps {
  salesforceItem: SalesforceItem
  asset: AssetListItem | undefined
}

export default function SalesforceLink({ salesforceItem, asset }: SalesforceLinkProps) {
  const logoName = (
    <Stack
      alignItems={'center'}
      direction={'row'}
      minWidth={0}
      spacing={1}>
      <Logo
        defaultIcon={<CompanyIcon />}
        size={22}
        src={asset?.logoFileUrl}
        variant={'rounded'}
      />
      <Typography
        color={'text.primary'}
        variant={'body2'}
        noWrap>
        {asset?.name || salesforceItem.name}
      </Typography>
    </Stack>
  )

  if (salesforceItem.url) {
    return (
      <StyledLink
        href={salesforceItem.url}
        rel={'noopener noreferrer'}
        target={'_blank'}
        underline={'none'}>
        {logoName}
        <StyledExternalLinkIcon />
      </StyledLink>
    )
  }

  return <Stack pl={1}>{logoName}</Stack>
}
