import { ListItemKey } from '@gain/rpc/list-model'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Fragment, MouseEvent, useCallback, useMemo, useState } from 'react'
import { useFieldArray } from 'react-final-form-arrays'

import DropdownMenu from '../../../../common/dropdown-menu'
import { useFilterBarContext } from '../filter-bar/filter-bar-context'
import { FilterConfig } from '../filter-config/filter-config-model'
import FilterItem from '../filter-item/filter-item'
import { filterValueItem } from '../filter-value-builders'
import { FilterValueItem } from '../filter-value-model'
import FilterItemDivider from './filter-item-divider'
import FilterPopover, { FilterPopoverProps } from './filter-popover'
import FilterSelectButton from './filter-select-button'

const StyledFooterContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.common.white,
  paddingBottom: theme.spacing(1),
}))

const StyledFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  padding: theme.spacing(1.5, 0),
}))

export interface FilterGroupMenuProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> extends FilterPopoverProps {
  groupIndex: number
  items: FilterValueItem<Item, FilterField>[]
  onRemoveGroup: (groupIndex: number) => void
  disableDelete?: boolean
}

/**
 * The FilterGroupMenu is a dropdown menu for filter options when clicking a filter chip. It
 * supports multiple filters as "OR" conditions. Multiple filters are separated visually by dividers.
 */
export default function FilterGroupMenu<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({
  groupIndex,
  items,
  onRemoveGroup,
  disableDelete,
  ...filterPopoverProps
}: FilterGroupMenuProps<Item, FilterField>) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { fields } = useFieldArray(`groups[${groupIndex}].value`)
  const { disableOrFilter, addFilterMenu, filterConfigMap, gaCategory } = useFilterBarContext<
    Item,
    FilterField
  >()

  const handleDelete = useCallback(
    (itemIndex: number) => () => {
      if (fields.length && fields.length === 1) {
        onRemoveGroup(groupIndex)
      } else {
        fields.remove(itemIndex)
      }
    },
    [groupIndex, fields, onRemoveGroup]
  )

  const handleSelectFilter = useCallback(
    (filter: FilterConfig<Item, FilterField>) => {
      fields.push(filterValueItem<Item, FilterField>(filter.id))
      setAnchorEl(null)
    },
    [fields]
  )

  const handleClose = useCallback(() => setAnchorEl(null), [])

  const handleSetAnchorEl = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    []
  )

  const visibleItems = useMemo(() => {
    return items
      .map((item, index) => ({
        item,
        index,
      }))
      .filter((item) => filterConfigMap[item.item.filterId])
  }, [filterConfigMap, items])

  return (
    <FilterPopover {...filterPopoverProps}>
      <>
        {visibleItems.map((item, index) => (
          <Fragment key={`${groupIndex}-${item.index}`}>
            {index > 0 && <FilterItemDivider />}
            <FilterItem
              disableDelete={disableDelete}
              groupIndex={groupIndex}
              itemIndex={item.index}
              onDelete={handleDelete(item.index)}
            />
          </Fragment>
        ))}

        {!disableOrFilter && (
          <StyledFooterContainer>
            <Divider sx={{ my: 0.5 }} />

            <StyledFooter>
              <Typography
                color={'text.secondary'}
                variant={'overline'}>
                Filter for{' '}
                {items
                  .filter((item) => filterConfigMap[item.filterId])
                  .map((item) => filterConfigMap[item.filterId].label)
                  .join(' or ')}{' '}
                or <FilterSelectButton onClick={handleSetAnchorEl} />
              </Typography>

              <DropdownMenu
                anchorEl={anchorEl}
                gaCategory={`${gaCategory} filters`}
                horizontalOrigin={'left'}
                onClose={handleClose}
                onSelect={handleSelectFilter}
                open={Boolean(anchorEl)}
                options={addFilterMenu}
                searchPlaceholder={'Search filter'}
              />
            </StyledFooter>
          </StyledFooterContainer>
        )}
      </>
    </FilterPopover>
  )
}
