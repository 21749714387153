import { CloseIcon, SearchIcon } from '@gain/components/icons'
import { noop } from '@gain/utils/common'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import InputAdornment, { inputAdornmentClasses } from '@mui/material/InputAdornment'
import { inputBaseClasses } from '@mui/material/InputBase'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { alpha, styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { MouseEvent } from 'react'

export const StyledTextField = styled(TextField)(({ theme }) => ({
  [`.${inputBaseClasses.root}`]: {
    height: 48,
    backgroundColor: theme.palette.grey['100'],
    borderRadius: 16,
    fontSize: 16,
    padding: theme.spacing(0, 1.5),
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),

    [`& .${inputBaseClasses.input}`]: {
      padding: theme.spacing(0, 1.5),

      [`&.${inputBaseClasses.inputAdornedStart}`]: {
        marginLeft: 0,
      },

      [`&.${inputAdornmentClasses.root}`]: {
        fontSize: 16,
      },
    },

    [`&.${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderWidth: 3,
      borderColor: theme.palette.grey['100'],
      borderRadius: 16,

      transition: theme.transitions.create('border-color', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shortest,
      }),
    },

    [`&.${inputBaseClasses.focused}`]: {
      backgroundColor: theme.palette.background.paper,

      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: `${theme.palette.primary.light} !important`,
      },
    },
  },
}))

const StyledResetButton = styled(IconButton)(({ theme }) => ({
  width: 16,
  height: 16,
  backgroundColor: alpha(theme.palette.text.primary, 0.2),
  borderRadius: '50%',
  fontSize: 12,
  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.text.primary,
  },
}))

export interface SearchFieldProps
  extends Pick<
    TextFieldProps,
    'onChange' | 'onClick' | 'inputRef' | 'value' | 'autoFocus' | 'className'
  > {
  onClear?: (event: MouseEvent) => void
  disableClear?: boolean
}

export default function SearchField({
  onClear = noop,
  disableClear = false,
  ...textFieldProps
}: SearchFieldProps) {
  return (
    <StyledTextField
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'}>
            <SearchIcon fontSize={'inherit'} />
          </InputAdornment>
        ),
        endAdornment: (
          <Fade in={!disableClear}>
            <StyledResetButton onClick={onClear}>
              <CloseIcon color={'inherit'} />
            </StyledResetButton>
          </Fade>
        ),
        inputProps: {
          spellCheck: false,
          autoComplete: 'off',
        },
      }}
      placeholder={'Search'}
      variant={'outlined'}
      fullWidth
      {...textFieldProps}
    />
  )
}
