import { SearchResultItem as ISearchResultItem } from '@gain/rpc/app-model'

import {
  correctItemDescription,
  correctItemIcon,
  correctItemName,
  correctItemRegion,
  correctItemTo,
  ObjectListItem,
} from '../../../object-list-item'
import { useCorrectMatchReason } from './use-correct-match-reason'

type SearchListItemProps = {
  item: ISearchResultItem | null
}

export default function SearchResultItem({ item }: SearchListItemProps) {
  const matchReason = useCorrectMatchReason(item)

  return (
    <ObjectListItem
      description={correctItemDescription(item?.type, item?.description, item?.regions)}
      icon={correctItemIcon(item?.type, item?.imageUrl)}
      imageUrl={item?.imageUrl}
      isSkeleton={item === null}
      name={correctItemName(item?.type, item?.name)}
      region={correctItemRegion(item?.type, item?.regions)}
      to={correctItemTo(item?.type, item?.id, item?.name)}
      type={item?.type}>
      {matchReason}
    </ObjectListItem>
  )
}
