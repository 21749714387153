import { formatPercentage } from './format-percentage'

export interface FormatBpsOptions {
  round?: number | 'auto' | 'smart'
  emptyValue?: string
}

export function formatBps(
  bps: number | null,
  { round = 'auto', emptyValue = '-' }: FormatBpsOptions = {}
) {
  return formatPercentage(bps, {
    round,
    emptyValue,
    suffix: 'bps',
  })
}
