import AccordionSummary, {
  accordionSummaryClasses,
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

import { CheckboxAccordionExpandIcon } from './checkbox-accordion-expand-icon.component'

export const CheckboxAccordionSummary = styled(
  ({ expandIcon = <CheckboxAccordionExpandIcon />, ...props }: AccordionSummaryProps) => (
    <AccordionSummary
      {...props}
      expandIcon={expandIcon}
    />
  )
)({
  padding: 0,
  minHeight: 32,
  [`& .${accordionSummaryClasses.content}`]: {
    justifyContent: 'space-between',
    margin: 0,
    minWidth: 0,
    [`&.${accordionSummaryClasses.expanded}`]: {
      margin: 0,
    },
  },
  [`&.${accordionSummaryClasses.expanded}`]: {
    minHeight: 32,
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    position: 'relative',
    right: -2,
    [`& > .${svgIconClasses.root}`]: {
      fontSize: 20,
    },
  },
})
