import Tooltip from '@gain/components/tooltip'
import { ListItemKey } from '@gain/rpc/list-model'
import { COUNTRY_CODE_MAP } from '@gain/utils/countries'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { uniq } from 'lodash'
import { useMemo } from 'react'

import { useCheckboxListFilterToggle } from '../filter/filter-bar/filter/filter-checkbox/use-checkbox-list-filter-toggle'

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0, 0.75 / 2),
  '&:first-child': {
    marginLeft: 0,
  },
  '&:last-child': {
    marginRight: 0,
  },
}))

export interface AssetRegionsTableCellProps<Item extends object, Field extends ListItemKey<Item>> {
  regionNames: string[]
  disableCounts?: boolean
  dense?: boolean
  maxItems?: number
  field?: Field
}

export function AssetRegionsTableCell<
  Item extends object = object,
  Field extends ListItemKey<Item> = never
>({
  regionNames,
  disableCounts = false,
  dense,
  maxItems = 3,
  field,
}: AssetRegionsTableCellProps<Item, Field>) {
  const [active, handleClick] = useCheckboxListFilterToggle<string, Item, Field>(regionNames, field)
  const regionCountMap = useMemo(
    () =>
      regionNames.reduce(
        (acc, current) => ({
          ...acc,
          [current]: (acc[current] || 0) + 1,
        }),
        {} as Record<string, number>
      ),
    [regionNames]
  )

  const formatLabel = (region: string) => {
    const title = COUNTRY_CODE_MAP[region]
    if (disableCounts) {
      return title
    }

    return `${title} (${regionCountMap[region]})`
  }

  const sortedRegions = useMemo(
    () => uniq(regionNames).sort((a, b) => regionCountMap[b] - regionCountMap[a]),
    [regionNames, regionCountMap]
  )

  const rest = sortedRegions.slice(maxItems)

  if (sortedRegions.length === 0) {
    return <>-</>
  }

  return (
    <>
      {sortedRegions.slice(0, maxItems).map((region, index) => (
        <Tooltip
          key={index}
          title={formatLabel(region)}
          variant={'preview'}
          disableInteractive>
          <StyledChip
            color={active.includes(region) ? 'info' : undefined}
            label={region}
            onClick={field ? handleClick([region]) : undefined}
            size={dense ? 'small' : 'medium'}
          />
        </Tooltip>
      ))}
      {rest.length > 0 && (
        <Tooltip
          disableInteractive={!field}
          title={
            <Stack
              display={'flex'}
              flexDirection={'row'}
              flexWrap={'wrap'}
              gap={0.5}>
              {rest.map((region) => (
                <StyledChip
                  key={region}
                  color={active.includes(region) ? 'info' : undefined}
                  label={formatLabel(region)}
                  onClick={field ? handleClick([region]) : undefined}
                  size={dense ? 'small' : 'medium'}
                />
              ))}
            </Stack>
          }
          variant={'preview'}>
          <StyledChip
            label={`+${rest.length}`}
            size={dense ? 'small' : 'medium'}
            variant={'outlined'}
          />
        </Tooltip>
      )}
    </>
  )
}
