import { BookmarkList } from '@gain/rpc/app-model'

import { useUpdateBookmarkList } from '../../../api/bookmark-list-hooks'
import BookmarkListTitleDialog from '../bookmark-list-title-dialog/bookmark-list-title-dialog'

interface BookmarkListRenameDialogProps {
  list: BookmarkList
  open: boolean
  onClose: () => void
}

export default function BookmarksListRenameDialog({
  list,
  onClose,
  open,
}: BookmarkListRenameDialogProps) {
  const updateList = useUpdateBookmarkList()

  const handleSubmit = async (title: string) => {
    await updateList({ id: list.id, title })
    onClose()
  }

  return (
    <BookmarkListTitleDialog
      dialogTitle={'Rename list'}
      onClose={onClose}
      onSubmit={handleSubmit}
      open={open}
      value={list.title}
    />
  )
}
