import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const StyledUl = styled('ul')({
  margin: 0,
  padding: '0 1em',
})

const StyledLi = styled('li')(({ theme }) => ({
  ...theme.typography.body2,
  marginLeft: theme.spacing(0.5),
}))

export default function AIExplainerRevenueContent() {
  return (
    <Stack
      direction={'column'}
      gap={1}>
      <Typography variant={'h5'}>Methodology</Typography>
      <Typography
        sx={{ pb: 2 }}
        variant={'body2'}>
        AI estimates are based on a neural network trained on our company dataset. Predictions are
        made based on various factors including business activity, growth rate and founding year.
        The predicted Revenue / FTE is multiplied by the last available FTE proxy to give an
        estimate for Revenue.
      </Typography>
      <Typography variant={'h5'}>Accuracy</Typography>
      <Typography variant={'body2'}>
        Our tests on companies with known revenues show that predictions are accurate within:
      </Typography>
      <StyledUl>
        <StyledLi>Factor of 2: 70% of the time</StyledLi>
        <StyledLi>Factor of 3: 88%</StyledLi>
        <StyledLi>Factor of 5: 97%</StyledLi>
        <StyledLi>Factor of 10: 99.3%</StyledLi>
      </StyledUl>
    </Stack>
  )
}
