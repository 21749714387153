import { useSubsectorList } from '@gain/api/app/hooks'
import { AssetListItem } from '@gain/rpc/app-model'
import { useMemo } from 'react'

import ButtonLink from '../../common/button-link'
import { ASSET_LIST_PATH } from '../../routes/utils'
import { filterModelToQueryString, filterValueGroup, filterValueItem } from '../filter/filter-bar'

interface FormatSubSectorProps {
  subSector?: string | null
}

export function FormatSubSector({ subSector }: FormatSubSectorProps) {
  const { data } = useSubsectorList({})

  const apiSubSector = useMemo(
    () => data?.items?.find((item) => item.name === subSector),
    [data?.items, subSector]
  )

  if (!apiSubSector) {
    return null
  }

  return (
    <ButtonLink
      href={[
        ASSET_LIST_PATH,
        filterModelToQueryString<AssetListItem, 'subsector'>([
          filterValueGroup(filterValueItem('subsector', subSector)),
        ]),
      ]
        .filter(Boolean)
        .join('?')}
      size={'small'}>
      {apiSubSector.title}
    </ButtonLink>
  )
}
