import { useRpcClient } from '@gain/api/swr'
import { SpinnerIcon } from '@gain/components/icons'
import { SnackbarProps } from '@gain/components/snackbar'
import Typography from '@gain/components/typography'
import { RpcMethodMap, SalesforceItem, SalesforceStatus } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'

import { showSalesforceResults } from './salesforce-snackbar'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  border: `1px solid ${theme.palette.divider}`,
  alignItems: 'center',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
  padding: theme.spacing(1, 2),
  boxSizing: 'border-box',
  color: theme.palette.text.primary,
  gap: theme.spacing(1),
}))

export function createFailedResult(assetId: number) {
  return {
    status: SalesforceStatus.Failed,
    error: 'Network error',
    assetId,
    entryId: 0,
    entryListId: 0,
    name: '' + assetId, // use id; if indeed a network error the name is empty and nothing can be shown to the user
    url: '',
  }
}

export const SalesforceProgress = forwardRef<
  HTMLDivElement,
  SnackbarProps & { assetIds: number[] }
>(function Snackbar({ assetIds, id, action, icon, disableCloseButton, variant = 'default' }, ref) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const fetchCompletedRef = useRef(false)
  const rpcClient = useRpcClient<RpcMethodMap>()
  const [results, setResults] = useState<SalesforceItem[]>([])

  const addToSalesforce = useCallback(async () => {
    let fetchedResults: SalesforceItem[] = []
    for (const assetId of assetIds) {
      try {
        const response = await rpcClient({
          method: 'data.addToSalesforce',
          params: {
            assetIds: [assetId],
          },
        })
        fetchedResults = fetchedResults.concat(response)
        setResults(fetchedResults)
      } catch (error) {
        fetchedResults.push(createFailedResult(assetId))
        setResults(fetchedResults)
      }
    }
    closeSnackbar(id)
    showSalesforceResults(enqueueSnackbar, fetchedResults)
  }, [id, assetIds, rpcClient, closeSnackbar, enqueueSnackbar])

  useEffect(() => {
    // Make sure there is only one addToSalesforce running because of the snackbar state changes
    if (!fetchCompletedRef.current) {
      addToSalesforce()
      fetchCompletedRef.current = true
    }
  }, [addToSalesforce])

  let message = 'Adding 1 company to Salesforce.'
  if (assetIds.length > 1) {
    message = `${results.length} out of ${assetIds.length} companies added to Salesforce.`
  }

  return (
    <StyledRoot ref={ref}>
      <SpinnerIcon />
      <Typography
        color={'text.primary'}
        variant={'body2'}
        noWrap>
        {message} <strong>You can continue using the app</strong>
      </Typography>
    </StyledRoot>
  )
})

export default SalesforceProgress
