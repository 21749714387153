import Head from '@gain/modules/head'
import { DealListItem, Investor } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'

import InvestorDealsList from '../investor-deals-list/investor-deals-list'

interface RouteExitsProps {
  investor: Investor
}

export default function RouteExits({ investor }: RouteExitsProps) {
  const defaultFilters = [listFilter<DealListItem>('sellerInvestorIds', '=', investor.id)]

  return (
    <>
      <Head>
        <title>{investor.name} - Exits</title>
      </Head>
      <InvestorDealsList defaultFilters={defaultFilters} />
    </>
  )
}
