import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/app-model'
import { ColumnVisibilityModel } from '@gain/utils/table'
import { styled } from '@mui/material/styles'

import { ContentLinkCellContainer } from '../../../../common/content-link'
import { ColumnConfig } from '../../../../common/table'
import AssetContentLink from '../../../../features/asset/asset-content-link'
import AssetFte from '../../../../features/asset/asset-fte'
import AssetTags from '../../../../features/asset/asset-tags/asset-tags'
import FinancialValue from '../../../../features/financial/financial-value'

const StyledFlagContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})

export const columnVisibility: ColumnVisibilityModel<AssetListItem> = {
  description: 800,
  tagIds: 1100,
  fte: 600,
  region: 440,
}

export const assetCompetitorsColumns: Array<ColumnConfig<AssetListItem>> = [
  {
    headerName: 'Name',
    field: 'name',
    renderCell: ({ row }) => (
      <ContentLinkCellContainer>
        <AssetContentLink
          asset={row}
          id={row.id}
          logoFileUrl={row.logoFileUrl}
          name={row.name}
        />
      </ContentLinkCellContainer>
    ),
  },
  {
    headerName: 'Business description',
    field: 'description',
  },
  {
    field: 'revenueWithAiGeneratedEur',
    headerName: 'Revenue',
    align: 'right',
    width: 120,
    renderCell: ({ value, row }) => (
      <FinancialValue
        amount={value}
        isAiGenerated={row.revenueIsAiGenerated}
        year={row.revenueYear}
      />
    ),
  },
  {
    field: 'fte',
    headerName: 'FTEs',
    align: 'right',
    width: 144,
    renderCell: ({ row }) => (
      <AssetFte
        fte={row.fte}
        range={row.fteRange}
        year={row.fteYear}
      />
    ),
  },
  {
    field: 'region',
    headerName: 'Company HQ',
    align: 'right',
    width: 120,
    renderCell: ({ value }) =>
      value ? (
        <StyledFlagContainer>
          <Flag code={value} />
        </StyledFlagContainer>
      ) : (
        <>-</>
      ),
  },
  {
    field: 'tagIds',
    headerName: 'Tags',
    align: 'left',
    width: 344,
    renderCell: ({ row }) => (
      <AssetTags
        tagIds={row.tagIds}
        tags={row.tags}
      />
    ),
  },
]
