import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { SVGProps } from 'react'

const multiLineChartLineClasses = generateUtilityClasses('MultiLineChartLine', [
  'active',
  'inactive',
])

const StyledLine = styled('path')(({ theme }) => ({
  fill: 'none',
  strokeWidth: 1,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.short,
  }),
  opacity: 1,
  [`&.${multiLineChartLineClasses.active}`]: {},
  [`&.${multiLineChartLineClasses.inactive}`]: {
    opacity: 0.3,
  },
}))

export interface MultiLineChartLineProps extends SVGProps<SVGPathElement> {
  active?: boolean
  inactive?: boolean
}

export default function MultiLineChartLine({
  active,
  inactive,
  ...props
}: MultiLineChartLineProps) {
  return (
    <StyledLine
      {...props}
      className={clsx({
        [multiLineChartLineClasses.active]: active,
        [multiLineChartLineClasses.inactive]: inactive,
      })}
    />
  )
}
