import NewsFeedItemArticleSkeleton from './news-feed-item-article-skeleton'
import NewsFeedItemHeaderSkeleton from './news-feed-item-header-skeleton'

export default function NewsFeedSkeleton() {
  return (
    <div>
      <NewsFeedItemHeaderSkeleton />
      <NewsFeedItemArticleSkeleton />
      <NewsFeedItemArticleSkeleton />
      <NewsFeedItemArticleSkeleton />
      <NewsFeedItemArticleSkeleton />
      <NewsFeedItemArticleSkeleton />
      <NewsFeedItemArticleSkeleton />
    </div>
  )
}
