import { TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import { useDialogState } from '@gain/utils/dialog'

import ShareFeedbackAlert from './share-feedback-alert'
import ShareFeedbackDialog from './share-feedback-dialog'
import ShareFeedbackInfoDialog from './share-feedback-info-dialog'

export interface ShareFeedbackBarProps {
  title: string
  type: TicketType
  /**
   * Attributes that need to be send to Zendesk along with the feedback
   */
  attributes: Array<{ type: TicketAttributeType; value: string }>
  /**
   * Fields to show (in disabled state) in the feedback form
   */
  disabledInfoFields: Array<{ label: string; value: string }>
  /**
   * Descriptions to show inside the "Why would I share" dialog
   */
  infoDescriptions: string[]
}

export default function ShareFeedbackBar({ infoDescriptions, ...props }: ShareFeedbackBarProps) {
  const [feedbackDialogOpen, handleOpenFeedbackDialog, handleCloseFeedbackDialog] = useDialogState()
  const [feedbackInfoDialogOpen, handleOpenInfoDialog, handleCloseInfoDialog] = useDialogState()

  return (
    <>
      <ShareFeedbackDialog
        onClose={handleCloseFeedbackDialog}
        open={feedbackDialogOpen}
        {...props}
      />

      <ShareFeedbackInfoDialog
        descriptions={infoDescriptions}
        onClose={handleCloseInfoDialog}
        open={feedbackInfoDialogOpen}
      />

      <ShareFeedbackAlert
        onOpenFeedbackDialog={handleOpenFeedbackDialog}
        onOpenInfoDialog={handleOpenInfoDialog}
      />
    </>
  )
}
