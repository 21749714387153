import { useDealList } from '@gain/api/app/hooks'
import { AssetList, DealListItem, Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { createListFilterForPastFiveYears, listFilter, listFilters } from '@gain/rpc/utils'
import { useFormatCurrencyCallback, useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { median } from 'd3'

export function useInvestorStrategyDealCount(investor: Investor, strategy: InvestorStrategy) {
  const swrDeals = useDealList({
    filter: listFilters<DealListItem>(
      listFilter('buyerInvestorIds', '=', investor.id),
      listFilter('buyerStrategyIds', '=', strategy.id),
      createListFilterForPastFiveYears('announcementDate')
    ),
    limit: 0,
  })

  return swrDeals.data?.counts.filtered || null
}

export function useInvestorStrategyMedianEbitda(assets?: AssetList) {
  const formatCurrency = useFormatCurrencyCallback()

  if (!assets) {
    return null
  }

  const medianEbitda = median(
    assets.items.map((asset) => asset.ebitdaEur).filter((ebitda) => ebitda !== null && ebitda > 0)
  )
  if (!medianEbitda) {
    return null
  }

  return formatCurrency(medianEbitda)
}

export function useInvestorStrategyDryPowder(strategy: InvestorStrategy): string | null {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  if (strategy.dryPowderMinEur === null || strategy.dryPowderMaxEur === null) {
    return null
  }

  return formatCurrencyRange(strategy.dryPowderMinEur, strategy.dryPowderMaxEur, {
    round: 'estimate',
  })
}
