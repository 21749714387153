import { Route, Switch } from 'react-router-dom'

import { SETTINGS_ACCOUNT_PATH, SETTINGS_EMAIL_PATH, SETTINGS_TEAM_MEMBERS_PATH } from '../utils'
import SettingsAccountRoute from './settings-account'
import SettingsEmailRoute from './settings-email'
import SettingsTeamMembersRoute from './settings-team-members'

export default function SettingsRoutes() {
  return (
    <Switch>
      <Route
        component={SettingsAccountRoute}
        path={SETTINGS_ACCOUNT_PATH}
        exact
      />

      <Route
        component={SettingsEmailRoute}
        path={SETTINGS_EMAIL_PATH}
        exact
      />

      <Route
        component={SettingsTeamMembersRoute}
        path={SETTINGS_TEAM_MEMBERS_PATH}
        exact
      />
    </Switch>
  )
}
