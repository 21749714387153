import {
  useCreditList,
  useLender,
  useLenderListItem,
  useListLenderInvestors,
} from '@gain/api/app/hooks'
import { LenderIcon } from '@gain/components/icons'
import { SlotHome, SlotName } from '@gain/components/slot'
import { getLabelFromOption, LENDER_TYPE_OPTIONS } from '@gain/rpc/shared-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import { styled } from '@mui/material/styles'
import { generatePath, Redirect, Route, Switch, useHistory } from 'react-router-dom'

import Loading from '../../common/loading'
import ProfileTabBar, {
  ProfileTab,
  ProfileTabContainer,
  useActiveTab,
} from '../../common/profile-tab-bar'
import { useTrackPageView } from '../../features/planhat/planhat-hooks'
import MobilePageHeader from '../../layout/mobile-page-header'
import NotFound from '../not-found'
import LenderOffline from './lender-offline'
import {
  LENDER_COMPANIES_PATH,
  LENDER_CREDITS_PATH,
  LENDER_INVESTORS_PATH,
  LENDER_SUMMARY_PATH,
  RouteLenderTabs,
  useLenderPageParams,
} from './lender-path'
import RouteCompanies from './route-companies/route-companies'
import RouteCredits from './route-credits/route-credits'
import RouteInvestors from './route-investors/route-investors'
import { KEY_INVESTORS_SORT } from './route-summary/key-investors-card'
import RouteSummary from './route-summary/route-summary'

interface UseLenderTabsOptions {
  hasCredits: boolean
  hasCompanies: boolean
  hasInvestors: boolean
}

export function useLenderTabs(options: UseLenderTabsOptions): ProfileTab[] {
  const params = useLenderPageParams()

  return [
    {
      label: 'Summary',
      path: generatePath(LENDER_SUMMARY_PATH, params),
      value: RouteLenderTabs.Summary,
    },
    {
      label: 'Credits',
      path: generatePath(LENDER_CREDITS_PATH, params),
      value: RouteLenderTabs.Credits,
      hidden: !options.hasCredits,
    },
    {
      label: 'Companies',
      path: generatePath(LENDER_COMPANIES_PATH, params),
      value: RouteLenderTabs.Companies,
      hidden: !options.hasCompanies,
    },
    {
      label: 'Investors',
      path: generatePath(LENDER_INVESTORS_PATH, params),
      value: RouteLenderTabs.Investors,
      hidden: !options.hasInvestors,
    },
  ]
}

const StyledProfileTabContainer = styled(ProfileTabContainer)({
  flex: 1,
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
})

export default function RouteLender() {
  const params = useLenderPageParams()
  const history = useHistory()
  const isXs = useIsXs()

  const swrLender = useLender({ id: params.id })

  // Load lender list item to determine if the profile is published and for
  // aggregated metrics
  const swrLenderListItem = useLenderListItem(params.id)

  // Load credits to determine if we can show the credits and companies tabs
  const swrCredits = useCreditList({
    filter: listFilters(listFilter('lenderIds', '=', params.id)),
    sort: [listSort('debtQuantumEur', 'desc')],
    limit: 500,
  })

  // Load key investors to determine if we can show the investor tab
  const swrKeyInvestors = useListLenderInvestors({
    lenderId: params.id,
    sort: KEY_INVESTORS_SORT,
    limit: 4,
  })

  const tabs = useLenderTabs({
    hasCredits: !!swrCredits?.data.items.length,
    hasCompanies: swrCredits?.data.items.some((credit) => !!credit.assetId),
    hasInvestors: !!swrKeyInvestors?.data.items.length,
  })
  const activeTab = useActiveTab(tabs)

  const isLoading =
    swrLender.loading || swrLenderListItem.loading || swrCredits.loading || swrKeyInvestors.loading

  useTrackPageView('lender', {
    id: params.id,
    tab: activeTab,
    requireTab: true,
  })

  if (isLoading) {
    return <Loading />
  }

  if (!swrLenderListItem.data) {
    return <NotFound />
  }

  if (!swrLenderListItem.data.live) {
    return <LenderOffline lender={swrLenderListItem.data} />
  }

  // Ensure data is properly loaded, this is needed to indicate to TypeScript
  // that the data props are defined
  if (!swrLender.data || !swrCredits.data || !swrKeyInvestors.data) {
    // This should not happen but in case rpc calls have finished loading and
    // data is not there, show not found page
    return <NotFound />
  }

  const summaryPath = generatePath(LENDER_SUMMARY_PATH, params)

  return (
    <>
      {isXs && <MobilePageHeader title={swrLender.data.name} />}

      <ProfileTabBar
        activeTab={activeTab}
        defaultIcon={!isXs ? LenderIcon : undefined}
        logoFileUrl={swrLender.data.logoFileUrl}
        onLogoClick={() => history.push(summaryPath)}
        subtitle={getLabelFromOption(LENDER_TYPE_OPTIONS, swrLender.data.type)}
        tabActions={<SlotHome slotName={SlotName.Tabs} />}
        tabs={tabs}
        title={swrLender.data.name}
        replaceUrl
        sticky
      />

      <StyledProfileTabContainer
        maxWidth={'lg'}
        stickyVariant={'tabs'}
        disableGuttersTop>
        <Switch>
          <Route path={LENDER_SUMMARY_PATH}>
            <RouteSummary
              credits={swrCredits.data.items}
              keyInvestors={swrKeyInvestors.data.items}
              lender={swrLender.data}
              lenderListItem={swrLenderListItem.data}
            />
          </Route>

          <Route path={LENDER_CREDITS_PATH}>
            <RouteCredits lender={swrLender.data} />
          </Route>

          <Route path={LENDER_COMPANIES_PATH}>
            <RouteCompanies lender={swrLender.data} />
          </Route>

          <Route path={LENDER_INVESTORS_PATH}>
            <RouteInvestors lender={swrLender.data} />
          </Route>

          <Route path={'*'}>
            <Redirect to={generatePath(LENDER_SUMMARY_PATH, params)} />
          </Route>
        </Switch>
      </StyledProfileTabContainer>
    </>
  )
}
