import { useAssetList } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'

import Benchmarking from '../../../features/benchmarking'
import { BenchmarkingContextProvider } from '../../../features/benchmarking/benchmarking-context'
import { INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL } from '../../../features/investor-strategy/investor-strategy-contants'
import { useTrackPageView } from '../../../features/planhat/planhat-hooks'

export interface RouteBenchmarkingProps {
  investor: Investor
  strategy: InvestorStrategy
}

export default function RouteBenchmarking({ investor, strategy }: RouteBenchmarkingProps) {
  useTrackPageView('investor_strategy', { id: strategy.id, tab: 'benchmarking', requireTab: true })

  const swrAssets = useAssetList({
    filter: listFilters(listFilter('strategyIds', '=', strategy.id)),
    sort: [listSort('revenueEur', 'desc')],
    limit: 500,
  })

  const name = `${investor?.name || ''} - ${
    strategy.name || INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL
  }`

  return (
    <>
      <Head>
        <title>{name} - Analysis</title>
      </Head>

      <BenchmarkingContextProvider
        value={{
          name,
          objectId: strategy.id,
          objectType: 'investorStrategy',
        }}>
        <Benchmarking
          initialAssets={swrAssets.data.items}
          loadingInitialAssets={swrAssets.loading}
        />
      </BenchmarkingContextProvider>
    </>
  )
}
