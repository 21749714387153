import type {} from '@mui/lab/themeAugmentation'
import { createTheme } from '@mui/material/styles'
import { CSSProperties } from 'react'

import { breakpoints } from './breakpoints'
import { createComponents } from './components'
import { palette } from './palette'
import { spacing } from './spacing'
import { typography } from './typography'

interface ShadowMap {
  level1a: string
  level1b: string
  level1c: string
  level1d: string
}

interface CardMap {
  asset: CSSProperties['color']
  owner: CSSProperties['color']
}

export type ChartColors = NonNullable<CSSProperties['color']>[]

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    chart: ChartColors
    cards: CardMap
    shadow: ShadowMap
  }

  interface PaletteOptions {
    chart: ChartColors
    cards: CardMap
    shadow: ShadowMap
  }
}

const globalTheme = createTheme({
  typography,
  palette,
  breakpoints,
  spacing,

  zIndex: {
    // Ensure to following order in app from top to bottom: dialog, menu, snackbar
    modal: 1300,
    snackbar: 1200,
  },
})

globalTheme.typography.h1[globalTheme.breakpoints.only('xs')] = {
  fontSize: 32,
  lineHeight: '40px',
}

globalTheme.palette.grey['50'] = '#F7F9FA'
globalTheme.palette.grey['100'] = '#EFF2F4'
globalTheme.palette.grey['200'] = '#E7E9EA'
globalTheme.palette.grey['300'] = '#D7D9DA'
globalTheme.palette.grey['400'] = '#B3B5B6'
globalTheme.palette.grey['500'] = '#939596'
globalTheme.palette.grey['600'] = '#6B6C6D'
globalTheme.palette.grey['700'] = '#57595A'
globalTheme.palette.grey['800'] = '#393A3B'
globalTheme.palette.grey['900'] = '#191A1B'

globalTheme.components = createComponents(globalTheme)

export const theme = globalTheme
