import {
  AlertCircleIcon,
  AlertTriangleIcon,
  CheckCircleIcon,
  InfoIcon,
} from '@gain/components/icons'
import { alpha, Components, Theme } from '@mui/material/styles'

export function MuiAlert(theme: Theme): Components['MuiAlert'] {
  return {
    styleOverrides: {
      root: {
        borderRadius: 8,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        lineHeight: theme.typography.body2.lineHeight,
        padding: theme.spacing(1.25, 2),
      },
      standardError: {
        backgroundColor: alpha(theme.palette.error.main, 0.08),
        color: theme.palette.error.dark,
      },
      standardInfo: {
        backgroundColor: alpha(theme.palette.info.main, 0.08),
        color: theme.palette.info.main,
      },
      standardSuccess: {
        backgroundColor: alpha(theme.palette.success.main, 0.08),
        color: theme.palette.success.main,
      },
      standardWarning: {
        backgroundColor: alpha(theme.palette.warning.main, 0.08),
        color: theme.palette.warning.main,
      },
      icon: {
        padding: theme.spacing(0.25, 0),
        fontSize: 16,
      },
      message: {
        padding: theme.spacing(0),
      },
    },
    defaultProps: {
      iconMapping: {
        error: <AlertCircleIcon fontSize={'inherit'} />,
        info: <InfoIcon fontSize={'inherit'} />,
        success: <CheckCircleIcon fontSize={'inherit'} />,
        warning: <AlertTriangleIcon fontSize={'inherit'} />,
      },
    },
  }
}
