import { AssetListItem, InvestorStrategyListItem } from '@gain/rpc/app-model'
import {
  AssetListItemEsgRatings,
  AssetListItemRatings,
  getMedianAssetEsgRatings,
  getMedianAssetRatings,
} from '@gain/utils/asset'
import { compareNumberDesc } from '@gain/utils/common'

export interface InvestorStrategyRatings {
  id: number
  name: string
  assets: AssetListItem[]
  investmentCriteria: AssetListItemRatings
  esg: AssetListItemEsgRatings
}

export function getRatingsPerStrategy(
  strategies: InvestorStrategyListItem[],
  assets: AssetListItem[]
): InvestorStrategyRatings[] {
  return assets
    .reduce(
      (acc, current) => {
        const strategy = strategies.find((item) => item.assetIds.includes(current.id))

        if (strategy && strategy.strategyId && strategy.strategyName) {
          const index = acc.findIndex((item) => item.id === strategy.strategyId)
          if (index > -1) {
            acc[index].assets.push(current)
          } else {
            acc.push({
              id: strategy.strategyId,
              name: strategy.strategyName,
              assets: [current],
            })
          }
        } else {
          const index = acc.findIndex((item) => item.id === 0)
          if (index > -1) {
            acc[index].assets.push(current)
          } else {
            acc.push({
              id: 0,
              name: strategies.some((item) => item.strategyId !== null) ? 'Unknown' : 'Undefined',
              assets: [current],
            })
          }
        }

        return acc
      },
      new Array<{
        id: number
        name: string
        assets: AssetListItem[]
      }>()
    )
    .sort((a, b) => compareNumberDesc(a.assets.length, b.assets.length))
    .map((strategy) => {
      return {
        ...strategy,
        investmentCriteria: getMedianAssetRatings(strategy.assets),
        esg: getMedianAssetEsgRatings(strategy.assets),
      }
    })
}
