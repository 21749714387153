import { ArrowUpRightIcon } from '@gain/components/icons'
import { formatUrlHostname } from '@gain/utils/common'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}))

export interface ListItemIconValueLinkProps {
  url: string
}

const ListItemIconValueLink = forwardRef<HTMLAnchorElement, ListItemIconValueLinkProps>(
  function ListItemIconValueLink({ url, ...props }, ref) {
    return (
      <StyledLink
        {...props}
        ref={ref}
        href={url}
        rel={'noopener noreferrer'}
        target={'_blank'}
        variant={'body2'}>
        {formatUrlHostname(url)}
        <ArrowUpRightIcon fontSize={'small'} />
      </StyledLink>
    )
  }
)

export default ListItemIconValueLink
