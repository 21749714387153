import { ChevronDownIcon, ChevronRightIcon } from '@gain/components/icons'
import Button, { buttonClasses } from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { styled, SxProps } from '@mui/material/styles'
import { MouseEvent, ReactNode } from 'react'

import ConditionalWrapper from '../conditional-wrapper'

const StyledButtonMore = styled(Button)(({ theme }) => ({
  [`.${buttonClasses.endIcon}`]: {
    marginLeft: theme.spacing(0.5),
  },
  [`.${buttonClasses.startIcon}`]: {
    marginRight: theme.spacing(0.5),
  },
}))

export interface ViewMoreButtonProps {
  amount?: number
  disablePadding?: boolean
  href?: string
  onClick?: (event: MouseEvent) => void
  variant?: 'plus' | 'chevron-down' | 'chevron-right'
  sx?: SxProps
  children?: ReactNode
}

export default function ViewMoreButton({
  amount,
  href,
  onClick,
  variant = 'plus',
  sx,
  children,
  disablePadding = false,
}: ViewMoreButtonProps) {
  if (amount !== undefined && amount <= 0) {
    return null
  }

  let copy = `${amount} more`
  switch (variant) {
    case 'plus':
      copy = `+ ${copy}`
      break
    case 'chevron-right':
    case 'chevron-down':
      copy = `View ${copy}`
      break
  }

  return (
    <ConditionalWrapper
      condition={!disablePadding}
      wrapper={(wrappedChildren) => (
        <Stack
          direction={'row'}
          px={2}
          py={0.5}>
          {wrappedChildren}
        </Stack>
      )}>
      <StyledButtonMore
        color={'primary'}
        endIcon={variant === 'chevron-right' && <ChevronRightIcon />}
        href={href}
        onClick={onClick}
        startIcon={variant === 'chevron-down' && <ChevronDownIcon />}
        sx={sx}>
        {children !== undefined ? children : copy}
      </StyledButtonMore>
    </ConditionalWrapper>
  )
}
