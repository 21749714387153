import { ChevronLeftIcon } from '@gain/components/icons'
import Head from '@gain/modules/head'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'

import ResourceOfflinePage from '../../common/resource-not-available'
import { HOME_PATH } from '../utils'

export default function NotFound() {
  return (
    <>
      <Head>
        <title>Not found</title>
      </Head>

      <ResourceOfflinePage
        actions={
          <Button
            color={'primary'}
            component={Link}
            href={HOME_PATH}
            startIcon={<ChevronLeftIcon />}
            variant={'contained'}>
            Back to homepage
          </Button>
        }
        message={
          'Sorry, but the page you were trying to view does not exist. You might have mistyped the address or the page may have been moved.'
        }
        title={'Page does not exist'}
      />
    </>
  )
}
