import Head from '@gain/modules/head'
import { useUserProfileContext } from '@gain/modules/user'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { useTrackPageView } from '../../../features/planhat/planhat-hooks'
import ExportLimits from './export-limits'
import UpdateAccountForm from './update-account-form'
import UpdatePasswordForm from './update-password-form'

const StyledContainer = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

export default function SettingsAccountRoute() {
  const user = useUserProfileContext()
  useTrackPageView('settings', { id: user?.id, tab: 'account', requireTab: true })

  // Bail out if no user available
  if (user === null) {
    return null
  }

  return (
    <StyledContainer maxWidth={'md'}>
      <Head>
        <title>My account</title>
      </Head>

      <Box paddingY={4}>
        <Grid
          direction={'column'}
          spacing={2}
          container>
          <Grid item>
            <Typography variant={'h2'}>My account</Typography>
          </Grid>

          <Grid item>
            <UpdateAccountForm />
          </Grid>

          {/* Don't render password form for SSO users */}
          {!user.isSsoUser && (
            <Grid item>
              <UpdatePasswordForm />
            </Grid>
          )}
        </Grid>
      </Box>

      <ExportLimits />
    </StyledContainer>
  )
}
