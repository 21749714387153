import Flag from '@gain/components/flag'
import OverflowContainer, { overflowContainerClasses } from '@gain/components/overflow-container'
import { AssetListItem, InvestorManagerListItem } from '@gain/rpc/app-model'
import { ObjectType } from '@gain/rpc/shared-model'
import { listFilter, listSort } from '@gain/rpc/utils'
import { useIsXs } from '@gain/utils/responsive'
import { formatSubsector } from '@gain/utils/sector'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'

import LinkedInButton from '../../../common/linked-in-button/linked-in-button'
import {
  MobileListItem,
  MobileListItemText,
  MobileListItemValue,
} from '../../../common/list-mobile'
import Tag from '../../../common/tag'
import { createVirtualTableColumns } from '../../../common/virtual-table'
import { NameVirtualTableHeaderCell } from '../../../common/virtual-table/cells'
import AssetContentLink from '../../asset/asset-content-link'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../filter/filter-bar'
import RequestEmailButton from '../../request-email-button/request-email-button'
import ListView from '..'

const StyledFlagsContainer = styled('div')({
  [`& .${overflowContainerClasses.visible}`]: {
    display: 'flex',
    alignItems: 'center',
  },
})

function useColumns(investorId: number, portfolioPath: string, assetsIds?: number[]) {
  return useMemo(() => {
    return createVirtualTableColumns<InvestorManagerListItem>(
      {
        field: 'fullName',
        headerName: 'Name',
        width: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => value,
        renderHeader: NameVirtualTableHeaderCell,
      },
      {
        field: 'linkedInUrl',
        headerName: 'Contact',
        width: 88,
        sortable: false,
        renderCell: ({ value, row }) => {
          return (
            <Stack
              flexDirection={'row'}
              gap={0.25}
              marginLeft={-0.75}>
              <RequestEmailButton
                objectId={investorId}
                objectType={ObjectType.Investor}
                person={{
                  id: row.personId,
                  firstName: row.firstName,
                  lastName: row.lastName,
                }}
              />
              <LinkedInButton url={value} />
            </Stack>
          )
        },
      },
      {
        field: 'regions',
        headerName: 'Regions',
        width: 90,
        sortable: false,
        renderCell: ({ value }) => {
          return (
            <StyledFlagsContainer>
              <OverflowContainer maxLines={1}>
                {value.map((region) => (
                  <Flag
                    key={region}
                    code={region}
                  />
                ))}
              </OverflowContainer>
            </StyledFlagsContainer>
          )
        },
      },
      {
        field: 'subsectors',
        headerName: 'Sectors',
        width: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => {
          return (
            <OverflowContainer
              lineHeight={16}
              maxLines={1}>
              {value.map((subsector) => (
                <Tag
                  key={subsector}
                  href={[
                    portfolioPath,
                    filterModelToQueryString<AssetListItem, 'subsector'>([
                      filterValueGroup(filterValueItem('subsector', [subsector])),
                    ]),
                  ].join('?')}
                  label={formatSubsector(subsector) as string}
                />
              ))}
            </OverflowContainer>
          )
        },
      },
      {
        field: 'assetCount',
        headerName: 'Portfolio',
        width: 200,
        flex: 1,
        renderCell: ({ row }) => {
          const rowAssets = assetsIds
            ? row.assets.filter((asset) => assetsIds.includes(asset.id))
            : row.assets

          return (
            <OverflowContainer
              lineHeight={24}
              maxLines={1}
              spacing={1}>
              {rowAssets.map((asset) => (
                <AssetContentLink
                  key={asset.id}
                  id={asset.id}
                  logoFileUrl={asset.logoFileUrl}
                  name={asset.name}
                  variant={'logo'}
                />
              ))}
            </OverflowContainer>
          )
        },
      }
    )
  }, [assetsIds, investorId, portfolioPath])
}

interface RepresentativesListViewProps {
  investorId: number
  portfolioPath: string
  assetsIds?: number[]
}

export default function RepresentativesListView({
  investorId,
  portfolioPath,
  assetsIds,
}: RepresentativesListViewProps) {
  const isXs = useIsXs()
  const columns = useColumns(investorId, portfolioPath, assetsIds)

  return (
    <ListView
      addFilterMenu={[]}
      defaultFilter={[listFilter<InvestorManagerListItem>('investorId', '=', investorId)]}
      defaultSort={[listSort('assetCount', 'desc'), listSort('firstName', 'asc')]}
      disableFilters={true}
      filterConfigMap={{}}
      inline={isXs}
      method={'data.listInvestorManagers'}
      sm={{
        variant: 'virtual-table',
        VariantProps: {
          RowComponentProps: {
            hover: false,
          },
          variant: 'inline',
          columns,
        },
      }}
      xs={{
        variant: 'list',
        VariantProps: {
          headerProps: {
            title: 'Name',
            secondaryTitle: 'Contact',
          },
          renderListItem: (item) => (
            <MobileListItem
              key={item.id}
              disableDivider>
              <MobileListItemText primary={item.fullName} />
              <MobileListItemValue>
                <Stack
                  flexDirection={'row'}
                  gap={0.25}>
                  <RequestEmailButton
                    objectId={investorId}
                    objectType={ObjectType.Investor}
                    person={{
                      id: item.personId,
                      firstName: item.firstName,
                      lastName: item.lastName,
                    }}
                  />
                  <LinkedInButton url={item.linkedInUrl} />
                </Stack>
              </MobileListItemValue>
            </MobileListItem>
          ),
        },
      }}
    />
  )
}
