import { SpinnerIcon } from '@gain/components/icons'
import { styled } from '@mui/material/styles'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export default function CardContentLoading() {
  return (
    <StyledRoot>
      <SpinnerIcon />
    </StyledRoot>
  )
}
