import { FilterableInvestorStrategyListItem, InvestorStrategyListItem } from '@gain/rpc/app-model'
import { ratingEsg, RatingTableCell } from '@gain/utils/investment-criteria'
import { formatNumber } from '@gain/utils/number'

import EbitdaValuesRange from '../../../common/ebitda-values-range'
import { createVirtualTableColumns } from '../../../common/virtual-table'
import {
  NameVirtualTableCell,
  NameVirtualTableHeaderCell,
} from '../../../common/virtual-table/cells'
import { AssetRegionsTableCell } from '../../asset/asset-regions-table-cell'
import { AssetSectorsTableCell } from '../../asset/asset-sectors-table-cell'
import FinancialRangeValue from '../../financial/financial-range-value'
import FinancialValue from '../../financial/financial-value'
import InvestorContentLink from '../../investor/investor-content-link'
import { InvestorListItemAssetsTotalTableCell } from '../../investor/investor-list-item-table/investor-list-item-assets-total-table-cell'
import { InvestorListItemFlagshipFundNameTableCell } from '../../investor/investor-list-item-table/investor-list-item-flagship-fund-name-table-cell'
import InvestorClassificationsTableCell from './investor-classifications-table-cell'

export const investorStrategyTableColumns =
  createVirtualTableColumns<FilterableInvestorStrategyListItem>(
    {
      field: 'strategyId',
      headerName: 'Strategy',
      align: 'left',
      width: 266,
      sticky: true,
      flex: 1,
      renderHeader: NameVirtualTableHeaderCell,
      renderCell: ({ row }) => (
        <NameVirtualTableCell
          description={row.investorName}
          name={row.strategyName}
        />
      ),
    },
    {
      field: 'investorId',
      headerName: 'Investor',
      align: 'left',
      width: 272,
      renderCell: ({ row }) => (
        <InvestorContentLink
          id={row.investorId}
          logoFileUrl={row.investorLogoFileUrl}
          name={row.investorName}
        />
      ),
    },
    {
      field: 'assetsTotal',
      headerName: 'Companies',
      align: 'right',
      width: 136,
      sortFields: ['assetsFiltered'],
      renderCell: (params) => <InvestorListItemAssetsTotalTableCell {...params} />,
    },
    {
      field: 'classifications',
      headerName: 'Strategy classification',
      width: 257,
      renderCell: ({ row }) => (
        <InvestorClassificationsTableCell classifications={row.classifications} />
      ),
    },
    {
      field: 'fundsRaisedLastFiveYearsEur',
      headerName: 'Fundraising (L5Y)',
      align: 'right',
      width: 176,
      renderCell: ({ value }) => <FinancialValue amount={value} />,
    },
    {
      field: 'latestFundName',
      headerName: 'Latest fund',
      width: 248,
      sortFields: ['latestFundVintageDate'],
      renderCell: ({ row, value }) => (
        <InvestorListItemFlagshipFundNameTableCell
          value={value}
          year={row.latestFundVintageDateYear}
        />
      ),
    },
    {
      field: 'latestFundSizeEur',
      headerName: 'Latest fund size',
      align: 'right',
      width: 160,
      renderCell: ({ value }) => <FinancialValue amount={value} />,
    },
    {
      field: 'latestFundVintageDateYear',
      headerName: 'Latest fund vintage date',
      width: 216,
      valueFormatter: ({ value }) => value || '-',
    },
    {
      field: 'dryPowderMinEur',
      headerName: 'Drypowder estimate',
      align: 'right',
      width: 192,
      renderCell: ({ row }) => (
        <FinancialRangeValue
          max={row.dryPowderMaxEur}
          min={row.dryPowderMinEur}
        />
      ),
    },
    {
      field: 'dealsTotalLastFiveYears',
      headerName: 'Deals (L5Y)',
      align: 'right',
      width: 160,
      valueFormatter: ({ value }) => formatNumber(value),
    },
    {
      field: 'dealsEntriesTotalLastFiveYears',
      headerName: 'Entries (L5Y)',
      align: 'right',
      width: 152,
      valueFormatter: ({ value }) => formatNumber(value),
    },
    {
      field: 'dealsExitTotalLastFiveYears',
      headerName: 'Exits (L5Y)',
      align: 'right',
      width: 152,
      valueFormatter: ({ value }) => formatNumber(value),
    },
    {
      field: 'dealsAddOnsTotalLastFiveYears',
      headerName: 'Add-ons (L5Y)',
      align: 'right',
      width: 152,
      valueFormatter: ({ value }) => formatNumber(value),
    },
    {
      field: 'assetEbitdasEur',
      headerName: 'Company EBITDA range',
      align: 'right',
      width: 208,
      sortable: false,
      renderCell: ({ value }) => <EbitdaValuesRange ebitdaValues={value} />,
    },
    {
      field: 'assetEbitdaMedianEur',
      headerName: 'Median EBITDA',
      headerExplainer:
        'Median EBITDA is calculated using only positive EBITDA values, to give a better representation of typical investment size',
      align: 'right',
      width: 168,
      sortFields: ['assetEbitdaMedianEur'],
      renderCell: ({ value }) => <FinancialValue amount={value} />,
    },
    {
      field: 'assetRegions',
      headerName: 'Active in region',
      width: 256,
      sortable: false,
      renderCell: ({ row }) => (
        <AssetRegionsTableCell
          maxItems={4}
          regionNames={row.assetRegions}
          disableCounts
        />
      ),
    },
    {
      field: 'assetSectors',
      headerName: 'Active in sector',
      width: 320,
      sortable: false,
      renderCell: ({ row }) => (
        <AssetSectorsTableCell
          maxItems={2}
          sectorNames={row.assetSectors}
        />
      ),
    },
    {
      field: 'assetsMedianMarketSegmentRatingOverall',
      headerName: 'Overall ESG risk',
      align: 'left',
      width: 168,
      renderCell: (params) => (
        <RatingTableCell
          criteria={ratingEsg}
          rating={params.value}
        />
      ),
    },
    {
      field: 'assetsMedianMarketSegmentRatingEnvironmental',
      headerName: 'Environmental risk',
      align: 'left',
      width: 176,
      renderCell: (params) => (
        <RatingTableCell
          criteria={ratingEsg}
          rating={params.value}
        />
      ),
    },
    {
      field: 'assetsMedianMarketSegmentRatingSocial',
      headerName: 'Social risk',
      align: 'left',
      width: 168,
      renderCell: (params) => (
        <RatingTableCell
          criteria={ratingEsg}
          rating={params.value}
        />
      ),
    }
  )

export const investorStrategyTableColumnNames = investorStrategyTableColumns
  .map(({ field }) => field)
  .filter(Boolean) as (keyof InvestorStrategyListItem)[]
