import { useUserCurrency } from '@gain/api/app/hooks'
import { InvestorFund, InvestorStrategy } from '@gain/rpc/app-model'
import { useConvertCurrencyCallback, useFormatCurrencyCallback } from '@gain/utils/currency'
import { useElementWidthEffect } from '@gain/utils/dom'
import { styled } from '@mui/material/styles'
import { useCallback, useMemo, useRef, useState } from 'react'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import { chartColorSet } from '../../../common/chart/chart-colors'
import { MeasureDimensions } from '../../../common/responsive'
import { StackedBarChart } from '../../../features/chart'

interface FundraisingItem {
  id: number
  year: number
  fundSizeEur: number
}

// Custom hook to transform and filter fundraising data from the provided funds.
// It extracts necessary properties (id, fund size, year) and skips funds with missing data.
// Returns an array of FundraisingItem objects for further processing.
function useFundraisingItems(funds: InvestorFund[]) {
  return useMemo(() => {
    return funds.reduce((acc, fund) => {
      // skip fund if there is no vintageDate or fundSize
      if (!fund.vintageDate || !fund.fundSizeEur) {
        return acc
      }

      // if not found create a new item
      acc.push({
        id: fund.id || 0,
        fundSizeEur: fund.fundSizeEur,
        year: fund.vintageDate.year,
      })

      return acc
    }, new Array<FundraisingItem>())
  }, [funds])
}

const StyledCard = styled(Card)({
  paddingBottom: 0,
})

export interface InvestorFundraisingCardProps {
  strategy: InvestorStrategy
}

export default function InvestorStrategyFundraisingCard({
  strategy,
}: InvestorFundraisingCardProps) {
  const userCurrency = useUserCurrency()
  const formatCurrency = useFormatCurrencyCallback()
  const convertCurrency = useConvertCurrencyCallback()
  const cardRef = useRef<HTMLDivElement>(null)
  const fundraisingItems = useFundraisingItems(strategy.funds)
  const [maxNrOfYears, setMaxNrOfYears] = useState(10)

  const visibleFundraisingItems = useMemo(() => {
    const maxYear = Math.max(...fundraisingItems.map((fund) => fund.year || 0))
    const minYear = maxYear - maxNrOfYears

    return fundraisingItems.filter((item) => item.year > minYear)
  }, [fundraisingItems, maxNrOfYears])

  // updates the max visible years based on card width to make sure the x-axis doesn't get too crowded
  const handleWidthChange = useCallback(() => {
    const width = cardRef.current?.getBoundingClientRect().width
    if (!width) {
      return
    }
    const maxYears = Math.floor((width - 200) / 30)
    setMaxNrOfYears(maxYears)
  }, [])

  // track width changes so we can update the number of visible axes accordingly
  useElementWidthEffect(cardRef, handleWidthChange)

  return (
    <StyledCard ref={cardRef}>
      <CardHeader title={'Fundraising'} />
      <CardContent>
        <MeasureDimensions fixedHeight={216}>
          {({ width, height }) => (
            <StackedBarChart
              data={visibleFundraisingItems}
              getColor={() => chartColorSet[0]}
              getHighlightGroup={(item) => item.id}
              height={height}
              width={width}
              xScaleConfig={{
                label: 'Year',
                getLabel: (value) => value.toString(),
                getValue: (item) => item.year,
              }}
              yScaleConfig={{
                label: 'Total',
                getLabel: (value) => formatCurrency(value, { dataCurrency: userCurrency.name }),
                getAxisLabel: (value) => {
                  if (value === 0) {
                    return '0m'
                  }

                  return formatCurrency(value, {
                    dataCurrency: userCurrency.name,
                    disablePrefix: true,
                    round: 'auto',
                  })
                },
                getValue: (item) => convertCurrency(item.fundSizeEur, 'EUR', userCurrency.name),
              }}
            />
          )}
        </MeasureDimensions>
      </CardContent>
    </StyledCard>
  )
}
