import { BriefcaseMoneyIcon } from '@gain/components/icons'
import { InvestorListItem } from '@gain/rpc/app-model'
import { useMemo } from 'react'

import ContentLink, { ContentLinkProps } from '../../../common/content-link'
import { generateInvestorPagePath } from '../../../routes/utils'
import TooltipInvestor from '../investor-tooltip'

interface InvestorContentLinkProps extends Pick<ContentLinkProps, 'description' | 'region'> {
  id?: number | null
  name?: string | null
  investor?: InvestorListItem | null
  logoFileUrl?: string | null
  disableTooltip?: boolean
}

export default function InvestorContentLink({
  id,
  name: nameProp,
  investor,
  logoFileUrl: logoFileUrlProp,
  disableTooltip,
  ...contentLinkProps
}: InvestorContentLinkProps) {
  const name = nameProp || investor?.name || '-'

  const logoFileUrl = useMemo(() => {
    return logoFileUrlProp || investor?.logoFileUrl
  }, [logoFileUrlProp, investor])

  const href = useMemo(() => {
    if (typeof id === 'number') {
      return generateInvestorPagePath({
        investorId: id,
        investorName: name || '',
      })
    }

    return null
  }, [id, name])

  if (!name) {
    return null
  }

  return (
    <TooltipInvestor
      disableTooltip={disableTooltip}
      id={id}
      investor={investor}>
      <ContentLink
        avatarProps={{
          src: logoFileUrl || undefined,
          children: !logoFileUrl && <BriefcaseMoneyIcon />,
        }}
        href={href}
        label={name}
        labelTypographyProps={{
          disableTooltip: true,
        }}
        {...contentLinkProps}
      />
    </TooltipInvestor>
  )
}
