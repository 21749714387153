import { LegalEntityShareholder } from '@gain/rpc/app-model'

/**
 * Represents a generic node in a hierarchical structure such as a tree.
 */
export interface Node<Data> {
  id: string
  data: Data
  parentId: string | null
}

/**
 * Temporary interface for a legal entity structure node. This is needed because
 * we currently cannot retrieve types for a recursive structure from the backend
 * TODO(@remy): Remove this when Berkay has fixed this
 */
export interface TempLegalEntityStructureNode {
  name: string
  children: TempLegalEntityStructureNode[] | null
}

/**
 * Defines the data that is available on a legal entity node
 */
export interface LegalEntityNodeData {
  name: string
}

/**
 * Typeguard to determine if a node is a shareholders node
 */
export function isShareholdersNode(node: Node<unknown>): node is Node<LegalEntityShareholder[]> {
  return Array.isArray(node.data)
}

/**
 * Typeguard to determine if a node is a legal entity node
 */
export function isLegalEntityNode(node: Node<unknown>): node is Node<LegalEntityNodeData> {
  return typeof node.data === 'object' && !!node.data && 'name' in node.data
}

/**
 * Global unique chart node id counter
 */
let lastGeneratedNodeId = 0

export function generateNodeId() {
  return `${lastGeneratedNodeId++}`
}

/**
 * Defines the position, dimensions and content to display a tooltip within the
 * chart.
 */
export interface TooltipData {
  top: number
  left: number
  width: number
  height: number
  content: string
}
