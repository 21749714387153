import { useInvestorList, useInvestorManagerList } from '@gain/api/app/hooks'
import { Asset, AssetManager, InvestorListItem } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import { isTruthy } from '@gain/utils/common'

export interface ManagerWithInvestor extends AssetManager {
  investor: InvestorListItem | null
}

export function useManagersWithInvestors(asset: Asset): { data: ManagerWithInvestor[] } {
  const managerPersonIds = asset.managers.map((manager) => manager.personId).filter(isTruthy)
  const shareholderInvestorIds = asset.shareholders.map((s) => s.investorId)

  // Get all investor managers linked to the asset managers
  const swrInvestorManagers = useInvestorManagerList({
    filter: [listFilter('personId', '=', managerPersonIds)],
  })

  // Get all investors linked to the shareholders
  const swrInvestors = useInvestorList({
    filter: [listFilter('id', '=', shareholderInvestorIds)],
  })

  return {
    // This is a map to assign an investor to each asset manager,
    // it is used in the card to display the investor content link
    data: asset.managers.map((assetManager) => {
      // Get the first investor manager linked to this asset manager
      const investorManager = swrInvestorManagers.data.items.find(
        ({ personId }) => personId === assetManager.personId
      )

      // Get the investor belonging to the investor manager
      const investor =
        swrInvestors.data.items.find(({ id }) => investorManager?.investorId === id) || null

      return {
        ...assetManager,
        investor,
      }
    }),
  }
}
