import { CreditListItem, Lender } from '@gain/rpc/app-model'

import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../../features/filter/filter-bar'
import { generateLenderPagePath, LENDER_CREDITS_PATH } from '../../lender-path'

export function getCreditsFilteredByYearPath(
  lender: Lender,
  minYear: number,
  maxYear: number,
  matured: boolean
) {
  const filterField = matured ? 'maturedAt' : 'issuedAt'

  return [
    generateLenderPagePath(lender.id, lender.name, LENDER_CREDITS_PATH),
    filterModelToQueryString<CreditListItem, typeof filterField>([
      filterValueGroup(filterValueItem(filterField, [`${minYear}-01-01`, `${maxYear}-12-31`])),
    ]),
  ].join('?')
}
