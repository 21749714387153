import { nanoid } from '@reduxjs/toolkit'
import { createContext, useContext, useState } from 'react'

const AUTH_STORAGE_KEY = 'gain:auth-context'

export interface AuthContextValue {
  sessionId: string | null
  startSession: () => void
  stopSession: () => void
  isAuthenticated: () => boolean
}

/**
 * The Auth context holds the authentication state in the form
 * of a session ID. If the session ID is present, the user is
 * correctly signed in.
 */
export const AuthContext = createContext<AuthContextValue>({
  sessionId: null,
  startSession: () => undefined,
  stopSession: () => undefined,
  isAuthenticated: () => false,
})

/**
 * Returns the auth context value, to be used when populating the AuthContext.
 * This is decoupled from the provider, so that we can set the session id outside
 * the Provider as well.
 */
export function useAuthContextValue() {
  const initialSessionId = localStorage.getItem(AUTH_STORAGE_KEY)
  const [sessionId, setSessionId] = useState<string | null>(initialSessionId)

  // Whenever we set the token, synchronize it with storage directly
  const handleSetSessionId = (newSessionId: string | null) => {
    setSessionId(newSessionId)
    if (newSessionId != null) {
      localStorage.setItem(AUTH_STORAGE_KEY, newSessionId)
    } else {
      localStorage.removeItem(AUTH_STORAGE_KEY)
    }
  }

  return {
    sessionId,
    startSession: () => {
      handleSetSessionId(nanoid())
    },
    stopSession: () => {
      handleSetSessionId(null)
    },
    isAuthenticated: () => {
      return sessionId != null
    },
  }
}

/**
 * Returns whether the current user is authenticated.
 */
export function useIsAuthenticated(): boolean {
  const { isAuthenticated } = useContext(AuthContext)
  return isAuthenticated()
}

/**
 * Starts a new authentication session and generates a new session id.
 */
export function useStartSession() {
  const { startSession } = useContext(AuthContext)
  return startSession
}

/**
 * Returns the auth token if the user is currently authenticated,
 * otherwise `null`.
 */
export function useSessionId(): string | null {
  const { sessionId } = useContext(AuthContext)
  return sessionId
}

/**
 * Stops the current authentication session.
 */
export function useStopSession() {
  const { stopSession } = useContext(AuthContext)
  return stopSession
}
