import { BriefcaseMoneyIcon } from '@gain/components/icons'
import Chip, { ChipProps } from '@mui/material/Chip'
import { useHistory } from 'react-router-dom'

import { APP_PATH, generateInvestorPagePath } from '../../../routes/utils'
import TooltipInvestor, { TooltipInvestorProps } from '../investor-tooltip'

export type InvestorListItemChipProps = Omit<ChipProps, 'label' | 'id' | 'classes'> & {
  id: number
  label: string
  TooltipProps?: Omit<TooltipInvestorProps, 'children' | 'asset'>
}

export function InvestorListItemChip({
  id,
  label,
  icon = <BriefcaseMoneyIcon color={'inherit'} />,
  TooltipProps,
  clickable = true,
  onClick,
  ...props
}: InvestorListItemChipProps) {
  const history = useHistory()

  return (
    <TooltipInvestor
      id={id}
      {...TooltipProps}>
      <Chip
        icon={icon}
        label={label}
        {...props}
        clickable={clickable}
        onClick={(event) => {
          if (onClick) {
            return onClick(event)
          }
          event.stopPropagation()
          event.preventDefault()
          const path = generateInvestorPagePath({
            investorId: id,
            investorName: label,
          })
          if (event.metaKey) {
            window.open(APP_PATH + path, '_blank')
          } else {
            history.push(path)
          }
        }}
        sx={{ minWidth: 0 }}
      />
    </TooltipInvestor>
  )
}
