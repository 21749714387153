import { Asset, AssetManager } from '@gain/rpc/app-model'
import { ManagementPositionType } from '@gain/rpc/shared-model'

export function getTopManager(asset: Asset): AssetManager | null {
  // Most important manager is the first since the
  // backend sorts manager by their relative importance.
  const topManager = asset.managers[0] ?? null

  // Return the top manager for CEOs and equivalent positions
  return topManager &&
    ![
      ManagementPositionType.Representative,
      ManagementPositionType.BoardMember,
      ManagementPositionType.Director,
    ].includes(topManager.managementPosition as ManagementPositionType)
    ? topManager
    : null
}
