import { isDefined } from '@gain/utils/common'

export interface FormatNumberLocaleOptions {
  round?: number
  emptyValue?: string
  suffix?: string
}

export function formatNumber(
  number: number | null | undefined,
  { emptyValue = '-', round = 0, suffix = '' }: FormatNumberLocaleOptions = {}
) {
  if (!isDefined(number)) {
    return emptyValue
  }

  return (
    number.toLocaleString('en-EN', {
      minimumFractionDigits: round,
      maximumFractionDigits: round,
    }) + suffix
  )
}
