import Typography from '@gain/components/typography'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import ColumnPickerColumnList from './column-picker-column-list'
import { TopLevelColumnGroup } from './column-picker-model'
import { ColumnPickerAPI } from './use-column-picker'

export interface ColumnPickerGroupProps {
  group: TopLevelColumnGroup
  api: ColumnPickerAPI
  activeFilterColumns: string[]
}

export default function ColumnPickerGroup({
  group,
  api,
  activeFilterColumns,
}: ColumnPickerGroupProps) {
  return (
    <Stack gap={1}>
      {'columns' in group && (
        <ColumnPickerColumnList
          activeFilterColumns={activeFilterColumns}
          api={api}
          columns={group.columns}
        />
      )}
      {'groups' in group && (
        <Stack divider={<Divider sx={{ my: 1 }} />}>
          {group.groups.map((childGroup, index) => (
            <Stack key={index}>
              <Typography
                color={'text.secondary'}
                sx={{ ml: 1 }}
                variant={'overline'}>
                {childGroup.name}
              </Typography>
              <ColumnPickerColumnList
                activeFilterColumns={activeFilterColumns}
                api={api}
                columns={childGroup.columns}
              />
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  )
}
