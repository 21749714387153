import { useAssetListItem } from '@gain/api/app/hooks'
import Tooltip, { TooltipProps } from '@gain/components/tooltip'
import { AssetListItem } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { PropsWithChildren, useCallback, useState } from 'react'

import AssetTooltipTitle from './asset-tooltip-title'

export type AssetTooltipProps = PropsWithChildren<
  Omit<TooltipProps, 'title' | 'id' | 'open'> & {
    id?: number
    asset?: AssetListItem | null
  }
>

export default function AssetTooltip({
  id,
  asset: providedAsset,
  variant = 'preview',
  disablePadding = true,
  children,
  ...props
}: AssetTooltipProps) {
  const [fetchAsset, setFetchAsset] = useState<boolean>(false)
  const swrAsset = useAssetListItem(fetchAsset && isDefined(id) ? id : null)

  const handleOpen = useCallback(() => {
    // If the asset is not provided, fetch it
    if (!providedAsset) {
      setFetchAsset(true)
    }
  }, [providedAsset])

  // Use the provided asset or the SWR asset
  const asset = providedAsset || swrAsset.data

  return (
    <Tooltip
      key={id}
      disablePadding={disablePadding}
      onOpen={handleOpen}
      title={
        asset ? (
          <AssetTooltipTitle
            asset={asset}
            onClick={(event) => event.stopPropagation()}
          />
        ) : (
          ''
        )
      }
      variant={variant}
      disableInteractive
      {...props}>
      {children}
    </Tooltip>
  )
}
