import OverflowContainer from '@gain/components/overflow-container'
import { InvestorStrategy, InvestorStrategyListItem } from '@gain/rpc/app-model'
import {
  getLabelFromOption,
  INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS,
} from '@gain/rpc/shared-model'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import InfoButton from '../../../common/info-button'
import KeyValueList, { KeyValueListItem } from '../../../common/key-value/key-value-list'
import { keyValueListItemClasses } from '../../../common/key-value/key-value-list/key-value-list-item'
import { Tag } from '../../../common/tag'
import { INVESTOR_STRATEGIES_LIST_PATH } from '../../../routes/utils'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../filter/filter-bar'

const StyledKeyValueListItem = styled(KeyValueListItem)({
  [`& .${keyValueListItemClasses.value}`]: {
    width: '100%',
  },
})

interface InvestorStrategyDescriptionCardProps {
  strategy: InvestorStrategy
  isSingleStrategy?: boolean
}

export default function InvestorStrategyDescriptionCard({
  strategy,
  isSingleStrategy,
}: InvestorStrategyDescriptionCardProps) {
  const strategyClassifications = [
    strategy.primaryClassification,
    strategy.secondaryClassification,
    strategy.tertiaryClassification,
  ].filter(Boolean)

  return (
    <Card fullHeight>
      <CardHeader
        endAdornment={
          isSingleStrategy && (
            <InfoButton
              color={'info'}
              dialogMessage={
                'This investor has one investment strategy. The description thereof is typically based on the investor website, but may also come from alternative sources."'
              }
              dialogTitle={'Single-strategy investor'}
              label={'Single strategy'}
              size={'medium'}
              sx={{ ml: 1 }}
            />
          )
        }
        title={'Strategy description'}
      />
      <CardContent>
        <Typography variant={'body2'}>{strategy.description}</Typography>
      </CardContent>

      {strategyClassifications.length > 0 && (
        <CardContent sx={{ mt: 'auto' }}>
          <KeyValueList>
            <StyledKeyValueListItem
              label={'Strategy classification'}
              value={
                <OverflowContainer
                  chipSize={'medium'}
                  lineHeight={24}
                  maxLines={1}
                  sx={{ justifyContent: 'flex-end' }}>
                  {strategyClassifications.map((classification) => (
                    <Tag
                      key={classification}
                      href={[
                        INVESTOR_STRATEGIES_LIST_PATH,
                        filterModelToQueryString<InvestorStrategyListItem, 'classifications'>([
                          filterValueGroup(filterValueItem('classifications', [classification])),
                        ]),
                      ].join('?')}
                      label={`${getLabelFromOption(
                        INVESTOR_STRATEGY_CLASSIFICATION_OPTIONS,
                        classification
                      )}`}
                      size={'medium'}
                    />
                  ))}
                </OverflowContainer>
              }
            />
          </KeyValueList>
        </CardContent>
      )}
    </Card>
  )
}
