import {
  BriefcaseMoneyIcon,
  ChevronDownIcon,
  CompanyIcon,
  IndustryIcon,
  LegalEntityIcon,
  SearchIcon,
  UsersIcon,
} from '@gain/components/icons'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { alpha, styled } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import { MouseEvent, useMemo, useState } from 'react'

import { useSearchAPI } from '../search-hooks'

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(-1.5),
  marginRight: theme.spacing(-1),
  whiteSpace: 'nowrap',

  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.text.primary,
  },
}))

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<MenuItemProps & { selected?: boolean }>(({ theme, selected }) => ({
  ...(selected && {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.16),

    [`& .${svgIconClasses.root}`]: {
      color: theme.palette.primary.main,
    },
  }),
}))

const StyledDivider = styled(Divider)({
  height: 16,
})

const filters = [
  { value: 'all', label: 'All', icon: <SearchIcon /> },
  { value: 'assets', label: 'Companies', icon: <CompanyIcon /> },
  { value: 'entities', label: 'Legal entities', icon: <LegalEntityIcon /> },
  { value: 'industries', label: 'Industries', icon: <IndustryIcon /> },
  { value: 'advisors', label: 'Advisors', icon: <UsersIcon /> },
  { value: 'investors', label: 'Investors', icon: <BriefcaseMoneyIcon /> },
]

export default function SearchFilter() {
  const { setSearchFilter, searchFilter } = useSearchAPI()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const selectedFilter = useMemo(() => {
    return filters.find(({ value }) => value === searchFilter) || filters[0]
  }, [searchFilter])

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeFilter = (newFilter: string) => () => {
    setSearchFilter(newFilter)
    handleClose()
  }

  return (
    <>
      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={1}>
        <StyledButton
          endIcon={<ChevronDownIcon />}
          onClick={handleClick}>
          {selectedFilter.label}
        </StyledButton>

        <StyledDivider orientation={'vertical'} />

        <SearchIcon fontSize={'inherit'} />
      </Stack>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}>
        {filters.map((filter) => (
          <StyledMenuItem
            key={filter.value}
            onClick={handleChangeFilter(filter.value)}
            selected={searchFilter === filter.value}>
            <ListItemIcon>{filter.icon}</ListItemIcon>
            <ListItemText primary={filter.label} />
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  )
}
