import { SUBSECTORS } from './subsectors'

export function getSectorForSubsector(subsector?: string | null) {
  if (subsector === undefined || subsector === null) {
    return null
  }

  const result = SUBSECTORS.find((item) => item.name === subsector)

  if (!result) {
    return null
  }

  return result.sector
}

export function getSubsectorsForSector(sector?: string | null) {
  if (sector === undefined || sector === null) {
    return []
  }

  return SUBSECTORS.filter((item) => item.sector === sector)
}
