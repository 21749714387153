import { DownloadIcon } from '@gain/components/icons'
import { useUserProfileContext } from '@gain/modules/user'
import { AnnualReport } from '@gain/rpc/app-model'
import { useAnnualReportFileEntries } from '@gain/utils/annual-report'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

import {
  ExportButton,
  methodConfigMap,
  UNLIMITED_EXPORTS,
} from '../../../../features/export/export-button'
import AnnualReportsTooltip from './annual-reports-tooltip'

const StyledExportButton = styled(ExportButton)(({ theme }) => ({
  marginLeft: theme.spacing(-1),
}))

interface AssetLegalEntitiesTableAnnualReportCellProps {
  annualReports: AnnualReport[]
}

export default function AssetLegalEntitiesTableAnnualReportCell({
  annualReports,
}: AssetLegalEntitiesTableAnnualReportCellProps) {
  const userProfile = useUserProfileContext()
  const annualReportFileEntries = useAnnualReportFileEntries(annualReports)
  if (annualReportFileEntries.length === 0) {
    return <>-</>
  }

  // Check if the user has reached the export limit
  const { current, max } =
    methodConfigMap['data.exportAnnualReportFile'].getExportLimits(userProfile)
  const hasMaxExports = max !== UNLIMITED_EXPORTS && current + 1 > max

  // If there is only one annual report for this year, or if the user has reached the export limit,
  // show the default export button.
  if (annualReportFileEntries.length === 1 || hasMaxExports) {
    return (
      <StyledExportButton
        color={'primary'}
        method={'data.exportAnnualReportFile'}
        params={{ id: annualReportFileEntries[0].arFile.id }}
        tooltipMode={'always'}
      />
    )
  }

  // If there are multiple annual reports for this year, show a download button similar to the
  // normal export button and display a tooltip with a selection of annual reports.
  return (
    <AnnualReportsTooltip annualReportFileEntries={annualReportFileEntries}>
      <IconButton
        color={hasMaxExports ? 'secondary' : 'primary'}
        sx={{ ml: -1 }}>
        <DownloadIcon />
      </IconButton>
    </AnnualReportsTooltip>
  )
}
