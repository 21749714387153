import { useIndustryListItem } from '@gain/api/app/hooks'
import Tooltip, { TooltipProps } from '@gain/components/tooltip'
import { IndustryListItem } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { PropsWithChildren, ReactElement, useCallback, useMemo, useState } from 'react'

import TooltipIndustryTitle from './tooltip-industry-title.component'

export type TooltipIndustryProps = PropsWithChildren<
  Omit<TooltipProps, 'title' | 'open' | 'id'> & {
    id: number | null
    industry?: IndustryListItem | null
    children: ReactElement
    open?: boolean
  }
>

export default function TooltipIndustry({
  id,
  industry: defaultIndustry,
  variant = 'preview',
  disablePadding = true,
  children,
  onOpen,
  open = false,
  ...props
}: TooltipIndustryProps) {
  const [shouldOpen, setShouldOpen] = useState<boolean>(open)
  const industry = useIndustryListItem(shouldOpen && isDefined(id) ? id : null)
  const handleOpen = useCallback(() => setShouldOpen(true), [])
  const handleClose = useCallback(() => setShouldOpen(false), [])
  const isOpen = useMemo(() => shouldOpen && isDefined(industry.data), [shouldOpen, industry.data])

  return (
    <Tooltip
      disablePadding={disablePadding}
      onClose={handleClose}
      onOpen={handleOpen}
      open={isOpen}
      title={
        industry.data ? (
          <TooltipIndustryTitle
            industry={industry.data}
            onClick={(event) => event.stopPropagation()}
          />
        ) : (
          ''
        )
      }
      variant={variant}
      disableInteractive
      {...props}>
      {children}
    </Tooltip>
  )
}
