import Tooltip from '@gain/components/tooltip'
import { DealListItem } from '@gain/rpc/app-model'
import { DealSideType, DealStatus } from '@gain/rpc/shared-model'
import { valueToEnum } from '@gain/utils/common'
import {
  compareDealListItemPartyByShareAndOrderDesc,
  DealListItemParty,
  formatDealShareLabel,
  partiesFromDealListItem,
} from '@gain/utils/deal'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'

import ContentLink from '../../../../common/content-link'
import AssetContentLink from '../../../asset/asset-content-link'
import InvestorContentLink from '../../../investor/investor-content-link'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: theme.spacing(1),
  minWidth: 0,
  alignItems: 'center',
}))

interface DealShareholderPartyProps {
  dealStatus: DealStatus | null | undefined
  type: 'buyer' | 'seller'
  party: DealListItemParty
  disableTooltip?: boolean
  disableDescription?: boolean
}

function DealParty({
  dealStatus,
  type,
  party,
  disableTooltip,
  disableDescription,
}: DealShareholderPartyProps) {
  const description = useMemo(() => {
    if (disableDescription) {
      return null
    }
    return formatDealShareLabel(
      party.share,
      party.sharePct,
      dealStatus,
      type === 'buyer' ? 'buy' : 'sell'
    )
  }, [disableDescription, party.share, party.sharePct, dealStatus, type])

  if (party.linkedId && party.name && party.type === DealSideType.Asset) {
    return (
      <AssetContentLink
        description={description}
        disableTooltip={disableTooltip}
        id={party.linkedId}
        logoFileUrl={party.logoFileUrl}
        name={party.name}
      />
    )
  } else if (party.linkedId && party.name && party.type === DealSideType.Investor) {
    return (
      <InvestorContentLink
        description={description}
        disableTooltip={disableTooltip}
        id={party.linkedId}
        logoFileUrl={party.logoFileUrl}
        name={party.name}
      />
    )
  } else if (party.name) {
    return (
      <ContentLink
        description={description}
        label={party.name}
      />
    )
  }

  return <>-</>
}

interface DealListItemShareholderTableCellProps {
  type: 'buyer' | 'seller'
  row: DealListItem
}

export default function DealShareholderTableCell({
  type,
  row,
}: DealListItemShareholderTableCellProps) {
  const parties = partiesFromDealListItem(row, type).sort(
    compareDealListItemPartyByShareAndOrderDesc
  )

  if (parties.length === 0) {
    return <>-</>
  }

  const status = valueToEnum(DealStatus, row.dealStatus)
  const [first, ...rest] = parties
  const firstPartyShareLabel = formatDealShareLabel(
    first.share,
    first.sharePct,
    status,
    type === 'buyer' ? 'buy' : 'sell',
    {
      format: 'short',
    }
  )

  return (
    <StyledRoot>
      <DealParty
        dealStatus={status}
        party={first}
        type={type}
        disableDescription
      />
      {firstPartyShareLabel && (
        <Chip
          label={firstPartyShareLabel}
          size={'small'}
        />
      )}
      {rest.length > 0 && (
        <Tooltip
          title={
            <Stack gap={1}>
              {rest.map((party, index) => (
                <DealParty
                  key={index}
                  dealStatus={status}
                  party={party}
                  type={type}
                  disableTooltip
                />
              ))}
            </Stack>
          }
          variant={'preview'}>
          <Chip
            label={`+${rest.length}`}
            size={'small'}
            variant={'outlined'}
          />
        </Tooltip>
      )}
    </StyledRoot>
  )
}
