import Head from '@gain/modules/head'
import { DealListItem, Investor } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'

import InvestorDealsList from '../investor-deals-list/investor-deals-list'

interface RouteEntriesProps {
  investor: Investor
}

export default function RouteEntries({ investor }: RouteEntriesProps) {
  const defaultFilters = [listFilter<DealListItem>('buyerInvestorIds', '=', investor.id)]

  return (
    <>
      <Head>
        <title>{investor.name} - Entries</title>
      </Head>
      <InvestorDealsList defaultFilters={defaultFilters} />
    </>
  )
}
