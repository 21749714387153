import Typography from '@gain/components/typography'

interface AssetListItemTableCellMatchingTextProps {
  value: string | null
}

export default function AssetListItemTableCellMatchingText({
  value,
}: AssetListItemTableCellMatchingTextProps) {
  if (value === null) {
    return <>-</>
  }

  return (
    <Typography
      color={'text.secondary'}
      maxLines={2}
      sx={{ maxWidth: '100%' }}
      variant={'body2'}>
      <span
        dangerouslySetInnerHTML={{
          // The value contains a fragment of the matching text where
          // matching words are wrapped in <b> tags. This is the result
          // of the ts_headline function in Postgres.
          //
          // Also, a span is used to allow overflowing content to be
          // displayed in a tooltip, setting the inner html of the
          // Typography does not work.
          __html: value,
        }}
      />
    </Typography>
  )
}
