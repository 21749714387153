import { XIcon } from '@gain/components/icons'
import { useUserProfileContext } from '@gain/modules/user'
import { ListMethodFilterType, ListMethodMap } from '@gain/rpc/app-model'
import { ListItemKey } from '@gain/rpc/list-model'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import { alpha, styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { forwardRef, ReactNode, Ref } from 'react'

import { exportsRemainingMessage, methodConfigMap } from '../export/export-button'
import { ListViewApi, ListViewMethod } from './core'

const StyledContainer = styled('div')(({ theme }) => ({
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(1),
  position: 'absolute',
  zIndex: 3,
}))

const StyledInnerBar = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.text.primary,
  borderRadius: 16,
  padding: theme.spacing(1),
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
}))

const StyledCrossIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.4),
  },
}))

interface ListViewFooterProps<
  Method extends ListViewMethod,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  FilterField extends ListItemKey<FilterItem>
> {
  api: ListViewApi<Method, Item, FilterItem, FilterField>
  itemNameSingular: string
  itemNamePlural: string
  children: ReactNode
  exportMethod: keyof typeof methodConfigMap
}

function ListViewFooter<
  Method extends ListViewMethod,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  FilterField extends ListItemKey<FilterItem>
>(
  {
    api,
    exportMethod,
    itemNameSingular,
    itemNamePlural,
    children,
  }: ListViewFooterProps<Method, Item, FilterItem, FilterField>,
  ref: Ref<HTMLDivElement>
) {
  const userProfile = useUserProfileContext()
  const { max, current } = methodConfigMap[exportMethod].getExportLimits(userProfile)

  return (
    <Slide
      direction={'up'}
      in={api.selectedRows.length > 0}
      mountOnEnter
      unmountOnExit>
      <StyledContainer ref={ref}>
        <StyledInnerBar>
          <Stack
            alignItems={'center'}
            direction={'row'}
            gap={0.5}>
            <StyledCrossIconButton
              onClick={api.clearSelection}
              size={'small'}>
              <XIcon />
            </StyledCrossIconButton>
            <Typography variant={'body2'}>
              {api.selectedRows.length}{' '}
              {api.selectedRows.length === 1 ? itemNameSingular : itemNamePlural} selected
            </Typography>
            <Typography variant={'subtitle2'}>({exportsRemainingMessage(current, max)})</Typography>
          </Stack>

          <Stack
            alignItems={'center'}
            direction={'row'}
            gap={2}>
            {children}
          </Stack>
        </StyledInnerBar>
      </StyledContainer>
    </Slide>
  )
}

export default forwardRef(ListViewFooter)
