import { useCurrencyList } from '@gain/api/app/hooks'
import { useUserProfileContext } from '@gain/modules/user'
import type { CurrencyListItem } from '@gain/rpc/app-model'
import { CurrencyDisplayType } from '@gain/rpc/shared-model'
import { listSort } from '@gain/rpc/utils'
import { useCallback } from 'react'

import { useHandleRPCError } from './user-hooks'

/**
 * Returns all currencies.
 */
export function useCurrencyListItems() {
  const currencyList = useCurrencyList({ limit: 500, sort: [listSort('id', 'asc')] })

  const handleError = useHandleRPCError()

  if (currencyList.error !== undefined) {
    handleError(currencyList.error)
    return undefined
  }

  return currencyList
}

/**
 * Returns the currency by name.
 */
export function useCurrency(currencyName: string | null | undefined): CurrencyListItem | undefined {
  const findCurrency = useFindCurrencyCallback()
  return findCurrency(currencyName)
}

// This is a fallback that in practice is unused since the list of
// currencies are populated during the initial data load.
const EUR: CurrencyListItem = {
  id: 1,
  name: 'EUR',
  symbol: '€',
  title: 'Euro',
  toEur: 1,
  updatedAt: null,
  display: CurrencyDisplayType.Symbol,
  allowUserSelect: true,
}

/**
 * Returns the user currency. If not found returns EUR as a fallback
 * which should never happen because listCurrencies in the SWR cache
 * is populated during the initial data load (which is blocking).
 */
export function useUserCurrency(): CurrencyListItem {
  const userProfile = useUserProfileContext()
  const findCurrency = useFindCurrencyCallback()
  if (!userProfile) {
    return EUR
  }

  return findCurrency(userProfile.currency) ?? EUR
}

/**
 * Returns a callback function to fetch a currency list item by name.
 * Returns undefined if the currency could not be found.
 */
export function useFindCurrencyCallback(): (
  currencyName: string | null | undefined
) => CurrencyListItem | undefined {
  const currencies = useCurrencyListItems()

  return useCallback(
    (currencyName: string | null | undefined) => {
      if (!currencyName || !currencies?.data) {
        return undefined
      }

      return currencies.data.items.find(
        ({ name }) => name.toLowerCase() === currencyName.toLowerCase()
      )
    },
    [currencies?.data]
  )
}
