import List from '@mui/material/List'
import Skeleton from '@mui/material/Skeleton'
import { memo } from 'react'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemText,
} from './mobile-list-styled'
import MobileListSubheader from './mobile-list-subheader.component'

export interface MobileListSkeletonProps {
  nrOfItems: number
  title?: string
}

const MobileListSkeleton = memo(function MobileListSkeleton({
  nrOfItems,
  title,
}: MobileListSkeletonProps) {
  const range = Array.from({ length: nrOfItems })
  return (
    <List disablePadding>
      {title && <MobileListSubheader>{title}</MobileListSubheader>}
      {range.map((_, index) => (
        <MobileListItem key={index}>
          <MobileListItemButton>
            <MobileListItemIcon>
              <Skeleton
                height={40}
                variant={'rounded'}
                width={40}
              />
            </MobileListItemIcon>
            <MobileListItemText
              primary={
                <Skeleton
                  variant={'text'}
                  width={'30%'}></Skeleton>
              }
              secondary={
                <Skeleton
                  variant={'text'}
                  width={'80%'}></Skeleton>
              }
            />
          </MobileListItemButton>
        </MobileListItem>
      ))}
    </List>
  )
})

export default MobileListSkeleton
