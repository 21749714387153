import { ListMethodFilterType, ListMethodMap } from '@gain/rpc/app-model'
import { ListItemKey } from '@gain/rpc/list-model'
import { PartialRecord } from '@gain/utils/typescript'
import { PropsWithChildren, useMemo } from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { FilterModel, filterModelToQueryString, FilterValueGroup } from '../../filter/filter-bar'
import { ListViewMethod, useListView } from '../core'

/**
 * Creates a link that if the list view contains filters that are inside the provided filterMap
 * it will go to the provided path with those filters instead of the original URL
 */
export function createListViewRowLink<
  Method extends ListViewMethod,
  Item extends ListMethodMap[Method],
  FilterItem extends Item & ListMethodFilterType<Method>,
  FilterField extends ListItemKey<FilterItem>,
  ToMethod extends ListViewMethod,
  ToItem extends ListMethodMap[ToMethod],
  ToFilterItem extends ToItem & ListMethodFilterType<ToMethod>,
  ToFilterField extends ListItemKey<ToFilterItem>
>(
  path: string,
  filterMap: PartialRecord<keyof FilterItem, ToFilterField>,
  appendFilterModel: FilterModel<ToFilterItem, ToFilterField> = []
) {
  return function ({ to, children, ...props }: PropsWithChildren<{ to: string } & LinkProps>) {
    const listView = useListView<Method, Item, FilterItem, FilterField>()

    // Build the additional query filter if we have active filters on the item
    const appendTo = useMemo(() => {
      if (listView.filterModel && listView.filterModel?.length > 0) {
        // Loop through all the filters
        const listViewFilters = listView.filterModel?.reduce((filters, filterGroup) => {
          // Only if the group has more than one value
          if (filterGroup.value.length > 0) {
            const filter = filterGroup.value.reduce(
              (filterValues, filterValue) => {
                // Exclude null values (they are placeholders), and ensure that the
                // filters are allowed for assets.
                if (filterValue.value !== null && filterValue.filterId in filterMap) {
                  // Add the mapped filter
                  filterValues.value.push({
                    filterId: filterMap[filterValue.filterId] as ToFilterField,
                    value: filterValue.value,
                  })
                }

                return filterValues
              },
              {
                value: [],
              } as FilterValueGroup<ToFilterItem, ToFilterField>
            )

            // Only add the filter if it has at-least one value
            // (This can be 0 if it contained no filter for the assets)
            if (filter.value.length > 0) {
              filters.push(filter)
            }
          }

          return filters
        }, [] as FilterModel<ToFilterItem, ToFilterField>)

        // If we have filters then we want to directly go to the path with those filters
        if (listViewFilters?.length > 0) {
          return `${path}?${filterModelToQueryString<ToFilterItem, ToFilterField>(
            appendFilterModel.concat(listViewFilters)
          )}`
        }
      }

      return ''
    }, [listView.filterModel])

    return (
      <Link
        to={`${to}${appendTo}`}
        {...props}>
        {children}
      </Link>
    )
  }
}
