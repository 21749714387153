import { useUserPermissionsByObject } from '@gain/api/app/hooks'
import { useUserProfileContext } from '@gain/modules/user'
import { BookmarkListItem, UserPermissionObjectType } from '@gain/rpc/app-model'
import { useCallback } from 'react'

import {
  useMutateBookmarkList,
  useNavigateToNextBookmarkList,
} from '../../../api/bookmark-list-hooks'
import { useDeleteUserPermissions } from '../../../api/user-permission-hooks'
import { useOpenDialog } from '../../../common/dialog'

export default function useLeaveBookmarkListConfirm(list: BookmarkListItem) {
  const openDialog = useOpenDialog()
  const deletePermissions = useDeleteUserPermissions()
  const userProfile = useUserProfileContext()
  const mutateList = useMutateBookmarkList()
  const navigateAway = useNavigateToNextBookmarkList()

  // When leaving a list remove any permissions associated with the list.
  const swrPermissions = useUserPermissionsByObject({
    objectType: UserPermissionObjectType.BookmarkList,
    objectId: list.id,
  })

  const leaveList = useCallback(async () => {
    const permissionIds =
      swrPermissions.data
        ?.filter((permission) => permission.userId === userProfile.id)
        .map(({ id }) => id) ?? []

    await deletePermissions(permissionIds)

    navigateAway(list)

    // Remove list from SWR cache
    await mutateList(list.id, () => undefined)
  }, [deletePermissions, list, mutateList, navigateAway, swrPermissions.data, userProfile.id])

  return useCallback(async () => {
    openDialog({
      title: 'Leave shared list',
      message: `Are you sure you want to leave “${list.title}”?`,
      confirmText: 'Yes, leave',
      cancelText: 'Cancel',
      color: 'error',
      onConfirm: async () => {
        await leaveList()
      },
    })
  }, [openDialog, list.title, leaveList])
}
