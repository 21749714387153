import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { formatDate } from '@gain/utils/date'
import { styled } from '@mui/material/styles'

import {
  ChartTooltipDataCell,
  ChartTooltipDivider,
  ChartTooltipLabelCell,
  ChartTooltipTable,
} from '../../../../common/chart/chart-tooltip'
import { SharePriceDatapoint } from './market-chart-types'

const StyledTooltipFooter = styled('div')(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: 0,
  paddingBottom: 2,
  textAlign: 'right',
}))

interface SharePriceTooltipTitleProps {
  sharePrice?: SharePriceDatapoint
  currency: string
}

/**
 * SharePriceTooltipTitle renders the content of the SharePriceTooltip. It shows
 * the share price and date.
 */
export default function SharePriceTooltipTitle({
  sharePrice,
  currency,
}: SharePriceTooltipTitleProps) {
  // Get the formatCurrency function. Note that all data is already converted
  const formatCurrency = useFormatCurrencyCallback(currency, currency)

  // Format the values, show 3 decimals for penny stocks (< 1.0)
  const decimals = sharePrice?.sharePrice && sharePrice?.sharePrice < 1 ? 3 : 2
  const formattedDate = formatDate(sharePrice?.date, { format: 'dateAbbreviated' })
  const formattedSharePrice = formatCurrency(sharePrice?.sharePrice, {
    round: decimals,
    emptyValue: 'n.a.',
    format: 'raw',
  })

  return (
    <>
      <ChartTooltipTable>
        <tbody>
          <tr>
            <ChartTooltipLabelCell>Price</ChartTooltipLabelCell>
            <ChartTooltipDataCell>{formattedSharePrice}</ChartTooltipDataCell>
          </tr>
        </tbody>
      </ChartTooltipTable>

      <ChartTooltipDivider />

      <StyledTooltipFooter>{formattedDate}</StyledTooltipFooter>
    </>
  )
}
