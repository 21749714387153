import { TZDate } from '@date-fns/tz'
import { addHours } from 'date-fns/addHours'
import { format } from 'date-fns/format'

export function getSupportHours(today: Date, userTimeZone: string) {
  // Update 28-01-2025:
  // Now live with 18/7 support, business hours are:
  // CET: 8:30 to 2:30 (am)
  // ET: 2:30 to 20:30
  const startTime = new TZDate(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    8,
    30,
    'Europe/Amsterdam'
  ).withTimeZone(userTimeZone)

  const endTime = addHours(startTime, 18) // Until 02:30

  return [format(startTime, 'HH:mm'), format(endTime, 'HH:mm')] as const
}
