import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Slide from '@mui/material/Slide'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { forwardRef, ReactElement } from 'react'
export const topBarClasses = generateUtilityClasses('TopBar', ['hidden', 'visible'])

const StyledRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.divider}`,
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
  transition: 'transform 0.1s ease, box-shadow 0.1s linear',
  padding: theme.spacing(0, 2),
  transform: 'translateY(0%)',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 3),
  },
}))

export interface TopBarProps {
  children: ReactElement
  className?: string
  hidden?: boolean
}

const TopBar = forwardRef<HTMLDivElement, TopBarProps>(({ children, className, hidden }, ref) => {
  return (
    <Slide
      direction={'down'}
      in={!hidden}>
      <StyledRoot
        ref={ref}
        className={clsx(className, {
          [topBarClasses.visible]: !hidden,
          [topBarClasses.hidden]: hidden,
        })}>
        {children}
      </StyledRoot>
    </Slide>
  )
})

export default TopBar
