import { useInvestorDealsPerTypeByYear } from '@gain/api/app/hooks'
import { SpinnerIcon } from '@gain/components/icons'
import { InvestorFundListItem } from '@gain/rpc/app-model'
import { useOpenLink } from '@gain/utils/router'

import Card, { CardContent, CardHeader } from '../../../../common/card/card'
import CardDealsSummary from '../../../../features/deal/deals-summary-card'
import {
  generateInvestorFundPagePath,
  INVESTOR_FUND_ADD_ONS_PAGE_PATH,
  INVESTOR_FUND_ENTRIES_PAGE_PATH,
  INVESTOR_FUND_EXITS_PAGE_PATH,
  useInvestorFundPageParams,
} from '../../route-fund-path'

const INVESTOR_GROUP_CONFIG = [
  { label: 'Entries', key: 'entry' },
  { label: 'Exits', key: 'exit' },
  { label: 'Add-ons', key: 'add-on' },
]

export interface InvestorFundDealsCardProps {
  fund: InvestorFundListItem
}

const GROUP_TYPE_TO_TAB_MAP = {
  entry: INVESTOR_FUND_ENTRIES_PAGE_PATH,
  exit: INVESTOR_FUND_EXITS_PAGE_PATH,
  'add-on': INVESTOR_FUND_ADD_ONS_PAGE_PATH,
}

export default function InvestorFundDealsCard({ fund }: InvestorFundDealsCardProps) {
  const params = useInvestorFundPageParams()
  const openLink = useOpenLink()

  const swrDeals = useInvestorDealsPerTypeByYear({
    investorId: fund.investorId,
    fundId: fund.id,
    maxYears: 6,
  })

  const hasAddons = (fund.dealsAddOnsTotalLastFiveYears ?? 0) > 0

  return (
    <Card
      href={
        hasAddons ? generateInvestorFundPagePath(params, INVESTOR_FUND_ADD_ONS_PAGE_PATH) : null
      }
      sx={{ minHeight: 288 }}
      fullHeight>
      <CardHeader title={'Add-ons'} />
      <CardContent sx={{ flexGrow: 1 }}>
        {swrDeals.loading && <SpinnerIcon />}
        {!swrDeals.loading && (
          <CardDealsSummary
            data={swrDeals.data?.filter((item) => item.type === 'add-on')}
            groupConfig={INVESTOR_GROUP_CONFIG}
            onClick={(group, event) => {
              openLink(
                generateInvestorFundPagePath(params, GROUP_TYPE_TO_TAB_MAP[group.type]),
                event
              )
            }}
            disableLegend
          />
        )}
      </CardContent>
    </Card>
  )
}
