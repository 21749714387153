import { StarIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { isTruthy } from '@gain/utils/common'
import {
  Rating as ICRating,
  roundInvestmentCriteriaValue,
  useRatingColor,
} from '@gain/utils/investment-criteria'
import Collapse from '@mui/material/Collapse'
import { alpha, styled } from '@mui/material/styles'

import InfoButton from '../../../common/info-button'
import MenuExpandIcon from '../../../common/menu-expand-icon'

const StyledListItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: 1,
  minHeight: 56,
  cursor: 'pointer',
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}))

const StyledLabelAndValueContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  padding: theme.spacing(1, 2),
}))

const StyledRatingAndIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(0.25, 2.75),
}))

interface StyledChevronDownIconProps {
  flip?: boolean
}

const StyledMenuExpandIcon = styled(MenuExpandIcon)<StyledChevronDownIconProps>(({ theme }) => ({
  fontSize: 20,
  color: theme.palette.text.secondary,
}))

interface StyledRatingOptionProps {
  selected?: boolean
  color?: string
}

const StyledRatingOption = styled('div')<StyledRatingOptionProps>(({ theme, selected, color }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flex: 1,
  borderRadius: 4,
  ...theme.typography.body2,
  color: theme.palette.grey['400'],
  padding: theme.spacing(0.25, 0.5),
  minHeight: 44,
  ...(selected &&
    color && {
      ...theme.typography.subtitle2,
      backgroundColor: alpha(color, 0.1),
      color,
    }),
}))

const StyledRatingOptionSuffix = styled('span')(({ theme }) => ({
  ...theme.typography.overline,
}))

interface StyledOptionsContainerProps {
  columns: number
}

const StyledOptionsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'columns',
})<StyledOptionsContainerProps>(({ theme, columns }) => ({
  display: 'grid',
  gridTemplateColumns: Array.from({ length: columns }).fill('1fr').join(' '),
  padding: theme.spacing(0.75),
  borderTop: `1px solid ${theme.palette.divider}`,
}))

interface RatingOptionProps {
  selected: boolean
  children: string
  color: string
  suffix?: string
}

function RatingOption({ selected, color, children, suffix }: RatingOptionProps) {
  return (
    <StyledRatingOption
      color={color}
      selected={selected}>
      {children}
      {selected && suffix && <StyledRatingOptionSuffix>{suffix}</StyledRatingOptionSuffix>}
    </StyledRatingOption>
  )
}

export interface ListRatingItemProps<Key extends string> {
  rating: ICRating<Key>
  value: number | null
  ratingValue?: number | null
  onExpand: () => void
  onCollapse: () => void
  expanded: boolean
}

export default function ListRatingItem<Key extends string>({
  rating,
  ratingValue,
  onExpand,
  onCollapse,
  expanded,
  ...props
}: ListRatingItemProps<Key>) {
  const value = roundInvestmentCriteriaValue(props.value)
  const color = useRatingColor(value)
  const valueLabel = rating.options
    .filter((option) => option.value === value)
    .map((option) => [option.label, rating.suffix].filter(isTruthy).join(' '))
    .pop()

  const handleClick = () => {
    if (value === null) {
      return
    }

    if (expanded) {
      onCollapse()
    } else {
      onExpand()
    }
  }

  return (
    <>
      <StyledListItemContainer onClick={handleClick}>
        <StyledLabelAndValueContainer>
          <Typography variant={'body2'}>
            {rating.label}

            {rating.explainer && (
              <InfoButton
                dialogMessage={rating.explainer}
                dialogTitle={rating.label}
                sx={{ ml: 0.5 }}
              />
            )}
          </Typography>
          <Typography
            color={'text.secondary'}
            variant={'overline'}>
            {value !== null ? valueLabel : 'Rating not available'}
          </Typography>
        </StyledLabelAndValueContainer>

        {value !== null && (
          <StyledRatingAndIconContainer>
            <StarIcon sx={{ color, fontSize: 12 }} />
            <Typography
              sx={{ color, ml: 0.75, mr: 1 }}
              variant={'subtitle2'}>
              {ratingValue || value}
            </Typography>
            <StyledMenuExpandIcon open={expanded} />
          </StyledRatingAndIconContainer>
        )}
      </StyledListItemContainer>

      <Collapse in={expanded}>
        <StyledOptionsContainer columns={rating.options.length > 5 ? 2 : 1}>
          {value !== null &&
            rating.options.map((option) => (
              <RatingOption
                key={option.value}
                color={color}
                selected={option.value === value}
                suffix={rating.suffix}>
                {option.label}
              </RatingOption>
            ))}
        </StyledOptionsContainer>
      </Collapse>
    </>
  )
}
