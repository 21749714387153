import { useUserPermissionsByObject } from '@gain/api/app/hooks'
import { useRpcClient } from '@gain/api/swr'
import { BookmarkListItem, BookmarkListType, UserPermissionObjectType } from '@gain/rpc/app-model'
import { useCallback } from 'react'

import {
  useCheckBookmarkListError,
  useMutateBookmarkList,
  useNavigateToNextBookmarkList,
} from '../../../api/bookmark-list-hooks'
import { ConfirmDialogProps, useOpenDialog } from '../../../common/dialog'

const deleteSharedListConfirmProps = (listTitle: string): ConfirmDialogProps => ({
  title: 'Delete list for all members',
  message: `The list “${listTitle}” will be deleted for all members. Are you sure you want delete it? `,
  confirmText: 'Yes, delete',
  cancelText: 'Cancel',
  color: 'error',
})

const deleteListConfirmProps = (listTitle: string): ConfirmDialogProps => ({
  title: 'Delete list',
  message: `Are you sure you want to delete “${listTitle}”?`,
  confirmText: 'Yes, delete',
  cancelText: 'Cancel',
  color: 'error',
})

function createConfirm(isShared: boolean, listTitle: string) {
  if (isShared) {
    return deleteSharedListConfirmProps(listTitle)
  }
  return deleteListConfirmProps(listTitle)
}

export default function useDeleteBookmarkListConfirm(list: BookmarkListItem) {
  const fetcher = useRpcClient()
  const mutateList = useMutateBookmarkList()

  const openDialog = useOpenDialog()
  const navigateToNextBookmarkList = useNavigateToNextBookmarkList()
  const checkListError = useCheckBookmarkListError()

  // Delete the list and remove it from the cache.
  const deleteList = useCallback(async () => {
    try {
      await fetcher({
        method: 'lists.deleteBookmarkList',
        params: { id: list.id },
      })

      // Navigate away from the list if we're currently viewing it
      navigateToNextBookmarkList(list)

      // Remove list from SWR cache
      await mutateList(list.id, () => undefined)
    } catch (err) {
      checkListError(err)
    }
  }, [fetcher, mutateList, navigateToNextBookmarkList, list, checkListError])

  // If there is only one owner, the owner should delete the list instead of leaving it.
  const swrPermissions = useUserPermissionsByObject({
    objectType: UserPermissionObjectType.BookmarkList,
    objectId: list.id,
  })
  const isShared = swrPermissions.data ? swrPermissions.data.length > 1 : false

  return useCallback(() => {
    // Recently filtered lists don't have a title and are not shared so we can
    // delete them directly.
    if (list.type === BookmarkListType.RecentAssetsFilter) {
      deleteList()
      return
    }

    // For other types of lists, show a confirmation dialog.
    openDialog({
      ...createConfirm(isShared, list.title),
      onConfirm: deleteList,
    })
  }, [openDialog, isShared, list, deleteList])
}
