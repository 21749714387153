import { ArrowUpRightIcon } from '@gain/components/icons'
import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { addHttpsIfMissing, formatUrlHostname } from '@gain/utils/common'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { alpha, styled, useTheme } from '@mui/material/styles'
import { tabScrollButtonClasses } from '@mui/material/TabScrollButton'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { useRef } from 'react'
import { animated, useSpring } from 'react-spring'

import { NAV_BAR_HEIGHT } from '../../layout/nav-bar'
import { TabScrollButton } from '../../theme'
import ConditionalWrapper from '../conditional-wrapper'
import { ProfileInfoAndTabsProps } from './profile-info-and-tabs-model'
import ProfileTabsOverflow from './profile-tabs-overflow'
import { useEnhancedScrollTrigger } from './use-scroll-trigger'

const profileTabBarClasses = generateUtilityClasses('ProfileTabBar', [
  'sticky',
  'stuck',
  'tabsContainer',
  'disableTopOffset',
])

const StyledRoot = styled(animated.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  zIndex: theme.zIndex.appBar - 2,
  [`&.${profileTabBarClasses.sticky}`]: {
    top: NAV_BAR_HEIGHT,
    position: 'sticky',
  },

  [`&.${profileTabBarClasses.disableTopOffset}`]: {
    top: 0,
  },

  [`&. .${tabScrollButtonClasses.root}`]: {
    transform: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
    backgroundColor: theme.palette.background.default,
  },

  [`&.${profileTabBarClasses.stuck}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,

    [`&.${tabScrollButtonClasses.root}`]: {
      backgroundColor: theme.palette.background.paper,
    },
  },

  [`&:not(.${profileTabBarClasses.stuck}) .${profileTabBarClasses.tabsContainer}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

const StyledLogoAndTextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  minWidth: 0,
}))

const StyledLogo = styled(animated.img, {
  shouldForwardProp: (prop) => prop !== 'fullSize',
})<{ fullSize?: boolean }>(({ theme, fullSize }) => ({
  boxSizing: 'border-box',
  borderRadius: 8,
  objectFit: fullSize ? 'cover' : 'contain',
  backgroundColor: theme.palette.background.paper,
  ...(!fullSize && {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  }),
}))

const StyledIconContainer = styled(animated.div)(({ theme }) => ({
  boxSizing: 'border-box',
  borderRadius: 8,
  backgroundColor: theme.palette.grey['100'],
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    color: alpha(theme.palette.text.secondary, 0.6),
    width: '50%',
    height: '50%',
  },
}))

const StyledTextContainer = styled('div')({
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

const StyledSubtitle = styled(animated.div)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  overflow: 'visible',
  display: 'flex',
  alignItems: 'end',
}))

const TabsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const AnimatedTabScrollButton = animated(TabScrollButton)

export default function ProfileInfoAndTabsSmUp({
  title,
  titleNoWrap = false,
  disableTitleClick,
  subtitle,
  subtitleUrl,
  logoFileUrl,
  defaultIcon: DefaultIcon,
  logoFullSize,
  onLogoClick,
  activeTab,
  tabs,
  sticky = false,
  infoActions,
  tabActions,
  replaceUrl,
  maxWidth,
  disableIndicatorAnimation,
  allowScrollButtonsMobile,
}: ProfileInfoAndTabsProps) {
  const mainRootRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const isBrowserExtension = useIsBrowserExtension()
  const stuck = useEnhancedScrollTrigger({
    offset: 36,
    disableHysteresis: true,
    threshold: 0,
  })

  const styles = useSpring({
    to:
      stuck && sticky && !isBrowserExtension
        ? {
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
            paddingTop: 16,
            paddingBottom: tabs && tabs.length > 0 ? 0 : 12, // 16 - 4 (gap)
            logoSize: 32,
            gap: 0,
            subtitleHeight: 0,
            subtitleOpacity: 0,
          }
        : {
            backgroundColor: isBrowserExtension
              ? theme.palette.background.paper
              : theme.palette.background.default,
            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
            paddingTop: 24,
            paddingBottom: isBrowserExtension && (!tabs || tabs?.length === 0) ? 16 : 0,
            logoSize: 56,
            gap: 4,
            subtitleHeight: 24,
            subtitleOpacity: 1,
          },
    config: { duration: theme.transitions.duration.shorter },
  })

  return (
    <StyledRoot
      ref={mainRootRef}
      as={animated.div}
      className={clsx({
        [profileTabBarClasses.sticky]: sticky,
        [profileTabBarClasses.stuck]: stuck,
        [profileTabBarClasses.disableTopOffset]: isBrowserExtension,
      })}
      style={{
        backgroundColor: styles.backgroundColor,
        boxShadow: styles.boxShadow,
        paddingTop: styles.paddingTop,
        paddingBottom: styles.paddingBottom,
      }}>
      <Container maxWidth={maxWidth}>
        <Stack
          direction={'row'}
          gap={2}
          justifyContent={'space-between'}>
          <StyledLogoAndTextContainer>
            {logoFileUrl && (
              <StyledLogo
                fullSize={logoFullSize}
                onClick={onLogoClick}
                src={logoFileUrl}
                style={{
                  minWidth: styles.logoSize,
                  minHeight: styles.logoSize,
                  maxWidth: styles.logoSize,
                  maxHeight: styles.logoSize,
                }}
                sx={{ cursor: onLogoClick ? 'pointer' : 'default' }}
              />
            )}
            {!logoFileUrl && DefaultIcon && (
              <StyledIconContainer
                style={{
                  minWidth: styles.logoSize,
                  minHeight: styles.logoSize,
                  maxWidth: styles.logoSize,
                  maxHeight: styles.logoSize,
                }}>
                <DefaultIcon />
              </StyledIconContainer>
            )}
            <StyledTextContainer>
              <Typography
                color={'text.primary'}
                noWrap={titleNoWrap}
                onClick={!disableTitleClick ? onLogoClick : undefined}
                sx={{
                  cursor: !disableTitleClick && onLogoClick ? 'pointer' : 'default',
                  zIndex: 1,
                }}
                variant={'h5'}>
                {title}
              </Typography>

              {(subtitle || subtitleUrl) && (
                <StyledSubtitle
                  style={{ height: styles.subtitleHeight, opacity: styles.subtitleOpacity }}>
                  <ConditionalWrapper
                    condition={Boolean(subtitle && typeof subtitle === 'string')}
                    wrapper={(children) => (
                      <Typography
                        color={'text.secondary'}
                        variant={'body2'}
                        noWrap>
                        {children}
                      </Typography>
                    )}>
                    {subtitle}
                    {subtitleUrl && (
                      <Link
                        color={'text.secondary'}
                        href={addHttpsIfMissing(subtitleUrl)}
                        rel={'noopener noreferrer'}
                        target={'_blank'}
                        underline={'none'}>
                        {formatUrlHostname(subtitleUrl)}
                        <ArrowUpRightIcon
                          fontSize={'inherit'}
                          sx={{ verticalAlign: 'middle' }}
                        />
                      </Link>
                    )}
                  </ConditionalWrapper>
                </StyledSubtitle>
              )}
            </StyledTextContainer>
          </StyledLogoAndTextContainer>
          {infoActions && infoActions.length > 0 && (
            <Box
              alignItems={'center'}
              display={'flex'}
              gap={1}>
              {infoActions}
            </Box>
          )}
        </Stack>

        <animated.div style={{ height: styles.gap }}></animated.div>

        {tabs && tabs.length > 0 && (
          <TabsContainer className={profileTabBarClasses.tabsContainer}>
            <ProfileTabsOverflow
              activeTab={activeTab}
              allowScrollButtonsMobile={allowScrollButtonsMobile}
              disableIndicatorAnimation={disableIndicatorAnimation}
              replaceUrl={replaceUrl}
              ScrollButtonComponent={AnimatedTabScrollButton}
              tabs={tabs}
              TabScrollButtonProps={{
                style: {
                  backgroundColor: styles.backgroundColor as never,
                  color: styles.backgroundColor as never,
                },
              }}
            />
            {tabActions}
          </TabsContainer>
        )}
      </Container>
    </StyledRoot>
  )
}
