import { AssetListItem, Investor } from '@gain/rpc/app-model'
import { ASSET_SHARE_TYPE_MAP } from '@gain/utils/asset'
import { MouseEvent, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import Card, { CardContentEmpty, CardHeader } from '../../../../common/card/card'
import { chartColorSetGrey } from '../../../../common/chart/chart-colors'
import TableValueDistribution, {
  TableValueDistributionConfig,
  useValueDistributionRows,
  ValueDistributionRow,
} from '../../../../common/table-value-distribution'
import { INVESTOR_SHARE_PARAM } from '../../../utils'
import {
  generateInvestorStrategyPagePath,
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../../route-strategy-path'

export interface InvestorStrategyOwnershipCardProps {
  investor: Investor
  assets: AssetListItem[]
}

export default function InvestorStrategyOwnershipCard({
  investor,
  assets,
}: InvestorStrategyOwnershipCardProps) {
  const params = useInvestorStrategyPageParams()
  const history = useHistory()

  const config = useMemo(() => {
    return {
      getValue: (asset: AssetListItem) => {
        const index = asset.ownerIds.indexOf(investor.id)

        if (index === -1) {
          return null
        }

        return asset.ownerShares[index]
      },
      formatValue: (value) => ASSET_SHARE_TYPE_MAP[value] || 'Other',
      colorSet: chartColorSetGrey,
    } as TableValueDistributionConfig<AssetListItem>
  }, [investor])

  const rows = useValueDistributionRows(assets, config)

  const handleRowClick = useCallback(
    (row: ValueDistributionRow, event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      const path = [
        generateInvestorStrategyPagePath(params, INVESTOR_STRATEGY_ASSETS_PAGE_PATH),
        `${INVESTOR_SHARE_PARAM}=${row.type}`,
      ].join('?')
      history.push(path)
    },
    [history, params]
  )

  return (
    <Card fullHeight>
      <CardHeader title={'Portfolio ownership'} />
      {rows.length === 0 ? (
        <CardContentEmpty message={'No data available'} />
      ) : (
        <TableValueDistribution
          itemLabel={'Ownership'}
          onRowClick={handleRowClick}
          rows={rows}
          shareLabel={'Companies'}
        />
      )}
    </Card>
  )
}
