import Typography from '@gain/components/typography'
import { formatRatio } from '@gain/utils/number'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { tabScrollButtonClasses } from '@mui/material/TabScrollButton'

import { RatioOption } from './ratio-utils'

const StyledTabs = styled(Tabs)(({ theme }) => ({
  flex: 1,
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),

  [`& .${tabScrollButtonClasses.root}`]: {
    backgroundColor: theme.palette.background.paper,
  },
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  margin: theme.spacing(0, 3),
}))

const StyledLabelAndValueContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'start',
  flex: 1,
  minWidth: 0,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(0.5),
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: 'inherit',
  fontSize: 14,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  textAlign: 'left',
  width: '100%',
}))

const StyledValue = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(0.25),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}))

interface RatioTabsProps {
  value: number
  onChange: (value: number) => void
  options: RatioOption[]
}

/**
 * RatioTabs displays the tab bar that is used on top of the ratio chart
 * to display the available ratio's including the most recently reported
 * value.
 */
export default function RatioTabs({ onChange, value, options }: RatioTabsProps) {
  return (
    <StyledTabs
      onChange={(_, newValue) => {
        onChange(newValue)
      }}
      scrollButtons={'auto'}
      value={value}
      variant={'scrollable'}>
      {options.map((option) => (
        <StyledTab
          key={option.key}
          disabled={option.disabled}
          label={
            <StyledLabelAndValueContainer>
              <StyledLabel
                variant={'overline'}
                noWrap>
                {option.label}
              </StyledLabel>
              <StyledValue
                color={'text.primary'}
                variant={'h5'}>
                {formatRatio(option.ratio)}
              </StyledValue>
            </StyledLabelAndValueContainer>
          }
        />
      ))}
    </StyledTabs>
  )
}
