import { isChildOfDescendantAnchorWithHref, useResizeObserver } from '@gain/utils/dom'
import { formatValue, renderCell, RowType } from '@gain/utils/table'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Skeleton from '@mui/material/Skeleton'
import { styled, SxProps, Theme } from '@mui/material/styles'
import { TableSortLabelProps } from '@mui/material/TableSortLabel'
import clsx from 'clsx'
import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  Ref,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { MOBILE_PAGE_HEADER_HEIGHT } from '../../layout/mobile-page-header'
import { NAV_BAR_HEIGHT } from '../../layout/nav-bar'
import {
  PROFILE_TAB_BAR_STICKY_HEIGHT,
  PROFILE_TAB_BAR_STICKY_NO_TABS_HEIGHT,
  profileTabBarContainerClasses,
} from '../profile-tab-bar'
import TableCell from './table-cell'
import TableHeader from './table-header'
import { ColumnConfig } from './table-model'
import TableScrollButtons from './table-scoll-buttons'

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: Ref<T>) => ReactElement | null
  ): (props: P & RefAttributes<T>) => ReactElement | null
}

export const tableClasses = generateUtilityClasses('Table', [
  'root',
  'withScroll',
  'dense',
  'table',
  'tr',
  'thead',
  'th',
  'tbody',
  'td',
  'stickyHeader',
  'spacer',
  'stickyCell',
  'disablePaddingBottom',
])

function useHeaderColumns<Row extends RowType>(columns: Array<ColumnConfig<Row>>) {
  return useMemo(() => {
    const headerColumns = new Array<ColumnConfig<Row>>()

    for (let i = 0; i < columns.length; i++) {
      const column = columns[i]
      headerColumns.push(column)

      if (typeof column.headerSpan === 'number') {
        i += column.headerSpan - 1
      }
    }

    return headerColumns
  }, [columns])
}

const StyledContainer = styled('div')({
  position: 'relative',
  borderRadius: 8,
})

const StyledScrollContainer = styled('div')({
  maxWidth: '100%',
  minWidth: 0,
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  position: 'relative', // Required for position sticky

  [`&.${tableClasses.withScroll}`]: {
    overflow: 'auto',
  },
})

const StyledTable = styled('table')(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  minWidth: 0,
  borderCollapse: 'separate',
  borderSpacing: 0,
  boxSizing: 'border-box',
  tableLayout: 'fixed',
  [`&.${tableClasses.dense}`]: {
    [`& .${tableClasses.th}`]: {
      height: 40,
      ...theme.typography.overline,
    },
    [`& .${tableClasses.td}`]: {
      height: 36,
    },
  },
  [`& .${tableClasses.tr}:first-of-type`]: {
    [`& .${tableClasses.td}`]: {
      paddingTop: theme.spacing(1),
      height: 44,
    },
  },
  [`& .${tableClasses.tr}:last-of-type`]: {
    [`& .${tableClasses.td}:not(.${tableClasses.disablePaddingBottom})`]: {
      paddingBottom: theme.spacing(1),
      height: 44,
    },
  },
}))

const StyledTHead = styled('thead')(({ theme }) => ({
  [`.${tableClasses.stickyHeader} &`]: {
    position: 'sticky',
    zIndex: 3,
    top: 0,
  },

  [`.${profileTabBarContainerClasses.sticky} .${tableClasses.stickyHeader} &`]: {
    top: NAV_BAR_HEIGHT + PROFILE_TAB_BAR_STICKY_HEIGHT,
  },

  [`.${profileTabBarContainerClasses.stickyNoTabs} .${tableClasses.stickyHeader} &`]: {
    top: NAV_BAR_HEIGHT + PROFILE_TAB_BAR_STICKY_NO_TABS_HEIGHT,
  },

  [theme.breakpoints.down('sm')]: {
    [`.${profileTabBarContainerClasses.sticky} .${tableClasses.stickyHeader} &, .${profileTabBarContainerClasses.stickyNoTabs} .${tableClasses.stickyHeader} &`]:
      {
        top: MOBILE_PAGE_HEADER_HEIGHT,
      },
  },
}))

const StyledTBody = styled('tbody')(({ theme }) => ({
  '&:not(:first-of-type)': {
    [`& .${tableClasses.tr}:first-of-type`]: {
      [`& .${tableClasses.td}`]: {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
    },
  },
}))

interface StyledTrProps {
  hover?: boolean
}

const StyledTr = styled('tr', {
  shouldForwardProp: (prop) => prop !== 'hover',
})<StyledTrProps>(({ theme, hover }) => ({
  ...(hover && {
    [`& .${tableClasses.td}`]: {
      cursor: 'pointer',
    },
    [`&:hover .${tableClasses.td}`]: {
      backgroundColor: theme.palette.grey['50'],
    },
  }),
}))

function useRowGroups<Row extends RowType, GroupByKey extends keyof Row>(
  groupBy: GroupByKey | undefined,
  groupBySort: ((a: Row[GroupByKey] | null, b: Row[GroupByKey] | null) => number) | undefined,
  sort: (a: Row, b: Row) => number,
  rows: Array<Row>
) {
  return useMemo(() => {
    return rows
      .reduce((acc, current) => {
        if (groupBy) {
          const index = acc.findIndex((group) => group.key === current[groupBy])
          if (index > -1) {
            acc[index].rows.push(current)
          } else {
            acc.push({ key: current[groupBy], rows: [current] })
          }
        } else {
          const index = acc.findIndex((group) => group.key === null)
          if (index > -1) {
            acc[index].rows.push(current)
          } else {
            acc.push({ key: null, rows: [current] })
          }
        }

        return acc
      }, new Array<{ key: Row[GroupByKey] | null; rows: Row[] }>())
      .sort((a, b) => groupBySort?.(a.key, b.key) || 0)
      .map((group) => {
        return {
          ...group,
          rows: group.rows.slice().sort(sort),
        }
      })
  }, [groupBy, groupBySort, rows, sort])
}

export interface TableProps<Row extends RowType, GroupByKey extends keyof Row = keyof Row> {
  columns: Array<ColumnConfig<Row>>
  rows: Array<Row>
  amountOfPlaceholders?: number
  sx?: SxProps<Theme>
  className?: string
  onRowClick?: (row: Row, event: MouseEvent) => void
  dense?: boolean
  disableStickyHeader?: boolean
  sort?: (a: Row, b: Row) => number
  groupBy?: GroupByKey
  groupBySort?: (a: Row[GroupByKey] | null, b: Row[GroupByKey] | null) => number
  isRowClickable?: (row: Row) => boolean
  disablePaddingBottomOnLastRow?: boolean
  shouldAddDivider?: (current: Row, previous?: Row | undefined) => boolean | undefined
  defaultOrder?: TableSortLabelProps['direction']
  defaultOrderBy?: keyof Row
}

const Table = forwardRef(function Table<
  Row extends RowType,
  GroupByKey extends keyof Row = keyof Row
>(
  {
    columns,
    rows,
    className,
    amountOfPlaceholders = 0,
    onRowClick,
    dense,
    disableStickyHeader,
    sort = () => 0,
    groupBy,
    groupBySort,
    isRowClickable,
    disablePaddingBottomOnLastRow,
    shouldAddDivider,
    defaultOrder,
    defaultOrderBy,
    ...tableProps
  }: TableProps<Row, GroupByKey>,
  forwardedRef: ForwardedRef<HTMLDivElement | null>
) {
  const [order, setOrder] = useState<TableSortLabelProps['direction']>(defaultOrder || 'asc')
  const [orderBy, setOrderBy] = useState<keyof Row | null>(defaultOrderBy || null)

  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const headerColumns = useHeaderColumns(columns)
  const [canScroll, setCanScroll] = useState(false)
  const [availableWidth, setAvailableWidth] = useState<number>(0)

  const scrollButtonOffsetLeft = useMemo(() => {
    return columns.reduce((acc, current) => {
      if (current.sticky && typeof current.width === 'number') {
        return acc + current.width
      }
      return acc
    }, 0)
  }, [columns])

  useImperativeHandle(forwardedRef, () => scrollContainerRef.current as HTMLDivElement)

  const handleRenderCell = useCallback((column: ColumnConfig<Row>, row: Row, rowIndex: number) => {
    const formattedValue = formatValue(column.field, row, rowIndex, column.valueFormatter)
    return renderCell(column.field, row, rowIndex, formattedValue, column.renderCell)
  }, [])

  const handleCellClassName = useCallback(
    (column: ColumnConfig<Row>, row: Row, rowIndex: number) => {
      if (!column.cellClassName) {
        return undefined
      }

      if (typeof column.cellClassName === 'string') {
        return column.cellClassName
      }

      return (
        column.cellClassName({ row, rowIndex, value: row[column.field], field: column.field }) ||
        undefined
      )
    },
    []
  )

  const handleRowClick = useCallback(
    (row: Row) => (event: MouseEvent<HTMLTableRowElement>) => {
      if (!onRowClick || !(event.target instanceof HTMLElement)) {
        return
      }
      const t = event.currentTarget

      const isAnchorWithHref =
        event.target instanceof HTMLAnchorElement && event.target.hasAttribute('href')
      const isDescendant = t.contains(event.target)
      const isInsideAnchorWithHref = isChildOfDescendantAnchorWithHref(event.target, t)

      if (!isAnchorWithHref && !isInsideAnchorWithHref && isDescendant) {
        onRowClick(row, event)
      }
    },
    [onRowClick]
  )

  const placeholders = useMemo(() => {
    if ((!rows || rows.length === 0) && amountOfPlaceholders > 0) {
      return new Array(amountOfPlaceholders).fill(null)
    }

    return []
  }, [amountOfPlaceholders, rows])

  const handleSort = useCallback(
    (column: ColumnConfig<Row>) => () => {
      let nextOrder: TableSortLabelProps['direction'] = 'asc'
      if (orderBy === column.field) {
        nextOrder = order === 'desc' ? 'asc' : 'desc'
      }

      setOrder(nextOrder)
      setOrderBy(column.field)
    },
    [order, orderBy]
  )

  const sortedRows = useMemo(() => {
    const orderByColumn = columns.find((column) => column.field === orderBy)
    const compareAsc = orderByColumn?.compareAsc

    if (!compareAsc) {
      return rows
    }

    return rows.slice().sort((a, b) => {
      if (order === 'asc') {
        return compareAsc(a, b)
      }

      return -compareAsc(a, b)
    })
  }, [rows, order, orderBy, columns])

  const rowGroups = useRowGroups<Row, GroupByKey>(groupBy, groupBySort, sort, sortedRows)

  const handleIsRowClickable = useCallback(
    (row: Row) => {
      return isRowClickable ? isRowClickable(row) : !!onRowClick
    },
    [isRowClickable, onRowClick]
  )

  const handleResize = useCallback(() => {
    if (scrollContainerRef.current) {
      setCanScroll(scrollContainerRef.current.scrollWidth > scrollContainerRef.current.clientWidth)
      setAvailableWidth(scrollContainerRef.current.getBoundingClientRect().width)
    }
  }, [])

  useResizeObserver(scrollContainerRef, handleResize)
  useResizeObserver(tableRef, handleResize)

  useLayoutEffect(() => {
    handleResize()
  }, [handleResize])

  return (
    <StyledContainer ref={forwardedRef}>
      {canScroll && scrollButtonOffsetLeft !== null && (
        <TableScrollButtons
          availableWidth={availableWidth}
          offsetLeft={scrollButtonOffsetLeft}
          scrollContainerRef={scrollContainerRef}
        />
      )}

      <StyledScrollContainer
        ref={scrollContainerRef}
        className={clsx(
          tableClasses.root,
          {
            [tableClasses.withScroll]: canScroll,
          },
          className
        )}
        {...tableProps}>
        <StyledTable
          ref={tableRef}
          className={clsx(tableClasses.table, {
            [tableClasses.dense]: dense,
            [tableClasses.stickyHeader]: !disableStickyHeader,
          })}>
          <StyledTHead className={tableClasses.thead}>
            <tr className={tableClasses.tr}>
              {headerColumns.map((column, columnIndex) => (
                <TableHeader
                  key={`${columnIndex}-${column.field}`}
                  className={clsx(tableClasses.th, column.headerClassName)}
                  column={column}
                  onSort={handleSort(column)}
                  sortActive={orderBy === column.field}
                  sortDirection={orderBy === column.field ? order : undefined}
                />
              ))}
            </tr>
          </StyledTHead>

          {rowGroups.map((rowGroup, rowGroupIndex) => (
            <StyledTBody
              key={rowGroupIndex}
              className={tableClasses.tbody}>
              {rowGroup.rows.map((row, rowIndex) => (
                <StyledTr
                  key={rowIndex}
                  className={tableClasses.tr}
                  hover={handleIsRowClickable(row)}
                  onClick={handleRowClick(row)}>
                  {columns.map((column, columnIndex) => (
                    <TableCell
                      key={`${columnIndex}-${column.field}`}
                      className={clsx(handleCellClassName(column, row, rowIndex), tableClasses.td, {
                        [tableClasses.disablePaddingBottom]:
                          disablePaddingBottomOnLastRow &&
                          rowGroupIndex === rowGroups.length - 1 &&
                          rowIndex === rowGroup.rows.length - 1,
                      })}
                      divider={
                        shouldAddDivider && shouldAddDivider(row, rowGroup.rows[rowIndex - 1])
                      }
                      paddingLeft={column.paddingLeft}
                      paddingRight={column.paddingRight}
                      sticky={canScroll && column.sticky}
                      textAlign={column.align}
                      width={column.width}>
                      {handleRenderCell(column, row, rowIndex)}
                    </TableCell>
                  ))}
                </StyledTr>
              ))}
            </StyledTBody>
          ))}

          {placeholders.length > 0 && (
            <StyledTBody className={tableClasses.tbody}>
              {placeholders.map((_, rowIndex) => (
                <StyledTr key={rowIndex}>
                  {columns.map((column, columnIndex) => (
                    <TableCell
                      key={`${columnIndex}-${column.field}`}
                      className={tableClasses.td}
                      sticky={canScroll && column.sticky}
                      textAlign={column.align}
                      width={column.width}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </StyledTr>
              ))}
            </StyledTBody>
          )}
        </StyledTable>
      </StyledScrollContainer>
    </StyledContainer>
  )
})

export default Table
