export const SHARE_FEEDBACK_ASSET_INFO = [
  'We do our very best to provide an outside in perspective on your assets. However, you hold a superior information position.',
  'With the majority of the ecosystem of potential buyers and their advisors now utilising us to develop a perspective on your assets, you can benefit from a description that is as accurate as possible. Even years before a potential exit, this allows relevant investors to warm up to your assets and accurately track them.',
  'Such engagement from your buyer field is likely to benefit valuations, either in a solicited or pro active (incl trade buyers) exit process.',
]

export const SHARE_FEEDBACK_INVESTOR_INFO = [
  'We do our very best to provide an outside in perspective on your fund. However, you hold a superior information position.',
  'With the majority of the ecosystem of potential buyers and their advisors now utilising us to develop a perspective, you can benefit from a description that is as accurate as possible. Even years before a potential exit, this allows relevant advisors to warm up to your assets and accurately track them.',
]
