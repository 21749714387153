import { useMyUserPermissions } from '@gain/api/app/hooks'
import { AppSwrKey, useRpcClient } from '@gain/api/swr'
import { useUserProfileContext } from '@gain/modules/user'
import {
  UserPermission,
  UserPermissionChange,
  UserPermissionObjectType,
  UserPermissionRole,
} from '@gain/rpc/app-model'
import { useCallback } from 'react'
import { mutate } from 'swr'

/**
 * Returns true if the current user has the required permission for the specified
 * object.
 */
export function useCurrentUserHasPermission(
  objectType: UserPermissionObjectType,
  objectId: number | undefined,
  role: UserPermissionRole
) {
  const userProfile = useUserProfileContext()
  const swrPermissions = useMyUserPermissions()
  if (swrPermissions.loading) {
    return false
  }

  return hasUserPermission(swrPermissions.data, objectType, objectId, role, userProfile.id)
}

// The order of the roles in the hierarchy is important as each role inherits
// permissions from the roles below it.
const roleHierarchy = [
  UserPermissionRole.Viewer,
  UserPermissionRole.Editor,
  UserPermissionRole.Owner,
]

/**
 * Returns true if the specified user has the required permissions for the
 * specified object.
 */
export function hasUserPermission(
  permissions: UserPermission[] | undefined,
  objectType: UserPermissionObjectType,
  objectId: number | undefined,
  role: UserPermissionRole,
  userId: number | undefined
) {
  if (!permissions || objectId === undefined || userId === undefined) {
    return false
  }

  // Find the required role in the hierarchy
  const requiredRole = roleHierarchy.indexOf(role)
  if (requiredRole === -1) {
    throw new Error(`Invalid role ${role}`) // Programmer error
  }

  return permissions.some(
    (perm) =>
      perm.objectType === objectType &&
      perm.objectId === objectId &&
      perm.userId === userId &&
      roleHierarchy.indexOf(perm.role) >= requiredRole
  )
}

export function useUpsertUserPermissions() {
  const fetcher = useRpcClient()
  const swrPermissions = useMyUserPermissions()
  const userProfile = useUserProfileContext()

  return useCallback(
    async (
      objectType: UserPermissionObjectType,
      objectId: number,
      permissions: UserPermissionChange[]
    ) => {
      await fetcher({
        method: 'account.upsertUserPermissions',
        params: { objectType, objectId, permissions },
      })

      // In case the role of the current user changed ensure the permissions are
      // re-fetched.
      if (permissions.some(({ userId }) => userId === userProfile.id)) {
        await swrPermissions.mutate()
      }
    },
    [fetcher, swrPermissions, userProfile.id]
  )
}

export function useDeleteUserPermissions() {
  const fetcher = useRpcClient()

  return useCallback(
    async (permissionIds: number[]) => {
      await fetcher({
        method: 'account.deleteUserPermissions',
        params: { ids: permissionIds },
      })
    },
    [fetcher]
  )
}

/**
 * Invalidates the SWR cache for account.getUserPermissionsByObject for a specific
 * object.
 */
export function mutateGetUserPermissionsByObject(
  objectType: UserPermissionObjectType,
  objectId: number
) {
  return mutate(
    (key: AppSwrKey<'account.getUserPermissionsByObject'>) =>
      key.method === 'account.getUserPermissionsByObject' &&
      key.params?.objectType === objectType &&
      key.params?.objectId === objectId
  )
}
