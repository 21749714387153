import { listItemButtonClasses } from '@mui/material/ListItemButton'
import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { listItemSecondaryActionClasses } from '@mui/material/ListItemSecondaryAction'
import { listItemTextClasses } from '@mui/material/ListItemText'
import { Components, Theme } from '@mui/material/styles'

export const MuiListItem = (theme: Theme): Components['MuiListItem'] => ({
  styleOverrides: {
    root: {
      [`& .${listItemSecondaryActionClasses.root}`]: {
        color: theme.palette.grey['600'],
        display: 'flex',
        right: 8,
        fontSize: 20,
      },
    },
    dense: {
      minHeight: 32,
      [`& .${listItemIconClasses.root}`]: {
        minWidth: 32,
        fontSize: 16,
      },
    },
    secondaryAction: {
      [`& .${listItemTextClasses.root}`]: {
        marginRight: 24,
      },
      [`& > .${listItemButtonClasses.root}`]: {
        paddingRight: 24,
      },
    },
  },
})
