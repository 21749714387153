import { LinkedInIcon } from '@gain/components/icons'
import { addHttpsIfMissing } from '@gain/utils/common'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

const StyledIconButton = styled(IconButton)(({ disabled }) => ({
  fontSize: 18,
  ...(disabled && {
    opacity: 0.23,
    filter: 'grayscale(1)',
  }),
}))

const StyledPlaceholder = styled('div')({
  width: 30,
})

interface LinkedInButtonProps extends IconButtonProps {
  url?: string | null
}

export default function LinkedInButton({ url, ...props }: LinkedInButtonProps) {
  if (!url) {
    return <StyledPlaceholder />
  }

  return (
    <StyledIconButton
      onClick={(event) => {
        event.stopPropagation()
        window.open(addHttpsIfMissing(url), '_blank', 'noopener,noreferrer')
      }}
      size={'small'}
      {...props}>
      <LinkedInIcon />
    </StyledIconButton>
  )
}
