import { useAssetListItem, useLegalEntityStructure, useSources } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Asset, DealListItem, SourceObjectType } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { listFilter, listSort, serializeListSortArray } from '@gain/rpc/utils'
import { buildAssetFileName } from '@gain/utils/asset'
import Grid from '@mui/material/Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import CardCreditFacilities from '../../../features/credit/card-credit-facilities'
import SourcesList from '../../../features/source/source-list'
import { useAssetLegalEntities } from '../use-asset-legal-entities'
import {
  useAcquisitionsAndDivestmentsDeals,
  useAssetDealHistoryDeals,
  useCreditFacilities,
} from './asset-ownership-hooks'
import CardAssetDeals from './card-asset-deals'
import CardAssetFunding from './card-asset-funding'
import { hasFundingMetrics } from './card-asset-funding/card-asset-funding'
import CardAssetHistory from './card-asset-history'
import CardAssetShareholders from './card-asset-shareholders'
import { useCurrentShareholders } from './card-asset-shareholders/asset-shareholder-util'
import LegalStructuresAndShareholdersCard from './legal-structures-and-shareholders-card/legal-structures-and-shareholders-card'
import { TempLegalEntityStructureNode } from './legal-structures-and-shareholders-card/org-chart/org-chart-model'
import ManagementCard from './management-card'
import { useManagersWithInvestors } from './management-card/asset-management-util'

const dealSort: ListSort<DealListItem>[] = [
  listSort('announcementDateYear', 'desc'),
  listSort('announcementDateMonth', 'desc'),
]

interface AssetOwnershipProps {
  asset: Asset
}

export default function AssetOwnership({ asset }: AssetOwnershipProps) {
  const assetListItem = useAssetListItem(asset.id)
  const assetDealHistoryDeals = useAssetDealHistoryDeals(asset.id)
  const acquisitionsAndDivestmentsDeals = useAcquisitionsAndDivestmentsDeals(asset.id)
  const creditFacilities = useCreditFacilities(asset.id)
  const swrStructure = useLegalEntityStructure({
    assetId: asset.id,
  })

  const swrSources = useSources({
    objectId: asset.id,
    objectType: SourceObjectType.AssetOwnership,
  })

  const managers = useManagersWithInvestors(asset)
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const legalEntities = useAssetLegalEntities(asset?.legalEntities || [])
  const shareholders = useCurrentShareholders(asset?.shareholders || [])
  const hasShareholders = !!shareholders.data?.length
  const hasFunding = assetListItem.data && hasFundingMetrics(assetListItem.data)

  let maxNrOfInitialShareholders = shareholders.data?.length || 0
  if (hasFunding && isLgUp) {
    maxNrOfInitialShareholders = 5
  }

  if (!Array.isArray(swrStructure.data)) {
    return null
  }

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Ownership</title>
      </Head>
      <Grid
        spacing={2}
        sx={{ alignSelf: 'stretch' }}
        container>
        <Grid
          size={{
            lg: 6,
            xs: 12,
          }}>
          <CardAssetHistory
            history={asset.description?.history || ''}
            ownershipIsVerified={asset.generalInfo?.ownershipIsVerified}
            ownershipType={asset.generalInfo?.ownership}
          />
        </Grid>

        <Grid
          size={{
            lg: 6,
            xs: 12,
          }}>
          <ManagementCard
            managers={managers.data}
            fullHeight
          />
        </Grid>

        {hasShareholders && (
          <Grid size={12}>
            <CardAssetShareholders
              asset={asset}
              maxNrOfInitialItems={maxNrOfInitialShareholders}
              shareholders={shareholders.data}
              fullHeight
            />
          </Grid>
        )}

        {assetListItem.data && hasFunding && (
          <Grid size={12}>
            <CardAssetFunding
              asset={assetListItem.data}
              totalFunding={asset?.generalInfo?.totalFunding}
              totalFundingCurrency={asset?.generalInfo?.totalFundingCurrency}
              fullHeight
            />
          </Grid>
        )}

        {assetDealHistoryDeals.data.items.length > 0 && (
          <Grid size={12}>
            <CardAssetDeals
              deals={assetDealHistoryDeals.data.items}
              exportContainerProps={{
                params: {
                  columns: [],
                  filename: buildAssetFileName(asset, 'deals.xlsx'),
                  filter: [
                    listFilter<DealListItem>(
                      'id',
                      '=',
                      assetDealHistoryDeals.data.items.map((item) => item.id)
                    ),
                  ],
                  sort: serializeListSortArray(dealSort),
                },
              }}
              title={'Platform deal history'}
              disableDealTargetMetrics
            />
          </Grid>
        )}

        {acquisitionsAndDivestmentsDeals.data.length > 0 && (
          <Grid size={12}>
            <CardAssetDeals
              deals={acquisitionsAndDivestmentsDeals.data}
              exportContainerProps={{
                params: {
                  columns: [],
                  filename: buildAssetFileName(asset, 'acquisitions and divestments.xlsx'),
                  filter: [
                    listFilter<DealListItem>(
                      'id',
                      '=',
                      acquisitionsAndDivestmentsDeals.data.map((item) => item.id)
                    ),
                  ],
                  sort: serializeListSortArray(dealSort),
                },
              }}
              title={'Acquisitions and divestments'}
              disableDealMetrics
            />
          </Grid>
        )}

        {creditFacilities.visible && (
          <Grid size={12}>
            <CardCreditFacilities
              setSort={creditFacilities.setSort}
              sort={creditFacilities.sort}
              swrCredits={creditFacilities.swrCredits}
              viewMoreHref={creditFacilities.viewMoreHref}
            />
          </Grid>
        )}

        {legalEntities.data.length > 0 && (
          <Grid size={12}>
            <LegalStructuresAndShareholdersCard
              legalEntities={legalEntities.data}
              // TODO(@remy): Remove cast when Berkay fixes typing for recursive structures in the backend
              structure={swrStructure.data as TempLegalEntityStructureNode[]}
            />
          </Grid>
        )}

        {!!swrSources.data?.length && (
          <Grid size={12}>
            <SourcesList sources={swrSources.data} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
