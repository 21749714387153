import { useDealList } from '@gain/api/app/hooks'
import { AssetList, DealListItem, Investor } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { createListFilterForPastFiveYears } from '@gain/rpc/utils'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { median } from 'd3'

export function useInvestorDealCount(investorId: number): number | null {
  const { data: deals } = useDealList({
    filter: listFilters(
      listFilter<DealListItem>('buyerInvestorIds', '=', investorId),
      createListFilterForPastFiveYears('announcementDate')
    ),
    limit: 0,
  })
  return deals.counts.filtered || null
}

export function useDryPowder(investor: Investor): string | null {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  let dryPowderMinEur = investor.dryPowderMinEur
  let dryPowderMaxEur = investor.dryPowderMaxEur

  // If we are a single strategy, get the dry powder from there
  if (investor.strategies.length === 1) {
    dryPowderMinEur = investor.strategies[0].dryPowderMinEur
    dryPowderMaxEur = investor.strategies[0].dryPowderMaxEur
  }

  if (dryPowderMinEur === null || dryPowderMaxEur === null) {
    return null
  }

  return formatCurrencyRange(dryPowderMinEur, dryPowderMaxEur, {
    round: 'estimate',
  })
}

export function useMedianEbitda(assets: AssetList): number | null {
  const medianEbitda = median(
    assets.items
      .map(({ ebitdaWithAiGeneratedEur }) => ebitdaWithAiGeneratedEur)
      .filter((ebitda) => ebitda !== null && ebitda > 0)
  )

  return medianEbitda || null
}
