import { XIcon } from '@gain/components/icons'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { HTMLAttributes, MouseEvent, ReactElement } from 'react'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& > *': {
    flex: 1,
  },
}))

const StyledActionsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})

export type DrawerHeaderProps = HTMLAttributes<HTMLDivElement> & {
  title: string
  onClose: (event: MouseEvent) => void
  actions?: ReactElement | false | null
}

export function DrawerHeader({ title, onClose, actions, ...props }: DrawerHeaderProps) {
  return (
    <StyledRoot {...props}>
      <div>
        <IconButton
          onClick={onClose}
          size={'small'}>
          <XIcon />
        </IconButton>
      </div>

      <Typography
        align={'center'}
        variant={'h5'}>
        {title}
      </Typography>
      <StyledActionsContainer>{actions && actions}</StyledActionsContainer>
    </StyledRoot>
  )
}
