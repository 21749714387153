import { AssetListItem } from '@gain/rpc/app-model'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'

import { generateAssetPagePath } from '../../routes/utils'
import TooltipAsset from '../asset/asset-tooltip'

const StyledLink = styled(Link)({
  fontWeight: 'bold',
  color: 'inherit',
})

interface ParentButtonProps {
  parent: AssetListItem | null
}

export default function ParentLink({ parent }: ParentButtonProps) {
  if (parent === null) {
    return null
  }

  return (
    <TooltipAsset id={parent.id}>
      <StyledLink
        href={generateAssetPagePath({
          id: parent.id,
          name: parent.name,
        })}>
        {parent.name}
      </StyledLink>
    </TooltipAsset>
  )
}
