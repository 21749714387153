import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const StyledUl = styled('ul')({
  margin: 0,
  padding: '0 1em',
})

const StyledLi = styled('li')(({ theme }) => ({
  ...theme.typography.body2,
  marginLeft: theme.spacing(0.5),
}))

export default function AIExplainerEbitdaContent() {
  return (
    <Stack
      direction={'column'}
      gap={1}>
      <Typography variant={'h5'}>Methodology</Typography>
      <Typography
        sx={{ pb: 2 }}
        variant={'body2'}>
        Our AI model estimates EBITDA margins using a neural network trained on our company dataset.
        Predictions consider factors such as historical financial performance, industry trends, and
        company size. These estimates are intended to give a general sense of a company's size and
        financial position, not precise values.
      </Typography>
      <Typography variant={'h5'}>Accuracy</Typography>
      <Typography variant={'body2'}>
        To assess accuracy, we tested how often predictions fell within the correct "buckets":
        &lt;0%, 0-3%, 3-6%, 6-9%, 9-12%, 12-15%, 15-20%, 20-25%, 25-30%, &gt;30%.
      </Typography>
      <Typography variant={'body2'}>
        Company EBITDA margins fluctuate over time - over a five-year period, 77% of companies move
        across two or more buckets, and 36% move across three or more buckets. Our model performs
        best for companies with stable EBITDA margins, while accuracy is lower for those with more
        volatile margins.
      </Typography>
      <Typography variant={'body2'}>
        Testing on companies with known EBITDA margins shows:
      </Typography>
      <StyledUl>
        <StyledLi>20% of predictions land in the exact bucket</StyledLi>
        <StyledLi>50% are within one bucket</StyledLi>
        <StyledLi>70% are within two buckets</StyledLi>
        <StyledLi>85% are within three buckets</StyledLi>
      </StyledUl>
    </Stack>
  )
}
