import { styled } from '@mui/material/styles'
import { PropsWithChildren } from 'react'

const Container = styled('div')(({ theme }) => ({
  minWidth: 200,

  '& a': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  [theme.breakpoints.down(664)]: {
    display: 'none',
  },
}))

export default function SearchTopResultSideInfo({ children }: PropsWithChildren<unknown>) {
  return <Container>{children}</Container>
}
