import { useAssetListItems } from '@gain/api/app/hooks'
import {
  AssetListItem,
  IndustryMarketSegmentListItem,
  InvestorStrategyListItem,
} from '@gain/rpc/app-model'
import { useSessionStorage } from '@gain/utils/storage'
import Stack from '@mui/material/Stack'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import { ChartGroupByConfig } from '../../common/chart/chart-groups'
import { ChartSizeTypeConfig } from '../../common/chart/chart-select'
import Loading from '../../common/loading'
import matchesSearchQuery from '../../common/search/matches-search-query'
import { VirtualSort } from '../../common/virtual-table'
import { AxisConfig } from '../chart/company-bubble-echart/axis/axis-config'
import BenchmarkingChart from './benchmarking-chart'
import { useBenchmarkingContext } from './benchmarking-context'
import BenchmarkingTable from './benchmarking-table'
import useSortedAssets from './use-sorted-assets'
import useVisibleAssets from './use-visible-assets'
import {
  addBenchmarkingItemType,
  BenchmarkingItem,
  BenchmarkingItemType,
  processInitialAssets,
} from './utils'

interface BenchmarkingProps {
  industrySegments?: IndustryMarketSegmentListItem[]
  investorStrategies?: InvestorStrategyListItem[]

  // Initial assets are shown as the default assets in the table. The first
  // DEFAULT_SELECT_ASSETS are automatically selected; the rest can be selected
  // manually.
  initialAssets: BenchmarkingItem[]
  loadingInitialAssets: boolean

  allowAdjustBenchmarkSize?: boolean
  initialSort?: VirtualSort<BenchmarkingItem>[]
}

export default function Benchmarking({
  initialAssets,
  loadingInitialAssets,
  industrySegments,
  investorStrategies,
  allowAdjustBenchmarkSize = false,
  initialSort = [{ field: 'fte', direction: 'desc' }],
}: BenchmarkingProps) {
  const { objectType, objectId } = useBenchmarkingContext()
  const storageKey = `BENCHMARKING-${objectType}-${objectId}`
  const [addedAssetIds, setAddedAssetIds] = useSessionStorage<number[]>(
    `${storageKey}-ADDED-ASSET-IDS`,
    []
  )
  const [deselectedAssetIds, setDeselectedAssetIds] = useSessionStorage<number[]>(
    `${storageKey}-DESELECTED-ASSET-IDS`,
    []
  )

  const targetAssetIds = initialAssets
    .filter((asset) => asset.type === BenchmarkingItemType.Target)
    .map(({ id }) => id)

  const [spotlightAssetIds, setSpotlightAssetIds] = useSessionStorage<number[]>(
    `${storageKey}-SPOTLIGHT-ASSET-IDS`,
    targetAssetIds
  )

  const [benchmarkSize, setBenchmarkSize] = useSessionStorage<number>(
    `${storageKey}-BENCHMARK-SIZE`,
    50
  )

  const [hasChanges, setHasChanges] = useSessionStorage<boolean>(`${storageKey}-HAS-CHANGES`, false)

  const [missingDataAssetIds, setMissingDataAssetIds] = useState<number[]>([])
  const [searchQuery, setSearchQuery] = useState<string | null>(null)

  // Select the assets that are fetched by being added manually. We want to avoid
  // a flicker when the assets are added to the table which is where the
  // "keepPreviousData" option comes in.
  const swrAddedAssets = useAssetListItems(addedAssetIds, {
    keepPreviousData: addedAssetIds.length > 0,
  })
  const addedAssets = swrAddedAssets.data.items

  // Update the selected asset ids when the user selects or deselects an asset.
  const handleSelectAssetId = useCallback(
    (assetId: number, isSelected: boolean) => {
      const newDeselectedAssetIds = isSelected
        ? deselectedAssetIds.filter((id) => id !== assetId)
        : [...deselectedAssetIds, assetId]
      setDeselectedAssetIds(newDeselectedAssetIds)
      setHasChanges(true)
    },
    [deselectedAssetIds, setDeselectedAssetIds, setHasChanges]
  )

  const handleClickSpotlightAssetId = useCallback(
    (assetId: number) => {
      const newSpotlightAssetIds = spotlightAssetIds.includes(assetId)
        ? spotlightAssetIds.filter((id) => id !== assetId)
        : [...spotlightAssetIds, assetId]
      setSpotlightAssetIds(newSpotlightAssetIds)
      setHasChanges(true)
    },
    [spotlightAssetIds, setSpotlightAssetIds, setHasChanges]
  )

  // Add assets to the selected assets list and the added assets list when
  // someone saves the dialog.
  const handleAddAssets = useCallback(
    async (newAssetIds: number[]) => {
      setAddedAssetIds([...newAssetIds, ...addedAssetIds])
      setHasChanges(true)
    },
    [addedAssetIds, setAddedAssetIds, setHasChanges]
  )

  // Combine the assets from the backend with the initial assets.
  const allAssets = useMemo(() => {
    const manualAddedAssets = addBenchmarkingItemType(addedAssets, 'Added')

    // Process initial assets to sort out similar and curated companies and
    // pre-sort by rank.
    const processedAssets = processInitialAssets(initialAssets)

    // If the benchmark size is adjustable, we show all assets up to the benchmark size.
    const assetsToShow = allowAdjustBenchmarkSize
      ? processedAssets.slice(0, benchmarkSize + 1) // +1 to account for the target asset
      : processedAssets

    return [...manualAddedAssets, ...assetsToShow]
  }, [addedAssets, initialAssets, benchmarkSize, allowAdjustBenchmarkSize])

  // Gather all assets; prioritize those that came from the backend.
  // The assets are ordered by the order of the assetIds array and the initial
  // assets are appended at the end.
  const [sortedAssets, sort, onSort] = useSortedAssets(
    allAssets,
    deselectedAssetIds,
    addedAssetIds,
    initialSort
  )

  // Only assets with datapoints for the X/Y axis and a size are plotted in
  // the chart. Assets that can't be plotted are marked as missing in the table.
  const handleChangeSettings = useCallback(
    (
      xAxis: AxisConfig,
      yAxis: AxisConfig,
      sizeType: ChartSizeTypeConfig<AssetListItem>,
      groupBy: ChartGroupByConfig<AssetListItem>
    ) => {
      const assetsMissingData = sortedAssets.filter(
        (asset) =>
          xAxis.getValue(asset) === null ||
          yAxis.getValue(asset) === null ||
          sizeType.getValue(asset) === null
      )
      setMissingDataAssetIds(assetsMissingData.map(({ id }) => id))
    },
    [sortedAssets]
  )

  // Resets to the initial state.
  const handleReset = useCallback(() => {
    setDeselectedAssetIds([])
    setAddedAssetIds([])
    setSpotlightAssetIds(targetAssetIds)
    setBenchmarkSize(50)
    setHasChanges(false)
  }, [
    setAddedAssetIds,
    setBenchmarkSize,
    setDeselectedAssetIds,
    setHasChanges,
    setSpotlightAssetIds,
    targetAssetIds,
  ])

  // Reset the state when the initial assets change. This happens when the user
  // changes filters of a filtered bookmark list.
  const prevInitialAssets = usePrevious(initialAssets.map((asset) => asset.id).sort())
  useEffect(() => {
    if (
      prevInitialAssets &&
      !isEqual(initialAssets.map((asset) => asset.id).sort(), prevInitialAssets)
    ) {
      handleReset()
    }
  }, [handleReset, initialAssets, prevInitialAssets])

  // Filter the assets based on the search query.
  const filteredAssets = useMemo(
    () =>
      sortedAssets.filter((asset) => !searchQuery || matchesSearchQuery(asset.name, searchQuery)),
    [sortedAssets, searchQuery]
  )

  // Only show assets that are selected. In addition, only pass assets that have
  // data for the X/Y axis and size to prevent them from affecting the axis calculations.
  const plottableAssets = useVisibleAssets(sortedAssets, deselectedAssetIds, missingDataAssetIds)

  // Only show a loader if the initial assets are still loading. When adding
  // assets manually there's no need to show the loader.
  if (loadingInitialAssets) {
    return <Loading />
  }

  return (
    <Stack
      gap={2}
      mb={2}
      width={'100%'}>
      <BenchmarkingChart
        assets={plottableAssets}
        industrySegments={industrySegments}
        investorStrategies={investorStrategies}
        onChangeSettings={handleChangeSettings}
        spotlightAssetIds={spotlightAssetIds}
      />

      <BenchmarkingTable
        allowAdjustBenchmarkSize={allowAdjustBenchmarkSize}
        assets={filteredAssets}
        benchmarkSize={benchmarkSize}
        deselectedAssetIds={deselectedAssetIds}
        hasChanges={hasChanges}
        missingDataAssetIds={missingDataAssetIds}
        onAddAssets={handleAddAssets}
        onClickSpotlightAssetId={handleClickSpotlightAssetId}
        onReset={handleReset}
        onSearch={setSearchQuery}
        onSelectAssetId={handleSelectAssetId}
        onSetBenchmarkSize={(size) => {
          setHasChanges(true)
          setBenchmarkSize(size)
        }}
        onSort={onSort}
        sort={sort}
        spotLightAssetIds={spotlightAssetIds}
      />
    </Stack>
  )
}
