import { ManagementPositionType, ManagerStatusType } from './lib/rpc-shared-model'
import { ChipOption, Option } from './option'

export const MANAGER_POSITION_OPTIONS: ReadonlyArray<
  Option<ManagementPositionType> & { shortLabel?: string }
> = [
  {
    label: 'CEO',
    value: ManagementPositionType.ChiefExecutiveOfficer,
  },
  {
    label: 'Co-CEO',
    value: ManagementPositionType.CoChiefExecutiveOfficer,
  },
  {
    label: 'Managing director',
    shortLabel: 'MD',
    value: ManagementPositionType.ManagingDirector,
  },
  {
    label: 'Director',
    shortLabel: 'Dir',
    value: ManagementPositionType.Director,
  },
  {
    label: 'Chair',
    value: ManagementPositionType.Chairman,
  },
  {
    label: 'Board member',
    shortLabel: 'Board',
    value: ManagementPositionType.BoardMember,
  },
  {
    label: 'Representative',
    shortLabel: 'Rep',
    value: ManagementPositionType.Representative,
  },
]

export const MANAGER_STATUS_OPTIONS: ReadonlyArray<ChipOption<ManagerStatusType>> = [
  {
    label: 'Valid',
    value: ManagerStatusType.Valid,
    color: 'success',
  },
  {
    label: 'Invalid',
    value: ManagerStatusType.Invalid,
    color: 'error',
  },
  {
    label: 'To validate',
    value: ManagerStatusType.ToValidate,
    color: 'info',
  },
]
