import {
  JsonRpcError,
  JsonRpcErrorResponse,
  JsonRpcResponse,
  JsonRpcSuccessResponse,
} from '@gain/jsonrpc'
import { ValidationIssue } from '@gain/rpc/shared-model'

import { isValidationFailed, RpcError } from './rpc-error'

function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object'
}

export function isJsonRpcError(error: unknown | Record<string, unknown>): error is JsonRpcError {
  if (error === null || typeof error !== 'object') {
    return false
  }

  return (
    isRecord(error) &&
    'code' in error &&
    typeof error['code'] === 'number' &&
    'message' in error &&
    typeof error['message'] === 'string'
  )
}

/**
 * Type-guard that is used to check validation issues
 */
function isValidationIssue(value: unknown): value is ValidationIssue {
  return (
    isRecord(value) &&
    'type' in value &&
    typeof value['type'] === 'string' &&
    'path' in value &&
    typeof value['path'] === 'string' &&
    'message' in value &&
    typeof value['message'] === 'string'
  )
}

export function isJsonRpcValidationError(error: JsonRpcError): boolean {
  return isValidationFailed(error.code)
}

export function isValidationIssueArray(value: unknown): value is ValidationIssue[] {
  return Array.isArray(value) && value.every(isValidationIssue)
}

export function isJsonRpcValidationErrorWithIssues(
  error: JsonRpcError
): error is JsonRpcError<ValidationIssue[]> {
  if (!isJsonRpcValidationError(error)) {
    return false
  }

  return isValidationIssueArray(error.data)
}

export function isJsonRpcSuccessResponse<T>(
  response: JsonRpcResponse<T>
): response is JsonRpcSuccessResponse<T> {
  return !('error' in response)
}

export function isJsonRpcErrorResponse<Result>(
  response: JsonRpcResponse<Result>
): response is JsonRpcErrorResponse {
  return 'error' in response
}

export const validateRpcResponse = <T>(response: JsonRpcResponse<T>) => {
  if (isJsonRpcErrorResponse(response)) {
    const {
      error: { message, code, data },
    } = response as JsonRpcErrorResponse

    throw new RpcError(message, code, data)
  }

  return response.result
}
