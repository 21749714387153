import { DealCloudIcon } from '@gain/components/icons'
import { useUserContext } from '@gain/modules/user'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

import { StyledLoadingButton } from './button-add-to-dealcloud'
import { showDealCloudProgress } from './dealcloud-snackbar'

interface ButtonAddAssetsToDealCloudProps {
  assetIds: number[]
  variant?: 'text' | 'icon'
}

export default function ButtonAddAssetsToDealCloud({
  assetIds,
  variant = 'text',
}: ButtonAddAssetsToDealCloudProps) {
  const { enqueueSnackbar } = useSnackbar()
  const userContext = useUserContext()

  const handleOnClick = useCallback(async () => {
    showDealCloudProgress(enqueueSnackbar, assetIds)
  }, [enqueueSnackbar, assetIds])

  if (!userContext.userProfile?.featureDealCloud) {
    return null
  }

  return (
    <StyledLoadingButton
      onClick={handleOnClick}
      startIcon={variant === 'icon' ? undefined : <DealCloudIcon />}
      variant={variant === 'icon' ? 'icon' : 'outlined'}>
      {variant === 'icon' ? <DealCloudIcon /> : 'Add to DealCloud'}
    </StyledLoadingButton>
  )
}
