import { useUserCurrency } from '@gain/api/app/hooks'
import { CurrencyDisplayType } from '@gain/rpc/shared-model'
import { useCallback } from 'react'

import { formatCurrency, FormatCurrencyOptions, isAtLeastThousand } from './format-currency'
import { useConvertCurrencyCallback } from './use-convert-currency-callback'
import { FormatCurrencyCallbackOptions } from './use-format-currency-callback'

/**
 * Formats a currency range. Some examples:
 *
 *  >$1m
 *  $1-3m
 *  <$1m
 *  NOK >1m
 *  NOK 1-3m
 *  NOK <3m
 */
export function useFormatCurrencyRangeCallback() {
  const convertCurrency = useConvertCurrencyCallback()
  const userCurrency = useUserCurrency()

  return useCallback(
    (min: number | null, max: number | null, options?: FormatCurrencyCallbackOptions) => {
      // Because we are dealing with ranges we need to ensure that both
      // values have the same format, we can't rely on autoformatting.
      let suffix = 'm'
      let format: FormatCurrencyOptions['format'] = 'millions'

      let minConverted = convertCurrency(min, options?.dataCurrency ?? 'EUR', userCurrency.name)
      let maxConverted = convertCurrency(max, options?.dataCurrency ?? 'EUR', userCurrency.name)

      if (options?.format === 'thousands-or-millions') {
        suffix = 'k'
        format = 'thousands'

        // Check if either min or max is a million. If so, we force both
        // values to be formatted as millions.
        if (
          (minConverted && Math.abs(minConverted) >= 1_000_000) ||
          (maxConverted && Math.abs(maxConverted) >= 1_000_000)
        ) {
          // For format "thousands-or-millions" the value is raw (i.e. value 1 = 1 EUR), but
          // for the format "millions" we need the value in millions (i.e. value 1 = 0.000001 EUR).
          if (minConverted) {
            minConverted /= 1_000_000
          }
          if (maxConverted) {
            maxConverted /= 1_000_000
          }
          suffix = 'm'
          format = 'millions'
        }
      } else {
        // Check if either min or max is a billion. If so, we force both
        // values to be billions.
        if (isAtLeastThousand(minConverted) || isAtLeastThousand(maxConverted)) {
          suffix = 'bn'
          format = 'billions'
        }
      }

      // Determine whether to show "$" or "NOK" etc. We can't use formatCurrency here
      // because the negation symbol is added in the wrong place for ranges.
      let symbol = ''
      let code = ''
      if (userCurrency.display === CurrencyDisplayType.Symbol) {
        symbol = userCurrency.symbol
      } else {
        code = `${userCurrency.name} ` // Add space to separate from value
      }

      // Format min if available without currency or suffix (we add this manually)
      let minFormatted: string | null = null
      if (minConverted !== null) {
        minFormatted = formatCurrency(minConverted, {
          ...options,
          disablePrefix: true,
          disableSuffix: true,
          format,
        })
      }

      let maxFormatted: string | null = null
      if (maxConverted !== null) {
        maxFormatted = formatCurrency(maxConverted, {
          ...options,
          format,
          disablePrefix: true,
          disableSuffix: true,
        })
      }

      if (minFormatted !== null && maxFormatted !== null) {
        if (min === max) {
          // "$3m" or "NOK 3m"
          return `${code}${symbol}${minFormatted}${suffix}`
        }
        // "$3m-5m" or "NOK 3-5m"
        return `${code}${symbol}${minFormatted}-${maxFormatted}${suffix}`
      }

      // "≥$3m" or "NOK ≥3m"
      if (minFormatted !== null) {
        return `${code}≥ ${symbol}${minFormatted}${suffix}`
      }

      // "≤$3m" or "NOK ≤3m"
      if (maxFormatted !== null) {
        return `${code}≤ ${symbol}${maxFormatted}${suffix}`
      }

      return null
    },
    [convertCurrency, userCurrency.display, userCurrency.name, userCurrency.symbol]
  )
}
