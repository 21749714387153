import Tooltip from '@gain/components/tooltip'
import { useSplitList } from '@gain/utils/list'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

import AssetContentLink from './asset-content-link'

interface AssetLike {
  id: number | null
  logoFileUrl: string | null
  name: string
}

interface CompanyProps {
  asset: AssetLike
}

/**
 * Company component that renders the given conference asset as a logo with
 * a tooltip for more details.
 */
function Company({ asset }: CompanyProps) {
  return (
    <AssetContentLink
      key={asset.id}
      id={asset.id}
      logoFileUrl={asset.logoFileUrl}
      name={asset.name}
      variant={'logo'}
    />
  )
}

interface CompaniesCellProps {
  assets: AssetLike[]
}

/**
 * Renders a list of assets within a cell. Displays the first 3 assets
 * inline and shows a tooltip with the remaining assets when more than 3 are
 * present.
 */
export default function CompaniesCell({ assets }: CompaniesCellProps) {
  const [initial, rest] = useSplitList(assets, 3)

  return (
    <Stack
      flexDirection={'row'}
      gap={0.5}>
      {initial.map((asset) => (
        <Company
          key={asset.id}
          asset={asset}
        />
      ))}

      {rest.length > 0 && (
        <Tooltip
          title={
            <Stack
              flexDirection={'row'}
              flexWrap={'wrap'}
              gap={0.5}>
              {rest.map((asset) => (
                <Company
                  key={asset.id}
                  asset={asset}
                />
              ))}
            </Stack>
          }
          variant={'preview'}>
          <Chip
            label={`+${rest.length}`}
            variant={'outlined'}
          />
        </Tooltip>
      )}
    </Stack>
  )
}
