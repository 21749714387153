import { kebabCase } from 'lodash'
import { ExtractRouteParams } from 'react-router'
import { generatePath, useParams } from 'react-router-dom'

import { INVESTOR_STRATEGY_PAGE_PATH } from '../investor-strategy/route-strategy-path'
import { Params } from '../utils'

export interface InvestorStrategyAdvisorDealsPagePathParams extends Params {
  investorId: string
  investorName: string
  strategyId: string
  strategyName: string
  advisorId: string
  advisorName: string
}

export const INVESTOR_STRATEGY_PAGE_ADVISOR_DEALS_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/advisor-deals/:advisorId/:advisorName`

export function useInvestorStrategyAdvisorDealsPageParams() {
  const params = useParams<InvestorStrategyAdvisorDealsPagePathParams>()

  return {
    investorId: parseInt(params.investorId, 10),
    investorName: params.investorName,
    strategyId: parseInt(params.strategyId, 10),
    strategyName: params.strategyName,
    advisorId: parseInt(params.advisorId, 10),
    advisorName: params.advisorName,
  }
}

export function generateInvestorStrategyAdvisorPath(
  params: ExtractRouteParams<typeof INVESTOR_STRATEGY_PAGE_ADVISOR_DEALS_PATH>
) {
  return generatePath(INVESTOR_STRATEGY_PAGE_ADVISOR_DEALS_PATH, {
    ...params,
    investorName: kebabCase(`${params.investorName}`) || '-',
    strategyName: kebabCase(`${params.strategyName}`) || '-',
    advisorName: kebabCase(`${params.advisorName}`) || '-',
  })
}
