import { IndustryIcon } from '@gain/components/icons'
import { TooltipHeader } from '@gain/components/tooltip'
import { IndustryListItem } from '@gain/rpc/app-model'
import { formatScopeAndCountries } from '@gain/utils/countries'
import { formatDate } from '@gain/utils/date'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { DetailedHTMLProps, HTMLAttributes, useRef } from 'react'

import { KeyValueList, KeyValueListItem } from '../../../common/key-value/key-value-list'
import Rating from '../../../common/rating'

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 320,
}))

type TooltipIndustryTitleProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  industry: IndustryListItem
}

export default function TooltipIndustryTitle({ industry, ...props }: TooltipIndustryTitleProps) {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={ref}
      {...props}>
      <StyledContainer
        direction={'column'}
        spacing={2}>
        <TooltipHeader
          defaultIcon={<IndustryIcon />}
          logoSrc={industry.smallImageFileUrl}
          subtitle={formatDate(industry.date, { format: 'monthYear' })}
          title={industry.title}
          fullSizeLogo
        />

        <KeyValueList
          spacing={0.5}
          dense>
          {industry.ratingMedianOrganicGrowth !== null && (
            <KeyValueListItem
              label={'Median organic growth'}
              value={<Rating value={industry.ratingMedianOrganicGrowth} />}
            />
          )}

          {industry.ratingMedianEbitda !== null && (
            <KeyValueListItem
              label={'Median EBITDA margin'}
              value={<Rating value={industry.ratingMedianEbitda} />}
            />
          )}

          {industry.regions.length > 0 && (
            <KeyValueListItem
              label={'Region'}
              value={formatScopeAndCountries(industry.regions, { scope: industry.scope })}
            />
          )}

          <KeyValueListItem
            label={'Companies'}
            value={industry.linkedAssetsTotal}
          />
        </KeyValueList>
      </StyledContainer>
    </div>
  )
}
