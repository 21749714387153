import { UserListItem } from '@gain/rpc/app-model'
import { formatDateDistance } from '@gain/utils/date'

import { ColumnConfig } from '../../common/virtual-table'
import { NameVirtualTableHeaderCell } from '../../common/virtual-table/cells'
import UserListItemActionsTableCell from './user-list-item-actions-table-cell'
import { UserListItemAdministratorTableCell } from './user-list-item-administrator-table-cell'
import { UserListItemStatusTableCell } from './user-list-item-status-table-cell'

export const userListItemTableColumns: ColumnConfig<UserListItem>[] = [
  {
    field: 'firstName',
    headerName: 'First name',
    align: 'left',
    width: 136,
    flex: 2,
    sticky: true,
    renderHeader: NameVirtualTableHeaderCell,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    align: 'left',
    width: 136,
    flex: 2,
    valueFormatter: ({ value }) => value,
  },
  {
    field: 'email',
    headerName: 'Email address',
    align: 'left',
    width: 256,
    flex: 2,
    valueFormatter: ({ value }) => value,
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'left',
    width: 152,
    renderCell: ({ value }) => <UserListItemStatusTableCell status={value} />,
  },
  {
    field: 'lastAccessAt',
    headerName: 'Last login',
    align: 'left',
    width: 176,
    flex: 1,
    valueFormatter: ({ value }) => formatDateDistance(value, new Date(), { emptyValue: 'Never' }),
  },
  {
    field: 'role',
    headerName: 'Admin',
    align: 'left',
    width: 112,
    renderCell: (params) => <UserListItemAdministratorTableCell {...params} />,
  },
  {
    field: 'userId',
    width: 80,
    renderCell: (params) => <UserListItemActionsTableCell {...params} />,
  },
]
