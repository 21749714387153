import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'

export type TLinkProps = LinkProps & {
  disableStyle?: boolean
}

const StyledReactRouterLink = styled(ReactRouterLink, {
  shouldForwardProp: (propName) => propName !== 'disableStyle',
})<{ disableStyle: boolean }>(({ theme, disableStyle }) => ({
  ...(!disableStyle && {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  }),
  ...(disableStyle && {
    display: 'contents',
    color: 'inherit',
    textDecoration: 'none',
  }),
}))

export const Link = forwardRef<HTMLAnchorElement, TLinkProps>(
  ({ disableStyle = false, ...props }, ref) => {
    return (
      <StyledReactRouterLink
        ref={ref}
        disableStyle={disableStyle}
        {...props}
      />
    )
  }
)
