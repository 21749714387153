import { ListItemKey } from '@gain/rpc/list-model'
import { isDefined } from '@gain/utils/common'
import { useCallback } from 'react'
import { useField, useForm } from 'react-final-form'

import Filter from '../filter'
import FilterFieldContextProvider from '../filter/filter-field-context-provider'
import { useFilterConfig } from '../filter-bar/filter-bar-context'
import { FilterConfig } from '../filter-config/filter-config-model'
import { FilterValueItem } from '../filter-value-model'
import FilterItemContainer from './filter-item-container'
import FilterItemHeader from './filter-item-header'

export interface FilterItemProps {
  groupIndex: number
  itemIndex: number
  onDelete: (groupIndex: number, itemIndex: number) => void
  disableDelete?: boolean
}

export default function FilterItem<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ groupIndex, itemIndex, onDelete, disableDelete }: FilterItemProps) {
  const path = `groups[${groupIndex}].value[${itemIndex}]`
  const field = useField<FilterValueItem<Item, FilterField>>(path)
  const filter = useFilterConfig<Item, FilterField>(field.input.value.filterId)
  const form = useForm()

  const handleDelete = useCallback(() => {
    onDelete(groupIndex, itemIndex)
  }, [onDelete, groupIndex, itemIndex])

  const handleClear = useCallback(() => {
    // Would like to use form.resetFieldState to make sure we get the initial values but this
    // doesn't seem to work, so we set all fields to null
    form.change(`${path}.value`, null)
  }, [path, form])

  // Used when the filter has a dropdown to select a different filter
  const handleChange = useCallback(
    (filterId: string) => {
      form.change(`${path}.filterId`, filterId)
    },
    [path, form]
  )

  return (
    <FilterFieldContextProvider
      config={filter as FilterConfig}
      groupIndex={groupIndex}
      itemIndex={itemIndex}>
      <FilterItemContainer>
        <FilterItemHeader
          disableClear={!isDefined(field.input.value.value)}
          disableDelete={disableDelete || filter.disableDelete}
          filter={filter}
          onChange={handleChange}
          onClear={handleClear}
          onDelete={handleDelete}
        />

        <Filter
          filter={filter}
          groupIndex={groupIndex}
          itemIndex={itemIndex}
          onClear={handleClear}
        />
      </FilterItemContainer>
    </FilterFieldContextProvider>
  )
}
