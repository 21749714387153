import { formatNumber } from '@gain/utils/number'

import {
  ChartTooltipDataCell,
  ChartTooltipDivider,
  ChartTooltipLabelCell,
  ChartTooltipTable,
} from '../../../../common/chart/chart-tooltip'
import { BrokerRecommendation } from './market-chart-types'

interface BrokerRecommendationTooltipTitleProps {
  recommendation?: BrokerRecommendation
}

/**
 * BrokerRecommendationTooltipTitle renders the contents of the broker recommendation
 * tooltip that shows the buy/hold/sell values of a broker recommendation in the
 * bar chart below the market price chart.
 */
export default function BrokerRecommendationTooltipTitle({
  recommendation,
}: BrokerRecommendationTooltipTitleProps) {
  const totalRatings = recommendation?.total ?? 0
  const total = formatNumber(totalRatings, { emptyValue: '-' })

  return totalRatings === 0 ? (
    <span>No ratings available</span>
  ) : (
    <>
      <ChartTooltipTable>
        <tbody>
          <tr>
            <ChartTooltipLabelCell>Buy</ChartTooltipLabelCell>
            <ChartTooltipDataCell>{recommendation?.buy ?? '-'}%</ChartTooltipDataCell>
          </tr>
          <tr>
            <ChartTooltipLabelCell>Hold</ChartTooltipLabelCell>
            <ChartTooltipDataCell>{recommendation?.hold ?? '-'}%</ChartTooltipDataCell>
          </tr>
          <tr>
            <ChartTooltipLabelCell>Sell</ChartTooltipLabelCell>
            <ChartTooltipDataCell>{recommendation?.sell ?? '-'}%</ChartTooltipDataCell>
          </tr>
        </tbody>
      </ChartTooltipTable>

      <ChartTooltipDivider />

      <ChartTooltipTable>
        <tbody>
          <tr>
            <ChartTooltipLabelCell>Ratings</ChartTooltipLabelCell>
            <ChartTooltipDataCell>{total}</ChartTooltipDataCell>
          </tr>
        </tbody>
      </ChartTooltipTable>
    </>
  )
}
