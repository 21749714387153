import { InfoIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { Asset, AssetListItem } from '@gain/rpc/app-model'
import { useDialogState } from '@gain/utils/dialog'
import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'

import { AlertDialog } from '../../../common/dialog'
import ParentLink from '../../../features/parent-link/parent-link'

const StyledContainer = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(0.5),
  cursor: 'pointer',
  color: theme.palette.error.main,
  ...theme.typography.body2,
}))

interface NotMaintainedMessageProps {
  asset: Asset
  parentAsset: AssetListItem | null
  children: ReactNode
}

export default function NotMaintainedMessage({
  asset,
  parentAsset,
  children,
}: NotMaintainedMessageProps) {
  const [isOpen, open, close] = useDialogState()

  return (
    <>
      <StyledContainer onClick={open}>
        <InfoIcon
          color={'error'}
          fontSize={'inherit'}
          style={{ cursor: 'pointer' }}
        />
        {children}
      </StyledContainer>
      <AlertDialog
        message={
          <>
            This company is a subsidiary, and as a result, its business description and financials
            are no longer maintained.
            {parentAsset && (
              <>
                {' '}
                Financial reporting is handled by the parent company — for further insight and
                consolidated financials, see the parent profile: <ParentLink parent={parentAsset} />
                .
              </>
            )}
          </>
        }
        onClose={close}
        open={isOpen}
        title={'Profile partially maintained'}
      />
    </>
  )
}
