import Tooltip from '@gain/components/tooltip'
import { ListItemKey } from '@gain/rpc/list-model'
import { getSubsectorsForSector, SECTORS } from '@gain/utils/sector'
import { isNotEmpty } from '@gain/utils/typescript'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { uniq } from 'lodash'
import { useMemo } from 'react'

import { useFindSectorCallback } from '../../api/sector-hooks'
import { useCheckboxListFilterToggle } from '../filter/filter-bar/filter/filter-checkbox/use-checkbox-list-filter-toggle'

function getSubsectorNames(sectorName: string) {
  return getSubsectorsForSector(sectorName).map((subsector) => subsector.name)
}

function isActiveSector(sectorName: string, activeSubsectors: string[]) {
  return SECTORS.find((sector) => {
    if (sector.name === sectorName) {
      return sector.subsectors.every((subsector) => activeSubsectors.includes(subsector.name))
    }

    return false
  })
}

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0, 0.75 / 2),
  '&:first-child': {
    marginLeft: 0,
  },
  '&:last-child': {
    marginRight: 0,
  },
}))

interface AssetSectorsTableCellProps<Item extends object, Field extends ListItemKey<Item>> {
  sectorNames: string[]
  maxItems?: number
  dense?: boolean
  field?: Field
}

export function AssetSectorsTableCell<
  Item extends object = object,
  Field extends ListItemKey<Item> = never
>({ sectorNames, dense, maxItems = 1, field }: AssetSectorsTableCellProps<Item, Field>) {
  const [active, handleClick] = useCheckboxListFilterToggle<string, Item, Field>(
    sectorNames.flatMap(getSubsectorNames),
    field
  )

  const findSector = useFindSectorCallback()

  const items = useMemo(() => {
    return uniq(sectorNames.filter(isNotEmpty)).map((name) => {
      const found = findSector(name)

      if (found) {
        return {
          name: found.name,
          title: found.title,
        }
      }

      return {
        name: name,
        title: name,
      }
    })
  }, [sectorNames, findSector])

  const rest = items.slice(maxItems)

  if (items.length === 0) {
    return <>-</>
  }

  return (
    <>
      {items.slice(0, maxItems).map((sector, index) => (
        <StyledChip
          key={sector.name}
          color={isActiveSector(sector.name, active) ? 'info' : undefined}
          label={sector.title}
          onClick={field ? handleClick(getSubsectorNames(sector.name)) : undefined}
          size={dense ? 'small' : 'medium'}
        />
      ))}
      {rest.length > 0 && (
        <Tooltip
          disableInteractive={!field}
          title={
            <Stack
              display={'flex'}
              flexDirection={'row'}
              flexWrap={'wrap'}
              gap={0.5}>
              {rest.map((sector) => (
                <StyledChip
                  key={sector.name}
                  color={isActiveSector(sector.name, active) ? 'info' : undefined}
                  label={sector.title}
                  onClick={field ? handleClick(getSubsectorNames(sector.name)) : undefined}
                  size={dense ? 'small' : 'medium'}
                />
              ))}
            </Stack>
          }
          variant={'preview'}>
          <StyledChip
            label={`+${rest.length}`}
            size={dense ? 'small' : 'medium'}
            variant={'outlined'}
          />
        </Tooltip>
      )}
    </>
  )
}
