import { BookmarkIcon, CompanyIcon } from '@gain/components/icons'
import { AssetListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { listFilter, serializeListSort } from '@gain/rpc/utils'
import { useOpenLink } from '@gain/utils/router'
import Hidden from '@mui/material/Hidden'
import Link from '@mui/material/Link'
import { useCallback, useState } from 'react'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemText,
  MobileListItemValue,
} from '../../../common/list-mobile'
import Logo from '../../../common/logo'
import { useMeasureMinWidth } from '../../../common/responsive'
import { TableFooterButton } from '../../../common/table/table-footer-button'
import { generateAssetPagePath } from '../../../routes/utils'
import {
  ASSET_DEFAULT_FILTERS,
  ASSET_FILTER_MAP,
  ASSET_FILTER_MENU,
  AssetFilterField,
} from '../../asset/asset-filter-bar'
import { assetTableColumnNames } from '../../asset/asset-list-item-table/asset-list-item-table-columns'
import BookmarkListAddMenu from '../../bookmarks-list/bookmark-add-object/bookmark-list-add-menu'
import ColumnPickerButton from '../../column-picker/column-picker-button'
import { ButtonAddAssetsToDealCloud } from '../../dealcloud'
import { ExportListButton } from '../../export/export-button'
import { useFilterModelQueryParam } from '../../filter/filter-bar'
import FinancialValue from '../../financial/financial-value'
import { ButtonAddAssetsToSalesforce } from '../../salesforce'
import ListView, { ListViewApi, ListViewProps } from '../core'
import ListViewFooter from '../list-view-footer'
import useActiveFilterColumns from '../use-active-filter-columns'
import AssetUpdateType from './asset-update-type'
import filterToAssetTableColumnMapping from './filter-to-asset-table-column-mapping'
import useActiveAssetFilterSorting from './use-active-asset-filter-sorting'
import useAssetListViewColumns from './use-asset-list-view-columns'

interface AssetListViewProps
  extends Pick<
    ListViewProps<'data.listAssets', AssetListItem, AssetListItem, AssetFilterField>,
    | 'defaultFilter'
    | 'defaultSort'
    | 'disableFilters'
    | 'renderBulkActions'
    | 'renderFilterBarActions'
    | 'apiRef'
    | 'onFilter'
    | 'tableEmptyProps'
    | 'showEmptyWhenNoFilters'
    | 'renderPageActions'
    | 'hideCount'
    | 'inline'
  > {
  maxLimit?: number
  maxLimitTooltip?: string
  disableClear?: boolean
  disableAddToList?: boolean
  disableListHeader?: boolean
  listSecondaryType?: 'updateType'
}

export const LIST_VIEW_ASSET_DEFAULT_SORT = [
  {
    direction: 'desc',
    field: 'lastDealYear',
  },
  {
    direction: 'desc',
    field: 'lastDealMonth',
  },
] as ListSort<AssetListItem>[]

export default function AssetListView({
  defaultFilter,
  defaultSort,
  renderBulkActions,
  renderFilterBarActions,
  renderPageActions,
  disableAddToList,
  disableListHeader,
  listSecondaryType,
  maxLimit,
  maxLimitTooltip,
  ...props
}: AssetListViewProps) {
  const [listFooterRef, isLargeFooter] = useMeasureMinWidth<HTMLDivElement>(970)
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState<Element | null>(null)
  const openLink = useOpenLink()
  const [filterModel] = useFilterModelQueryParam<AssetListItem, AssetFilterField>()
  const activeFilterColumns = useActiveFilterColumns(
    filterModel,
    ASSET_FILTER_MAP,
    assetTableColumnNames,
    filterToAssetTableColumnMapping
  )
  const columns = useAssetListViewColumns(activeFilterColumns, maxLimit, maxLimitTooltip)
  const activeFilterSorting = useActiveAssetFilterSorting(columns)

  const handleRenderPageActions = useCallback(
    (api: ListViewApi<'data.listAssets', AssetListItem, AssetListItem, AssetFilterField>) => {
      const additionalPageActions = renderPageActions ? renderPageActions(api) : null

      const pageActions = [
        <Hidden mdDown>
          <ColumnPickerButton
            activeFilterColumns={activeFilterColumns}
            columnConfigId={'asset'}
          />
        </Hidden>,
        <Hidden only={'xs'}>
          <ExportListButton
            method={'data.exportAssets'}
            params={{
              columns: [],
              filename: 'Company export - Gain.pro.xlsx',
              filter: api.queryParams.filter,
              search: api.search || '',
              sort: api.sort.map(serializeListSort),
            }}
            tooltipMode={'always'}
            variant={'text'}
          />
        </Hidden>,
      ]

      if (additionalPageActions) {
        if (Array.isArray(additionalPageActions)) {
          return additionalPageActions.concat(pageActions)
        }

        return [additionalPageActions, ...pageActions]
      }

      return pageActions
    },
    [activeFilterColumns, renderPageActions]
  )

  const handleRenderBulkActions = useCallback(
    (api: ListViewApi<'data.listAssets', AssetListItem, AssetListItem, AssetFilterField>) => (
      <ListViewFooter
        ref={listFooterRef}
        api={api}
        exportMethod={'data.exportAssets'}
        itemNamePlural={'companies'}
        itemNameSingular={'company'}>
        {renderBulkActions && renderBulkActions(api)}
        {!disableAddToList && (
          <BookmarkListAddMenu
            anchorEl={addMenuAnchorEl}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
            objectIds={api.selectedRows.map((asset) => asset.id)}
            onClose={() => setAddMenuAnchorEl(null)}
            open={Boolean(addMenuAnchorEl)}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
          />
        )}

        {!disableAddToList && (
          <TableFooterButton
            color={'grey'}
            onClick={(event) => setAddMenuAnchorEl(event.currentTarget)}
            startIcon={isLargeFooter ? <BookmarkIcon /> : undefined}
            variant={isLargeFooter ? 'text' : 'icon'}>
            {isLargeFooter ? 'Add to bookmarks' : <BookmarkIcon />}
          </TableFooterButton>
        )}

        <ExportListButton
          color={'primary'}
          method={'data.exportAssets'}
          params={{
            columns: [],
            filename: 'Company export - Gain.pro.xlsx',
            filter: [
              listFilter<AssetListItem>(
                'id',
                '=',
                api.selectedRows.map(({ id }) => id)
              ),
            ],
            search: api.search || '',
            sort: api.sort.map(serializeListSort),
          }}
          tooltipMode={'never'}
          variant={isLargeFooter ? 'contained' : 'icon-contained'}
        />

        <ButtonAddAssetsToDealCloud
          assetIds={api.selectedRows.map((asset) => asset.id)}
          variant={isLargeFooter ? 'text' : 'icon'}
        />

        <ButtonAddAssetsToSalesforce
          assetIds={api.selectedRows.map((asset) => asset.id)}
          variant={isLargeFooter ? 'text' : 'icon'}
        />
      </ListViewFooter>
    ),
    [addMenuAnchorEl, disableAddToList, isLargeFooter, renderBulkActions, listFooterRef]
  )

  return (
    <ListView
      addFilterMenu={ASSET_FILTER_MENU}
      defaultFilter={defaultFilter}
      defaultFilterModel={ASSET_DEFAULT_FILTERS}
      defaultSort={defaultSort || activeFilterSorting}
      filterConfigMap={ASSET_FILTER_MAP}
      method={'data.listAssets'}
      renderBulkActions={handleRenderBulkActions}
      renderFilterBarActions={renderFilterBarActions}
      renderPageActions={handleRenderPageActions}
      sm={{
        variant: 'virtual-table',
        VariantProps: {
          columns: columns,
          onRowClick({ row }, event) {
            openLink(
              generateAssetPagePath({
                id: row.id,
                name: row?.name || undefined,
              }),
              event
            )
          },
        },
      }}
      xs={{
        variant: 'list',
        VariantProps: {
          headerProps: !disableListHeader && {
            title: 'Name',
            secondaryTitle: 'Revenue',
          },
          renderListItem: (item) => (
            <MobileListItem
              key={item.id}
              disableDivider>
              <MobileListItemButton
                as={Link}
                {...{
                  href: generateAssetPagePath({ id: item.id, name: item.name, preview: false }),
                }}>
                <MobileListItemIcon>
                  <Logo
                    defaultIcon={<CompanyIcon />}
                    region={item.region}
                    size={40}
                    src={item.logoFileUrl}
                  />
                </MobileListItemIcon>
                <MobileListItemText
                  primary={item.name}
                  secondary={item.description}
                />
                <MobileListItemValue>
                  {listSecondaryType === 'updateType' ? (
                    <AssetUpdateType asset={item} />
                  ) : (
                    <FinancialValue amount={item.revenueEur} />
                  )}
                </MobileListItemValue>
              </MobileListItemButton>
            </MobileListItem>
          ),
        },
      }}
      checkboxSelection
      {...props}
    />
  )
}
