import { styled } from '@mui/material/styles'
import { PropsWithChildren } from 'react'
import { Link, LinkProps } from 'react-router-dom'

const StyledLink = styled(Link)(({ theme, to }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  minWidth: 0,
  textDecoration: 'none',
  color: 'inherit',
  gap: theme.spacing(1.5),
  height: 48,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(),

  // Disable the links if we are still loading
  ...(to === '#' && {
    pointerEvents: 'none',
  }),

  ...(to && {
    '&:hover': {
      backgroundColor: theme.palette.grey['100'],
    },
  }),
}))

export interface ObjectListItemLinkProps extends Omit<LinkProps, 'to'> {
  to?: string
  onClick?: () => void
  isSkeleton?: boolean
}

export function ObjectListItemLink({
  to,
  onClick,
  isSkeleton,
  children,
  ...props
}: PropsWithChildren<ObjectListItemLinkProps>) {
  return (
    <StyledLink
      {...props}
      as={!to && !onClick ? 'div' : Link}
      onClick={onClick}
      to={isSkeleton ? '#' : to ?? ''}>
      {children}
    </StyledLink>
  )
}
