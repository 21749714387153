import { useRpcClient } from '@gain/api/swr'
import Snackbar from '@gain/components/snackbar'
import { TicketAttributeType, TicketType } from '@gain/rpc/app-model'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import DialogHeader from '../../common/dialog-header'
import { TextInput } from '../../common/form'

const StyledForm = styled('form')({
  display: 'contents',
})

interface FormData {
  companyName: string
  companyWebsite: string
  comment: string
}

const FormSchema = Yup.object().shape({
  companyName: Yup.string().required('Company name field is required'),
  companyWebsite: Yup.string().required('Company website field is required'),
  comment: Yup.string(),
})

const SNACKBAR_KEY = 'company-profile-requested'

export interface RequestProfileDialogProps {
  companyName?: string
  companyWebsite?: string
  open: boolean
  onClose: () => void
}

export default function RequestProfileDialog({
  companyName,
  companyWebsite,
  open,
  onClose,
}: RequestProfileDialogProps) {
  const fetcher = useRpcClient()
  const [error, setError] = useState<null | string>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const form = useForm<FormData>({
    defaultValues: {
      companyName: companyName || '',
      companyWebsite: companyWebsite || '',
      comment: '',
    },
    resolver: yupResolver(FormSchema),
  })

  // Reset form to name and website when the props change
  useEffect(() => {
    form.reset({
      companyName: companyName || '',
      companyWebsite: companyWebsite || '',
      comment: '',
    })
  }, [form, companyName, companyWebsite])

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    setSubmitting(true)
    setError(null)
    try {
      await fetcher({
        method: 'account.createTicket',
        params: {
          type: TicketType.RequestCompanyProfile,
          body: formData.comment,
          attributes: [
            { type: TicketAttributeType.CompanyName, value: formData.companyName },
            { type: TicketAttributeType.CompanyWebsite, value: formData.companyWebsite },
          ],
        },
      })
      enqueueSnackbar(undefined, {
        key: SNACKBAR_KEY,
        content: () => (
          <Snackbar
            id={SNACKBAR_KEY}
            message={'Your profile request has been received. We will get back to you shortly.'}
          />
        ),
        preventDuplicate: true,
      })
      onClose()
    } catch {
      setError('An error occurred while submitting the form, please try again later.')
    }

    setSubmitting(false)
  }

  return (
    <FormProvider {...form}>
      <Dialog
        maxWidth={'sm'}
        onClose={onClose}
        open={open}
        TransitionProps={{
          onExited: () => form.reset(),
        }}
        fullWidth>
        <StyledForm
          onSubmit={form.handleSubmit(onSubmit)}
          noValidate>
          <DialogHeader
            onCloseClick={onClose}
            title={'Request a company profile'}
          />

          <DialogContent>
            <Grid
              rowSpacing={2}
              container>
              {error && (
                <Grid
                  xs={12}
                  item>
                  <Alert
                    icon={false}
                    severity={'error'}>
                    {error}
                  </Alert>
                </Grid>
              )}
              <Grid
                xs={12}
                item>
                <Alert
                  icon={false}
                  severity={'info'}>
                  Please fill in the form below and we’ll get back to you within 3 business days.
                </Alert>
              </Grid>
              <Grid
                xs={12}
                item>
                <TextInput
                  label={'Company name'}
                  name={'companyName'}
                  placeholder={'Company name'}
                />
              </Grid>
              <Grid
                xs={12}
                item>
                <TextInput
                  label={'Company website'}
                  name={'companyWebsite'}
                  placeholder={'https://www.example.com'}
                  type={'url'}
                />
              </Grid>
              <Grid
                xs={12}
                item>
                <TextInput
                  label={'Comment'}
                  maxRows={10}
                  minRows={7}
                  name={'comment'}
                  placeholder={'Anything else you want to share with us?'}
                  multiline
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <LoadingButton
              color={'primary'}
              disabled={!form.formState.isDirty || !form.formState.isValid}
              loading={submitting}
              type={'submit'}
              variant={'contained'}
              fullWidth>
              Send
            </LoadingButton>
          </DialogActions>
        </StyledForm>
      </Dialog>
    </FormProvider>
  )
}
