import { chartColorSet } from '../../chart/chart-colors'

export interface BarChartItem {
  label: string
  value: number
  color?: string
}

export type BarChartColor = 'primary' | 'success'

export function getBarColor(color: BarChartColor): string {
  switch (color) {
    case 'primary':
      return chartColorSet[0]
    case 'success':
      return chartColorSet[1]
  }
}
