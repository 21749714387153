import { Investor, InvestorStrategyListItem } from '@gain/rpc/app-model'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { useOpenLink } from '@gain/utils/router'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import { MouseEvent, useCallback, useMemo, useRef } from 'react'

import Table, { createTableColumns } from '../../../../common/table'
import FinancialValue from '../../../../features/financial/financial-value'
import { generateInvestorStrategyPagePath } from '../../../investor-strategy'
import { DRY_POWDER_EXPLAINER } from '../../investor-profile-util'

const investorStrategiesTableClasses = generateUtilityClasses('InvestorStrategiesTable', [
  'estimate',
])

const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${investorStrategiesTableClasses.estimate}`]: {
    color: theme.palette.info.main,
  },
})) as typeof Table

function useColumns() {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  return useMemo(() => {
    return createTableColumns<InvestorStrategyListItem>(
      {
        field: 'strategyName',
        headerName: 'Strategy name',
        align: 'left',
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'assetsTotal',
        headerName: 'Companies',
        align: 'right',
        width: 80,
        valueFormatter: ({ value }) => {
          if (value === 0) {
            return '-'
          }
          return value
        },
      },
      {
        field: 'latestFundSizeEur',
        headerName: 'Latest fund size',
        align: 'right',
        width: 120,
        renderCell: ({ value }) => <FinancialValue amount={value} />,
      },
      {
        field: 'dryPowderMaxEur',
        headerName: 'Drypowder est.',
        align: 'right',
        width: 148,
        headerExplainer: DRY_POWDER_EXPLAINER,
        cellClassName: ({ row }) => {
          if (row.dryPowderMinEur !== null && row.dryPowderMaxEur !== null) {
            return investorStrategiesTableClasses.estimate
          }

          return undefined
        },
        valueFormatter: ({ row }) => {
          const min = row.dryPowderMinEur
          const max = row.dryPowderMaxEur

          if (min !== null && max !== null) {
            return formatCurrencyRange(min, max, { round: 'estimate' })
          }

          return '-'
        },
      },
      {
        field: 'investmentTicketSizeMinEur',
        headerName: 'Inv. ticket size',
        align: 'right',
        width: 148,
        valueFormatter: ({ row }) =>
          row.investmentTicketSizeMinEur && row.investmentTicketSizeMaxEur
            ? formatCurrencyRange(row.investmentTicketSizeMinEur, row.investmentTicketSizeMaxEur)
            : '-',
      }
    )
  }, [formatCurrencyRange])
}

interface InvestorStrategyTableProps {
  investor: Investor
  rows: InvestorStrategyListItem[]
}

export default function InvestorStrategiesTable({ rows, investor }: InvestorStrategyTableProps) {
  const openLink = useOpenLink()
  const tableRef = useRef<HTMLDivElement>(null)

  const columns = useColumns()

  const visibilityModel = useMemo((): ColumnVisibilityModel<InvestorStrategyListItem> => {
    return {
      dryPowderMaxEur: 700,
      investmentTicketSizeMinEur: 500,
    }
  }, [])

  const visibleColumns = useVisibleColumns(tableRef, columns, visibilityModel)

  const handleRowClick = useCallback(
    (row: InvestorStrategyListItem, event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      if (row.strategyId < 1) {
        return
      }

      const path = generateInvestorStrategyPagePath({
        investorId: investor.id,
        investorName: investor.name,
        strategyId: row.strategyId,
        strategyName: row.strategyName,
      })

      openLink(path, event)
    },
    [investor.id, investor.name, openLink]
  )

  const handleIsRowClickable = useCallback((row: InvestorStrategyListItem) => {
    return row.strategyId > 0
  }, [])

  return (
    <StyledTable
      ref={tableRef}
      columns={visibleColumns}
      isRowClickable={handleIsRowClickable}
      onRowClick={handleRowClick}
      rows={rows}
      dense
      disablePaddingBottomOnLastRow
      disableStickyHeader
    />
  )
}
