import { useListUsersByIds, useUserPermissionsByObject } from '@gain/api/app/hooks'
import { useUserProfileContext } from '@gain/modules/user'
import {
  UserListItem as UserListItemType,
  UserPermissionChange,
  UserPermissionRole,
} from '@gain/rpc/app-model'
import { useResizeObserver } from '@gain/utils/dom'
import { createRef, RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import { ShareActions } from './share-actions'
import ShareDialog from './share-dialog'
import { useShareContext } from './share-dialog-provider'
import useUpdateShareSettings from './use-update-share-settings'

function sortUser(a: UserListItemType, b: UserListItemType) {
  let sortResult = a.firstName.localeCompare(b.firstName)
  if (sortResult === 0) {
    sortResult = a.lastName.localeCompare(b.lastName)
  }
  if (sortResult === 0) {
    sortResult = a.email.localeCompare(b.email)
  }
  return sortResult
}

function useHasOverflow<T extends HTMLElement>(ref: RefObject<T>) {
  const [hasOverflow, setHasOverflow] = useState(false)

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return
    }

    setHasOverflow(ref.current.scrollHeight > ref.current.clientHeight)
    return
  }, [ref])

  useResizeObserver(ref, handleResize)

  return hasOverflow
}

export default function ShareDialogContainer() {
  const contentRef = createRef<HTMLDivElement>()
  const userProfile = useUserProfileContext()
  const [isOpen, setIsOpen] = useState(false)
  const prevOpen = usePrevious(isOpen)
  const fetchUsers = useListUsersByIds()
  const hasOverflow = useHasOverflow(contentRef)
  const [selectedUsers, setSelectedUsers] = useState<UserListItemType[]>([])
  const [visibleItems, setVisibleItems] = useState<UserListItemType[]>([])
  const [pendingChanges, setPendingChanges] = useState<UserPermissionChange[]>([])
  const [pendingRemovals, setPendingRemovals] = useState<number[]>([])
  const { objectType, objectId, closeShareDialog } = useShareContext()
  const { updatePermissions, isLoading } = useUpdateShareSettings()

  const hasObject = Boolean(objectType) && Boolean(objectId)
  const swrPermissions = useUserPermissionsByObject(() =>
    hasObject ? { objectType, objectId } : null
  )

  const userIds = useMemo(
    () => swrPermissions.data?.map(({ userId }) => userId) ?? [],
    [swrPermissions.data]
  )

  const hasChanges = hasObject && (pendingChanges.length > 0 || pendingRemovals.length > 0)

  // Refetch users when opening the modal
  useEffect(() => {
    if (isOpen && prevOpen !== isOpen) {
      setPendingChanges([])
      setPendingRemovals([])

      fetchUsers(userIds).then((result) => {
        setSelectedUsers(result.sort(sortUser))
        setVisibleItems(result)
      })
    }
  }, [prevOpen, fetchUsers, isOpen, userIds])

  useEffect(() => {
    setIsOpen(Boolean(objectId) && Boolean(objectType))
  }, [objectId, objectType])

  useEffect(() => {
    setVisibleItems(selectedUsers) // Trigger change in next render to ensure `in` changes from false to true
  }, [selectedUsers])

  const isOwner = (userId: number) => {
    const change = pendingChanges.find((item) => item.userId === userId)
    if (change) {
      return change.role === UserPermissionRole.Owner
    }

    const permission = swrPermissions.data?.find((item) => item.userId === userId)
    return permission?.role === UserPermissionRole.Owner ?? false
  }

  const handleSave = async () => {
    if (hasChanges && objectType && objectId) {
      await updatePermissions(objectType, objectId, pendingChanges, pendingRemovals)
    } else {
      closeShareDialog()
    }
  }

  // TODO when we have Zustand we should consider moving this to a store
  const handleAction = (action: ShareActions) => {
    switch (action.type) {
      case 'add':
        setPendingChanges((prev) => [
          ...prev,
          { userId: action.user.userId, role: UserPermissionRole.Editor },
        ])
        setSelectedUsers((prev) => [...prev, action.user])

        // Disable animation when scrolling down, we use `scrollTo` for those instead
        if (hasOverflow) {
          setVisibleItems((prev) => [...prev, action.user])
        }
        break
      case 'updateRole':
        setPendingChanges((prev) => [
          { userId: action.userId, role: action.role },
          ...prev.filter((item) => item.userId !== action.userId),
        ])
        break
      case 'hide':
        setVisibleItems((prev) => prev.filter((item) => item.userId !== action.userId))
        break
      case 'remove': {
        setSelectedUsers((prev) => prev.filter((item) => item.userId !== action.userId))

        // Remove pending changes for the user if any
        setPendingChanges((prev) => prev.filter((item) => item.userId !== action.userId))

        // If the user had permissions in the original list mark it for removal
        const existingPermissions = swrPermissions.data?.find(
          (item) => item.userId === action.userId
        )
        if (existingPermissions) {
          setPendingRemovals((prev) => [...prev, existingPermissions.id])
        }
        break
      }
    }
  }

  const currentUserRole = useMemo(() => {
    const permission = swrPermissions.data?.find((item) => item.userId === userProfile?.id)
    return permission?.role ?? null
  }, [swrPermissions.data, userProfile?.id])

  if (!currentUserRole) {
    return null
  }

  return (
    <ShareDialog
      ref={contentRef}
      currentUserRole={currentUserRole}
      hasChanges={hasChanges}
      isLoading={isLoading}
      isOwner={isOwner}
      objectId={objectId}
      objectType={objectType}
      onAction={handleAction}
      onClose={closeShareDialog}
      onSave={handleSave}
      open={isOpen}
      selectedUsers={selectedUsers}
      visibleUsers={visibleItems}
    />
  )
}
