import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { useIsXs } from '@gain/utils/responsive'
import { useMemo } from 'react'

import { ProfileInfoAndTabsProps } from './profile-info-and-tabs-model'
import ProfileInfoAndTabsSmUp from './profile-info-and-tabs-sm-up'
import ProfileInfoAndTabsXs from './profile-info-and-tabs-xs'

export default function ProfileTabBar({ tabs, ...props }: ProfileInfoAndTabsProps) {
  const isBrowserExtension = useIsBrowserExtension()
  const isXs = useIsXs()
  const visibleTabs = useMemo(() => {
    return tabs?.filter((tab) => !tab.hidden)
  }, [tabs])

  if (isXs && !isBrowserExtension) {
    return (
      <ProfileInfoAndTabsXs
        {...props}
        tabs={visibleTabs}
      />
    )
  }

  return (
    <ProfileInfoAndTabsSmUp
      allowScrollButtonsMobile={isBrowserExtension}
      tabs={visibleTabs}
      {...props}
    />
  )
}
