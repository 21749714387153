import { Lender, LenderCreditCountPerYear } from '@gain/rpc/app-model'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { CardContent, CardContentEmpty, CardContentLoading } from '../../../../common/card/card'
import BarChart from '../../../../common/echart/bar-chart/bar-chart'
import { getCreditsFilteredByYearPath } from './credit-counts-util'

interface CreditCountsChartProps {
  lender: Lender
  isLoading: boolean
  data: LenderCreditCountPerYear[]
  matured: boolean
}

export default function CreditCountsChart({
  lender,
  isLoading,
  data,
  matured,
}: CreditCountsChartProps) {
  const history = useHistory()

  // Prepare data for bar chart
  const barSeries = useMemo(() => {
    return data.map((item) => {
      return {
        label: item.year.toString(10),
        value: item.count,
      }
    })
  }, [data])

  // Show loading spinner while data is being fetched
  if (isLoading) {
    return <CardContentLoading />
  }

  // Show "No data available" when all counts equal 0
  if (data.every((item) => item.count === 0)) {
    return <CardContentEmpty message={'No data available'} />
  }

  // Render the actual chart
  return (
    <CardContent>
      <BarChart
        color={matured ? 'success' : 'primary'}
        data={barSeries}
        height={220}
        onBarClick={(item, event) => {
          event.event?.event.preventDefault()
          event.event?.event.stopPropagation()

          const year = parseInt(item.label)

          history.push(getCreditsFilteredByYearPath(lender, year, year, matured))
        }}
      />
    </CardContent>
  )
}
