import { useLocation } from 'react-router'
import { generatePath, matchPath, Redirect, Route } from 'react-router-dom'

import NotFound from '../not-found'
import {
  BOOKMARKS_FILTERED_COMPANIES_PATH,
  BOOKMARKS_LIST_COMPANIES_PATH,
  BOOKMARKS_PATH,
  BookmarksPathParams,
  OLD_CUSTOM_ASSET_LIST_PATH,
  OLD_CUSTOM_ASSET_QUERY_PATH,
  OLD_MY_LISTS_PATH,
} from '../utils'

/**
 * BookmarksRedirect forwards old bookmark urls to new paths.
 * TODO it should be OK to remove this after 2024-06-01, that gives 9 months of transition time.
 */
export default function BookmarksRedirect() {
  const location = useLocation()

  // Redirect /my-lists/list/:listId/* to /bookmarks/list/:listId/companies
  let match = matchPath<BookmarksPathParams>(location.pathname, {
    path: OLD_CUSTOM_ASSET_LIST_PATH,
  })
  if (match != null) {
    return (
      <Redirect
        to={{
          pathname: generatePath(BOOKMARKS_LIST_COMPANIES_PATH, {
            listId: match.params.listId,
          }),
        }}
      />
    )
  }

  // Redirect /my-lists/saved-filter/:listId/* to /bookmarks/filtered/:listId/companies
  match = matchPath<BookmarksPathParams>(location.pathname, {
    path: OLD_CUSTOM_ASSET_QUERY_PATH,
  })
  if (match != null) {
    return (
      <Redirect
        to={{
          pathname: generatePath(BOOKMARKS_FILTERED_COMPANIES_PATH, {
            listId: match.params.listId,
          }),
        }}
      />
    )
  }

  // Redirect /my-lists to /bookmarks
  match = matchPath<BookmarksPathParams>(location.pathname, {
    path: OLD_MY_LISTS_PATH,
    exact: true,
  })
  if (match != null) {
    return <Redirect to={{ pathname: BOOKMARKS_PATH }} />
  }

  // Fallback to the "Not found"-page, e.g. when accessing /my-lists/unknown
  return <Route component={NotFound} />
}
