import { TooltipHeader } from '@gain/components/tooltip'
import { LegalEntity } from '@gain/rpc/app-model'
import { formatCountry } from '@gain/utils/countries'
import { formatExternalId } from '@gain/utils/legal-entity'
import { formatNumber } from '@gain/utils/number'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

import { KeyValueList, KeyValueListItem } from '../../../common/key-value/key-value-list'
import YearChip from '../../../common/year-chip'
import FinancialValue from '../../financial/financial-value'

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 320,
}))

export interface TooltipLegalEntityTitleProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  legalEntity: LegalEntity
}

export default function TooltipLegalEntityTitle({
  legalEntity,
  ...props
}: TooltipLegalEntityTitleProps) {
  return (
    <div {...props}>
      <StyledContainer
        direction={'column'}
        spacing={2}>
        <TooltipHeader
          subtitle={'Legal entity'}
          title={legalEntity.name}
        />

        <KeyValueList
          spacing={0.5}
          dense>
          <KeyValueListItem
            label={'Region'}
            value={formatCountry(legalEntity.region, { defaultValue: '-' })}
          />

          <KeyValueListItem
            label={'Entity number'}
            value={formatExternalId(legalEntity.externalId, legalEntity.region)}
          />

          <KeyValueListItem
            label={'Annual reports'}
            value={legalEntity.annualReports.length}
          />

          {!!legalEntity.financialLatests?.revenue && (
            <KeyValueListItem
              label={'Revenue'}
              value={
                <FinancialValue
                  amount={legalEntity.financialLatests.revenue}
                  dataCurrency={legalEntity?.currency}
                  year={legalEntity.financialLatests?.revenueYear}
                />
              }
            />
          )}

          {!!legalEntity.financialLatests?.ebitda && (
            <KeyValueListItem
              label={'EBITDA'}
              value={
                <FinancialValue
                  amount={legalEntity.financialLatests.ebitda}
                  dataCurrency={legalEntity?.currency}
                  year={legalEntity.financialLatests?.ebitdaYear}
                />
              }
            />
          )}

          {!!legalEntity.financialLatests?.fte && (
            <KeyValueListItem
              label={'FTEs'}
              value={
                <>
                  {formatNumber(legalEntity.financialLatests?.fte)}
                  <YearChip
                    sx={{ ml: 1 }}
                    year={legalEntity.financialLatests?.fteYear}
                  />
                </>
              }
            />
          )}
        </KeyValueList>
      </StyledContainer>
    </div>
  )
}
