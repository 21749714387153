import Head from '@gain/modules/head'
import { Asset, ListedSecurityListItem } from '@gain/rpc/app-model'

import { useAssetListedSecurities } from '../route-asset-hooks'
import ListedAssetValuation from './listed-asset-valuation'
import { PrivateAssetValuation } from './private-asset-valuation'

function getListedSecurity(listedSecurities: ListedSecurityListItem[]) {
  if (listedSecurities.length === 0) {
    return null
  }

  // For the foreseeable future, there will only be a 1-on-1 mapping between an
  // asset and listed security. In theory the same asset could be listed on more
  // than 1 exchange, but we don't support that yet.
  const listedSecurity = listedSecurities[0]
  if (!listedSecurity.tradingCurrency) {
    return null
  }

  return listedSecurity
}

interface AssetValuationProps {
  asset: Asset
}

/**
 * The AssetValuation component renders the valuation page for a listed company
 * when the asset is linked to a listed security. We show a valuation based on
 * similar list companies when the company is not linked to a listed security.
 */
export default function AssetValuation({ asset }: AssetValuationProps) {
  const listedSecurities = useAssetListedSecurities(asset)
  const listedSecurity = getListedSecurity(listedSecurities.data.items)

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Valuation</title>
      </Head>
      {listedSecurity && <ListedAssetValuation listedSecurity={listedSecurity} />}
      {!listedSecurity && <PrivateAssetValuation asset={asset} />}
    </>
  )
}
