import { formatYear } from '@gain/utils/date'
import Chip, { ChipProps } from '@mui/material/Chip'

export interface YearChipProps extends Omit<ChipProps, 'label'> {
  year: number | null | undefined
}

export default function YearChip({ year, size = 'small', ...props }: YearChipProps) {
  if (!year) {
    return null
  }

  return (
    <Chip
      component={'span'}
      label={formatYear(year, 'short')}
      size={size}
      {...props}
    />
  )
}
