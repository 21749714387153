import { ListItemKey } from '@gain/rpc/list-model'
import { styled } from '@mui/material/styles'
import { useCallback, useEffect, useState } from 'react'
import { IMaskMixin } from 'react-imask'

import CustomOutlinedInput, {
  CustomOutlinedInputProps,
} from '../../../../common/form/custom-outlined-input'
import { FilterConfigRange } from '../filter-config/filter-config-model'

function isValidDate(value: string): boolean {
  const parts = value.split('/')
  if (parts.length !== 3) {
    return false
  }
  const month = parseInt(parts[1], 10)
  const year = parts[2]
  return month >= 1 && month <= 12 && year.length === 4
}

function isValidMonth(value: string): boolean {
  const parts = value.split('/')
  if (parts.length !== 2) {
    return false
  }
  const month = parseInt(parts[0], 10)
  const year = parts[1]
  return month >= 1 && month <= 12 && year.length === 4
}

function maskedToValue(value: string): string | null {
  return value ? value.split('/').reverse().join('-') : null
}

function valueToMasked(value: string | null): string {
  if (!value) {
    return value || ''
  }

  return value.split('-').reverse().join('/')
}

const StyledFilterInput = styled(CustomOutlinedInput)({
  flex: 1,
})

const MaskedField = IMaskMixin(({ inputRef, ...otherProps }) => (
  <StyledFilterInput
    {...(otherProps as CustomOutlinedInputProps)}
    inputRef={inputRef}
  />
))

export interface FilterRangeDateInputProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  type: 'month' | 'date'
  filter: FilterConfigRange<Item, FilterField>
  autoFocus?: boolean
  value: string | null
  onChange: (value: string | null) => void
}

export default function FilterRangeDateInput<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ type, value, onChange, autoFocus }: FilterRangeDateInputProps<Item, FilterField>) {
  const [tempValue, setTempValue] = useState(value)
  const handleChange = useCallback(
    (nextValue: string) => {
      // Prevent updates if the value does not change
      if (tempValue === maskedToValue(nextValue)) {
        return
      }

      if (
        (type === 'month' && isValidMonth(nextValue)) ||
        (type === 'date' && isValidDate(nextValue)) ||
        nextValue === ''
      ) {
        onChange(maskedToValue(nextValue))
      }
      setTempValue(nextValue)
    },
    [onChange, tempValue, type]
  )

  useEffect(() => {
    if (value === null) {
      setTempValue(null)
    }
  }, [value])

  return (
    <MaskedField
      autoFocus={autoFocus}
      mask={type === 'month' ? '[0]0/0000' : '[0]0/[0]0/0000'}
      onAccept={handleChange}
      placeholder={type === 'month' ? 'MM/YYYY' : 'DD/MM/YYYY'}
      type={'text'}
      unmask={false}
      value={valueToMasked(tempValue)}
    />
  )
}
