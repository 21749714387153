import { useAssetList } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { AssetListItem, DealListItem, Investor } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'

import InvestorDealsList from '../investor-deals-list/investor-deals-list'

interface RouteAddOnsProps {
  investor: Investor
}

export default function RouteAddOns({ investor }: RouteAddOnsProps) {
  const assets = useAssetList({
    filter: [listFilter<AssetListItem>('ownerIds', '=', investor.id)],
    sort: [{ field: 'ebitda', direction: 'desc' }],
    limit: 500,
  })
  const defaultFilters = [
    listFilter<DealListItem>(
      'buyerAssetIds',
      '=',
      assets.data.items.map((item) => item.id)
    ),
  ]

  return (
    <>
      <Head>
        <title>{investor.name} - Add-ons</title>
      </Head>
      <InvestorDealsList defaultFilters={defaultFilters} />
    </>
  )
}
