import { getSubsectorsForSector } from './sector-utils'

export interface Sector {
  id: number
  name: string
  title: string
}

const SECTORS_WITHOUT_SUBSECTORS: ReadonlyArray<Sector> = [
  {
    id: 1,
    name: 'services',
    title: 'Services',
  },
  {
    id: 2,
    name: 'tmt',
    title: 'TMT',
  },
  {
    id: 3,
    name: 'consumer',
    title: 'Consumer',
  },
  {
    id: 4,
    name: 'materialsEnergy',
    title: 'Materials & energy',
  },
  {
    id: 5,
    name: 'industrials',
    title: 'Industrials',
  },
  {
    id: 6,
    name: 'scienceHealth',
    title: 'Science & health',
  },
  {
    id: 7,
    name: 'financial',
    title: 'Financial services',
  },
  {
    id: 8,
    name: 'other',
    title: 'Other',
  },
]

export const SECTORS = Object.freeze(
  SECTORS_WITHOUT_SUBSECTORS.map((sector) => ({
    ...sector,
    subsectors: getSubsectorsForSector(sector.name),
  }))
)
