import { useLenderCreditCount } from '@gain/api/app/hooks'
import { Lender } from '@gain/rpc/app-model'

import Card, { CardHeader } from '../../../../common/card/card'
import CreditCountsChart from './credit-counts-chart'
import { getCreditsFilteredByYearPath } from './credit-counts-util'

interface CreditCountsCardProps {
  lender: Lender
  matured: boolean
}

export default function CreditCountsCard({ lender, matured }: CreditCountsCardProps) {
  const title = matured ? 'Maturing credits' : 'Credits issued'

  const swrCreditCounts = useLenderCreditCount({
    lenderId: lender.id,
    nrOfYears: 5,
    countMatured: matured,
  })

  const years = swrCreditCounts.data?.map((item) => item.year) ?? []

  // Only return a link when we have 1 or more years
  let href: string | undefined = undefined
  if (years.length > 0) {
    const minYear = Math.min(...years)
    const maxYear = Math.max(...years)
    href = getCreditsFilteredByYearPath(lender, minYear, maxYear, matured)
  }

  return (
    <Card
      href={href}
      fullHeight>
      <CardHeader title={title} />
      <CreditCountsChart
        data={swrCreditCounts.data || []}
        isLoading={swrCreditCounts.loading}
        lender={lender}
        matured={matured}
      />
    </Card>
  )
}
