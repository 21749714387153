import { useElementWidthEffect } from '@gain/utils/dom'
import * as echarts from 'echarts/core'
import { useCallback, useRef } from 'react'

import ChartTooltip from '../../chart/chart-tooltip'
import EChart from '../echart'
import { BarChartItem, getBarColor } from './bar-chart-utils'
import useBarChartChartHover from './use-bar-chart-chart-hover'
import useBarChartOption from './use-bar-chart-option'

interface BarChartProps {
  data: BarChartItem[]
  color: 'primary' | 'success'
  onBarClick?: (bar: BarChartItem, event: echarts.ECElementEvent) => void
  height: number
}

export default function BarChart({ data, color, onBarClick, height }: BarChartProps) {
  const eChartsInstanceRef = useRef<echarts.ECharts | undefined>()

  const chartOption = useBarChartOption(data, getBarColor(color))
  const [activeBar, handleMouseMove, handleMouseOut] = useBarChartChartHover()

  // Resize ECharts whenever the card size changes
  const containerRef = useRef<HTMLDivElement>(null)
  const resizeECharts = useCallback(() => {
    eChartsInstanceRef.current?.resize()
  }, [])
  useElementWidthEffect(containerRef, resizeECharts)

  // Keep the ECharts reference up-to-date each time it (re-)initializes
  const handleInit = useCallback((eChartsInstance: echarts.ECharts) => {
    eChartsInstanceRef.current = eChartsInstance
  }, [])

  // Force default cursor when bar is not clickable
  const handleForceDefaultCursor = useCallback(() => {
    if (onBarClick) {
      return
    }

    eChartsInstanceRef.current?.getZr().setCursorStyle('default')
  }, [onBarClick])

  const handleClick = useCallback(
    (event: echarts.ECElementEvent) => {
      onBarClick?.(data[event.dataIndex], event)
    },
    [data, onBarClick]
  )

  return (
    <div
      ref={containerRef}
      style={{ position: 'relative' }}>
      {activeBar && (
        <ChartTooltip
          placement={'top'}
          title={activeBar.value}
          disableInteractive
          open>
          <div
            style={{
              position: 'absolute',
              top: activeBar?.rect.y,
              left: activeBar?.rect.x,
              height: activeBar?.rect.height,
              width: activeBar?.rect.width,
            }}></div>
        </ChartTooltip>
      )}
      <EChart
        onClick={handleClick}
        onInit={handleInit}
        onMouseMove={handleMouseMove}
        onMouseMoveZr={handleForceDefaultCursor}
        onMouseOut={handleMouseOut}
        option={chartOption}
        style={{ height }}
        clearOnChange
      />
    </div>
  )
}
