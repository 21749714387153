import { BookmarkIcon, PlusIcon, UsersIcon, WatchlistIcon } from '@gain/components/icons'
import { BookmarkListItem, BookmarkListType } from '@gain/rpc/app-model'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import { useCallback, useMemo, useState } from 'react'

import { useBookmarkListsByObject } from '../../../api/bookmark-list-hooks'
import { MenuDivider } from '../../../common/menu-divider'
import BookmarksListCreateDialog, {
  BookmarksListCreateCloseReason,
} from '../bookmarks-list-create/bookmarks-list-create-dialog'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover, &:focus': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}))

const StyledMenu = styled(Menu)({
  maxWidth: 270,
})

export type BookmarkListMenuCloseReason =
  | 'cancel'
  | 'createBookmarkList'
  | 'addBookmark'
  | 'removeBookmark'

type BookmarksListMenuCloseHandler = (
  reason: BookmarkListMenuCloseReason,
  list?: BookmarkListItem
) => void

interface BookmarksListMenuProps extends Omit<MenuProps, 'onClose'> {
  lists: BookmarkListItem[]
  onClose: BookmarksListMenuCloseHandler
  excludeListIds?: number[]
  objectIds?: number[]
}

export default function BookmarkListMenu({
  lists,
  excludeListIds = [],
  objectIds = [],
  onClose,
  ...props
}: BookmarksListMenuProps) {
  const [createDialogOpen, setCreateDialogOpen] = useState(false)

  // Figure out which lists already contain the object. For now we only support one object
  // because it's ambiguous what to do if multiple objects are selected:
  //
  // 1. A list has none of the bookmarks; show as "unfilled"
  // 2. A list has all the bookmarks; show as "filled"
  // 3. A list has some of the bookmarks; could be considered either "filled" or "unfilled"
  //
  // For now this setup mimics the behavior of the old bookmarking system.
  const alreadyBookmarkedLists = useBookmarkListsByObject(
    BookmarkListType.LegacyCustomAssetList,
    objectIds.length === 1 ? objectIds[0] : undefined
  )
  const selectedListIds = alreadyBookmarkedLists.map(({ id }) => id)

  const filteredLists = useMemo(() => {
    return lists
      .filter((list) => !excludeListIds.includes(list.id))
      .sort((a, b) => a.title.localeCompare(b.title))
  }, [lists, excludeListIds])

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true)
  }

  const handleCloseCreateDialog = useCallback(
    (reason: BookmarksListCreateCloseReason, list?: BookmarkListItem) => {
      setCreateDialogOpen(false)
      onClose(reason, list)
    },
    [onClose]
  )

  return (
    <>
      <BookmarksListCreateDialog
        onClose={handleCloseCreateDialog}
        open={createDialogOpen}
        disableRedirect
      />

      <StyledMenu
        anchorEl={props.anchorEl}
        onClick={(event) => event.stopPropagation()}
        onClose={() => onClose('cancel')}
        {...props}>
        <StyledMenuItem
          onClick={handleOpenCreateDialog}
          dense>
          <ListItemIcon>
            <PlusIcon color={'primary'} />
          </ListItemIcon>
          <ListItemText>New list</ListItemText>
        </StyledMenuItem>

        {filteredLists.length > 0 && <MenuDivider />}

        {filteredLists.map((list) => (
          <MenuItem
            key={list.id}
            onClick={(event) => {
              event.stopPropagation()
              onClose(selectedListIds.includes(list.id) ? 'removeBookmark' : 'addBookmark', list)
            }}
            dense>
            <ListItemIcon>
              {selectedListIds.includes(list.id) ? (
                <WatchlistIcon color={'primary'} />
              ) : (
                <BookmarkIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={list.title} />
            {list.userCount > 1 && (
              <UsersIcon
                color={'textSecondary'}
                sx={{ ml: 2 }}
              />
            )}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}
