import Typography, { TypographyProps } from '@gain/components/typography'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Stack from '@mui/material/Stack'
import clsx from 'clsx'
import { forwardRef } from 'react'

import { AvatarProps } from '../../../../common/avatar'
import Logo, { LogoProps } from '../../../../common/logo'

export const contentLinkClasses = generateUtilityClasses('ContentLink', ['root', 'title'])

interface UltimateOwnershipLinkProps {
  className?: string
  avatarProps?: AvatarProps
  region?: LogoProps['region']
  label: string
  labelTypographyProps?: TypographyProps
}

const UltimateOwnership = forwardRef<HTMLElement, UltimateOwnershipLinkProps>(function ContentLink(
  { className, avatarProps, region, label, labelTypographyProps, ...props },
  ref
) {
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      minWidth={0}
      spacing={1}>
      {avatarProps && (
        <Logo
          avatarProps={{
            imagePadding: 0.5,
            ...avatarProps,
          }}
          defaultIcon={avatarProps.children}
          region={region}
          size={24}
          variant={'rounded'}
        />
      )}
      <Stack
        direction={'column'}
        justifyContent={'center'}
        minWidth={0}>
        <Stack
          alignItems={'center'}
          direction={'row'}
          minWidth={0}
          spacing={0}>
          <Typography
            color={'text.primary'}
            variant={'body2'}
            {...labelTypographyProps}
            className={clsx(contentLinkClasses.title, labelTypographyProps?.className)}
            noWrap>
            {label}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
})

export default UltimateOwnership
