import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { ReactElement } from 'react'

import Empty from '../../empty'

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 8,
  overflow: 'hidden',
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)',
}))

const StyledEmptyTableHeader = styled('div')(({ theme }) => ({
  height: 48,
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey['100'],
}))

export interface TableEmptyProps {
  title: string
  message: string
  actions?: ReactElement
}

export default function TableEmpty(props: TableEmptyProps) {
  return (
    <StyledPaper>
      <StyledEmptyTableHeader />
      <Box
        alignItems={'center'}
        display={'flex'}
        flex={1}
        flexDirection={'column'}
        justifyContent={'center'}
        rowGap={0}>
        <Empty {...props} />
      </Box>
    </StyledPaper>
  )
}
