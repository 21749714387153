import Typography from '@gain/components/typography'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import matchesSearchQuery from '../../common/search/matches-search-query'
import ColumnPickerColumnList from './column-picker-column-list'
import { Column, NestedColumnGroup, TopLevelColumnGroup } from './column-picker-model'
import { ColumnPickerAPI } from './use-column-picker'

interface SearchResultGroup {
  label: string
  columns: Column[]
}

/**
 * useSearchResults returns a list of SearchResultGroup items containing the
 * columns that match the given query in one of the following:
 *  - column label
 *  - column search alias
 *  - any column ancestor group's label
 */
function useSearchResults(groups: TopLevelColumnGroup[], searchQuery: string): SearchResultGroup[] {
  const results = new Array<SearchResultGroup>()

  // Find matching columns
  const findColumns = (columns: Column[], parentGroupLabels: string[]): Column[] => {
    return columns.filter((column) => {
      return matchesSearchQuery(
        column.label,
        searchQuery,
        parentGroupLabels.concat(column.searchAliases)
      )
    })
  }

  // Recursively find matching columns in the given group and adds them to the
  // result.
  const searchGroup = (group: TopLevelColumnGroup | NestedColumnGroup, labelPrefix: string[]) => {
    const label = labelPrefix.concat(group.name)

    // Add matching columns to the result
    if ('columns' in group) {
      const matches = findColumns(group.columns, label)
      if (matches.length > 0) {
        results.push({
          label: label.join(' - '),
          columns: matches,
        })
      }
    }

    // Recursively search groups for matching columns
    if ('groups' in group) {
      for (const subGroup of group.groups) {
        searchGroup(subGroup, label)
      }
    }
  }

  for (const group of groups) {
    searchGroup(group, [])
  }

  return results
}

export interface ColumnPickerSearchResultsProps {
  searchQuery: string
  api: ColumnPickerAPI
  activeFilterColumns: string[]
}

export default function ColumnPickerSearchResults({
  searchQuery,
  api,
  activeFilterColumns,
}: ColumnPickerSearchResultsProps) {
  const results = useSearchResults(api.groups, searchQuery)

  if (results.length === 0) {
    return <Typography color={'text.secondary'}>No matching columns found</Typography>
  }

  return (
    <Stack divider={<Divider sx={{ my: 1 }} />}>
      {results.map((resultGroup, index) => (
        <Stack key={index}>
          <Typography
            color={'text.secondary'}
            sx={{ ml: 1 }}
            variant={'overline'}>
            {resultGroup.label}
          </Typography>
          <ColumnPickerColumnList
            activeFilterColumns={activeFilterColumns}
            api={api}
            columns={resultGroup.columns}
          />
        </Stack>
      ))}
    </Stack>
  )
}
