import { BookmarkIcon, PlusIcon, SlidersIcon } from '@gain/components/icons'
import { BookmarkListItem } from '@gain/rpc/app-model'
import Badge from '@mui/material/Badge'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { generatePath, Link, useHistory } from 'react-router-dom'

import { isLegacyCustomAssetList } from '../../../api/bookmark-list-hooks'
import {
  BOOKMARKS_CREATE_PATH,
  BOOKMARKS_FILTERED_SUMMARY_PATH,
  BOOKMARKS_LIST_SUMMARY_PATH,
} from '../../../routes/utils'

const StyledMenu = styled(Menu)({
  maxWidth: 270,
})

const StyledNewListMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(1),
}))

interface BookmarksMenuCollapsedProps {
  items: BookmarkListItem[]
  anchorEl: Element | null
  onClose: () => void
}

export default function BookmarksMenuCollapsed({
  items,
  anchorEl,
  onClose,
}: BookmarksMenuCollapsedProps) {
  const history = useHistory()
  const generateListPath = (item: BookmarkListItem) => {
    const path = isLegacyCustomAssetList(item)
      ? BOOKMARKS_LIST_SUMMARY_PATH
      : BOOKMARKS_FILTERED_SUMMARY_PATH
    return generatePath(path, { listId: item.id })
  }

  return (
    <StyledMenu
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      onClose={onClose}
      open={Boolean(anchorEl)}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}>
      <StyledNewListMenuItem
        onClick={() => {
          history.push(BOOKMARKS_CREATE_PATH)
          onClose()
        }}>
        <ListItemIcon>
          <PlusIcon color={'primary'} />
        </ListItemIcon>
        <ListItemText primary={'New list'} />
      </StyledNewListMenuItem>

      <StyledDivider />

      {items.map((item) => (
        <MenuItem
          key={item.id}
          component={Link}
          to={generateListPath(item)}>
          <ListItemIcon>
            {isLegacyCustomAssetList(item) ? <BookmarkIcon /> : <SlidersIcon />}
          </ListItemIcon>

          <ListItemText primary={item.title} />

          {item.updatedCount > 0 && (
            <StyledBadge
              badgeContent={item.updatedCount}
              color={'error'}
              max={99}
            />
          )}
        </MenuItem>
      ))}
    </StyledMenu>
  )
}
