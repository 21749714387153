import { ArrowLeftIcon } from '@gain/components/icons'
import { useNavigateBackOrHomeCallback } from '@gain/utils/router'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const StyledBackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

export default function SearchBackButton() {
  const handleClick = useNavigateBackOrHomeCallback()

  return (
    <StyledBackButton
      onClick={handleClick}
      startIcon={<ArrowLeftIcon color={'inherit'} />}
      sx={{ ml: -1 }}
      variant={'text'}>
      Back
    </StyledBackButton>
  )
}
