import { ChevronDownIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { RowType } from '@gain/utils/table'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { TableSortLabelProps } from '@mui/material/TableSortLabel'
import { typographyClasses } from '@mui/material/Typography'
import clsx from 'clsx'

import InfoButton, { infoButtonClasses } from '../info-button'
import { tableClasses } from './table'
import { ColumnConfig } from './table-model'

const classes = generateUtilityClasses('TableHeader', ['sortIcon'])

interface StyledThProps {
  width?: number | string
  textAlign?: 'left' | 'right' | 'center' | 'justify'
  paddingLeft?: number
  paddingRight?: number
  disablePaddingLeft?: boolean
  sortActive?: boolean
  sortDirection?: TableSortLabelProps['direction']
  sortable?: boolean
  reverseItems?: boolean
}

const STYLED_TH_PROPS = [
  'width',
  'disablePaddingLeft',
  'textAlign',
  'paddingLeft',
  'paddingRight',
  'sortActive',
  'sortDirection',
  'sortable',
  'reverseItems',
]

const StyledTh = styled('th', {
  shouldForwardProp: (prop) => !STYLED_TH_PROPS.includes(prop.toString()),
})<StyledThProps>(
  ({
    theme,
    width,
    paddingRight,
    paddingLeft,
    disablePaddingLeft,
    sortActive,
    sortDirection,
    sortable,
    reverseItems,
  }) => ({
    height: 38,
    boxSizing: 'border-box',
    width,
    paddingLeft: theme.spacing(paddingLeft || 1),
    paddingRight: theme.spacing(paddingRight || 1),
    backgroundColor: theme.palette.background.paper,
    ...theme.typography.body2,
    position: 'relative',
    cursor: sortable ? 'pointer' : 'default',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-of-type': {
      paddingRight: theme.spacing(3),
    },
    '&:first-of-type': {
      paddingLeft: theme.spacing(3),
      ...(disablePaddingLeft && {
        paddingLeft: 0,
      }),
    },

    [theme.breakpoints.only('xs')]: {
      top: 0,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },

    [`& .${typographyClasses.root}`]: {
      order: reverseItems ? 2 : 1,
      ...(sortActive && {
        color: theme.palette.text.primary,
        fontWeight: 600,
      }),
    },

    [`& .${infoButtonClasses.root}`]: {
      order: reverseItems ? 3 : 1,
    },

    [`& .${classes.sortIcon}`]: {
      opacity: 0,
      color: theme.palette.text.secondary,
      order: reverseItems ? 0 : 2,
      transition: theme.transitions.create(['opacity', 'color', 'transform'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.short,
      }),

      ...(sortActive && {
        opacity: 1,
        color: theme.palette.text.primary,
      }),

      ...(sortDirection === 'desc' && {
        transform: 'rotate(180deg)',
      }),
    },

    [`&:hover .${classes.sortIcon}`]: {
      opacity: 1,
    },

    [`&.${tableClasses.stickyCell}`]: {
      position: 'sticky',
      left: 0,
      zIndex: 3,
    },
  })
)

interface TableHeaderProps<Row extends RowType> {
  className?: string
  column: ColumnConfig<Row>
  disablePaddingLeft?: boolean
  sortActive?: boolean
  sortDirection?: TableSortLabelProps['direction']
  onSort?: () => void
}

export default function TableHeader<Row extends RowType>({
  className,
  column,
  disablePaddingLeft,
  sortActive,
  sortDirection,
  onSort,
}: TableHeaderProps<Row>) {
  const sortable = !!column.compareAsc
  const align = column.headerAlign || column.align

  return (
    <StyledTh
      className={clsx(className, {
        [tableClasses.stickyCell]: column.sticky,
      })}
      colSpan={column.headerSpan}
      disablePaddingLeft={disablePaddingLeft}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()

        if (sortable) {
          onSort?.()
        }
      }}
      paddingLeft={column.paddingLeft}
      paddingRight={column.paddingRight}
      reverseItems={align === 'right'}
      sortable={sortable}
      sortActive={sortActive}
      sortDirection={sortDirection}
      textAlign={column.headerAlign || column.align}
      width={column.headerSpan && column.headerSpan > 1 ? column.headerWidth : column.width}>
      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={0.5}
        justifyContent={column.headerAlign || column.align}>
        <Typography
          color={'text.secondary'}
          variant={'inherit'}
          noWrap>
          {typeof column.renderHeader === 'function' ? column.renderHeader() : column.headerName}
        </Typography>

        {column.headerExplainer && (
          <InfoButton
            dialogMessage={column.headerExplainer}
            dialogTitle={column.headerName || ''}
          />
        )}

        {sortable && (
          <ChevronDownIcon
            className={classes.sortIcon}
            fontSize={'inherit'}
          />
        )}
      </Stack>
    </StyledTh>
  )
}
