import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import { listItemButtonClasses } from '@mui/material/ListItemButton'
import { styled } from '@mui/material/styles'
import { ReactNode, useCallback } from 'react'

import ConditionalWrapper from '../conditional-wrapper'
import MenuExpandIcon from '../menu-expand-icon'
import DropdownMenuItem from './dropdown-menu-item'
import {
  DropdownMenuGroupType,
  DropdownMenuOption,
  isDropdownMenuGroup,
} from './dropdown-menu-model'

const StyledList = styled(List, {
  shouldForwardProp: (prop) => !['isChildGroup', 'isLast'].includes(prop as string),
})<{
  isChildGroup: boolean
  isLast: boolean
}>(({ theme, isChildGroup, isLast }) => ({
  backgroundColor: theme.palette.grey['50'],
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: 0,
  marginBlock: theme.spacing(1),
  ...(isChildGroup && {
    backgroundColor: theme.palette.grey['100'],
    paddingLeft: theme.spacing(1),
  }),
  ...(isLast && {
    marginBlock: 0,
    ...(isChildGroup && { borderBottom: 0 }),
  }),
  [`& .${listItemButtonClasses.root}:hover`]: {
    backgroundColor: theme.palette.grey['200'],
  },
}))

/**
 * DropdownMenuItem is a styled ListItemButton that displays a dropdown menu
 * item with a label and optional icon.
 *
 * Because we want the padding to be part of the collapse/expand animation when
 * opening a group and not suddenly show/hide padding we determine this padding
 * dynamically.
 */
const StyledDropdownMenuItem = styled(DropdownMenuItem, {
  shouldForwardProp: (prop) => prop !== 'isLast',
})<{ isLast: boolean }>(({ theme, isLast }) => ({
  ...(isLast && {
    paddingBottom: theme.spacing(1),
  }),
}))

const StyledMenuExpandIcon = styled(MenuExpandIcon)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.grey[600],
}))

export interface DropdownMenuGroupProps<T extends DropdownMenuOption> {
  group: DropdownMenuGroupType<T>
  onSelect: (item: T) => void
  onOpen: (groupId: string) => void
  onClose: () => void
  alwaysOpen?: boolean
  isLast: boolean
  activeGroupId: string | null
  isChildGroup?: boolean
  direction?: 'down' | 'up'

  // The collapse transition does not work well with popover:
  // https://github.com/mui/material-ui/issues/11337#issuecomment-510578264
  //
  // During the expand animation the popover grows in height and the popover does
  // not automatically reposition. This is only a problem when the popover is
  // shown above the anchor.
  //
  // We tried to reposition dynamically in JS using a resize observer but the
  // animation becomes bouncy and jittery. So instead we disable the animation
  // when the popover is shown above the anchor.
  disableAnimation?: boolean
}

/**
 * A dropdown menu group is a collapsible set of options, capable of containing
 * nested groups with additional options, up to a maximum depth of two levels.
 *
 * By default, dropdown menu groups are collapsed, and only one group can be
 * manually expanded at a time. However, when a user performs a search, this
 * behavior changes: all groups with matching options are automatically expanded.
 */
export default function DropdownMenuGroup<T extends DropdownMenuOption>(
  props: DropdownMenuGroupProps<T>
) {
  const {
    group,
    onSelect,
    alwaysOpen = false,
    onOpen,
    onClose,
    isLast = false,
    activeGroupId,
    isChildGroup = false,
    disableAnimation = false,
  } = props

  const handleItemClick = useCallback(
    (item: T) => () => {
      onSelect(item)
    },
    [onSelect]
  )

  // If the current group or one of its child groups is active we expand the group.
  // If the user is searching an option the "alwaysOpen" overrides this behaviour
  // and always opens the group regardless.
  const isChildGroupActive = group.children.some((item) => item.id === activeGroupId)
  const isOpen = alwaysOpen || group.id === activeGroupId || isChildGroupActive

  const handleClick = useCallback(() => {
    if (isOpen) {
      onClose()
    } else {
      onOpen(group.id)
    }
  }, [group.id, isOpen, onClose, onOpen])

  // When closing a child group we keep the parent open. Since there can only be
  // one active group at a time we simply open the parent group.
  const handleCloseChildGroup = useCallback(() => {
    onOpen(group.id)
  }, [group.id, onOpen])

  return (
    <>
      <StyledDropdownMenuItem
        icon={group.icon}
        isLast={isLast}
        label={
          <>
            {group.label}
            <StyledMenuExpandIcon open={isOpen} />
          </>
        }
        onClick={handleClick}
      />

      <ConditionalWrapper
        condition={!disableAnimation}
        wrapper={(children: ReactNode) => <Collapse in={isOpen}>{children}</Collapse>}
        wrapperWhenFalse={(children: ReactNode) => <span>{isOpen && children}</span>}>
        <StyledList
          isChildGroup={isChildGroup}
          isLast={isLast}
          dense>
          {group.children.map((item, index) => {
            const isLastChild = index === group.children.length - 1
            if (isDropdownMenuGroup(item)) {
              return (
                <DropdownMenuGroup
                  {...props}
                  key={item.id}
                  group={item}
                  isLast={isLastChild}
                  onClose={handleCloseChildGroup}
                  isChildGroup
                />
              )
            }

            return (
              <StyledDropdownMenuItem
                key={item.id}
                isLast={isLastChild}
                label={item.label}
                onClick={handleItemClick(item)}
              />
            )
          })}
        </StyledList>
      </ConditionalWrapper>
    </>
  )
}
