import { AssetListItem, Investor } from '@gain/rpc/app-model'
import { ListFilter } from '@gain/rpc/list-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { listFilter, listFilters } from '@gain/rpc/utils'

// Only show curated companies in portfolio on the app
// Hide automated companies linked to the investor through dealroom deal import
export function createInvestorPortfolioFilters(
  investor: Investor,
  assetIds?: number[],
  ...filters: Array<ListFilter<AssetListItem>>
) {
  return listFilters<AssetListItem>(
    listFilter('ownerIds', '=', investor.id),
    assetIds && assetIds.length !== 0 && listFilter('id', '=', assetIds),

    // Only show curated companies in portfolio on the app,
    // hide automated companies linked to the investor through
    // dealroom deal
    investor.onlyIncludeCurated && listFilter('profileType', '!=', AssetProfileType.Automated),
    ...filters
  )
}

export const DRY_POWDER_EXPLAINER =
  'Estimate of committed capital that has not yet been called by the fund. The drypowder estimate is based on the average time to final closing of funds in a particular strategy. Drypowder is assumed to decrease linearly over time.'
