import { BriefcaseIcon, CompanyIcon, LeafIcon, ShuffleIcon } from '@gain/components/icons'
import { SECTOR_AND_SUBSECTOR_SEARCH_ALIASES } from '@gain/utils/sector'

import { investorListItemTableColumns } from '../investor/investor-list-item-table/investor-list-item-table-columns'
import { investorStrategyTableColumns } from '../investor-strategy/investor-strategy-table/investor-strategy-table-columns'
import { TopLevelColumnGroup } from './column-picker-model'
import { fromConfig, getRegionAndCountrySearchAliases } from './column-picker-util'

export const columnPickerConfigInvestorStrategy = new Array<TopLevelColumnGroup>(
  {
    name: 'Key facts',
    Icon: CompanyIcon,
    columns: [
      fromConfig(investorStrategyTableColumns, 'strategyId', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(investorStrategyTableColumns, 'investorId', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(investorStrategyTableColumns, 'assetsTotal', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(investorStrategyTableColumns, 'classifications', {
        visibleByDefault: true,
      }),
      fromConfig(investorStrategyTableColumns, 'fundsRaisedLastFiveYearsEur', {
        visibleByDefault: true,
      }),
      fromConfig(investorListItemTableColumns, 'dryPowderMinEur', { visibleByDefault: true }),
      fromConfig(investorStrategyTableColumns, 'latestFundName'),
      fromConfig(investorStrategyTableColumns, 'latestFundSizeEur', { visibleByDefault: true }),
      fromConfig(investorStrategyTableColumns, 'latestFundVintageDateYear'),
    ],
  },
  {
    name: 'Portfolio',
    Icon: BriefcaseIcon,
    columns: [
      fromConfig(investorStrategyTableColumns, 'assetEbitdasEur', {
        visibleByDefault: true,
      }),
      fromConfig(investorStrategyTableColumns, 'assetEbitdaMedianEur', {
        visibleByDefault: true,
      }),
      fromConfig(investorStrategyTableColumns, 'assetRegions', {
        visibleByDefault: true,
        searchAliases: getRegionAndCountrySearchAliases(),
      }),
      fromConfig(investorStrategyTableColumns, 'assetSectors', {
        visibleByDefault: true,
        searchAliases: SECTOR_AND_SUBSECTOR_SEARCH_ALIASES,
      }),
    ],
  },
  {
    name: 'Deals',
    Icon: ShuffleIcon,
    columns: [
      fromConfig(investorStrategyTableColumns, 'dealsTotalLastFiveYears', {
        visibleByDefault: true,
      }),
      fromConfig(investorStrategyTableColumns, 'dealsEntriesTotalLastFiveYears'),
      fromConfig(investorStrategyTableColumns, 'dealsExitTotalLastFiveYears'),
      fromConfig(investorStrategyTableColumns, 'dealsAddOnsTotalLastFiveYears'),
    ],
  },
  {
    name: 'ESG',
    Icon: LeafIcon,
    columns: [
      fromConfig(investorStrategyTableColumns, 'assetsMedianMarketSegmentRatingOverall'), // Overall ESG risk
      fromConfig(investorStrategyTableColumns, 'assetsMedianMarketSegmentRatingEnvironmental'), // Environmental risk
      fromConfig(investorStrategyTableColumns, 'assetsMedianMarketSegmentRatingSocial'), // Social risk
    ],
  }
)
