import { useRpcClient } from '@gain/api/swr'
import Snackbar from '@gain/components/snackbar'
import { isJsonRpcError, RpcErrorCode } from '@gain/rpc/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import DialogHeader from '../../common/dialog-header'
import { TextInput } from '../../common/form'

const StyledForm = styled('form')({
  display: 'contents',
})

interface FormData {
  firstName: string
  lastName: string
  email: string
}

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name field is required'),
  lastName: Yup.string().required('Last name field is required'),
  email: Yup.string().email('Email address is invalid').required('Email address field is required'),
})

interface TeamMemberInviteDialogProps
  extends Pick<DialogProps, 'open' | 'onClose' | 'keepMounted'> {
  onUserInvited?: () => void
}

export default function TeamMemberInviteDialog({
  onClose,
  onUserInvited,
  ...dialogProps
}: TeamMemberInviteDialogProps) {
  const fetcher = useRpcClient()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const form = useForm<FormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    resolver: yupResolver(FormSchema),
  })

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    setIsSaving(true)
    try {
      await fetcher({
        method: 'admin.createUser',
        params: {
          partial: formData,
        },
      })

      onUserInvited?.()
      const snackbarKey = `create-user-${formData.email}`
      enqueueSnackbar(undefined, {
        key: snackbarKey,
        content: () => (
          <Snackbar
            id={snackbarKey}
            message={`Email sent to “${formData.email}”`}
          />
        ),
        preventDuplicate: true,
      })
      form.reset()
      onClose?.({}, 'backdropClick')
    } catch (e) {
      if (isJsonRpcError(e)) {
        if (e.code === RpcErrorCode.ValidationFailed) {
          for (const item of e.data) {
            form.setError(item.path, {
              message: item.message,
            })
          }
        } else {
          form.setError('root', {
            message: e.message,
          })
        }
      } else {
        form.setError('root', {
          message: 'An unexpected error occurred, please try again.',
        })
      }
    }
    setIsSaving(false)
  }

  return (
    <Dialog
      {...dialogProps}
      maxWidth={'sm'}
      onClose={onClose}
      fullWidth>
      <FormProvider {...form}>
        <StyledForm
          onSubmit={form.handleSubmit(onSubmit)}
          noValidate>
          <DialogHeader
            onCloseClick={(e) => onClose?.(e, 'backdropClick')}
            title={'Invite new user'}
          />

          <DialogContent>
            <Grid
              direction={'column'}
              spacing={2}
              container>
              <Grid item>
                <Alert severity={'info'}>
                  The user will receive an email with further instructions.
                </Alert>
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
              <Grid item>
                <TextInput
                  label={'First name'}
                  name={'firstName'}
                  placeholder={'First name'}
                  variant={'outlined'}
                />
              </Grid>
              <Grid item>
                <TextInput
                  label={'Last name'}
                  name={'lastName'}
                  placeholder={'Last name'}
                  variant={'outlined'}
                />
              </Grid>
              <Grid item>
                <TextInput
                  label={'Email address'}
                  name={'email'}
                  placeholder={'Email address'}
                  type={'email'}
                  variant={'outlined'}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <LoadingButton
              color={'primary'}
              loading={isSaving}
              type={'submit'}
              variant={'contained'}
              fullWidth>
              Invite
            </LoadingButton>
          </DialogActions>
        </StyledForm>
      </FormProvider>
    </Dialog>
  )
}
