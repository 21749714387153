import { MenuIcon } from '@gain/components/icons'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import GainProLogo from '../../common/gain-pro-logo'
import { useMenuContext } from '../../features/menu'
import { SearchInput } from '../../features/search/search-input'
import { HOME_PATH } from '../../routes/utils'
import NavBar from '../nav-bar'
import { ProfileButton } from './profile-button'
import TopBar from './top-bar'

export const TOP_BAR_ID = 'top-bar'

const StyledRoot = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.appBar,
}))

const StyledLink = styled(Link)({
  lineHeight: 0,
})

const StyledSearchContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  [theme.breakpoints.only('xs')]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

export default function MainNavigation() {
  const [isOpen, setIsOpen, isMenuEnabled] = useMenuContext()

  return (
    <StyledRoot>
      <TopBar>
        <NavBar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isMenuEnabled && (
              <IconButton
                edge={'start'}
                onClick={() => setIsOpen(!isOpen)}
                size={'small'}
                sx={{ mr: 2 }}>
                <MenuIcon />
              </IconButton>
            )}
            <StyledLink to={HOME_PATH}>
              <GainProLogo />
            </StyledLink>
          </Box>

          <StyledSearchContainer>
            <SearchInput />
          </StyledSearchContainer>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flex: '0 1 auto',
              justifyContent: 'flex-end',
            }}>
            <ProfileButton />
          </Box>
        </NavBar>
      </TopBar>
      <div id={TOP_BAR_ID} />
    </StyledRoot>
  )
}
