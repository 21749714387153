import { Asset } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { formatMonthYear } from '@gain/utils/date'

import { KeyValueList, KeyValueListItem } from '../../../../common/key-value/key-value-list'
import AssetOwnershipTypeExplainer from '../../../../features/asset/asset-ownership'
import AssetOwnershipUnverifiedChip from '../../../../features/asset/asset-ownership/asset-ownership-unverified-chip'
import { getSubsidiaryParentId } from '../../asset-header/asset-header-subtitle'
import SubsidiaryParentAlert from './subsidiary-parent-alert'

interface AssetOwnershipDefinitionProps {
  asset: Asset
}

export default function AssetOwnershipDefinition({ asset }: AssetOwnershipDefinitionProps) {
  const parentId = getSubsidiaryParentId(asset.subsidiaryPath)
  const isVerified = asset.generalInfo?.ownershipIsVerified
  const ownershipType = asset.generalInfo?.ownership

  return (
    <KeyValueList>
      {parentId !== null && <SubsidiaryParentAlert parentId={parentId} />}

      {ownershipType !== AssetOwnershipType.Subsidiary && (
        <KeyValueListItem
          label={
            <>
              Type
              {!isVerified && ownershipType && (
                <AssetOwnershipUnverifiedChip ownershipType={ownershipType} />
              )}
            </>
          }
          value={
            <AssetOwnershipTypeExplainer
              disableExplainer={!isVerified}
              ownershipType={ownershipType}
            />
          }
        />
      )}

      <KeyValueListItem
        label={'Last platform deal'}
        value={formatMonthYear(asset.generalInfo?.lastDealMonth, asset.generalInfo?.lastDealYear)}
      />
    </KeyValueList>
  )
}
