import Head from '@gain/modules/head'
import { Investor, InvestorStrategy, RelatedDealAdvisor } from '@gain/rpc/app-model'
import isEmpty from 'lodash/isEmpty'
import { stringify } from 'query-string'
import { DelimitedNumericArrayParam } from 'serialize-query-params'
import { encodeQueryParams } from 'use-query-params'

import Card, { CardContentEmpty, CardHeader } from '../../../common/card/card'
import { calculateVisibleRows } from '../../../common/visible-rows'
import RelatedDealAdvisorsTable from '../../../features/related-deal-advisors-table'
import { generateInvestorStrategyAdvisorPath } from '../../investor-strategy-advisor-deals/route-investor-strategy-advisor-deals-path'

interface RouteAdvisorsProps {
  investor: Investor
  strategy: InvestorStrategy
  dealAdvisors: RelatedDealAdvisor[]
  availableHeight: number
}

export default function RouteAdvisors({
  investor,
  strategy,
  dealAdvisors,
  availableHeight,
}: RouteAdvisorsProps) {
  const nrOfVisibleRows = calculateVisibleRows(availableHeight)
  const generateInvestorAdvisorDealsPath = (advisor: RelatedDealAdvisor) => {
    const path = generateInvestorStrategyAdvisorPath({
      investorId: advisor.advisorId,
      investorName: investor.name,
      strategyId: strategy.id,
      strategyName: strategy.name,
      advisorId: advisor.advisorId,
      advisorName: advisor.advisorName,
    })

    // Currently we do not have "buyerAdvisorIds" or "sellerAdvisorIds" in the deal model,
    // hence we pass the deal ids explicitly.
    const dealIds = encodeQueryParams(
      { dealIds: DelimitedNumericArrayParam },
      { dealIds: advisor.dealIds }
    )
    return `${path}?${stringify(dealIds)}`
  }

  return (
    <>
      <Head>
        <title>
          {investor.name} - {strategy.name} - Advisors
        </title>
      </Head>

      <Card sx={{ pb: 1, width: '100%', minHeight: 256 }}>
        <CardHeader title={'Deal advisors'} />
        {!isEmpty(dealAdvisors) && (
          <RelatedDealAdvisorsTable
            dealsPath={generateInvestorAdvisorDealsPath}
            items={dealAdvisors ?? []}
            nrOfVisibleRows={nrOfVisibleRows}
          />
        )}

        {isEmpty(dealAdvisors) && (
          <CardContentEmpty message={'There are no advisors associated with this investor'} />
        )}
      </Card>
    </>
  )
}
