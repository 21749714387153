import { useAssetListItem } from '@gain/api/app/hooks'
import Typography from '@gain/components/typography'
import { Asset } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { useMemo } from 'react'

import ParentLink from '../../../features/parent-link/parent-link'
import NotMaintainedMessage from './not-maintained-message'

export function getSubsidiaryParentId(subsidiaryPath?: number[] | null) {
  if (!subsidiaryPath || subsidiaryPath.length < 2) {
    return null
  }

  return subsidiaryPath[subsidiaryPath.length - 2]
}

const noLongerMaintainedOwnerships = [AssetOwnershipType.Subsidiary, AssetOwnershipType.Bankrupt]

export function isNoLongerMaintained(asset?: Asset) {
  if (!asset || !asset.generalInfo) {
    return false
  }

  return noLongerMaintainedOwnerships.includes(asset.generalInfo.ownership)
}

export function useHasSubtitle(asset?: Asset) {
  return useMemo(() => {
    if (!asset) {
      return false
    } else if (getSubsidiaryParentId(asset?.subsidiaryPath)) {
      return true
    } else if (isNoLongerMaintained(asset)) {
      return true
    } else if (asset.description?.short) {
      return true
    }

    return false
  }, [asset])
}

interface AssetHeaderSubtitleProps {
  asset: Asset
}

export default function AssetHeaderSubtitle({ asset }: AssetHeaderSubtitleProps) {
  const parentId = getSubsidiaryParentId(asset.subsidiaryPath)
  const swrParentAsset = useAssetListItem(parentId)

  // Delay rendering until parent asset is loaded
  if (swrParentAsset.loading) {
    return null
  }

  // Show this company is bankrupt
  if (asset.generalInfo?.ownership === AssetOwnershipType.Bankrupt) {
    return (
      <Typography
        color={'error'}
        variant={'body2'}
        noWrap>
        This company is bankrupt
      </Typography>
    )
  }

  // Show this message when the profile is a subsidiary and not automated
  if (parentId && swrParentAsset.data) {
    return (
      <NotMaintainedMessage
        asset={asset}
        parentAsset={swrParentAsset.data}>
        This is a subsidiary of <ParentLink parent={swrParentAsset.data} />
      </NotMaintainedMessage>
    )
  }

  // Fallback to the short description
  if (asset.description?.short) {
    return (
      <Typography
        color={'text.secondary'}
        variant={'body2'}
        noWrap>
        {asset.description.short}
      </Typography>
    )
  }

  return null
}
