import { LenderIcon } from '@gain/components/icons'

import ContentLink from '../../common/content-link'
import { generateLenderPagePath } from '../../routes/lender/lender-path'
import LenderTooltip from './lender-tooltip/lender-tooltip'

interface LenderContentLinkProps {
  id: number
  name: string
  logoFileUrl: string | null
}

export default function LenderContentLink({ id, name, logoFileUrl }: LenderContentLinkProps) {
  const href = generateLenderPagePath(id, name)

  return (
    <LenderTooltip id={id}>
      <ContentLink
        avatarProps={{
          src: logoFileUrl ?? undefined,
          children: !logoFileUrl && <LenderIcon />,
        }}
        href={href}
        label={name}
      />
    </LenderTooltip>
  )
}
