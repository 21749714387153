import { formatYear } from '@gain/utils/date'
import { formatNumber } from '@gain/utils/number'
import Chip from '@mui/material/Chip'

interface AssetFteProps {
  fte: number | null
  year: number | null
  range: string | null
}

export default function AssetFte({ fte, year, range }: AssetFteProps) {
  if (!fte && range) {
    return range
  }

  return (
    <>
      {formatNumber(fte)}

      {year && (
        <>
          &nbsp;
          <Chip
            label={formatYear(year, 'short')}
            size={'small'}
          />
        </>
      )}
    </>
  )
}
