import { CompanyIcon } from '@gain/components/icons'
import { Asset, AssetListItem, SearchItemType } from '@gain/rpc/app-model'
import Stack from '@mui/material/Stack'

import { mapSparklineValues } from '../../../../../common/sparkline'
import { AssetBusinessDetails } from '../../../../../routes/asset'
import { LatestFinancials } from '../../../../financial/latest-financials'
import { ObjectListItemAvatar, ObjectListItemText } from '../../../../object-list-item'
import { useSearchAPI } from '../../../search-hooks'
import SearchTopResultSideInfo from './search-top-result-side-info'
import {
  StyledDescription,
  StyledSubTitle,
  TopResultLoadingLines,
  TopResultLoadingSideInfo,
} from './search-top-result-skeleton'
import SearchTopResultTags from './search-top-result-tags'
import SearchTopResultTitle from './search-top-result-title'

export default function SearchTopResultAsset({
  asset,
  assetListItem,
}: {
  asset?: Asset
  assetListItem?: AssetListItem
}) {
  const {
    result: { topResult },
  } = useSearchAPI()

  return (
    <Stack
      direction={'row'}
      gap={4}>
      <Stack flex={1}>
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={2}>
          <ObjectListItemAvatar
            size={'big'}
            src={topResult?.imageUrl ?? undefined}
            type={SearchItemType.CuratedAsset}
            variant={'rounded'}>
            <CompanyIcon />
          </ObjectListItemAvatar>

          <ObjectListItemText variant={'top-result'}>
            <SearchTopResultTitle />
            <StyledSubTitle>Company &bull; {asset?.description?.short}</StyledSubTitle>
          </ObjectListItemText>
        </Stack>

        {((!asset && !assetListItem) || asset?.description?.atAGlance) && (
          <StyledDescription maxLines={2}>
            {asset && asset.description?.atAGlance}

            {!asset && !assetListItem && <TopResultLoadingLines />}
          </StyledDescription>
        )}

        {asset && !asset.description?.atAGlance && (
          <SearchTopResultTags tags={asset.tags.map(({ tag }) => tag)} />
        )}

        {assetListItem && <SearchTopResultTags tags={assetListItem.tags} />}

        {asset?.financialLatests && (
          <LatestFinancials
            capital={asset.financialLatests.capital?.amount}
            capitalLines={asset.financialResults.map(({ capital, year }) => ({
              value: capital?.amount || null,
              year,
            }))}
            dataCurrency={asset?.generalInfo?.currency}
            ebit={asset.financialLatests.ebit?.amount}
            ebitda={asset.financialLatests.ebitda?.amount}
            ebitdaLines={asset.financialResults.map(({ ebitda, year }) => ({
              value: ebitda?.amount || null,
              year,
            }))}
            ebitLines={asset.financialResults.map(({ ebit, year }) => ({
              value: ebit?.amount || null,
              year,
            }))}
            fte={asset.financialLatests.fte?.amount}
            fteLines={asset.financialResults.map(({ fte, year }) => ({
              value: fte?.amount || null,
              year,
            }))}
            grossMargin={asset.financialLatests.grossMargin?.amount}
            grossMarginLines={asset.financialResults.map(({ grossMargin, year }) => ({
              value: grossMargin?.amount || null,
              year,
            }))}
            netDebt={asset.financialLatests.netDebt?.amount}
            netDebtLines={asset.financialResults.map(({ netDebt, year }) => ({
              value: netDebt?.amount || null,
              year,
            }))}
            revenue={asset.financialLatests.revenue?.amount}
            revenueLines={asset.financialResults.map(({ revenue, year }) => ({
              value: revenue?.amount || null,
              year,
            }))}
            totalAssets={asset.financialLatests.totalAssets?.amount}
            totalAssetsLines={asset.financialResults.map(({ totalAssets, year }) => ({
              value: totalAssets?.amount || null,
              year,
            }))}
            variant={'vertical'}
          />
        )}

        {assetListItem && (
          <LatestFinancials
            capital={assetListItem.capitalEur}
            capitalLines={
              mapSparklineValues(assetListItem.capitalYears, assetListItem.capitalResults) || []
            }
            ebit={assetListItem.ebitEur}
            ebitda={assetListItem.ebitdaEur}
            ebitdaLines={
              mapSparklineValues(assetListItem.ebitdaYears, assetListItem.ebitdaResults) || []
            }
            ebitLines={mapSparklineValues(assetListItem.ebitYears, assetListItem.ebitResults) || []}
            fte={assetListItem.fte}
            fteLines={mapSparklineValues(assetListItem.fteYears, assetListItem.fteResults) || []}
            grossMargin={assetListItem.grossMarginEur}
            grossMarginLines={
              mapSparklineValues(
                assetListItem.grossMarginYears,
                assetListItem.grossMarginResults
              ) || []
            }
            netDebt={assetListItem.netDebtEur}
            netDebtLines={
              mapSparklineValues(assetListItem.netDebtYears, assetListItem.netDebtResults) || []
            }
            revenue={assetListItem.revenueEur}
            revenueLines={
              mapSparklineValues(assetListItem.revenueYears, assetListItem.revenueResults) || []
            }
            totalAssets={assetListItem.totalAssetsEur}
            totalAssetsLines={
              mapSparklineValues(
                assetListItem.totalAssetsYears,
                assetListItem.totalAssetsResults
              ) || []
            }
            variant={'vertical'}
          />
        )}
      </Stack>

      <SearchTopResultSideInfo>
        {asset && (
          <AssetBusinessDetails
            asset={asset}
            variant={'minimal'}
          />
        )}

        {!asset && assetListItem && (
          <AssetBusinessDetails
            asset={assetListItem}
            variant={'minimal'}
          />
        )}

        {!asset && !assetListItem && <TopResultLoadingSideInfo />}
      </SearchTopResultSideInfo>
    </Stack>
  )
}
