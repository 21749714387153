import { alpha, styled, useTheme } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { ReactNode } from 'react'

// Add custom background to MUI tooltip with a bit of opacity, such that you can
// see the chart below and the tooltip doesn't fully block a part of the chart.
const StyledTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip
    classes={{
      popper: className,
    }}
    {...props}>
    {children}
  </Tooltip>
))<TooltipProps>(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(theme.palette.grey[900], 0.9),
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: alpha(theme.palette.grey[900], 0.9),
  },
}))

type ChartTooltipProps = TooltipProps & {
  children: ReactNode
}

/**
 * ChartTooltip adds a custom background to MUI tooltip with a bit of opacity,
 * such that you can see the chart below and the tooltip doesn't fully block a
 * part of the chart.
 */
export default function ChartTooltip({ children, ...props }: ChartTooltipProps) {
  const theme = useTheme()

  return (
    <StyledTooltip
      TransitionProps={{
        // Disable exit animation to prevent showing empty data during animation
        timeout: { enter: theme.transitions.duration.enteringScreen, exit: 0 },
      }}
      {...props}>
      {children}
    </StyledTooltip>
  )
}
