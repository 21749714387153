import { useAssetList } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { AssetListItem, DealListItem, Investor, InvestorStrategy } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'

import { useTrackPageView } from '../../../features/planhat/planhat-hooks'
import InvestorDealsList from '../../investor/investor-deals-list'

export interface RouteAddOnsProps {
  investor: Investor
  strategy: InvestorStrategy
}

export default function RouteAddOns({ investor, strategy }: RouteAddOnsProps) {
  useTrackPageView('investor_strategy', {
    id: strategy.id,
    tab: 'add-ons',
    requireTab: true,
  })

  const assets = useAssetList({
    filter: [
      listFilter<AssetListItem>('ownerIds', '=', investor.id),
      listFilter<AssetListItem>('strategyIds', '=', strategy.id),
    ],
    sort: [{ field: 'ebitda', direction: 'desc' }],
    limit: 500,
  })

  const defaultFilters = [
    listFilter<DealListItem>(
      'buyerAssetIds',
      '=',
      assets.data.items.map((item) => item.id)
    ),
  ]

  return (
    <>
      <Head>
        <title>
          {investor.name} - {strategy.name} - Add-ons
        </title>
      </Head>
      <InvestorDealsList defaultFilters={defaultFilters} />
    </>
  )
}
