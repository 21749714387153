import { DownloadIcon, ExcelIcon } from '@gain/components/icons'
import { AssetListItem } from '@gain/rpc/app-model'
import LoadingButton from '@mui/lab/LoadingButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { MouseEvent, useState } from 'react'

import { ChartGroupByConfig } from '../../common/chart/chart-groups'
import { ChartSizeTypeConfig } from '../../common/chart/chart-select'
import { AxisConfig } from '../chart/company-bubble-echart/axis/axis-config'
import ExportContainer from '../export/export-button/export-container'
import { useBenchmarkingContext } from './benchmarking-context'
import { BenchmarkingItem } from './utils'

const StyledLoadingButton = styled(LoadingButton)({
  minWidth: 34,
  minHeight: 34,
})

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  width: 24,
  height: 24,
  fontSize: 24,
}))

interface BenchmarkingExportButtonProps {
  assets: BenchmarkingItem[]
  xAxisConfig: AxisConfig
  yAxisConfig: AxisConfig
  activeSizeType: ChartSizeTypeConfig<AssetListItem> | null
  activeGroupBy: ChartGroupByConfig<AssetListItem> | null
}

export default function BenchmarkingExportButton({
  assets,
  xAxisConfig,
  yAxisConfig,
  activeSizeType,
  activeGroupBy,
}: BenchmarkingExportButtonProps) {
  const context = useBenchmarkingContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <ExportContainer
      method={'data.exportAssetBenchmarking'}
      params={{
        columns: [],
        filename: `${context.name} benchmarking - Gain pro.xlsx`,
        filter: [
          {
            field: 'id',
            operator: '=',
            value: assets.map(({ id }) => id),
          },
        ],
        chartConfig: {
          xaxis: xAxisConfig.label,
          yaxis: yAxisConfig.label,
          sizeLabel: activeSizeType?.label || '',
          groupByLabel: activeGroupBy?.label || '',
          includeRankingColumn: context.showRankColumn || false,
        },
        customRanks: assets.map((asset) => ({
          assetId: asset.id,
          type: asset.type || '',
          rank: asset.relevanceRank,
        })),
        industryId: context.objectType === 'industry' ? context.objectId : null,
        investorId: context.objectType === 'investor' ? context.objectId : null,
      }}>
      {(api) => (
        <>
          <StyledLoadingButton
            color={'primary'}
            disabled={api.disabled}
            loading={api.loading}
            onClick={handleClick}>
            <DownloadIcon />
          </StyledLoadingButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={handleClose}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <MenuItem
              onClick={(event) => {
                api.download(event)
                handleClose()
              }}>
              <StyledListItemIcon>
                <ExcelIcon fontSize={'inherit'} />
              </StyledListItemIcon>
              <ListItemText>Download as Excel (.xlsx)</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </ExportContainer>
  )
}
