import { UserIcon } from '@gain/components/icons'
import { LegalEntityShareholder } from '@gain/rpc/app-model'

import { Node } from './org-chart-model'
import { formatShare, orgChartClasses } from './org-chart-utils'

interface ShareholdersNodeProps {
  node: Node<LegalEntityShareholder[]>
}

export default function ShareholdersNode({ node }: ShareholdersNodeProps) {
  return (
    <>
      {node.data.map((shareholder, index) => (
        <>
          {index > 0 && <div className={orgChartClasses.divider}></div>}
          <div className={orgChartClasses.itemContainer}>
            <div className={orgChartClasses.iconContainer}>
              <UserIcon />
            </div>
            <div className={orgChartClasses.label}>
              {shareholder.name}{' '}
              {shareholder.percentageShareExact && (
                <span className={orgChartClasses.textSecondary}>{formatShare(shareholder)}</span>
              )}
            </div>
          </div>
        </>
      ))}
    </>
  )
}
