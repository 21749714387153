import Head from '@gain/modules/head'
import { Asset, AssetListItem } from '@gain/rpc/app-model'
import { isTruthy } from '@gain/utils/common'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { useAssetList } from 'libs/api/app/hooks/src/app-api-hooks'
import { listFilter, listFilters, listSort } from 'libs/rpc/utils/src/rpc-list-util'

import CardAssetFte, { useHasFteMeasurements } from '../../../features/asset/asset-fte-card'
import SourcesList from '../../../features/source/source-list'
import { useAssetSources } from '../route-asset-hooks'
import CardAssetBusinessDescription from './asset-business-description-card'
import AssetBusinessDetailsCard, { useHasBusinessDetails } from './asset-business-details-card'
import AssetChartsCard from './asset-charts-card'
import AssetSegmentationCard from './asset-segmentation-card'
import AssetSubsidiariesCard from './asset-subsidiaries-card/asset-subsidiaries-card'

function useAssetSubsidiaries(subsidiaryPath: number[] | null) {
  return useAssetList(
    subsidiaryPath
      ? {
          filter: listFilters(listFilter('subsidiaryPath', '<@', subsidiaryPath)),
          sort: [listSort('name', 'asc')],
          limit: 500,
        }
      : null
  )
}

function filterDirectSubsidiaries(subsidiaries: AssetListItem[], subsidiaryPath: number[] | null) {
  if (!subsidiaryPath) {
    return null
  }

  return subsidiaries.filter(
    (asset) =>
      // only include assets that are exactly one level deeper than the parent path and match the parent path
      asset.subsidiaryPath?.length === subsidiaryPath.length + 1 &&
      asset.subsidiaryPath?.slice(0, -1).every((id, index) => id === subsidiaryPath[index])
  )
}

interface AssetBusinessProps {
  asset: Asset
}

export default function AssetBusiness({ asset }: AssetBusinessProps) {
  const hasBusinessDetails = useHasBusinessDetails(asset)
  const hasFteMeasurements = useHasFteMeasurements(asset)
  const hasDescription =
    [
      asset.description?.atAGlance,
      asset.description?.furtherInformation,
      asset.market?.competition,
    ].filter(isTruthy).length > 0

  const sources = useAssetSources(asset.sources, 'business')

  const swrSubsidiaries = useAssetSubsidiaries(asset.subsidiaryPath)
  const directSubsidiaries = filterDirectSubsidiaries(
    swrSubsidiaries.data.items,
    asset.subsidiaryPath
  )

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Business</title>
      </Head>
      <Grid
        spacing={2}
        container>
        {(hasBusinessDetails || hasDescription) && (
          <Grid
            spacing={2}
            container
            item>
            {hasBusinessDetails && (
              <Grid
                lg={!hasDescription && hasFteMeasurements ? 6 : 12}
                xs={12}
                item>
                <AssetBusinessDetailsCard
                  asset={asset}
                  fullHeight
                />
              </Grid>
            )}
            {hasDescription && (
              <Grid
                lg={hasFteMeasurements ? 6 : 12}
                xs={12}
                item>
                <Stack
                  gap={2}
                  height={'100%'}>
                  <CardAssetBusinessDescription asset={asset} />
                </Stack>
              </Grid>
            )}
            {hasFteMeasurements && (
              <Grid
                lg={hasDescription ? 6 : 12}
                xs={12}
                item>
                <CardAssetFte
                  asset={asset}
                  showDivider
                />
              </Grid>
            )}
          </Grid>
        )}
        {!!asset.segmentation?.charts.length && (
          <Grid
            xs={12}
            item>
            <AssetChartsCard charts={asset.segmentation.charts} />
          </Grid>
        )}
        {!!asset.segmentation?.segments.length && (
          <Grid
            xs={12}
            item>
            <AssetSegmentationCard segmentation={asset.segmentation} />
          </Grid>
        )}

        {directSubsidiaries && directSubsidiaries.length > 0 && (
          <Grid
            xs={12}
            item>
            <AssetSubsidiariesCard subsidiaries={directSubsidiaries} />
          </Grid>
        )}
        {sources.length > 0 && (
          <Grid
            xs={12}
            item>
            <SourcesList sources={sources} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
