import { isJsonRpcError, RpcErrorCode } from '@gain/rpc/utils'
import { useEffect } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useHistory } from 'react-router-dom'

import NotFound from './not-found'

export default function ErrorHandler({ error, resetErrorBoundary }: FallbackProps) {
  const history = useHistory()

  // Reset on navigate
  useEffect(() => history.listen(() => resetErrorBoundary()), [history, resetErrorBoundary])

  if (isJsonRpcError(error)) {
    switch (error.code) {
      case RpcErrorCode.ResourceOffline:
      case RpcErrorCode.InvalidResourceIdentifier:
        return <NotFound />
    }
  }

  throw error
}
