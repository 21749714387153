import Chip, { chipClasses, ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'

import ConditionalWrapper from '../../conditional-wrapper'

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => !['active', 'disabled', 'textOnly'].includes(prop.toString()),
})<{ active?: boolean; disabled?: boolean; textOnly?: boolean }>(
  ({ theme, active, disabled, textOnly }) => ({
    border: `1px solid ${theme.palette.common.white}`,
    color: theme.palette.text.primary,
    [`&.${chipClasses.clickable}:hover`]: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider}`,
      ...(textOnly && {
        color: theme.palette.text.secondary,
      }),
    },
    ...(textOnly && {
      color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    }),
    ...(disabled && {
      opacity: 0.5,
      textDecoration: 'line-through',
    }),
    ...(active && {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider}`,
    }),
  })
)

const StyledChipLabel = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(0.75),
  ...theme.typography.body2,
  alignItems: 'center',
}))

const StyledDot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ color }) => ({
  borderRadius: '50%',
  backgroundColor: color,
  width: 8,
  height: 8,
}))

interface ChipPropOverrides {
  label: string
  color?: string
  active?: boolean
  disabled?: boolean
}

type ChartLegendChipProps = ChipPropOverrides & Omit<ChipProps, keyof ChipPropOverrides>

function ChartLegendChip({ label, color, ...chipProps }: ChartLegendChipProps, ref) {
  return (
    <StyledChip
      ref={ref}
      label={
        <ConditionalWrapper
          condition={!!color}
          wrapper={(children) => (
            <StyledChipLabel>
              {color && <StyledDot color={color} />}
              {children}
            </StyledChipLabel>
          )}>
          <StyledChipLabel>{label}</StyledChipLabel>
        </ConditionalWrapper>
      }
      textOnly={!color}
      variant={'outlined'}
      clickable
      {...chipProps}
    />
  )
}

export default forwardRef(ChartLegendChip)
