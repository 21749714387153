import Divider from '@mui/material/Divider'
import { MouseEvent, useCallback, useMemo, useState } from 'react'

import { chartColorSet } from '../../../common/chart/chart-colors'
import { ChartGroup } from '../../../common/chart/chart-groups'
import ChartLegend from '../../../common/chart/chart-legend'
import { MeasureDimensions } from '../../../common/responsive'
import { StackedBarChart } from '../../chart'

interface GroupConfig {
  label: string
  key: string
}

interface CardDealsSummaryProps<Data> {
  groupConfig: GroupConfig[]
  data?: Data[]
  onClick?: (group: Data, event: MouseEvent) => void
  disableLegend?: boolean
}

export default function CardDealsSummary<
  Data extends { type: string; dealCount: number; year: number }
>({ data, groupConfig, onClick, disableLegend }: CardDealsSummaryProps<Data>) {
  const [hoverGroup, setHoverGroup] = useState<ChartGroup<Data> | null>(null)

  const groups: ChartGroup<Data, number>[] = useMemo(() => {
    if (!data) {
      return []
    }

    return groupConfig.map(({ label, key }, index) => {
      return {
        label,
        items: data.filter((item) => item.type === key),
        value: index,
        color: chartColorSet[index],
      }
    })
  }, [groupConfig, data])

  // A helper function to find the index of a group in the groupConfig
  const findGroupIndex = useCallback(
    (item: Data) => groupConfig.findIndex((group) => group.key === item.type),
    [groupConfig]
  )

  const handleGetColor = useCallback(
    (item: Data) =>
      groups.find((group) => group.value === groupConfig.findIndex((cfg) => cfg.key === item.type))
        ?.color || 'black',
    [groupConfig, groups]
  )

  const [visibleGroups, setVisibleGroups] = useState<ChartGroup<Data, number>[]>(groups)

  const isEmpty = useMemo(() => !!data?.every((item) => item.dealCount === 0), [data])

  // Returns the deals that are visible after enabling/disable visible groups
  const visibleDeals = useMemo(() => {
    if (!data || isEmpty) {
      return []
    }

    const types = visibleGroups.map((group) => group.value)

    return data
      .filter((item) => types.includes(findGroupIndex(item)))
      .sort((a, b) => findGroupIndex(a) - findGroupIndex(b))
  }, [findGroupIndex, data, visibleGroups, isEmpty])

  const handleGroupClick = useCallback(
    (group: Data, event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      onClick?.(group, event)
    },
    [onClick]
  )

  if (!data) {
    return null
  }

  return (
    <MeasureDimensions>
      {({ width, height }) => (
        <>
          <StackedBarChart
            data={visibleDeals}
            getColor={handleGetColor}
            getHighlightGroup={findGroupIndex}
            height={isEmpty ? height : height - (disableLegend ? 0 : 49)}
            highlightGroup={hoverGroup?.value}
            onGroupClick={handleGroupClick}
            width={width}
            xScaleConfig={{
              label: 'Year',
              getLabel: (value) => value.toString(),
              getValue: (item) => item.year,
            }}
            yScaleConfig={{
              label: 'Total',
              getLabel: (value) => value.toString(),
              getValue: (item) => item.dealCount,
            }}
          />
          {!isEmpty && !disableLegend && (
            <>
              <Divider sx={{ mx: -3 }} />
              <ChartLegend
                groups={groups}
                onChange={setVisibleGroups}
                onGroupHover={setHoverGroup}
                value={visibleGroups}
              />
            </>
          )}
        </>
      )}
    </MeasureDimensions>
  )
}
