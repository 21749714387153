import { useUserProfile } from '@gain/api/app/hooks'
import { useRpcClient } from '@gain/api/swr'
import { WifiOffIcon } from '@gain/components/icons'
import PublicPage from '@gain/components/public-page'
import Snackbar from '@gain/components/snackbar'
import Typography from '@gain/components/typography'
import { apiEndpoint, RpcClient } from '@gain/rpc/utils'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useEffect, useState } from 'react'

import { UnexpectedRpcError } from '../../../rpc/utils/src/rpc-error'
import { useHasInternet } from './maintenance-hooks'

const INTERNET_NOT_AVAILABLE_SNACKBAR_KEY = 'internet-not-available'

export interface MaintenanceProps {
  client: RpcClient
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  marginBottom: `${theme.spacing(1)} !important`,
}))

const NoInternetOverlay = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0,0,0,.2)',
  zIndex: theme.zIndex.tooltip,
}))

export default function Maintenance({ children, client }: PropsWithChildren<MaintenanceProps>) {
  // Make sure we use an app RPC client for fetching the user profile
  let appRpcClient = client
  if (appRpcClient.baseUrl !== apiEndpoint.RpcApp) {
    appRpcClient = new RpcClient(apiEndpoint.RpcApp)
  }

  const appRpcClientFetcher = useRpcClient(appRpcClient)
  const swrUserProfile = useUserProfile(undefined, { fetcher: appRpcClientFetcher })
  const snackbar = useSnackbar()
  const [isInMaintenance, setIsInMaintenance] = useState(false)
  const hasInternet = useHasInternet()

  // Show maintenance mode when the backend returns 503, or an unknown error
  // happens while the user profile has never loaded yet, or there are multiple
  // sequential unknown errors.
  useEffect(() => {
    client.onUnknownError((error, errorCount) => {
      const isMaintenanceMode = error instanceof UnexpectedRpcError && error.status === 503
      if (isMaintenanceMode || !swrUserProfile.data || errorCount > 2) {
        setIsInMaintenance(true)
      }
    })
  }, [swrUserProfile.data])

  // Show a snackbar when the user is offline, hide the snackbar
  // when internet becomes available again
  useEffect(() => {
    if (hasInternet) {
      snackbar.closeSnackbar(INTERNET_NOT_AVAILABLE_SNACKBAR_KEY)
    } else {
      snackbar.enqueueSnackbar(undefined, {
        key: INTERNET_NOT_AVAILABLE_SNACKBAR_KEY,
        persist: true,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        preventDuplicate: true,
        content: () => (
          <Snackbar
            disableCloseButton={true}
            icon={<WifiOffIcon />}
            id={INTERNET_NOT_AVAILABLE_SNACKBAR_KEY}
            message={
              'It seems there is a problem with your connection. Please check your network status.'
            }
            variant={'dark'}
          />
        ),
      })
    }
  }, [hasInternet, snackbar])

  // Show the maintenance page when the RPC client indicates maintenance
  if (isInMaintenance) {
    return (
      <PublicPage>
        <StyledDivider />

        <Stack
          alignItems={'center'}
          display={'flex'}
          gap={2}
          textAlign={'center'}>
          <Typography variant={'h4'}>Great things are on the way!</Typography>

          <Typography
            color={'text.secondary'}
            variant={'body1'}>
            Gain.pro is being upgraded. This will only take a couple of minutes. Thank you for your
            patience.
          </Typography>
        </Stack>
      </PublicPage>
    )
  }

  // Render the children when there are no connection issues
  return (
    <>
      <Fade in={!hasInternet}>
        <NoInternetOverlay />
      </Fade>
      {children}
    </>
  )
}
