import { MapPinIcon } from '@gain/components/icons'
import OverflowContainer from '@gain/components/overflow-container'
import { formatCountry } from '@gain/utils/countries'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { useSearchAPI } from '../../../search-hooks'

export default function SearchTopResultLocation() {
  const {
    result: { topResult },
  } = useSearchAPI()

  if (!topResult?.regions || topResult?.regions.length === 0) {
    return null
  }

  return (
    <ListItem
      disableGutters
      disablePadding>
      <ListItemIcon>
        <MapPinIcon />
      </ListItemIcon>
      <ListItemText>
        <OverflowContainer
          maxLines={1}
          spacing={0.5}>
          {topResult.regions.map(
            (region, index) =>
              `${formatCountry(region)}${index + 1 !== topResult.regions.length ? ', ' : ''}`
          )}
        </OverflowContainer>
      </ListItemText>
    </ListItem>
  )
}
