import { useAssetListItem } from '@gain/api/app/hooks'
import Typography from '@gain/components/typography'
import Alert, { alertClasses } from '@mui/material/Alert'
import { styled } from '@mui/material/styles'

import ParentLink from '../../../../features/parent-link/parent-link'

const StyledLabel = styled(Typography)({
  whiteSpace: 'nowrap',
})

const StyledAlert = styled(Alert)(({ theme }) => ({
  cursor: 'default',
  display: 'flex',
  padding: theme.spacing('7px', 2),
  margin: theme.spacing(0, -2),
  [`& .${alertClasses.message}`]: {
    overflow: 'visible',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
  },
}))

const StyledGoTo = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

interface SubsidiaryParentItemProps {
  parentId: number
}

export default function SubsidiaryParentAlert({ parentId }: SubsidiaryParentItemProps) {
  const parent = useAssetListItem(parentId)

  if (!parent.data) {
    return null
  }

  return (
    <StyledAlert
      icon={false}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
      severity={'error'}>
      <StyledLabel typography={'body2'}>This is a subsidiary</StyledLabel>

      <StyledGoTo>
        <StyledLabel typography={'body2'}>Go to&nbsp;</StyledLabel>
        <ParentLink parent={parent.data} />
      </StyledGoTo>
    </StyledAlert>
  )
}
