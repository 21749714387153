import { useListRecentVisits } from '@gain/api/app/hooks'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import Popper, { PopperProps } from '@mui/material/Popper'
import { styled } from '@mui/material/styles'
import { memo, useEffect, useState } from 'react'

import { useRecentlyFilteredBookmarkLists } from '../../../api/bookmark-list-hooks'
import RecentFilterList from '../recent-filter'
import RecentVisitsList from '../recent-visits'

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,
  boxShadow: '0px 24px 90px -20px rgba(0, 0, 0, 0.3)',
  padding: theme.spacing(1),
  maxHeight: 570,
  overflowY: 'auto',

  [theme.breakpoints.up('sm')]: {
    width: 464,
  },
  [theme.breakpoints.up('lg')]: {
    width: 664,
  },
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}))

interface SearchDropdownProps extends PopperProps {
  onClose: () => void
}

// Keep popperModifiers stable! If we don't do this the nested bookmarks list menu
// will flicker when you add a new bookmark list. It is quite a peculiar bug that
// only occurs when a menu is opened inside another menu and took a while to
// figure out, so please heed this warning!
const popperModifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, 5], // small gap between search and dropdown
    },
  },
]

function SearchDropdown({ anchorEl, onClose, open, ...props }: SearchDropdownProps) {
  const [keepMounted, setKeepMounted] = useState(false)
  const swrRecentlyFiltered = useRecentlyFilteredBookmarkLists()
  const swrRecentVisits = useListRecentVisits()

  // We intentionally avoid mounting the Popper children on initial render to
  // prevent flickering when the user clicks on the search input and data is
  // still loading.
  //
  // However, once the Popper has been opened at least once, we set `keepMounted`
  // to true. This prevents unnecessary re-loading of its contents (like tag
  // names, investor names, ...) on subsequent openings.
  useEffect(() => {
    setKeepMounted((prev) => prev || open)
  }, [open])

  const recentFilters = swrRecentlyFiltered.data.items
  const recentVisits = swrRecentVisits.data?.items ?? []

  if (!recentVisits) {
    return null
  }

  if (recentVisits.length === 0 && recentFilters.length === 0) {
    onClose() // Notify parent the popper should remain closed and not update upon trackVisit-requests
    return null // Don't render popover when there is nothing to see
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <StyledPopper
        {...props}
        anchorEl={anchorEl}
        keepMounted={keepMounted}
        modifiers={popperModifiers}
        open={open}
        placement={'bottom-start'}
        disablePortal>
        {/* Collapse transition does not work with popper:
            https://github.com/mui/material-ui/issues/11337#issuecomment-510578264
        */}

        <RecentFilterList
          onClick={onClose}
          recentFilterLists={recentFilters}
        />

        {recentFilters.length > 0 && recentVisits.length > 0 && <StyledDivider />}

        <RecentVisitsList
          items={recentVisits}
          onClick={onClose}
        />
      </StyledPopper>
    </ClickAwayListener>
  )
}

export default memo(SearchDropdown)
