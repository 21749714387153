import { StarIcon } from '@gain/components/icons'
import { Components, Theme } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

export function MuiRating(theme: Theme): Components['MuiRating'] {
  return {
    defaultProps: {
      icon: <StarIcon />,
      emptyIcon: <StarIcon />,
    },
    styleOverrides: {
      root: {
        fontSize: 14.4,
      },
      decimal: {
        '&:not(:last-child)': {
          marginRight: 4,
        },
      },
      icon: {
        [`& .${svgIconClasses.root}`]: {
          fontSize: 'inherit',
        },
      },
      iconEmpty: {
        color: theme.palette.divider,
      },
    },
  }
}
