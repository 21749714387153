import { SpinnerIcon } from '@gain/components/icons'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

export const loadingClasses = generateUtilityClasses('Loading', [
  'root',
  'sizeSmall',
  'primaryColor',
])

const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '50vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [`&.${loadingClasses.sizeSmall}`]: {
    height: 70,
  },
  [`&.${loadingClasses.primaryColor} circle:last-of-type`]: {
    color: theme.palette.primary.main,
  },
}))

export interface LoadingProps {
  size?: 'small'
  color?: 'default' | 'primary'
}

export default function Loading({ size, color }: LoadingProps) {
  return (
    <StyledContainer
      className={clsx(loadingClasses.root, {
        [loadingClasses.sizeSmall]: size === 'small',
        [loadingClasses.primaryColor]: color === 'primary',
      })}>
      <SpinnerIcon />
    </StyledContainer>
  )
}
