import * as Shared from '@gain/rpc/shared-model'
import Stack from '@mui/material/Stack'

import useFinancialVariantColor from '../use-financial-variant-color'

interface CircleIconProps {
  amountType?: Shared.FinancialResultAmountType
  isForecast?: boolean
}

export default function CircleIcon({
  amountType = Shared.FinancialResultAmountType.Actual,
  isForecast = false,
}: CircleIconProps) {
  const color = useFinancialVariantColor(amountType, isForecast)

  return (
    <Stack
      bgcolor={color}
      borderRadius={'50%'}
      height={9}
      width={9}
    />
  )
}
