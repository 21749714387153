import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText'
import { alpha, styled } from '@mui/material/styles'

export interface MobileListItemProps {
  disableDivider?: boolean
  disablePadding?: boolean
}

export const MobileListItem = styled(ListItem, {
  shouldForwardProp: (prop) => !['disableDivider', 'disablePadding'].includes(prop as string),
})<MobileListItemProps>(({ theme, disableDivider, disablePadding }) => ({
  padding: disablePadding ? theme.spacing(0) : theme.spacing(2, 3),
  ...(!disableDivider && {
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
}))

export type MobileListItemIconColor = 'info' | 'default'

export const MobileListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: MobileListItemIconColor }>(({ theme, color = 'default' }) => ({
  width: 40,
  height: 40,
  fontSize: 20,
  borderRadius: 8,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1.5),
  ...(color === 'info' && {
    backgroundColor: alpha(theme.palette.info.main, 0.08),
    color: theme.palette.info.main,
  }),
  ...(color === 'default' && {
    backgroundColor: theme.palette.grey['50'],
    color: theme.palette.grey['600'],
  }),
}))

export const MobileListItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  [`& .${listItemTextClasses.primary}`]: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
  },
  [`& .${listItemTextClasses.secondary}`]: {
    ...theme.typography.overline,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.25),
  },
}))

export const MobileListItemButton = styled(ListItemButton)({
  display: 'contents',
})

export const MobileListItemValue = styled('span')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(1),
}))
