import { isDefined } from '@gain/utils/common'
import MuiAvatar, { avatarClasses, AvatarProps as MuiAvatarProps } from '@mui/material/Avatar'
import { alpha, styled } from '@mui/material/styles'

import Skeleton from '../skeleton'

export interface AvatarProps extends MuiAvatarProps {
  skeleton?: boolean
  disableBorder?: boolean
  size?: 'normal' | 'large' | number
  imagePadding?: number
}

export const StyledAvatar = styled(MuiAvatar, {
  shouldForwardProp: (prop) =>
    !['disableGutter', 'size', 'disableBorder', 'imagePadding'].includes(prop as string),
})<AvatarProps>(({ theme, size, disableBorder, imagePadding, src, children }) => ({
  ...(disableBorder && {
    border: 'none',
  }),

  [`& .${avatarClasses.img}`]: {
    padding: '10%',
  },

  ...(typeof imagePadding === 'number' && {
    [`& .${avatarClasses.img}`]: {
      ...(imagePadding > 0 && {
        padding: theme.spacing(imagePadding),
      }),
      ...(imagePadding === 0 && {
        padding: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }),
    },
  }),

  ...(size === 'large' && {
    width: 64,
    height: 64,
  }),

  ...(typeof size === 'number' && {
    width: size,
    height: size,
  }),

  ...(isDefined(src) && {
    backgroundColor: theme.palette.common.white,
  }),

  ...(!isDefined(src) &&
    isDefined(children) && {
      backgroundColor: theme.palette.grey['100'],
      border: 'none',
      '& svg': {
        color: alpha(theme.palette.text.secondary, 0.6),
        width: 'calc(100% / 2)',
        height: 'calc(100% / 2)',
      },
    }),
}))

export default function Avatar({ skeleton, ...props }: AvatarProps) {
  return (
    <Skeleton enabled={skeleton}>
      <StyledAvatar {...props} />
    </Skeleton>
  )
}
