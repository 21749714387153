import { ListItemKey } from '@gain/rpc/list-model'
import Button, { buttonClasses } from '@mui/material/Button'
import ListItemText from '@mui/material/ListItemText'
import Menu, { menuClasses } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'

import MenuExpandIcon from '../../../../common/menu-expand-icon'
import { FilterConfig } from '../filter-config/filter-config-model'

const StyledHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  flexDirection: 'row',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  padding: 0,

  '&:hover': {
    background: 'none',
  },

  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: theme.spacing(0.5),
  },
}))

const StyledMenu = styled(Menu)({
  [`& .${menuClasses.list}`]: {
    minWidth: 'inherit', // Reset so it can grow smaller if it can (default value is wider)
  },
})

export interface FilterItemHeaderSelectProps<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  filter: FilterConfig<Item, FilterField>
  onChange: (value: string) => void
}

/**
 * `FilterItemHeaderSelect` is a component that displays a header with a dropdown
 * menu for switching between various filters, such as time durations (1Y, 2Y, 3Y, etc.),
 * where each option represents a distinct filter.
 */
export default function FilterItemHeaderSelect<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>({ filter, onChange }: FilterItemHeaderSelectProps<Item, FilterField>) {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const open = Boolean(anchorEl)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleChange = useCallback(
    (value: string) => {
      handleClose()
      onChange(value)
    },
    [onChange, handleClose]
  )

  const selectedOption = filter.select?.options.find((option) => option.id === filter.id)

  if (!filter.select || !selectedOption) {
    return null
  }

  return (
    <>
      <StyledHeader variant={'subtitle2'}>
        <div>{filter.select.header}</div>
        <StyledButton
          endIcon={<MenuExpandIcon open={Boolean(anchorEl)} />}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          {selectedOption?.label}
        </StyledButton>
      </StyledHeader>

      <StyledMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: -4,
        }}>
        {filter.select.options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => handleChange(option.id)}>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}
