import Flag from '@gain/components/flag'
import { AssetListItem } from '@gain/rpc/app-model'
import { ColumnVisibilityModel } from '@gain/utils/table'
import { useMemo } from 'react'

import { ContentLinkCellContainer } from '../../../../common/content-link'
import { createTableColumns } from '../../../../common/table'
import AssetContentLink from '../../../../features/asset/asset-content-link'
import AssetFte from '../../../../features/asset/asset-fte'
import { PercentageTableCell } from '../../../../features/asset/asset-list-item-table/table-cell/percentage-table-cell'
import { MIN_REVENUE_PERCENTAGE } from '../../../../features/financial/financial-utils'
import FinancialValue from '../../../../features/financial/financial-value'
import { SummaryColumnConfig, SummaryRowConfig } from './table-summary'

export const columnVisibility: ColumnVisibilityModel<AssetListItem> = {
  description: 1210,
  ebitdaPctRevenue: 920,
  fte: 800,
  revenueEur: 610,
  region: 460,
  relevanceRank: 400,
}

export const summaryRows: SummaryRowConfig[] = [
  { method: 'min' },
  { method: 'median', bold: true },
  { method: 'max' },
]

export function useCreateTableSummaryColumns(
  currency?: string
): SummaryColumnConfig<AssetListItem>[] {
  return useMemo(() => {
    return [
      {
        field: 'ebitdaPctRevenue',
        getAggregationValue: (value: number) => (value < MIN_REVENUE_PERCENTAGE ? null : value),
        renderCell: (value: number | null) => (
          <PercentageTableCell
            notMeaningfulMin={MIN_REVENUE_PERCENTAGE}
            percentage={value}
            enableNotMeaningful
          />
        ),
      },
      {
        field: 'revenueFteRatioEur',
        renderCell: (value: number | null) => (
          <FinancialValue
            amount={value}
            format={'thousands-or-millions'}
            sx={{ pr: 4 }} // Align with values in the column above that have ('23) chips
            toCurrency={currency}
          />
        ),
      },
    ]
  }, [currency])
}

export function useCreateTableColumns(currency?: string) {
  return useMemo(
    () =>
      createTableColumns<AssetListItem>(
        {
          headerName: 'Name',
          field: 'name',
          renderCell: ({ row }) => (
            <ContentLinkCellContainer>
              <AssetContentLink
                asset={row}
                id={row.id}
                logoFileUrl={row.logoFileUrl}
                name={row.name}
              />
            </ContentLinkCellContainer>
          ),
        },
        {
          headerName: 'Business description',
          field: 'description',
        },
        {
          field: 'region',
          headerName: 'HQ',
          width: 68,
          align: 'center',
          renderCell: ({ value }) => (value ? <Flag code={value} /> : <>-</>),
        },
        {
          field: 'relevanceRank',
          headerName: 'Rank',
          width: 60,
          valueFormatter: ({ value }) => `#${value}`,
        },
        {
          field: 'fte',
          headerName: 'FTEs',
          align: 'right',
          width: 144,
          renderCell: ({ row }) => (
            <AssetFte
              fte={row.fte}
              range={row.fteRange}
              year={row.fteYear}
            />
          ),
        },
        {
          field: 'revenueEur',
          headerName: 'Revenue',
          align: 'right',
          width: 150,
          renderCell: ({ value, row }) => (
            <FinancialValue
              amount={value}
              toCurrency={currency}
              year={row.revenueYear}
            />
          ),
        },
        {
          field: 'ebitdaPctRevenue',
          headerName: 'EBITDA %',
          width: 160,
          align: 'right',
          renderCell: ({ row }) => (
            <PercentageTableCell
              notMeaningfulMin={MIN_REVENUE_PERCENTAGE}
              percentage={row.ebitdaPctRevenue}
              results={row.ebitdaPctRevenueResults}
              years={row.ebitdaPctRevenueYears}
              enableNotMeaningful
            />
          ),
        },
        {
          field: 'revenueFteRatioEur',
          headerName: 'Revenue / FTE',
          align: 'right',
          width: 150,
          renderCell: ({ row }) => (
            <FinancialValue
              amount={row.revenueFteRatioEur}
              format={'thousands-or-millions'}
              toCurrency={currency}
              year={row.revenueFteRatioYear}
            />
          ),
        }
      ),
    [currency]
  )
}
