import { useBookmarkListUrlImportTaskState } from '@gain/api/app/hooks'
import { BookmarkIcon, ChevronDownIcon, DownloadIcon, XIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { forwardRef, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { BOOKMARKS_LIST_PATH } from '../../../routes/utils'
import { useZendesk } from '../../zendesk'

const StyledRoot = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: theme.palette.shadow.level1d,
  minWidth: 434,
}))

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  flexDirection: 'row-reverse',
  backgroundColor: theme.palette.common.white,

  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    marginRight: 'auto',
  },

  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: 12,
  },
}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  maxHeight: 265,
  overflowY: 'auto',
  backgroundColor: theme.palette.grey['50'],
}))

const StyledStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.grey['200']}`,
  padding: theme.spacing(0, 1, 0, 2),
}))

const StyledListItemButton = styled(ListItemButton)({
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledSupportButton = styled('span')({
  cursor: 'pointer',
  fontWeight: 'bold',
  '&:hover': {
    textDecoration: 'underline',
  },
})

interface BookmarkListUrlImportStatusOverviewSnackbarProps {
  snackbarId: string
  taskId: number
}

/**
 * Snackbar message that is displayed when importing a list of URLs to a
 * bookmark list has completed.
 */
const BookmarkListUrlImportStatusOverviewSnackbar = forwardRef<
  HTMLDivElement,
  BookmarkListUrlImportStatusOverviewSnackbarProps
>(({ snackbarId, taskId }, ref) => {
  const zendesk = useZendesk()
  const history = useHistory()
  const snackbar = useSnackbar()
  const status = useBookmarkListUrlImportTaskState(taskId)

  // After the file is downloaded, it is removed in the backend. Keep track
  // of the state so we can hide the download link when it has been downloaded
  const [hasDownloaded, setHasDownloaded] = useState(false)

  const success = !!status.data?.resultFileUrl
  const error = !!status.data?.hasError
  const uniqueMatches = status.data ? status.data.urlsMatched - status.data.duplicateMatchCount : 0

  return (
    <StyledRoot ref={ref}>
      {success && (
        <Accordion>
          <StyledStack>
            <StyledAccordionSummary expandIcon={<ChevronDownIcon fontSize={'small'} />}>
              <Typography
                color={'textPrimary'}
                variant={'body2'}
                noWrap>
                Successfully matched {status.data?.urlsMatched}/{status.data?.urlsTotal} URLs
                {!!status.data?.duplicateMatchCount && (
                  <>
                    , resulting in {uniqueMatches} unique profile{uniqueMatches !== 1 && 's'}
                  </>
                )}
              </Typography>
            </StyledAccordionSummary>
            <IconButton
              onClick={() => snackbar.closeSnackbar(snackbarId)}
              size={'small'}>
              <XIcon />
            </IconButton>
          </StyledStack>
          <StyledAccordionDetails>
            <List dense>
              <Collapse in={!hasDownloaded && !!status.data?.resultFileUrl}>
                <ListItem disablePadding>
                  <StyledListItemButton
                    onClick={() => {
                      if (status.data?.resultFileUrl) {
                        window.open(status.data.resultFileUrl)
                        setHasDownloaded(true)
                      }
                    }}>
                    Download matching report
                    <DownloadIcon
                      fontSize={'small'}
                      sx={{ color: 'grey.500' }}
                    />
                  </StyledListItemButton>
                </ListItem>
              </Collapse>
              <ListItem disablePadding>
                <StyledListItemButton
                  disabled={!status.data}
                  onClick={() => {
                    if (status.data) {
                      history.push(
                        generatePath(BOOKMARKS_LIST_PATH, { listId: status.data?.bookmarkListId })
                      )
                    }
                  }}>
                  View bookmark list
                  <BookmarkIcon
                    fontSize={'small'}
                    sx={{ color: 'grey.500' }}
                  />
                </StyledListItemButton>
              </ListItem>
            </List>
          </StyledAccordionDetails>
        </Accordion>
      )}
      {error && (
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={1}
          sx={{ mx: 2, my: 1 }}>
          <Typography variant={'body2'}>
            Something went wrong, please try again or{' '}
            <StyledSupportButton onClick={() => zendesk.enableChat()}>
              contact support
            </StyledSupportButton>
          </Typography>
          <IconButton
            onClick={() => snackbar.closeSnackbar(snackbarId)}
            size={'small'}>
            <XIcon />
          </IconButton>
        </Stack>
      )}
    </StyledRoot>
  )
})

export default BookmarkListUrlImportStatusOverviewSnackbar
