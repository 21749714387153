import { InvestorFundListItem } from '@gain/rpc/app-model'
import { formatDate } from '@gain/utils/date'
import { investorFundExplanations } from '@gain/utils/investor-fund'
import { formatMultiple, formatPercentage } from '@gain/utils/number'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import { CardContent } from '../../../../common/card/card'
import InfoButton from '../../../../common/info-button'
import { KeyValueGridItem, useKeyValueGridKeys } from '../../../../common/key-value/key-value-grid'

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'grid',
  rowGap: theme.spacing(1),
  columnGap: theme.spacing(2),
  gridTemplateColumns: 'repeat(2, 1fr)',
  marginTop: theme.spacing(2),
}))

function renderLabel(label: string, explainer: string) {
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      gap={1}>
      <Typography
        color={'text.primary'}
        fontWeight={'bold'}
        variant={'inherit'}>
        {label}
      </Typography>

      <InfoButton
        dialogMessage={explainer}
        dialogTitle={label}
      />
    </Stack>
  )
}

function renderValue(
  key: keyof InvestorFundListItem,
  formatter: (value: number | null | undefined) => string
) {
  return function (data: InvestorFundListItem) {
    return (
      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={1}>
        <Typography
          color={'text.primary'}
          variant={'body2'}
          noWrap>
          {formatter(data[key] as never)}
        </Typography>

        {data[`${key}Date`] && (
          <Chip
            label={formatDate(data[`${key}Date`], { format: 'monthYearAbbreviated' })}
            size={'small'}
          />
        )}
      </Stack>
    )
  }
}

interface InvestorFundOverviewCardPerformanceTabProps {
  fund: InvestorFundListItem
}

export default function InvestorFundOverviewCardPerformanceTab({
  fund,
}: InvestorFundOverviewCardPerformanceTabProps) {
  const keyValues = useKeyValueGridKeys<InvestorFundListItem>(fund, [
    {
      field: 'grossIrr',
      label: renderLabel('Gross IRR', investorFundExplanations.grossIrr),
      renderValue: renderValue('grossIrr', (value) => formatPercentage(value, { round: 2 })),
    },
    {
      field: 'netIrr',
      label: renderLabel('Net IRR', investorFundExplanations.netIrr),
      renderValue: renderValue('netIrr', (value) => formatPercentage(value, { round: 2 })),
    },
    {
      field: 'twr',
      label: renderLabel('TWR', investorFundExplanations.twr),
      renderValue: renderValue('twr', (value) => formatPercentage(value, { round: 2 })),
    },
    {
      field: 'moic',
      label: renderLabel('MOIC', investorFundExplanations.moic),
      renderValue: renderValue('moic', (value) => formatMultiple(value, { round: 2 })),
    },
    {
      field: 'dpi',
      label: renderLabel('DPI', investorFundExplanations.dpi),
      renderValue: renderValue('dpi', (value) => formatMultiple(value, { round: 2 })),
    },
    {
      field: 'rvpi',
      label: renderLabel('RVPI', investorFundExplanations.rvpi),
      renderValue: renderValue('rvpi', (value) => formatMultiple(value, { round: 2 })),
    },
    {
      field: 'tvpi',
      label: renderLabel('TVPI', investorFundExplanations.tvpi),
      renderValue: renderValue('tvpi', (value) => formatMultiple(value, { round: 2 })),
    },
  ])

  return (
    <StyledCardContent>
      {keyValues.map((keyValue) => (
        <KeyValueGridItem
          key={keyValue.field}
          keyValue={keyValue}
          sx={{
            p: 0,
          }}
        />
      ))}
    </StyledCardContent>
  )
}
