import Head from '@gain/modules/head'
import { Investor, InvestorStrategy } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid2'

import Card, { CardHeader } from '../../../common/card/card'
import InvestorFundPerformanceSources from '../../../features/investor-fund/investor-fund-performance-sources'
import { useTrackPageView } from '../../../features/planhat/planhat-hooks'
import InvestorFundsTable from '../../investor/route-funds/investor-funds-table'
import InvestorStrategyFundraisingCard from './investor-strategy-fundraising-card'

export interface RouteFundsProps {
  investor: Investor
  strategy: InvestorStrategy
}

export default function RouteFunds({ investor, strategy }: RouteFundsProps) {
  useTrackPageView('investor_strategy', { id: strategy.id, tab: 'funds', requireTab: true })

  const funds = investor.funds.filter((fund) => fund.investorStrategyId === strategy.id) || []

  return (
    <Grid
      spacing={2}
      sx={{ alignSelf: 'stretch' }}
      container>
      <Head>
        <title>
          {investor?.name || ''} - {strategy.name} - Funds
        </title>
      </Head>

      <Grid size={12}>
        <InvestorStrategyFundraisingCard strategy={strategy} />
      </Grid>

      <Grid size={12}>
        <Card sx={{ pb: 0, overflow: 'clip' }}>
          <CardHeader title={'Funds'} />
          <InvestorFundsTable
            funds={funds}
            investor={investor}
            strategies={[]}
          />
        </Card>
      </Grid>

      <InvestorFundPerformanceSources funds={funds} />
    </Grid>
  )
}
