import InfoButton from '../../../common/info-button'

export default function ButtonAlertEsgOutperformer() {
  return (
    <InfoButton
      dialogMessage={
        'Proactively addresses ESG risks related to the Company and the underlying industry segment'
      }
      dialogTitle={'ESG outperformer'}
      sx={{ ml: 0.5 }}
    />
  )
}
