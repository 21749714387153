import { useAssetListItems, useSimilarAssets } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Asset } from '@gain/rpc/app-model'

import Loading from '../../../common/loading'
import Benchmarking from '../../../features/benchmarking'
import { BenchmarkingContextProvider } from '../../../features/benchmarking/benchmarking-context'
import { addBenchmarkingItemType } from '../../../features/benchmarking/utils'
import { useAssetCompetitors } from '../use-asset-competitors'

interface AssetBenchmarkingProps {
  asset: Asset
}

export default function AssetBenchmarking({ asset }: AssetBenchmarkingProps) {
  const swrCurrentAsset = useAssetListItems([asset.id])
  const swrSimilarAssets = useSimilarAssets(asset.id, 500)
  const swrCompetitors = useAssetCompetitors(asset.competitors)

  if (
    swrSimilarAssets.loading ||
    swrCurrentAsset.loading ||
    swrCompetitors.loading ||
    !swrSimilarAssets.data ||
    !swrCurrentAsset.data ||
    !swrCompetitors.data
  ) {
    return <Loading />
  }

  const name = asset.generalInfo?.name || ''

  return (
    <>
      <Head>
        <title>{name} - Benchmarking</title>
      </Head>

      <BenchmarkingContextProvider
        value={{
          name,
          objectId: asset.id,
          objectType: 'asset',
          showRankColumn: true,
        }}>
        <Benchmarking
          initialAssets={[
            ...addBenchmarkingItemType(swrCurrentAsset.data.items, 'Target'),
            ...addBenchmarkingItemType(swrSimilarAssets.data.items, 'Similar'),
            ...addBenchmarkingItemType(swrCompetitors.data.items, 'Curated'),
          ]}
          initialSort={[{ field: 'relevanceRank', direction: 'asc' }]}
          loadingInitialAssets={false}
          allowAdjustBenchmarkSize
        />
      </BenchmarkingContextProvider>
    </>
  )
}
