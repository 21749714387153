import Stack from '@mui/material/Stack'
import { ReactNode } from 'react'

import InfoButton from '../../info-button/info-button'
import SelectMenu from '../../select-menu/select-menu'

interface Explainer {
  title: string
  message: string
}

export interface ChartSizeTypeConfig<D> {
  id: string
  label: string
  explainer?: Explainer
  getValue: (d: D) => number | null
  formatter: (value: number | null, item: D) => string
  formatTooltipLabel?: (d: D) => string | ReactNode
}

interface ChartSizeTypeSelectProps<Data> {
  options: ChartSizeTypeConfig<Data>[]
  value: ChartSizeTypeConfig<Data>
  onChange: (value: ChartSizeTypeConfig<Data>) => void
}

/**
 * Changes which sizing-type to use for a chart. This setting impacts the area
 * items take up in the chart.
 */
export default function ChartSizeTypeSelect<Data>({
  options,
  value,
  onChange,
}: ChartSizeTypeSelectProps<Data>) {
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      gap={1}>
      {value?.explainer && (
        <InfoButton
          dialogMessage={value.explainer.message}
          dialogTitle={value.explainer.title}
        />
      )}
      <SelectMenu
        label={'Size'}
        onChange={onChange}
        options={options.map((option) => ({
          label: option.label,
          value: option,
        }))}
        value={value}
      />
    </Stack>
  )
}
