import { isDefined } from '@gain/utils/common'
import Stack, { StackProps } from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { Children, useMemo } from 'react'

import { keyValueListItemClasses } from './key-value-list-item'

export interface StyledKeyValueListProps {
  dense?: boolean
}

const StyledKeyValueList = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'dense',
})<StyledKeyValueListProps>(({ dense }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...(dense && {
    [`& .${keyValueListItemClasses.root}`]: {
      minHeight: 24,
    },
  }),
}))

export interface KeyValueListProps extends StyledKeyValueListProps, StackProps {
  maxItems?: number
}

export default function KeyValueList({ maxItems, children, ...props }: KeyValueListProps) {
  const visibleChildren = useMemo(() => {
    if (isDefined(maxItems)) {
      return Children.toArray(children).slice(0, maxItems)
    }

    return children
  }, [children, maxItems])

  return <StyledKeyValueList {...props}>{visibleChildren}</StyledKeyValueList>
}
