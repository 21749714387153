import { AssetListItem } from '@gain/rpc/app-model'
import { TooltipProps } from '@mui/material/Tooltip'

import { ChartSizeTypeConfig } from '../../../../common/chart/chart-select'
import ChartTooltip from '../../../../common/chart/chart-tooltip'
import { AxisConfig } from '../axis/axis-config'
import { ActiveBubble } from '../use-active-bubble'
import CompanyBubbleEChartTooltipTitle from './company-bubble-echart-tooltip-title'

type CompanyBubbleEChartTooltipProps = Omit<TooltipProps, 'title' | 'children' | 'followCursor'> & {
  assets: AssetListItem[]
  activeBubble: ActiveBubble
  sizeConfig: ChartSizeTypeConfig<AssetListItem>
  xAxisConfig: AxisConfig
  yAxisConfig: AxisConfig
}

/**
 * CompanyBubbleEChartTooltip is a custom tooltip when hovering over the chart
 * bubbles.
 */
export default function CompanyBubbleEChartTooltip({
  open,
  assets,
  activeBubble,
  sizeConfig,
  xAxisConfig,
  yAxisConfig,
  ...props
}: CompanyBubbleEChartTooltipProps) {
  return (
    <ChartTooltip
      placement={'left'}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              // MUIs default offset is way too far from the actual target element
              offset: [0, -8],
            },
          },
        ],
      }}
      title={
        <CompanyBubbleEChartTooltipTitle
          asset={activeBubble.asset}
          sizeConfig={sizeConfig}
          xAxisConfig={xAxisConfig}
          yAxisConfig={yAxisConfig}
        />
      }
      disableInteractive
      open
      {...props}>
      {/* The active bubble is part of the canvas and not a real component.
          Therefore, we wrap an empty div that is properly positioned. */}
      <div
        style={{
          position: 'absolute',
          top: activeBubble?.y,
          left: activeBubble?.x,
          height: activeBubble?.height,
          width: activeBubble?.width,
        }}
      />
    </ChartTooltip>
  )
}
