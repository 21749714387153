import { ChangeEvent, MouseEvent, useCallback, useMemo, useRef } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'

import { MOBILE_SEARCH_EMPTY_PATH, MOBILE_SEARCH_PATH, MobileSearchParams } from './home-mobile'
import SearchField from './search/search-field'
import { useSearchActive } from './search/use-search-active'

export default function SearchInput() {
  const active = useSearchActive()
  const history = useHistory()
  const textFieldRef = useRef<HTMLInputElement>()
  const match = useRouteMatch<MobileSearchParams>(MOBILE_SEARCH_PATH)
  const query = useMemo(() => decodeURIComponent(match?.params.query || ''), [match?.params.query])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        history.replace(generatePath(MOBILE_SEARCH_PATH, { query: event.target.value }))
      } else {
        history.replace(MOBILE_SEARCH_EMPTY_PATH)
      }
    },
    [history]
  )

  const handleClearInput = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      history.replace(MOBILE_SEARCH_EMPTY_PATH)

      textFieldRef.current?.focus()
    },
    [history]
  )

  const handleActivate = useCallback(() => {
    if (!active) {
      history.push(MOBILE_SEARCH_EMPTY_PATH)
    }
  }, [active, history])

  return (
    <SearchField
      disableClear={!query}
      inputRef={textFieldRef}
      onChange={handleChange}
      onClear={handleClearInput}
      onClick={handleActivate}
      value={query}
      autoFocus
    />
  )
}
