import { BriefcaseMoneyIcon } from '@gain/components/icons'
import { TooltipHeader } from '@gain/components/tooltip'
import { InvestorStrategyListItem } from '@gain/rpc/app-model'
import { isDefined, isTruthy } from '@gain/utils/common'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

import EbitdaValuesRange from '../../../common/ebitda-values-range'
import { KeyValueList, KeyValueListItem } from '../../../common/key-value/key-value-list'

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 320,
}))

const StyledEbitdaValuesRange = styled(EbitdaValuesRange)({
  width: 104,
})

export type InvestorStrategyWithIdListItem = Omit<InvestorStrategyListItem, 'strategyId'> & {
  strategyId: number
}

type TooltipInvestorStrategyTitleProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  strategy: InvestorStrategyWithIdListItem
}

export default function TooltipInvestorStrategyTitle({
  strategy,
  ...props
}: TooltipInvestorStrategyTitleProps) {
  const formatCurrency = useFormatCurrencyCallback()

  const ebitdaValues = strategy.assetEbitdasEur.filter((value) => Math.round(value) > 0) ?? []
  const hasStrategyStats =
    [ebitdaValues.length, strategy.assetIds.length].filter(isTruthy).length > 0
  const hasLatestFundStats =
    [strategy.latestFundName, strategy.latestFundSizeEur, strategy.latestFundVintageDate].filter(
      isDefined
    ).length > 0

  return (
    <div {...props}>
      <StyledContainer
        direction={'column'}
        spacing={2}>
        <TooltipHeader
          defaultIcon={<BriefcaseMoneyIcon />}
          logoSrc={strategy.investorLogoFileUrl}
          subtitle={`Strategy of ${strategy.investorName}`}
          title={strategy.strategyName}
        />

        {hasStrategyStats && (
          <KeyValueList
            spacing={0.5}
            dense>
            {strategy.assetIds.length > 0 && (
              <KeyValueListItem
                label={'Companies'}
                value={strategy.assetIds.length}
              />
            )}
            {ebitdaValues?.length > 0 && (
              <KeyValueListItem
                label={'EBITDA range'}
                value={<StyledEbitdaValuesRange ebitdaValues={ebitdaValues} />}
                valueTypographyProps={{
                  component: 'div',
                }}
              />
            )}
          </KeyValueList>
        )}

        {hasStrategyStats && hasLatestFundStats && <Divider />}

        {hasLatestFundStats && (
          <KeyValueList
            spacing={0.5}
            dense>
            <Typography variant={'subtitle2'}>Latest fund</Typography>
            {strategy.latestFundName !== null && (
              <KeyValueListItem
                label={'Fund'}
                value={strategy.latestFundName}
              />
            )}
            {strategy.latestFundSizeEur !== null && (
              <KeyValueListItem
                label={'Fund size'}
                value={formatCurrency(strategy.latestFundSizeEur)}
              />
            )}
            {strategy.latestFundVintageDateYear !== null && (
              <KeyValueListItem
                label={'Vintage date'}
                value={strategy.latestFundVintageDateYear}
              />
            )}
          </KeyValueList>
        )}
      </StyledContainer>
    </div>
  )
}
