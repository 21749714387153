import { styled } from '@mui/material/styles'
import { Route, Switch } from 'react-router-dom'

import SearchInput from '../search-input'
import SearchCancelButton from './search-cancel-button'
import { SearchEmpty } from './search-empty'
import SearchResults from './search-results'
import { SEARCH_MOBILE_HEADER_HEIGHT } from './search-results-constants'

const StyledHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: SEARCH_MOBILE_HEADER_HEIGHT,
  padding: theme.spacing(0, 3),
  gap: theme.spacing(2),
  zIndex: theme.zIndex.appBar,
}))

export default function SearchMobile() {
  return (
    <div>
      <StyledHeader>
        <SearchInput />
        <SearchCancelButton />
      </StyledHeader>
      <Switch>
        <Route
          component={SearchResults}
          path={'/home/search/:query'}
          exact
        />
        <Route
          component={SearchEmpty}
          path={'/home/search'}
          exact
        />
      </Switch>
    </div>
  )
}
