import Typography from '@gain/components/typography'
import { UserListItem as UserListItemType } from '@gain/rpc/app-model'
import Chip from '@mui/material/Chip'
import ListItem, { ListItemProps } from '@mui/material/ListItem'
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'

import UserAvatar from './user-avatar'

const StyledListItem = styled(ListItem)(({ theme }) => ({
  // Add extra padding on the right for "More options" menu
  padding: theme.spacing(0.75, 6, 0.75, 2.5),
  gap: theme.spacing(1),
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),

  [`& .${listItemTextClasses.primary}`]: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },

  [`& .${listItemTextClasses.secondary}`]: {
    margin: 0,
  },
}))

const StyledUsername = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis', // truncate the username instead of the "Owner"-chip
})

interface ShareUserListItemProps extends ListItemProps {
  user: UserListItemType
  isOwner?: boolean
}

export default function ShareUserListItem({
  user,
  isOwner = false,
  ...props
}: ShareUserListItemProps) {
  return (
    <StyledListItem {...props}>
      <UserAvatar
        firstName={user.firstName}
        lastName={user.lastName}
      />

      <StyledListItemText
        primary={
          <>
            <StyledUsername variant={'body2'}>
              {user.firstName} {user.lastName}
            </StyledUsername>
            {isOwner && (
              <Chip
                label={'Owner'}
                size={'small'}
              />
            )}
          </>
        }
        primaryTypographyProps={{
          component: 'div',
        }}
        secondary={user.email}
      />
    </StyledListItem>
  )
}
