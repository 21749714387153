import Flag from '@gain/components/flag'
import { isDefined } from '@gain/utils/common'
import { styled } from '@mui/material/styles'
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react'
import { animated, useSpring } from 'react-spring'

import Avatar, { AvatarProps } from '../avatar'

export type LogoProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  loading?: boolean
  src?: string | null
  region?: string | null
  size?: number
  variant?: AvatarProps['variant']
  fullSize?: boolean
  avatarProps?: Omit<AvatarProps, 'src' | 'variant' | 'children'>
  defaultIcon?: ReactNode
  dot?: 'error' | 'warning'
}

const StyledContainer = styled(animated.div)({
  position: 'relative',
})

const StyledFlag = styled(Flag)<{ size?: number }>(({ size }) => ({
  position: 'absolute',
  bottom: -2,
  right: -2,
  maxWidth: 16,
  maxHeight: 12,

  ...(size && {
    width: size / 2,
    // Aspect ratio of 4:3
    height: (size / 2 / 4) * 3,
  }),
}))

const StyledDot = styled('div')<{ color: 'error' | 'warning' }>(({ color, theme }) => ({
  position: 'absolute',
  top: -2,
  right: -2,
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette[color].main,
}))

export default function Logo({
  loading,
  src,
  region,
  className,
  size,
  variant = 'rounded',
  avatarProps,
  fullSize,
  defaultIcon,
  dot,
}: LogoProps) {
  const style = useSpring({
    width: size,
    height: size,
    minWidth: size,
    minHeight: size,
    maxWidth: size,
    maxHeight: size,
  })

  return (
    <StyledContainer
      className={className}
      style={style}>
      <Avatar
        component={animated.div}
        disableBorder={fullSize}
        imagePadding={fullSize ? 0 : undefined}
        skeleton={loading}
        src={src || undefined}
        style={style as never}
        variant={variant}
        {...avatarProps}>
        {!isDefined(src) && defaultIcon}
      </Avatar>
      {region && (
        <StyledFlag
          code={region}
          size={size}
        />
      )}
      {dot && <StyledDot color={dot} />}
    </StyledContainer>
  )
}
