import { useCurrentValuationRatios } from '@gain/api/app/hooks'
import Typography from '@gain/components/typography'
import { ListedSecurityListItem, ListedSecurityValuationRatios } from '@gain/rpc/app-model'
import { useElementWidthEffect } from '@gain/utils/dom'
import { formatRatio } from '@gain/utils/number'
import { useIsXs } from '@gain/utils/responsive'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import * as echarts from 'echarts/core'
import { useCallback, useRef, useState } from 'react'

import Card, { CardContent, CardHeader } from '../../../../common/card/card'
import ChartTooltip from '../../../../common/chart/chart-tooltip'
import EChart from '../../../../common/echart/echart'
import SelectMenu from '../../../../common/select-menu/select-menu'
import {
  useValuationPeriodOptions,
  ValuationPeriod,
} from '../../../../features/valuation/valuation-util'
import OutlierIcon from './outlier-icon'
import RatioSelect from './ratio-select'
import RatioTabs from './ratio-tabs'
import { useCurrentValuationRatioOptions } from './ratio-utils'
import useRatiosChartHover from './use-ratios-chart-hover'
import useRatiosChartOption from './use-ratios-chart-option'

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4.5, 3, 2),
  position: 'relative',
}))

const StyledLegendContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
}))

export interface RatiosCardProps {
  listedSecurity: ListedSecurityListItem
  historicalValuations: ListedSecurityValuationRatios[]
}

/**
 * RatiosCard allows the user to select an available ratio and shows the
 * datapoint for the selected ratio in a chart.
 */
export default function RatiosCard({ listedSecurity, historicalValuations }: RatiosCardProps) {
  const isXs = useIsXs()
  const eChartsInstanceRef = useRef<echarts.ECharts | undefined>()
  const [activeRatio, handleMouseMove, handleMouseOut] = useRatiosChartHover()
  const swrCurrentValuationRatios = useCurrentValuationRatios({
    listedSecurityId: listedSecurity.id,
  })

  // Force the cursor to be default since we cannot style it using ECharts
  const handleForceDefaultCursor = useCallback(() => {
    eChartsInstanceRef.current?.getZr().setCursorStyle('default')
  }, [])

  // Keep the ECharts reference up-to-date each time it (re-)initializes
  const handleInit = useCallback((eChartsInstance: echarts.ECharts) => {
    eChartsInstanceRef.current = eChartsInstance
  }, [])

  // Resize ECharts whenever the card size changes
  const cardRef = useRef<HTMLDivElement>(null)
  const resizeECharts = useCallback(() => {
    eChartsInstanceRef.current?.resize()
  }, [])
  useElementWidthEffect(cardRef, resizeECharts)

  // Valuation period selector
  const valuationPeriodOptions = useValuationPeriodOptions()
  const [valuationPeriod, setValuationPeriod] = useState<ValuationPeriod>('LastTwelveMonths')

  const selectedValuationPeriodOption = valuationPeriodOptions.find(
    (option) => option.value === valuationPeriod
  )

  const selectedValuationRatios = swrCurrentValuationRatios.data?.find((valuation) =>
    selectedValuationPeriodOption?.matches(valuation)
  )

  // Ratio options, the options are updated when the selected valuation period changes
  const currentValuationRatioOptions = useCurrentValuationRatioOptions(
    selectedValuationRatios,
    historicalValuations
  )

  // Active tab, the initial active tab is the first with a valid ratio option
  const [activeTab, setActiveTab] = useState(
    currentValuationRatioOptions.findIndex((option) => !option.disabled)
  )

  // ECharts option, the option is updated when the active tab changes. The echarts option
  // is extracted from the historical valuations based on the active tab.
  const echartsOption = useRatiosChartOption(
    currentValuationRatioOptions[activeTab]?.key,
    historicalValuations
  )

  return (
    <Card
      ref={cardRef}
      sx={{ pb: 0 }}>
      <CardHeader
        actions={
          !isXs && (
            <SelectMenu
              label={'Valuation period'}
              onChange={setValuationPeriod}
              options={valuationPeriodOptions}
              sx={{ mr: -0.5 }}
              value={valuationPeriod}
            />
          )
        }
        title={'Valuation ratios'}
      />

      {/* Display tabs on all displays except mobile */}
      {!isXs && (
        <>
          <RatioTabs
            onChange={setActiveTab}
            options={currentValuationRatioOptions}
            value={activeTab}
          />
          <Divider />
        </>
      )}

      {/* A dropdown select is shown on mobile to select the active ratio */}
      {isXs && (
        <Stack sx={{ mx: 3, gap: 1 }}>
          <SelectMenu
            label={'Valuation period'}
            onChange={setValuationPeriod}
            options={valuationPeriodOptions}
            value={valuationPeriod}
          />
          <RatioSelect
            onChange={setActiveTab}
            options={currentValuationRatioOptions}
            value={activeTab}
          />
        </Stack>
      )}

      <StyledCardContent>
        {activeRatio && (
          <ChartTooltip
            placement={activeRatio.placement}
            title={formatRatio(activeRatio.data.value)}
            disableInteractive
            open>
            <div
              style={{
                position: 'absolute',
                top: activeRatio?.rect.y,
                left: activeRatio?.rect.x,
                height: activeRatio?.rect.height,
                width: activeRatio?.rect.width,
                // ECharts uses transforms to position the plotted dots on the
                // chart. We simply apply the same transformation to this div
                // to make sure the containing tooltip can be rendered next to
                // the plotted dot that this tooltip belongs to.
                transform: `matrix(${activeRatio?.transform})`,
              }}></div>
          </ChartTooltip>
        )}
        <EChart
          onInit={handleInit}
          onMouseMove={handleMouseMove}
          // We cannot force the default cursor on a plotted line. To enforce
          // this behavior we set the cursor to default on every Zr mouse move
          // event.
          onMouseMoveZr={handleForceDefaultCursor}
          onMouseOut={handleMouseOut}
          option={echartsOption}
          style={{ height: 292 }}
          clearOnChange
        />
      </StyledCardContent>
      <Divider />
      <StyledLegendContainer>
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={0.5}>
          <OutlierIcon />
          <Typography
            color={'text.secondary'}
            variant={'overline'}>
            Outlier
          </Typography>
        </Stack>
      </StyledLegendContainer>
    </Card>
  )
}
