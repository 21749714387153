import { ChevronRightIcon, XIcon } from '@gain/components/icons'
import Button, { buttonClasses } from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Fade from '@mui/material/Fade'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputAdornment, { inputAdornmentClasses } from '@mui/material/InputAdornment'
import { inputBaseClasses } from '@mui/material/InputBase'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import { alpha, styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { ChangeEvent, useRef, useState } from 'react'

import DialogHeader from '../../common/dialog-header'
import ColumnPickerGroup from './column-picker-group'
import ColumnPickerSearchResults from './column-picker-search-results'
import useColumnPicker, { ColumnPickerConfigType } from './use-column-picker'

const DIALOG_HEADER_HEIGHT = 57
const DIALOG_ACTIONS_HEIGHT = 57
const SEARCH_INPUT_HEIGHT = 40
const SHOW_COLUMNS_FOR_ACTIVE_FILTERS_CONTAINER_HEIGHT = 36
const DIALOG_CONTENT_GAP_PX = 16
const SEARCH_INPUT_CONTAINER_HEIGHT =
  DIALOG_CONTENT_GAP_PX + SEARCH_INPUT_HEIGHT + DIALOG_CONTENT_GAP_PX
const GROUP_LIST_ITEM_HEIGHT = 34

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: 600,
  paddingTop: 0,
  paddingBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}))

const StyledSearchContainer = styled('div')(({ theme }) => ({
  // Scrolling inside the DialogContent should only scroll the actual
  // column list while having the scrollbar cover the entire height of
  // the Dialog content.
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.tooltip,
  backgroundColor: theme.palette.background.paper,
}))

const StyledSearchInputContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2, 0, 2, 0),
  position: 'relative', // zIndex doesn't work without this
  zIndex: 2, // On top of everything (within the StyledSearchContainer)
}))

const StyledSearchInput = styled(TextField)(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    backgroundColor: theme.palette.grey['100'],

    [`& .${inputBaseClasses.input}`]: {
      padding: theme.spacing(0, 1.5),

      [`&.${inputAdornmentClasses.root}`]: {
        fontSize: 16,
      },
    },

    [`&.${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderWidth: 3,
      borderColor: theme.palette.grey['100'],
      borderRadius: 8,

      transition: theme.transitions.create('border-color', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shortest,
      }),
    },

    [`&.${inputBaseClasses.focused}`]: {
      backgroundColor: theme.palette.background.paper,

      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: `${theme.palette.primary.light} !important`,
      },

      [`& .${buttonClasses.root}, .${buttonClasses.endIcon} svg`]: {
        color: theme.palette.primary.main,
      },
    },
  },
}))

const StyledShowColumnsForActiveFiltersContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  borderRadius: 8,
  padding: theme.spacing(0, 1),
  marginBottom: theme.spacing(2),
  zIndex: 1, // On top of "show columns for active filters", below search input
  height: SHOW_COLUMNS_FOR_ACTIVE_FILTERS_CONTAINER_HEIGHT,
  display: 'flex',
  alignItems: 'center',
}))

const StyledSearchResultsContainer = styled('div', {
  shouldForwardProp: (propName) => propName !== 'visible',
})<{ visible: boolean }>(({ theme, visible }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'fixed', // On top of everything except the search input
  // position fixed is relative to a fixed parent, in this case the actual
  // dialog, so we offset it only cover the actual content without header
  // and footer.
  top: DIALOG_HEADER_HEIGHT,
  right: 0,
  bottom: DIALOG_ACTIONS_HEIGHT,
  left: 0,
  zIndex: 1,
  paddingTop: SEARCH_INPUT_CONTAINER_HEIGHT,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  overflow: 'auto',
  display: 'block',
  ...(!visible && {
    display: 'none',
  }),
}))

const StyledColumnGroupListContainer = styled('div')(({ theme }) => ({
  // Component should not overflow but just in case we add overflow 'auto'
  overflow: 'auto',
  // Component should not move when scrolling in the dialog, the only part
  // that should be scrolling is the actual columns list.
  position: 'sticky',
  top:
    SEARCH_INPUT_CONTAINER_HEIGHT +
    SHOW_COLUMNS_FOR_ACTIVE_FILTERS_CONTAINER_HEIGHT +
    DIALOG_CONTENT_GAP_PX, // Same as DialogContent padding bottom
  flex: 1,
  alignSelf: 'flex-start', // Required for position sticky to work
  paddingRight: theme.spacing(2),
}))

const StyledColumnContainer = styled('div')(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.divider}`,
  flex: 1,
  paddingLeft: theme.spacing(2),
}))

const StyledCrossIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.text.primary, 0.2),
  fontSize: 12,
  width: 16,
  height: 16,
  padding: 2,
  '&:hover': {
    backgroundColor: alpha(theme.palette.text.primary, 0.4),
  },
}))

export interface ColumnPickerDialogProps {
  open: boolean
  onClose: () => void
  columnConfigId: ColumnPickerConfigType
  activeFilterColumns: string[]
}

export default function ColumnPickerDialog({
  open,
  onClose,
  columnConfigId,
  activeFilterColumns,
}: ColumnPickerDialogProps) {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const api = useColumnPicker(columnConfigId)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleGroupClick = (index: number) => () => {
    setSelectedIndex(index)
  }

  const handleResetClick = () => {
    api.resetToDefault()
  }

  const handleDialogOpen = () => {
    searchInputRef.current?.focus()
  }

  const handleDialogClosed = () => {
    setSearchQuery('')
    setSelectedIndex(0)
  }

  return (
    <Dialog
      onClose={onClose}
      onTransitionEnter={handleDialogOpen}
      onTransitionExited={handleDialogClosed}
      open={open}>
      <DialogHeader
        onCloseClick={onClose}
        title={'Add or remove columns'}
      />
      <StyledDialogContent
        sx={{
          // Dialog should be able to display all groups without overflow with
          // a minimum of 9
          height:
            SEARCH_INPUT_CONTAINER_HEIGHT +
            SHOW_COLUMNS_FOR_ACTIVE_FILTERS_CONTAINER_HEIGHT +
            DIALOG_CONTENT_GAP_PX +
            Math.max(9, api.groups.length) * GROUP_LIST_ITEM_HEIGHT +
            DIALOG_CONTENT_GAP_PX,
        }}>
        <StyledSearchContainer>
          <StyledSearchInputContainer>
            <StyledSearchInput
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <Fade in={!!searchQuery}>
                      <StyledCrossIconButton onClick={() => setSearchQuery('')}>
                        <XIcon />
                      </StyledCrossIconButton>
                    </Fade>
                  </InputAdornment>
                ),
              }}
              inputRef={searchInputRef}
              onChange={handleSearch}
              placeholder={'Search column'}
              value={searchQuery}
              variant={'outlined'}
              fullWidth
            />
          </StyledSearchInputContainer>
          <StyledSearchResultsContainer visible={!!searchQuery}>
            <ColumnPickerSearchResults
              activeFilterColumns={activeFilterColumns}
              api={api}
              searchQuery={searchQuery}
            />
          </StyledSearchResultsContainer>
          <StyledShowColumnsForActiveFiltersContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={api.showColumnsForActiveFilters}
                  onChange={(_, checked) => {
                    api.setShowColumnsForActiveFilters(checked)
                  }}
                />
              }
              label={'Show columns for active filters'}
            />
          </StyledShowColumnsForActiveFiltersContainer>
        </StyledSearchContainer>
        <Stack direction={'row'}>
          <StyledColumnGroupListContainer>
            <List
              dense
              disablePadding>
              {api.groups.map((group, index) => (
                <ListItem
                  key={index}
                  disablePadding>
                  <ListItemButton
                    onClick={handleGroupClick(index)}
                    selected={selectedIndex === index}>
                    <ListItemIcon>
                      <group.Icon />
                    </ListItemIcon>
                    <ListItemText primary={group.name} />
                    <ChevronRightIcon />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </StyledColumnGroupListContainer>
          <StyledColumnContainer key={selectedIndex}>
            <ColumnPickerGroup
              activeFilterColumns={activeFilterColumns}
              api={api}
              group={api.groups[selectedIndex]}
            />
          </StyledColumnContainer>
        </Stack>
      </StyledDialogContent>
      <DialogActions>
        <Button
          color={'error'}
          onClick={handleResetClick}
          sx={{ flex: 1, backgroundColor: 'background.paper' }}
          variant={'outlined'}>
          Reset preferences
        </Button>
        <Button
          color={'primary'}
          onClick={onClose}
          sx={{ flex: 1 }}
          variant={'contained'}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}
