import { InvestorFundListItem } from '@gain/rpc/app-model'
import {
  ChipOption,
  getOption,
  INVESTOR_FUND_FUNDRAISING_STATUS_OPTIONS,
  InvestorFundFundraisingStatus,
} from '@gain/rpc/shared-model'
import { useFormatCurrencyCallback } from '@gain/utils/currency'
import { formatMonthYear } from '@gain/utils/date'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'

import { CardContent } from '../../../../common/card/card'
import KeyValueList, { KeyValueListItem } from '../../../../common/key-value/key-value-list'
import { useFundRaisingStatus } from './investor-fund-overview-card-hooks'

interface InvestorFundOverviewCardFundraisingTabProps {
  fund: InvestorFundListItem
}

export default function InvestorFundOverviewCardFundraisingTab({
  fund,
}: InvestorFundOverviewCardFundraisingTabProps) {
  const formatCurrency = useFormatCurrencyCallback(fund.currency)

  const fundRaisingStatus = useFundRaisingStatus(fund)
  const statusOption = fundRaisingStatus
    ? getOption<InvestorFundFundraisingStatus, ChipOption<InvestorFundFundraisingStatus>>(
        INVESTOR_FUND_FUNDRAISING_STATUS_OPTIONS,
        fundRaisingStatus
      )
    : null

  return (
    <CardContent>
      <KeyValueList sx={{ py: 1 }}>
        <KeyValueListItem
          label={'Fundraising status'}
          value={
            <Chip
              color={statusOption?.color}
              label={statusOption?.label || 'Not set'}
            />
          }
        />
      </KeyValueList>

      <Grid
        pb={2}
        size={12}
        spacing={2.5}
        container>
        <Grid size={12}>
          <Divider />
        </Grid>

        <Grid
          size={12}
          spacing={2}
          container>
          <Grid size={6}>
            <Typography variant={'subtitle2'}>Launch date</Typography>
            <Typography variant={'body2'}>
              {formatMonthYear(fund.launchMonth, fund.launchYear)}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant={'subtitle2'}>Target size</Typography>
            <Typography variant={'body2'}>{formatCurrency(fund.targetFundSize)}</Typography>
          </Grid>
          <Grid
            offset={6}
            size={6}>
            <Typography variant={'subtitle2'}>Hard cap</Typography>
            <Typography variant={'body2'}>{formatCurrency(fund.hardCapSize)}</Typography>
          </Grid>
        </Grid>

        <Grid size={12}>
          <Divider />
        </Grid>

        <Grid
          size={12}
          spacing={2}
          container>
          <Grid size={6}>
            <Typography variant={'subtitle2'}>First close date</Typography>
            <Typography variant={'body2'}>
              {formatMonthYear(fund.firstCloseMonth, fund.firstCloseYear)}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant={'subtitle2'}>First close</Typography>
            <Typography variant={'body2'}>{formatCurrency(fund.firstCloseSize)}</Typography>
          </Grid>
        </Grid>

        <Grid size={12}>
          <Divider />
        </Grid>

        <Grid
          size={12}
          spacing={2}
          container>
          <Grid size={6}>
            <Typography variant={'subtitle2'}>Final close date</Typography>
            <Typography variant={'body2'}>
              {formatMonthYear(fund.finalCloseMonth, fund.finalCloseYear)}
            </Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant={'subtitle2'}>Fund size</Typography>
            <Typography variant={'body2'}>{formatCurrency(fund.fundSize)}</Typography>
          </Grid>
        </Grid>

        <Grid size={12}>
          <Divider />
        </Grid>

        <Grid
          size={12}
          spacing={2}
          container>
          <Grid size={6}>
            <Typography variant={'subtitle2'}>Vintage date</Typography>
            <Typography variant={'body2'}>{fund.vintageYear}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  )
}
