import { CompanyIcon } from '@gain/components/icons'
import clsx from 'clsx'

import { LegalEntityNodeData, Node } from './org-chart-model'
import { orgChartClasses } from './org-chart-utils'

interface LegalEntityNodeProps {
  node: Node<LegalEntityNodeData>
}

export default function LegalEntityNode({ node }: LegalEntityNodeProps) {
  return (
    <div className={orgChartClasses.itemContainer}>
      <div className={orgChartClasses.iconContainer}>
        <CompanyIcon />
      </div>
      <span className={clsx(orgChartClasses.label, orgChartClasses.truncateText)}>
        {node.data.name}
      </span>
    </div>
  )
}
