import { useResizeObserver } from '@gain/utils/dom'
import { RatingGroup } from '@gain/utils/investment-criteria'
import { useValueCellWidth } from '@gain/utils/table'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { memo, useCallback, useRef, useState } from 'react'

import TableHeadCellTitle from './table-head-cell-title.component'
import { LABEL_CELL_WIDTH, VALUE_CELL_MIN_WIDTH } from './table-rating.constants'
import { TableRatingColumn } from './table-rating.model'
import TableRatingContent from './table-rating-content'
import TableRatingScrollButtons from './table-rating-scoll-buttons.component'

const StyledRoot = styled('div')({
  position: 'relative',
})

const StyledTable = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  position: 'relative',
  zIndex: 3, // Put scrollbar on top of shadow
})

const StyledTableHead = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
})

const StyledTableRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
})

const StyledTableCell = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.body2,
}))

const StyledLabelCell = styled(StyledTableCell)(({ theme }) => ({
  width: LABEL_CELL_WIDTH,
  minWidth: LABEL_CELL_WIDTH,
  maxWidth: LABEL_CELL_WIDTH,
  alignItems: 'center',
  position: 'sticky',
  left: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
}))

const StyledTitleCell = styled(StyledLabelCell)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(3),
}))

const StyledValueCell = styled(StyledTableCell)({})

const StyledValueHeaderCell = styled(StyledValueCell)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}))

export interface TableRatingProps<D extends string> {
  title?: string | false
  columns: TableRatingColumn<D>[]
  ratingGroups: RatingGroup<D>[]
}

function TableRating<D extends string>({ title, columns, ratingGroups }: TableRatingProps<D>) {
  const ref = useRef<HTMLDivElement>(null)
  const [canScroll, setCanScroll] = useState(false)
  const [availableWidth, setAvailableWidth] = useState<number>(0)

  const valueCellWidth = useValueCellWidth(
    availableWidth,
    columns.length,
    VALUE_CELL_MIN_WIDTH,
    undefined,
    true
  )
  const [headerHeight, setHeaderHeight] = useState(0)

  const handleResize = useCallback(() => {
    if (ref.current) {
      setCanScroll(ref.current.scrollWidth > ref.current.clientWidth)
      setAvailableWidth(ref.current.getBoundingClientRect().width - LABEL_CELL_WIDTH)
    }
  }, [])

  useResizeObserver(ref, handleResize)

  return (
    <StyledRoot>
      {canScroll && headerHeight && (
        <TableRatingScrollButtons
          availableWidth={availableWidth}
          headerHeight={headerHeight}
          scrollContainerRef={ref}
        />
      )}
      <StyledTable ref={ref}>
        {availableWidth > 0 && (
          <>
            <StyledTableHead
              ref={(el) => {
                if (!el) {
                  return
                }
                setHeaderHeight(el.getBoundingClientRect().height)
              }}>
              <StyledTableRow>
                <StyledTitleCell>{title}</StyledTitleCell>
                {columns.map((column, columnIndex) => (
                  <StyledValueHeaderCell
                    key={columnIndex}
                    sx={{ width: valueCellWidth, minWidth: valueCellWidth }}>
                    <TableHeadCellTitle
                      color={'text.primary'}
                      variant={'subtitle2'}>
                      {column.title}
                    </TableHeadCellTitle>
                    <Typography
                      color={'text.secondary'}
                      variant={'body2'}>
                      {column.subtitle}
                    </Typography>
                  </StyledValueHeaderCell>
                ))}
              </StyledTableRow>
            </StyledTableHead>
            <TableRatingContent
              columns={columns}
              ratingGroups={ratingGroups}
              valueCellWidth={valueCellWidth}
            />
          </>
        )}
      </StyledTable>
    </StyledRoot>
  )
}

export default memo(TableRating)
