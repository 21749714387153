import { BriefcaseMoneyIcon } from '@gain/components/icons'
import { TooltipHeader } from '@gain/components/tooltip'
import { InvestorListItem } from '@gain/rpc/app-model'
import { isDefined, isTruthy } from '@gain/utils/common'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

import EbitdaValuesRange from '../../../common/ebitda-values-range'
import { KeyValueList, KeyValueListItem } from '../../../common/key-value/key-value-list'
import FinancialValue from '../../financial/financial-value'

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 320,
}))

const StyledEbitdaValuesRange = styled(EbitdaValuesRange)({
  width: 104,
})

export interface TooltipInvestorTitleProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  investor: InvestorListItem
}

export default function TooltipInvestorTitle({ investor, ...props }: TooltipInvestorTitleProps) {
  const ebitdaValues = investor.assetEbitdasEur.filter((value) => Math.round(value) > 0) ?? []

  const hasInvestorStats =
    [ebitdaValues.length, investor.fundsRaisedLastFiveYearsEur].filter(isTruthy).length > 0

  const hasFlagshipFundStats =
    [
      investor.flagshipFundName,
      investor.flagshipFundSizeEur,
      investor.flagshipFundVintageYear,
    ].filter(isDefined).length > 0

  return (
    <div {...props}>
      <StyledContainer
        direction={'column'}
        spacing={2}>
        <TooltipHeader
          defaultIcon={<BriefcaseMoneyIcon />}
          logoSrc={investor.logoFileUrl}
          subtitle={`${investor.assetIds.length} ${
            investor.assetIds.length === 1 ? 'company' : 'companies'
          } in portfolio`}
          title={investor.name}
        />

        {hasInvestorStats && (
          <KeyValueList
            spacing={0.5}
            dense>
            {ebitdaValues?.length > 0 && (
              <KeyValueListItem
                label={'EBITDA range'}
                value={<StyledEbitdaValuesRange ebitdaValues={ebitdaValues} />}
                valueTypographyProps={{
                  component: 'div',
                }}
              />
            )}

            {investor.fundsRaisedLastFiveYearsEur !== null && (
              <KeyValueListItem
                label={'Fundraising (L5Y)'}
                value={<FinancialValue amount={investor.fundsRaisedLastFiveYearsEur} />}
              />
            )}
          </KeyValueList>
        )}

        {hasInvestorStats && hasFlagshipFundStats && <Divider />}

        {hasFlagshipFundStats && (
          <KeyValueList
            spacing={0.5}
            dense>
            <Typography variant={'subtitle2'}>Flagship fund</Typography>
            {investor.flagshipFundName !== null && (
              <KeyValueListItem
                label={'Fund'}
                value={investor.flagshipFundName}
              />
            )}
            {investor.flagshipFundSizeEur !== null && (
              <KeyValueListItem
                label={'Fund size'}
                value={<FinancialValue amount={investor.flagshipFundSizeEur} />}
              />
            )}
            {investor.flagshipFundVintageYear !== null && (
              <KeyValueListItem
                label={'Vintage date'}
                value={investor.flagshipFundVintageYear}
              />
            )}
          </KeyValueList>
        )}
      </StyledContainer>
    </div>
  )
}
