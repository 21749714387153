import { CalendarIcon, GlobeIcon, HashIcon, MapPinIcon } from '@gain/components/icons'
import { ConferenceEditionListItem } from '@gain/rpc/app-model'
import { formatCountryCity } from '@gain/utils/countries'
import { formatDateRange } from '@gain/utils/date'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import ListItemUrl from '../../../../common/list-item-url'

export interface ConferenceDetailsListProps {
  conferenceEdition: ConferenceEditionListItem
}

export default function ConferenceDetailsList({ conferenceEdition }: ConferenceDetailsListProps) {
  return (
    <List
      dense
      disablePadding>
      <ListItem
        disableGutters
        disablePadding>
        <ListItemIcon>
          <MapPinIcon />
        </ListItemIcon>
        <ListItemText>
          {formatCountryCity(
            conferenceEdition.venueCountryCode,
            conferenceEdition.venueCity,
            conferenceEdition.venueRegion
          )}
        </ListItemText>
      </ListItem>

      <ListItem
        disableGutters
        disablePadding>
        <ListItemIcon>
          <CalendarIcon />
        </ListItemIcon>
        <ListItemText>
          {formatDateRange(conferenceEdition.startDate, conferenceEdition.endDate)}
        </ListItemText>
      </ListItem>

      <ListItem
        disableGutters
        disablePadding>
        <ListItemIcon>
          <HashIcon />
        </ListItemIcon>
        <ListItemText>{conferenceEdition.exhibitorsCount} exhibitors</ListItemText>
      </ListItem>

      {conferenceEdition.url && (
        <ListItem
          disableGutters
          disablePadding>
          <ListItemIcon>
            <GlobeIcon />
          </ListItemIcon>
          <ListItemUrl url={conferenceEdition.url} />
        </ListItem>
      )}
    </List>
  )
}
